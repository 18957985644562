import React, { useState } from 'react';
import { Tooltip, Typography, Box, Button } from "@mui/material";
import { makeStyles, withStyles } from '@mui/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {
    InfoOutlined
} from "@mui/icons-material";


const infoColor = '#48bff7';

const useStyles = makeStyles(() => ({
    icon: {
        color: '#8460CB',
        fontSize: '0.8rem',
        cursor: 'pointer',
        minWidth: '0em'
    },
    otherIcon: {
        color: infoColor,
        fontSize: '0.8rem',
        cursor: 'pointer',
        minWidth: '0em'
    }
}));


export const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: infoColor,
        color: theme.palette.common.white,
        maxWidth: '15em',
        fontSize: '1em',
        borderRadius: '0.5em',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        padding: '1em 1.5em',
    },
    arrow: {
        "&:before": {
            border: `1px solid ${infoColor}`
        },
        color: infoColor
    },
}))(Tooltip);

const TooltipVariant = ({ title, open, iconClass, placement, handleTooltipOpen, handleTooltipClose, ...props }) => {
    return (
        <HtmlTooltip
            placement={placement || 'top-start'}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            {...props}
            title={
                <Box>
                    <Typography variant='body2'>{title}</Typography>
                </Box>
            }
        >
            <Button className={iconClass} onClick={handleTooltipOpen} variant='text'>
                <InfoOutlined />
            </Button>
        </HtmlTooltip>
    )
}

const CustomTooltip = ({ title, placement, variant = 'click', ...props }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        variant === 'click' ? <ClickAwayListener onClickAway={handleTooltipClose}>
            <Box>
                <TooltipVariant
                    title={title}
                    open={open}
                    iconClass={classes.icon}
                    placement={placement}
                    handleTooltipOpen={handleTooltipOpen}
                    handleTooltipClose={handleTooltipClose}
                    {...props}
                />
            </Box>
        </ClickAwayListener> :
            <Box onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
                <TooltipVariant
                    title={title}
                    open={open}
                    iconClass={classes.otherIcon}
                    placement={placement}
                    handleTooltipOpen={handleTooltipOpen}
                    handleTooltipClose={handleTooltipClose}
                    {...props}
                />
            </Box>
    )
}

export default CustomTooltip
