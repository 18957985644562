import React from 'react';
import {Grid, Link} from "@mui/material";
import type {Account} from "../../types/Account";

const ViewLegalAgreement = (props: {account: Account, legalAgreementFile: File, classes?: string}) => {
  const {classes, account, legalAgreementFile} = props;

  return (
    <Grid item className={classes}>
      {
        legalAgreementFile ? <Link
          href={URL.createObjectURL(legalAgreementFile)}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Uploaded Legal Agreement
        </Link> : account.supplierLegalAgreementFileName && <Link
          href={`https://s3-us-west-1.amazonaws.com/securspace-files/legal-agreements/${account.supplierLegalAgreementFileName}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Current Legal Agreement
        </Link>
      }
    </Grid>
  );
}

export default ViewLegalAgreement;
