import moment from "moment/moment";
import {DateFormats} from "../../components/constants/securspace-constants";

export const getDates: (correction: Object) => {
  originalCheckIn: string,
  originalCheckOut: string,
  revisedCheckIn: string,
  revisedCheckOut: string
} = (correction) => {
  const {locationInventoryActivityGetVO, correctActivityDate} = correction;

  if (locationInventoryActivityGetVO) {

    const {activity, otherActivity, activityDate, otherActivityDate} = locationInventoryActivityGetVO;

    const isCheckIn = activity => activity === 'CHECK_IN';
    const formatDate = date => {
      const dateMoment = moment(date);
      return dateMoment.isValid() ? dateMoment.format(DateFormats.DATE) : '-';
    }

    const originalCheckOut = !isCheckIn(activity) ? formatDate(activityDate) : !isCheckIn(otherActivity) ? formatDate(otherActivityDate) : 'N/A'

    return {
      originalCheckIn: isCheckIn(activity) ? formatDate(activityDate) : isCheckIn(otherActivity) ? formatDate(otherActivityDate) : 'N/A',
      originalCheckOut: originalCheckOut,
      revisedCheckIn: isCheckIn(activity) ? formatDate(activityDate) : isCheckIn(otherActivity) ? formatDate(otherActivityDate) : 'N/A',
      revisedCheckOut: correctActivityDate ? formatDate(correctActivityDate) : originalCheckOut,
    }
  }
}