import request from '../../../util/SuperagentUtils';


const getBookingApprovals = (
    searchParams,
    onSuccess,
    onFail
) => {

    const { supplierAccountId } = searchParams;

    const result = request.get(`/api/booking?supplierAccountId=${supplierAccountId}&approvalsOnly=true`);

    if (onSuccess && onFail) {
        result
            .then(onSuccess)
            .catch(onFail)
    } else {
        return result
    }
}

const bookingStatus = (data, onSuccess, onFail) => {
    const { status, body} = data;
    const result = request.post(`/api/booking/${status}`).send(body);

    if (onSuccess && onFail) {
        result
            .then(onSuccess)
            .catch(onFail)
    } else {
        return result
    }
}

export {
    getBookingApprovals,
    bookingStatus
}