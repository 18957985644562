import React from 'react';
import LocationManagedLocationChip from './LocationManagedLocationChip';

const RequestSpaceChip = () => {
  return <LocationManagedLocationChip label={'Request Space'}
                                      severity={'warning'}
  />
}

export default RequestSpaceChip;
