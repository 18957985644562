import React, {isValidElement} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Theme,
} from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles: (theme: Theme) => {
  backgroundGrey100: CSSStyleSheet;
} = makeStyles((theme: Theme) => ({
  backgroundGrey100: {
    backgroundColor: theme.palette.grey[100]
  }
}));

type SimpleConfirmationDialogProps = {
  body: string | JSX.Element,
  onClose: () => void,
  onConfirm: () => void,
  open: boolean,
  title: string | JSX.Element,
  titleProps?: Object,
};

const SimpleConfirmationDialog: (props: SimpleConfirmationDialogProps) => JSX.Element = (
  {
    open,
    onClose,
    onConfirm,
    title,
    body,
    titleProps = {variant: 'h6'}
  }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={'ss-confirm-title'}
      aria-describedby={'ss-confirm-description'}
    >
      <DialogTitle id={'ss-confirm-title'} {...titleProps}>{ title }</DialogTitle>
      <DialogContent id={'ss-confirm-description'}>
          { isValidElement(body) ? <>{body}</>
            : <DialogContentText>{body}</DialogContentText>
          }
      </DialogContent>
      <DialogActions className={classes.backgroundGrey100}>
        <Button color={'primary'} onClick={onClose}>
          Cancel
        </Button>
        <Button color={'primary'} type={'submit'} onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleConfirmationDialog;