import React from 'react'
import { Box, Typography, Button, Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { styles } from './styles';
import { Image } from '@securspace/securspace-ui-kit'

const BuyerInterChangeImageModal = ({
    openModal,
    handleClose,
    images
}) => {

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            sx={{
                m: 2
            }}
        >
            <Box sx={styles.modalContainer}>
                <Box>
                    <Box sx={styles.modalHeader}>
                        <Typography variant="h6" sx={{
                            fontWeight: 600
                        }}>
                            View Images
                        </Typography>
                        <Button
                            variant="text"
                            onClick={handleClose}
                            color="secondary">
                            <CloseIcon />
                        </Button>
                    </Box>

                    <Box sx={styles.imageContainer}>
                        {
                            images && images.map((item) => (
                                <Box sx={styles.image} key={item}>
                                    <Image
                                        src={item}
                                        errorImg='/app-images/not-found-image/place_holder_image.png'
                                        placeHolderImg='/app-images/loading/image-loader.gif'
                                    />
                                </Box>
                            ))
                        }
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default BuyerInterChangeImageModal
