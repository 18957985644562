const PHONE_NUMBER_REGEX = /^\d{10}$/;

const PHONE_NUMBER_WITH_COUNTRY_CODE_REGEX = /^\+[1-9][0-9]{10}$/;

const EMAIL_ADDRESS_REGEX = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
const PASSWORD_PATTERN = /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/g;

export const validatePhoneNumber = (phoneNumber, withCountryCode) => {
  if (withCountryCode) {
    return PHONE_NUMBER_WITH_COUNTRY_CODE_REGEX.test(phoneNumber);
  }
  return PHONE_NUMBER_REGEX.test(phoneNumber);
}

export const validateEmail = (email) => {
  if (email && email.endsWith("@mail.ru")) {
    return false;
  }
  return EMAIL_ADDRESS_REGEX.test(email);
}

export const validatePassword = (password) => {
  return PASSWORD_PATTERN.test(password);
};

export const parseFormattedPhoneNumber = (formattedPhoneNumber: string) => {
  return ('' + formattedPhoneNumber).replace(/\D/g, '');
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  // match country code too: 1
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[2] + ') ' + match[3] + '-' + match[4];
  }
  return phoneNumberString;
}

export const validateName: (name: string) => boolean = (name) => {
  const alphaNumericSpacesOnly = /^[a-zA-Z0-9- ]*$/;
  return name.length && name.trim().length ? alphaNumericSpacesOnly.test(name) : false;
}