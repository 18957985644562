import Request from 'superagent';
import request from '../../../util/SuperagentUtils';

export const requestLocations = () : Promise => {
  return Request.get(`/api/location`);
}

export const makeLocationInactive = (location) : Promise => {
  return Request.post(`/api/location`).send(location)
}

export const getLocationManagers = (accountId) : Promise => {
  return Request.get(`/api/account/${accountId}/location-managers`)
}

export const getLocationById = (locationId: string) : Promise => {
  return Request.get(`/api/location/suppliers/${locationId}`);
}

export const getLocationByIdWithDates = (locationId: string, startDate: string, endDate: string) : Promise => {
  return Request.get(`/api/location/suppliers/${locationId}`).query({startDate: startDate, endDate: endDate});
}

export const getLocationHasRequestedCapacityById = (locationId: string, numberOfSpaces: number, startDate: string, endDate: string): Promise => {
  return Request.get(`/api/location/suppliers/${locationId}/hasRequestedCapacity?numberOfSpaces=${numberOfSpaces}&startDate=${startDate}&endDate=${endDate}`);
};

export const getLocationSuppliers = (latitude, longitude, startDate, endDate, numberOfSpaces) : Promise => {
  return Request.get(`/api/location/suppliers?lat=${latitude}&lon=${longitude}&startDate=${startDate}&endDate=${endDate}&numberOfSpaces=${numberOfSpaces}`);
};

export const getLocationSuppliersInRadius = (latitude: string, longitude: string, mileRadius: number, startDate, endDate, numberOfSpaces): Promise => {
  return Request.get(`/api/location/suppliers?lat=${latitude}&lon=${longitude}&radius=${mileRadius}&startDate=${startDate}&endDate=${endDate}&numberOfSpaces=${numberOfSpaces}`);
};

export const updateLocationManager = (accountId, updatedUser, onSuccess?: (response:Object)=> void, 
                                      onFail?: (err: Object)=> void): (Promise | void) => {

  const result = request.put(`/api/account/${accountId}/user`).send(updatedUser)

  if (onSuccess && onFail) {
    result
    .then((resp) => onSuccess(resp.body))
    .catch((err) => onFail(err))
  } else {
    return result
  }
}
export const getRecentlyBooked = (pageNumber, size) : Promise => {

  let searchParams;

  if(pageNumber || size) {
    searchParams = new URLSearchParams();
  
    if (pageNumber) {
      searchParams.append("pageNumber", pageNumber)
    }
    if (size) {
      searchParams.append("size", size)
    }
  }

  return request.get(`/api/location/featured-locations${searchParams ? `?${searchParams}`: ""}`)
}

export const getCitiesByRank = (page, pageSize, sortBy, order) : Promise => {
  return Request.get(`/api/cities?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}`);
}

export const getByIpLocation = () : Promise => {
  return Request.get("/api/getByIpLocation");
};

export const getAllLocationsByBuyer = (buyerAccountId): Promise => {
  return Request.get(`/api/location/buyer/${buyerAccountId}`);
};

/**
 * Get all currently logged-in user's locations. Response consists of id and locationName only.
 * @returns {Promise}
 */
export const getAllLocationNames = (): Promise => {
  return request.get(`/api/location/locationName`);
}