import { Grid, Typography, Skeleton } from '@mui/material';
import React from 'react';

const LocationProfileDescription = ({ desc, loading }) => {
  return <Grid container direction='column' className='ss-profile-desc-container'>
    {loading ? <Skeleton width={"15%"} /> : <Typography className='ss-profile-desc-title' >Description</Typography>}
    {loading ? <Skeleton width={"70%"} /> : <Typography className="ss-profile-desc">{desc}</Typography>}
  </Grid>
}

export default LocationProfileDescription;
