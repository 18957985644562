import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import DownloadCsvIcon from '../components/icons/DownloadCsvIcon';
import { Box, Grid, Typography } from '@mui/material';
import { GroupSortFilter, CSVButton, Pagination } from '@securspace/securspace-ui-kit';
import useStyles from '../components/partner-subscriptions/styles';
import Busy from '../components/Busy';
import { toast } from 'react-toastify';
import { getTransactionsByPartnerSubscriptionId } from '../components/subscriptions/subscription-requests';
import PartnerSubscriptionInvoicesTable from '../components/partner-subscriptions/invoices/PartnerSubscriptionInvoicesTable';
import { formatInvoiceItem } from '../components/partner-subscriptions/formatSubscriptions';
import { SORT_DIRECTION_ASC } from '../util/ReportUtils';
import { getComparator, stableSort } from '../util/Sorting';
import NoMatchingInvoicesFound from '../components/NoMatchingInvoicesFound';
import moment from 'moment';

export function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
}

const PartnerSubscriptionInvoicesReport = ({account}) => {
    const classes = useStyles();
    
    const [transactions, setTransactions] = useState([]);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [sort, setSort] = useState('');
    const [order, setOrder] = useState(SORT_DIRECTION_ASC);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0)

    const { subscriptionId } = useParams();
    const sortByOptions = [];

    const handleSearchQueryChange = (event) => {
        setSearchFilter(event.searchFilter);
    };

    const handleSortChange = (sort, order) => {
        setSort(sort);
        setOrder(order);
    };

    const handlePageChange = (_, selectedPage) => {
        setPage(selectedPage);
        scrollToTop();
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        scrollToTop();
    };

    const visibleRows = React.useMemo(
        () =>
        stableSort(filteredTransactions, getComparator(order, sort)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        ),
        [sort, page, rowsPerPage, filteredTransactions, order],
    );

    useEffect(() => {
        if (!subscriptionId) {
            toast.error('No Partner Subscription Id Provided in URL');
        } else {
            Busy.set(true);
            getTransactionsByPartnerSubscriptionId(subscriptionId).then(({body}) => {
                if (body) {
                    const formattedInvoiceItems = body.map(item => formatInvoiceItem(item));
                    setTransactions(formattedInvoiceItems);
                }
            }).catch(() => {
            }).finally(() => Busy.set(false));
        }
    }, [subscriptionId]);

    useEffect(() => {
        let filtered = [];
    
        if (searchFilter) {
          let filterTokens = searchFilter.split(" ").map(value => value.toLocaleLowerCase());
    
          transactions.forEach((transaction) => {
            for (let token of filterTokens) {

              if (transaction.type?.toLocaleLowerCase().includes(token) ||
                transaction.status?.toLocaleLowerCase().includes(token) ||
                transaction.serviceMonth?.toLocaleLowerCase().includes(token) ||
                JSON.stringify(transaction.invoiceNumber)?.toLocaleLowerCase().includes(token)
              ) {
                filtered.push(transaction)
                break;
              }
            }
          });
    
        } else {
          filtered = transactions;
        }

        setFilteredTransactions(filtered);
    
      }, [searchFilter, transactions]);

    const reportName = `Partner Subscription Invoices_${moment().format("MM-DD-YYYY_hh:mm A")}`;

    return <Grid container flexDirection="column" className={classes.mainContainer}>
        <Typography variant={'h5'} component={'h1'}>Partner Subscription Invoices</Typography>
    
            <Grid container item justifyContent="space-between">
                <Grid item mt={4} className={classes.searchContainer}>
                    <GroupSortFilter
                        filterCallback={handleSearchQueryChange}
                        sortByOption={sortByOptions}
                        sortDir={order}
                        debounceTime={1000}
                        searchPlaceholder='Search by invoice number, status, type, or service month'
                    />
                </Grid>
                <Grid item mt={4} className={classes.csvContainer}>
                    <CSVButton
                        reportName={reportName}
                        reportData={filteredTransactions}
                        title="Download Report"
                        startIcon={<DownloadCsvIcon />}
                    />
                </Grid>
            </Grid>
            <Box mt={4}>
                {
                    visibleRows?.length ?
                        <PartnerSubscriptionInvoicesTable account={account} transactions={visibleRows} handleSortChange={handleSortChange} classes={classes} />
                    : <NoMatchingInvoicesFound/>
                }
            </Box>
                <Pagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    count={transactions?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
    </Grid>;
};

export default PartnerSubscriptionInvoicesReport;