import {useEffect, useState} from "react";
import {getErrorMessageForStandardResponse} from "../util/NetworkErrorUtil";
import { logout } from "../util/LogoutUtil";

const useGetRequestBody = (requestMethod, requestParamsArray, snackbarShowMessage) => {
  const [result, setResult] = useState([]);
    useEffect(() => {
      (async () => {
        try {
          setResult((await requestMethod(...requestParamsArray)).body);
        }
        catch(err) {
           if (response.status === 401) {
             logout();
           } else if (snackbarShowMessage) {
            snackbarShowMessage(getErrorMessageForStandardResponse(err), "error", 15000,);
          }
        }
      })();
    }, [snackbarShowMessage, requestMethod, ...requestParamsArray]);
  return result;
}

export default useGetRequestBody;