import React from 'react'
import { useStyles } from './style'
import { Grid, Typography } from '@mui/material';
import moment from 'moment'
import {Link} from 'react-router-dom';
import Image from '../../components/skeleton/ImageComponent';
import URLUtils from "../../util/URLUtils";

const BlogCard = ({
    featuredImage,
    name,
    publishDate,
    id
}) => {
    const classes = useStyles();

    const redirectPath = `/blog/${id}/${URLUtils.formatEncodePath(name)}`;

    return (
        <Link to={redirectPath} className={classes.blogMain}>
            <Image errorImg='/app-images/not-found-image/place_holder_image.png'  className={classes.blogThumbnail} src={featuredImage} alt={name} />
            <Grid className={classes.contentWrap}>
                <Typography className={classes.blogTitle}>
                    {name}
                </Typography>
                <Typography className={classes.blogSubTitle}>{moment(publishDate).format('MMMM Do YYYY')}</Typography>
            </Grid>
        </Link>
    )
}

export default BlogCard