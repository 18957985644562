import React from "react";
import { Grid, Skeleton } from "@mui/material";
import FeatureOptionItem from "./FeatureOptionItem";


const FeaturesOptionsMap = ({ features, loading }) => {

    return (
        <Grid wrap="wrap" container justifyContent="space-between" alignItems="center" className='ss-location-features-option-item-parent'>
            {loading ? [...Array(20).keys()].map((_, index) => <Grid key={index} item className='ss-location-features-option-item'>
                <Skeleton width={'90%'} height={25} variant="text" />
            </Grid>) : features?.map((feature) => <FeatureOptionItem key={feature} feature={feature} />)}
        </Grid>
    )
}

export default FeaturesOptionsMap;
