import React from 'react';
import Recaptcha from 'react-recaptcha';
import { RECAPTCHA_KEY } from '../constants/securspace-constants';
import PropTypes from 'prop-types';

const SimpleRecaptcha = (props) => {
    const { verifyCallback, expiredCallback, onloadCallback, setRef} = props;

    const handleSetRef = (ref) => {
        if (setRef) {
            setRef(ref); // e.g. ref.reset() to reset captcha
        }
    };

    return (
        <Recaptcha
            sitekey={RECAPTCHA_KEY}
            render='explicit'
            ref={handleSetRef}
            verifyCallback={verifyCallback}
            expiredCallback={expiredCallback}
            onloadCallback={onloadCallback}
        />
    );
};

SimpleRecaptcha.propTypes = {
    verifyCallback: PropTypes.func,
    expiredCallback: PropTypes.func,
    onloadCallback: PropTypes.func,
    setRef: PropTypes.func, // setter func
};

export default SimpleRecaptcha;