import moment from 'moment';
import request from '../../../util/SuperagentUtils';
import { DateFormats } from '../../constants/securspace-constants';


const BASE_URL = '/api/inventory/buyers';

const getAllBuyerInventory = (
    searchParams,
    onSuccess,
    onFail
) => {

    const { buyerAccountId, page, size, startDate, endDate, locationId, bookingNumber, sortBy, supplierName, equipmentType, equipmentNumber, driverName, sortDir } = searchParams;

    let pageParam = ["page", page || 0];
    let sizeParam = ["size", size || 10];

    let params = new URLSearchParams([pageParam, sizeParam]);

    if (startDate) {
        params.append("startDate", moment(startDate).format(DateFormats.DAY));
    }
    if (endDate) {
        params.append("endDate", moment(endDate).format(DateFormats.DAY));
    }
    if (locationId && locationId !== 'all') {
        params.append("locationId", locationId)
    }
    if (bookingNumber) {
        params.append("bookingNumber", bookingNumber)
    }
    if (driverName) {
        params.append("driverName", driverName)
    }
    if (sortBy && sortBy !== 'none') {
        params.append("sortBy", sortBy)
    }
    if (supplierName) {
        params.append("supplierName", supplierName?.trim())
    }
    if (equipmentType && equipmentType !== 'All') {
        params.append("equipmentType", equipmentType)
    }
    if (equipmentNumber) {
        params.append("equipmentNumber", equipmentNumber)
    }
    if(sortDir) {
        params.append("sortDir", sortDir)
    }

    const result = request.get(`${BASE_URL}/${buyerAccountId}?${params}`);

    if (onSuccess && onFail) {
        result
            .then(onSuccess)
            .catch(onFail)
    } else {
        return result
    }
}

const getBuyerInventoryReportUrl: (
  buyerAccountId: string,
  searchParams: {
      startDate?: string,
      endDate?: string,
      locationId?: string,
      bookingNumber?: string,
      supplierName?: string,
      driverName?: string,
      equipmentType?: string,
      equipmentNumber?: string,
      sortBy?: string,
      sortDir?: string
  }) => string = (buyerAccountId, searchParams) => {
    const { startDate, endDate, locationId, bookingNumber, supplierName, driverName, equipmentType, equipmentNumber, sortBy, sortDir } = searchParams;
    const queryParams = new URLSearchParams();
    if (startDate) queryParams.append("startDate", startDate);
    if (endDate) queryParams.append("endDate", endDate);
    if (locationId) queryParams.append("locationId", locationId);
    if (bookingNumber) queryParams.append("bookingNumber", bookingNumber);
    if (supplierName) queryParams.append("supplierName", supplierName);
    if (driverName) queryParams.append("driverName", driverName);
    if (equipmentType) queryParams.append("equipmentType", equipmentType);
    if (equipmentNumber) queryParams.append("equipmentNumber", equipmentNumber);
    if (sortBy) queryParams.append("sortBy", sortBy);
    if (sortDir) queryParams.append("sortDir", sortDir);

    return `${BASE_URL}/${buyerAccountId}/report?${queryParams}`;
}

export {
    getAllBuyerInventory,
    getBuyerInventoryReportUrl
}