import React, { useContext } from 'react';
import BaseNav from '../../routing/BaseNav';
import { Helmet } from 'react-helmet';
import { getUserBasedNavOptions } from '../../routing/nav-options';
import {determineRightNav} from '../../routing/nav/DefaultNav';
import { AppContext } from '../../context/app-context';
import './style.css';
import { mergeNavigationArray } from '../../routing/LandingNav';

const AboutNavHeader = (props) => {
    const { pendingApprovalCount, pendingInventoryCount } = props;
    const context = useContext(AppContext)
    const { user } = context
    const leftNav = getUserBasedNavOptions(user);
    const rightNav = determineRightNav(user);

    return (
        <header className='ss-about-nav-header header-background'>
            <Helmet>
                <title>About SecurSpace | Semi Truck Parking Nationwide</title>
                <meta name="keywords" content="semi truck parking" />
                <meta name="description"
                    content="SecurSpace is an online marketplace that connects companies looking for parking and storage options to those with dedicated or excess capacity." />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <BaseNav
                rightNavOptions={mergeNavigationArray(leftNav, rightNav)}
                fontColorOverride={true}
                pendingApprovalCount={pendingApprovalCount}
                pendingInventoryCount={pendingInventoryCount}
                logoUrl={"../../app-images/logo/secure-space_white-logo_WTG.svg"}
            />
        </header>
    );
};

export default AboutNavHeader;
