import React from 'react';
import {Box, Typography} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {useStylesBlogDetails} from "../../views/blog-details/style";
import URLUtils from "../../util/URLUtils";

const RelatedBlogs = ({relatedBlogs}) => {
  const classes = useStylesBlogDetails();

  const history = useNavigate();

  return (
    <Box component={'section'}>
      <Typography className={classes.relatedTitle} component={'h6'} color={'textPrimary'}>Related posts</Typography>
      <Box className={classes.relatedPost}>
        {
          relatedBlogs.length > 0 && relatedBlogs.map((item, inx) => <Box key={inx}
                                                                          onClick={() => history(`/blog/${item?.id}`)}
                                                                          className={classes.relatedPostMain}>
            <img className={classes.relatedPostImage}
                 src={item?.featuredImage ? item?.featuredImage : `/app-images/not-found-image/place_holder_image.png`}
                 alt="Related Post"/>
            <Link to={`/blog/${item?.id}/${URLUtils.formatEncodePath(item?.name)}`} className={classes.relatedPostTitle}>
              {item?.name}
            </Link>
          </Box>)
        }

      </Box>
    </Box>
  );
};

export default RelatedBlogs;