import React, {useCallback, useEffect, useRef, useState} from 'react';
import useStyles from './styles';
import {Grid, Typography} from "@mui/material";
import PayoutFilter from "../../components/supplier-ready-for-payout/PayoutFilter";
import type {Account} from "../../types/Account";
import {requestReadyForPayoutTransactionsForAccount} from "../requests/account-report";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../util/NetworkErrorUtil";
import PayoutTransactionList from "../../components/supplier-ready-for-payout/PayoutTransactionList";
import {withSnackbar} from "../../components/hocs/withSnackbar";
import PayoutReportDataSummary from "../../components/supplier-ready-for-payout/PayoutReportDataSummary";
import TotalPayout from "../../components/supplier-ready-for-payout/TotalPayout";
import InitiatePayouts from "../../components/supplier-ready-for-payout/InitiatePayouts";
import {UserType} from "../../components/constants/securspace-constants";
import useAllLocationNames from "../../hooks/components/location/useAllLocationNames";

type SupplierReadyForPayoutProps = {
  account: Account,
  snackbarShowMessage: (message: string, variant: string) => void,
};

const SupplierReadyForPayout: SupplierReadyForPayoutProps = ({account, snackbarShowMessage}) => {
  const [query: { sortBy: string, sortDir: string, locationId: string, }, setQuery] = useState({
    sortBy: 'invoiceNumber',
    sortDir: 'asc',
    locationId: '',
  });
  const [payoutTransactions, setPayoutTransactions] = useState([]);
  const [loadingPayoutTransactions, setLoadingPayoutTransactions] = useState(false);
  const [selectedLocationName, setSelectedLocationName] = useState('');
  const initialRender = useRef(true); // to prevent SortFilter from calling filterCallback on initial render

  const classes = useStyles();

  const locations = useAllLocationNames(snackbarShowMessage);

  useEffect(() => {
    if (!account?.id) return;
    setLoadingPayoutTransactions(true);
    requestReadyForPayoutTransactionsForAccount(account.id, query)
      .then((response) => {
        setPayoutTransactions(response.body);
      }).catch((error) => {
        snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error, 'Failed to fetch Payout Transactions. Please refresh the page or contact SecurSpace if this issue persists.'), 'error');
      }).finally(() => {
        setLoadingPayoutTransactions(false);
      });
  }, [account, query, snackbarShowMessage]);

  const handleFilterChange: (filter: {
    filters: any[],
    selectFilters?: any[],
    groupBy?: string,
    sortBy: string,
    startDate?: string,
    endDate?: string,
    location: Object | undefined,
    sortDir?: string,
  }) => void = useCallback((filter) => {
    if (!initialRender.current) {
      setQuery({
        sortBy: filter.sortBy,
        sortDir: filter.sortDir,
        locationId: filter.location?.id || null,
      });
      setSelectedLocationName(filter.location?.id && filter.location?.locationName ? filter.location?.locationName : '');
    } else {
      initialRender.current = false;
    }
  }, [initialRender]);

  return (
    <Grid container rowSpacing={'1rem'} className={classes.mainContainer}>
      <Grid container item xs={12} justifyContent={'space-between'}>
        <Typography variant={'h5'} component={'h1'}>Ready for Payout</Typography>
        { account.userType === UserType.ADMIN &&
          <InitiatePayouts payoutTransactions={payoutTransactions} accountId={account.id} locations={locations} />
        }
      </Grid>
      <PayoutFilter
        onFilterChange={handleFilterChange}
        locations={locations}
      />
      <PayoutReportDataSummary
        accountId={account.id}
        query={query}
        count={payoutTransactions.length}
        title={'Transactions Ready for Payout'}
      />
      <TotalPayout payoutTransactions={payoutTransactions} title={selectedLocationName} gridItemProps={{ mb: 2 }}/>
      <PayoutTransactionList payoutTransactions={payoutTransactions} loading={loadingPayoutTransactions} />
    </Grid>
  );
};

export default withSnackbar(SupplierReadyForPayout);