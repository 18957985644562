import React from 'react';
import { Box, Checkbox, TableCell, TableRow } from "@mui/material";
import CustomTooltip from './CustomTooltip';



const SupplierAdministrativeTableRow = (props) => {
  const {
    administrativeAccountOwnerRole,
    handleAdministrativeAccountOwnerRoleChange,
    administrativeGateClerkRole,
    handleAdministrativeGateClerkRoleChange,
    administrativeGateManagerRole,
    handleAdministrativeGateManagerRoleChange,
    administrativeAdminRole,
    handleAdministrativeAdminRoleChange,
    gmsPro
  } = props;

  return <>
    <TableRow>
      <TableCell>
        <Box display="flex" alignItems="center">
          {'Administrative'}
          <CustomTooltip title={'Receive emails when payments fail for Customer bookings and overages.'} />
        </Box>
      </TableCell>
      <TableCell>
        {<Checkbox
          checked={administrativeAccountOwnerRole}
          onClick={handleAdministrativeAccountOwnerRoleChange}
        />}
      </TableCell>
      {/* Need the two separate conditionals here for table formatting */}
      {
        gmsPro ?
          <TableCell>
            {<Checkbox
              checked={administrativeGateClerkRole}
              onClick={handleAdministrativeGateClerkRoleChange}
            />}
          </TableCell>
          : ""
      }
      {
        gmsPro ?
          <TableCell>
            {<Checkbox
              checked={administrativeGateManagerRole}
              onClick={handleAdministrativeGateManagerRoleChange}
            />}
          </TableCell>
          : ""
      }
      <TableCell>
        {<Checkbox
          checked={administrativeAdminRole}
          onClick={handleAdministrativeAdminRoleChange}
        />}
      </TableCell>
    </TableRow>
  </>
}

export default SupplierAdministrativeTableRow;
