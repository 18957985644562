import Busy from "../components/Busy";
import {requestAddPaymentMethod} from "../components/paymentMethods/addPaymentMethod/paymentMethodRequests";
import {getErrorMessageForNonStandardAndStandardResponse} from "./NetworkErrorUtil";

export const loadStripe = (onload) => {
  if (!window.Stripe) {
    const script = document.createElement('script');
    script.onload = function () {
      onload();
    };
    script.src = 'https://js.stripe.com/v3/';
    document.head.appendChild(script);
  } else {
    onload();
  }
};

export const loadPlaid = (onload) => {
  if (!window.Plaid) {
    const script = document.createElement('script');
    script.onload = function () {
      onload();
    };
    script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
    document.head.appendChild(script);
  } else {
    onload();
  }
};

export const loadDwolla = (onload) => {
  if (!window.dwolla) {
    const script = document.createElement('script');
    script.onload = function () {
      onload();
    };
    script.src = 'https://cdn.dwolla.com/1/dwolla.min.js';
    document.head.appendChild(script);
  } else {
    onload();
  }
};

export const loadStripeCheckout = (onload) => {
  if (!window.StripeCheckout) {
    const script = document.createElement('script');
    script.onload = function () {
      onload();
    };
    script.src = 'https://checkout.stripe.com/checkout.js';
    document.head.appendChild(script);
  } else {
    onload();
  }
};

export const loadStripeHandler = async (account, window, payWithAch, success) => {
  return window.StripeCheckout.configure({
    key: account.platformPublishedKey,
    image: "https://static.secur.space/app-images/securspace-favicon.png",
    locale: "auto",
    zipCode: true,
    name: "Add Payment Method",
    panelLabel: "Add Payment Method",
    color: 'black',
    email: account.email,
    token: (token) => {
      Busy.set(true);
      const data = JSON.stringify({
        accountId: account.id, stripeToken: token.id, paymentType: payWithAch ? "ACH" : "CARD"
      });
      requestAddPaymentMethod(data).then(() => {
        Busy.set(false);
        success();
      }).catch((error) => {
        Busy.set(false);
        getErrorMessageForNonStandardAndStandardResponse(error);
      });
    }
  });
}

export const loadPlaidHandler = async (account, window, payWithAch, success) => {
  return window.Plaid.create({
    env: account.plaidEnvironment,
    clientName: account.plaidClientName,
    key: account.plaidPublicKey,
    product: ['auth'],
    selectAccount: true,
    onSuccess: (public_token, metadata) => {
      Busy.set(true);
      const data = JSON.stringify({
        accountId: account.id, plaidPublicToken: public_token, plaidAccountId: metadata.account_id, paymentType: payWithAch ? "ACH" : "CARD"
      });
      requestAddPaymentMethod(data).then(() => {
        Busy.set(false);
        success();
      }).catch((error) => {
        Busy.set(false);
        getErrorMessageForNonStandardAndStandardResponse(error);
      });
    },
  });
}
