// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import LockIcon from '@mui/icons-material/Lock';
// import LanguageIcon from '@mui/icons-material/Language';
import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import './style.css'

const useStyles: () => {
    grid: CSSStyleSheet,
    card: CSSStyleSheet,
    iconContainer: CSSStyleSheet,
    icon: CSSStyleSheet,
    text: CSSStyleSheet
} = makeStyles({
    grid: {
        alignItems: "flex-start",
        gap: 50,
        marginTop: 20,
        marginLeft : "4.3rem",
        marginRight : "4.3rem"
    },
    card: {
        height: '32vh',
        width: '20vw'
    },
    iconContainer: {
        display: 'flex'
    },
    icon: {
        justifyContent: 'flex-start'
    },
    text: {
        paddingLeft: '10%'
    },
    flex: {
        display: "flex",
        gap: "20px",
    },
    head: {
        fontSize: 20,
        fontWeight: "700",
        marginBottom:10
    },
    p: {
        fontSize: 16,
        maxWidth: 315
    }
});

export const CardContentInfo = ({ image, title, description }) => {
    const classes = useStyles();
    return (
        <div className={classes.flex} id="content_wraper">
            <img src={image} alt="Land" style={{ width: 38, height: 38, objectfit: "cover" }} />
            <div>
                <Typography variant="h4" color="textPrimary" className={classes.head}>{title}</Typography>
                <Typography className={classes.p} variant="subtitle1" component="p" color="textPrimary">
                    {description}
                </Typography>
            </div>
        </div>
    )
}

export const CustomerText = () => {

    const contents = [
        {
            image: "https://static.secur.space/icons/security.png",
            title: "Find The Right Fit",
            description: " Search for available parking and storage space with the security, visibility and access that meets your needs."
        },
        {
            image: "https://static.secur.space/icons/calendar.png",
            title: "Flexible Arrangements",
            description: "Reserve and pay only for the capacity you need; no more long term commitments."
        },
        {
            image: "https://static.secur.space/icons/global.png",
            title: "Growing National Network",
            description: " Access a growing network of locations in every major transportation hub."
        },
    ]

    const classes = useStyles();
    return (
        <Container maxWidth="lg" className="ss-customer-text">
            <Grid
                container
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between"
                className={classes.grid}
            >
                {contents.map((item, index) => (
                    <CardContentInfo key={index} {...item} />
                ))}
            </Grid>
        </Container>
    )
}


