import React, {useMemo} from 'react';
import {Alert, Box, Collapse, Grid, Theme, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import PaymentMethodSelect from "./PaymentMethodSelect";
import {PaymentType} from "../constants/securspace-constants";
import RentalAgreementCheckbox from "../RentalAgreementCheckbox";

const useStyles: (theme: Theme) => {
  panel: CSSStyleSheet,
  link: CSSStyleSheet
} = makeStyles((theme) => ({
  panel: {
    backgroundColor: theme.palette.grey[50],
    padding: '1.35em',
    borderRadius: '0.57rem',
  },
  link: {
    fontSize: "inherit",
    color: 'rgba(63, 81, 181, 1)',
    textDecoration: 'none',
  },
}));


const UpdatePaymentMethodForm = (props) => {
  const classes = useStyles();
  const {agreeToTerms, selectedPaymentMethodId, onChange, paymentMethods} = props;

  const displayProcessingFeesWarning = useMemo(() => {
    const selectedPaymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.id === selectedPaymentMethodId);
    return selectedPaymentMethod?.type === PaymentType.CARD;
  }, [paymentMethods, selectedPaymentMethodId]);

  return (
    <Grid container direction={'column'} rowGap={'0.71rem'}>
      <Grid item>
        <Typography variant={'body1'}>Select Payment Method</Typography>
        <Box className={classes.panel}>
          <PaymentMethodSelect paymentMethods={paymentMethods} onChange={onChange} value={selectedPaymentMethodId}/>
        </Box>
        <Collapse in={displayProcessingFeesWarning} className={'w-100'}>
          <Alert severity={'info'}>
            <Typography component={'strong'}>Additional credit card processing fees apply</Typography>
          </Alert>
        </Collapse>
      </Grid>
      <Grid item>
        <Typography variant={'body1'}>
          Accept <Link to={'/terms-of-use'} target={"_blank"} className={classes.link}>Terms of Service</Link> Agreement
        </Typography>
        <Box className={classes.panel}>
          <RentalAgreementCheckbox name={"agreeToTerms"} checked={agreeToTerms} onChange={onChange} required />
        </Box>
      </Grid>
    </Grid>
  )
};

export default UpdatePaymentMethodForm;