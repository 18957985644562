import { makeStyles } from "@mui/styles";

const useStyles: () => {
  mainContainer: CSSStyleSheet,
} = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: '2.5rem',
    paddingBottom: '2.86rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '8.04rem',
      paddingRight: '8.04rem',
    }
  },

}));

export default useStyles;