import React, {useEffect, useState} from 'react';
import useRefOffsetDimensions from '../../hooks/useRefOffsetDimensions';
import {Grid, Paper, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PlacesAutoCompleteMuiItem from "./PlacesAutoCompleteMuiItem";

const useStyles: () => {
  container: CSSStyleSheet,
  margins: CSSStyleSheet
} = (width, height, left, top) => (makeStyles({
  container: {
    position: 'absolute',
    width: width,
    left: left,
    top: top + height * .75,
    boxShadow: '0 .1428em .0714em -.0714em rgb(0 0 0 / 20%), 0px .0714em .0714em 0 rgb(0 0 0 / 14%), 0 .0714em .21428em 0 rgb(0 0 0 / 12%)',
    borderRadius: '.285714em',
    color: 'rgba(0, 0, 0, 0.87)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: '#fff',
    zIndex: 10002,
  },
  margins: {
    '&:first-child': {
      marginTop: '.5em',
    },
    '&:last-child': {
      marginBottom: '1.5em',
    }
  }
}))(width, height, left, top);

const PlacesAutocompleteMui = (props) => {
  const {
    inputRef,
    inputValue,
    containerRef = inputRef,
    pacService,
    pacSession,
    pacOptions,
    onClickPac,
    handleSelectPac,
    selectedPacItem,
  } = props;
  const {width, left, top, height} = useRefOffsetDimensions(containerRef);
  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const classes = useStyles(width, height, left, top);

  useEffect(() => {
    let isRendered = true;
    const getPlacesAutocompleteSuggestions = () => {
      pacService.getPlacePredictions({
        input: inputValue,
        sessionToken: pacSession,
        ...pacOptions,
        fields: ['geometry.location']
      }, (response) => {
        if (isRendered) {
          setAutocompleteResults(response);
        }
      });
    }

    if (inputValue && pacSession && pacService) {
      getPlacesAutocompleteSuggestions();
    } else {
      setAutocompleteResults([]);
    }
    return () => {
      isRendered = false;
    }
  }, [inputValue, pacSession, pacService, pacOptions]);

  useEffect(() => {
    const ENTER = 'Enter';
    const UP = 'ArrowUp';
    const DOWN = 'ArrowDown';

    const handleUpDown = (event) => {
      if (event?.key === DOWN) {
        const pacIndex = autocompleteResults?.findIndex((x) => x.place_id === selectedPacItem.place_id);
        if (pacIndex !== -1 && pacIndex !== autocompleteResults?.length - 1) {
          handleSelectPac(autocompleteResults?.at(pacIndex + 1));
        } else {
          handleSelectPac(autocompleteResults?.at(0));
        }
      } else if (event?.key === UP) {
        const pacIndex = autocompleteResults?.findIndex((x) => x.place_id === selectedPacItem.place_id);
        handleSelectPac(autocompleteResults?.at(pacIndex - 1));
      } else if (event?.key === ENTER) {
        if (selectedPacItem?.description) {
          // if an item is selected, enter selects that item
          onClickPac(selectedPacItem);
        } else if (autocompleteResults?.length) {
          // if an item isn't selected, but there is an autocomplete result list, select first item
          const [firstPacItem] = autocompleteResults;
          onClickPac(firstPacItem);
        }
      }
    }

    window.addEventListener('keydown', handleUpDown);

    return () => {
      window.removeEventListener('keydown', handleUpDown);
    }
  }, [autocompleteResults, handleSelectPac, selectedPacItem, onClickPac]);

  const handleClickPac = (pacItem) => {
    onClickPac(pacItem);
  }

  return <Paper className={classes.container} elevation={0}>
    <Grid container direction={'row'} alignContent={'stretch'}>
      <Grid container
            item
            direction={'column'}
            alignContent={'stretch'}
            rowSpacing={1}
            className={autocompleteResults?.length > 0 ? classes.margins : ''}
      >
        {autocompleteResults?.length > 0 && <Typography variant={'overline'} style={{
          marginTop: '1.14em',
          marginRight: '2em',
          marginBottom: '0.29em',
          marginLeft: '2em',
        }}>popular searches</Typography>
        }
        {
          autocompleteResults?.map(
            (result) => <PlacesAutoCompleteMuiItem
              key={result.place_id}
              pacItem={result}
              onClick={handleClickPac}
              onSelect={handleSelectPac}
              selected={result.place_id === selectedPacItem?.place_id}
            />
          )
        }
      </Grid>
    </Grid>
  </Paper>
}

export default PlacesAutocompleteMui;
