import React, {useState} from 'react';
import type {Account} from "../types/Account";
import SimpleConfirmationDialog from "./SimpleConfirmationDialog";
import {DialogContentText, Theme, Typography} from "@mui/material";
import {removeAccountFundingSource} from "./user/request/user-requests";
import Busy from "./Busy";
import {getErrorMessageForNonStandardAndStandardResponse} from "../util/NetworkErrorUtil";
import { makeStyles } from '@mui/styles';

const useStyles: (theme: Theme) => {
  marginTop10: CSSStyleSheet,
} = makeStyles(() => ({
  marginTop10: {
    marginTop: '0.71rem',
  },
}));

const DialogBody = ({errorMsg}) => {
  const classes = useStyles();
  return <>
    <DialogContentText component={'strong'}>
      Are you sure you would like to remove this payout account? Please make sure to replace or update your bank
      account on file in order to process any payout from Securspace going forward.
    </DialogContentText>
    {
      errorMsg &&
      <Typography variant="body1" component="div" color="error" align="center" className={classes.marginTop10}>
        {errorMsg}
      </Typography>
    }
  </>
}

const RemovePartnerFundingSourceDialog = (props: {
  account: Account,
  open: boolean,
  onClose: () => void,
  handleLogout: () => void,
  onSuccess: () => void
}) => {
  const {account, open, onClose, handleLogout, onSuccess} = props;
  const [errorMsg, setErrorMsg] = useState('');

  const resetDialog = () => {
    setErrorMsg('');
  }

  const handleClose = () => {
    resetDialog();
    onClose();
  };

  const handleConfirm = (event) => {
    event.preventDefault();
    const handleSuccess = (response) => {
      Busy.set(false);
      resetDialog();
      onSuccess(response);
    };
    const handleError = (error) => {
      Busy.set(false);
      if (error.status === 401) {
        handleLogout();
      } else {
        setErrorMsg(getErrorMessageForNonStandardAndStandardResponse(error));
      }
    };
    Busy.set(true);
    removeAccountFundingSource(account.id, handleSuccess, handleError);
  };

  return (
    <SimpleConfirmationDialog
      onClose={handleClose}
      onConfirm={handleConfirm}
      open={open}
      title="Remove Payout Account"
      body={<DialogBody errorMsg={errorMsg} />}
    />
  );
};

export default RemovePartnerFundingSourceDialog;