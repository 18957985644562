import React, {useEffect, useState} from 'react'
import {withSnackbar} from "../../components/hocs/withSnackbar";
import {Box, Typography} from "@mui/material";
import useStyles from './styles';
import {getErrorMessageForNonStandardAndStandardResponse} from '../../util/NetworkErrorUtil';
import {
  updateInventoryCorrection,
  getBookingOrderNumber,
  moveAssetToDifferentBooking
} from '../../components/inventory-correction/requests/inventory-correction-requests';
import {GroupSortFilter} from '@securspace/securspace-ui-kit'
import InventoryModal from '../../components/inventory-correction/InventoryModal';
import {AccountType} from '../../components/constants/securspace-constants';
import InventoryCorrectionsTable from "../../components/inventory-correction/InventoryCorrectionsTable";
import DeleteInterchangeConfirmationDialog
  from "../../components/inventory-correction/DeleteInterchangeConfirmationDialog";
import ReviewModal from '../../components/inventory-correction/ReviewModal';
import {
  InventoryCorrectionReasons,
} from "../../components/constants/inventory-correction-constants";
import {getComparator, stableSort} from "../../util/Sorting";
import type {Account} from "../../types/Account";
import {FC} from "react";

type InventoryCorrectionsProps = {
  // Event called to fetch a new set of inventory corrections. This function is called whenever an action is performed on an inventory correction.
  fetchInventoryCorrections: () => void,
  // List of formatted inventory corrections to be displayed
  inventoryCorrections?: any[],
  // List of unformatted inventory corrections. This list is used to get the original inventory correction data for review.
  rawCorrections?: any[],
  // List of options to sort the inventory corrections by
  sortByOptions?: { value: string, label: string }[],
  // The account of the user
  account: Account,
  // Function to log the current user out. Called in case of a 401 api response
  handleLogout: () => void,
  // Function to show a snackbar message
  snackbarShowMessage: (message: string, variant: string) => void
};

const modalTypeOptions = {REVIEW: "REVIEW", ACCEPT: "ACCEPT", REJECT: "REJECT"}

const InventoryCorrections: FC<InventoryCorrectionsProps> = ({
                                fetchInventoryCorrections,
                                inventoryCorrections = [],
                                rawCorrections = [],
                                sortByOptions = [],
                                account,
                                handleLogout,
                                defaultSearchFilter,
                                snackbarShowMessage
                              }: InventoryCorrectionsProps) => {
  const classes = useStyles();
  const [rejectReason, setRejectReason] = useState('');
  const [errorMessages, setErrorMessages] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [selectInventory, setSelectInventory] = useState(null);
  const [inventoryToReview, setInventoryToReview] = useState(null);
  const [inventoryCorrectionsWithFilter, setInventoryCorrectionsWithFilter] = useState([]);
  const [searchFilter: {
    searchFilter: string,
    sortBy: string,
    sortDir: string
  }, setSearchFilter] = useState(defaultSearchFilter);
  const [modalType, setModalType] = useState(null);
  const [error, setError] = useState(false);
  const [showDeleteInterchangeConfirmationDialog: boolean, setShowDeleteInterchangeConfirmationDialog] = useState(false);
  const isSupplier = account.type === AccountType.SUPPLIER;

  const handleOpen = () => setModalType(modalTypeOptions.REVIEW);

  const handleClose = () => {
    setModalType(null);
    setErrorMessages('');
    setError(false);
    setRejectReason('');
    setOrderNumber('');
  }

  const handleUnknownBookingNumberClick = () => {
    handleClose();
    setShowDeleteInterchangeConfirmationDialog(true);
  };

  const handleCloseDeleteInterchangeConfirmationDialog = () => {
    setSelectInventory(null);
    setShowDeleteInterchangeConfirmationDialog(false);
  };

  const handleDeleteInterchangeSuccess = () => {
    handleCloseDeleteInterchangeConfirmationDialog();
    fetchInventoryCorrections();
  };

  const updateInventory = (inventoryId, data) => {
    updateInventoryCorrection(inventoryId, data)
      .then(() => {
        snackbarShowMessage('Inventory Corrections Updated successfully', 'success');
        handleClose();
        setRejectReason('');
        fetchInventoryCorrections();
      })
      .catch((error) => {
        snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error')
      })
  };

  const filterCallback = (filter: { searchFilter?: string, groupBy?: string, sortBy?: string, sortDir?: string }) => {
    setSearchFilter(filter);
  };

  useEffect(() => {
    let filteredData = searchFilter.searchFilter ? inventoryCorrections.filter((item) =>
      item.buyerName?.toLowerCase().includes(searchFilter?.searchFilter?.toLowerCase()) ||
      item.orderNumber?.toLowerCase().includes(searchFilter?.searchFilter?.toLowerCase())
    ) : inventoryCorrections;

    // Sort by the selected option
    if (searchFilter.sortBy && searchFilter.sortBy !== 'none') {
      filteredData = stableSort(filteredData, getComparator(searchFilter.sortDir, searchFilter.sortBy));
    }
    setInventoryCorrectionsWithFilter(filteredData);
  }, [inventoryCorrections, searchFilter]);

  const rejectInventoryCorrection = () => {
    if (!rejectReason) {
      setErrorMessages('Please enter the reason for rejection');
      setError(true);
      return;
    }
    const inventoryId = selectInventory.item.id;
    const data = {
      status: "REJECTED",
      rejectionReason: rejectReason
    }
    updateInventory(inventoryId, data);
    setError(false);
    setErrorMessages('');

  };

  const acceptInventoryCorrection = () => {
    const inventoryId = selectInventory.item.id;
    const data = {
      status: "APPROVED",
      rejectReason: ""
    }
    if (selectInventory?.item?.reason === InventoryCorrectionReasons.ASSET_DOES_NOT_BELONG_TO_BUYER) {
      handleOpen();
    } else {
      updateInventory(inventoryId, data);
    }
  };

  const reviewInventoryCorrection = (item) => {
    const rawInventoryCorrection = rawCorrections.find(correction => correction.id === item.item.id);
    setInventoryToReview(rawInventoryCorrection)
    setSelectInventory(item)
    handleOpen()
  };

  const handleSelectRejectInventoryCorrection = () => {
    setModalType(modalTypeOptions.REJECT)
  }

  const handleSelectAcceptInventoryCorrectionNotBelongToBuyer = () => {
    setModalType(modalTypeOptions.ACCEPT)
  }

  const validateBookingNumber = () => {
    getBookingOrderNumber(orderNumber)
      .then(() => {
        setErrorMessages('');
        setError(false);
        updateInventoryWithBookingNumber()
      })
      .catch(() => {
        setErrorMessages('The booking entered is invalid.')
        setError(true);
      })
  };

  const updateInventoryWithBookingNumber = () => {
    const inventoryActivityId = selectInventory.item.locationInventoryActivityGetVO.id;
    moveAssetToDifferentBooking(inventoryActivityId, orderNumber)
      .then(() => {
        snackbarShowMessage('Booking moved successfully', 'success');
        handleClose();
        setOrderNumber('');
        fetchInventoryCorrections();
      })
      .catch((error) => {
        snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error')
      })
  };

  return (
    <Box component='section' className={classes.mainContainer}>
      <Typography variant={'h5'} component={'h1'}>Inventory Corrections</Typography>
      <Box mt={4}>
        <GroupSortFilter
          filterCallback={filterCallback}
          sortByOption={sortByOptions}
          sortDir={searchFilter.sortDir}
          searchPlaceholder={`Type to search by booking number or ${account.type === AccountType.BUYER ? 'supplier' : 'buyer'} name`}
          debounceTime={1000}
        />
      </Box>
      <InventoryCorrectionsTable
        inventoryCorrections={inventoryCorrectionsWithFilter}
        handleReviewItem={reviewInventoryCorrection}
        isSupplier={isSupplier}
      />
      <ReviewModal
        inventoryCorrection={inventoryToReview}
        modalType={modalType}
        handleClose={handleClose}
        classes={classes}
        showNewBookingNumberForm={handleSelectAcceptInventoryCorrectionNotBelongToBuyer}
        handleAccept={acceptInventoryCorrection}
        handleReject={handleSelectRejectInventoryCorrection}
        openModal={modalType === modalTypeOptions.REVIEW}
        isSupplier={isSupplier}
      />
      <InventoryModal
        correctionReason={selectInventory?.item?.reason}
        onDeleteInterchangeActivity={handleUnknownBookingNumberClick}
        type={modalType}
        error={error}
        handleClose={handleClose}
        classes={classes}
        rejectReason={rejectReason}
        setRejectReason={setRejectReason}
        orderNumber={orderNumber}
        setOrderNumber={setOrderNumber}
        handleSubmit={modalType === modalTypeOptions.ACCEPT ? validateBookingNumber : rejectInventoryCorrection}
        errorMessages={errorMessages}
        modalTypeOptions={modalTypeOptions}
        handleReject={rejectInventoryCorrection}
      />
      <DeleteInterchangeConfirmationDialog
        inventoryActivity={selectInventory?.item}
        handleLogout={handleLogout}
        onClose={handleCloseDeleteInterchangeConfirmationDialog}
        onSuccess={handleDeleteInterchangeSuccess}
        open={showDeleteInterchangeConfirmationDialog}
      />
    </Box>
  )
}

export default withSnackbar(InventoryCorrections)
