import React, {useEffect} from 'react';
import {FormControl, Theme} from "@mui/material";
import classNames from "classnames";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from '@mui/styles';

const useStyles: (theme: Theme) => {
  colGap10: CSSStyleSheet,
  appInput: CSSStyleSheet,
  backgroundGrey100: CSSStyleSheet,
  rowGap10: CSSStyleSheet,
} = makeStyles((theme) => ({
  colGap10: {
    columnGap: '0.71rem',
  },
  appInput: {
    marginBottom: '0.6rem',
    '& label': {
      fontSize: '1.14em',
      fontWeight: 400,
      fontFamily: 'Inter',
      lineHeight: '0.71em',
      letterSpacing: '.05em',
      color: theme.palette.text.disabled,
    }
  },
  backgroundGrey100: {
    backgroundColor: theme.palette.grey["100"],
  },
  rowGap10: {
    rowGap: '0.71rem',
  },
}));

const UserLocationSelect = (props: {
  locations: Object[],
  selectedLocationIds: string[],
  handleLocationIdsChange: (value: string[]) => void
}) => {
  const { locations = [], selectedLocationIds, handleLocationIdsChange } = props;
  const classes = useStyles();

  useEffect(() => {
    if (!selectedLocationIds?.length) {
      handleLocationIdsChange(['all']);
    }
  }, [selectedLocationIds]);

  const onSelectChange = (event) => {
    const newSetIncludesAll = event.target.value.includes('all');
    const currentSetIncludesAll = selectedLocationIds.includes('all');

    if (!event.target.value?.length || (!currentSetIncludesAll && newSetIncludesAll)) {
      handleLocationIdsChange(['all']);
    } else if (currentSetIncludesAll && newSetIncludesAll && event.target.value.length > 1) {
      handleLocationIdsChange(event.target.value.filter(id => id !== 'all'));
    } else {
      handleLocationIdsChange(event.target.value);
    }
  };

  return <FormControl variant='standard' className={classNames("w-100", classes.appInput)} required>
    <InputLabel htmlFor={"create-edit-user-select-role"}>{"Select Location"}</InputLabel>
    <Select
      id={"create-edit-user-select-role"}
      value={selectedLocationIds || ['all']}
      onChange={onSelectChange}
      placeholder={"Location"}
      multiple={true}
    >
      <MenuItem value={'all'}>All</MenuItem>
      {
        locations?.length > 0 && locations.map(location => (
          <MenuItem value={location.id} key={location?.id}>
            {location.addressLine1}
          </MenuItem>
        ))
      }
    </Select>
  </FormControl>
};

export default UserLocationSelect;
