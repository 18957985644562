import React from 'react';
import InvoiceListGroupItem from "./InvoiceListGroupItem";
import CostBreakdownListGroupItem from "./CostBreakdownListGroupItem";
import { Box } from '@mui/material';

const CostBreakdownContent = (props) => {
  const {bookingTransaction} = props;
  return <>
    <h3 className={'ss-title-no-image'}>COST BREAKDOWN</h3>
    <Box>
      {
        bookingTransaction?.invoiceItems?.length && bookingTransaction.invoiceItems.map(invoiceItem => <InvoiceListGroupItem key={invoiceItem?.itemNumber} invoiceItem={invoiceItem}/>)
      }
      <CostBreakdownListGroupItem title={'Total (USD)'}
                                  amount={bookingTransaction?.buyerTransactionAmount}
      />
    </Box>
  </>
}

export default CostBreakdownContent;
