import React, {useEffect, useState} from 'react';
import SolidButton from "./form/SolidButton";
import  PhoneInput from 'react-phone-number-input/input';
import { Alert } from '@mui/material';
import { Box, Snackbar } from '@mui/material';
import {  makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    copyToClipBoard: {
        '&:hover': {
            cursor: 'pointer',
        }
    },
    locationManagerContent: {
        marginTop: ".5em",
        marginBottom: ".5em"
    }
}));

const AddLocationManagerInfo = (props) => {
    const {
        updatedManagerInfo,
        setUpdatedManagerInfo,
        submit,
        showAllUpdateFields,
        showUpdateButton,
    } = props;

    const [showTextOptInMessage, setShowTextOptInMessage] = useState(false);
    const [showEmailCopiedAlert, setShowEmailCopiedAlert] = useState(false);
    const classes = useStyles();

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setUpdatedManagerInfo((prevManagerInfo) => ({...prevManagerInfo, [name]: type !=='checkbox' ? value : checked}))
    }

    const handlePhoneNumberChange = (value) => {
        setUpdatedManagerInfo((prevManagerInfo) => ({...prevManagerInfo, phoneNumber: value}));
    }

    const handleEmailClick = () => {
        window.navigator.clipboard.writeText(updatedManagerInfo.username);
        setShowEmailCopiedAlert(true);
    };

    useEffect(() => {
        if (updatedManagerInfo?.isOptedIntoText) {
            setShowTextOptInMessage(true)
        } else {
            setShowTextOptInMessage(false)
        }
    }, [updatedManagerInfo])

    return (
      <>
          {
            showAllUpdateFields && <>
                <fieldset className={'ss-top hs-field'}>
                    <label htmlFor="updateLocationManagerFirstName">LOCATION MANAGER FIRST NAME</label>
                    <input type="text"
                           id="updateLocationManagerFirstName"
                           name="firstName"
                           value={updatedManagerInfo?.firstName || ''}
                           onChange={handleChange}
                           placeholder="Enter First Name"
                    />
                </fieldset>
                <fieldset className="hs-field">
                    <label htmlFor="updateLocationManagerLastName">LOCATION MANAGER LAST NAME</label>
                    <input type="text"
                           id="updateLocationManagerLastName"
                           name="lastName"
                           value={updatedManagerInfo?.lastName || ''}
                           onChange={handleChange}
                           placeholder="Enter Last Name"
                    />
                </fieldset>
                <fieldset className="hs-field">
                    <label htmlFor="locationManagerPhoneNumber">LOCATION MANAGER PHONE NUMBER</label>
                    <PhoneInput
                      id="locationManagerPhoneNumber"
                      placeholder="Enter phone number"
                      defaultCountry="US"
                      name="phoneNumber"
                      value={updatedManagerInfo?.phoneNumber || ''}
                      maxLength={14}
                      onChange={handlePhoneNumberChange}
                    />
                </fieldset>
                <fieldset className="hs-field">
                    <label htmlFor="locationManagerEmail">LOCATION MANAGER EMAIL</label>
                    <input
                      id="locationManagerEmail"
                      className={classes.copyToClipBoard}
                      name="email"
                      value={updatedManagerInfo?.username || ''}
                      readOnly
                      onClick={handleEmailClick}
                    />
                </fieldset>
            </>
          }
          {
              showTextOptInMessage ?
                <Box mb={2.2}>
                    <Alert severity="warning">
                        <p className={classes.locationManagerContent}>
                            Checking this box acknowledges that the location manager will be signed up to receive text message notifications for booking requests.
                        </p>
                        <p className={classes.locationManagerContent}>
                            The user will receive an initial message in which they can approve or decline this feature.
                        </p>
                        <p className={classes.locationManagerContent}>
                            If the user opts out, then to opt in again user must text 1-855-997-1771 with the word 'subscribe'.
                        </p>
                    </Alert>
                </Box>
                :
                ""
          }
              <label className={'ss-checkbox'}>
                  <input type={'checkbox'}
                         name={'isOptedIntoText'}
                         className={'ss-checkbox-container-checkbox'}
                         checked={!!updatedManagerInfo?.isOptedIntoText}
                         onChange={handleChange}
                  />
                  <span>Text Opt In</span>
              </label>
          {
            showUpdateButton && <SolidButton label={'Update'} className={'w-100'} onClick={submit}/>
          }
        <Snackbar
            open={showEmailCopiedAlert}
            autoHideDuration={4000}
            onClose={() => setShowEmailCopiedAlert(false)}
        >
            <Alert severity="info">Email copied to clipboard</Alert>
        </Snackbar>
      </>
    )
}

export default AddLocationManagerInfo;
