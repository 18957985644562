import React from 'react';
import {Collapse, TextField, Grid} from "@mui/material";
import { makeStyles } from '@mui/styles';
import PropTypes from "prop-types";
import {Alert} from "@mui/material";
import {Theme} from "@mui/material";

const useStyles: (theme: Theme) => {
  marginTop10: CSSStyleSheet,
} = makeStyles(() => ({
  marginTop10: {
    marginTop: '1.2em',
  }
}));

export const CollapsibleMinSpaceOverrideAlert = (props: { in: boolean, minimumSpacesRequired: number }) => {
  const {in: show, minimumSpacesRequired} = props;

  return <Collapse in={show} className={"w-100"}>
    <Alert severity={"info"}>
      {`This location requires at least ${minimumSpacesRequired} ${minimumSpacesRequired === 1 ? "space" : "spaces"} to book. You are overriding this minimum.`}
    </Alert>
  </Collapse>;
};

const BookingNumberOfSpacesTextField = ({
                                          value,
                                          onChange,
                                          minimumSpacesRequired = 1,
                                          overrideMinimum = false,
                                          prePendedContent,
                                          postPendedContent,
                                          hideOverrideNotice = false,
                                          ...otherProps
                                        }) => {
  const classes = useStyles();

  const intValue = Number.parseInt(value, 10);
  const isValid = overrideMinimum ? intValue >= 0 : intValue >= minimumSpacesRequired;
  let errorMsg = '';
  if (!isValid) {
    errorMsg = overrideMinimum ?
      'A booking requires at least 0 spaces to process.' :
      `This location requires a minimum of ${minimumSpacesRequired} ${minimumSpacesRequired > 1 ? 'spaces' : 'space'} to book.`;
  }

  const displayOverrideWarning = overrideMinimum && intValue < minimumSpacesRequired;

  const handleSpaceChange = (event) => {
    const value = event.target.value;
    if (value === '') {
      onChange(value);
    } else {
      const intValue = Number.parseInt(value);
      if (intValue >= 0) {
        onChange(intValue);
      }
    }
  };

  return (
    <Grid container>
      <Grid item container xs={12} wrap={'nowrap'} alignItems={'center'}>
        {prePendedContent && <Grid item>{prePendedContent}</Grid>}
        <TextField
          value={value}
          onChange={handleSpaceChange}
          error={!isValid}
          helperText={errorMsg}
          variant='standard'
          type={'number'}
          {...otherProps}
        />
        {postPendedContent && <Grid item>{postPendedContent}</Grid>}
      </Grid>
      {
        !hideOverrideNotice && <Grid item xs={12} className={displayOverrideWarning ? classes.marginTop10 : ''}>
          <CollapsibleMinSpaceOverrideAlert in={displayOverrideWarning} minimumSpacesRequired={minimumSpacesRequired}/>
        </Grid>
      }
    </Grid>
  );
};

BookingNumberOfSpacesTextField.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  minimumSpacesRequired: PropTypes.number,
  overrideMinimum: PropTypes.bool,
  prependedContent: PropTypes.any,
  postPendedContent: PropTypes.any,
  hideOverrideNotice: PropTypes.bool,
};

export default BookingNumberOfSpacesTextField;
