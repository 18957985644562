export const styles ={
    rightAlignment: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    itemsAlign:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        gap:'1em',
        mt:2,
        mb:4,
        flexWrap:'wrap'
    },
}