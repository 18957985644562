import React, {forwardRef} from 'react';
import {TextField} from "@mui/material";
import TextFieldWithDirtyValidation from "./TextFieldWithDirtyValidation";

const PhoneNumberTextField = forwardRef(
  (props, ref) => {
    const {label, name, placeholder, ...otherProps} = props;
    return (
      <TextField
        {...otherProps}
        inputRef={ref}
        label={label || "Phone Number"}
        name={name || "phoneNumber"}
        placeholder={placeholder || "Enter your phone number"}
        type="tel"
      />
    );
});

const PhoneNumberTextFieldWithDirtyValidation = forwardRef(
  (props, ref) => {
    const {label, name, placeholder, ...otherProps} = props;
    return (
      <TextFieldWithDirtyValidation
        {...otherProps}
        inputRef={ref}
        label={label || "Phone Number"}
        name={name || "phoneNumber"}
        placeholder={placeholder || "Enter your phone number"}
        type="tel"
      />
  );
});

export {
  PhoneNumberTextField,
  PhoneNumberTextFieldWithDirtyValidation
};