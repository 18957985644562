import React from 'react';
import { Box, Typography } from "@mui/material";
import { useStyles } from "./styles/authorizationStyle";

const AuthorizationCard = ({ payWithAch }) => {

  const classes = useStyles();

  return <Box className={classes.cardContainer}>
    {!payWithAch && <Typography className={classes.title}>
      Additional credit card processing fee applies
    </Typography>}
    <Box className={classes.cardContent}>
      <Typography variant='subtitle2' color='textPrimary'>
        Authorization Agreement
      </Typography>
      <Typography className={classes.cardSubtitle}>
        I agree that all future payments to or facilitated by SecurSpace will be processed by
        the Dwolla or Stripe payment systems from the account I select for this payment method. In order
        to cancel this authorization, I will change my payment settings within my SecurSpace account.
      </Typography>
    </Box>
  </Box>
}

export default AuthorizationCard;
