import React, {useEffect, useState} from 'react';
import {Grid} from '@mui/material';
import LocationFeatureListItem from './LocationFeatureListItem';

const LocationFeatureList = (props) => {
  const {features} = props;
  const [topFeatures, setTopFeatures] = useState([]);

  useEffect(() => {
    if (features?.length > 0) {
      if (features.length > 2) {
        setTopFeatures([...features.slice(0, 3)]);
      } else {
        setTopFeatures([...features]);
      }
    } else {
      setTopFeatures([]);
    }
  }, [features]);

  return <Grid container direction={'column'} className={"ss-profile-feature-list"} spacing={1}>
    {
      topFeatures?.map(feature => <LocationFeatureListItem key={feature}
                                                           feature={feature}
        />
      )
    }
  </Grid>
}

export default LocationFeatureList;
