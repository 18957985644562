import React, {useState, useEffect} from "react";
import { Grid, Typography, Divider } from "@mui/material";
import { inventoryCorrectionReasons } from "../constants/securspace-constants";

const fieldTypes = {DATE: "DATE", ASSET: "ASSET", DRIVER: "DRIVER"}

const CorrectionComparison = ({inventoryCorrection, classes, getDates}) => {

    const {locationInventoryActivityGetVO, reason, driverFirstName, driverLastName, driverLicenseNumber} = inventoryCorrection;

    const [revisedDateFields, setRevisedDateFields] = useState({   
            checkInDate: getDates().revisedCheckIn, 
            checkOutDate: getDates().revisedCheckOut
        });
    const [revisedAssetFields, setRevisedAssetFields] = useState({
            containerNumber: inventoryCorrection?.containerNumber, 
            chassisNumber: inventoryCorrection?.chassisNumber, 
            truckLicensePlateNumber: inventoryCorrection?.truckLicensePlateNumber, 
            trailerNumber: inventoryCorrection?.trailerNumber
        });

    const [revisedDriverFields, setRevisedDriverFields] = useState({
            firstName: inventoryCorrection?.driverFirstName, 
            lastName: inventoryCorrection?.driverLastName, 
            licenseNumber: inventoryCorrection?.driverLicenseNumber, 
        });

    const [displayFormat] = useState(reason != inventoryCorrectionReasons.ASSET_DOES_NOT_BELONG_TO_BUYER ? {
            showRevisionColumn: true,
            dataColClass: classes.correctionDataColSingle,
            revisionColClass: classes.correctionDataColSingle
        } : {
            showRevisionColumn: false,
            dataColClass: classes.correctionDataColDouble,
            revisionColClass: classes.hidden
        });

    const showRevisedField = (fieldType) => {
        if (reason == inventoryCorrectionReasons.ASSET_DOES_NOT_BELONG_TO_BUYER)
            return false;

        switch(fieldType){
            case fieldTypes.DATE: 
                return reason === inventoryCorrectionReasons.ASSET_CHECKED_OUT_ON_WRONG_DATE || reason === inventoryCorrectionReasons.ASSET_LEFT_THE_YARD;

            case fieldTypes.ASSET: 
                return reason === inventoryCorrectionReasons.ASSET_NUMBER_ENTERED_INCORRECTLY;
        
            case fieldTypes.DRIVER: 
                return Boolean(driverFirstName) || Boolean(driverLastName) || Boolean(driverLicenseNumber);
        
        }
    } 

    const nullSafeField = (item) => {
        return item ? item : "-"
    }

    const getClassName = (field1, field2, type, original) => {
        if(showRevisedField(type) && nullSafeField(field1) !== nullSafeField(field2)) {
            return original ? classes.originalField : classes.correctedField;
        }
    }

    useEffect(() => {

        if (reason === inventoryCorrectionReasons.ASSET_CHECKED_OUT_ON_WRONG_DATE || reason === inventoryCorrectionReasons.ASSET_LEFT_THE_YARD) {
            setRevisedAssetFields({
                containerNumber: locationInventoryActivityGetVO?.containerNumber, 
                chassisNumber: locationInventoryActivityGetVO?.chassisNumber, 
                truckLicensePlateNumber: locationInventoryActivityGetVO?.truckLicensePlateNumber, 
                trailerNumber: locationInventoryActivityGetVO?.trailerNumber})
        } else if (reason === inventoryCorrectionReasons.ASSET_NUMBER_ENTERED_INCORRECTLY) {
            setRevisedDateFields({
                checkInDate: getDates().originalCheckIn, 
                checkOutDate: getDates().originalCheckOut})
        }

        if((!driverFirstName && !driverLastName && !driverLicenseNumber)) {
            setRevisedDriverFields({
                firstName: locationInventoryActivityGetVO?.driverFirstName, 
                lastName: locationInventoryActivityGetVO?.driverLastName, 
                licenseNumber: locationInventoryActivityGetVO?.driverLicenseNumber, 
            })
        } 
        
    }, [inventoryCorrection])

    return <Grid container item rowSpacing={1}>
        <Grid container item>
            <Grid item className={classes.correctionHeaderCol}>
                <Grid container item rowSpacing={1} className={classes.correctionDisplayContainer} direction="column">

                    <Divider/>
                    <Grid item >
                        <Typography variant="body1">Date</Typography>
                    </Grid>
                    <Divider/>

                    <Grid item >
                        <Typography variant="subtitle2">Check In Date</Typography>
                    </Grid>
                    <Grid item >
                        <Typography variant="subtitle2">Check Out Date</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={displayFormat.dataColClass} >
                <Grid container item rowSpacing={1} className={classes.correctionDisplayContainer} direction="column">

                    <Divider/>
                    <Grid item >
                        <Typography variant="body1">Original</Typography>
                    </Grid>
                    <Divider/>

                    <Grid item>
                        <Typography variant="subtitle2" className={getClassName(getDates().originalCheckIn, getDates().revisedCheckIn, fieldTypes.DATE, true)} >
                            {nullSafeField(getDates().originalCheckIn)}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography variant="subtitle2" className={getClassName(getDates().originalCheckOut, getDates().revisedCheckIn, fieldTypes.DATE, true)} >
                            {nullSafeField(getDates().originalCheckOut)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={displayFormat.revisionColClass} >
                <Grid container item rowSpacing={1} className={classes.correctionDisplayContainer} direction="column">

                    <Divider/>
                    <Grid item >
                        <Typography variant="body1">Buyer's Revision</Typography>
                    </Grid>
                    <Divider/>

                    <Grid item>
                        <Typography variant="subtitle2" className={getClassName(getDates().originalCheckIn, getDates().revisedCheckIn, fieldTypes.DATE, false)} >
                            {nullSafeField(revisedDateFields.checkInDate)}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography variant="subtitle2" className={getClassName(getDates().originalCheckOut, getDates().revisedCheckOut, fieldTypes.DATE, false)} >
                            {nullSafeField(revisedDateFields.checkOutDate)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container item>
            <Grid item className={classes.correctionHeaderCol}>
                <Grid container item rowSpacing={1} className={classes.correctionDisplayContainer} direction="column">

                    <Divider/>
                    <Grid item >
                        <Typography variant="body1">Asset Number</Typography>
                    </Grid>
                    <Divider/>

                    <Grid item >
                        <Typography variant="subtitle2">Container</Typography>
                    </Grid>
                    <Grid item >
                        <Typography variant="subtitle2">Chassis</Typography>
                    </Grid>
                    <Grid item >
                        <Typography variant="subtitle2">Trailer</Typography>
                    </Grid>
                    <Grid item >
                        <Typography variant="subtitle2">Truck License</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={displayFormat.dataColClass} >
                <Grid container item rowSpacing={1} className={classes.correctionDisplayContainer} direction="column">

                    <Divider/>
                    <Grid item >
                        <Typography variant="body1">Original</Typography>
                    </Grid>
                    <Divider/>

                    <Grid item >
                        <Typography variant="subtitle2" className={getClassName(locationInventoryActivityGetVO?.containerNumber, inventoryCorrection?.containerNumber, fieldTypes.ASSET, true)} >
                            {nullSafeField(locationInventoryActivityGetVO?.containerNumber)}
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Typography variant="subtitle2" className={getClassName(locationInventoryActivityGetVO?.chassisNumber, inventoryCorrection?.chassisNumber, fieldTypes.ASSET, true)} >
                            {nullSafeField(locationInventoryActivityGetVO?.chassisNumber)}
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Typography variant="subtitle2" className={getClassName(locationInventoryActivityGetVO?.trailerNumber, inventoryCorrection?.trailerNumber, fieldTypes.ASSET, true)} >
                            {nullSafeField(locationInventoryActivityGetVO?.trailerNumber)}
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Typography variant="subtitle2" className={getClassName(locationInventoryActivityGetVO?.truckLicensePlateNumber, inventoryCorrection?.truckLicensePlateNumber, fieldTypes.ASSET, true)} >
                            {nullSafeField(locationInventoryActivityGetVO?.truckLicensePlateNumber)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={displayFormat.revisionColClass} >
                <Grid container item rowSpacing={1} className={classes.correctionDisplayContainer} direction="column">

                    <Divider/>
                    <Grid item >
                        <Typography variant="body1">Buyer's Revision</Typography>
                    </Grid>
                    <Divider/>

                    <Grid item>
                        <Typography variant="subtitle2" className={getClassName(locationInventoryActivityGetVO?.containerNumber, inventoryCorrection?.containerNumber, fieldTypes.ASSET, false)} >
                            {nullSafeField(revisedAssetFields.containerNumber)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" className={getClassName(locationInventoryActivityGetVO?.chassisNumber, inventoryCorrection?.chassisNumber, fieldTypes.ASSET, false)} >
                            {nullSafeField(revisedAssetFields.chassisNumber)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" className={getClassName(locationInventoryActivityGetVO?.trailerNumber, inventoryCorrection?.trailerNumber, fieldTypes.ASSET, false)} >
                            {nullSafeField(revisedAssetFields.trailerNumber)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" className={getClassName(locationInventoryActivityGetVO?.truckLicensePlateNumber, inventoryCorrection?.truckLicensePlateNumber, fieldTypes.ASSET, false)} >
                            {nullSafeField(revisedAssetFields.truckLicensePlateNumber)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <Grid container item>
            <Grid item className={classes.correctionHeaderCol}>
                <Grid container item rowSpacing={1} className={classes.correctionDisplayContainer} direction="column">

                    <Divider/>
                    <Grid item >
                        <Typography variant="body1">Driver</Typography>
                    </Grid>
                    <Divider/>

                    <Grid item >
                        <Typography variant="subtitle2">First Name</Typography>
                    </Grid>
                    <Grid item >
                        <Typography variant="subtitle2">Last Name</Typography>
                    </Grid>
                    <Grid item >
                        <Typography variant="subtitle2">License Number</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={displayFormat.dataColClass} >
                <Grid container item rowSpacing={1} className={classes.correctionDisplayContainer} direction="column">
                    
                    <Divider/>
                    <Grid item >
                        <Typography variant="body1">Original</Typography>
                    </Grid>
                    <Divider/>

                    <Grid item >
                        <Typography variant="subtitle2" className={getClassName(locationInventoryActivityGetVO?.driverFirstName, inventoryCorrection?.driverFirstName, fieldTypes.DRIVER, true)} >
                            {nullSafeField(locationInventoryActivityGetVO?.driverFirstName)}
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Typography variant="subtitle2" className={getClassName(locationInventoryActivityGetVO?.driverLastName, inventoryCorrection?.driverLastName, fieldTypes.DRIVER, true)} >
                            {nullSafeField(locationInventoryActivityGetVO?.driverLastName)}
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Typography variant="subtitle2" className={getClassName(locationInventoryActivityGetVO?.driverLicenseNumber, inventoryCorrection?.driverLicenseNumber, fieldTypes.DRIVER, true)} >
                            {nullSafeField(locationInventoryActivityGetVO?.driverLicenseNumber)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={displayFormat.revisionColClass} >
                <Grid container item rowSpacing={1} className={classes.correctionDisplayContainer} direction="column">

                    <Divider/>
                    <Grid item >
                        <Typography variant="body1">Buyer's Revision</Typography>
                    </Grid>
                    <Divider/>

                    <Grid item>
                        <Typography variant="subtitle2" className={getClassName(locationInventoryActivityGetVO?.driverFirstName, inventoryCorrection?.driverFirstName, fieldTypes.DRIVER, false)} >
                            {nullSafeField(revisedDriverFields.firstName)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" className={getClassName(locationInventoryActivityGetVO?.driverLastName, inventoryCorrection?.driverLastName, fieldTypes.DRIVER, false)} >
                            {nullSafeField(revisedDriverFields.lastName)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" className={getClassName(locationInventoryActivityGetVO?.driverLicenseNumber, inventoryCorrection?.driverLicenseNumber, fieldTypes.DRIVER, false)} >
                            {nullSafeField(revisedDriverFields.licenseNumber)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid> 
}

export default CorrectionComparison;
