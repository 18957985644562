import { useCallback, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useUrlQueryParams = () => {
    const { search } = useLocation();
    const getParamsAsObject = useCallback(() => Object.fromEntries(new URLSearchParams(search)), [search]);
    const [paramsValues, setParamsValues] = useState(getParamsAsObject());

    useEffect(() => {
        setParamsValues(getParamsAsObject());
    }, [getParamsAsObject]);

    return paramsValues;
}

export default useUrlQueryParams;
