import React from 'react';
import AccountReport from './AccountReport';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { DateFormats, PartnerSubscriptionTypeLabel } from '../components/constants/securspace-constants';
import Money from 'js-money';

const PartnerSubscriptionsLegacy = (props) => {
    const history = useNavigate();

    const partnerSubscriptionFields = [
        {
            name: 'type',
            label: 'Fee Type',
            formatter: (value) => {
                return PartnerSubscriptionTypeLabel[value] ? PartnerSubscriptionTypeLabel[value] : value.replaceAll('_', ' ');
            }
        },
        {
            name: 'creationDate',
            label: 'Created On',
            formatter: (value) => {
                return moment(new Date(value)).format(DateFormats.DAY);
            }
        },
        {
            name: 'createdBy',
            label: 'Created By'
        },
        {
            name: 'reason',
            label: 'Reason'
        },
        {
            name: 'amount',
            label: 'Amount',
            formatter: (value, subscription) => {
                // If actual subscription amount is 0 then DataListResultsEntryRow.js will pass '' as the value
                let sanitizedValue;
                if (value) {
                    sanitizedValue = value;
                } else {
                    sanitizedValue = subscription.amount ? subscription.amount : 0;
                }
                const money = new Money(sanitizedValue, Money.USD, Math.round); // val originally stored in cents
                return `${Money.USD.symbol}${money.toString()}`;
            }
        },
        {
            name: 'paymentSource',
            label: 'Payment Source'
        },
        {
            name: 'active',
            label: 'Active',
            formatter: (value) => {
                return value ? 'YES' : 'NO';
            }
        },
    ];

    const actionList = [
        {
            displayValue: 'View Invoice',
            action: (partnerSubscription) => {
                history(`/subscriptions/${partnerSubscription.id}`);
            }
        }
    ];

    return (
        <article className="h-100">
            <AccountReport
                title="Supplier Subscriptions"
                parentMenu="Finances"
                account={props.account}
                getReportDataUrl={(account) => `api/partner-subscription?accountId=${account.id}`}
                reportFields={partnerSubscriptionFields}
                actionList={actionList}
                defaultSortBy="startDate"
                defaultSortByDirection="DESC"
            />
        </article>
    );
};

export default PartnerSubscriptionsLegacy;