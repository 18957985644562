import React, {useEffect, useState} from 'react';
import SubscriptionForm from "./SubscriptionForm";
import {
  requestCreateSubscription,
  requestDeletePartnerSubscription,
  requestGetSubscription, requestUpdatePartnerSubscription
} from "./subscription-requests";
import {toast} from "react-toastify";
import {getErrorMessageForStandardResponse} from "../../util/NetworkErrorUtil";
import Busy from "../Busy";
import {PartnerSubscriptionTypes} from "../constants/securspace-constants";

const Subscriptions = (props) => {
  const {account} = props;
  const [subscription, setSubscription] = useState({
    id: '',
    type: '',
    accountId: account?.id,
    amount: 0,
    reason: '',
    paymentSource: '',
    active: false
  });

  useEffect(() => {
    Busy.set(true);
    requestGetSubscription(account.id).then((resp) => {
      if (resp.body) {
        setSubscription(resp.body);
      }
    }).catch(() => {
      // Don't need to alert that there isn't a current subscription
    }).finally(() => {
      Busy.set(false);
    });
  }, [account.id]);

  const handleSubscriptionChange = (updatedSubscription) => {
    setSubscription({...updatedSubscription})
  }

  const handleSubmit = () => {
    if (validateSubmit()) {
      Busy.set(true);
      if (subscription.type === PartnerSubscriptionTypes.NO_SUBSCRIPTION) {
        deleteSubscription();
      } else if (subscription.id) {
        updateSubscription();
      } else if (subscription.type === PartnerSubscriptionTypes.AGS_FEE) {
        createSubscription();
      }
    }
  }

  const validateSubmit = () => {
    if (subscription?.type === '') {
      toast.error("Must select a valid subscription type");
      return false;
    } else if (subscription?.amount < 0) {
      toast.error("Amount cannot be a negative value");
      return false;
    } else if (subscription?.paymentSource === '') {
      toast.error("Must select a valid payment source");
      return false;
    }
    return true;
  }

  const createSubscription = () => {
    requestCreateSubscription(subscription).then((resp) => {
      setSubscription(resp.body);
      toast.info("Subscription Successfully Created");
    }).catch(err => {
      toast.error(getErrorMessageForStandardResponse(err));
    }).finally(() => {
      Busy.set(false);
    });
  }

  const updateSubscription = () => {
    requestUpdatePartnerSubscription(subscription.id, subscription).then(() => {
      toast.info("Partner Subscription Successfully Updated")
    }).catch(err => {
      toast.error(getErrorMessageForStandardResponse(err));
    }).finally(() => {
      Busy.set(false);
    });
  }

  const deleteSubscription = () => {
    requestDeletePartnerSubscription(subscription.id).then(() => {
      toast.info("Partner Subscription Successfully Deleted")
    }).catch(err => {
      toast.error(getErrorMessageForStandardResponse(err));
    }).finally(() => {
      setSubscription({
        id: '',
        type: '',
        accountId: account?.id,
        amount: 0,
        reason: '',
        paymentSource: '',
        active: false
      })
      Busy.set(false);
    });
  }

  return <SubscriptionForm
    subscription={subscription}
    onSubmit={handleSubmit}
    onChange={handleSubscriptionChange}
  />
}

export default Subscriptions;
