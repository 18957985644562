import React from 'react';
import Select from "../../components/Select";
import Error from "../../components/Error";

const ACCOUNT_TYPE_INDIVIDUAL_CHECKING = "Individual - Checking";
const ACCOUNT_TYPE_COMPANY_CHECKING = "Company - Checking";
const ACCOUNT_TYPE_INDIVIDUAL_SAVINGS = "Individual - Savings";
const ACCOUNT_TYPE_COMPANY_SAVINGS = "Company - Savings";

const MicroDepositForm = (props) => {
  const {
    microDepositVerifyBankAccountHolderName,
    microDepositVerifyBankAccountHolderType,
    microDepositVerifyBankRoutingNumber,
    microDepositVerifyBankAccountNumber,
    microDepositVerifyBankAccountNumber2,
    addPaymentMethodWithMicroDepositVerification,
    addPaymentMethodErrorMessage,
    addPaymentMethod,
    handleChange,
  } = props;

  return <>
    <div id="collectMicroDepositVerifyPayment">
      <div className="modal-body">
        <h4 className="ss-summary"><b>Enter Bank Account
          Details</b></h4>
        <fieldset className="ss-stand-alone">
          <label
            htmlFor="microDepositVerifyBankAccountHolderName">ACCOUNT
            HOLDER FULL NAME</label>
          <input type="text"
                 id="microDepositVerifyBankAccountHolderName"
                 name="microDepositVerifyBankAccountHolderName"
                 placeholder="Enter the full name of the person who holds this account"
                 value={microDepositVerifyBankAccountHolderName}
                 onChange={handleChange}
          />
        </fieldset>
        <fieldset className="ss-stand-alone">
          <label>ACCOUNT TYPE</label>
          <Select
            id="microDepositVerifyBankAccountHolderType"
            name="microDepositVerifyBankAccountHolderType"
            className="ss-bank-account-type"
            handleChange={handleChange}
            selectedOption={microDepositVerifyBankAccountHolderType}
            placeholder="Choose"
            options={[
              ACCOUNT_TYPE_INDIVIDUAL_CHECKING,
              ACCOUNT_TYPE_COMPANY_CHECKING,
              ACCOUNT_TYPE_INDIVIDUAL_SAVINGS,
              ACCOUNT_TYPE_COMPANY_SAVINGS
            ]}
          />
        </fieldset>
        <fieldset className="ss-stand-alone">
          <label
            htmlFor="microDepositVerifyBankRoutingNumber">ROUTING
            NUMBER</label>
          <input type="text"
                 id="microDepositVerifyBankRoutingNumber"
                 name="microDepositVerifyBankRoutingNumber"
                 placeholder="Enter your bank's routing number"
                 value={microDepositVerifyBankRoutingNumber}
                 onChange={handleChange}
          />
        </fieldset>
        <fieldset className="ss-stand-alone">
          <label
            htmlFor="microDepositVerifyBankAccountNumber">ACCOUNT
            NUMBER</label>
          <input type="text"
                 id="microDepositVerifyBankAccountNumber"
                 name="microDepositVerifyBankAccountNumber"
                 placeholder="Enter your bank account number"
                 value={microDepositVerifyBankAccountNumber}
                 onChange={handleChange}
          />
        </fieldset>
        <fieldset className="ss-stand-alone">
          <label
            htmlFor="microDepositVerifyBankAccountNumber2">RE-ENTER
            ACCOUNT NUMBER</label>
          <input type="text"
                 id="microDepositVerifyBankAccountNumber2"
                 name="microDepositVerifyBankAccountNumber2"
                 placeholder="Re-enter your bank account number"
                 value={microDepositVerifyBankAccountNumber2}
                 onChange={handleChange}
          />
        </fieldset>

        {
          addPaymentMethodErrorMessage ?
            <Error>{addPaymentMethodErrorMessage}</Error> : ''
        }
      </div>
      <div className="modal-footer">
        <div className="table text-center">
          <button
            type="button"
            onClick={() => addPaymentMethod()}
            className="ss-button-secondary">Back
          </button>
          <button
            type="button"
            onClick={addPaymentMethodWithMicroDepositVerification}
            className="ss-button-primary">Next
          </button>
        </div>
      </div>
    </div>
  </>
}

export default MicroDepositForm;
