import React from 'react';
import { CSVLink } from "react-csv";
import {cleanCsvData} from "../../../util/CsvDataUtil";

const ExportCSVButton = (props) => {

  const data = props.data || [];
  const reportName = props.reportName || 'Report';

  return <div className='csvLink-container'>
    <CSVLink filename={reportName}
             data={cleanCsvData(data)}
             separator={","}>
      <button type="button" className="ss-button-primary ss-excel-icon">DOWNLOAD REPORT</button>
    </CSVLink>
  </div>
}

export default ExportCSVButton;
