import React from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    textBox: {
        borderRadius: '.5em',
        padding: '.2em',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.grey[100],
    },
    title: {
        fontSize: '1rem',
        fontWeight: '500',
        marginLeft: '0.5em',
    },
    icon: {
        color: "#007bff",
    }
}));

const TextBox = ({ title }) => {

    const classes = useStyles();

    return (
        <Box className={classes.textBox}>
            <IconButton className={classes.icon}>
                <InfoIcon />
            </IconButton>
            <Typography variant='body2' className={classes.title}>{title}</Typography>
        </Box>
    )
}

export default TextBox;
