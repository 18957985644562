import React, {useState, useEffect} from 'react'
import { useStylesBlogDetails } from './style'
import { Box, Typography, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { SubscribeEmail } from '../../components/blog/page/BlogSection'
import './style.css'
import { useParams } from 'react-router-dom';
import Image from '../../components/skeleton/ImageComponent';
import BackButton from "../../components/BackButton";
import URLUtils from "../../util/URLUtils";
import {getBlogPost, getRelatedBlogs} from "../../requests/hubspot-requests";
import RelatedBlogs from "../../components/blog/RelatedBlogs";
import DOMPurify from 'dompurify';

const BlogDetails = () => {
    const classes = useStylesBlogDetails();
    const [loading, setLoading] = useState(false);
    const [singlePost, setSinglePost] = useState(null);
    const history = useNavigate();
    const [email, setEmail] = useState('');
    const params = useParams();
    const [contentGroupId, setContentGroupId] = useState(null);
    const [relatedBlogs, setRelatedBlogs] = useState([]);
    let ctaRegex = /{{cta\([^)]*\)}}/g;


    const LIMIT = 5
    const OFFSET = 0

    useEffect(() => {
        if (params?.postId) {
            setLoading(true);
            getBlogPost(params?.postId).then(response => {
                const { body } = response;
                setContentGroupId(body?.contentGroupId);
                setSinglePost(body);
                if (body?.name && body.name.length && !params.title) {
                    const urlString = URLUtils.formatEncodePath(body?.name);
                    history(`/blog/${params?.postId}/${urlString}`, {replace: true});
                }
            }).catch(() => {
                history('/blogs');
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [params, history]);

    useEffect(() => {
        if (contentGroupId) {
            getRelatedBlogs(contentGroupId, LIMIT, OFFSET).then(response =>{
              const { body } = response;
              setRelatedBlogs(body?.results);
            });
        }
    }, [contentGroupId]);

    return (
        <>
            <section className={classes.sectionPadding}>
                <Box className={classes.container}>
                    <Box className={classes.mainContainer}>
                        <BackButton onClick={() => history("/blogs")}>
                            Back to blogs
                        </BackButton>
                        {loading ? <Skeleton width='40%' height={40} /> : <Typography className={classes.title}>{singlePost?.name}</Typography>}
                        {loading ? <Skeleton width="30%" /> : <Typography className={classes.subtitle}>{moment(singlePost?.publishDate).format('MMMM Do YYYY')}</Typography>}
                        <Box className={classes.thumbnail}>
                            <Image src={singlePost?.featuredImage} errorImg='/app-images/not-found-image/place_holder_image.png' alt={singlePost?.name || 'Blog Title'} className={classes.img} />
                        </Box>
                        <Box className={classes.content}>
                            <Box className={classes.share}>
                                <Typography className={classes.shareTitle}>Share</Typography>
                                <a href="https://www.facebook.com/">
                                    <img src="https://static.secur.space/icons/facebook.png" alt="Icon" className={classes.icon} />
                                </a>
                                <a href="https://www.twitter.com/">
                                    <img src="https://static.secur.space/icons/twitter.png" alt="Icon" className={classes.icon} />
                                </a>
                                <a href="https://www.linkedin.com/">
                                    <img src="https://static.secur.space/icons/linkedin.png" alt="Icon" className={classes.icon} />
                                </a>
                            </Box>
                            <Box className={classes.cotentDescription}>
                                {loading ? <>
                                    {[...Array(27).keys()].map((_, index) => {
                                        return index % 4 === 0 ? <br key={index} ></br> : <Skeleton key={index} variant='text' />
                                    })}
                                </> : <div
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(singlePost?.postBody.replace(ctaRegex, ""), {ADD_TAGS: ['iframe']}) }}
                                    className='ss-blog-content'
                                />}
                                <Box className={classes.smallSocialMedia}>
                                    <Typography className={classes.shareTitle}>Share: </Typography>
                                    <a href="https://www.facebook.com/">
                                        <img src="https://static.secur.space/icons/facebook.png" alt="Icon" className={classes.icon} />
                                    </a>
                                    <a href="https://www.twitter.com/">
                                        <img src="https://static.secur.space/icons/twitter.png" alt="Icon" className={classes.icon} />
                                    </a>
                                    <a href="https://www.linkedin.com/">
                                        <img src="https://static.secur.space/icons/linkedin.png" alt="Icon" className={classes.icon} />
                                    </a>
                                </Box>
                                <Box className={classes.tags}>
                                    <Typography className={classes.tag}>Secure Truck Parking</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.subcribe}>
                                <SubscribeEmail email={email} setEmail={setEmail} />
                                <RelatedBlogs relatedBlogs={relatedBlogs}/>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </section>
        </>
    )
}

export default BlogDetails;
