import React from 'react';
import {Grid, Typography} from "@mui/material";
import "./style.css";
import {formatCurrencyValue} from "../../../util/PaymentUtils";
import {Theme} from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles: (theme: Theme) => {
  marginBottom1: CSSStyleSheet,
} = makeStyles(() => ({
  marginBottom1: {
    marginBottom: '1em'
  },
}));

const OverageRatePrice = (props: { overageRateInCents: number }) => {
  const {overageRateInCents} = props;

  const classes = useStyles();

  let formattedPrice = "$0.00";
  if (overageRateInCents !== null && overageRateInCents !== undefined && overageRateInCents >= 0) {
    formattedPrice = formatCurrencyValue(overageRateInCents, true);
  }

  return (
    <Grid item container>
      <Typography variant={'h6'} color={'textPrimary'} className={classes.marginBottom1}>Overage Rate</Typography>
      <Grid item container justifyContent={'space-between'}>
        <Typography component={'h6'} className={'ss-location-profile-section-subtitle'}>Price/Day</Typography>
        <Typography component={'h6'} className={'ss-location-profile-section-subtitle'}>
          {formattedPrice}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OverageRatePrice;