import React from 'react';
import { ButtonBase, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import type { SeverityColor } from '../../constants/securspace-constants';
import { severityColors } from '../../constants/securspace-constants';

// maybe i should fit this into the global styles somewhere :thinking:
const backgroundColorMap = {
  light: '#FFF',
  dark: '#000000DE',
}
const backgroundColors = Object.keys(backgroundColorMap);
type BackgroundColor = $Keys<typeof backgroundColorMap>;

const boxInfoInnerContent = {
  minHeight: '3.636363636em',
  minWidth: '7.454545454545em',
  left: '0',
  top: '0',
  borderRadius: '.727272727272em',
  padding: '.727272727272em 1.09090909090909em'
}

const infoPointyBoxThing ={
  width: '1.0909090909em',
  height: '1.0909090909em',
  transform: 'rotate(45deg)',
  marginTop: '-.545454545454em',
  marginLeft: '42.75%'
}

const infoBoxTypography = {
  fontFamily: 'Inter',
  fontSize: '1.428571428rem',
  fontWeight: '600',
  lineHeight: '1.714285714rem',
  letterSpacing: '0',
  textAlign: 'left'
}

const useStyles: () => {
  infoBoxInnerContent: CSSStyleSheet,
  infoBoxPointyThing: CSSStyleSheet,
  infoBoxTypography: CSSStyleSheet,
  infoBoxCircle: CSSStyleSheet,
  infoBoxSpacer: CSSStyleSheet,
} = makeStyles(theme => ({
  infoBoxInnerContent: {
    background: backgroundColorMap.light,
    ...boxInfoInnerContent,
  },
  infoBoxInnerContentHover: {
    background: backgroundColorMap.dark,
    zIndex: 10,
    ...boxInfoInnerContent,
  },
  infoBoxPointyThing: {
    background: backgroundColorMap.light,
   ...infoPointyBoxThing
  },
  infoBoxPointyThingHover:{
    background: backgroundColorMap.dark,
    ...infoPointyBoxThing
  },
  infoBoxTypography: {
    color: backgroundColorMap.dark,
    ...infoBoxTypography
  },
  infoBoxTypographyHover: {
    color: backgroundColorMap.light,
    ...infoBoxTypography
  },
  infoBoxCircle: {
    backgroundColor: props => theme.palette[props.severity].main,
    clipPath: 'circle(50%)',
    width: '0.909090909em',
    height: '0.909090909em',
  },
  infoBoxSpacer: {
    width: '0.818181818181em',
    height: '0.818181818181em',
  },

}));

const LocationPriceInfoBoxBase = (
  props: {
    formattedPrice: string,
    onClick: () => {},
    severity: SeverityColor,
    backgroundColor: BackgroundColor
  }) => {
  const { formattedPrice, onClick, severity = 'success', backgroundColor, listHover } = props;
  const classes = useStyles({ severity, backgroundColor });

  return <>
    <ButtonBase onClick={onClick}>
      <Grid container
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-around'}
        wrap={'nowrap'}
        className={ listHover ? classes.infoBoxInnerContentHover : classes.infoBoxInnerContent}
      >
        <Grid item>
          <div className={classes.infoBoxCircle} />
        </Grid>
        <Grid item>
          <div className={classes.infoBoxSpacer} />
        </Grid>
        <Grid item>
          <Typography className={listHover ? classes.infoBoxTypographyHover :  classes.infoBoxTypography}>{formattedPrice}</Typography>
        </Grid>
      </Grid>
    </ButtonBase>
    <div className={listHover ? classes.infoBoxPointyThingHover :classes.infoBoxPointyThing} />
  </>
}

LocationPriceInfoBoxBase.propTypes = {
  formattedPrice: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(severityColors),
  backgroundColor: PropTypes.oneOf(backgroundColors),
  onClick: PropTypes.func,
}

export default LocationPriceInfoBoxBase;
