import React from 'react';
import LocationPriceInfoBoxBase from './LocationPriceInfoBoxBase';

const InstantApprovalInfoBox = (props) => {
  const { formattedPrice, onClick, listHover } = props;
  return <LocationPriceInfoBoxBase formattedPrice={formattedPrice}
    onClick={onClick}
    severity={'success'}
    backgroundColor={'light'}
    listHover={listHover}
  />
}

export default InstantApprovalInfoBox;
