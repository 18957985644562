
export default {
  OPTIONS : [
    "Overage Spaces - Parking",
    "Overage Spaces - Storage",
    "Grounded Container: Loaded",
    "Grounded Container: Empty",
    "Lift Fees: Loaded",
    "Lift Fees: Empty",
    "Gate Fees - Parking",
    "Gate Fees - Storage",
    "Reefer KW Usage",
    "Equipment Moving Fee - Parking",
    "Equipment Moving Fee - Storage"
  ],
}
