import React from 'react';
import {FormGroup} from "react-bootstrap";

const TaxPercentageFormGroupLegacy = (props) => {
  const {value, onChange} = props;

  const handleOnChange = (e) => {
    const value = parseFloat(e.target.value);
    if (Number.isNaN(value) || value < 0) {
      onChange(0);
    } else if (value > 100) {
      onChange(100)
    } else {
      onChange(value);
    }
  }

  return <FormGroup className='mb-4'>
    <label className='fw-bold' htmlFor={'tax-percentage'}>Tax Percentage</label>
    <input type={'number'}
           id={'tax-percentage'}
           className={'form-control'}
           value={value?.toString()}
           onChange={handleOnChange}
           min={0}
           max={100}
           step={.5}
    />
  </FormGroup>
}

export default TaxPercentageFormGroupLegacy;