import React from 'react';
import { Box, Button, ButtonGroup, Grid, Typography } from "@mui/material";
import PaymentMethodType from "./PaymentMethodType";
import AuthorizationCard from "./AuthorizationCard";
import { useStyles } from "./styles/paymentModalStyle";

const PaymentModalBody = React.forwardRef((props, ref) => {
  const {
    payWithAch,
    setACH,
    setMicroDeposit,
    doneWithAddPaymentMethod,
    launchAddPaymentMethod,
    collectMicroDepositVerifyPayment
  } = props;

  const classes = useStyles();

  return <Box className={classes.boxStyles}>
    <Box className={classes.stickyTop}>
    <Typography variant={'h6'} className={classes.title}>
        Add Payment Method
      </Typography>
    </Box>
    <Grid item className={classes.fullContent}>
    <Box className={classes.contentWrap}>
    <Typography variant={'h6'} className={classes.subtitle}>
        Select Payment Method
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item className={classes.paymentWrapper}>
          <PaymentMethodType
            setACH={setACH}
            payWithAch={payWithAch}
            collectMicroDepositVerifyPayment={collectMicroDepositVerifyPayment}
            setMicroDeposit={setMicroDeposit}
          />
        </Grid>
      </Grid>
    </Box>
    <Grid container>
      <AuthorizationCard payWithAch={payWithAch} />
    </Grid>
    </Grid>
    <ButtonGroup className={classes.buttonGroup} ref={ref}>
      <Button color='secondary' className={classes.button} onClick={doneWithAddPaymentMethod}   >
        Cancel
      </Button>
      <Button color='secondary' className={classes.button} onClick={launchAddPaymentMethod}>
        Agree & Continue
      </Button>
    </ButtonGroup>
  </Box>
});

export default PaymentModalBody;
