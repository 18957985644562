import React, {useEffect, useState} from 'react';
import {Grid, Typography} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Money from 'js-money';
import {DAYS_PER_MONTH} from '../constants/securspace-constants';

const useStyles: () => {
  pricePerMonth: CSSStyleSheet,
  saveMoney: CSSStyleSheet,
} = makeStyles(() => ({
  pricePerMonth: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.71428rem',
    lineHeight: '133.4%',
    display:"flex",
    alignItems:"flex-end"
  },
  saveMoney: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.1em',
    lineHeight: '157%',
    color: '#F44336',
  },
  perMonth: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '.6em',
  },
}));

const LocationItemPriceDisplay = (props) => {
  const {pricePerMonth = 0, pricePerDay = 0} = props;
  const [formattedMonthly, setFormattedMonthly] = useState('');
  const [formattedDaily, setFormattedDaily] = useState('');
  const [formattedSaveMoneys, setFormattedSaveMoneys] = useState('');
  const classes = useStyles();

  useEffect(() => {
    const currency = Money.USD;
    const moneyPricePerMonth = new Money(pricePerMonth, currency);
    const moneyPricePerDay = new Money(pricePerDay, currency);

    setFormattedMonthly(moneyPricePerMonth.isPositive() ? `${currency.symbol}${moneyPricePerMonth}/mon` : '');

    setFormattedDaily(moneyPricePerDay.isPositive() ? `${currency.symbol}${moneyPricePerDay}/day` : '');

    if (pricePerDay && pricePerMonth) {
      const savings = moneyPricePerMonth.divide(DAYS_PER_MONTH, Math.round).subtract(moneyPricePerDay);
      if (savings.isPositive()) {
        setFormattedSaveMoneys(`Save ${savings}${currency.symbol}/day`);
      }
    }
  }, [pricePerMonth, pricePerDay]);



  return <div className='ss-listing-location-item-price'>
    <Grid item xs={8}>
      <Typography className={`${classes.pricePerMonth} ss-listing-price-per-month`}  color={'textPrimary'}>
      {formattedMonthly ? formattedMonthly?.split("/")[0] : formattedDaily?.split("/")[0]}
        <Typography component={"span"} className={classes.perMonth} >
        /{formattedMonthly ? formattedMonthly?.split("/")[1] : formattedDaily?.split("/")[1]}
        </Typography>
      </Typography>
      <Typography className={`${classes.saveMoney}`} >{formattedSaveMoneys}</Typography>
    </Grid>
      <Typography className='ss-daily-price ss-boooking-save-money' variant={'subtitle2'} color={'textSecondary'}>{formattedMonthly && formattedDaily}</Typography>
  </div>
}

export default LocationItemPriceDisplay;
