import React, {Component} from 'react';
import '../css/views/staticPage.css';

class Badge extends Component {
  render() {
    return (
      <div className={this.props.pendingBookings > 0 ? `badge badge-alert ${this.props.isPadding ? `ss-badge-padding` : ``} ` + this.props.type + (this.props.static ? " static" : "") : "hidden"}>
        {this.props.pendingBookings > 0 ? this.props.pendingBookings : ''}
      </div>
    )
  }
}

export default Badge;
