import React, {useState, useEffect} from "react";
import {Grid, FormControl, TextField, Typography} from "@mui/material";

type Props = {
    initialDriverFirst: string,
    initialDriverLast: string,
    initialDriverLicense: string,
    setDriverInfo: (driverInfo: {firstName: string, lastName: string, license: string}) => void,
    required?: boolean
};

const DriverInfoForm = ({initialDriverFirst, initialDriverLast, initialDriverLicense, setDriverInfo, required}: Props) => {

    const [driverFirst: string, setDriverFirst] = useState(initialDriverFirst);
    const [driverLast: string, setDriverLast] = useState(initialDriverLast);
    const [driverLicense: string, setDriverLicense] = useState(initialDriverLicense);

    useEffect(() => {
        setDriverInfo({firstName: driverFirst, lastName: driverLast, license: driverLicense});
    }, [driverFirst, driverLast, driverLicense])

    return <Grid item container component={'section'} marginTop={"1em"} spacing={2} direction={"column"}>
        <Grid item>
            <Typography variant={'h6'} color={'textPrimary'}>Driver Details</Typography>
        </Grid>
            <Grid item>
                <FormControl fullWidth>
                    <TextField 
                        id="ss-select-correction-driver-first"
                        value={driverFirst} 
                        type="text" 
                        name="driverFirst"
                        onChange={({target}) => setDriverFirst(target.value)}
                        label="First Name"
                        required={required}
                    />
                </FormControl>
            </Grid>

        <Grid item>
            <FormControl fullWidth>
                <TextField 
                    id="ss-select-correction-driver-last"
                    value={driverLast} 
                    type="text" 
                    name="driverLast"
                    onChange={({target}) => setDriverLast(target.value)}
                    label="Last Name"
                    required={required}
                />
            </FormControl>
        </Grid>
            
        <Grid item>
            <FormControl fullWidth>
                <TextField 
                    id="ss-select-correction-driver-license"
                    value={driverLicense} 
                    type="text" 
                    name="driverLicense"
                    onChange={({target}) => setDriverLicense(target.value)}
                    label="License Number"
                    required={required}
                />
            </FormControl>
        </Grid>
    </Grid>

}

export default DriverInfoForm;