export const verbiage = {
  title: 'Payouts',
  groupByLocation: 'Group By Location',
  downloadReport: 'Download Report',

  errorFailedReportDownload: 'Failed to download report',
  errorFailedGroupSummaryDownload: 'Failed to download group summary',
  viewTransactions: 'View Transactions',

  fieldAmount: 'Amount',
  fieldPayoutInitiated: 'Payout Initiated',
  fieldPayoutCompleted: 'Payout Completed',
  fieldStatus: 'Status',
  fieldLocation: 'Location',
  fieldPayoutComplete: 'Payout Complete',

  summaryTotalPayouts: 'Total Payouts',
  summaryPayoutsCount: 'Payouts Count',
  summaryTransactionCount: 'Transaction Count',

  optionAny: 'Any',
  optionYes: 'Yes',
  optionNo: 'No',

  buttonText: 'View Transactions',
};
