import React from "react";
import { Select, InputLabel, Grid, FormControl, MenuItem } from "@mui/material";
import { payoutScheduleOptions } from "../constants/securspace-constants";

const PayoutScheduleSelect = (props) => {
    const {classes, selection, handleChangeSelection} = props;

    return(
        <Grid item component={'section'} className={classes}>
            <FormControl variant="standard" className={"ss-byb-details-input"}>
            <InputLabel id='ssPayoutScheduleSelect' variant={'standard'} color={'primary'}>Payout Schedule</InputLabel>
            <Select
                value={selection || payoutScheduleOptions[0]}
                onChange={handleChangeSelection}
                className='ss-byb-details-input'
                labelId="ssPayoutScheduleSelect"
                name={'payoutSchedule'}
                fullWidth
            >
                {
                payoutScheduleOptions?.map(item => (
                    <MenuItem key={item.value} value={item.value} name={item.label}>{item.label}</MenuItem>
                ))
                }
            </Select>
            </FormControl>
        </Grid>
    )
}

export default PayoutScheduleSelect;