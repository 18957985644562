import React from 'react';
import './topTier.css';
import { Grid } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

const TopTier = () => {
    const companyLogos = [
        {
            img: "../app-images/landing/toptier/new/HD_Main_Logo.png"
        },
        {
            img: "../app-images/landing/toptier/new/ITS_ConGlobal_logo.png"
        },
        {
            img: "../app-images/landing/toptier/new/MRS_CMC_logo.png"
        },
        {
            img: "../app-images/landing/toptier/new/TCW_Logo_full.png"
        },
        {
            img: "../app-images/landing/toptier/new/yusenlogo.png"
        },
    ];

    return (
        <Grid container className="ss-customer-partner-testimonials-container ss-tier-swiper-wrapper"  >
            <Swiper
                slidesPerView={1}
                spaceBetween={60}
                breakpoints={{
                    400: {
                        slidesPerView: 2,
                    },
                    720: {
                        slidesPerView: 3,
                        autoplay: false
                    },

                    1200: {
                        slidesPerView: 5,
                        autoplay: false
                    },
                }}
                autoplay={{
                    delay:2500,
                    disableOnInteraction:false
                }}
                modules={[Autoplay]}
            >
                {companyLogos.map((item, index) => (
                    <SwiperSlide className={index === 0 ? "ss-first-slider" : "ss-other-slides"}  key={index}>
                        <img
                            className='ss-tier-brand-image'
                            src={item.img} alt={`Logo ${index}`}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Grid>
    );
};

export default TopTier;
