import React, {useState, useEffect} from 'react';
import LocationListItemListingImage from './LocationListItemListingImage';
import LocationListItemGalleryImage from './LocationListItemGalleryImage';

const LocationListItemImages = (props) => {
  const { activeSlide, gallery, listingImageFileName, locationId, ...other } = props;
  const [galleryImageFileName, setGalleryImageFileName] = useState();
  const [showListingImage, setShowListingImage] = useState(true);

  useEffect(() => {
    if (activeSlide > 0) {
      setShowListingImage(false);
      setGalleryImageFileName(gallery[activeSlide-1]);
    } else {
      setShowListingImage(true)
    }
  }, [activeSlide, gallery])

  return (
    <div>
      { showListingImage ? <LocationListItemListingImage listingImageFileName={listingImageFileName} {...other} /> : <LocationListItemGalleryImage galleryImageFileName={galleryImageFileName} locationId={locationId} {...other} />}
    </div>
  )
}

export default LocationListItemImages;
