import React from 'react';
import { Link } from "react-router-dom";
import { Typography, Card, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import '../style.css';
import BookingRentalAgreementCheckbox from "./BookingRentalAgreementCheckbox";

const useStyles = makeStyles((theme) => ({
  secondaryMainColor: {
    color: theme.palette.secondary.main,
  },
  cancelTerms: {
    backgroundColor: '#F5F5F5'
  },
  rentalAgreement: {
    backgroundColor: '#FFF6D8'
  }
}));

const BookingPaymentBeforeYouBook = (props) => {
  const { rentalAgreementURL, hasAcceptedRentalAgreement, setHasAcceptedRentalAgreement, instantApproval, isBrokeredBooking } = props;

  const classes = useStyles();

  const handleCheckBox = (event) => {
    setHasAcceptedRentalAgreement(event.target.checked)
  }

  return (
    <Grid container direction='column' className='ss-booking-payment-byb-container'>
        <Typography variant='h6' color='textPrimary'>{ isBrokeredBooking ? 'BROKERED BOOKING' : 'Before You Book' }</Typography>
        <Card className={`ss-byb-card ${classes.cancelTerms}`}>
          <Typography variant={'h6'} color={'textPrimary'}>Cancellation Policy</Typography>
          <Typography variant='body2' className='ss-byb-card-desc'>Customers reserve the right to cancel bookings up to 24H before the start date of the a booking. Same day bookings or unused spaces are non-refundable.</Typography>
          <br/>
          <Link to="/terms-of-use" target="_blank">
            <Typography variant='body2' color={'secondary'}>READ MORE</Typography>
          </Link>
        </Card>
        {
          instantApproval ?
            null
          : 
            <Card className={`ss-byb-card ${classes.rentalAgreement}`}>
              <Typography variant={'h6'}>Your Reservation</Typography>
              <Typography variant='body2' className='ss-byb-card-desc'>Your reservations won’t be confirmed until the request is accepted. You will be notified by email and in your “My Bookings” page automatically whether your request is accepted within 30 minutes of submitting.</Typography>
            </Card>
        }
        {
          isBrokeredBooking ? null
          :
          <Grid item className='ss-byb-rental-agreement-container'>
            <BookingRentalAgreementCheckbox checked={hasAcceptedRentalAgreement} onChange={handleCheckBox} rentalAgreementUrl={rentalAgreementURL} name={"acceptedRentalAgreement"} />
          </Grid>
        }
    </Grid>
  );
};

export default BookingPaymentBeforeYouBook;
