// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import LockIcon from '@mui/icons-material/Lock';
// import LanguageIcon from '@mui/icons-material/Language';
import React from "react";
import {  Container, Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { CardContentInfo } from './CustomerText';

const useStyles: () => {
    grid: CSSStyleSheet,
    card: CSSStyleSheet,
    iconContainer: CSSStyleSheet,
    icon: CSSStyleSheet,
    text: CSSStyleSheet
} = makeStyles({
    grid: {
        display: "flex",
        gap: 50,
        marginTop: 20,
        marginLeft : "4.3rem",
        marginRight : "4.3rem"
    },
    card: {
        height: '32vh',
        width: '20vw'
    },
    iconContainer: {
        display: 'flex'
    },
    icon: {
        justifyContent: 'flex-start'
    },
    text: {
        paddingLeft: '10%'
    },

});
export const PartnerText = () => {
    const classes = useStyles();

    const contents = [
        {
            image: "https://static.secur.space/icons/security.png",
            title: "Track Requests",
            description: " SecurSpace will automatically send you requests from Buyers who want to book your location! You have 30 minutes to respond to these requests."
        },
        {
            image: "https://static.secur.space/icons/calendar.png",
            title: "Sign Up For Instant Approval",
            description: "Talk to a SecurSpace team member to set up your account on auto-pilot! Our system can manage your extra spaces and track this for you!"
        },
        {
            image: "https://static.secur.space/icons/global.png",
            title: "Keep Us Informed",
            description: "Did you move? Grow? Downsize? Let us know so we can ensure you're maximizing parking and storage revenue! Many of our Suppliers also use us to find flexible real estate solutions in new cities so reach out if this is something we can help you with!"
        },
    ]

    return (
        <Container maxWidth="lg" className="ss-customer-text">
            <Grid
                container
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between"
                className={classes.grid}
            >
                {contents.map((item, index) => (
                    <CardContentInfo key={index} {...item} />
                ))}
            </Grid>
        </Container>
    )
}
