import React from "react";
import { Card, CardContent } from "@mui/material";
import {
    InventoryCorrectionReasonLabels,
    inventoryCorrectionStatus
} from "../../constants/securspace-constants";

const CorrectionStatusBar = ({classes, item}) => {
    const {checkinCorrection, checkoutCorrection} = item;
    
    const formatCorrectionReason = (reason) => {
        return InventoryCorrectionReasonLabels.find(item => item.value === reason).label
    }

    const correction = checkinCorrection ? checkinCorrection : checkoutCorrection ? checkoutCorrection : null;

    const statusBarMessage = correction?.status === inventoryCorrectionStatus.PENDING ? 
                `Your Correction: ${formatCorrectionReason(correction.reason)}` 
            : correction?.status === inventoryCorrectionStatus.APPROVED ?   
            `Suppliers Response: Your correction has been approved.`
            : correction?.status === inventoryCorrectionStatus.REJECTED ?
            `Suppliers Response: Your correction has been rejected. Reason: ${correction?.rejectionReason}`
            : "Your correction has been cancelled"

    return <>
        {
            correction ?
                <Card className={correction.status === inventoryCorrectionStatus.PENDING ? classes.statusBarPending : classes.statusBarApproved}>
                    <CardContent>{statusBarMessage}</CardContent>
                </Card>
            : ""
        }
    </> 
};

export default CorrectionStatusBar;