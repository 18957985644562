import React from 'react';
import {FC} from "react";
import {Grid, TextField} from "@mui/material";
import DateCorrectionForm from "./DateCorrectionForm";
import type {GroupedLocationInventoryActivity} from "../../../types/GroupedLocationInventoryActivity";

interface ICheckoutDateCorrectionForm {
  inventory?: GroupedLocationInventoryActivity,
  correctActivityDate?: string,
  setCorrectActivityDate?: (date: Object | null) => void,
  currentTimeData: {dateTime: Object, timeZoneName: string, timeZoneAbbreviation: string},
  setDriverInfo?: (driverInfo: { firstName: string, lastName: string, license: string }) => void,
  setCorrectAssetInfo?: (correctAssetInfo: {
    containerNumber: string,
    chassisNumber: string,
    trailerNumber: string,
    truckLicensePlateNumber: string
  }) => void,
  correctAssetInfo?: {
    containerNumber: string,
    chassisNumber: string,
    trailerNumber: string,
    truckLicensePlateNumber: string
  },
  // Optional function that disables the submit button when the user enters an incorrect date or time
  setDisableSubmit?: (disable: boolean) => void,
}

const CheckoutDateCorrectionForm: FC<ICheckoutDateCorrectionForm> = (
  {
    inventory,
    correctActivityDate,
    setCorrectActivityDate,
    setDriverInfo,
    setCorrectAssetInfo,
    correctAssetInfo,
    currentTimeData,
    setDisableSubmit = () => {},
  }: ICheckoutDateCorrectionForm) => {

  const handleError = (error: Object) => {
    if (error) {
      setDisableSubmit(true);
    }
  }

  return (
    <DateCorrectionForm
      inventory={inventory}
      correctActivityDate={correctActivityDate}
      setCorrectActivityDate={setCorrectActivityDate}
      setDriverInfo={setDriverInfo}
      driverInfoRequired={true}
      onDateTimeError={handleError}
      currentTimeData={currentTimeData}
      disableDatesBeforeCheckIn
    >
      <Grid item>
        <TextField
          id="ss-select-correction-truck-license-plate-number"
          value={correctAssetInfo.truckLicensePlateNumber}
          type="text"
          name="truckLicensePlateNumber"
          onChange={({target}) => setCorrectAssetInfo({setCorrectAssetInfo, truckLicensePlateNumber: target.value})}
          label="Truck License Plate Number"
          fullWidth
          required
        />
      </Grid>
    </DateCorrectionForm>
  );
};

export default CheckoutDateCorrectionForm