import React from 'react'
import { Grid } from '@mui/material'
import { BookingCard, NoDataMessage, BookingLoadingSkeleton } from '@securspace/securspace-ui-kit'
import useStyles from './styles';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom'
import { sendBookingConfirmationEmail } from '../../views/requests/booking-requests';
import { headerCells } from './data';
import { InvalidServiceFeeChargeTypes } from "../constants/securspace-constants";

const SupplierBookingList = ({
    supplierBookings,
    loading,
    locationId,
    sortBy,
    filter
}) => {

    const classes = useStyles();
    const history = useNavigate();
    

    const sendConfirmationEmail = async (booking) => {
        try {
            await sendBookingConfirmationEmail(booking.id);
            toast.success(`Confirmation email sent successfully: ${booking.buyerEmail}`);
        } catch (error) {
            toast.error(`Failed to send confirmation email: ${error}`)
        }
    }

    const navigateToServiceFeeCreation = (booking) => {
        history(`/booking/${booking.id}/charge-service-fee`, { state: { booking }});
    }

    return (
        <Grid container flexDirection='column' item mb={4} className={(!supplierBookings.length && !loading) && classes.container}>
            {loading ? (
                [...Array(10).keys()].map((_, index) => (
                    <BookingLoadingSkeleton key={index} />
                ))
            ) : (
                (!supplierBookings.length ? (
                    <Grid className={classes.showMessage}>
                        {filter || locationId || sortBy ? (
                            <NoDataMessage
                                image="/app-images/person-and-box.svg"
                                message="No matching records found."
                            />
                        ) : (
                            <NoDataMessage
                                image="/app-images/no-data-match.png"
                                message="No input selected, To view results kindly select the data above"
                            />
                        )}
                    </Grid>
                ) : (
                    supplierBookings.map((booking) => {
                        const buttons = [];
                        if (booking?.active === 'Active') {
                            buttons.push(
                                {
                                    label: 'Resend Confirmation Email',
                                    onClick: sendConfirmationEmail,
                                    variant: 'outlined'
                                }
                            );
                        }
                        if (!InvalidServiceFeeChargeTypes.includes(booking.status)) {
                            buttons.push(
                                {
                                    label: 'Charge Service Fee',
                                    onClick: navigateToServiceFeeCreation,
                                    variant: 'outlined'
                                }
                            );
                        }


                        return (
                            <BookingCard
                                data={booking}
                                headCells={headerCells}
                                key={booking.id}
                                bookingType='supplier'
                                buttons={buttons}
                                rootStyle={classes.bookingCard}
                                bookingTitle={booking.buyerCompanyName}
                            />
                        );
                    })

                ))
            )}
        </Grid>
    )
}

export default SupplierBookingList
