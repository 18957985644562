import React, {useState, useEffect} from "react";
import { Button } from "@mui/material";

const BookSpaceButton = (props) => {
    const {handleBookingSpace, user, formIsValid, instantApproval, isBrokeredBooking} = props;
    const [buttonLabel, setButtonLabel] = useState('Log in to Book Space');
    const isUserAdminAndNotBrokered = !isBrokeredBooking && user?.userType === 'ADMIN';
    const isLoggedInAndFormIsNotValid = user && !formIsValid;
    const shouldDisableButton = isLoggedInAndFormIsNotValid || isUserAdminAndNotBrokered;

    useEffect( () => {
        if (!user) {
            setButtonLabel('Log in to Book Space')
        } else {
            if (instantApproval) {
                setButtonLabel('Book Space')
            } else {
                setButtonLabel('Request Space')
            }
        }
    }, [user, instantApproval])

    return(
        <Button
            className='ss-booking-button'
            variant="contained"
            color="primary"
            onClick={handleBookingSpace}
            disabled={shouldDisableButton}
        >
            {buttonLabel}
        </Button>
    )
}

export default BookSpaceButton;
