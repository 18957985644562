export default {

    convertToYesNo(booleanValue) {
        if (booleanValue) {
            return 'Yes';
        }
        else {
            return 'No';
        }

    }


}

