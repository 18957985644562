import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import ExternalLanding from "./ExternalLanding";
import {getBlogPost} from "../requests/hubspot-requests";

const HubspotBlogPost = () => {
    const { postId } = useParams();

    const [blogURL, setBlogURL] = useState("");

    useEffect(() => {
        if(postId) {
            const getPost = async () => {
                const post = await getBlogPost(postId);
                const {body:{url}} = post;
                setBlogURL(url);
            };
            getPost();
        }
    }, [postId])

    return <>
            {
                blogURL && 
                <ExternalLanding 
                    height='100%'
                    url={blogURL}
                />
            }
        </>
    
}

export default HubspotBlogPost;