import React from 'react';
import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowDownward } from '@mui/icons-material';
import TopTier from './topTier/TopTier';
import './testimonials.css'
import TestimonialItemList from './TestimonialItemList';

const useStyles = makeStyles((theme) => ({
    testimonialsDescription: {
        color: theme.palette.text.disabled,
    },
}));

const Testimonials = () => {

    const classes = useStyles();

    return (
        <section>
            <Container>
                <div className="ss-customer-partner-testimonials-parent">
                    <ArrowDownward className='ss-testimonial-arrow-icon' fontSize='large' />
                    <div className="ss-customer-partner-testimonials-head">
                        <Typography variant="h3" color="textPrimary">Supported by top-tier buyers and suppliers across the globe</Typography>
                        <Typography variant="overline" className={classes.testimonialsDescription}>Testimonials</Typography>
                    </div>
                    <TestimonialItemList />
                    <TopTier />
                </div>
            </Container>
        </section>
    )
}

export default Testimonials;
