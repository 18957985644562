import React from 'react';
import SpaceUsageItemsList from "../SpaceUsageItemsList";
import {Pagination} from "@securspace/securspace-ui-kit";
import {Grid} from "@mui/material";
import type {BookedSpaceCalendarView} from "../../types/BookedSpaceCalendarView";

const SpaceUsageItemsPaginated = (props: {
  spaceUsageItems: BookedSpaceCalendarView[],
  count: number,
  rowsPerPage: number,
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  page: number,
  onPageChange: (event: React.ChangeEvent, page: number) => void,
  onViewOverageInvoices: () => void,
}) => {
  const {spaceUsageItems, count, page, rowsPerPage, onRowsPerPageChange, onPageChange, onViewOverageInvoices} = props;

  return (
    <Grid item container rowGap={'2rem'} component={'section'}>
      <SpaceUsageItemsList spaceUsageItems={spaceUsageItems} onViewOverageInvoices={onViewOverageInvoices}/>
      <Grid sx={{
        width:'100%',
        display:'flex',
        justifyContent:'flex-end',
        alignItems:'flex-end',
      }}>
      <Pagination
        onPageChange={onPageChange}
        count={count}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        variant={'tablePagination'}
      />
      </Grid>
    </Grid>
  );
};

export default SpaceUsageItemsPaginated;
