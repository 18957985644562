import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(theme => ({
  mainContainer: {
    paddingTop: '2.5rem',
    paddingBottom: '2.86rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '8.04rem',
      paddingRight: '8.04rem',
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh'
  },
  showMessage:{
    '& img': {
      width: '15em',
      height: '15em',
      objectFit: 'cover',
      borderRadius: '0.3em'
  }
  },
  bookingCard: {
    marginBottom: '1rem'
  }
}));

export default useStyles;