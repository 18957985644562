import React, {Component} from 'react';
import {NavLink} from 'react-router-dom'
import {logout} from '../../util/LogoutUtil'
import '../../css/views/navMenu.css';
import classNames from 'classnames'
import Badge from "../Badge";
import {Typography} from '@mui/material';
import {withStyles} from '@mui/styles';
import NavMenuSubmenuListItem from "./NavMenuSubmenuListItem";
import type {NavMenuItem} from "./NavMenuSubmenuListItem";

const styles = (theme) => ({
  navMenuItem: {
    fontFamily: 'Satoshi-Variable',
    fontWeight: 700,
    fontSize: '1.14em',
    lineHeight: '150%',
    '&:hover': {
      fontWeight: 900,
    },
  },
  loginBtn: {
    fontFamily: 'Satoshi-Variable',
    fontWeight: 700,
    padding: '0.71em 1.78em',
    border: `3px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
  fontColor: {
    color: '#ffffff',
  },
  otherPageFontColor: {
    color: theme.palette.secondary.main,
  },
  hover: {
    '&:hover': {
      fontWeight: 700
    }
  }
});

class NavMenu extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeMenuItem: '',
      activeSubmenu: '',
      overrideFontColor: this.props.overrideFontColor ? this.props.overrideFontColor : false,
    };

    this.setActiveMenuItem = this.setActiveMenuItem.bind(this);
  }

  logout = event => {
    event.stopPropagation();
    event.preventDefault();
    this.props.closeNavMenu();
    logout(this.props.handleLogout, false);
  };

  setActiveMenuItem: (menuItem: NavMenuItem, subMenuItemPath?: string) => void = (menuItem, subMenuItemPath) => {
    this.props.closeNavMenu();
    this.setState({
      activeMenuItem: menuItem.path,
      activeSubmenu: subMenuItemPath ? subMenuItemPath : ""
    });
    if (menuItem.label === 'How It Works') {
      const element = document.getElementById('how-it-works');
      element && element.scrollIntoView({behavior: 'smooth'})
    }
  };

  render() {
    const {classes} = this.props;

    return (
      <div id="ssNavMenuContainer" onScroll={(e) => e.stopPropagation()}>
        <ul id="ssNavMenu">
          {
            this.props.navMenu?.map((menuItem, index) =>
              menuItem?.submenus ?
                <NavMenuSubmenuListItem
                  activeMenuItem={this.state.activeMenuItem}
                  activeSubmenu={this.state.activeSubmenu}
                  buyerSpaceUsageRedesign={this.props.buyerSpaceUsageRedesign}
                  classes={classes}
                  index={index}
                  isSticky={this.props.isSticky}
                  key={index}
                  logout={this.logout}
                  menuItem={menuItem}
                  navMenu={this.props.navMenu}
                  overrideFontColor={this.state.overrideFontColor}
                  pendingApprovalCount={this.props.pendingApprovalCount}
                  pendingInventoryCount={this.props.pendingInventoryCount}
                  setActiveMenuItem={this.setActiveMenuItem}
                />
                :
                <li key={index} className="ss-nav-menu ss-nav-menu-item">
                  {
                    (menuItem?.modal)
                      ?
                      <Typography
                        variant="subtitle1"
                        component="span"
                        className={classNames(menuItem?.highlighted ? "pointer ss-nav-menu-item-highlighted" : "pointer", classes.navMenuItem)}
                        onClick={() => this.setActiveMenuItem(menuItem)}>{menuItem?.label}</Typography>
                      :
                      <NavLink
                        className={({isActive}) => isActive ? classNames(menuItem?.linkClassName, "active") : classNames(menuItem?.linkClassName)}
                        to={'/' + menuItem?.path}
                        onClick={() => this.setActiveMenuItem(menuItem)}
                      >
                        {menuItem?.label !== "Log In" && <Typography
                          variant="subtitle1"
                          component="span"
                          className={classNames({"ss-nav-menu-item-highlighted": menuItem?.highlighted}, menuItem?.className, classes.navMenuItem, this.state.overrideFontColor && !this.props.isSticky && classes.fontColor)}
                        >
                          {menuItem?.label}
                        </Typography>}

                        {
                          menuItem?.badge &&  menuItem?.path === 'approvals' && !!this.props.pendingApprovalCount && this.props.pendingApprovalCount > 0 &&
                          <Badge
                            type="left"
                            pendingBookings={this.props.pendingApprovalCount}
                          />
                        }

                        {
                          menuItem.badge &&
                          (menuItem.path === 'inventory-corrections' || menuItem.path === 'buyer-inventory-corrections') &&
                          !!this.props.pendingInventoryCount && this.props.pendingInventoryCount > 0 &&
                          <Badge
                            type={"left"}
                            pendingBookings={this.props.pendingInventoryCount}
                          />
                        }

                        {menuItem?.label === "Log In" && ['/', '/about'].includes(window.location.pathname) ?
                          <Typography
                            variant="subtitle1"
                            component="span"
                            className={this.props.isSticky ? classes.loginBtn : `login_btn`}
                          >
                            {menuItem?.label}
                          </Typography> : menuItem?.label === "Log In" && window.location.pathname !== '/' &&
                          <Typography
                            variant="subtitle1"
                            component="span"
                            className={classes.loginBtn}
                          >
                            {menuItem?.label}
                          </Typography>}
                      </NavLink>
                  }
                </li>
            )
          }
        </ul>
      </div>
    );
  }
}

export default withStyles(styles)(NavMenu);
