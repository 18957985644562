import React, { useState } from 'react';
import { Box, Button, Grid, Typography, TextField } from "@mui/material";
import { useStyles } from '../../../views/payment/styles/bankInfoStyles';


const bankArray = [
  'You are one step away from completing the process of adding this payment method.',
  'You must now verify your  bank account by confirming 2 micro deposit amounts.',
  'Two small deposits will be made into your bank account within 1-2 days.',
  'The deposit amount will be less than $0.10.',
  'Once you see the deposits, come back to this page and click the “Verify” button.',
  'Follow the remaining steps to verify and you’re all done!'
];



const enterBankAccountDepositAmounts = [
  'Two deposits were made into your bank account.',
  'Deposit amounts were less than $.10.',
  'Deposits take 1-2 days to appear.'
]


const BankAccountConfirmation = (props) => {
  const { handleModalClose } = props;
  const classes = useStyles();
  const [isClickDone, setIsClickDone] = useState(true);

  return (
    <>
    <Box className={classes.bankContainer}>
      <Typography variant='h6' className={classes.verifyTitlePrimary}>Add Payment Method</Typography>
      <Typography variant='h6' className={classes.verifyTitleSecondary}>
        {isClickDone ? `Bank Account Verification Still Required` : `Enter Bank Account Deposit Amounts`}
      </Typography>
      <Box sx={{
        width:'100%'
      }}>
        {isClickDone ? bankArray.map((item, index) => (
          <Box display='flex' alignItems='center' key={index}>
            <Box className={classes.bankBullet} />
            <Typography variant='body1' className={classes.bankText}>{item}</Typography>
          </Box>
        )) : enterBankAccountDepositAmounts.map((item, index) => (
          <Box display='flex' alignItems='center' className={classes.marginBottom} key={index}>
            <Box className={classes.bankBullet} />
            <Typography key={index} variant='body1' className={classes.bankText}>{item}</Typography>
          </Box>
        ))}
        {!isClickDone && <>
          <TextField
            type='text'
            placeholder='Enter the first micro deposit amount'
            className={classes.marginTop}
            fullWidth
            label="First Deposit Amount"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
            id="outlined-basic"
          />
          <TextField
            type='text'
            placeholder='Enter second micro deposit amount'
            className={classes.marginTop}
            fullWidth
            label="Second Deposit Amount"
            variant="standard"
            id="outlined-basic"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </>}
      </Box>
    </Box>
    {
      isClickDone ? <Grid className={classes.padding} container alignItems='flex-end' justifyContent='flex-end'>
        <Button onClick={() => setIsClickDone(!isClickDone)} variant='text' color='secondary' type='submit'>
          DONE
        </Button>
      </Grid> : <Grid className={classes.verifyButton} container alignItems='flex-end' justifyContent='flex-end'>
        <Button variant='text' color='secondary' type='submit' onClick={handleModalClose}>
          Verify Bank account
        </Button>
      </Grid>
    }
  </>
  )
  
}

export default BankAccountConfirmation;
