import moment from "moment/moment";
import {DateFormats} from "../../constants/securspace-constants";
import request from "../../../util/SuperagentUtils";

export const getSpaceUsageUrlParams = (requestParams: {}, isPageable: boolean) => {

    const {page, size, startDate, endDate, locationId, locationName, bookingNumber, invoiceNumber, sortBy, sortDir, groupBy} = requestParams;

    const params = isPageable ? new URLSearchParams([["page", page || 0], ["size", size || 10]]) : new URLSearchParams();

    if (startDate) {
        params.append("startDate", moment(startDate).format(DateFormats.DAY));
    }
    if (endDate) {
        params.append("endDate", moment(endDate).format(DateFormats.DAY));
    }
    if (locationId) {
        params.append("locationId", locationId)
    }
    if (locationName && !isPageable) {
        params.append("locationName", locationName)
    }
    if (bookingNumber) {
        params.append("bookingNumber", bookingNumber)
    }
    if (invoiceNumber) {
        params.append("overageInvoiceNumber", invoiceNumber)
    }
    if (sortBy) {
        params.append("sortBy", sortBy)
    }
    if (sortDir) {
        params.append("sortDir", sortDir)
    }
    if (groupBy) {
        params.append("groupBy", groupBy)
    }

    return params;
}

export const requestBuyerSpaceUsagePaginated = (searchParams: {},
                                                onSuccess?: () => void,
    onFail?: (err: Object) => void) : Promise | void => {

    const result = request.get(`/api/space-usage/buyers/${searchParams.buyerAccountId}?${getSpaceUsageUrlParams(searchParams, true)}`);

    if(onSuccess && onFail) {
        result
            .then(onSuccess)
            .catch(onFail)
    } else {
        return result
    }
}

export const getBuyerSpaceUsageReportUrl = (searchParams: {}) => {
    return `/api/space-usage/buyers/${searchParams.buyerAccountId}/report?${getSpaceUsageUrlParams(searchParams, false)}`;
}

export const getBuyerSpaceUsageGroupSummaryUrl = (searchParams: {}) => {
    let summaryParams = {
        startDate: searchParams.startDate,
        endDate: searchParams.endDate,
        locationId: searchParams.locationId,
        locationName: searchParams.locationName,
        groupBy: searchParams.groupBy
    }

    return `/api/space-usage/buyers/${searchParams.buyerAccountId}/group-summary-report?${getSpaceUsageUrlParams(summaryParams, false)}`;
}

export const requestCalculateBuyerSpaceUsageSummary = async (searchParams) => {
    return await request.get(`/api/space-usage/buyers/${searchParams.buyerAccountId}/summary?${getSpaceUsageUrlParams(searchParams, false)}`);
}
