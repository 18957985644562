import React, { useState, useEffect } from 'react'
import { Typography, Grid } from '@mui/material';
import moment from 'moment';
import { Pagination, formatFrequency } from '@securspace/securspace-ui-kit'
import SecurSpaceContainer from '../../components/common/SecurSpaceContainer'
import useStyles from '../../components/supplier-booking/styles';
import { SupplierBookingFilter, SupplierBookingList, SupplierBookingTitle } from '../../components/supplier-booking';
import { withSnackbar } from '../../components/hocs/withSnackbar';
import { getErrorMessageForStandardResponse } from '../../util/NetworkErrorUtil';
import { getSupplierBooking } from '../../components/supplier-booking/requests/supplier-booking-rquests';
import { formatCurrencyValue } from '../../util/PaymentUtils';
import { BookingStatus } from '../../components/constants/securspace-constants';
import { scrollToTop } from '../../util/BookingUtil';
import useUrlQueryParams from "../../hooks/useUrlQueryParams";


export const shouldShowEndDate = (data, bookingStatusLabel) => {
    return (
        data.frequency === 'RECURRING' &&
        data.endDateAdjusted !== true &&
        (data.active === true || data.status === bookingStatusLabel.PENDING || data.status === bookingStatusLabel.INCOMPLETE)
    );
};

export function transformBooking(booking) {
    const commonProperties = {
        initialSupplierPayoutAmount: formatCurrencyValue(booking.initialCharge, true),
        recurringSupplierPayoutAmount: formatCurrencyValue(booking.recurringSupplierPayoutAmount, true),
        frequencyValue: formatFrequency(booking.frequency, booking.durationType),
        startDateValue: moment(booking.startDate).format("L h:mm a").toUpperCase(),
        createdOn: moment(booking.createdOn).format("L h:mm a").toUpperCase(),
        supplierCompanyName: booking.supplierCompanyName ? booking.supplierCompanyName : 'N/A',
        active: booking.active ? 'Active' : 'Not Active',
        brokered: booking.brokered ? 'True' : 'False',
        endDateValue: shouldShowEndDate(booking, BookingStatus) ? 'Until cancelled' : `${moment(booking.endDate).format("L h:mm a").toUpperCase() || "N/A"}`,
        rate: booking.brokered ? 'N/A' : formatCurrencyValue(booking.rate) + (booking.durationType === 'WEEKLY' ? ' /week' : booking.durationType === 'MONTHLY' ? ' /month' : ' /day'),
    };

    return {
        ...booking,
        ...commonProperties
    };
};

const SupplierBooking = ({
    account,
    snackbarShowMessage
}) => {
    const { bookingNumber: urlQueryBookingNumber } = useUrlQueryParams();

    const classes = useStyles();
    const [supplierBookings, setSupplierBookings] = useState([]);
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState({
        page: 0,
        size: 50,
        startDate: '',
        endDate: '',
        buyerName: '',
        bookingNumber: urlQueryBookingNumber || '',
        selectedLocation: null,
        sortBy: '',
        equipmentType: '',
        status: '',
        active: '',
        brokered: false,
        frequency: '',
        sortDir: ''
    });
    const [totalNumberResults, setTotalNumberResults] = useState(0);

    const handlePageChange: (event, value: number) => void = (_, selectedPage) => {
        setQuery((prevState) => ({ ...prevState, page: selectedPage }))
    };

    const handleSizeChange = (event) => {
        setQuery((prevState) => ({ ...prevState, size: event.target.value }))
    };

    useEffect(() => {
        const { id } = account;

        if (id) {
            setLoading(true)
            const requestParams = {
                supplierAccountId: id,
                ...query,
                locationId: query.selectedLocation?.id,
            };

            getSupplierBooking(requestParams)
                .then(({ body }) => {
                    setTotalNumberResults(body.count);
                    const data = body.content?.map((booking) => {
                        return transformBooking(booking);
                    });
                    setSupplierBookings(data);
                    scrollToTop()
                })
                .catch(err => {
                    snackbarShowMessage(getErrorMessageForStandardResponse(err), "error", 15000)
                })
              .finally(() => {
                setLoading(false);
              });
        }

    }, [account, query, snackbarShowMessage]);


    return (
        <SecurSpaceContainer className={classes.mainContainer}>
            <Typography mb={4} variant='h5' component='h1'>Bookings</Typography>
            <SupplierBookingFilter
                setQuery={setQuery}
            />
            {
                supplierBookings?.length > 0 && <SupplierBookingTitle
                    title={query.selectedLocation?.locationName || 'All'}
                    count={supplierBookings?.length}
                    supplierBookings={supplierBookings}
                />
            }
            <SupplierBookingList
                filter={query.buyerName}
                supplierBookings={supplierBookings}
                loading={loading}
                locationId={query.selectedLocation?.id || ''}
                sortBy={query.sortBy}
            />
            {
                supplierBookings?.length > 0 && <Grid item>
                    <Pagination
                        count={totalNumberResults}
                        rowsPerPage={query.size}
                        page={query.page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleSizeChange}
                        variant="tablePagination"
                    />
                </Grid>
            }
        </SecurSpaceContainer>
    )
}

export default withSnackbar(SupplierBooking)
