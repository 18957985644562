export const verbiage = {
  title: 'Subscriptions',
  type: 'Subscription Type',
  amount: 'Amount',
  reason: 'Reason',
  source: 'Payment Source',
  active: 'Active',
  save: 'Save',
  payout: 'PAYOUT',
  saveSuccess: 'Subscription Successfully Created',
  updateSuccess: 'Partner Subscription Successfully Updated',
  deleteSuccess: 'Partner Subscription Successfully Deleted',
  validation_amount_format: 'Amount must be a valid format',
  validation_amount_positive: 'Amount must be a positive value',
  validation_reason_blank: 'Reason may not be left empty',
  placeholder_amount: 'Please enter the subscription cost',
  placeholder_reason: 'Please enter the reason for this subscription'
};