import React from 'react';
import '../../css/components/report/report-data.css';
import * as PropTypes from 'prop-types';

const ReportData = (props) => {
  const { label, children, modified } = props;

  return (
    <div className="ss-report-data-base">
      <label className="ss-report-data-label">{label}</label>
      <div className={modified ? "modified ss-report-data-children" : "ss-report-data-children"}
      >{children || '-'}</div>
    </div>
  )
}

ReportData.propTypes = {
  label: PropTypes.any.isRequired
}

export default ReportData;


