import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react'
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    footerLink: {
        display: 'inline',
        color: theme.palette.secondary.main,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
        }
    }
}));

const FooterLinks = (props) => {

    const classes = useStyles();
    const { location, showTermsModal, showPrivacyModal } = props;

    const { policyDocumentsRedesign } = useFlags();

    return <div className="ss-footer-ul">
        <div className="ss-footer-li">
            <Link to="/about"
                className={(location.pathname === '/about' ? " active" : "")}>
                <Typography variant="subtitle2" className={classes.footerLink}>
                    About
                </Typography>
            </Link>
        </div>
        <div className="ss-footer-li">
            <Link to="/contact"
                className={(location.pathname === '/contact' ? " active" : "")}>
                <Typography variant="subtitle2" className={classes.footerLink}>
                    Contact Us
                </Typography>
            </Link>
        </div>
        <div className="ss-footer-li">
            <Link to="/faq"
                className={(location.pathname === '/faq' ? " active" : "")}>
                <Typography variant="subtitle2" className={classes.footerLink}>
                    FAQ
                </Typography>
            </Link>
        </div>

        <div className="ss-footer-li">
            <Link to="/blogs"
                className={(location.pathname === '/blogs' ? " active" : "")}>
                <Typography variant="subtitle2" className={classes.footerLink}>
                    Blog
                </Typography>
            </Link>
        </div>

        {
            policyDocumentsRedesign ? <>
                <div className="ss-footer-li ss-footer-terms-of-use">
                    <Link to="/terms-of-use" target="_blank">
                        <Typography
                            variant="subtitle2"
                            className={classNames("footer-link-text", "pointer", classes.footerLink)}
                        >
                            Terms Of Use
                        </Typography>
                    </Link>
                </div>
                <div className="ss-footer-li ss-footer-privacy-policy footer-link-text">
                    <Link to="/privacy-policy" target="_blank">
                        <Typography
                            variant="subtitle2"
                            className={classNames("footer-link-text", "pointer", classes.footerLink)}
                        >
                            Privacy Policy
                        </Typography>
                    </Link>
                </div>
            </> : <>
                <div className="ss-footer-li ss-footer-terms-of-use">
                    <Typography
                        variant="subtitle2"
                        className={classNames("footer-link-text", "pointer", classes.footerLink)}
                        onClick={() => { showTermsModal() }}
                    >
                        Terms Of Use
                    </Typography>
                </div>
                <div className="ss-footer-li ss-footer-privacy-policy footer-link-text">
                    <Typography
                        variant="subtitle2"
                        className={classNames("footer-link-text", "pointer", classes.footerLink)}
                        onClick={() => { showPrivacyModal() }}
                    >
                        Privacy Policy
                    </Typography>
                </div>
            </>
        }
    </div>
}

export default FooterLinks;
