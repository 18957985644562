import React, {JSX, useState} from 'react';
import Badge from "../Badge";
import {ButtonBase} from "@mui/material";
import classNames from "classnames";
import {IoMdArrowDropdown} from "react-icons/io";
import UserDropDown from "../../routing/UserDropDown";
import RegisterSubMenu from "../../components/RegisterSubMenu";
import SubMenu from "../../components/SubMenu";
import type {Nav} from "../../routing/nav/navs";
import {ClassNameMap, makeStyles} from "@mui/styles";
import {useNavigate} from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";

export type NavMenuItem = {
  badge: boolean,
  label: string,
  path: string,
  submenuWidth?: number,
  submenus: Array<Nav>,
};

export type NavMenuItemProps = {
  activeMenuItem: string,
  activeSubmenu: string,
  buyerSpaceUsageRedesign: boolean,
  classes: ClassNameMap,
  index: number,
  isSticky?: boolean,
  logout: (event) => void,
  menuItem: NavMenuItem,
  navMenu: Array<Nav>,
  overrideFontColor?: boolean,
  pendingApprovalCount: number,
  pendingInventoryCount: number,
  setActiveMenuItem: (menuItem: NavMenuItem, path: string) => void,
};

const useStyles: () => { focus: CSSStyleSheet } = makeStyles(() => ({
  focus: {
    fontWeight: "900 !important",
  }
}));

const NavMenuSubmenuListItem: (props: NavMenuItemProps) => JSX.Element = ({
  activeMenuItem,
  activeSubmenu,
  buyerSpaceUsageRedesign,
  classes,
  index,
  isSticky,
  logout,
  menuItem,
  navMenu,
  overrideFontColor,
  pendingApprovalCount,
  pendingInventoryCount,
  setActiveMenuItem,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showRegisterDropdown, setShowRegisterDropdown] = useState(false);
  const navigate = useNavigate();

  const styles = useStyles();

  const isRegisterOption = menuItem.path === 'register';

  const className = overrideFontColor && !isSticky ?
    'ss-nav-menu-item ss-home-nav-menu-item' : 'ss-nav-menu-item' + (menuItem.badge ? " has-badge " : "");

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmenuClick = (path: string) => {
    handleClose();
    setActiveMenuItem(menuItem, path);
    navigate('/' + path);
  };

  const toggleButtonId = menuItem.label + "-menu-toggle-button";
  const subMenuId = menuItem.label + "-menu";
  const menuOpen = Boolean(anchorEl);

  return (
    <li key={index} className={className}>
      {
        menuItem.label === 'Account' ? <UserDropDown
          overrideFontColor={overrideFontColor && !isSticky}
          name={menuItem.label}
          menuItem={menuItem}
        /> :
        <ButtonBase
          id={toggleButtonId}
          className={classNames(
            "ss-nav-submenu-toggle",
            (activeMenuItem === menuItem.path ? " active" : ""),
            menuItem?.className,
            {"ss-nav-menu-item-highlighted": menuItem?.highlighted},
            classes.navMenuItem,
            overrideFontColor && !isSticky ? classes.fontColor : "")
          }
          focusVisibleClassName={styles.focus}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            if (menuItem.path === "register"){
              setActiveMenuItem(menuItem);
              setShowRegisterDropdown(!showRegisterDropdown);
            }
            setAnchorEl(event.currentTarget);
          }}
          aria-controls={menuOpen ? subMenuId : undefined}
          aria-haspopup={"menu"}
          aria-expanded={menuOpen}
        >
          {
            menuItem.badge && menuItem.path === 'bookings' && !!pendingApprovalCount && pendingApprovalCount > 0 &&
            <Badge
              type="left"
              pendingBookings={pendingApprovalCount}
            />
          }
          {
            menuItem.badge && menuItem.path === 'gate-management' && !!pendingInventoryCount && pendingInventoryCount > 0 &&
            <Badge
              type="left"
              pendingBookings={pendingInventoryCount}
            />
          }
          {menuItem.label}
          <IoMdArrowDropdown
            size={20}
            className={overrideFontColor && !isSticky ? classes.fontColor : classes.otherPageFontColor}
          />
        </ButtonBase>
      }
      {
        isRegisterOption ?
          <ClickAwayListener onClickAway={() => {
            setShowRegisterDropdown(false)
          }}>
            <div>
              <RegisterSubMenu
                show={showRegisterDropdown}
                onClick={() => setShowRegisterDropdown(!showRegisterDropdown)}
              />
            </div>
          </ClickAwayListener>
          :
          <SubMenu
            id={subMenuId}
            anchorEl={anchorEl}
            show={menuOpen}
            onClose={handleClose}
            activeSubMenuItemPath={activeSubmenu}
            menuItem={menuItem}
            menuItemIndex={index}
            menuTotalItems={navMenu.length}
            onSubMenuItemClick={handleSubmenuClick}
            handleLogout={logout}
            pendingApprovalCount={pendingApprovalCount}
            pendingInventoryCount={pendingInventoryCount}
            buyerSpaceUsageRedesign={buyerSpaceUsageRedesign}
            MenuListProps={{
              "aria-labelledby": toggleButtonId,
            }}
          />
      }
    </li>
  );
};

export default NavMenuSubmenuListItem;