import React, {memo} from 'react';
import PaymentMethodItem from "./PaymentMethodItem";
import {Grid, Typography} from "@mui/material";
import { makeStyles } from '@mui/styles';
import {Theme} from "@mui/material";
import PropTypes from "prop-types";
import {determinePaymentImage} from "../../util/PaymentUtils";

const useStyles: (theme: Theme) => {
  listContainer: CSSStyleSheet,
} = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    border: `0.07rem solid ${theme.palette.grey[200]}`,
    borderRadius: '0.57rem',
  }
}));

const PaymentMethodsList = memo(
  function PaymentMethodsList({paymentMethods = [], onMenuAction, verifyPaymentMethod}) {
    const classes = useStyles();

    return (
      <Grid container direction={'column'} className={classes.listContainer}>
        {
          paymentMethods.length ? paymentMethods.map((paymentMethod) => {
            return <PaymentMethodItem
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              img={determinePaymentImage(paymentMethod)}
              cardTitle={`...${paymentMethod.lastFour}`}
              onMenuAction={onMenuAction}
              verifyPaymentMethod={verifyPaymentMethod}
              disableRemove={paymentMethods.length < 2}
            />
          }) : <Typography variant={'h5'} component={'strong'}>No payment method added yet</Typography>
        }
      </Grid>
    );
  });

PaymentMethodsList.propTypes = {
  paymentMethods: PropTypes.array.isRequired,
};

export default PaymentMethodsList;
