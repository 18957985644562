import React from "react";
import ReportFlexRightContainer from "../../report/ReportFlexRightContainer";
import ReportKebabButton from "../../report/ReportKebabButton";
import {MenuItem} from "@mui/material";
import AddRefundCreditForm from "../../AddRefundCreditForm";
import AddRefundTransactionForm from "../../AddRefundTransactionForm";
import {superAdminList} from "../../constants/securspace-constants";

const AdminInvoicesMenu = (props) => {

  const {
    account,
    invoice,
    addRefundCreditCompleted,
    viewInvoice,
    viewInventoryLog,
    handlePutPayoutOnHold,
    handleTakePayoutOffHold,
    handleRetryPayment,
    addRefundTransactionCompleted,
    handleCompleteRefund,
    handleDenyRefund,
    handleIncludeRefundInPayout,
    closeAllDialogs,
    showAddRefundTransactionView,
    addRefundTransaction,
    showAddRefundCreditView,
    addRefundCreditTransaction
  } = props;

  const chargedButNotPaidOutToPartnerStatuses = [
    'PAYMENT_PENDING',
    'PAYMENT_SUCCEEDED',
    'SECURSPACE_PAYOUT_PENDING',
    'SECURSPACE_PAYOUT_SUCCEEDED',
    'SECURSPACE_PAYOUT_FAILED',
    'SECURSPACE_PAYOUT_CANCELED',
    'SUPPLIER_PAYOUT_FAILED_SECURSPACE_FEE_PAYOUT_SUCCEEDED',
    'SUPPLIER_PAYOUT_FAILED'
  ];

  const DONT_SHOW_INCLUDE_IN_PAYOUT_STATUSES = [
    "REFUND_REQUESTED",
    "SUPPLIER_PAYOUT_INITIATING",
    "SUPPLIER_PAYOUT_PENDING",
    "SUPPLIER_PAYOUT_ON_HOLD",
    "SUPPLIER_PAYOUT_SUCCEEDED",
    "SUPPLIER_PAYOUT_FAILED_SECURSPACE_FEE_PAYOUT_SUCCEEDED",
    "SUPPLIER_PAYOUT_SUCCEEDED_SECURSPACE_FEE_PAYOUT_FAILED",
    "SUPPLIER_PAYOUT_FAILED"
  ];

  const refundableStatus = [
    'PAYMENT_SUCCEEDED',
    'SECURSPACE_PAYOUT_PENDING',
    'SECURSPACE_PAYOUT_SUCCEEDED',
    'SECURSPACE_PAYOUT_FAILED',
    'SECURSPACE_PAYOUT_CANCELED',
    'SUPPLIER_PAYOUT_FAILED_SECURSPACE_FEE_PAYOUT_SUCCEEDED',
    'SUPPLIER_PAYOUT_FAILED'
  ]

  const refundableType = [
    'BOOKING_CHARGE',
    'DATE_ADJUST_CHARGE',
    'ADD_SPACE_CHARGE',
    'OVERAGE_CHARGE',
    'DAILY_OVERAGE_CHARGE',
    'OVERSTAY_CHARGE'
  ];

  const addRefundCreditView = () => {
    return (
      <div className="unselectable">
        <div className="modal-dialog">
          <div className="modal-content ">
            <div className="popup-header">
              <h1>Add Refund Credit To Invoice</h1>
              <button type="button" className="close pull-right"
                      aria-label="Close"
                      onClick={closeAllDialogs}>
                <img alt="" src={"../app-images/close.png"}/>
              </button>
            </div>
            <AddRefundCreditForm
              display="popup"
              bookingTransactionId={invoice.transactionId}
              closeSubViewHandler={closeAllDialogs}
              addRefundCreditCompleted={addRefundCreditCompleted}
            />
          </div>
        </div>
      </div>
    )
  };

  const addRefundTransactionView = () => {
    return (
      <div className="unselectable">
        <div className="modal-dialog">
          <div className="modal-content ">
            <div className="popup-header">
              {
                chargedButNotPaidOutToPartnerStatuses.includes(invoice.status) ?
                  <h1>Create a Refund For This Charge</h1>
                  :
                  <h1>Request a Refund For This Charge</h1>
              }
              <button type="button" className="close pull-right"
                      aria-label="Close"
                      onClick={closeAllDialogs}>
                <img alt="" src={"../app-images/close.png"}/>
              </button>
            </div>
            <AddRefundTransactionForm
              display="popup"
              bookingTransaction={invoice}
              chargedButNotPaidOutToPartner={chargedButNotPaidOutToPartnerStatuses.includes(invoice.status)}
              closeSubViewHandler={closeAllDialogs}
              addRefundTransactionCompleted={addRefundTransactionCompleted}
            />
          </div>
        </div>
      </div>
    );
  }

  return <ReportFlexRightContainer>
    {addRefundCreditTransaction ? addRefundCreditView() : ''}
    {addRefundTransaction ? addRefundTransactionView(): ''}
    <ReportKebabButton>
      <MenuItem onClick={() => viewInvoice(invoice)}>View Invoice</MenuItem>
      <MenuItem onClick={() => viewInventoryLog(invoice)}>View Inventory Log</MenuItem>
      {
        invoice.status === 'CHARGE_PENDING' &&
        <MenuItem onClick={showAddRefundCreditView}>Add Refund Credit To Invoice</MenuItem>
      }
      {
        refundableStatus.includes(invoice.status) &&
        !refundableType.includes(invoice.type) &&
        !invoice.supplierPayoutOnHold &&
        <MenuItem onClick={() => handlePutPayoutOnHold(invoice)}>Put Payout On Hold</MenuItem>
      }
      {
        refundableStatus.includes(invoice.status) &&
        !refundableType.includes(invoice.type) &&
        invoice.supplierPayoutOnHold &&
        <MenuItem onClick={() => handleTakePayoutOffHold(invoice)}>Take Payout Off Hold</MenuItem>
      }
      {
        invoice.status === 'PAYMENT_FAILED' &&
        <MenuItem onClick={() => handleRetryPayment(invoice)}>Retry Failed Payment</MenuItem>
      }
      {
        superAdminList.includes(account.username) &&
        <div>
          {
            invoice.transferType === 'CHARGE' &&
            refundableStatus.includes(invoice.status) &&
            <MenuItem onClick={() => showAddRefundTransactionView()}>Refund...</MenuItem>
          }
          {
            invoice.status === 'CHARGE_PENDING' &&
            <MenuItem onClick={() => showAddRefundTransactionView()}>Add Refund Credit...</MenuItem>
          }
          {
            invoice.status === 'REFUND_REQUESTED' &&
            <MenuItem onClick={() => handleCompleteRefund(invoice, true)}>Complete Refund (Deduct From Next Payout)</MenuItem>
          }
          {
            invoice.status === 'REFUND_REQUESTED' &&
            <MenuItem onClick={() => handleCompleteRefund(invoice, false)}>Complete Refund (DO NOT Deduct From Next Payout)</MenuItem>
          }
          {
            invoice.status === 'REFUND_REQUESTED' &&
            <MenuItem onClick={() => handleDenyRefund(invoice)}>Deny Refund</MenuItem>
          }
          {
            invoice.transferType === 'REFUND' &&
            !invoice.includeRefundInPayout &&
            !DONT_SHOW_INCLUDE_IN_PAYOUT_STATUSES.includes(invoice.status) &&
            <MenuItem onClick={() => handleIncludeRefundInPayout(invoice)}>Include Refund In Payout</MenuItem>
          }
          {
            invoice.transferType === 'REFUND' &&
            invoice.includeRefundInPayout &&
            !DONT_SHOW_INCLUDE_IN_PAYOUT_STATUSES.includes(invoice.status) &&
            <MenuItem onClick={() => handleIncludeRefundInPayout(invoice)}>Exclude Refund From Payout</MenuItem>
          }
        </div>
      }
    </ReportKebabButton>
  </ReportFlexRightContainer>
}

export default AdminInvoicesMenu;
