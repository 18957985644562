import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

const useStyle = makeStyles((theme) => ({
    navSubmenuToggle: {
        color: theme.palette.secondary.main
    }
}));

const StyledMenu = ((props) => {

    return (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            sx={{
                zIndex:100000000000
            }}
            {...props}
        />
    )
});


export default function UserDropDown({ name, menuItem, overrideFontColor }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useNavigate()
    const location = useLocation()
    const classes = useStyle()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleRedirect(item) {
        history(`/${item.path}`)
        handleClose()
    }



    return (
        <div>
            <p
                onClick={handleClick}
                className={classNames(classes.navSubmenuToggle, `ss-nav-submenu-toggle ${overrideFontColor ? `ss-account-menu-home` : 'ss-account-menu'}`)}
            >
                {name}
                <IoMdArrowDropdown size={20} />
            </p>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className='ss-dropw-down-menu-main'
            >
                {menuItem?.submenus?.map((item, index) => (
                    <MenuItem className={`${location.pathname === `/${item?.path}` ? `ss-drop-down-active` : `ss-dropw-down-inactive`}`} onClick={() => handleRedirect(item)} key={index}>
                        <ListItemText className='ss-nav-drop-down-menu' primary={item?.label} />
                    </MenuItem>
                ))}
            </StyledMenu>
        </div>
    );
}
