import React from 'react';
import SubscriptionSelectForm from "./SubscriptionSelectForm";
import SubscriptionAmount from "./SubscriptionAmount";
import SubscriptionReason from "./SubscriptionReason";
import SubscriptionPaymentSource from "./SubscriptionPaymentSource";
import SubscriptionActive from "./SubscriptionActive";
import SubscriptionButton from "./SubscriptionButton";
import { validateCurrencyValue, parseCurrencyValue } from '../../util/PaymentUtils';

const SubscriptionForm = (props) => {
  const {subscription, onSubmit, onChange} = props;

  const handleSubscriptionTypeChange = (updatedSubscriptionType) => {
    const type = updatedSubscriptionType.target.value;
    onChange({
      ...subscription,
      type: type,
    });
  }

  const handleSubscriptionAmountChange = (event) => {
    const value = event.target.value;
    if (!validateCurrencyValue(value)) {
      return;
    }
    onChange({
      ...subscription,
      amount: parseCurrencyValue(value || 0),
    });
  }

  const handleSubscriptionReasonChange = (updatedSubscriptionReason) => {
    const reason = updatedSubscriptionReason.target.value;
    onChange({
      ...subscription,
      reason: reason,
    });
  }

  const handleSubscriptionPaymentSourceChange = (updatedSubscriptionPaymentSource) => {
    const paymentSource = updatedSubscriptionPaymentSource.target.value;
    onChange({
      ...subscription,
      paymentSource: paymentSource,
    });
  }

  const handleSubscriptionPaymentActiveChange = () => {
    const active = !subscription.active;
    onChange({
      ...subscription,
      active: active,
    });
  }

  return (
    <form className="ss-form ss-block">
      <div className="for-section-container">
        <div className="for-section">
          <h3 className="group-title company">Subscriptions</h3>
          <div>
            <SubscriptionSelectForm
              handleChange={handleSubscriptionTypeChange}
              subscriptionType={subscription?.type}
            />
            <SubscriptionAmount
              handleChange={handleSubscriptionAmountChange}
              amount={subscription?.amount}
            />
            <SubscriptionReason
              handleChange={handleSubscriptionReasonChange}
              reason={subscription?.reason}
            />
            <SubscriptionPaymentSource
              handleChange={handleSubscriptionPaymentSourceChange}
              paymentSource={subscription?.paymentSource}
            />
            <SubscriptionActive
              handleChange={handleSubscriptionPaymentActiveChange}
              active={subscription?.active}
            />
            <SubscriptionButton
              onSubmit={onSubmit}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default SubscriptionForm;
