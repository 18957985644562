import React from 'react';
import {Checkbox, FormControlLabel, Link, Theme, Typography, Grid} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {makeStyles} from "@mui/styles";

const useStyles: (theme: Theme) => {
  link: CSSStyleSheet
} = makeStyles(() => ({
  link: {
    font: "inherit",
    color: 'rgba(63, 81, 181, 1)',
    textDecoration: 'none'
  },
  required: {
    '&.Mui-required': {
      color: 'tomato'
    }
  }
}));

const BookingRentalAgreementCheckbox = (props: {checked: boolean, onChange(event: Object): void, rentalAgreementUrl: string, name: string, disabled?: boolean}) => {
  const {checked, onChange, rentalAgreementUrl, name, disabled} = props;
  const classes = useStyles();

  return(
    <Grid container flexDirection='column'>
      <Grid item>
        <FormControlLabel
          className={classes.required}
          label={<Typography variant={'subtitle1'} color='textPrimary' component='span'>Agree to Terms</Typography>}
          control={<Checkbox checked={checked} onChange={onChange} name={name} disabled={disabled} required/>}
          />
      </Grid>
      <Grid item>
          <Typography variant={'subtitle1'} color='textPrimary'>
            By checking this box, you agree to the website <RouterLink to={'/terms-of-use'} target={'_blank'} className={classes.link}>Terms Of Use</RouterLink>, <Link href={rentalAgreementUrl} target={'_blank'} className={classes.link}>Reservation Agreement</Link>, and agree to pay any and all overages accrued.
          </Typography>
      </Grid>
    </Grid>
  );
};

export default BookingRentalAgreementCheckbox;