import React from 'react'
import { Container } from '@mui/material'
import './css/secur-space-container.css'
import classNames from 'classnames'

const SecurSpaceContainer = ({ children, className, styles }) => {
    return (
        <Container maxWidth="lg" sx={styles} disableGutters={true} className={classNames("ss-main-container", { [className]: className })}
        >{children }</Container>
    )
}

export default SecurSpaceContainer