import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Skeleton } from '@mui/material';


const useStyles = makeStyles(() => ({
    skeleton: {
        boxShadow: '0px 4px 12px 4px rgba(63, 81, 181, 0.05)',
        background: '#FFFFFF',
        borderRadius: '0.8em',
        height: '23em',
        width: '20em',
    },
    wrapperPadding: {
        padding: '1.71em'
    }
}));


const SkeletonBlog = () => {
    const classes = useStyles();
    return (
        <Box className={classes.skeleton}>
            <Skeleton variant="rectangular" width={"100%"} height={140} />
            <Box className={classes.wrapperPadding}>
                {[...Array(5).keys()].map((_, inx) => <Skeleton key={inx} variant='text' />)}
                <Skeleton width="60%" />
            </Box>
        </Box>
    )
}

export default SkeletonBlog