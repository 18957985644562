import request from "superagent";
import logout from "../components/Logout";

const isSupportedFileType = (file) => {
  let fileType = file['type'];
  let ValidFileTypes = ['image/gif', 'image/jpeg', 'image/png', 'application/pdf'];
  return $.inArray(fileType, ValidFileTypes) >= 0;
}

export const validateFiles = (allUploadFiles) => {
  for (let i = 0; i < allUploadFiles.length; i++) {
    let fileToUpload = allUploadFiles[i];
    let file = fileToUpload.files ? fileToUpload.files[0] : '';
    if (file.size > 20000000) {
      return false
    }
    if (fileToUpload.isImageFile) {
      if (file && !isSupportedFileType(file)) {
        return false;
      }
    }
  }
  return true;
}

export const validateFile = (uploadFile) => {
  if (uploadFile.size > 20000000) {
    return false
  }
  if (uploadFile.isImageFile) {
    if (uploadFile && !isSupportedFileType(uploadFile)) {
      return false;
    }
  }
  return true;
}

export const fetchDownloadFile = (reportUrl: string) => {
  request
    .get(reportUrl)
    .responseType('blob')
    .then(res => {
      const {body, headers} = res;
      const rawFileName = headers['content-disposition'].split('filename=')[1];
      const filename = rawFileName.match(/"([^"]+)"/)[1];

      downloadFileFromBlob(body, filename);
    }, err => {
      if (err.status === 401) {
        logout();
      }
      throw err;
    });
};

const downloadFileFromBlob: (blob: any, fileName: string) => void = (blob, fileName) => {
  if (blob) {
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);

    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    link.dispatchEvent(clickEvt);
    link.remove();
  }
}