import React, {useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl, InputLabel,
  MenuItem,
  Select,
  Theme
} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles: (theme: Theme) => {
  backgroundGrey100: CSSStyleSheet;
} = makeStyles((theme: Theme) => ({
  backgroundGrey100: {
    backgroundColor: theme.palette.grey[100]
  }
}));

type InitiatePayoutDialogProps = {
  locations: {id: string, locationName: string}[],
  open: boolean,
  onClose: () => void,
  onConfirm: () => void,
};

const InitiatePayoutDialog: (props: InitiatePayoutDialogProps) => JSX.Element = ({ locations = [], open, onClose, onConfirm }) => {
  const [selectedLocationId, setSelectedLocationId] = useState('All');

  const classes = useStyles();

  const handleCancel = () => {
    setSelectedLocationId('All');
    onClose();
  }

  const handleChange = (event) => {
    setSelectedLocationId(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    onConfirm(selectedLocationId);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-label={'ss-initiate-payout-title'}
      aria-describedby={'ss-initiate-payout-description'}
      fullWidth
    >
      <DialogTitle id={'ss-initiate-payout-title'}>
        Initiate Payouts by Location
      </DialogTitle>
      <DialogContent id={'ss-initiate-payout-description'}>
        <FormControl variant={'standard'} fullWidth>
          <InputLabel id={'ss-initiate-payout-select-location-label'}>Select Location</InputLabel>
          <Select
            labelId={'ss-initiate-payout-select-location'}
            value={selectedLocationId}
            onChange={handleChange}
            displayEmpty
            fullWidth
            variant={'standard'}
          >
            <MenuItem value={'All'}>All Locations</MenuItem>
            {
              locations?.map((location) =>
                <MenuItem key={location.id} value={location.id}>{location.locationName}</MenuItem>)
            }
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.backgroundGrey100}>
        <Button color={'primary'} onClick={handleCancel}>
          Cancel
        </Button>
        <Button color={'primary'} type={'submit'} onClick={handleSubmit}>
          Initiate Payouts
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InitiatePayoutDialog;