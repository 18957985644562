import moment from 'moment';
import request from '../../../util/SuperagentUtils';
import { DateFormats } from '../../constants/securspace-constants';

const getInterChangesUrlParams = (requestParams: {
  buyerAccountId?: string,
  page?: number,
  size?: number,
  startDate?: string,
  endDate?: string,
  locationId?: string,
  locationName: string,
  bookingNumber?: string,
  sortBy?: string,
  supplierName?: string,
  equipmentType?: string,
  equipmentNumber?: string,
  driverName?: string,
  sortDir?: string}, isPageable: boolean) => {

  const { page, size, startDate, endDate, locationId, locationName, bookingNumber, sortBy, supplierName, equipmentType, equipmentNumber, driverName, sortDir } = requestParams;

  const params = isPageable ? new URLSearchParams([["page", page || 0], ["size", size || 10]]) : new URLSearchParams();

  if (startDate) {
    params.append("startDate", moment(startDate).format(DateFormats.DAY));
  }
  if (endDate) {
    params.append("endDate", moment(endDate).format(DateFormats.DAY));
  }
  if (locationId) {
    params.append("locationId", locationId)
  }
  if (locationName && !isPageable) {
    params.append("locationName", locationName)
  }
  if (bookingNumber) {
    params.append("bookingNumber", bookingNumber)
  }
  if (sortBy && sortBy !== 'none') {
    params.append("sortBy", sortBy)
  }
  if (supplierName) {
    params.append("supplierName", supplierName?.trim())
  }
  if (equipmentType && equipmentType !== 'All') {
    params.append("equipmentType", equipmentType)
  }
  if (equipmentNumber) {
    params.append("equipmentNumber", equipmentNumber)
  }

  if (driverName) params.append("driverName", driverName);

  if (sortDir) {
    params.append("sortDir", sortDir)
  }

  return params;
}

export const getAllInterChanges = (searchParams, onSuccess, onFail ) => {

  const result = request.get(`/api/inventory-activity/buyers/${searchParams.buyerAccountId}?${getInterChangesUrlParams(searchParams, true)}`);

  if (onSuccess && onFail) {
    result
      .then(onSuccess)
      .catch(onFail)
  } else {
    return result
  }
}

export const getBuyerInterChangeReportUrl = (searchParams: {}) => {
    return `/api/inventory-activity/buyers/${searchParams.buyerAccountId}/report?${getInterChangesUrlParams(searchParams, false)}`;
}
