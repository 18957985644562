import React from 'react';
import {AccountType} from "../constants/securspace-constants";
import PropTypes from "prop-types";
import type {Account} from "../../types/Account";
import PartnerPaymentMethods from "./PartnerPaymentMethods";
import BuyerPaymentMethods from "./BuyerPaymentMethods";

const PaymentMethods = (props: { account: Account, handleLogout: () => void, handleAccountUpdated: () => void}) => {
  const { account, handleLogout, handleAccountUpdated } = props;

  return (
    account.type === AccountType.SUPPLIER ?
      <PartnerPaymentMethods account={account} handleLogout={handleLogout} handleAccountUpdated={handleAccountUpdated}/>
      : <BuyerPaymentMethods account={account} handleLogout={handleLogout}/>
  );
};

PaymentMethods.propTypes = {
  account: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default PaymentMethods;
