import React, { useEffect, useState } from "react";
import { Container, Typography } from "@mui/material";
import RecentlyBookedLocations from "./RecentlyBookedLocations";
import { getRecentlyBooked } from "../../location/requests/location-requests";
import '../../../css/components/recentlyBooked.css';
import { withSnackbar } from "../../hocs/withSnackbar";

const RecentlyBookedCarousel = ({ snackbarShowMessage }) => {

  const [currentListings, setCurrentListings] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const LIMIT = 8
  const PAGE = 0

  useEffect(() => {
    try {
      const getListings = async (page, size) => await getRecentlyBooked(page, size);
      getListings(PAGE, LIMIT)
        .then(res => {
          setCurrentListings(res.body.content)
          setIsLoading(false)
        })
        .catch(err => {
          snackbarShowMessage(err, 'error', 15000);
        });
    } catch (err) {
      snackbarShowMessage(err, 'error', 15000);
    }
  }, [snackbarShowMessage]);

  return (
    <Container maxWidth='xl'>
      <Typography variant="h3" className="ss-recently-booked-title">Recently Booked</Typography>
      <RecentlyBookedLocations loading={isLoading} currentListings={currentListings}/>
    </Container>
  )
}

export default withSnackbar(RecentlyBookedCarousel);
