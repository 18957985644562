import React from 'react'

export default function DownloadCsvIcon(props: {
    size?: string,
    color?: string
}) {
    let { size, color} = props;
    size = size || "21.4px";
    color = color || "white";
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={color}
            viewBox="0 0 24 24"
            width={`${size}`}
            preserveAspectRatio="xMinYMid">
            <path
                fillRule="evenodd"
                d="
                    M 2.721 2.877 L 13.275 1.082 C 13.505 1.042 13.718 1.232 13.75 1.506 C 13.753 1.529 13.755 1.553 13.755 1.576 L 13.755 22.424 C 13.755 22.7 13.567 22.924 13.335 22.924 C 13.315 22.924 13.295 22.922 13.275 22.918 L 2.721 21.123 C 2.307 21.052 2 20.63 2 20.133 L 2 3.867 C 2 3.37 2.307 2.948 2.721 2.877 Z
                    M 14.594 19 L 20.058 19.029 L 19.913 5 L 14.594 5 L 14.594 3 L 20.869 3 C 21.332 3 21.737 3.448 21.737 4 L 21.971 19.942 C 21.971 20.494 21.449 21 20.986 21 L 14.594 21 L 14.594 19 Z
                    M 8.885 12 L 11.236 16 L 9.221 16 L 7.877 13.714 L 6.534 16 L 4.519 16 L 6.87 12 L 4.519 8 L 6.534 8 L 7.877 10.286 L 9.221 8 L 11.236 8 L 8.885 12 Z
                "
            />
            <rect x="14.594" y="6.6" width="1.1" height="1.5"></rect>
            <rect x="16.5" y="6.6" width="1.65" height="1.5"></rect>
            <circle cx="18.15" cy="7.35" r="0.75"></circle>
            <rect x="14.594" y="9.7" width="1.1" height="1.5"></rect>
            <rect x="16.5" y="9.7" width="1.65" height="1.5"></rect>
            <circle cx="18.15" cy="10.45" r="0.75"></circle>
            <rect x="14.594" y="12.8" width="1.1" height="1.5"></rect>
            <rect x="16.5" y="12.8" width="1.65" height="1.5"></rect>
            <circle cx="18.15" cy="13.55" r="0.75"></circle>
            <rect x="14.594" y="15.9" width="1.2" height="1.5"></rect>
            <rect x="16.5" y="15.9" width="1.65" height="1.5"></rect>
            <circle cx="18.15" cy="16.65" r="0.75"></circle>
        </svg>
    )
}