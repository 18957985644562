export const ErrorMessage = Object.freeze({
  BOOK_SPACE_FAILED: "Sorry, your booking could not be completed. Try again later."
});

export const DEFAULT_DAYS_AHEAD = 30;

export const DEFAULT_DAILY_BOOKING_DAYS_AHEAD = 1;

export const DEFAULT_NUM_SPACES = 1;

export const AMorPM = {AM: "AM", PM: "PM"};

export const timeOfDay = {NIGHT: "NIGHT", DAY: "DAY"}