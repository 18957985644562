import React, {useRef} from 'react';
import {FormControl, InputAdornment, InputBase} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import PlacesAutocompleteInputAdornmentEnd from './PlacesAutocompleteInputAdornmentEnd';
import PlacesAutocompleteMui from './PlacesAutocompleteMui';
import PropTypes from 'prop-types';
import './style.css'

const useStyles = makeStyles(({
  input: {
    fontSize: '1rem',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    lineHeight: '1.75',
    letterSpacing: '0.00938em',
  },
  hoverCancel: {
    cursor: 'pointer',
  },
  searchIcon:{
    fontSize: '2.5rem',
  }
}));

const PlacesAutocompleteInputMui = (props) => {
  const {
    onChange,
    value,
    name,
    onCancelIconClick,
    selectedPacItem,
    handleClickPac,
    handleSelectPac,
    containerRef,
    showPac,
    autoFocus,
    handleKeyUp,
  } = props;
  const inputRef = useRef(null);
  const pacSession = new window.google.maps.places.AutocompleteSessionToken();
  const pacService = new window.google.maps.places.AutocompleteService();
  const pacOptions = {
    types: ['(cities)']
  };
  const classes = useStyles();

  return <>
    <FormControl fullWidth>
      <InputBase type="text"
                 inputRef={inputRef}
                 name={name}
                 value={value}
                 onChange={onChange}
                 className='searchinput'
                 placeholder="Type a location"
                 startAdornment={<InputAdornment position="start">
                   <SearchIcon className={classes.searchIcon}  color={'primary'}/>
                 </InputAdornment>}
                 endAdornment={<PlacesAutocompleteInputAdornmentEnd searchValue={value}
                                                                    onCancelIconClick={onCancelIconClick}
                 />}
                 inputstyle={classes.input}
                 autoComplete={'off'}

                 autoFocus={autoFocus}
                 onKeyUp={handleKeyUp}
      />
    </FormControl>
    {
      showPac && <PlacesAutocompleteMui inputRef={inputRef}
                                        inputValue={value}
                                        containerRef={containerRef}
                                        pacSession={pacSession}
                                        pacService={pacService}
                                        pacOptions={pacOptions}
                                        onClickPac={handleClickPac}
                                        handleSelectPac={handleSelectPac}
                                        selectedPacItem={selectedPacItem}
      />
    }
  </>
}

PlacesAutocompleteInputMui.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  onCancelIconClick: PropTypes.func,
  selectedPacItem: PropTypes.object,
  handleClickPac: PropTypes.func,
  handleSelectPac: PropTypes.func,
  containerRef: PropTypes.object,
  showPac: PropTypes.bool,
}

export default PlacesAutocompleteInputMui;
