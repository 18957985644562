import request from '../../../util/SuperagentUtils';

export const getAllBuyerBookings = (accountId: String, sortBy: String, sortDir: String) : Promise => {
    const queryParams = new URLSearchParams();
    if (sortBy) queryParams.append('sortBy', sortBy);
    if (sortDir) queryParams.append('sortDir', sortDir);
    return request
        .get(`api/booking/all-buyer-bookings/${accountId}?${queryParams.toString()}`);
};

export const getActiveAndPendingBuyerBookings = (accountId: String) : Promise => {
    return request
        .get(`api/booking/active-pending-buyer-bookings/${accountId}`);
};

export const getInactiveBuyerBookings = (accountId: String) : Promise => {
    return request
        .get(`api/booking/inactive-buyer-bookings/${accountId}`);
};

export const getCountInactiveBuyerBookings = (accountId: String) : Promise => {
    return request
        .get(`api/booking/count-inactive-buyer-bookings/${accountId}`);
};

export const requestGetFailedTransactionsForBooking = (bookingId : String,
                                                       onSuccess?: (failedTransactions: Array<Object>) => void,
                                                       onFail?: (err: Object) => void) : (Promise<Object> | void) => {
    const result = request
        .get(`/api/booking/${bookingId}/transactions/failed`)

    if(onSuccess && onFail) {
        result
            .then((resp) => onSuccess(resp.body))
            .catch((err) => onFail(err))
    } else {
        return result
    }
}

export const requestCompleteBooking = (
    data: { id: String, buyerAccountId: String, tosDocUrl: String, paymentMethodId: String },
    onSuccess?: (booking: Object) => void,
    onFail?: (err: Object) => void
    ) : (Promise<Object> | void) => {

    const result = request
        .post(`/api/booking/complete-booking`)
        .send(data)

    if(onSuccess && onFail) {
        result
            .then(resp => onSuccess(resp.body))
            .catch(err => onFail(err))
    } else {
        return result
    }
}

export const requestRetryFailedTransactionsForBooking = (bookingId: String,
                                                         onSuccess?: () => void,
                                                         onFail?: (err: Object) => void) : (Promise<Object> | void) => {
    const result = request
        .post(`/api/booking/${bookingId}/transactions/failed/retry`)

    if(onSuccess && onFail) {
        result
            .then(onSuccess)
            .catch(onFail)
    } else {
        return result
    }
}

export const requestReadSupplierPendingBooking = (id: String,
                                                  onSuccess?: () => void,
                                                  onFail?: (err: Object) => void) : Promise | void  => {
    const result = request
        .get(`/api/booking/count-bookings?supplierAccountId=${id}&approvalsOnly=true`)

    if(onSuccess && onFail) {
        result
            .then(onSuccess)
            .catch(onFail)
    } else {
        return result
    }
}

export const requestReadBuyerPendingBooking = (id: String,
                                                onSuccess?: () => void,
                                                onFail?: (err: Object) => void) : Promise | void => {
    const result = request.get(`/api/booking/buyer-pending-count?buyerAccountId=${id}`);

    if(onSuccess && onFail) {
        result
            .then(onSuccess)
            .catch(onFail)
    } else {
        return result
    }
}

export const updatePaymentMethod = (booking) => {
    return request
        .post(`/api/booking/update-payment-method`)
        .send(booking);
}
