import React, {useState} from 'react';
import {
  Table,
  TableCell, TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import {Theme} from "@mui/material";
import UsersTableUserMap from './UsersTableUserMap';

const useStyles: (theme: Theme) => {
  columnGap8: CSSStyleSheet,
  minWidthAuto: CSSStyleSheet,
  fontColorSecondaryDark: CSSStyleSheet
} = makeStyles(theme => ({
  columnGap8: {
    columnGap: '0.57rem',
  },
  minWidthAuto: {
    minWidth: 'auto',
  },
  fontColorSecondaryDark: {
    color: theme.palette.secondary.dark,
  },
}));

const UsersTable = ({ users, onEditUser, onDeleteUser, setUserToDelete }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const classes = useStyles();

  const handlePageChange = (_event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const usersPerRow = rowsPerPage > 0 ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : users;

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User Name</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>{/* Empty cell for actions column */}</TableCell>
            </TableRow>
          </TableHead>
          <UsersTableUserMap 
            usersPerRow={usersPerRow} 
            handleEdit={onEditUser} 
            onDeleteUser={onDeleteUser}
            classes={classes}
            setUserToDelete={setUserToDelete}
          />
        </Table>
      </TableContainer>
      <TablePagination
        className={'w-100'}
        component={'div'}
        count={users.length || 0}
        page={page}
        rowsPerPageOptions={[5, 10, 25]}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  );
};

export default UsersTable;
