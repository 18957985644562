import moment from 'moment';
import request from '../../../util/SuperagentUtils';
import { DateFormats } from '../../constants/securspace-constants';

const getAllLocationsBySupplier = (supplierAccountId) => {
    return request.get(`/api/suppliers/${supplierAccountId}/space-utilization`);
};


const getSupplierBooking = (
    searchParams: {
        active?: string,
        bookingNumber?: string,
        brokered?: boolean,
        buyerName?: string,
        endDate?: string,
        equipmentType?: string,
        frequency?: string,
        locationId?: string,
        page?: number,
        size?: number,
        sortBy?: string,
        sortDir?: string,
        startDate?: string,
        status?: string,
        supplierAccountId: string,
    },
    onSuccess,
    onFail
) => {

    const { supplierAccountId, page, buyerName, bookingNumber, size, startDate, endDate, locationId, sortBy, equipmentType, status, active,brokered, frequency, sortDir } = searchParams;

    let pageParam = ["page", page || 0];
    let sizeParam = ["size", size || 10];

    let params = new URLSearchParams([pageParam, sizeParam]);

    if (startDate) {
        params.append("startDate", moment(startDate).format(DateFormats.DAY));
    }
    if (endDate) {
        params.append("endDate", moment(endDate).format(DateFormats.DAY));
    }
    if (locationId) {
        params.append("locationId", locationId)
    }
    if (sortBy) {
        params.append("sortBy", sortBy)
    }
    if (buyerName) {
        params.append("buyerName", buyerName)
    }
    if (equipmentType && equipmentType !== "All") {
        params.append("equipmentType", equipmentType)
    }
    if (status) {
        params.append("status", status)
    }
    if (active) {
        params.append("active", active)
    }
    if (brokered) {
        params.append("brokered", brokered)
    }
    if (frequency) {
        params.append("frequency", frequency)
    }
    if (sortDir) {
        params.append("sortDir", sortDir)
    }

    if (bookingNumber) params.append("bookingNumber", bookingNumber);

    const result = request.get(`/api/booking/suppliers/${supplierAccountId}?${params}`);

    if (onSuccess && onFail) {
        result
            .then(onSuccess)
            .catch(onFail)
    } else {
        return result
    }
}

export {
    getAllLocationsBySupplier,
    getSupplierBooking
}