import React from 'react';
import {formatCurrencyValue, parseCurrencyValue, validateCurrencyValue} from "../../util/PaymentUtils";
import {FormGroup} from "react-bootstrap";

const ChargeAmountFormGroup = (props) => {
  const {value, onChange} = props;

  const handleOnChange = (e) => {
    if (!validateCurrencyValue(e.target.value)) {
      return;
    }
    onChange(parseCurrencyValue(e.target.value));
  }

  return <FormGroup className='mb-4'>
    <label className='fw-bold' htmlFor={'charge-amount'}>Charge Amount*</label>
    <input type={'text'}
           id={'charge-amount'}
           className={'form-control'}
           value={formatCurrencyValue(value, true)}
           onChange={handleOnChange}
           maxLength={10}
           placeholder={"Enter the amount to charge the buyer for the service fee"}
    />
  </FormGroup>
}

export default ChargeAmountFormGroup;
