import React from 'react';
import { formatCurrencyValue } from '../../util/PaymentUtils';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  input: {
      marginLeft: "0 !important",
  }
}));

const SubscriptionAmount = (props) => {
  const { amount, handleChange } = props;
  const classes = useStyles();

  return (
    <fieldset className="ss-securspace-amount ss-stand-alone">
      <label>
        AMOUNT
        <input
          className={classes.input}
          type="text"
          name="amount"
          inputMode="decimal"
          value={formatCurrencyValue(amount ? amount : 0, true)}
          onChange={handleChange}
          placeholder="Enter the amount"
          maxLength={10}
        />
      </label>
    </fieldset>
  );
}

export default SubscriptionAmount;
