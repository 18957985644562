import "babel-polyfill";
import React from 'react';
import ReactDOM from 'react-dom/client';
import SecurSpaceApp from "./SecurSpaceApp";
import {unregister} from './registerServiceWorker';
import '../src/css/theme/hs-elements.css';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import {asyncWithLDProvider} from "launchdarkly-react-client-sdk";

(async () => {
  const AsyncLDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_ACCESS_KEY
  });
  ReactDOM.createRoot(document.getElementById('root')).render(
    <AsyncLDProvider>
      <StyledEngineProvider injectFirst>
        <SecurSpaceApp />
      </StyledEngineProvider>,
    </AsyncLDProvider>
  )

  unregister();
})();