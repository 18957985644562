import { Box } from '@mui/material';
import React from 'react';
import { ListGroupItem } from "react-bootstrap";


const InvoiceInformationContent = (props) => {
  const { bookingTransaction } = props;

  const informationArray = [
    {
      label: "Invoice Number",
      value: bookingTransaction?.transactionNumber
    },
    {
      label: "Invoice Date",
      value: bookingTransaction?.createdOn
    },
    {
      label: "Location",
      value: bookingTransaction?.booking?.location?.locationName
    },
    {
      label: "Payment Method To Charge",
      value: bookingTransaction?.booking?.paymentMethodDescription
    },
  ]

  return (
    <Box mt={4}>
      <h3 style={{ float: "none" }} className={'ss-title-no-image'}>INVOICE INFORMATION</h3>
      <Box>
        {informationArray.map((information) => (
          <ListGroupItem key={information.label}>
            <h6 style={{
              fontSize: '1.15rem',
              textTransform: "capitalize"
            }}>{information.label}</h6>
            <small style={{
              fontSize: '0.875rem',
              color: '#6c757d'
            }}>{information.value}</small>
          </ListGroupItem>
        ))}
      </Box>
    </Box>
  )
}

export default InvoiceInformationContent;
