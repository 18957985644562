import React, {Component} from 'react';
import ExternalLanding from "../ExternalLanding";

class HubSpotResourcesBuyer extends Component {
    render() {
        return <ExternalLanding url="https://launch.secur.space/buyer-resources" height="100%" />
    }
}

export default HubSpotResourcesBuyer;
