import { useState, useEffect } from "react";
import { getRentalAgreementURL } from "../util/BookingUtil";

const useRentalAgreementLink = (rentalAgreementFileName) => {

    const [rentalAgreementURL, setRentalAgreementURL] = useState();

    useEffect(() => {
        setRentalAgreementURL(getRentalAgreementURL(rentalAgreementFileName))
        
    }, [rentalAgreementFileName])

    return rentalAgreementURL;
}

export default useRentalAgreementLink;