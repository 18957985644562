import React from 'react';
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import type {PaymentMethod} from "../../types/PaymentMethod";
import {PaymentType} from "../constants/securspace-constants";

const PaymentMethodOption = (props: { paymentMethod: PaymentMethod }) => {
  const {paymentMethod} = props;
  return (
    <FormControlLabel
      value={paymentMethod.id}
      label={
        <><strong>{paymentMethod.type === PaymentType.CARD ? paymentMethod.cardBrand : paymentMethod.bankName}</strong> ending in {paymentMethod.lastFour}</>
      }
      control={<Radio />}
    />
  );
};

const PaymentMethodSelect = (props: {
  paymentMethods: Object[],
  onChange: () => void,
  value: string,
}) => {
  const {paymentMethods, onChange, value} = props;
  return <FormControl>
    <RadioGroup
      name={"paymentMethod"}
      onChange={onChange}
      value={value}
    >
      {paymentMethods.map((paymentMethod: PaymentMethod) => {
        return <PaymentMethodOption
          key={paymentMethod.id}
          paymentMethod={paymentMethod}
        />
      })}
    </RadioGroup>
  </FormControl>
};

export default PaymentMethodSelect;