import React, {useState, useEffect} from "react";
import {Grid, FormControl, TextField, Typography} from "@mui/material";
import DriverInfoForm from "./DriverInfoForm";
import type {GroupedLocationInventoryActivity} from "../../../types/GroupedLocationInventoryActivity";

type Props = {
    inventory: GroupedLocationInventoryActivity,
    setCorrectAssetInfo: (correctAssetInfo: {containerNumber: string, chassisNumber: string, trailerNumber: string, truckLicensePlateNumber: string}) => void,
    setDriverInfo: (driverInfo: {firstName: "", lastName: "", license: ""}) => void,
    driverFieldsRequired?: boolean,
};

const AssetNumberCorrectionForm = ({inventory, setCorrectAssetInfo, setDriverInfo, driverFieldsRequired}: Props) => {

    const {chassisNumber, truckLicensePlateNumber, containerNumber, trailerNumber, checkinDriverFirstName, checkinDriverLastName, checkinDriverLicenseNumber} = inventory;

    const [correctChassisNumber, setCorrectChassisNumber] = useState(chassisNumber);
    const [correctContainerNumber, setCorrectContainerNumber] = useState(containerNumber);
    const [correctTrailerNumber, setCorrectTrailerNumber] = useState(trailerNumber);
    const [correctTruckLicensePlateNumber, setCorrectTruckLicensePlateNumber] = useState(truckLicensePlateNumber);


    const handleChangeTextField = ({target}) => {
        const {value, name} = target;
        switch(name) {
            case "trailerNumber":
                setCorrectTrailerNumber(value);
                break;
            case "containerNumber":
                setCorrectContainerNumber(value);
                break;
            case "chassisNumber":
                setCorrectChassisNumber(value);
                break;
            case "truckLicensePlateNumber":
                setCorrectTruckLicensePlateNumber(value);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        setCorrectAssetInfo({containerNumber: correctContainerNumber, chassisNumber: correctChassisNumber, trailerNumber: correctTrailerNumber, truckLicensePlateNumber: correctTruckLicensePlateNumber})
    }, [correctChassisNumber, correctContainerNumber, correctTrailerNumber, correctTruckLicensePlateNumber])

    return <Grid item container>
            <Grid item container component={'section'} gap={2} direction={"column"}>
                <Typography variant="h6">Asset Details</Typography>
                <Typography variant={"body1"} color={"textPrimary"}>Enter the correct equipment numbers.</Typography>
                <Grid item>
                    <FormControl fullWidth>
                        <TextField 
                            id="ss-select-correction-chassis-number"
                            value={correctChassisNumber}
                            type="text" 
                            name="chassisNumber"
                            onChange={handleChangeTextField}
                            label="Chassis Number"
                        />
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl fullWidth>
                        <TextField 
                            id="ss-select-correction-container-number"
                            value={correctContainerNumber} 
                            type="text" 
                            name="containerNumber"
                            onChange={handleChangeTextField}
                            label="Container Number"
                        />
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl fullWidth>
                        <TextField 
                            id="ss-select-correction-trailer-number"
                            value={correctTrailerNumber} 
                            type="text" 
                            name="trailerNumber"
                            onChange={handleChangeTextField}
                            label="Trailer Number"
                        />
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl fullWidth>
                        <TextField 
                            id="ss-select-correction-truck-license-plate-number"
                            value={correctTruckLicensePlateNumber} 
                            type="text" 
                            name="truckLicensePlateNumber"
                            onChange={handleChangeTextField}
                            label="Truck License Plate Number"
                        />
                    </FormControl>
                </Grid>           
            </Grid>
            <DriverInfoForm 
                initialDriverFirst={checkinDriverFirstName}
                initialDriverLast={checkinDriverLastName}
                initialDriverLicense={checkinDriverLicenseNumber}
                setDriverInfo={setDriverInfo}
                required={driverFieldsRequired}
            />
    </Grid>

}

export default AssetNumberCorrectionForm;