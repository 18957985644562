export const styles = {
    modalContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '40em',
        width: '100%',
        backgroundColor: (theme) => theme.palette.background.paper,
        borderRadius: '0.5em',
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 4,
        py: 2,
        borderBottom: (theme) => `1px solid ${theme.palette.grey[100]}`,
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '1em',
        p: 4
    },
    image: {
        '& img': {
            width: '15em',
            height: '15em',
            objectFit: 'cover',
            borderRadius: '0.3em'
        }
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
    },
    rightAlignment: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    itemsAlign:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        gap:'1em',
        mt:2,
        mb:4,
        flexWrap:'wrap'
    },
    showMessage: {
        '& img': {
            width: "25em",
            height: '100%',
            '@media (max-width:39.28em)': {
                width: '100%',
            }
        }
    }
}