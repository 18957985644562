import React, {memo} from 'react';
import {Grid, useMediaQuery} from "@mui/material";
import DownloadCSVButton from "../report/export/DownloadCSVButton";
import DownloadGroupSummaryCSVButton from '../report/export/DownloadGroupSummaryCSVButton';
import { getPayoutsReportUrl, getPayoutsGroupSummaryUrl } from "./request/payouts-requests";
import { withSnackbar } from "../hocs/withSnackbar";
import { verbiage } from "./payouts-report-verbiage.js";

const groupSummaryOptions = [
  {value: 'location', label: verbiage.groupByLocation},
];

const PayoutsExportCSVs = (props: {
  item?: boolean,
  query: Object,
  snackbarShowMessage: (message: Object, severity: String, duration: Number, position?: {}) => void,
}) => {
  const { item, query, snackbarShowMessage } = props;
  const isMd = useMediaQuery(theme => theme.breakpoints.up('md'));
  const payoutsReportUrl = getPayoutsReportUrl(query);

  const handleDownloadReportError = () => {
    snackbarShowMessage(verbiage.errorFailedReportDownload, 'error');
  };

  const handleDownloadGroupSummaryError = () => {
    snackbarShowMessage(verbiage.errorFailedGroupSummaryDownload, 'error');
  };

  return (
    <Grid item={item} container gap={4} justifyContent={isMd ? 'flex-end' : 'space-between'} xs={12} md>
      <Grid item>
        <DownloadCSVButton reportUrl={payoutsReportUrl} title={verbiage.downloadReport} onError={handleDownloadReportError}/>
      </Grid>
      <DownloadGroupSummaryCSVButton
        onError={handleDownloadGroupSummaryError}
        queryParams={query}
        groupByOptions={groupSummaryOptions}
        getUrl={getPayoutsGroupSummaryUrl}
      />
    </Grid>
  )
}
export default withSnackbar(memo(PayoutsExportCSVs));