import React from 'react';
import {Grid} from "@mui/material";
import { NoDataMessage } from '@securspace/securspace-ui-kit'

const NoRecordsFound = (props: {
  type: String
}) => {
  const {type} = props;
  const defaultType = "filtered"

  const types = {
    filtered: { message: "No matching records found.", image: "/app-images/no-data-match.png" },
    unselected: { message: "No input selected, To view results kindly select the data above", image: "/app-images/person-and-box.svg" },
  };

  const getMessage = () => {
    return types[type] ? types[type].message : types[defaultType].message;
  }

  const getImage = () => {
    return types[type] ? types[type].image : types[defaultType].image;
  }

  return (
    <Grid>
      <NoDataMessage image={getImage()} message={getMessage()} />
    </Grid>
  );
};

export default NoRecordsFound;