import React, {useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import DownloadGroupSummaryCSVButton from "../../../report/export/DownloadGroupSummaryCSVButton";
import {getAdminInvoiceGroupSummaryReportUrl, getAdminInvoiceReportUrl} from "../../../../requests/invoice-requests";
import {getAdminInvoiceGroupSummaryOptions} from "../../../../requests/type-requests";
import {withSnackbar} from "../../../hocs/withSnackbar";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../../../util/NetworkErrorUtil";
import DownloadCSVButton from "../../../report/export/DownloadCSVButton";

type ExportCSVsProps = {
  query: Object,
  snackbarShowMessage: (message: Object, severity: String, duration: Number, position?: {}) => void,
}

/**
 * Renders the standard CSV download button and the group summary CSV download button for the admin invoice report.
 *
 * @param query The query parameters used in the API request that generates the report.
 * @param snackbarShowMessage
 * @returns {Element}
 * @constructor
 */
const ExportCSVs: (props: ExportCSVsProps) => JSX.Element = ({query, snackbarShowMessage}) => {
  const [groupSummaryOptions, setGroupSummaryOptions] = useState([]);

  useEffect(() => {
    getAdminInvoiceGroupSummaryOptions().then((response) => {
      setGroupSummaryOptions(response.body);
    }).catch((error) => {
      snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error');
    });
  }, [snackbarShowMessage]);

  return (
    <Grid item container justifyContent={'end'} gap={2}>
      <Grid item>
        <DownloadCSVButton title={'Download Report'} reportUrl={getAdminInvoiceReportUrl(query)} />
      </Grid>
      <DownloadGroupSummaryCSVButton
        queryParams={query}
        groupByOptions={groupSummaryOptions}
        getUrl={getAdminInvoiceGroupSummaryReportUrl}
        onError={(error) => snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error')}/>
    </Grid>
  );
};

export default withSnackbar(ExportCSVs);