import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    cardStyles: {
      width: '50%',
      height: '7.1em',
      borderRadius: '0.8em',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
      gap: '1em',
    },
    cardTitleActive: {
      fontFamily: 'Inter',
      fontSize: '1em',
      color: '#fff',
      fontWeight: '500',
      textTransform: 'capitalize',
      marginTop: '0.5em',
    },
    cardTitleDefault: {
      fontFamily: 'Inter',
      fontSize: '1em',
      color: '#000000',
      fontWeight: '500',
      textTransform: 'capitalize',
      marginTop: '0.5em',
    },
    paymentACH: {
      height: '3em',
      textTransform: 'capitalize',
      borderRadius: '0.8em !important',
      fontFamily: 'Inter',
      fontSize: '0.8em',
      fontWeight: '500',
      [theme.breakpoints.up('sm')]: {
        fontSize: '1em',
    }
    },
    title: {
      fontFamily: 'Inter',
      fontSize: '1.14em',
      fontWeight: '400',
      lineHeight: '1.78em',
      marginBottom: '.7em',
      marginTop: '.6em',
    },
  
    cardContent: {
      backgroundColor: '#F5F5F5',
      padding: '0.8em',
      borderRadius: '0.5em',
      marginTop: '1em',
      marginBottom: '1em',
    },
    cardContentFooter: {
      marginBottom: '.3em',
    },
    listTitle: {
      fontFamily: 'Inter',
      fontSize: '1em',
      fontWeight: '500',
      color: '#000000',
    },
    listDescription: {
      fontFamily: 'Inter',
      fontSize: '0.9em',
      fontWeight: '400',
      color: 'rgba(0, 0, 0, 0.6)',
      lineHeight: '1.28em',
      padding: '0em',
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5em',
      padding: '0em',
    },
    icon: {
      fontSize: '0.5em',
      color: 'rgba(0, 0, 0, 0.6)',
    }
  
  }));