import {makeStyles} from '@mui/styles'
import {Theme} from "@mui/material";

export const useStyles: (theme: Theme) => {
  blogMain: CSSStyleSheet,
  blogThumbnail: CSSStyleSheet,
  contentWrap: CSSStyleSheet,
  blogTitle: CSSStyleSheet,
  blogSubTitle: CSSStyleSheet,
} = makeStyles(theme => ({
  blogMain: {
    boxShadow: '0px 4px 12px 4px rgba(63, 81, 181, 0.05)',
    background: theme.palette.common.white,
    borderRadius: '0.8em',
    width: '20em',
    height: '23em',
    cursor: 'pointer',
    "@media (min-width: 550px)": {
      width: '100%',
    },
    "@media (min-width: 768px)": {
      width: '43%',
    },
    "@media (min-width: 990px)": {
      width: '20em',
    },
    '&:link': {
      color: 'unset',
      textDecoration: 'none',
    },
    '&:visited': {
      color: 'unset',
      textDecoration: 'none',
    },
    '&:focus': {
      color: 'unset',
      textDecoration: 'none',
    },
    '&:hover': {
      color: 'unset',
      textDecoration: 'none',
    },
    '&:active': {
      color: 'unset',
      textDecoration: 'none',
    },
  },
  blogThumbnail: {
    height: '10.5em',
    borderRadius: '10px 10px 0px 0px',
    overflow: 'hidden',
    objectfit: 'cover',
    width: '100%'
  },
  contentWrap: {
    padding: '1.71em'
  },
  blogTitle: {
    height: '6.28em',
    fontFamily: 'Inter',
    fontSize: '1.28em',
    color: theme.palette.secondary.main,
    fontWeight: '600',
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: 'capitalize',
    textAlign: 'left'
  },
  blogSubTitle: {
    fontFamily: 'Inter',
    fontSize: '1em',
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: '400'
  },
}));