import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(theme => ({
    mainContainer: {
        paddingTop: '2.5rem',
        paddingBottom: '2.86rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '8.04rem',
            paddingRight: '8.04rem',
        }
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
    },
    showMessage: {
        '& img': {
            width: "25em",
            height: '100%',
            '@media (max-width:39.28em)': {
                width: '100%',
            }
        }
    },
    bookingCard: {
        marginBottom: '1rem'
    }
}));

export default useStyles;