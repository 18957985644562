import React, { useRef } from 'react';
import { useListingImgStyles } from '../../../../hooks/components/location/useListingImgStyles';
import {constructGalleryImageUrl} from "../../../../util/ConstructImageUrlUtil";

const LocationListItemGalleryImage = (props) => {
  const { galleryImageFileName, locationId, ...other } = props;
  const classes = useListingImgStyles(props);
  const imageRef = useRef(null);
  const imageSrc = constructGalleryImageUrl(`${locationId}/${galleryImageFileName}`);

  return <div className={classes.container} >
    <img src={imageSrc} ref={imageRef} alt={'listing'} className={classes.img} {...other} />
  </div>
}

export default LocationListItemGalleryImage;
