import React from 'react';
import { Typography } from '@mui/material';

const TestimonialItem = (props) => {
    const { content, authorName, companyName, bookings, imageSrc } = props;


    return (

        <div
            className="ss-customer-partner-testimonials-item"
        >
            <Typography variant="body1" color="textPrimary" className="ss-customer-partner-testimonials-content">"{content}"</Typography>
            <div className='ss-customer-partner-testimonials-div'>
            {imageSrc && <img
                    alt="testimonial-company-logo"
                    className='ss-customer-partner-testimonials-image'
                    src={imageSrc}
                />}
                <div className="ss-customer-partner-testimonials-data-div">
                    <Typography variant="caption" color="textPrimary" className="ss-customer-partner-testimonials-data">{authorName}</Typography>
                    <Typography variant="caption" color="textPrimary" className="ss-customer-partner-testimonials-data">{companyName}</Typography>
                    <Typography variant="caption" color="textPrimary" className="ss-customer-partner-testimonials-data">{bookings}+ Bookings</Typography>
                </div>
                
            </div>
        </div>

    )
}

export default TestimonialItem
