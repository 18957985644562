import { makeStyles } from "@mui/styles";
import {Theme} from "@mui/material";

const useStyles: (theme: Theme) => {
    blueCard: CSSStyleSheet,
    title: CSSStyleSheet,
    container: CSSStyleSheet,
    section: CSSStyleSheet,
    heading: CSSStyleSheet,
    lpDivider: CSSStyleSheet,
    mainContainer: CSSStyleSheet,
    lpListingInfoSection: CSSStyleSheet,
} = makeStyles((theme) => ({
    blueCard: {
        backgroundColor: 'rgba(216, 248, 255, 1)'
    },
    mainSection:{
        backgroundColor: '#fff'
    },
    title: {
      fontWeight: 600,
      margin: '0.5em 0 1.5em 0'
    },
    container: {
        maxWidth: '97.14em !important',
        margin: '0 auto',
    },
    section: {
        padding: '2em 0em',
    },
    heading: {
        fontSize: '1.43em',
        fontWeight: '600',
        fontFamily: 'Inter',
        lineHeight: '2.28em',
        letterSpacing: '0.01em',
    },
    lpDivider: {
        height: '0.07rem',
        color: '#EEEEEE',
        marginTop: '1em',
    },
    mainContainer: {
        columnGap: '2.3em',
        marginTop: '2em',
        marginBottom: '8em',
    },
    lpListingInfoSection: {
        [theme.breakpoints.between('md', 'lg')]: {
            width: '57.14rem',
        }
    },
}));

const bookingPaymentStyle: (theme: Theme) => {
    agreementTitle: CSSStyleSheet,
    beforeBookContainer: CSSStyleSheet,
    beforeBookContainerSecond: CSSStyleSheet,
    beforeBookTitle: CSSStyleSheet,
    beforeSubTitle: CSSStyleSheet,
    button: CSSStyleSheet,
    noPayment: CSSStyleSheet,
    payIcon: CSSStyleSheet,
    paymentSaveInfoContainer: CSSStyleSheet,
    policyTitle: CSSStyleSheet,
    readmore: CSSStyleSheet,
} = makeStyles((theme) => ({
    button: {
        fontFamily: 'Inter',
        fontSize: '0.9em',
        fontWeight: '500',
        lineHeight: '1.57em',
        letterSpaceing: '0.03em',
    },
    payIcon: {
        marginRight: '0.3em',
    },
    paymentSaveInfoContainer: {
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: '0.5em',
        marginTop: '1.5em',
        marginBottom: '1.5em',
    },
    beforeBookTitle:{
        fontSize: '1.14em',
        fontWeight: '500',
        lineHeight: '1.71em',
        fontFamily: 'Inter',
        color: 'rgba(0, 0, 0, 0.87)',
        marginBottom: '1em',
    },
    noPayment:{
        fontWeight: '500',
        lineHeight: '1.71em',
        fontFamily: 'Inter',
        color: 'rgba(0, 0, 0, 0.87)',
        marginBottom: '2em',
        marginTop: '2em',
        fontSize: '1.71em',
        textAlign:'center'
    },
    policyTitle:{
        fontSize: '1.14em',
        fontWeight: '500',
        lineHeight: '1.71em',
        fontFamily: 'Inter',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    agreementTitle:{
        fontSize: '1em',
        fontWeight: '500',
        lineHeight: '1.57em',
        fontFamily: 'Inter',
        color: 'rgba(0, 0, 0, 0.87)',
        marginBottom: '1em',
    },
    beforeBookContainer:{
        backgroundColor: 'rgba(245, 245, 245, 1)',  
        padding: '1.5em',
        borderRadius: '0.5em',
        marginBottom: '1.5em',
    },
    beforeBookContainerSecond:{
        backgroundColor: 'rgba(255, 246, 216, 1)',  
        padding: '1.5em',
        borderRadius: '0.5em',
        marginBottom: '1.5em',
    },
    beforeSubTitle:{
        fontSize: '1em',
        fontWeight: '400',
        lineHeight: '1.57em',
        fontFamily: 'Inter',
        color: 'rgba(0, 0, 0, 0.87)',
        letteSpacing: '0.03em',
    },
    readmore:{
        fontSize: '1em',
        fontWeight: '500',
        fontFamily: 'Inter',
        padding: '0',
        marginTop: '1.5em',
    }
}));

const bookingCardStyles: (theme: Theme) => {
    bookCardContainer: CSSStyleSheet,
    cardImg: CSSStyleSheet,
    cardTitle: CSSStyleSheet,
    circle: CSSStyleSheet,
    circleInner: CSSStyleSheet,
    gap: CSSStyleSheet,
} = makeStyles((theme) => ({
    bookCardContainer: {
        padding: '1.43em',
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        display:'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        width: '100%',
    },
    gap: {
        gap: '0.5em'
    },
    cardImg: {
        width: '3.42em',
        height: '2.28em',
        objectfit: 'cover'
    },
    cardTitle: {
        fontSize: '1.14em',
        lineHeight: '1.71em',
        fontWeight: '500',
        fontFamily: 'Inter'
    },
    circle: {
        width: '1.14em',
        height: '1.14em',
        borderRadius: '50%',
        border: `2px solid ${theme.palette.primary.main}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    circleInner:{
        width: '0.57em',
        height: '0.57em',
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
    }
}));

const bookingDetailsStyles = makeStyles((theme) => ({
    container: {
        boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '0.5em',
        padding: '1.5em',
    },
    heading: {
        fontSize: '1.71em',
        fontWeight: '500',
        lineHeight: '2.28em',
        fontFamily: 'Inter',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    detailsImg:{
        width: '100%',
        height: '11.42em',
        objectfit: 'cover',
        borderRadius: '0.5em',
    },
    requestType:{
        width: 'fit-content',
        marginTop: '1em',
    },
    title:{
        fontSize: '1.14em',
        fontWeight: '500',
        lineHeight: '1.71em',
        fontFamily: 'Inter',
        color: 'rgba(0, 0, 0, 0.87)',
        letteSpacing: '0.03em',
    },
    icon:{
        color: 'rgba(0, 0, 0, 0.54)',
        marginRight: '0.2em',
    },
    cityState:{
        fontSize: '1em',
        fontWeight: '400',
        lineHeight: '1.42em',
        fontFamily: 'Inter',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    bookDetailsRow:{
        borderBottom: '1px solid rgba(238, 238, 238, 1)',
        marginTop: '1.5em',
        paddingBottom: '0.5em !important',
    },
    bookingDetailsLabel:{
        fontSize: '0.85em',
        color: 'rgba(0, 0, 0, 0.6)',
        fontWeight: '400',
        fontFamily: 'Inter',
        textTransform: 'uppercase',
    },
    bookingDetailsTitle:{
        fontSize: '1.14em',
        fontWeight: '500',
        lineHeight: '1.71em',
        fontFamily: 'Inter',
    },
    penIcon:{
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
    titleRowTitle:{
        fontSize: '1.14em',
        fontWeight: '500',
        lineHeight: '1.71em',
        fontFamily: 'Inter',
        color: 'rgba(0, 0, 0, 0.6)',
        letteSpacing: '0.03em',
    },
    marginTop:{
        marginTop: '1.5em',
    }
}));


export {
    useStyles,
    bookingPaymentStyle,
    bookingCardStyles,
    bookingDetailsStyles
}