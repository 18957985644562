import React from 'react'

const internalContext = React.createContext({
    user: undefined,
    loadingUserDetails: false,
    lastSearchedLocation: {},
    setLastSearchedLocation: (): void => {},
    // eslint-disable-next-line
    updateUser: (user: Object, history?: { push: (path: String) => void }) : void => {},
    clearUser: () : void => {},
    logout: () : void => {},
    pendingApprovalCount:0,
    updatePendingApprovalCount: () => {},
    pendingInventoryCount:0,
    updatePendingInventoryCount: () => {},
})

internalContext.displayName = "SecurSpaceAppContext"

export const AppContext = internalContext;
