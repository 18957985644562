import React, {useState, useContext } from 'react';
import LandingSearchInput from './LandingSearchInput';
import {Grid, Typography} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {useNavigate} from 'react-router';
import LandingNav from "../../routing/LandingNav";
import classNames from 'classnames';
import {  slugify } from "../../util/GeoLocation";
import { AppContext } from '../../context/app-context';

const useStyles: {grid: CSSStyleSheet} = makeStyles(() => ({
  whiteShadowHeader: {
    color: '#FFFFFF',
    textShadow: '0rem 0.29rem 0.29rem rgba(0, 0, 0, 0.25)',
    fontSize:64
  },
  subheader: {
    fontFamily: 'Satoshi-Variable',
    fontWeight: 500,
    fontSize: '1.71rem',
    lineHeight: '2.29rem',
  },
  grid: {
    height: '10%'
  }
}));

const LandingHeader = () => {
  const classes = useStyles();
  const history = useNavigate();
  const [selectedPacItem, setSelectedPacItem] = useState({}); // update to use up/down events
  const geocoder = new window.google.maps.Geocoder();
  const appContext = useContext(AppContext);
  const { setLastSearchedLocation } = appContext;

  // moved location function to here
  const getLocation = (place) => {
    let locationGeometry = place ? place.geometry : null;
    return locationGeometry ? locationGeometry.location : null;
  }


  const redirectByPlacesAutocompleteItem = (pacItem) => {
    // need geo info to use current search screen.
    geocoder.geocode({placeId: pacItem.place_id}, (place, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const pacWithGeometry = {
          ...pacItem,
          geometry: {...place[0].geometry},
          address_components: {...place[0].address_components}
        }
        const foundLocation = getLocation(pacWithGeometry);
        setLastSearchedLocation(pacWithGeometry);

        if (!foundLocation) {
          history('/search');
        } else {
          history(`/search?$city=${slugify(place[0].formatted_address.split(',')[0].trim())}&state=${place[0].formatted_address.split(',')[1].trim().toLowerCase()}`);
        }
      }
    });
  }

  // if they click it, it should redirect immediately
  const handleClickPac = (pacItem) => {
    redirectByPlacesAutocompleteItem(pacItem);
  }

  const handleSelectPac = (pacItem) => {
    setSelectedPacItem(pacItem);
  }

  const handleOnSubmit = () => {
    if (selectedPacItem) {
      redirectByPlacesAutocompleteItem(selectedPacItem);
    } else {
      history('/search');
    }
  }


  return <header>
    <LandingNav />
    <Grid container
          className={'h-100'}
          direction={'column'}
          justifyContent={'center'}
          alignItems={'stretch'}
    >
      <Grid item>
        <Typography variant={'h1'} className={classes.whiteShadowHeader} align='center'>On-Demand Access to Yard Space</Typography>
      </Grid>
      <Grid item>
        <Typography variant={'h3'} align='center' className={classNames(classes.whiteShadowHeader, classes.subheader)}>
          It's simple and secure
        </Typography>
      </Grid>
      <Grid item className={classes.grid}></Grid>
      <Grid item>
        <LandingSearchInput name={'pacSearch'}
                            selectedPacItem={selectedPacItem}
                            handleClickPac={handleClickPac}
                            handleSelectPac={handleSelectPac}
                            onSearchSubmit={handleOnSubmit}
        />
      </Grid>
    </Grid>
  </header>
}

export default LandingHeader;