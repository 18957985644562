const RISKY_CSV_CHARS_PATTERN = /^[-+=@\t\r]/g;

export const cleanCsvData = (data) => {
  if (!data) {
    return null;
  }
  // if data is an array, recursively sanitize it
  if (Array.isArray(data)) {
    return data.map(eachValue => {
      return cleanCsvData(eachValue);
    });
  }
  // if data is an object, recursively clean the values of each key
  if (typeof data === 'object') {
    return Object.keys(data).reduce((acc, eachKey) => {
      acc[eachKey] = cleanCsvData(data[eachKey]);
      return acc;
    }, {});
  }
  // if it's a boolean or number, no need to sanitize
  if (typeof data === 'boolean' || typeof data === 'number') {
    return data;
  }
  /*
    we know now it's a string
    following https://owasp.org/www-community/attacks/CSV_Injection
    1# trim, react-cvs by default wraps values by double quotes enclosingCharacter={`"`}
    2# Prepend each cell field with a single quote
    3# Escape every double quote using an additional double quote
   */
  return data.trim().replace(RISKY_CSV_CHARS_PATTERN, match => `'${match}`).replace(/"/g, `""`);
}

