import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import { getLocationSuppliersInRadius } from '../requests/location-requests';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DateFormats } from '../../constants/securspace-constants';
import LocationProfileCardList from './locationProfileList/LocationProfileCardList';

const useStyles = makeStyles(() => ({
  headerContainer: {
    marginTop: '1.57rem'
  },
  headerText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '1.43em',
    lineHeight: '160%',
    letterSpacing: '0.01em',
  },
  listContainer: {
    marginTop: '2.07rem',
    marginBottom: '3.86rem',
  }
}));

const MILE_RADIUS = 15;
const MAX_PROFILES = 4;

const LocationProfileSimilarYardsNearby = (props) => {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const { location, mileRadius, maxProfiles } = props;
  const { locationId, addressLatitude, addressLongitude } = location;

  const classes = useStyles();

  useEffect(() => {
    if (!location) return;
    const today = moment(new Date());
    setLoading(true)
    getLocationSuppliersInRadius(
      addressLatitude,
      addressLongitude,
      mileRadius ? mileRadius : MILE_RADIUS,
      today.format(DateFormats.DAY),
      today.add(1, 'days').format(DateFormats.DAY),
      1
    ).then(response => {
      if (response.body) {
        setLoading(false)
        setListings(response.body.sort((a, b) =>  {
          return (Number.parseFloat(a.distance) || 0) - (Number.parseFloat(b.distance) || 0);
        }).filter(location => location.locationId !== locationId).slice(0, maxProfiles ? maxProfiles : MAX_PROFILES));
      }
    }).catch(() => {
      // do nothing
      setLoading(false)
    });
  }, [location, locationId, addressLatitude, addressLongitude, mileRadius, maxProfiles]);

  return (
    listings.length > 0 && <section className='w-100'>
      <Grid container item direction='column'>
        <Grid item xs className={classes.headerContainer}>
          <Typography component='h3' color='textPrimary' className={classes.headerText}>Similar yards nearby</Typography>
        </Grid>
        <Grid container item xs wrap='nowrap' className={classes.listContainer}>
          <LocationProfileCardList loading={loading} locations={listings} />
        </Grid>
      </Grid>
    </section>
  );
};

LocationProfileSimilarYardsNearby.propTypes = {
  location: PropTypes.object,
  mileRadius: PropTypes.number,
  maxProfiles: PropTypes.number,
};

export default LocationProfileSimilarYardsNearby;
