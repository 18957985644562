import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    boxStyles: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        height: '35em',
        borderRadius: '0.5em',
        boxShadow: '0px 9px 46px 8px #0000001F',
        position: ' absolute',
        top: '50%',
        left: '42%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            width: '38.37em',
            height: 'auto',
            maxHeight: '90vh',
        }
    },
    stickyTop:{
        position: 'sticky',
        top: 0,
        padding: '1.5em 1.5em .2em 1.5em',
    },
    fullContent:{
        flex: '1',
        overflowY: 'scroll',
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1.14em',
        fontWeight: '500',
        lineHeight: '1.78em',
        marginBottom: '1.5em',
    },
    subtitle: {
        fontFamily: 'Inter',
        fontSize: '1.14em',
        fontWeight: '500',
        lineHeight: '1.78em',
        marginBottom: '0.3em',
    },
    paymentWrapper: {
        width: '100%',
    },
    buttonGroup: {
        backgroundColor: 'rgba(245, 245, 245, 1)',
        justifyContent: 'flex-end',
        alignItems: 'center',
        display: 'flex',
        height: '3.71em',
        width: '100%',
    },
    button: {
        border: 'none !important',
        outline: 'none',
        backgroundColor: 'transparent',
        '&:hover': {
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent',
        },
    },
    contentWrap: {
        padding: '0em 1.5em .2em 1.5em',
    }
}));