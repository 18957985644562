import React, { useState } from 'react';
import ServiceFeeSelectFormGroup from "./ServiceFeeSelectFormGroup";
import {
  MaintenanceAndRepairSubTypes,
  ServiceFeeType
} from "../constants/securspace-constants";
import MaintenanceAndRepairFormGroup from "./MaintenanceAndRepairFormGroup";
import ChargeAmountFormGroup from "./ChargeAmountFormGroup";
import ServiceFeeBreakdown from "./ServiceFeeBreakdown";
import NotesFormGroup from "./NotesFormGroup";
import Error from '../Error';
import { Grid, CardContent, Typography, Alert, Button } from '@mui/material';
import { LocationOn, Person } from '@mui/icons-material'
import { FaCalendarAlt } from 'react-icons/fa';
import { styles } from './customer/ServiceFeeApproval';

const ServiceFeeChargeForm = (props) => {
  const { serviceFee, securspaceServiceFeePercentage, onSubmit, onCancel, onChange, taxRate, booking } = props;
  const showMaintenanceAndRepair = MaintenanceAndRepairSubTypes.includes(serviceFee.serviceFeeType);
  const [displayTaxMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');

  const handleServiceFeeTypeChange = (updatedServiceFeeType) => {
    const serviceFeeType = updatedServiceFeeType === '' ? ServiceFeeType.MUDFLAP_REPAIR : updatedServiceFeeType;
    onChange({
      ...serviceFee,
      serviceFeeType: serviceFeeType,
    });
  }

  const handleMaintenanceAndRepairChange = (updatedMaintenanceAndRepairDescription) => {
    onChange({
      ...serviceFee,
      serviceFeeType: updatedMaintenanceAndRepairDescription,
    });
  }

  const handleChargeAmountChange = (updatedChargeAmount) => {
    onChange({
      ...serviceFee,
      chargeAmount: updatedChargeAmount,
    });
  }


  const handleNotesChange = (updatedNotes) => {
    onChange({
      ...serviceFee,
      notes: updatedNotes,
    });
  }

  const handleSubmit = () => {
    if (!serviceFee?.serviceFeeType) {
      setErrorMessage('Please select a service fee type.');
      return;
    }
    if (serviceFee.chargeAmount < 50) {
      setErrorMessage('Please enter a charge amount more than $0.50.');
      return;
    }
    setErrorMessage('');
    onSubmit();
  }

  const commonStyles = {
    display: 'flex',
    alignItems: 'center'
  }

  return <Grid container flexDirection='column'>
    <Grid item sx={{ mt: 4 }}>
      <Grid item sx={{
        ...commonStyles,
        gap: '0.5em',
      }}>
        <Alert sx={() => ({ width: "100%" })} severity="info">Booking Information</Alert>
      </Grid>
      <CardContent sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography sx={{
              ...commonStyles,
              gap: '0.4em'
            }} variant="subtitle1" color="textSecondary"> <FaCalendarAlt /> Booking # </Typography>
            <Typography variant="subtitle2">{booking?.orderNumber}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography sx={{
              ...commonStyles,
              gap: '0.4em'
            }} variant="subtitle2" color="textSecondary"> <LocationOn /> Location </Typography> <Typography variant="subtitle2">{booking?.locationName}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography sx={{
              ...commonStyles,
              gap: '0.4em'
            }} variant="subtitle2" color="textSecondary"> <Person /> Buyer </Typography> <Typography variant="subtitle2">{booking?.buyerCompanyName}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Grid>
    <Grid item>
      <ServiceFeeSelectFormGroup value={MaintenanceAndRepairSubTypes.includes(serviceFee?.serviceFeeType) ? '' : serviceFee?.serviceFeeType}
        onChange={handleServiceFeeTypeChange}
      />
    </Grid>
    {
      showMaintenanceAndRepair && <Grid item>
        <MaintenanceAndRepairFormGroup value={serviceFee?.serviceFeeType}
          onChange={handleMaintenanceAndRepairChange}
        />
      </Grid>
    }
    <Grid item>
      <ChargeAmountFormGroup value={serviceFee.chargeAmount}
        onChange={handleChargeAmountChange}
      />
    </Grid>
    <Grid item>
      {
        displayTaxMessage ?
          <div className='ss-conditional-tax-message'> Tax funds will be sent to your account. You are responsible for remitting tax payments to government entities. </div>
          : ""
      }
    </Grid>
    <Grid item>
      <ServiceFeeBreakdown chargeAmount={serviceFee.chargeAmount}
        taxPercentage={taxRate}
        securspaceServiceFee={securspaceServiceFeePercentage}
      />
    </Grid>
    <Grid item>
      <NotesFormGroup value={serviceFee.notes}
        onChange={handleNotesChange}
      />
    </Grid>
    <Grid item>
      {
        errorMessage && errorMessage !== '' && <Error>{errorMessage}</Error>
      }
    </Grid>
    <Grid container sx={(theme) => ({
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    })} spacing={2}>
      <Grid sm={12} item md={6}>
        <Button
          onClick={handleSubmit}
          sx={styles.solid_btn}
          fullWidth
        >
          Charge
        </Button>
      </Grid>
      <Grid item sm={12} md={6}>
        <Button
          onClick={onCancel}
          sx={styles.outline_btn}
          fullWidth
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  </Grid>
}

export default ServiceFeeChargeForm;
