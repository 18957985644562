import React from 'react';
import {FormGroup} from "react-bootstrap";
import {MaintenanceAndRepairSubTypes, ServiceFeeTypeLabel} from "../constants/securspace-constants";

const MaintenanceAndRepairFormGroup = (props) => {
  const {onChange, value} = props;

  const handleOnChange = (e) => {
    onChange(e.target.value);
  }

  return <FormGroup>
    <label htmlFor={'select-sub-fee-type'}>Maintenance and Repair Type:*</label>
    <select id={'select-sub-fee-type'} className={'form-control'} placeholder={'Select Maintenance and Repair Sub Type'} value={value} onChange={handleOnChange}>
      {
        MaintenanceAndRepairSubTypes.map(maintenanceRepairType => <option value={maintenanceRepairType} key={maintenanceRepairType}>{ServiceFeeTypeLabel[maintenanceRepairType]}</option>)
      }
    </select>
  </FormGroup>
}

export default MaintenanceAndRepairFormGroup;
