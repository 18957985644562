import React, {useEffect, useState} from 'react';
import '../../css/components/report/report-form-date.css';
import ReportFormInputLabel from "./ReportFormInputLabel";
import {DateFormats} from "../constants/securspace-constants";
import moment from 'moment';

const ReportFormDateInput = (props) => {
  const {date, onDateChange, label} = props;
  const DATE_INPUT_FORMAT = 'yyyy-MM-DD';
  const [formattedDate, setFormattedDate] = useState(moment(date).format(DATE_INPUT_FORMAT));

  useEffect(() => {
    if (date && moment(date).isValid()) {
      setFormattedDate(moment(date).format(DATE_INPUT_FORMAT));
    } else {
      setFormattedDate(moment().format(DATE_INPUT_FORMAT));
    }
  }, [date])

  const handleDateChange = (event) => {
    const dateValue = event.target.value;
    onDateChange(moment(dateValue).format(DateFormats.DAY));
  }

  return <fieldset className="ss-report-form-date-fieldset">
    <ReportFormInputLabel label={label}/>
    <input type="date"
           className="ss-report-form-date"
           name="formDate"
           placeholder="MM/DD/YYYY"
           value={formattedDate}
           onChange={handleDateChange}
    />
  </fieldset>
}

export default ReportFormDateInput;
