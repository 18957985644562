import React from 'react';
import {TableCell} from "@mui/material";

const PartnerTableCells = () => {

  return <>
    <TableCell>Gate Clerk</TableCell>
    <TableCell>Gate Manager</TableCell>
  </>
}

export default PartnerTableCells;
