import React from 'react';
import { Grid, Typography, Tooltip } from '@mui/material';
import EquipmentTypeMap from './equipment/EquipmentTypeMap';
import '../../../css/components/locationProfilePriceBreakdown.css'
import { makeStyles } from '@mui/styles';
import InfoIcon from "@mui/icons-material/Info";


const useStyles = makeStyles({
    title: {
        fontWeight: 500,
        fontSize: '1rem',
        marginBottom: '1em',
    },
    price: {
        paddingRight: '2em',
    },
    icon: {
        marginLeft: '.3em',
    },
});

const LocationProfilePriceBreakdown = (props) => {
    const { equipmentTypeArray, defaultPricePerDay, defaultPricePerMonth, loading } = props;
    const classes = useStyles();


    const equipmentTypeInfo = "These are all types of equipment this facility can accept.";

    return (
        <Grid container className='ss-location-profile-pricing'>
            <Typography variant='h6' className="ss-location-profile-pricing-title">
                Prices
            </Typography>
            <Grid container>
                <Grid item className="ss-location-profile-pricing-grid-titles">
                    <div className="ss-location-profile-pricing-subcontainer-icon-title">
                        <Typography variant='subtitle2' className={classes.title}>
                            Equipment Type
                        </Typography>
                        <Tooltip title={equipmentTypeInfo} arrow placement="right">
                            <InfoIcon className={classes.icon} color={'secondary'}/>
                        </Tooltip>
                    </div>
                    <div className="ss-price-per-container">
                        <Typography variant='subtitle2' className={classes.price}>
                            Price / day
                        </Typography>
                        <Typography variant='subtitle2'>
                            Price / month
                        </Typography>
                    </div>
                </Grid>
                <EquipmentTypeMap
                    equipmentTypeArray={equipmentTypeArray}
                    defaultPricePerDay={defaultPricePerDay}
                    defaultPricePerMonth={defaultPricePerMonth}
                    loading={loading}
                />
            </Grid>
        </Grid>
    )
}

export default LocationProfilePriceBreakdown;
