import React, { useCallback, useMemo, useState } from 'react';
import { Modal } from "@mui/material";
import PaymentModalBody from "./payment/PaymentModalBody";
import RedesignMicroDepositForm from "./payment/RedesignMicroDepositForm";
import PlaidService from "../components/paymentMethods/processors/PlaidService";
import StripeService from "../components/paymentMethods/processors/StripeService";
import PropTypes from "prop-types";
import {withSnackbar} from "../components/hocs/withSnackbar";

const RedesignAddPaymentMethodSetup = React.forwardRef((props, ref) => {
  const {
    account,
    collectMicroDepositVerifyPayment,
    doneWithAddPaymentMethod,
    loadPaymentMethods,
    microDepositVerify,
    payWithAch,
    paymentModalOpen,
    setACH,
    setMicroDeposit,
    setPaymentModalOpen,
    snackbarShowMessage
  } = props;

  const [showMicroDepositVerify, setShowMicroDepositVerify] = useState(false);

  const handleModalClose = useCallback(() => {
    setPaymentModalOpen(!paymentModalOpen);
    setShowMicroDepositVerify(false);
    loadPaymentMethods();
  }, [setPaymentModalOpen, paymentModalOpen, setShowMicroDepositVerify, loadPaymentMethods]);

  const plaid = useMemo(() => {
    const authority = {
      id: account.id,
      email: account.email,
      userType: account.userType
    };
    const plaidEnv = {
      plaidEnvironment: account.plaidEnvironment,
      plaidClientName: account.plaidClientName,
      plaidPublicKey: account.plaidPublicKey,
    };
    return new PlaidService(plaidEnv, authority, handleModalClose, handleModalClose);
  }, [account, handleModalClose]);

  const stripe = useMemo(() => {
    const authority = {
      id: account.id,
      email: account.email,
      userType: account.userType
    };
    return new StripeService(
      {platformPublishedKey: account.platformPublishedKey},
      authority,
      handleModalClose,
      handleModalClose
    );
  }, [account, handleModalClose]);

  const launchAddPaymentMethod = () => {
    if (collectMicroDepositVerifyPayment) {
      setShowMicroDepositVerify(true);
    } else if (!payWithAch && !collectMicroDepositVerifyPayment) {
      handleStripePayment();
    } else {
      handlePlaidPayment();
    }
  };

  const handlePlaidPayment = () => {
    if (account.id) {
      plaid.openPlaidHandler();
    } else {
      snackbarShowMessage("There was an error launching Payment Service. Please try again later.", 'error', 15000);
    }
  }

  const handleStripePayment = () => {
    if (account.id) {
      stripe.openStripeCheckout();
    } else {
      snackbarShowMessage("There was an error launching Payment Service. Please try again later.", 'error', 15000);
    }
  }

  const modalStyles = {
    left: '15%'
  };

  return <Modal
    open={paymentModalOpen}
    style={modalStyles}
    onClose={handleModalClose}
  >
    <>
      {!showMicroDepositVerify ?
        <PaymentModalBody
          ref={ref}
          setACH={setACH}
          payWithAch={payWithAch}
          collectMicroDepositVerifyPayment={collectMicroDepositVerifyPayment}
          setMicroDeposit={setMicroDeposit}
          microDepositVerify={microDepositVerify}
          doneWithAddPaymentMethod={doneWithAddPaymentMethod}
          launchAddPaymentMethod={launchAddPaymentMethod}
        />
        :
        <RedesignMicroDepositForm
          account={account}
          setShowMicroDepositVerify={setShowMicroDepositVerify}
          handleModalClose={handleModalClose}
        />
      }
    </>
  </Modal>
});

RedesignAddPaymentMethodSetup.propTypes = {
  account: PropTypes.object.isRequired,
  collectMicroDepositVerifyPayment: PropTypes.bool.isRequired,
  doneWithAddPaymentMethod: PropTypes.func.isRequired,
  loadPaymentMethods: PropTypes.func.isRequired,
  microDepositVerify: PropTypes.bool.isRequired,
  payWithAch: PropTypes.bool.isRequired,
  paymentModalOpen: PropTypes.bool.isRequired,
  setACH: PropTypes.func.isRequired,
  setMicroDeposit: PropTypes.func.isRequired,
  setPaymentModalOpen: PropTypes.func.isRequired,
  snackbarShowMessage: PropTypes.func.isRequired,
};

export default withSnackbar(RedesignAddPaymentMethodSetup);
