import React from 'react'
import './style.css'
import { Grid, Typography } from '@mui/material'
import ForgotForm from './ForgotForm'
import { useNavigate } from 'react-router-dom'

const ForgotPassword = () => {

    const history = useNavigate()

    return (
        <section>
            <div className="ss-forgot-password-container" />
            <Grid className='ss-forgot-password-section-container' container direction='column' justifyContent='center' alignItems='center'>
                <Grid className='ss-forgot-password-content-container'>
                    <Typography className='ss-forgot-password-title'>Forgot your password?</Typography>
                    <Typography className='ss-forgot-password-subtitle'>
                        Enter your email address to reset your password. You may need to check your spam folder or unblock DoNotReply@secur.space
                    </Typography>
                    <ForgotForm />
                    <Grid className='ss-forgot-password-footer' container justifyContent='center' alignItems='center'>
                        <Typography className='ss-forgot-password-account-title'>Remembered your account details?</Typography>
                        <Typography component={"a"} onClick={() => history("/login")} className='ss-forgot-password-signup'>{" "} Log In</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </section>
    )
}

export default ForgotPassword