import { Button,Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import TextFieldWithDirtyValidation from './form/TextFieldWithDirtyValidation';
import { Link } from 'react-router-dom';
import { AccountType } from './constants/securspace-constants';
import classNames from 'classnames';
import request from 'superagent';
import { getErrorMessageForNonStandardAndStandardResponse } from '../util/NetworkErrorUtil';
import Busy from './Busy';
import { validateEmail, validatePassword, validatePhoneNumber } from '../util/ValidationUtils';
import SimpleRecaptcha from './form/SimpleRecaptcha';
import { Alert } from '@mui/material';
import PropTypes from 'prop-types';
import PhoneInput from "react-phone-number-input/input";
import {PhoneNumberTextFieldWithDirtyValidation} from "./form/PhoneNumberTextField";
import {Theme} from "@mui/material";
import {withSnackbar} from "./hocs/withSnackbar";
import TermsAndPolicyCheckbox from "./TermsAndPolicyCheckbox";

const useStyles: (theme: Theme) => {
    signUpContainer: CSSStyleSheet,
    signUpFormContainer: CSSStyleSheet,
    signUpForm: CSSStyleSheet,
    agreementLabel: CSSStyleSheet,
    agreementLabelPartner: CSSStyleSheet,
    submitButton: CSSStyleSheet,
    partnerSignUpFormTitle: CSSStyleSheet,
    loginLinkContainer: CSSStyleSheet,
    loginLinkText: CSSStyleSheet,
    appInput: CSSStyleSheet,
} = makeStyles((theme) => ({
    signUpContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '0.57rem',
        overflow: 'hidden',
        width: '100%',
    },
    signUpFormContainer: {
        padding: '2.5em',
        [theme.breakpoints.up('md')]: {
            padding: '3rem 4.6rem 0 4.6rem',
        }
    },
    signUpForm: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '0.71rem',
        marginTop: '1.43rem',
        width: '100%',
    },
    submitButton: {
        marginTop: '1.71em',
        marginBottom: "1em"
    },
    partnerSignUpFormTitle: {
        fontWeight: 700,
    },
    loginLinkContainer: {
        marginTop: '1.71rem',
        height: '4.29rem',
        backgroundColor: '#FAFAFA',
        borderRadius: '0 0 0.57rem 0.57rem',
    },
    loginLinkText: {
        color: theme.palette.secondary.main,
    },
    appInput: {
        marginBottom: '0.6rem',
        '& label':{
             fontSize:'1.14em',
             fontWeight: 400,
             fontFamily: 'Inter',
             lineHeight:'0.71em',  
             letterSpacing:'.05em',  
             color:'rgba(0, 0, 0, 0.38)',
        }
     },
}));

const SignUpForm = (props) => {
    const type = props.type || AccountType.BUYER;
    const snackbarShowMessage = props.snackbarShowMessage;
    const {externalForm = false, externalSubmitForm} = props;

    const [companyName, setCompanyName] = useState('');
    const [companyNameErrorMsg, setCompanyNameErrorMsg] = useState('Please enter your company name.');
    const [firstName, setFirstName] = useState(props.socialLoginTemporaryUser?.user ? props.socialLoginTemporaryUser.user.first_name : '');
    const [firstNameErrorMsg, setFirstNameErrorMsg] = useState('Please enter your first name.');
    const [lastName, setLastName] = useState(props.socialLoginTemporaryUser?.user ? props.socialLoginTemporaryUser.user.last_name : '');
    const [lastNameErrorMsg, setLastNameErrorMsg] = useState('Please enter your last name.');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberErrorMsg, setPhoneNumberErrorMsg] = useState('Please enter a phone number.');
    const [email, setEmail] = useState(props.socialLoginTemporaryUser?.user ? props.socialLoginTemporaryUser.user.email : '');
    const [emailErrorMsg, setEmailErrorMsg] = useState('Please enter an email address.');
    const [password, setPassword] = useState('');
    const [passwordErrorMsg, setPasswordErrorMessage] = useState('Please enter a password.');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState('Passwords do not match.');
    const [agreementAccepted, setAgreementAccepted] = useState(false);
    const [agreementAcceptedErrorMsg, setAgreementAcceptedErrorMsg] = useState('Please accept the agreements.');
    const [recaptchaInstance, setRecaptchaInstance] = useState(null);
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const [recaptchaErrorMsg, setRecaptchaErrorMsg] = useState('');
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
    const [formErrorMsg, setFormErrorMsg] = useState('');

    const disableCaptcha = process.env.REACT_APP_DISABLE_RECAPTCHA;
    const classes = useStyles();

    const simpleTextValidation = (value, label) => {
        if (!value || !value.trim()) {
            return `Please enter your ${label}.`;
        }
        return '';
    };

    const confirmPasswordValidation = (value) => {
        if (!value || !value.trim()) {
            return "Please enter a password.";
        }
        return value === password ? '' : 'Passwords do not match.';
    };

    const phoneNumberValidation = (value) => {
        if (!value || !value.trim()) return 'Please enter a phone number.';
        return validatePhoneNumber(value, true) ? '' : 'Phone number must be 10 digits.';
    };

    const emailValidation = (value) => {
        if (!value || !value.trim()) return 'Please enter an email address.';
        return validateEmail(value) ? '' : 'Email address is invalid.';
    };

    const handleSimpleTextChange = (event) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        switch (fieldName) {
            case 'companyName':
                setCompanyName(fieldValue);
                setCompanyNameErrorMsg(simpleTextValidation(fieldValue, 'company name'));
                break;
            case 'firstName':
                setFirstName(fieldValue);
                setFirstNameErrorMsg(simpleTextValidation(fieldValue, 'first name'));
                break;
            case 'lastName':
                setLastName(fieldValue);
                setLastNameErrorMsg(simpleTextValidation(fieldValue, 'last name'));
                break;
            case 'email':
                setEmail(fieldValue);
                setEmailErrorMsg(emailValidation(fieldValue));
                break;
            case 'confirmPassword':
                setConfirmPassword(fieldValue);
                setConfirmPasswordErrorMsg(confirmPasswordValidation(fieldValue));
                break;
            default:
                break;
        }
    };

    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        setAgreementAccepted(checked);
        setAgreementAcceptedErrorMsg(checked ? '' : 'Please accept the agreement.');
    };

    const handlePhoneNumberChange = (value) => {
        setPhoneNumber(value || '');
        setPhoneNumberErrorMsg(phoneNumberValidation(value));
    };

    const handlePasswordChange = (event) => {
        const value = event.target.value;
        setPassword(value);
        if (!validatePassword(value)) {
            setPasswordErrorMessage('Password must have at least: 8 characters, one uppercase, one number, and one symbol.')
        } else {
            setPasswordErrorMessage('');
        }
        if (value !== confirmPassword) {
            setConfirmPasswordErrorMsg('Passwords do not match.');
        }
    };

    const verifyRecaptchaCallback = (response) => {
        if (response) {
            setRecaptchaVerified(true);
        }
    };

    const handleExpiredRecaptcha = () => {
        setRecaptchaVerified(false);
    };

    const handleRecaptchaLoaded = () => {
        setRecaptchaLoaded(true);
    };

    const signup = async (body) => {
        return request.post('/api/signup').send(body);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!disableCaptcha) {
            if (!recaptchaLoaded) {
                setRecaptchaErrorMsg('Unable to verify recaptcha, please try again or refresh the page.');
                return;
            } else if (!recaptchaVerified) {
                setRecaptchaErrorMsg('Please verify that you are a human.');
                return;
            } else {
                setRecaptchaErrorMsg('');
            }
        }

        if (companyNameErrorMsg ||
            firstNameErrorMsg ||
            lastNameErrorMsg ||
            phoneNumberErrorMsg ||
            emailErrorMsg ||
            passwordErrorMsg ||
            confirmPasswordErrorMsg ||
            agreementAcceptedErrorMsg
        ) {
            setFormErrorMsg('Please complete the form to proceed.');
            return;
        } else {
            setFormErrorMsg('');
        }
        const body = {
            type: type,
            email: email,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            username: email,
            password: password,
            companyName: companyName,
        };
        Busy.set(true);
        signup(body).then(response => {
            Busy.set(false);
            if (!disableCaptcha) {
                recaptchaInstance.reset();
                setRecaptchaVerified(false);
            }
            if (response.body) {
                props.handleAccountChange(response.body);
            }
        }).catch(error => {
            Busy.set(false);
            if (!disableCaptcha) {
                recaptchaInstance.reset();
                setRecaptchaVerified(false);
            }
            const errorMessage = getErrorMessageForNonStandardAndStandardResponse(error);
            
            if(!externalForm) {
                snackbarShowMessage(errorMessage, 'error', 15000);
            }
        });

        if (externalForm) {
            externalSubmitForm()
        }
    };

    return (
        <Paper className={classes.signUpContainer} elevation={2} >
            <Grid container className={classes.signUpFormContainer} >
                {type === AccountType.BUYER ?
                    <Typography variant='h5' component='h1' className={classes.partnerSignUpFormTitle}>Sign Up as a Buyer</Typography>
                    : <Typography variant='h5' component='h1' className={classes.partnerSignUpFormTitle}>Sign Up as a Supplier</Typography>
                }
                <form onSubmit={handleSubmit} className={classes.signUpForm} noValidate>
                    <TextFieldWithDirtyValidation
                        value={companyName}
                        onChange={handleSimpleTextChange}
                        name='companyName'
                        label='Company Name'
                        placeholder='Please enter your company name'
                        error={!!companyNameErrorMsg}
                        helperText={companyNameErrorMsg}
                        fullWidth
                        required
                    />
                    <TextFieldWithDirtyValidation
                        value={firstName}
                        onChange={handleSimpleTextChange}
                        name='firstName'
                        label='First Name'
                        placeholder='Enter your first name'
                        error={!!firstNameErrorMsg}
                        helperText={firstNameErrorMsg}
                        fullWidth
                        required
                    />
                    <TextFieldWithDirtyValidation
                        value={lastName}
                        onChange={handleSimpleTextChange}
                        name='lastName'
                        label='Last Name'
                        placeholder='Enter your last name'
                        error={!!lastNameErrorMsg}
                        helperText={lastNameErrorMsg}
                        fullWidth
                        required
                    />
                    <PhoneInput
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      inputComponent={PhoneNumberTextFieldWithDirtyValidation}
                      defaultCountry={"US"}
                      error={!!phoneNumberErrorMsg}
                      helperText={phoneNumberErrorMsg}
                      fullWidth
                      required
                      className={classes.appInput}
                    />
                    <TextFieldWithDirtyValidation
                        value={email}
                        onChange={handleSimpleTextChange}
                        name='email'
                        label='Email'
                        placeholder='Enter your email'
                        type='email'
                        error={!!emailErrorMsg}
                        helperText={emailErrorMsg}
                        fullWidth
                        required
                    />
                    <TextFieldWithDirtyValidation
                        value={password}
                        onChange={handlePasswordChange}
                        name='password'
                        label='Password'
                        placeholder='Create a password with at least 8 characters'
                        type='password'
                        error={!!passwordErrorMsg}
                        helperText={passwordErrorMsg}
                        fullWidth
                        required
                    />
                    <TextFieldWithDirtyValidation
                        value={confirmPassword}
                        onChange={handleSimpleTextChange}
                        name='confirmPassword'
                        label='Confirm Password'
                        placeholder='Repeat your password'
                        type='password'
                        error={!!confirmPasswordErrorMsg}
                        helperText={confirmPasswordErrorMsg}
                        fullWidth
                        required
                    />
                    <TermsAndPolicyCheckbox checked={agreementAccepted} onChange={handleCheckboxChange} accountType={type} name={'agreementAccepted'} externalForm={externalForm} />
                    {
                        !disableCaptcha &&
                            <SimpleRecaptcha
                            setRef={setRecaptchaInstance}
                            verifyCallback={verifyRecaptchaCallback}
                            expiredCallback={handleExpiredRecaptcha}
                            onloadCallback={handleRecaptchaLoaded}
                            />
                    }
                    
                    {
                        recaptchaErrorMsg && <Alert severity='error'>{recaptchaErrorMsg}</Alert>
                    }
                    {
                        formErrorMsg && <Alert severity='error'>{formErrorMsg}</Alert>
                    }
                    <Button type='submit' variant='contained' color='secondary' fullWidth className={classes.submitButton}>Agree & Sign Up</Button>
                </form>
            </Grid>
            {
                !externalForm ? 
                    <Grid container className={classNames('w-100', classes.loginLinkContainer)} alignContent='center'>
                        <Typography variant="subtitle2" align='center' color="textPrimary" className='w-100'>
                            Already have an account? <Link to={{ pathname: "/login" }} className={classes.loginLinkText}>Log In</Link>
                        </Typography>
                    </Grid>
                : ""
            }
        </Paper>
    );
};

SignUpForm.propTypes = {
    type: PropTypes.oneOf([AccountType.BUYER, AccountType.SUPPLIER]),
    handleAccountChange: PropTypes.func.isRequired,
    socialLoginTemporaryUser: PropTypes.object
};

export default withSnackbar(SignUpForm);
