import React from 'react';
import PropTypes from 'prop-types';
import SimpleInfoPanel from '../SimpleInfoPanel';
import { makeStyles } from '@mui/styles';
import { formatCurrencyValue } from '../../util/PaymentUtils';
import BookSpaceInfoPanelContent from './profile/BookSpaceInfoPanelContent';

const useStyles = makeStyles(theme => ({
  payoutSummaryInfoPanel: {
    padding: '1.43rem',
    backgroundColor: theme.palette.grey[100],
    borderRadius: '0.57rem',
  }
}));

const PayoutSummaryInfoPanelContent = ({ customerAmount, partnerAmount, securSpaceAmount }) => {
  const panelData = [
    {
      label: 'Customer Amount',
      value: formatCurrencyValue(customerAmount || 0, true),
    },
    {
      label: 'Partner Amount',
      value: formatCurrencyValue(partnerAmount || 0, true),
    },
    {
      label: 'SecurSpace Amount',
      value: formatCurrencyValue(securSpaceAmount || 0, true),
    }
  ];
  return (
    <BookSpaceInfoPanelContent data={panelData} />
  );
};

const PayoutSummaryInfoPanel = (props) => {
  const { customerAmount, partnerAmount, securSpaceAmount } = props;
  const classes = useStyles();

  return (
    <SimpleInfoPanel
      title='Payout Summary'
      content={<PayoutSummaryInfoPanelContent customerAmount={customerAmount} partnerAmount={partnerAmount} securSpaceAmount={securSpaceAmount} /> }
      className={classes.payoutSummaryInfoPanel}
    />
  );
};

PayoutSummaryInfoPanel.propTypes = {
  customerAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  partnerAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  securSpaceAmount: PropTypes.number,
};

export default PayoutSummaryInfoPanel;
