import React from "react";
import { Grid, Skeleton } from "@mui/material";
import EquipmentTypeItem from "./EquipmentTypeItem";

const EquipmentTypeMap = (props) => {
    const { equipmentTypeArray, defaultPricePerDay, defaultPricePerMonth, loading } = props;
    return (
        <Grid container>
            {loading ? [...Array(4).keys()].map((_, index) => <Skeleton variant="text" key={index} />) : equipmentTypeArray?.map((asset) => <EquipmentTypeItem
                key={asset.id}
                asset={asset}
                defaultPricePerDay={defaultPricePerDay}
                defaultPricePerMonth={defaultPricePerMonth}
            />)}

        </Grid>
    )
}

export default EquipmentTypeMap;