import React from 'react';
import ExternalLanding from "./ExternalLanding";
import {useSearchParams} from "react-router-dom";

export const Contact = () => {
  const [searchParams] = useSearchParams();
  const utm_source = searchParams.get('utm_source');
  const utm_medium = searchParams.get('utm_medium');
  const utm_campaign = searchParams.get('utm_campaign');
  const utm_term = searchParams.get('utm_term');
  const utm_content = searchParams.get('utm_content');
  const utm_id = searchParams.get('utm_id');

  const constructedUrl = `https://launch.secur.space/contact-us?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&utm_term=${utm_term}&utm_content=${utm_content}&utm_id=${utm_id}`;

  return <ExternalLanding url={constructedUrl} height="100%"/>
}

export default Contact;
