import React, { useContext, useEffect, useState } from 'react';
import { LEFT_NAV_HOME, RIGHT_NAV } from "./nav/DefaultNav";
import BaseNav from "./BaseNav";
import { AppContext } from '../context/app-context';
import { readPendingBooking } from './SecurSpaceContent';
import UserBasedNav from './UserBasedNav';
import { inventoryCorrectionCount } from '../components/inventory-correction/requests/inventory-correction-requests';

export const mergeNavigationArray = (leftNav, rightNav) => {
    const mergeArray = [
        ...(leftNav ? leftNav : []),
        ...(rightNav ? rightNav : []),
    ];
    const filterUndefined = mergeArray.filter((menu) => menu !== undefined)
    return filterUndefined
}

const LandingNav = () => {

    const context = useContext(AppContext)
    const [pendingInventoryCount, setPendingInventoryCount] = useState(null)
    const { user, pendingApprovalCount, updatePendingApprovalCount } = context

    useEffect(() => {
        readPendingBooking(user, updatePendingApprovalCount)
        inventoryCorrectionCount(user, setPendingInventoryCount)
    }, [user])


    return user ? <UserBasedNav isHomeNav={true} pendingApprovalCount={pendingApprovalCount} pendingInventoryCount={pendingInventoryCount} /> : <BaseNav
        rightNavOptions={user ? null : mergeNavigationArray(LEFT_NAV_HOME, RIGHT_NAV)}
        fontColorOverride={true}
        logoUrl={"../../app-images/logo/secure-space_white-logo_WTG.svg"}
    />
}

export default LandingNav;
