import React, {useContext} from 'react'
import { AppContext } from "../context/app-context";
import { determineRightNav } from "./nav/DefaultNav";
import BaseNav from "./BaseNav";
import {getUserBasedNavOptions} from './nav-options';
import { mergeNavigationArray } from './LandingNav';

const UserBasedNav = ({ pendingApprovalCount, pendingInventoryCount, isHomeNav = false }) => {
    const context = useContext(AppContext)
    const { user } = context
    const leftNavWithoutAccount = getUserBasedNavOptions(user);
    const rightNav = determineRightNav(user);


    return  <BaseNav
        pendingInventoryCount={pendingInventoryCount}
        rightNavOptions={mergeNavigationArray(leftNavWithoutAccount, rightNav)}
        pendingApprovalCount={pendingApprovalCount}
        logoUrl={isHomeNav ? `../../app-images/logo/secure-space_white-logo_WTG.svg` : "../../app-images/logo/secure-space_white-blue_WTG.svg"}
        fontColorOverride={isHomeNav}
    />
}

export default UserBasedNav
