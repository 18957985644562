import React from 'react';
import {
  InventoryCorrectionCheckinReasonLabels,
  InventoryCorrectionCheckinCheckoutReasonLabels
} from "../../constants/securspace-constants";
import {SelectChangeEvent} from "@mui/material";
import type {GroupedLocationInventoryActivity} from "../../../types/GroupedLocationInventoryActivity";
import SimpleSelect from "../../SimpleSelect";

type Props = {
  value?: string,
  onChange?: (event: SelectChangeEvent) => void,
  groupedLocationInventoryActivity?: GroupedLocationInventoryActivity,
}

const ReasonSelect: (props: Props) => JSX.Element = ({value, onChange, groupedLocationInventoryActivity}: Props) => {
  let options: {
    label: string,
    value: string
  }[] = groupedLocationInventoryActivity?.ids.length === 2 ? InventoryCorrectionCheckinCheckoutReasonLabels : InventoryCorrectionCheckinReasonLabels;
  return (
    <SimpleSelect id={"ss-select-correction-reason"} label={"Select Reason (required)"} value={value} onChange={onChange} options={options}/>
  );
};

export default ReasonSelect;