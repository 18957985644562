import useGetRequestBody from "../../useGetRequestBody";
import {getAllLocationNames} from "../../../components/location/requests/location-requests";
import {useMemo} from "react";

/**
 * Returns a list of all currently logged-in user's locations with an empty string id and 'All' location name
 * @param showMessage
 * @returns {[{id: string, locationName: string,},...*]}
 */
const useAllLocationNames = (showMessage?: (message: Object, severity: String, duration: Number, position?: {}) => void ) => {
  const allLocations = useGetRequestBody(getAllLocationNames, [], showMessage);

  return useMemo(() => {
    return [{id: '', locationName: 'All'}, ...allLocations];
  }, [allLocations]);
};

export default useAllLocationNames;