import moment from "moment";
import request from "../../../../util/SuperagentUtils";
import { DateFormats } from "../../../constants/securspace-constants";

const BASE_URL = "/api/invoices/buyers";

const getBuyerInvoices = (
    searchParams,
    onSuccess,
    onFail
) => {

    const { buyerAccountId, page, size, startDate, endDate, locationId, bookingNumber, invoiceNumber, transactionType, status, sortBy, sortDir } = searchParams;

    let pageParam = ["page", page || 0];
    let sizeParam = ["size", size || 10];

    let params = new URLSearchParams([pageParam, sizeParam]);

    if (startDate) {
        params.append("startDate", moment(startDate).format(DateFormats.DAY));
    }
    if (endDate) {
        params.append("endDate", moment(endDate).format(DateFormats.DAY));
    }
    if (locationId) {
        params.append("locationId", locationId)
    }
    if (bookingNumber) {
        params.append("bookingNumber", bookingNumber)
    }
    if (invoiceNumber) {
        params.append("transactionNumber", invoiceNumber)
    }
    if (transactionType) {
        params.append("transactionType", transactionType)
    }
    if (status) {
        params.append("status", status)
    }
    if (sortBy) {
        params.append("sortBy", sortBy)
    }
    if (sortDir) {
        params.append("sortDir", sortDir)
    }


    const result = request.get(`${BASE_URL}/${buyerAccountId}?${params}`);

    if (onSuccess && onFail) {
        result
            .then(onSuccess)
            .catch(onFail)
    } else {
        return result
    }
}

/**
 * Get the url for the buyer invoices report
 * @param buyerAccountId
 * @param searchParams
 * @returns {string}
 */
const getBuyerInvoicesReportUrl: (
  buyerAccountId: string,
  searchParams: {
      startDate?: string,
      endDate?: string,
      locationId?: string,
      bookingNumber?: string,
      invoiceNumber?: string,
      transactionType?: string,
      status?: string,
      sortBy?: string,
      sortDir?: string
}) => string = (buyerAccountId, searchParams) => {
    const { startDate, endDate, locationId, bookingNumber, invoiceNumber, transactionType, status, sortBy, sortDir } = searchParams;
    const queryParams = new URLSearchParams();
    if (startDate) queryParams.append("startDate", startDate);
    if (endDate) queryParams.append("endDate", endDate);
    if (locationId) queryParams.append("locationId", locationId);
    if (bookingNumber) queryParams.append("bookingNumber", bookingNumber);
    if (invoiceNumber) queryParams.append("invoiceNumber", invoiceNumber);
    if (transactionType) queryParams.append("transactionType", transactionType);
    if (status) queryParams.append("status", status);
    if (sortBy) queryParams.append("sortBy", sortBy);
    if (sortDir) queryParams.append("sortDir", sortDir);

    return `${BASE_URL}/${buyerAccountId}/report?${queryParams.toString()}`;
};

export {
    getBuyerInvoices,
    getBuyerInvoicesReportUrl
}