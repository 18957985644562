import React, { useState, useEffect } from 'react';
import PopularLocationListingItem from './PopularLocationListingItem';
import { geocodeLatLng } from '../util/GeoLocation';
import { toast } from 'react-toastify'

const SearchRedirectPopularLocationListingItem = (props) => {
    const [searchString, setSearchString] = useState("");
    
    useEffect(() => {
        if (props.cityObj?.coordinates) {
            const generateSearchString = (coordinates) => {
                if (!coordinates || !coordinates.length) return "";
                let coordinatesArr = coordinates.split(",");
                if (coordinatesArr.length < 2) return "";
    
                return geocodeLatLng(coordinatesArr[0], coordinatesArr[1].trim())
                    .then((location) => {
                        const locationItem = { city: location.city, state: location.state };
                        const searchString = `?city=${locationItem.city}&state=${locationItem.state}`;
                        setSearchString(searchString);
                        return searchString;
                    })
                    .catch((error) => {
                        toast.error(`SearchRedirectPopularLocationListingItem Error: ${error}`);
                        return "";
                    });
            };
    
            generateSearchString(props.cityObj.coordinates)
        }
    }, [props.cityObj]);

    return (<PopularLocationListingItem cityObj={props.cityObj} linkTo={{ pathname: "/search", search: searchString }} />);
};

export default SearchRedirectPopularLocationListingItem;
