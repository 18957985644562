import React, {useEffect, useState} from 'react';
import type {Booking} from "../../types/Booking";
import type {Account} from "../../types/Account";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme} from "@mui/material";
import UpdatePaymentMethodForm from "./UpdatePaymentMethodForm";
import {makeStyles} from "@mui/styles";
import {requestPaymentMethods} from "../paymentMethods/request/payment-method-requests";
import UpdatePaymentMethodSuccessBody from "./UpdatePaymentMethodSuccessBody";
import {getRentalAgreementURL} from "../../util/BookingUtil";
import {requestCompleteBooking, updatePaymentMethod} from "../bookedSpaces/request/booked-spaces-requests";
import Busy from "../Busy";
import type {PaymentMethod} from "../../types/PaymentMethod";
import {BankAccountStatus} from "../constants/securspace-constants";
import {withSnackbar} from "../hocs/withSnackbar";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../util/NetworkErrorUtil";

const useStyles: (theme: Theme) => {
  rowGap10: CSSStyleSheet,
  backgroundGrey100: CSSStyleSheet,
} = makeStyles((theme: Theme) => ({
  rowGap10: {
    rowGap: '0.71rem',
  },
  backgroundGrey100: {
    backgroundColor: theme.palette.grey[100],
  },
}));

const UpdatePaymentMethodDialog = (props: {
  open: boolean,
  onClose: () => void,
  booking: Booking,
  account: Account,
  onSuccess: () => void,
  snackbarShowMessage: (message: string) => void,
}) => {
  const {open, onClose, booking, account, onSuccess, snackbarShowMessage} = props;
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);
  const [agreeToTermsChecked, agreeToTermsCheckedSet] = useState(false);
  const [paymentUpdated, setPaymentUpdated] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (open && account) {
      Busy.set(true);
      const onSuccess = (paymentMethods) => {
        setPaymentMethods(paymentMethods.filter((paymentMethod: PaymentMethod) => {
          return paymentMethod.dwollaStatus !== BankAccountStatus.UNVERIFIED && paymentMethod.stripeStatus !== BankAccountStatus.UNVERIFIED;
        }));
        Busy.set(false);
      };
      const onError = (error) => {
        Busy.set(false);
        snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error');
      };
      requestPaymentMethods(account.id, account.userType, onSuccess, onError);
    }
  }, [open, account, snackbarShowMessage]);

  const resetForm = () => {
    setSelectedPaymentMethodId(null);
    agreeToTermsCheckedSet(false);
    setPaymentUpdated(false);
  };

  const onConfirm = () => {
    setPaymentUpdated(false);

    const bookingData = {
      id: booking.id,
      buyerAccountId: account.id,
      paymentMethodId: selectedPaymentMethodId,
      tosDocUrl: getRentalAgreementURL(booking.supplierLegalAgreementFileName),
    };
    Busy.set(true);
    updatePaymentMethod(bookingData).then((response) => {
      const updatedBooking = response.body;
      updatedBooking.buyerAccountId = updatedBooking.buyerAccount.id;
      updatedBooking.supplierAccountId = updatedBooking.supplierAccount.id;
      requestCompleteBooking(updatedBooking).then(() => {
        setPaymentUpdated(true);
        Busy.set(false);
      });
    }).catch(() => {
      Busy.set(false);
    });
  };

  const handleFormChange = (event) => {
    const {name, value, checked} = event.target;
    switch (name) {
      case 'paymentMethod':
        setSelectedPaymentMethodId(value);
        break;
      case 'agreeToTerms':
        agreeToTermsCheckedSet(checked);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    if (paymentUpdated) {
      onSuccess();
    } else {
      onClose();
    }
    resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={'ss-confirm-title'}
      aria-describedby={'ss-confirm-description'}
      fullWidth
    >
      <DialogTitle id={'ss-confirm-title'} variant={'h6'}>
        Update Payment Method
      </DialogTitle>
      <DialogContent id={'ss-confirm-description'}>
        {
          paymentUpdated ? <UpdatePaymentMethodSuccessBody/>
            : <UpdatePaymentMethodForm
              onChange={handleFormChange}
              booking={booking}
              account={account}
              paymentMethods={paymentMethods}
              selectedPaymentMethodId={selectedPaymentMethodId}
              agreeToTerms={agreeToTermsChecked}
            />
        }
      </DialogContent>
      <DialogActions className={classes.backgroundGrey100}>
        {
          paymentUpdated ? <Button color={'primary'} type={'submit'} onClick={handleClose}>
            Done
          </Button> : <>
            <Button color={'primary'} onClick={handleClose}>
              Cancel
            </Button>
            <Button color={'primary'} type={'submit'} onClick={onConfirm} disabled={!selectedPaymentMethodId || !agreeToTermsChecked}>
              Next
            </Button>
          </>
        }
      </DialogActions>
    </Dialog>
  );
};

export default withSnackbar(UpdatePaymentMethodDialog);