import React from 'react';

const ReportBackgroundContainer = (props) => {
  const { children } = props;

  return (
    <div className="flex h-100">
      <div className="grey-bg hs-bookings-container h-100">
        <div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default ReportBackgroundContainer;
