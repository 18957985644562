import React, {useMemo} from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {SelectChangeEvent} from "@mui/material";

type Props = {
  id?: string,
  label?: string,
  value?: string,
  onChange?: (event: SelectChangeEvent) => void,
  options?: { value: string, label: string }[],
};

const SimpleSelect = ({id = "select", label = "Select", value = "", onChange = () => {}, options = []}: Props) => {
  const memoizedOptions = useMemo(() => {
    return options.map(item => <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>)
  }, [options]);

  return (
    <FormControl variant='standard' fullWidth>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        id={id}
        onChange={onChange}
        value={value}
      >
        {memoizedOptions}
      </Select>
    </FormControl>
  );
};

export default SimpleSelect;