import request from "../util/SuperagentUtils";
import { getSpaceUsageUrlParams } from "../components/spaceUsage/request/space-usage-requests";

const BASE_URL = "/api/space-usage/supplier";

export const getSupplierSpaceUsageByPage:
  (accountId: string, locationId?: string, bookingNumber?: string, overageInvoiceNumber?: string, startDate?: string, endDate?: string, sortBy?: string, sortDir: string, page?: number, size?: number) =>
    Promise = (accountId, locationId, bookingNumber, overageInvoiceNumber, startDate, endDate, sortBy, sortDir, page, size) => {

  const queryParams = new URLSearchParams();
  if (locationId) queryParams.append("locationId", locationId);
  if (bookingNumber) queryParams.append("bookingNumber", bookingNumber);
  if (overageInvoiceNumber) queryParams.append("overageInvoiceNumber", overageInvoiceNumber);
  if (startDate) queryParams.append("startDate", startDate);
  if (endDate) queryParams.append("endDate", endDate);
  if (sortBy) queryParams.append("sortBy", sortBy);
  if (sortDir) queryParams.append("sortDir", sortDir);
  if (page !== null && page !== undefined) queryParams.append("page", page);
  if (size !== null && size !== undefined) queryParams.append("size", size);

  return request.get(`${BASE_URL}/${accountId}?${queryParams.toString()}`);
};

const getSupplierSpaceUsageReportQueryParams: (accountId: string, locationId: string, locationName: string, bookingNumber: string, overageInvoiceNumber: string, startDate: string, endDate: string, sortBy: string, sortDir: string) =>
  string = (accountId, locationId, locationName, bookingNumber, overageInvoiceNumber, startDate, endDate, sortBy, sortDir) => {
  const queryParams = new URLSearchParams();
  if (locationId) queryParams.append("locationId", locationId);
  if (locationName) queryParams.append("locationName", locationName);
  if (bookingNumber) queryParams.append("bookingNumber", bookingNumber);
  if (overageInvoiceNumber) queryParams.append("overageInvoiceNumber", overageInvoiceNumber);
  if (startDate) queryParams.append("startDate", startDate);
  if (endDate) queryParams.append("endDate", endDate);
  if (sortBy) queryParams.append("sortBy", sortBy);
  if (sortDir) queryParams.append("sortDir", sortDir);
  return queryParams.toString();
};

export const getSupplierSpaceUsageReportUrl: (accountId: string, locationId: string, locationName: string, bookingNumber: string, overageInvoiceNumber: string, startDate: string, endDate: string, sortBy: string, sortDir: string) =>
  string = (accountId, locationId, locationName, bookingNumber, overageInvoiceNumber, startDate, endDate, sortBy, sortDir) => {
  return `${BASE_URL}/${accountId}/report?${getSupplierSpaceUsageReportQueryParams(accountId, locationId, locationName, bookingNumber, overageInvoiceNumber, startDate, endDate, sortBy, sortDir)}`;
};

export const getSupplierSpaceUsageGroupSummaryReportUrl: (searchParams: {accountId: string; startDate: string; endDate: string; locationId: string; locationName: string, groupBy?: string}) =>
  string = (searchParams) => {
    let summaryParams;

    if(searchParams) {
        summaryParams= {
        startDate: searchParams.startDate,
        endDate: searchParams.endDate,
        locationId: searchParams.locationId,
        locationName: searchParams.locationName,
        groupBy: searchParams.groupBy
      }
    }

  return searchParams ? `/api/space-usage/supplier/${searchParams.accountId}/group-summary-report?${getSpaceUsageUrlParams(summaryParams, false)}` : "";

};