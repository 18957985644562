import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import PropTypes from 'prop-types';


const useStyles = makeStyles(() => ({
    appInput: {
        marginBottom: '0.6rem',
       '& input':{
            width:'100%'
       },
       '& label':{
            fontSize:'1em',
            fontWeight: 400,
            fontFamily: 'Inter',
            letterSpacing:'.05em',  
            color:'rgba(0, 0, 0, 0.38)',
            lineHeight: 'normal',
       }
    },
}));

const TextFieldWithDirtyValidation = (props) => {
    const [isDirty, setIsDirty] = useState(false);
    const classes = useStyles();

    const handleBlur = (event) => {
        setIsDirty(true);
        if (props.onBlur) {
            props.onBlur(event);
        }
    };

    return (
        <TextField
            {...props}
            onBlur={handleBlur}
            error={isDirty && props.error}
            helperText={(isDirty && props.error) || props.showHelperText ? props.helperText : undefined} 
            className={classes.appInput}
            variant="standard"
        />
    );
};

TextFieldWithDirtyValidation.propTypes = {
    onBlur: PropTypes.func,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    showHelperText: PropTypes.bool,
};

export default TextFieldWithDirtyValidation;