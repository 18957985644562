import { useState, useEffect } from "react";

export const usePasswordValidation = ({ password = "", confirmPassword = "" }) => {
    const [validLength, setValidLength] = useState(null);
    const [hasNumber, setHasNumber] = useState(null);
    const [upperCase, setUpperCase] = useState(null);
    const [specialChar, setSpecialChar] = useState(null);
    const [match, setMatch] = useState(null);

    useEffect(() => {
        setValidLength(password.length >= 8 ? true : false);
        setUpperCase(password.toLowerCase() !== password);
        setHasNumber(/\d/.test(password));
        setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password));
        setMatch(password && password === confirmPassword)

    }, [password, confirmPassword]);

    return [validLength, hasNumber, upperCase, match, specialChar];

}
