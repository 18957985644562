import React, {Component} from 'react';
import {Navigate} from "react-router-dom";

class RedirectToHomePage extends Component {


    render() {
        return (
            <div>
                {
                    this.props.redirectToHome ?
                        this.props.attemptedUrlPath && this.props.attemptedUrlSearch && this.props.currentUserHasAccessToAttemptedUrlPath ?
                            <Navigate  to={{
                                pathname: this.props.attemptedUrlPath,
                                search: this.props.attemptedUrlSearch
                            }} />
                            :
                            this.props.attemptedUrlPath && this.props.currentUserHasAccessToAttemptedUrlPath ?
                                <Navigate to={this.props.attemptedUrlPath}/>
                                :
                                this.props.accountType === 'Supplier' && (!this.props.userType || this.props.userType === 'OWNER' || this.props.userType === 'ADMIN') ?
                                    <Navigate to="/locations-profile"/>
                                    :
                                    this.props.accountType === 'Supplier' && this.props.userType === 'GATEMANAGER' ?
                                        <Navigate to="/approvals"/>
                                        :
                                        this.props.accountType === 'Supplier' && this.props.userType === 'GATECLERK' ?
                                            <Navigate to="/check-in"/>
                                            :
                                            this.props.accountType === 'Buyer' && this.props.userType === 'DISPATCHER' ?
                                                <Navigate to="/search"/>
                                                :
                                                this.props.accountType === 'Buyer' && (!this.props.userType || this.props.userType === 'OWNER' || this.props.userType === 'ADMIN') ?
                                                    <Navigate to="/search"/>
                                                    :
                                                    this.props.accountType === 'Admin' && this.props.userType === 'ADMIN'?
                                                        <Navigate to="/login-as-account"/>
                                                        :
                                                        ''
                        :
                        ''
                }
            </div>
        )
    }
}

export default RedirectToHomePage;
