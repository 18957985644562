import React from "react";
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { IconButton} from "@mui/material";
import { makeStyles } from '@mui/styles';
import {YouTube} from "@mui/icons-material";
import {Theme} from "@mui/material";

const useStyles: (theme: Theme) => {
    blueSocialIcon: CSSStyleSheet,
    icon: CSSStyleSheet
}  = makeStyles((theme) => ({
    blueSocialIcon: {
        color: theme.palette.secondary.dark,
        '&:hover': {
            backgroundColor: 'rgba(0, 41, 132, 0.10)', // RGBA val of palette.secondary.dark with alpha turned way down
        }
    },
    icon: {
        // This is the standard size of material UI icons
        height: '24px',
        width: '24px',
    }
}));

const FooterSocialMediaIcons = () => {

    const classes = useStyles();

    const handleOpenFaceBook = () =>{
        window.open('https://www.facebook.com/CargoWise/', '_blank');
    }

    const handleOpenTwitter = () =>{
        window.open('https://twitter.com/cargowise', '_blank');
    }

    const handleOpenYouTube = () =>{
        window.open('https://www.youtube.com/@_CargoWise', '_blank');
    }

    const handleOpenLinkedIn = () =>{
        window.open('https://www.linkedin.com/company/cargowiseone', '_blank');
    }

    return <>
        <IconButton onClick={handleOpenFaceBook} className={classes.blueSocialIcon}><FacebookIcon/></IconButton>
        {/* TODO: Replace X icon with material-ui icon when it becomes available */}
        <IconButton onClick={handleOpenTwitter} className={classes.blueSocialIcon}><img src="https://static.secur.space/icons/twitter_x.png" alt="Icon" className={classes.icon} /></IconButton>
        <IconButton onClick={handleOpenYouTube} className={classes.blueSocialIcon}><YouTube/></IconButton>
        <IconButton onClick={handleOpenLinkedIn} className={classes.blueSocialIcon}><LinkedInIcon/></IconButton>
    </>
}

export default FooterSocialMediaIcons;
