import React from 'react';
import {
    Grid, Typography,
    Checkbox, Select, MenuItem, FormControl, InputLabel, ListItemText, Chip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import EquipmentTypes from '../components/EquipmentTypes'
import LocationFeatures from '../components/LocationFeatures'


const useStyles: (theme: Theme) => {
    button: CSSStyleSheet,
    selectedStyle: CSSStyleSheet,
    dropDown: CSSStyleSheet,
} = makeStyles(theme => ({
    button: {
        border: 'none',
        outline: 'none',
        background: 'none',
        width: '29em'
    },
    selectedStyle: {
        color: 'rgba(0,0,0,.38)',
        padding: '0 1em',
    },
    dropDown: {
        width: '22em',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '& .MuiChip-filledDefault .MuiChip-labelSmall': {
            fontSize: '0.8em !important',
            padding: '0em 0.4em !important'
        },

    },
    padding: {
        padding: '1em 1.5em',
        flexWrap: 'nowrap',
        '& .css-1pggc45-MuiFormLabel-root-MuiInputLabel-root': {
            fontSize: '0.9em !important',
            left: '-0.7em'
        },
        '& .css-1ax3epa-MuiFormLabel-root-MuiInputLabel-root': {
            fontSize: '1em !important',
            left: '-0.7em'
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
        }
    },
    formControl: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));


const SearchFilter = ({
    selectedEquipmentType, setSelectedEquipmentType, selectedLocationFeatures, setSelectedLocationFeatures
}) => {

    const classes = useStyles();

    const equipmentTypeChange = (value) => {
        setSelectedEquipmentType(value);
    }

    const locationFeaturesChange = (value) => {
        setSelectedLocationFeatures(value);
    }

    return (
        <Grid container alignItems='center' gap={3} className={classes.padding}>
            <FormControl className={classes.formControl} variant='standard' size='small'>
                <InputLabel id="equipment" className={classes.label}>Equipment Type</InputLabel>
                <Select
                    size='small'
                    multiple
                    className={classes.dropDown}
                    labelId="equipment"
                    id="equipment"
                    value={selectedEquipmentType || []}
                    label="Equipment Type"
                    onChange={(e) => equipmentTypeChange(e.target.value)}
                    renderValue={(selected) => (
                        selected.length > 0 && <div style={{
                            display: 'flex', flexWrap: 'nowrap',
                            overflow: 'auto',
                            whiteSpace: 'nowrap', columnGap: '2px'
                        }}>
                            {selected.map((value) => (
                                <Chip size='small' key={value} label={value} />
                            ))}
                        </div>
                    )}
                >
                    {EquipmentTypes.OPTIONS.map((option) => (
                        <MenuItem key={option} value={option}>
                            <Checkbox size='small' checked={selectedEquipmentType?.indexOf(option) > -1} />
                            <ListItemText primary={<Typography variant='body2' fontSize={13}>{option}</Typography>} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl className={classes.formControl} variant='standard' size='small' >
                <InputLabel id="location-features">Location Features</InputLabel>
                <Select
                    size='small'
                    multiple
                    className={classes.dropDown}
                    labelId="location-features"
                    id="location-features"
                    value={selectedLocationFeatures || []}
                    label="Location Features"
                    onChange={(e) => locationFeaturesChange(e.target.value)}
                    renderValue={(selected) => (
                        selected.length > 0 && <div style={{
                            display: 'flex', flexWrap: 'nowrap',
                            overflow: 'auto',
                            whiteSpace: 'nowrap', columnGap: '2px'
                        }}>
                            {selected.map((value) => (
                                <Chip size='small' key={value} label={value} />
                            ))}
                        </div>
                    )}
                >
                    {LocationFeatures.OPTIONS.map((option) => (
                        <MenuItem key={option} value={option}>
                            <Checkbox checked={selectedLocationFeatures?.indexOf(option) > -1} />
                            <ListItemText primary={<Typography variant='body2' fontSize={13}>{option}</Typography>} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    )
}

export default SearchFilter