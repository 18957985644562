import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LocationFeatureList from './LocationFeatureList';
import ImageNotAvailablePlaceholder from '../ImageNotAvailablePlaceholder';
import InstantApprovalChip from './InstantApprovalChip';
import RequestSpaceChip from './RequestSpaceChip';
import LocationListItemImages from './LocationImages/LocationListItemImages';
import LocationItemPriceDisplay from '../LocationItemPriceDisplay';
import './style.css'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import NotLiveLocationChip from "../../NotLiveLocationChip";
import type {Supplier} from "../../../types/Supplier";

const useStyles: () => {
  item: CSSStyleSheet,
  container: CSSStyleSheet,
  secondColumnContainer: CSSStyleSheet,
  buttonBase: CSSStyleSheet,
  distance: CSSStyleSheet,
} = makeStyles(() => ({
  item: {
    borderBottom: '.07143em solid #E9E9FB',
  },
  container: {
    padding: '1.07143em',
    minWidth: '45.143em',
  },
  secondColumnContainer: {
    minHeight: '100%',
  },
  buttonBase: {
    textAlign: 'inherit',
    width: "100%",
  },
  distance: {
    fontSize: '1.14rem',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500
  }
}));

const LocationListItem = (props: { location: Supplier, showVisibilityChip: boolean, setListHover: () => void }) => {
  const {location, showVisibilityChip, setListHover} = props;
  const classes = useStyles();
  const listingImage = location?.listingImageFileName;
  const gallery = [...new Set([location?.gallery ? location?.gallery : null].flat().filter(item => item))];
  const distance = location?.distance;
  const [activeSlide, setActiveSlide] = useState(0);

  const {locationName} = location;
  let locationDescription = locationName ? locationName : `${location.city}, ${location.state}`;

  const slideHandler = (n) => {
    setActiveSlide(activeSlide + n)
  }

  const prevImage = (e) => {
    e.preventDefault();
    slideHandler(-1)
  }

  const nextImage = (e) => {
    e.preventDefault();
    slideHandler(1)
  }

  return (
    <div className='ss-listing-card' onMouseEnter={() => setListHover(location)} onMouseLeave={() => setListHover(null)}>
        <Grid item className='ss-listing-thumnail'>
          {
            listingImage ?
              <LocationListItemImages  
                errorimg='/app-images/not-found-image/place_holder_image.png'
                placeholderimg="/app-images/loading/image-loader.gif"
                activeSlide={activeSlide} 
                listingImageFileName={listingImage} 
                gallery={gallery} 
                locationId={location?.locationId}
              /> :
              <ImageNotAvailablePlaceholder/>
          }
          {gallery.length > 0 &&
            <>
              <button disabled={activeSlide === 0} onClick={prevImage} className='ss-left-side-button'>
                <AiOutlineLeft size={25} color={`${activeSlide === 0 ? "#2E363E" : "black"}`}/>
              </button>
              <button disabled={activeSlide === gallery.length - 1} onClick={nextImage} className='ss-right-side-button'>
                <AiOutlineRight size={25} color={`${activeSlide === gallery.length - 1 ? "#2E363E" : "black"}`}/>
              </button>
            </>
          }
        </Grid>

        <Grid container
              direction={'column'}
              wrap={'nowrap'}
              className={"ss-profile-details"}
        >
          <Grid item>
            <Grid container wrap='nowrap' justifyContent='space-between' alignItems='center'>
              <Grid item container spacing={2}>
                {
                  location?.hasRequestedCapacity ?
                    <Grid item><InstantApprovalChip/></Grid> :
                    <Grid item><RequestSpaceChip /></Grid>
                }
                {
                  showVisibilityChip && !location?.visible && <Grid item><NotLiveLocationChip /></Grid>
                }
              </Grid>
              <Typography className={classes.distance}>
                {distance} mi
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography className='ss-listing-title'>{locationDescription}</Typography>
          </Grid>
          <Grid item className={'ss-spacer'}>
            <LocationFeatureList features={location?.features}/>
          </Grid>
          <Grid item>
            <LocationItemPriceDisplay pricePerMonth={location?.pricePerMonth ? location.pricePerMonth : 0}
                                      pricePerDay={location?.pricePerDay ? location.pricePerDay : 0}
            />
          </Grid>
        </Grid>
      </div>
  )
}

export default LocationListItem;
