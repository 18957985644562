import React, {useEffect, useState} from 'react';
import MapStyle from '../MapStyle';
import LocationMarker from './LocationMarker';
import {GoogleMap} from '@react-google-maps/api';
import {makeStyles} from '@mui/styles';

const google = window.google;

const useStyles: () => {
  containerElement: CSSStyleSheet,
} = makeStyles({
  containerElement: {
    height: `100%`,
    width: '100%',
  }
});

const LocationMap = (props) => {
    const {onLocationSelect, locations, listHover} = props;
    const [coordinates, setCoordinates] = useState();
    const classes = useStyles();

    useEffect(() => {
        if (locations?.length > 0) {
            let location = locations[0];
            setCoordinates({lat: parseFloat(location.addressLatitude), lng: parseFloat(location.addressLongitude)});
        }
    }, [locations]);

    return (
        <GoogleMap
            center={coordinates}
            zoom={12}
            mapContainerClassName={classes.containerElement}
            options={{
                styles: MapStyle,
                mapTypeControl: true,
                streetViewControl: false,
                fullscreenControl: false,
                zoomControl: true,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT
                },
            }}>
            {
                locations?.length > 0 && locations.map((location, idx) => {
                    return (
                        <LocationMarker
                            key={idx}
                            onLocationSelect={onLocationSelect}
                            location={location}
                            listHover={listHover}
                        />)
                })
            }
        </GoogleMap>
    )
};

export default LocationMap;
