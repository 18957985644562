import React, {useState, useEffect} from "react";
import LocationItemLink from "./LocationItemLink";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    reclocationItemLink: {
        maxWidth: '75%',
        [theme.breakpoints.up('md')]: {
            maxWidth: '25%',
        }
    }
}));

const RecentlyBookedLocationItem = (props) => {
    const {location} = props;
    const classes = useStyles();

    const {listingImageFileName, pricePerDay, pricePerMonth, managedSpaces, locationName, cityName, id} = location;

    const [instantApproval, setInstantApproval] = useState();

    useEffect(() => {
        if(managedSpaces > 0) {
            setInstantApproval(true)
        }
    }, [managedSpaces])

    return (
        <LocationItemLink
            listingImageFileName={listingImageFileName}
            pricePerDay={pricePerDay}
            pricePerMonth={pricePerMonth}
            instantApproval={instantApproval}
            locationName={locationName}
            cityName={cityName}
            href={`/location-profile/${id}`}
            target={'_blank'}
            className={classes.reclocationItemLink}
        />
    )
}

export default RecentlyBookedLocationItem;
