import { makeStyles } from '@mui/styles'

export const useStylesBlogDetails = makeStyles(_theme => ({
    sectionPadding: {
        paddingBottom: '3.7em'
    },
    container: {
        marginTop: '1.7em',
    },
    backButton: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.3em',
        cursor: 'pointer'
    },
    backButtonTitle: {
        fontFamily: 'Inter',
        fontSize: '0.7em',
        color: _theme.palette.secondary.main,
        textTransform: 'uppercase'
    },
    mainContainer: {
        maxWidth: '95em',
        margin: '0 auto',
        padding: '1em'
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '2.28em',
        marginTop: '.4em',
        color: _theme.palette.secondary.main,
        textTransform: 'capitalize',
        fontWeight: '600'
    },
    subtitle: {
        fontFamily: 'Inter',
        fontSize: '1em',
        color: 'rgba(0, 0, 0, 0.6)',
        fontWeight: '400',
        marginTop: '0.4em'
    },
    thumbnail: {
        marginTop: '0.8em',
        height: '43em',
        "@media (max-width: 768px)": {
            height: '100%'
        }
    },
    img: {
        width: '100%',
        height: '100%',
        objectfit: 'cover'
    },
    content: {
        display: 'flex',
        gap: '1em',
        marginTop: '2.5em',
        "@media (max-width: 768px)": {
            flexDirection: 'column',
            gap: '4em'
        }
    },
    cotentDescription: {
        flex: 1
    },
    shareTitle: {
        fontFamily: 'Inter',
        fontSize: '1.21em',
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: '500',
    },
    share: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.2em',
        alignItems: 'center',
        "@media (max-width: 768px)": {
            display: ' none'
        }
    },
    tags: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '1em'
    },
    tag: {
        background: '#EEEEEE',
        borderRadius: '1.1em',
        padding: '0.21em 0.42em',
        fontFamily: 'Roboto',
        fontSize: '0.9em',
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: '500',
        cursor: 'pointer'
    },
    icon: {
        width: '2.28em',
        height: '2.28em',
        objectfit: 'cover'
    },
    authAvatar: {
        width: '9em',
        height: '9em',
        borderRadius: '50%',
        margin: 'auto',
        transform: 'translateY(-3.57em)'
    },
    authot_img: {
        width: '100%',
        height: '100%',
        objectfit: 'cover',
        borderRadius: '50%'
    },
    relatedPost: {
        display : "flex",
        flexDirection: "column",
        gap : '1em',
    },
    relatedTitle: {
        fontFamily: 'Inter',
        marginTop: '2em',
        fontSize: '1.2em',
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: '500',
        textTransform: 'capitalize',
        marginBottom: '1.2em'
    },
    relatedPostMain: {
        display: 'flex',
        alignItems: 'center',
        gap: '1em',

    },
    relatedPostImage: {
        width: '3.6em',
        height: '3.6em',
        objectfit: 'cover',
        borderRadius: '.5em'
    },
    relatedPostTitle: {
        fontFamily: 'Inter',
        fontSize: '1.em',
        color: _theme.palette.secondary.main,
        fontWeight: '500',
        textTransform: 'capitalize',
        "@media (max-width: 768px)": {
            maxWidth: '15em'
        }
    },
    subcribe: {
        width: '20em',
        "@media (max-width: 768px)": {
            width:'100%'
        }
    },
    smallSocialMedia: {
        display: 'none',
        "@media (max-width: 768px)": {
            display: ' flex',
            alignItems: 'center',
            gap: '1em'
        }
    }

}));