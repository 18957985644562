import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField, Theme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import LocationMap from "../LocationMap";
import MapStyle from "../MapStyle";
import { PinDrop } from "@mui/icons-material";

const google = window.google;

const useStyles: (theme: Theme) => {
  marginBottom10: CSSStyleSheet,
} = makeStyles(() => ({
  marginBottom10: {
    marginBottom: '0.71rem'
  }
}));

const AddressLookup = ({ name, value, onChange, label, placeholder, error = false, helperText = '' }) => {
  const inputRef = useRef(null);

  const [mapContainer, setMapContainer] = useState(null);
  const [center, setCenter] = useState({ lat: 37.090240, lng: -95.712891 });
  const [markers, setMarkers] = useState([]);
  const [zoom, setZoom] = useState(4);

  const classes = useStyles();

  const setMarkerFromPlace = useCallback((location) => {
    setMarkers([{ position: location, showInfo: false }]);
  }, []);

  const centerMapOnGeometry = useCallback((location, viewport) => {
    setCenter(location);
    if (mapContainer && viewport) {
      mapContainer.fitBounds(viewport);
    } else {
      setZoom(10);
    }
  }, [mapContainer]);

  useEffect(() => {
    if (value) {
      new google.maps.Geocoder().geocode(
        { 'address': value },
        (results, status) => {
          if (status === 'OK') {
            let location = results[0].geometry.location
            setMarkerFromPlace(location);
            centerMapOnGeometry(location, results[0].geometry.viewport);
          }
        }
      );
    }
  }, [value, setMarkerFromPlace, centerMapOnGeometry]);

  const onLoad = (mapContainer) => {
    setMapContainer(mapContainer)
  };

  const onUnmount = () => {
    setMapContainer(null);
  };

  const handleTextChange = (event) => {
    event.persist();
    const autocomplete = new google.maps.places.Autocomplete(event.target);
    autocomplete.addListener('place_changed', () => {
      let place = autocomplete.getPlace();
      if (!place.geometry) {
        console.log("No details available for input: '" + place.name + "'");
        return;
      }

      let streetNumber = '';
      let route = '';
      let city = '';
      let state = '';
      let zip = '';

      let location = place.geometry.location;

      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];

        if (addressType === 'street_number') {
          streetNumber = place.address_components[i]['short_name'];
        } else if (addressType === 'route') {
          route = place.address_components[i]['long_name'];
        } else if (addressType === 'locality') {
          city = place.address_components[i]['long_name'];
        } else if (addressType === 'administrative_area_level_1') {
          state = place.address_components[i]['long_name'];
        } else if (addressType === 'postal_code') {
          zip = place.address_components[i]['short_name'];
        }
      }

      setMarkerFromPlace(location);
      centerMapOnGeometry(location, place.geometry.viewport);
      onChange({
        lookupLocation: place.formatted_address,
        addressLine1: streetNumber + ' ' + route,
        addressLine2: '',
        city: city,
        state: state,
        zip: zip,
        addressLatitude: location.lat(),
        addressLongitude: location.lng()
      });
    });
    // User did not select an address, just update the text value
    onChange({
      lookupLocation: event.target.value,
    });
  };

  return (
    <>
      <TextField
        variant='standard'
        ref={inputRef}
        name={name}
        value={value}
        label={label}
        onChange={handleTextChange}
        placeholder={placeholder}
        error={error}
        helperText={helperText}
        fullWidth
        className={classes.marginBottom10}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PinDrop color={'action'} />
            </InputAdornment>
          )
        }}
      />
      <LocationMap
        onLoad={onLoad}
        onUnmount={onUnmount}
        center={center}
        zoom={zoom}
        options={{
          styles: MapStyle,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          zoomControl: false,
          scrollwheel: false
        }}
        markers={markers}
      />
    </>
  );
};

AddressLookup.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string
};

export default AddressLookup;