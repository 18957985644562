import React, {useCallback} from 'react';
import {Paper} from '@mui/material';
import LocationListItemLink from './LocationListItemLink';
import PropTypes from "prop-types";

const LocationList = (props) => {
  const {locations = [], instantApproval, listHover, setListHover, onlyLiveLocations = true} = props;

  const generatePath = useCallback(function generatePath(location) {
    let queryParams = '';
    if (location.hasRequestedCapacity && instantApproval) {
      queryParams = '?showMonth=false';
    }
    return `location-profile/${location.locationId}${queryParams}`;
  }, [instantApproval]);


  return <Paper className={"ss-search-result-container"} square>
    {
      locations?.map(location =>
        <LocationListItemLink
          key={location.locationId}
          location={location}
          to={generatePath(location)}
          listHover={listHover}
          setListHover={setListHover}
          showVisibilityChip={!onlyLiveLocations}
        />
      )
    }
  </Paper>
};

LocationList.propTypes = {
  locations: PropTypes.array,
  instantApproval: PropTypes.bool,
  onlyLiveLocations: PropTypes.bool,
};

export default LocationList;
