export const styles ={
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
    },
    rightAlignment:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '1em',
        mb:4,
        mt:2
    },
    appContainer:(theme) => ({
        paddingTop: '2.5rem',
        paddingBottom: '2.86rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '8.04rem',
            paddingRight: '8.04rem',
        }
    }),
    showMessage: {
        '& img': {
            width: "25em",
            height: '100%',
            '@media (max-width:39.28em)': {
                width: '100%',
            }
        }
    }
}