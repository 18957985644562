import { LEFT_NAV } from "./nav/DefaultNav";
import { ADMIN_NAV } from "./nav/AdminNav";
import {BUYER_DISPATCHER_NAV, makeBuyerOwnerNav} from "./nav/BuyerNav";
import {
    makeGateManagerNav,
    makeSupplierOwnerGMSNav,
    MARKETPLACE_ONLY_GATE_USER_NAV,
    SUPPLIER_GATE_CLERK_NAV,
    SUPPLIER_OWNER_MARKETPLACE_ONLY_NAV
} from "./nav/SupplierNav";
import { AccountType, SubscriptionType, UserType } from "../components/constants/securspace-constants";
import { useFlags } from "launchdarkly-react-client-sdk";

export const getUserBasedNavOptions: (user: { type: String, userType: String, subscriptionType: String }) => Array<{
    badge: boolean,
    label: string,
    path: string,
    submenuWidth: number,
    submenu: Array<{
        exact: boolean,
        highlighted: boolean,
        label: string,
        logoType: string,
        path: string
    }>,
}> = (user: { type: String, userType: String, subscriptionType: String }) => {
    const { type, userType } = user || {}

    let navOptions

    let {inventoryCorrection, buyerInventoryCorrection} = useFlags();

    switch (type) {
        case AccountType.ADMIN:
            navOptions = ADMIN_NAV
            break
        case AccountType.BUYER:
            navOptions = getBuyerNavOptions(userType, buyerInventoryCorrection)
            break
        case AccountType.SUPPLIER:
            navOptions = getSupplierNavOptions(user, inventoryCorrection)
            break
        default:
            navOptions = LEFT_NAV
    }

    return navOptions
};

export const getSupplierNavOptions = (user: { userType: String, subscriptionType: String }, inventoryCorrection) => {

    const { subscriptionType } = user || {}

    let navOptions

    switch (subscriptionType) {
        case SubscriptionType.GMS_LITE:
        case SubscriptionType.GMS_PRO:
            navOptions = getSupplierGmsSubscribersNavOptions(user, inventoryCorrection)
            break
        case SubscriptionType.MARKETPLACE_ONLY:
        default:
            navOptions = getSupplierMarketplaceOnlyNavOptions(user, inventoryCorrection)
    }

    return navOptions
};

export const getSupplierGmsSubscribersNavOptions = (user, inventoryCorrection) => {
    let navOptions

    const { subscriptionType, userType } = user || {}

    switch (userType) {
        case UserType.GATE_CLERK:
            navOptions = SUPPLIER_GATE_CLERK_NAV
            break
        case UserType.GATE_MANAGER:
            navOptions = makeGateManagerNav(user, inventoryCorrection)
            break
        case UserType.OWNER:
        case UserType.PRIMARY_OWNER:
        case UserType.ADMIN:
            navOptions = getSupplierOwnerNavOptions(subscriptionType, inventoryCorrection);
            break
        default:
            navOptions = LEFT_NAV
    }

    return navOptions
};


export const getSupplierMarketplaceOnlyNavOptions = (user) => {
    let navOptions

    const { userType } = user || {}

    switch (userType) {
        case UserType.GATE_CLERK:
        case UserType.GATE_MANAGER:
            navOptions = MARKETPLACE_ONLY_GATE_USER_NAV
            break
        case UserType.ADMIN:
        case UserType.OWNER:
        case UserType.PRIMARY_OWNER:
        default:
            navOptions = getSupplierOwnerNavOptions(SubscriptionType.MARKETPLACE_ONLY)
    }

    return navOptions
};

const getSupplierOwnerNavOptions: (subscriptionType: String, supplierInventoryCorrections: boolean) =>
  Array<{
      exact: boolean,
      highlighted: boolean,
      label: string,
      logoType: string,
      path: string
  }> = (subscriptionType, supplierInventoryCorrections) => {
    let navOptions

    switch (subscriptionType) {
        case SubscriptionType.MARKETPLACE_ONLY:
            navOptions = SUPPLIER_OWNER_MARKETPLACE_ONLY_NAV
            break
        case SubscriptionType.GMS_LITE:
        case SubscriptionType.GMS_PRO:
            navOptions = makeSupplierOwnerGMSNav(subscriptionType, supplierInventoryCorrections);
            break
        default:
            navOptions = LEFT_NAV
    }

    return navOptions
};

const getBuyerNavOptions = (userType: String, buyerInventoryCorrection) => {
    if (userType === UserType.OWNER || userType === UserType.ADMIN || userType === UserType.PRIMARY_OWNER) {
        return makeBuyerOwnerNav(buyerInventoryCorrection);
    } else {
        return BUYER_DISPATCHER_NAV
    }
};

export const filterNavOptions = (navOptions, flags: { [path: string]: boolean }) => {
    const filteredNavOptions = [];
    navOptions.forEach((navOption) => {
        if (!navOption || !navOption.submenus || navOption.submenus.length === 0) {
            filteredNavOptions.push(navOption);
        } else {
            const { submenus } = navOption;
            const newSubmenu = submenus.filter((subOption) => {
                return flags[subOption.path] !== undefined ? flags[subOption.path] : true;
            });
            filteredNavOptions.push({
                ...navOption,
                submenus: newSubmenu
            });
        }
    });
    return filteredNavOptions;
};
