import React from 'react';

const SecurSpacePageTitle = (props) => {
  const {children} = props;

  return <header>
    <div className={'flex justify-content-center'}>
      <div className={'flex'}>
        <h1 className={'content-header-title'}>
          {children}
        </h1>
      </div>
    </div>
  </header>
}

export default SecurSpacePageTitle;
