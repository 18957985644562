import React from 'react';
import { Grid, Typography, Skeleton } from '@mui/material';
import FeaturesOptionsMap from './FeaturesOptionsMap';
import '../../../../css/components/locationProfileFeatures.css'

const LocationProfileFeatures = (props) => {
    const { features, loading } = props;

    return (
        <Grid container component='section' alignContent='center' className='ss-location-features-option-parent'>
            {loading ? <Skeleton width={'30%'} /> : <Typography variant='h6' className='ss-location-features-option-title'>
                Location Features
            </Typography>}
            <FeaturesOptionsMap loading={loading} features={features} />
        </Grid>
    )

}
export default LocationProfileFeatures;