import React, { useState, useEffect } from "react";
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InstantApprovalChip from "../../location/search/InstantApprovalChip";
import RequestSpaceChip from "../../location/search/RequestSpaceChip";
import { Theme } from "@mui/material";
import { PinDrop as PinDropIcon } from '@mui/icons-material';
import type {Supplier} from "../../../types/Supplier";
import BookingDetailsDatePicker from "./BookingDetailsDatePicker";
import BookingDetailsEquipmentTypeSelect from "./BookingDetailsEquipmentTypeSelect";
import BookingDetailsNumberOfSpaces from "./BookingDetailsNumberOfSpaces";


const useStyles: (theme: Theme) => {
  pinDropIcon: CSSStyleSheet,
  checkIcon: CSSStyleSheet,
  title: CSSStyleSheet,
  locationName: CSSStyleSheet,
  cityName: CSSStyleSheet,
  label: CSSStyleSheet,
  text: CSSStyleSheet,
} = makeStyles(() => ({
  pinDropIcon: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  checkIcon: {
    color: 'rgb(199, 207, 252)'
  },
  title: {
    fontWeight: '600',
    fontSize: '1.7rem',
  },
  locationName: {
    fontWeight: '500',
    fontSize: '1.15rem',
  },
  cityName: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    fontWeight: '400',
    marginLeft: '0.5rem'
  },
  label: {
    color: 'rgba(0, 0, 0, 0.38)',
    marginLeft: '-0.8em',
  },
  text: {
    marginBottom: '0.5rem',
  }
}));

const BookingInfo = (props: {
  instantApproval: boolean,
  bookingLocation: Supplier,
  startDate: any,
  endDate: any,
  setStartDate: () => void,
  setEndDate: () => void,
  assetType: any,
  setAssetType: () => void,
  staticData: any,
  numberSpaces: number,
  setNumberSpaces: () => void,
  disableDatePickerPast: boolean,
  isBrokeredBooking: boolean,
  disableBookingChanges: boolean,
  assetTypelist: any,
  setBookingIsValid: () => void,
}) => {

  const {
    instantApproval,
    bookingLocation,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    assetType,
    setAssetType,
    staticData,
    numberSpaces,
    setNumberSpaces,
    disableDatePickerPast,
    isBrokeredBooking,
    disableBookingChanges,
    assetTypelist,
    setBookingIsValid,
  } = props;

  const [editBookingDates, setEditBookingDates] = useState(false);
  const [editEquipmentType, setEditEquipmentType] = useState(false);
  const [editNumberOfSpaces, setEditNumberOfSpaces] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    setBookingIsValid(!editBookingDates && !editEquipmentType && !editNumberOfSpaces);
  }, [setBookingIsValid, editBookingDates, editEquipmentType, editNumberOfSpaces]);

  const {monthly} = staticData;

  return (
    <Grid item container direction={'column'} spacing={2}>
      <Grid item container>
        <Grid item container justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="body1" className={classes.locationName}>{bookingLocation?.locationName}</Typography>
          {instantApproval ? <Grid item><InstantApprovalChip/></Grid> : <Grid item><RequestSpaceChip/></Grid>}
        </Grid>
        <Grid item className="ss-byb-details-city-container">
          <PinDropIcon fontSize="small" className={classes.pinDropIcon}/>
          {/* TODO: add country name when applicable */}
          <Typography variant="subtitle1"
                      className={classes.cityName}>{`${bookingLocation?.city}, ${bookingLocation?.state}`}</Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item container className="ss-byb-details-child" xs={12}>
          <BookingDetailsDatePicker
            editMode={editBookingDates}
            onToggleEditMode={setEditBookingDates}
            showEndDate={!monthly}
            fromDate={startDate}
            onFromDateChange={setStartDate}
            toDate={endDate}
            onToDateChange={setEndDate}
            readOnly={disableBookingChanges}
            disablePast={disableDatePickerPast}
          />
        </Grid>
        <Grid item className={'ss-byb-details-child'} xs={12}>
          <BookingDetailsEquipmentTypeSelect
            editMode={editEquipmentType}
            onToggleEditMode={setEditEquipmentType}
            value={assetType}
            onChange={setAssetType}
            options={assetTypelist}
            selectClasses={{root: "w-100", label: classes.label}}
            disableBookingChanges={disableBookingChanges}
            readOnly={disableBookingChanges}
          />
        </Grid>
        <Grid container item className="ss-byb-details-child" xs={12}>
          <BookingDetailsNumberOfSpaces
            editMode={editNumberOfSpaces}
            onToggleEditMode={setEditNumberOfSpaces}
            bookingLocation={bookingLocation}
            onChange={setNumberSpaces}
            value={numberSpaces}
            readOnly={disableBookingChanges}
            overrideMinimum={isBrokeredBooking}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BookingInfo;
