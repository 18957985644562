import React, {useState} from 'react';
import {IconButton, Menu} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ReportKebabButton = (props) => {
  const { children } = props;

  const [ anchorEl, setAnchorEl ] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <>
    <IconButton
      className="kebab"
      aria-label="more"
      onClick={handleClick}
      aria-haspopup="true"
      aria-controls="long-menu"
    >
      <MoreVertIcon/>
    </IconButton>
    <Menu
      anchorEl={anchorEl}
      keepMounted
      onClose={handleClose}
      onClick={handleClose}
      open={open}>
      {children}
    </Menu>
  </>
}

export default ReportKebabButton;
