import React from 'react';
import '../../css/components/report/report-header.css';
import * as PropTypes from 'prop-types';

const ReportHeader = (props) => {
  const { children, parentMenu } = props;
  return (
    <header>
      <ul className="breadcrumb">
        <li>{parentMenu || 'Reports'}</li>
        <li>{children}</li>
      </ul>
      <h1 className="ss-report-header-title">{children}</h1>
    </header>
  )
}

ReportHeader.propTypes = {
  parentMenu: PropTypes.any
}

export default ReportHeader;
