import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { getStaticContent } from '../../components/staticContent/static-content-requests';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BackButton from '../../components/BackButton';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import PolicyDocumentSkeleton from './PolicyDocumentSkeleton';
import {withSnackbar} from "../../components/hocs/withSnackbar";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../util/NetworkErrorUtil";
import {Theme} from "@mui/material";

const useStyles: (theme: Theme) => {
    mainContainer: CSSStyleSheet,
    rowGap5: CSSStyleSheet,
    marginTop20: CSSStyleSheet,
    titleFont: CSSStyleSheet,
    lastModifiedFont: CSSStyleSheet,
    contentStyles: CSSStyleSheet,
    wrapContent: CSSStyleSheet
} = makeStyles(() => ({
    mainContainer: {
        paddingTop: '2.86rem',
        paddingRight: '7.79vw',
        paddingLeft: '7.79vw',
    },
    rowGap5: {
        rowGap: '0.36rem',
    },
    marginTop20: {
        marginTop: '1.43rem',
    },
    wrapContent: {
        marginTop: '0.5em',
        marginBottom: '2em',
    },
    titleFont: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '1.43rem',
        lineHeight: '160%',
        letterSpacing: '0.01rem',
    },
    lastModifiedFont: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '1.14rem',
        lineHeight: '125%',
        letterSpacing: '0.01rem',
    },
    contentStyles: {
        '& .no-display': {
            display: 'none'
        },
        '& .ss-tos-platform-title': {
            fontWeight: '500',
            fontSize: '1.1em'
        },
        '& .ss-tos-arbitration-note': {
            textAlign: 'center'
        },
        '& .ss-tos-title': {
            fontWeight: '600',
            marginBottom: '1.5em'
        },
        '& .container': {
            width: '100% !important',
        },
        '& .static-page-section p': {
            textIndent: '0em',
            marginTop: '2em',
        },
        '& .static-page-section h4': {
            marginBottom: '-1em'
        },
        '& div h6': {
            marginBottom: '2.5em',
            fontWeight: 'bold',
            fontSize: '1.1em'
        },
        '& div h4': {
            fontWeight: '500',
            fontSize: '1.1em',
            marginBottom: '0.7em'
        },
        '& div h5': {
            fontWeight: 'bold',
            fontSize: '1.1em',
            marginBottom: '1em'
        },
        '& div p': {
            fontWeight: '400',
            fontSize: '1.1em',
            lineHeight: '1.5em',
            letterSpacing: '0.01em',
            '& strong': {
                fontWeight: '500',
                display: 'inline-block',
                marginBottom: '0.3em'
            }
        },
        '& div': {
            marginBottom: '2.5em'
        },
        '& p, h2, h3': {
            marginBottom: '2em'
        }
    }
}));

const PolicyDocument = ({ type, snackbarShowMessage }) => {
    const [policyData, setPolicyData] = useState('');
    const [loading, setLoading] = useState(false);
    const documentRef = useRef(null);

    const navigate = useNavigate();
    const location = useLocation();
    const { versionNumber } = useParams();
    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
        getStaticContent(type, versionNumber).then((response) => {
            setPolicyData(response.body);
        }).catch((error) => {
            snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error', 15000);
        }).finally(() => {
            setLoading(false);
        });
    }, [type, versionNumber, snackbarShowMessage]);

    useEffect(() => {
        const documentRoot = documentRef.current;
        if (!loading && documentRoot && location.hash) {
            const element = documentRoot.querySelector(location.hash);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [loading, documentRef, location]);

    let headerTitle;
    switch (type) {
        case "terms":
            headerTitle = "Platform Terms of Use";
            break;
        case "privacy-policy":
            headerTitle = "SecurSpace Privacy Policy";
            break;
        default:
            break;
    }

    const handleBackButtonClick = () => {
        navigate("/");
    };

    const lastModifiedDate = policyData?.createdDate ? moment(policyData?.createdDate).format("MMMM D, YYYY") : '';

    return (
        loading ? <PolicyDocumentSkeleton type={type} /> : <Grid container direction='column' className={classes.mainContainer}>
            <Box component='header' className={classes.wrapContent}>
                <BackButton onClick={handleBackButtonClick} />
                {!loading && <Grid container direction='column' className={classes.rowGap5}>
                    <Typography className={classNames(classes.titleFont, classes.marginTop20)}>
                        {headerTitle}
                    </Typography>
                    <Typography className={classes.lastModifiedFont}>
                        Last modified: {lastModifiedDate}
                    </Typography>
                    <Typography variant='subtitle2' color='textPrimary'>
                        Version number: {policyData.versionNumber}
                    </Typography>
                </Grid>
                }
            </Box>
            <div ref={documentRef} dangerouslySetInnerHTML={{ __html: policyData?.content?.split("\n")?.slice(0, -2).join("").toString() }} className={classes.contentStyles} />
        </Grid>
    );
};

PolicyDocument.propTypes = {
    type: PropTypes.oneOf(["terms", "privacy-policy"]),
};

export default withSnackbar(PolicyDocument);
