import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import useRefOffsetDimensions from '../hooks/useRefOffsetDimensions';

const useStyles: () => {
    iFrameContainer: CSSStyleSheet,
} = makeStyles(() => ({
    iFrameContainer: (containerWidth) => {
        return {
            '& iframe:nth-child(1)': { // iframe form height clipped off bottom of form
                minHeight: containerWidth > 500 ? '75vh' : '125vh',
                marginTop: "2em"
            },
        }
    },
}));

const HubSpotForm = (props) => {
    const [formCreated, setFormCreated] = useState(false);

    const { region, portalId, formId } = props;

    const containerRef = useRef(null);
    const { width } = useRefOffsetDimensions(containerRef);
    const classes = useStyles(width);

    useEffect(() => {
        const hbspt = window.hbspt ? window.hbspt : undefined;
        if (hbspt && !formCreated) {
            const form = { target: "#hbspt-form", region, portalId, formId };
            hbspt.forms.create(form);
            setFormCreated(true);
        }
    }, [formCreated, region, portalId, formId]);

    return (
        <div id="hbspt-form" ref={containerRef} className={classes.iFrameContainer}></div>
    );
};

HubSpotForm.propTypes = {
    region: PropTypes.string,
    portalId: PropTypes.string,
    formId: PropTypes.string,
};

export default HubSpotForm;
