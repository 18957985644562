import React from 'react'
import './style.css'
import { Grid, Typography } from '@mui/material'
import ChangePasswordForm from './ChangePasswordForm'
import { useNavigate } from 'react-router-dom'

const ChangePassword = ({ isNewUser }) => {

    const history = useNavigate()

    return (
        <section>
            <div className='ss-change-password-container' />
            <Grid className='ss-change-password-section-container' container direction='column' justifyContent='center' alignItems='center'>
                <Grid className='ss-change-password-content'>
                    <Typography className='ss-change-password-title'>Change your password</Typography>
                    <Typography className='ss-change-password-subtitle'>
                        Please enter a new password.
                    </Typography>
                    <ChangePasswordForm history={history} isNewUser={isNewUser} />
                    <Grid className='ss-change-password-footer' container justifyContent='center' alignItems='center'>
                        <Typography className='ss-change-password-account-title'>Remembered your account details?</Typography>
                        <Typography component={"a"} onClick={() => history("/login")} className='ss-change-password-login'>Log In</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </section>
    )
}

export default ChangePassword