import React, {useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import DriverInfoForm from "./DriverInfoForm";
import {DateTimePicker} from "@securspace/securspace-ui-kit";
import type {GroupedLocationInventoryActivity} from "../../../types/GroupedLocationInventoryActivity";
import {FC} from "react";
import {ReactNode} from "react";
import moment from "moment-timezone";

interface IDateCorrectionForm {
  inventory?: GroupedLocationInventoryActivity,
  correctActivityDate?: moment.Moment | null,
  setCorrectActivityDate?: (date: Object | null) => void,
  setDriverInfo?: (driverInfo: {firstName: string, lastName: string, license: string}) => void,
  driverInfoRequired?: boolean,
  onDateTimeError?: (error: Object, value: string) => void,
  setDisableSubmit?: () => void,
  // Optional prop that disables the date picker from selecting dates before the inventory's checkin date
  disableDatesBeforeCheckIn?: boolean,
  // Additional form elements that should be wrapped in Grid items
  children?: ReactNode,
  currentTimeData: {dateTime: Object, timeZoneName: string, timeZoneAbbreviation: string},
}

const DateCorrectionForm: FC<IDateCorrectionForm> = ({
    inventory,
    correctActivityDate,
    setCorrectActivityDate,
    setDriverInfo,
    driverInfoRequired = false,
    onDateTimeError = () => {},
    disableDatesBeforeCheckIn = false,
    currentTimeData,
    children
  }: IDateCorrectionForm) => {

    const {checkoutDriverFirstName, checkoutDriverLastName, checkoutDriverLicenseNumber, timeZoneName} = inventory;
    
    const [dateErrorMessage, setDateErrorMessage] = useState("");

    useEffect(() => {
      if(moment(correctActivityDate).isAfter(currentTimeData.dateTime)) {
        setDateErrorMessage(`Please select an activity time before ${moment(currentTimeData.dateTime).format("hh:mm A MM/DD/YY")} ${currentTimeData.timeZoneAbbreviation}.`);
        onDateTimeError("error")
      } else {
        setDateErrorMessage("")
        onDateTimeError(null)
      }
    }, [correctActivityDate])

    return <Grid item container direction="column" spacing={2}>
            <Grid item marginTop="1em">
                <DateTimePicker
                  value={correctActivityDate}
                  label="Check Out Date and Time"
                  onChange={setCorrectActivityDate}
                  minDateTime={disableDatesBeforeCheckIn ? inventory.checkinDate : undefined}
                  onError={onDateTimeError}
                  timezone={timeZoneName}
                  maxDate={currentTimeData.dateTime}
                />
            </Grid>
            { 
              dateErrorMessage ?
                <Grid item alignSelf={'center'}>
                  <Typography variant="subtitle1" color="tomato" fontSize="0.9em">{dateErrorMessage}</Typography>
                </Grid>
              : ""
            }
            <Grid item>
                <DriverInfoForm 
                    initialDriverFirst={checkoutDriverFirstName}
                    initialDriverLast={checkoutDriverLastName}
                    initialDriverLicense={checkoutDriverLicenseNumber}
                    setDriverInfo={setDriverInfo}
                    required={driverInfoRequired}
                />
            </Grid>
            {children}
        </Grid>
}

export default DateCorrectionForm;
