import React, {memo, useState} from 'react';
import RedesignAddPaymentMethodSetup from "../../views/RedesignAddPaymentMethodSetup";
import PropTypes from "prop-types";

const AddPaymentMethodFlowManager = memo(({account, open, setOpen, loadPaymentMethods}) => {
  const [isACHPaymentMethod, setIsACHPaymentMethod] = useState(false);
  const [isMicroDepositPaymentMethod, setIsMicroDepositPaymentMethod] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleACHChange = (value: boolean) => {
    setIsACHPaymentMethod(value);
    setIsMicroDepositPaymentMethod(false);
  }

  return (
    <RedesignAddPaymentMethodSetup
      account={account}
      doneWithAddPaymentMethod={handleClose}
      payWithAch={isACHPaymentMethod}
      loadPaymentMethods={loadPaymentMethods}
      paymentModalOpen={open}
      setPaymentModalOpen={setOpen}
      setACH={handleACHChange}
      setMicroDeposit={setIsMicroDepositPaymentMethod}
      microDepositVerify={isMicroDepositPaymentMethod}
      collectMicroDepositVerifyPayment={isMicroDepositPaymentMethod}
    />
  )
});

AddPaymentMethodFlowManager.propTypes = {
  account: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  loadPaymentMethods: PropTypes.func.isRequired,
};

export default AddPaymentMethodFlowManager;