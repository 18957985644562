import React from 'react';
import {withSnackbar} from "../hocs/withSnackbar";
import DownloadCSVButton from "../report/export/DownloadCSVButton";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../util/NetworkErrorUtil";
import ReportDataSummary from "../report/ReportDataSummary";
import {getBuyerInventoryReportUrl} from "./requests/buyer-inventory-requests";

type BuyerInventoryReportDataSummaryProps = {
  accountId: string,
  query: {
    startDate?: string,
    endDate?: string,
    locationId?: string,
    bookingNumber?: string,
    invoiceNumber?: string,
    transactionType?: string,
    status?: string,
    sortBy?: string,
    sortDir?: string
  },
  count: number,
  title: string,
  setExpandedItems: (items: Object) => void,
  setExpandedAll: (isExpanded: boolean) => void,
  isShowCollapseButton?: boolean,
  handleExpandAll: (event: MouseEvent) => void,
  expandedItems: Object,
  snackbarShowMessage: (message: Object, severity: String, duration: Number, position?: {}) => void,
};

const BuyerInventoryReportDataSummary: (props: BuyerInventoryReportDataSummaryProps) => JSX.Element = ({
                                                                                                         accountId,
                                                                                                         query,
                                                                                                         count,
                                                                                                         title,
                                                                                                         setExpandedItems,
                                                                                                         setExpandedAll,
                                                                                                         isShowCollapseButton = false,
                                                                                                         handleExpandAll,
                                                                                                         expandedItems,
                                                                                                         snackbarShowMessage
                                                                                                       }) => {
  return (
    <ReportDataSummary
      count={count}
      title={title}
      setExpandedItems={setExpandedItems}
      setExpandedAll={setExpandedAll}
      isShowCollapseButton={isShowCollapseButton}
      handleExpandAll={handleExpandAll}
      expandedItems={expandedItems}
    >
      <DownloadCSVButton
        title={'Download Report'}
        reportUrl={getBuyerInventoryReportUrl(accountId, query)}
        onError={(error) => snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error, 'Failed to download report'))}
      />
    </ReportDataSummary>
  );
};

export default withSnackbar(BuyerInventoryReportDataSummary);