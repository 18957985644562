import React from 'react'
import {Route} from "react-router";
import { AccountType } from '../../components/constants/securspace-constants';
import HubspotBuyerSignup from '../../views/HubspotBuyerSignup';
import {Routes} from "react-router-dom";

const ExternalContent = () => {

    return (
      <Routes>
          <Route path="/external-buyer-signup" key="/external-buyer-signup" element={<HubspotBuyerSignup type={AccountType.BUYER} />}>
          </Route>
      </Routes>
      )

}

export default ExternalContent;
