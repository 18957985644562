
import request from "../../util/SuperagentUtils";

const BASE_URL = `/api/suppliers`;

export const requestCreatePayouts = (payoutRequest) : Promise => {
    return request.post(`${BASE_URL}/create-payouts`)
        .send(payoutRequest)
}

export const requestReadyForPayoutTransactionsForAccount: (
  accountId: string,
  query: {
      locationId?: string,
      sortBy: string,
      sortDir: string,
  }) => Promise = (accountId, query) => {
    return request.get(`${BASE_URL}/${accountId}/ready-for-payout`).query(query);
}


export const getReadyForPayoutReportUrl: (
  accountId: string,
  searchParams: { locationId?: string, sortBy?: string, sortDir?: string }
) => string = (accountId, searchParams) => {
  const { locationId, sortBy, sortDir } = searchParams;
  const queryParams = new URLSearchParams();
  if (locationId) queryParams.append("locationId", locationId);
  if (sortBy) queryParams.append("sortBy", sortBy);
  if (sortDir) queryParams.append("sortDir", sortDir);

  return `${BASE_URL}/${accountId}/ready-for-payout-report?${queryParams}`;
}

export const getReadyForPayoutGroupSummaryReportUrl: (
  accountId: string, searchParams: { locationId?: string }
) => string = (accountId, searchParams) => {
    const { locationId } = searchParams;
    const queryParams = new URLSearchParams();
    if (locationId) queryParams.append("locationId", locationId);

    return `${BASE_URL}/${accountId}/ready-for-payout-group-summary-report?${queryParams}`;
}

export const requestInitiatePayouts: (body: { supplierAccountId: string, transactionIds: string[], groupForSuppliers?: boolean }) => Promise = (body) => {
    return request.post(`${BASE_URL}/create-payouts`).send(body);
}