import React, {useEffect, useState} from 'react';
import {Grid, useMediaQuery} from "@mui/material";
import DownloadCSVButton from "../report/export/DownloadCSVButton";
import {
  getBuyerInterChangeReportUrl
} from "./requests/buyer-interchanges-requests";
import {ClassNameMap} from "@mui/styles";
import {withSnackbar} from "../hocs/withSnackbar";

const BuyerInterChangeExportCSVs = (props: {
  item?: boolean,
  query: {
    buyerAccountId: string,
    startDate: string,
    endDate: string,
    locationId: string,
    locationName: string,
    supplierName: string,
    bookingNumber: string,
    equipmentType: string,
    equipmentNumber: string,
    sortBy: string,
    sortDir: string
  },
  className?: ClassNameMap,
  snackbarShowMessage: () => void
}) => {
  const { item, query, className, snackbarShowMessage } = props;
  const isMd = useMediaQuery(theme => theme.breakpoints.up('md'));

  const [basicReportUrl, setBasicReportUrl] = useState("");

  useEffect(() => {
    setBasicReportUrl(getBuyerInterChangeReportUrl(query));
  }, [query]);

  const handleReportError = () => {
    snackbarShowMessage('Failed to download report', 'error');
  }

  return (
    <Grid item={item} container gap={4} justifyContent={isMd ? 'flex-end' : 'space-between'} xs={12} md>
      <Grid item>
        <DownloadCSVButton
          reportUrl={basicReportUrl}
          title={'Download Report'}
          onError={handleReportError}
          className={className}/>
      </Grid>
    </Grid>
  )
};

export default withSnackbar(BuyerInterChangeExportCSVs);