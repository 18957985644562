import React from 'react';
import {FormControl, Grid, Input, InputLabel} from "@mui/material";

const UploadLegalAgreement = (props) => {
  const {classes, handleFileUpload} = props;

  return (
    <Grid item className={classes}>
      <FormControl variant="standard" className={".ss-byb-details-input"}>
        <InputLabel id='ssLegalAgreement' variant={'standard'} color={'primary'} shrink={true}>Legal Agreement</InputLabel>
        <Input
          className='ss-byb-details-input'
          type={'file'}
          onChange={handleFileUpload}
        />
      </FormControl>
    </Grid>
  )
}

export default UploadLegalAgreement;
