import React, { useContext} from 'react'
import { Route, Navigate } from "react-router-dom";
import { AppContext } from "../../context/app-context";
import { withAccessControl } from "../ProtectedRoute";
import AccessControlBuilder from "../AccessControlBuilder";

import CompanyProfile from "../../views/CompanyProfile";
import LocationsProfile from "../../views/LocationsProfile";
import ManageUsers from "../../views/ManageUsers";
import AccountOverview from '../../views/account-overview/AccountOverview';
import NotificationsSettings from "../../views/NotificationsSettings";
import {useFlags} from "launchdarkly-react-client-sdk";

const allOwnerAuthorization = new AccessControlBuilder().allowAllOwners().build()
const allSupplierOwnerAuthorization = new AccessControlBuilder().allowAllSupplierOwner().build()
const gateManagerAuthorization = new AccessControlBuilder().allowGateManager().build()

const ProtectedCompanyProfile = withAccessControl(CompanyProfile, allOwnerAuthorization)
const ProtectedLocationsProfile = withAccessControl(LocationsProfile, allSupplierOwnerAuthorization, gateManagerAuthorization)
const ProtectedManageUsers = withAccessControl(ManageUsers, allOwnerAuthorization)
const ProtectedRedesignNotificationsSettings = withAccessControl(NotificationsSettings, allOwnerAuthorization);
const ProtectedNotificationSettings = withAccessControl(AccountOverview, allOwnerAuthorization)
const ProtectedAccountOverview = withAccessControl(AccountOverview, allOwnerAuthorization);

const AccountContent = () => {
    const appContext = useContext(AppContext)
    const {user, updateUser, logout} = appContext
    const flags = useFlags();
    const isRedesign = flags.accountNavRedesign;

    return [
      <Route path="/company-profile" key="/company-profile" element=
        {
          isRedesign ?
            <Navigate to='/account/company-profile'/>
            :
            <ProtectedCompanyProfile account={user} handleLogout={logout} handleAccountUpdated={updateUser} />
        }  >
      </Route>,
      <Route path="/locations-profile" key="/locations-profile" element={ <ProtectedLocationsProfile account={user} handleLogout={logout} />} > </Route>,
      <Route path="/user-management" key="/user-management" element=
        {
          isRedesign ?
            <Navigate to='/account/user-management'/>
            :
            <ProtectedManageUsers account={user} handleLogout={logout} />
        } >
      </Route>,

      <Route path="/notification-settings" key="/notification-settings" element=
        {
          isRedesign ?
            <Navigate to='/account/notification-settings'/>
            :
            <ProtectedRedesignNotificationsSettings account={user} handleLogout={logout} />
        } >
      </Route>,

      <Route path="/account/company-profile" key="/account/company-profile" element={
        <ProtectedAccountOverview
          account={user}
          handleLogout={logout}
          handleAccountUpdated={updateUser}
          initialTab={'company'}
        />} >
      </Route>,

      <Route path="/account/user-management" key="/account/user-management" element={<ProtectedAccountOverview account={user} handleLogout={logout} handleAccountUpdated={updateUser} initialTab={'users'} /> } > </Route>,
      <Route path={"/account/payment-methods"} key={"/account/payment-methods"} element={<ProtectedAccountOverview account={user} handleLogout={logout} handleAccountUpdated={updateUser} initialTab={'payment'} />} > </Route>,
      <Route path="/account/notification-settings" key="/notification-settings" element={<ProtectedNotificationSettings account={user} handleLogout={logout} initialTab={'notifications'} /> } > </Route>,
    ];
}

export default AccountContent;
