import React from 'react';
import {makeStyles} from '@mui/styles';
import './style.css'
import {Link} from "react-router-dom";
import LocationListItem from "./LocationListItem";
import {Theme} from "@mui/material";
import PropTypes from "prop-types";

const useStyles: (theme: Theme) => {
  locationItemLink: CSSStyleSheet,
} = makeStyles(() => ({
  locationItemLink: {
    textAlign: 'inherit',
    width: "100%",
    '&:link': {
      color: 'unset',
      textDecoration: 'none',
    },
    '&:visited': {
      color: 'unset',
      textDecoration: 'none',
    },
    '&:focus': {
      color: 'unset',
      textDecoration: 'none',
    },
    '&:hover': {
      color: 'unset',
      textDecoration: 'none',
    },
    '&:active': {
      color: 'unset',
      textDecoration: 'none',
    },
  }
}));

const LocationListItemLink = (props) => {
  const { location, to, listHover, setListHover, showVisibilityChip } = props;
  const classes = useStyles();


  return (
    <div className='ss-listing-location-list-item'>
      <Link to={`/${to}`} className={classes.locationItemLink} target={'_blank'}>
        <LocationListItem
          location={location}
          listHover={listHover}
          setListHover={setListHover}
          showVisibilityChip={showVisibilityChip}
        />
      </Link>
    </div>
  );
};

LocationListItemLink.propTypes = {
  location: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
  showVisibilityChip: PropTypes.bool,
};

export default LocationListItemLink;
