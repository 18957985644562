import request from "../util/SuperagentUtils";

const BUYER_BASE_URL = "/api/buyers";

export const getBuyerInventoryReport: (accountId: string) => Promise = (accountId) => {
    return request.get(`${BUYER_BASE_URL}/${accountId}/inventory-report`);
};

export const getBuyerOverageDailyReportByDate: (
  baseUrl: string,
  locationId: string,
  buyerAccountId: string,
  date: string
) => Promise = (baseUrl, locationId, buyerAccountId, date) => {
    return request.get(`${baseUrl}/api/overage-daily-report-by-date/${locationId}/${buyerAccountId}/${date}`);
};

export const deleteInventoryActivity: (inventoryActivityId: string) => Promise = (inventoryActivityId) => {
    return request.delete(`api/inventory-activity/${inventoryActivityId}`);
};