import React from 'react';

export default ({isActive, activeTag, inactiveTag, onToggle, baseClass, activeClass, inactiveClass, firstTooltip, secondTooltip}) =>
    (   <div className={baseClass}>
            <div className={`${!isActive ? `${inactiveClass} ${activeClass}` : `${inactiveClass}`} ss-circle-container`}
                 data-tip data-for={firstTooltip ? firstTooltip  : ''}
                 onClick={() => onToggle(false)}>
                    <div className={`ss-circle-box ${!isActive && `active`}`}>
                        {!isActive && <div className='ss-circle-main' />}
                        </div>
                {inactiveTag}
            </div>
            <div className={`${!isActive ? `${inactiveClass} ${activeClass}` : `${inactiveClass}`} ss-circle-container`}
                 data-tip data-for={secondTooltip ? secondTooltip  : ''}
                 onClick={() => onToggle(true)}>
                    <div className={`ss-circle-box ${isActive && `active`}`}>
                        {isActive && <div className='ss-circle-main' />}
                        </div>
                {activeTag}
            </div>
        </div>
    );

