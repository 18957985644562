import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, Grid } from "@mui/material";

const CompleteBookingModal = ({open, done, classes}) => {
    return (
        <Dialog open={open} >
            <DialogTitle>Complete Booking</DialogTitle>
            <DialogContent>
                    <Typography>BOOKING COMPLETED SUCCESSFULLY</Typography>
                <Grid container className={classes.topMargin}>
                    <Grid item>
                        <Typography>
                        This Booking is now fully approved and your payment is processing.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            You may now use this Booking to store equipment.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent={"center"}>
                    <Button type="contained"
                            onClick={done}>Done
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default CompleteBookingModal;