import React, { useState } from 'react';
import { Col, Form, Row } from "react-bootstrap";
import ServiceFeeSelectFormGroup from "./ServiceFeeSelectFormGroup";
import {
  MaintenanceAndRepairSubTypes,
  ServiceFeeType
} from "../constants/securspace-constants";
import MaintenanceAndRepairFormGroup from "./MaintenanceAndRepairFormGroup";
import ChargeAmountFormGroup from "./ChargeAmountFormGroup";
import TaxPercentageFormGroupLegacy from "./TaxPercentageFormGroupLegacy";
import ServiceFeeBreakdown from "./ServiceFeeBreakdown";
import NotesFormGroup from "./NotesFormGroup";
import SolidButton from "../form/SolidButton";
import OutlinedButton from "../form/OutlinedButton";
import Error from '../Error';

const ServiceFeeChargeFormLegacy = (props) => {
  const { serviceFee, securspaceServiceFeePercentage, onSubmit, onCancel, onChange, taxRate } = props;
  const showMaintenanceAndRepair = MaintenanceAndRepairSubTypes.includes(serviceFee.serviceFeeType);
  const [displayTaxMessage, setDisplayTaxMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');

  const handleServiceFeeTypeChange = (updatedServiceFeeType) => {
    const serviceFeeType = updatedServiceFeeType === '' ? ServiceFeeType.MUDFLAP_REPAIR : updatedServiceFeeType;
    onChange({
      ...serviceFee,
      serviceFeeType: serviceFeeType,
    });
  }

  const handleMaintenanceAndRepairChange = (updatedMaintenanceAndRepairDescription) => {
    onChange({
      ...serviceFee,
      serviceFeeType: updatedMaintenanceAndRepairDescription,
    });
  }

  const handleChargeAmountChange = (updatedChargeAmount) => {
    onChange({
      ...serviceFee,
      chargeAmount: updatedChargeAmount,
    });
  }

  const handleTaxPercentageChange = (updatedTaxPercentage) => {
    onChange({
      ...serviceFee,
      taxRate: updatedTaxPercentage,
    });

    if (updatedTaxPercentage === 0) {
      setDisplayTaxMessage(false)
    } else {
      setDisplayTaxMessage(true)
    }
  }

  const handleNotesChange = (updatedNotes) => {
    onChange({
      ...serviceFee,
      notes: updatedNotes,
    });
  }

  const handleSubmit = () => {
    if (!serviceFee?.serviceFeeType) {
      setErrorMessage('Please select a service fee type.');
      return;
    }
    if (serviceFee.chargeAmount < 50) {
      setErrorMessage('Please enter a charge amount more than $0.50.');
      return;
    }
    setErrorMessage('');
    onSubmit();
  }

  return <Form>
    <Row>
      <ServiceFeeSelectFormGroup value={MaintenanceAndRepairSubTypes.includes(serviceFee?.serviceFeeType) ? '' : serviceFee?.serviceFeeType}
        onChange={handleServiceFeeTypeChange}
      />
    </Row>
    {
      showMaintenanceAndRepair && <Row>
        <MaintenanceAndRepairFormGroup value={serviceFee?.serviceFeeType}
          onChange={handleMaintenanceAndRepairChange}
        />
      </Row>
    }
    <Row>
      <ChargeAmountFormGroup value={serviceFee.chargeAmount}
        onChange={handleChargeAmountChange}
      />
    </Row>
    <Row>
      <TaxPercentageFormGroupLegacy value={taxRate}
        onChange={handleTaxPercentageChange}
      />
      {
        displayTaxMessage ?
          <div className='ss-conditional-tax-message'> Tax funds will be sent to your account. You are responsible for remitting tax payments to government entities. </div>
          : ""
      }
    </Row>
    <Row>
      <ServiceFeeBreakdown chargeAmount={serviceFee.chargeAmount}
        taxPercentage={serviceFee.taxRate}
        securspaceServiceFee={securspaceServiceFeePercentage}
      />
    </Row>
    <Row>
      <NotesFormGroup value={serviceFee.notes}
        onChange={handleNotesChange}
      />
    </Row>
    <Row>
      {
        errorMessage && errorMessage !== '' && <Error>{errorMessage}</Error>
      }
    </Row>
    <Row className='g-3 g-md-5'>
      <Col sm={6}>
        <Row>
          <SolidButton label={'Charge'}
            className={'w-100'}
            onClick={handleSubmit}
          />
        </Row>
      </Col>
      <Col sm={6}>
        <Row>
          <OutlinedButton label={'Cancel'}
            className={'w-100'}
            onClick={onCancel}
          />
        </Row>
      </Col>
    </Row>
  </Form>
}

export default ServiceFeeChargeFormLegacy;
