import moment from "moment";

export const formatDate = (date) => {
  if (date !== '') {
    const [ year, month, day ] = date.split('-');
    return [ month, day, year ].join('/');
  } else {
    return date;
  }
}

export const formatDateYYMMDD = (date) => {
  if (date !== '') {
    const [ month, day, year ] = date.split('/');
    return [ year, month, day ].join('-');
  } else {
    return date;
  }
}

export const formatDateMMDDYYYY = (date) => {
  return moment(date).format('MM/DD/YYYY hh:mm A')
}

export const formatDateMMDDyyyy = (date) => {
  return moment(date).format('MM/DD/YYYY')
}

export const formatDateMDyyyy = (date) => {
  return moment(date).format('M/D/yyyy');
}

export const calculateNumberOfDays = (startDate, endDate) => {
  const duration = moment(endDate).diff(startDate, 'days') + 1;
  return duration
}


export function calculateProratedAmount(startDate, totalAmount, duration) {
  const daysInMonth = moment(startDate).daysInMonth();

  return (totalAmount / daysInMonth) * duration;
}