import React, {useState} from 'react';
import {InfoCard} from "@securspace/securspace-ui-kit";

const headCells: {id: string, label: string}[] = [
  {id: 'transactionNumber', label: 'Invoice Number'},
  {id: 'bookingNumber', label: 'Booking Number'},
  {id: 'startDate', label: 'Start Date'},
  {id: 'endDate', label: 'End Date'},
  {id: 'transactionType', label: 'Charge Type'},
  {id: 'buyerCompanyName', label: 'Buyer'},
  {id: 'createdOn', label: 'Invoice Created'},
  {id: 'chargeCode', label: 'Revenue Charge Code'},
  {id: 'buyerAmount', label: 'Buyer Amount'},
  {id: 'paymentProcessorFees', label: 'Payment Processor Amount'},
  {id: 'transactionTaxAmount', label: 'Sales Tax Amount'},
  {id: 'supplierAmount', label: 'Supplier Amount'},
  {id: 'securspaceFees', label: 'SecurSpace Amount'},
  {id: 'paymentType', label: 'Payment Type'},
  {id: 'paymentProcessor', label: 'Payment Processor'},
  {id: 'paymentCreatedOn', label: 'Payment Initiated'},
  {id: 'paymentCompletedOn', label: 'Process Completed'},
  {id: 'payoutInitiated', label: 'Payout Initiated'},
  {id: 'payoutCompletedOn', label: 'Payout Completed'},
  {id: 'supplierPayoutOnHold', label: 'Payout On Hold'},
  {id: 'buyerCustomerCode', label: 'Buyer Code'},
  {id: 'bookingStatus', label: 'Booking Status'},
  {id: 'numberOfSpaces', label: 'Spaces'},
  {id: 'assetType', label: 'Equipment Type'},
  {id: 'serviceFeeType', label: 'Service Charge Type'},
  {id: 'bookingCreatedOn', label: 'Booking Created On'},
  {id: 'bookingCreatedBy', label: 'Booking Created By'},
  {id: 'supplierCompanyName', label: 'Supplier'},
  {id: 'locationName', label: 'Location'},
  {id: 'locationAddress', label: 'Location Address'},
  {id: 'locationIsLive', label: 'Location Is Live'},
  {id: 'supplierSubscriptionType', label: 'Supplier Subscription Type'},
  {id: 'status', label: 'Transaction Status'},
  {id: 'buyerTransactionStatus', label: 'Buyer Transaction Status'},
  {id: 'supplierTransactionStatus', label: 'Supplier Transaction Status'},
  {id: 'statusNotes', label: 'Status'},
  {id: 'paymentMessage', label: 'Payment Message'},
  {id: 'taxStatus', label: 'Tax Status'},
];

const InvoiceCard = ({invoice}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <InfoCard
      data={invoice}
      headCells={headCells}
      collapse={expanded}
      handleToggle={() => setExpanded(!expanded)}
      links={{ bookingNumber: {baseUrl: '/admin-bookings', queryParam: 'bookingNumber'} }}
    />
  );
};

export default InvoiceCard;