import React, {useState} from 'react';
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Theme, Typography} from "@mui/material";
import {AdminPanelSettings} from "@mui/icons-material";
import {AccountType, DateFormats, subscriptionTypeOptions} from "../constants/securspace-constants";
import moment from "moment";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {makeStyles} from "@mui/styles";
import {createUpdateAccount} from "../user/request/user-requests";
import Busy from "../Busy";
import {withSnackbar} from "../hocs/withSnackbar";
import {formatCurrencyValue, parseCurrencyValue, validateCurrencyValue, isInteger} from "../../util/PaymentUtils";
import {requestLoggedInAuthoritySource} from "../../services/session/session-requests";

const useStyles: (theme: Theme) => {
  columnGap5: CSSStyleSheet,
  marginTop20: CSSStyleSheet,
  width100: CSSStyleSheet,
  marginTop5: CSSStyleSheet,
  rowGap10: CSSStyleSheet,
  fontSize: CSSStyleSheet,
} = makeStyles(() => ({
  columnGap5: {
    columnGap: '0.36rem',
  },
  marginTop20: {
    marginTop: '1.43rem',
  },
  width100: {
    width: '100%',
  },
  marginTop5: {
    marginTop: '0.36rem',
  },
  rowGap10: {
    rowGap: '0.71rem',
  },
  fontSize: {
    fontSize: '0.87rem',
  },
}));

const AdminInfo = (props) => {
  const {
    account,
    snackbarShowMessage,
    handleLogout,
    handleAccountUpdated
  } = props;

  const [feePercentage, setFeePercentage] = useState(account.feePercentage);
  const [subscriptionType, setSubscriptionType] = useState(account.subscriptionType);
  const [startDate, setStartDate] = useState(account.subscriptionEffectiveDate);
  const [subscriptionFee, setSubscriptionFee] = useState(account.subscriptionFee);
  const [billingFeePercentage, setBillingFeePercentage] = useState(account.billingFeePercentage);
  const [securspaceServiceFeePercentage, setSecurspaceServiceFeePercentage] = useState(account.securspaceServiceFeePercentage);
  const [changesMade, setChangesMade] = useState(false);

  const classes = useStyles();

  const valueInRange = (value, min, max) => {
    return value >= min && value <= max;
  }

  const handleFeePercentageChange = (event) => {
    let value = event.target.value;
    if (value.length && (!isInteger(value) || !valueInRange(parseInt(value), 0, 100))) {
      return;
    }
    setChangesMade(true);
    setFeePercentage(value);
  };

  const handleSubscriptionTypeChange = (event) => {
    setChangesMade(true);
    setSubscriptionType(event.target.value);
  };

  const handleFromDateChange = (date) => {
    setChangesMade(true);
    if (date.isValid()) {
      setStartDate(moment(date).format(DateFormats.DAY));
    } else {
      setStartDate(null);
    }
  };

  const handleSubscriptionFeeChange = (event) => {
    let value = event.target.value;
    if (!validateCurrencyValue(value)) {
      return;
    }
    setChangesMade(true);
    setSubscriptionFee(parseCurrencyValue(value));
  };

  const handleServiceFeeChange = (event) => {
    let value = event.target.value;
    if (value.length && !valueInRange(parseFloat(value), 0, 100)) {
      return;
    }
    setChangesMade(true);
    setBillingFeePercentage(value);
  };

  const handleSubscriptionBillingFeeChange = (event) => {
    let value = event.target.value;
    if (value.length && !valueInRange(parseFloat(value), 0, 100)) {
      return;
    }
    setChangesMade(true);
    setSecurspaceServiceFeePercentage(event.target.value);
  };

  const handleResponseError = (error, message, number) => {
    if (error.status === 401) {
      handleLogout();
    } else {
      snackbarShowMessage(message, 'error', number);
    }
    Busy.set(false);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    Busy.set(true);

    const body = {
      ...account,
      feePercentage : feePercentage,
      subscriptionFee : subscriptionFee,
      billingFeePercentage : billingFeePercentage,
      subscriptionType : subscriptionType,
      subscriptionEffectiveDate: startDate,
      securspaceServiceFeePercentage : securspaceServiceFeePercentage,
    }

    createUpdateAccount(body).then(() => {
      requestLoggedInAuthoritySource().then((resp) => {
        handleAccountUpdated(resp.body);
        snackbarShowMessage('Admin info updated successfully', 'success', 8000);
      }).catch(error => {
        handleResponseError(error, 'An error occurred while updating the Admin info', 10000);
      }).finally(() => {
        Busy.set(false);
        setChangesMade(false);
      });
    }).catch(error => {
      handleResponseError(error, 'An error occurred while updating the Admin info', 15000);
    });
  };

  return <form className={classes.marginTop20}>
    <Grid item container component={'header'} className={classes.columnGap5} alignContent={'center'}>
      <AdminPanelSettings color={'action'}/>
      <Typography variant={'h6'}>Admin Information</Typography>
    </Grid>
    <Grid container direction={'column'} className={classes.rowGap10}>
      {account.type === AccountType.SUPPLIER &&
        <>
          <TextField
            variant='standard'
            value={feePercentage}
            label={'Marketplace Commission Fee %'}
            name={'feePercentage'}
            onChange={handleFeePercentageChange}
            placeholder={'Enter the SecurSpace Fee %'}
            fullWidth
          />
          <FormControl variant="standard" className={"ss-byb-details-input"} >
            <InputLabel id='ssSubscriptionTypeSelect' variant={'standard'} color={'primary'}>Subscription
              Type</InputLabel>
            <Select
              value={subscriptionType || subscriptionTypeOptions[0]}
              label="Subscription Type"
              onChange={handleSubscriptionTypeChange}
              className='ss-byb-details-input'
              labelId="ssSubscriptionTypeSelect"
              fullWidth
            >
              {
                subscriptionTypeOptions?.map(item => (
                  <MenuItem key={item.value} value={item.value} name={item.label}>{item.label}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid container className={classes.marginTop5}>
              <InputLabel
                className={classes.fontSize}
                id='ssSubscriptionStartDate'
                variant={'standard'}
                color={'primary'}
              >Subscription Start Date</InputLabel>
              <DatePicker
                className={classes.width100}
                value={moment(startDate, DateFormats.DAY)}
                onChange={handleFromDateChange}
                format={DateFormats.DAY}
                slotProps={{textField: {size: "small", error: false}}}
              />
            </Grid>
          </LocalizationProvider>
          <TextField
            variant='standard'
            value={formatCurrencyValue(subscriptionFee)}
            label={'Subscription Fee'}
            name={'subscriptionFee'}
            onChange={handleSubscriptionFeeChange}
            placeholder={'Enter the Subscription Monthly Fee'}
            fullWidth
          />
          <TextField
            variant='standard'
            value={securspaceServiceFeePercentage}
            label={'Subscription Billing Fee %'}
            name={'securspaceServiceFeePercentage'}
            onChange={handleSubscriptionBillingFeeChange}
            placeholder={'Enter the Subscription Billing Fee %'}
            fullWidth
          />
          <TextField
            variant='standard'
            value={billingFeePercentage}
            label={'Service Fee %'}
            name={'serviceFee'}
            onChange={handleServiceFeeChange}
            placeholder={'Enter the Service Fee %'}
            fullWidth
          />
        </>
      }
    </Grid>
    <Button
      type={'submit'}
      variant={'contained'}
      color={'secondary'}
      fullWidth
      className={classes.marginTop20}
      disabled={!changesMade}
      onClick={handleSubmit}
    >
      Save Admin Information
    </Button>
  </form>
}

export default withSnackbar(AdminInfo);
