import React from 'react';

const SubscriptionActive = (props) => {
  const {active, handleChange} = props;

  return <>
    <fieldset className="ss-subscription ss-stand-alone">
      <label htmlFor="active">ACTIVE</label>
      <input type={'checkbox'}
             name={'active'}
             style={{marginLeft: '2em'}}
             checked={active}
             onChange={handleChange}
      />
    </fieldset>
  </>
}

export default SubscriptionActive;
