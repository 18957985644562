import React from "react";
import { TableBody } from "@mui/material";
import UsersTableRow from "./UsersTableRow";

const UsersTableUserMap = ({usersPerRow, handleEdit, onDeleteUser, classes, setUserToDelete}) => {
    return <TableBody>
        { 
            usersPerRow.map((user, index) => {
                return (
                    <UsersTableRow
                        index={index}
                        key={user.username}
                        user={user}
                        handleEdit={handleEdit} 
                        onDeleteUser={onDeleteUser}
                        classes={classes}
                        setUserToDelete={setUserToDelete}
                    />
                )
            }
        ) }
        </TableBody>
      
};
export default UsersTableUserMap;
