import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, Typography} from "@mui/material";
import {
  validateEmail, validateName,
  validatePhoneNumber
} from "../../../util/ValidationUtils";
import {createNewUser, updateExistingUser, deleteUser, getAccountUsers} from "../request/user-requests";
import Busy from "../../Busy";
import {createLogoutOnFailureHandler} from "../../../util/LogoutUtil";
import UsersTable from "./UsersTable";
import {AccountType, SubscriptionType, UserTypeDBFormat} from "../../constants/securspace-constants";
import CreateEditUser from "./CreateEditUser";
import {withSnackbar} from "../../hocs/withSnackbar";
import {getErrorMessageForNonStandardAndStandardResponse} from '../../../util/NetworkErrorUtil';
import UserDialog from "./UserDialog";

const NEW_USER = Object.freeze({
  username: "",
  role: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  locationIds: [],
  isLocationManager: false,
});

const UserManagement = ({account, handleLogout, snackbarShowMessage}) => {
  const [users, setUsers] = useState([]);
  const [userToEdit, setUserToEdit] = useState({...NEW_USER});
  const [userToDelete, setUserToDelete] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [showCreateEditUser, setShowCreateEditUser] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState('Please enter an email address.');
  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState("Please enter the user's first name.");
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState("Please enter the user's last name");
  const [phoneNumberErrorMsg, setPhoneNumberErrorMsg] = useState(''); // not required
  const [roleErrorMsg, setRoleErrorMsg] = useState('');
  const [formErrorMsg, setFormErrorMsg] = useState('');
  const [adminUsers, setAdminUsers] = useState([]);
  const [showEditAlert, setShowEditAlert] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogText, setDialogText] = useState('');

  const loadAccountUsers = useCallback(() => {
    Busy.set(true);
    getAccountUsers(account.id).then(response => {
      Busy.set(false);
      const {body} = response;
      if (body) {
        setUsers(body);
        const usersWithAdminOrOwner = body.filter(user => (user.role === UserTypeDBFormat.ROLE_USERTYPE_ADMIN || user.role === UserTypeDBFormat.ROLE_USERTYPE_OWNER))
        setAdminUsers(usersWithAdminOrOwner)
      }
    }).catch(error => {
      Busy.set(false);
      if (error.response.status === 401) {
        createLogoutOnFailureHandler(handleLogout);
      }
      snackbarShowMessage('Failed to fetch users. Please contact support.', 'error', 15000);
    });
  }, [account, handleLogout, snackbarShowMessage]);

  useEffect(() => {
    loadAccountUsers();
  }, [loadAccountUsers]);

  const handleAddUserClick = () => {
    setIsEdit(false);
    setShowCreateEditUser(true);
  };

  const handleCloseCreateEditUser = () => {
    setShowCreateEditUser(false);
    setUserToEdit({...NEW_USER});
  };

  const emailValidation = (value) => {
    if (!value || !value.trim()) return 'Please enter an email address.';
    return validateEmail(value) ? '' : 'Email address is invalid.';
  };

  const nameValidation = (value, label) => {
    if (!value || !value.trim()) {
      return `Please enter the user's ${label}.`;
    }
    return validateName(value) ? '' : `${label} can contain only spaces, hyphens, and alphanumeric characters`;
  };

  const handleSimpleTextChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    switch (name) {
      case 'email':
        setUserToEdit({...userToEdit, username: value});
        setEmailErrorMsg(emailValidation(value));
        break;
      case 'firstName':
        setUserToEdit({...userToEdit, firstName: value});
        setFirstNameErrorMsg(nameValidation(value, 'First name'));
        break;
      case 'lastName':
        setUserToEdit({...userToEdit, lastName: value})
        setLastNameErrorMsg(nameValidation(value, 'Last name'));
        break;
      default:
        break;
    }
  };

  const handlePhoneNumberChange = (value) => {
    // PhoneInput returns undefined for empty string so convert to ''
    setUserToEdit({...userToEdit, phoneNumber: value || ''});
  };

  const handleLocationIdsChange = (value) => {
    setUserToEdit({...userToEdit, locationIds: value});
  }

  const handleSelectChange = (event) => {
    setUserToEdit({
      ...userToEdit,
      role: event.target.value,
    });
    setRoleErrorMsg('');
  };

  const handleEditUser = (user) => {
    setIsEdit(true);

    setShowCreateEditUser(true);
    setUserToEdit({...user});
    setEmailErrorMsg('');
    setFirstNameErrorMsg('');
    setLastNameErrorMsg('');
    setPhoneNumberErrorMsg('');
    setRoleErrorMsg('');
  };

  const submitDeleteUserToApi = () => {
    setShowDeleteAlert(false);
    if (userToDelete.username === account.username) {
      snackbarShowMessage('Account Owner cannot be deleted.', 'error', 15000);
      return;
    }

    Busy.set(true);
    deleteUser(userToDelete).then(() => {
      Busy.set(false);
      loadAccountUsers();
    }).catch(error => {
      Busy.set(false);
      if (error.response.status === 401) {
        createLogoutOnFailureHandler(handleLogout);
      }
      snackbarShowMessage('Failed to delete user. Please contact support.', 'error', 15000);
    });
  }

  const handleDeleteUser = (user) => {
    if (user.isLocationManager) {
      setDialogTitle('Delete Location Manager?');
      setDialogText('Removing this user will also remove a Location Manager. You are able to select a new Location Manager at the Location Management page.');
      setShowDeleteAlert(true);
    } else {
      setDialogTitle('Delete User?');
      setDialogText('This user will no longer be able to access this account. Are you sure you want to delete this user?');
      setShowDeleteAlert(true);
    }
  };

  const submitToApi = (event) => {
    event.preventDefault();
    setShowEditAlert(false);
    // These fields validate on change
    if (emailErrorMsg ||
      firstNameErrorMsg ||
      lastNameErrorMsg
    ) {
      if (!userToEdit.role) {
        setRoleErrorMsg('Please select a role.');
      }
      setFormErrorMsg('Please complete the form to proceed');
      return;
    } else {
      // These fields validate on submit
      if (userToEdit.phoneNumber && !validatePhoneNumber(userToEdit.phoneNumber, true)) {
        setPhoneNumberErrorMsg('Phone number must be 10 digits.');
        setFormErrorMsg('Please complete the form to proceed');
        return;
      } else {
        setPhoneNumberErrorMsg('');
      }
      setFormErrorMsg('');
    }

    let locationIds = userToEdit.locationIds;
    if (locationIds?.includes('all')) {
      locationIds = isEdit ? null : [];
    }

    // NOTE: backend expects null for phoneNumber if empty string
    const body = {
      accountId: account.id,
      username: userToEdit.username,
      firstName: userToEdit.firstName.trim(),
      lastName: userToEdit.lastName.trim(),
      phoneNumber: userToEdit.phoneNumber ? userToEdit.phoneNumber : null,
      role: userToEdit.role,
      locationIds: locationIds,
      isLocationManager: userToEdit?.isLocationManager,
    };

    Busy.set(true)
    if (isEdit) {
      updateExistingUser(body).then(() => {
        Busy.set(false);
        setShowCreateEditUser(false);
        loadAccountUsers();
        setUserToEdit({...NEW_USER});
        snackbarShowMessage("User information has been successfully updated.", 'success');
      }).catch(error => {
        Busy.set(false);
        if (error.response.status === 401) {
          createLogoutOnFailureHandler(handleLogout);
        }
        const errorMessage = getErrorMessageForNonStandardAndStandardResponse(error);
        snackbarShowMessage(`Failure to update user information. ${errorMessage}`, 'error', 15000);
      });
    } else {
      createNewUser(body).then(() => {
        Busy.set(false);
        setShowCreateEditUser(false);
        loadAccountUsers();
        setUserToEdit({...NEW_USER});
        snackbarShowMessage("User successfully added. User will receive an email to create a password.", 'success')
      }).catch(error => {
        Busy.set(false);
        if (error.response.status === 401) {
          createLogoutOnFailureHandler(handleLogout);
        }
        const errorMessage = getErrorMessageForNonStandardAndStandardResponse(error)
        snackbarShowMessage(errorMessage, 'error', 15000);
      });
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (account.type === AccountType.SUPPLIER) {
      if (userToEdit?.isLocationManager && userToEdit?.role === UserTypeDBFormat.ROLE_USERTYPE_GATECLERK) {
        setDialogTitle('Downgrade Usertype?');
        setDialogText('Changing this user to a Gate Clerk will also remove a Location Manager. You are able to select a new Location Manager at the Location Management page.');
        setShowEditAlert(true);
      } else {
        submitToApi(event);
      }
    } else {
      submitToApi(event);
    }
  };

  const roleBasedOptions = useMemo(() => {
    if (account.type === AccountType.SUPPLIER) {
      if (account.subscriptionType === SubscriptionType.MARKETPLACE_ONLY) {
        return [UserTypeDBFormat.ROLE_USERTYPE_OWNER];
      } else {
        return [UserTypeDBFormat.ROLE_USERTYPE_OWNER, UserTypeDBFormat.ROLE_USERTYPE_GATECLERK, UserTypeDBFormat.ROLE_USERTYPE_GATEMANAGER];
      }
    } else if (account.type === AccountType.BUYER) {
      return [UserTypeDBFormat.ROLE_USERTYPE_OWNER, UserTypeDBFormat.ROLE_USERTYPE_DISPATCHER];
    }
    return []; // This shouldn't happen...
  }, [account]);

  return (
    <>
      <UserDialog
        showAlert={showEditAlert}
        setShowAlert={setShowEditAlert}
        dialogTitle={dialogTitle}
        dialogText={dialogText}
        submitToApi={submitToApi}
      />
      <UserDialog
        showAlert={showDeleteAlert}
        setShowAlert={setShowDeleteAlert}
        dialogTitle={dialogTitle}
        dialogText={dialogText}
        submitToApi={submitDeleteUserToApi}
      />
      <Grid component={'article'} container>
        <Grid item container justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant={'h5'} component={'h1'} color={'textPrimary'}>Users</Typography>
          <Button variant={'contained'} color={'primary'} onClick={handleAddUserClick}>Add User</Button>
        </Grid>
        <CreateEditUser
          open={showCreateEditUser}
          onClose={handleCloseCreateEditUser}
          onSubmit={handleSubmit}
          onTextChange={handleSimpleTextChange}
          onSelectChange={handleSelectChange}
          onPhoneNumberChange={handlePhoneNumberChange}
          handleLocationIdsChange={handleLocationIdsChange}
          isEdit={isEdit}
          user={userToEdit}
          emailErrorMsg={emailErrorMsg}
          firstNameErrorMsg={firstNameErrorMsg}
          lastNameErrorMsg={lastNameErrorMsg}
          phoneNumberErrorMsg={phoneNumberErrorMsg}
          roleErrorMsg={roleErrorMsg}
          roleBasedOptions={roleBasedOptions}
          formErrorMsg={formErrorMsg}
          adminUsersArray={adminUsers}
          account={account}
        />
        <UsersTable
          users={users}
          onEditUser={handleEditUser}
          onDeleteUser={handleDeleteUser}
          setUserToDelete={setUserToDelete}
        />
      </Grid>
    </>
  );
};

UserManagement.propTypes = {
  account: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default withSnackbar(UserManagement);
