import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import EquipmentTypes from "../../EquipmentTypes";

const EquipmentTypeSelect = ({selection, setSelection, assetTypeList, classes}) => {

  const {OPTIONS} = EquipmentTypes;

  const handleChangeSelection = (event) => {
    setSelection(event.target.value)
  }

  return <FormControl className={classes.root}>
      <InputLabel id='ssEquipmentTypeLabel' className={classes.label}>Equipment Type</InputLabel>
      <Select
        value={selection || OPTIONS[0]}
        label="Equipment Type"
        onChange={handleChangeSelection}
        className='w-100'
        labelId="ssEquipmentTypeLabel"
        variant="standard"
      >
        {
          assetTypeList?.map(item => (
            <MenuItem key={item} value={item}>{item}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
}

export default EquipmentTypeSelect;
