import { Grid, Typography, Button } from '@mui/material'
import React from 'react'

type ReportDataSummaryProps = {
  count: number,
  title: string,
  setExpandedItems?: (items: Object) => void,
  setExpandedAll?: (isExpanded: boolean) => void,
  isShowCollapseButton?: boolean,
  handleExpandAll?: (event: MouseEvent) => void,
  expandedItems?: Object,
  children?: any
}

/**
 * Displays a summary of the report data.
 * Supports expand/collapse all functionality and custom children e.g. CSV download buttons.
 *
 * @param count The number of records in the data group
 * @param title The title header of the report data summary
 * @param setExpandedItems
 * @param setExpandedAll
 * @param isShowCollapseButton
 * @param handleExpandAll
 * @param expandedItems
 * @param children
 * @returns {Element}
 * @constructor
 */
const ReportDataSummary: (props: ReportDataSummaryProps) => JSX.Element = ({
                                count,
                                title,
                                setExpandedItems,
                                setExpandedAll,
                                isShowCollapseButton = false,
                                handleExpandAll,
                                expandedItems,
                                children
                              }) => {
  return (
    <Grid item container mt={4} mb={4} sx={{
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '1rem'
    }}>
      <Grid item sx={{
        display: 'flex',
        alignItems: 'center',
        gap: "1rem",
        flexWrap: 'wrap'
      }}>
        <Typography variant='h6' sx={{
          textTransform: 'uppercase',
        }}>{title} (SHOWING ALL {count} RECORDS IN GROUP)</Typography>
        {isShowCollapseButton &&
          <Button onClick={Object.keys(expandedItems).length > 0 ? () => {
            setExpandedItems({})
            setExpandedAll(false)
          } : handleExpandAll} variant='text'>
            {Object.keys(expandedItems).length > 0 ? 'Collapse All' : 'Expand All'}
          </Button>
        }
      </Grid>
      {children}
    </Grid>
  )
}

export default ReportDataSummary;
