import React from 'react';
import {Typography} from "@mui/material";

const BookingDetails = (props: { title: string, value: any, headerClass?: string, valueClass?: string}) => {
  const {title, value, headerClass, valueClass} = props;

  return (
    <>
      <Typography color={'textSecondary'} className={headerClass}>
        {title}
      </Typography>
      <Typography variant="h6" color={'textPrimary'} className={valueClass}>{value}</Typography>
    </>
  );
};

export default BookingDetails;