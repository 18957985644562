import React from 'react';
import {Grid, Typography} from "@mui/material";

const NoBookingsFound = () => {
    const imageURI =  "/app-images/person-and-box.svg";
    const message = "No matching bookings found"

  return (
    <Grid item container spacing={4}>
      <Grid item xs={12} textAlign={'center'}>
        <img src={imageURI} alt={message} />
      </Grid>
      <Grid item xs={12} textAlign={'center'}>
        {
          message && React.isValidElement(message)
            ? message
            : <Typography variant='body2'>{message}</Typography>
        }
      </Grid>
    </Grid>
  );

};

export default NoBookingsFound;