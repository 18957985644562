import React, { Component } from 'react';
import "../css/components/dropzone.css";
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import ModalForm from "../components/ModalForm";

const MAX_FILES = 10;
const MAX_FILE_SIZE_BYTES = 20971520; // 20 MB in bytes
const ACCEPTED_FILE_TYPES = {
  'image/*': ['.png', '.gif', '.jpeg', '.jpg']
}

const S3_BASE_URL = `https://s3-us-west-1.amazonaws.com/securspace-files`;

class DropGallery extends Component {

  constructor(props) {
    super(props);
    this.galleryFiles = [];
    this.cropperRef = React.createRef();
    this.state = {
      errorMessage: this.props.errorMessage ? this.props.errorMessage : null,
      folder: this.props.folder,
      bucket: this.props.bucket,
      showCropper: 0,
      totalFiles: 0,
      cropFile: null,
      dropzone: { files: [] },
      galleryImagesArray: this.props.galleryImagesArray,
      imageFileNameAttribute: this.props.imageFileNameAttribute,
      imageFilePath: this.props.imageFilePath,
      maxFiles: this.props.maxFiles || MAX_FILES
    }
  }

  componentDidMount() {
    this.preloadImages();
  }

  handleFileAdded = ([file]) => {
    let _this = this;
    if (file && (typeof file.cropped == 'undefined' || file.cropped === 0)) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        if (file.status !== 'error') {
          // Do whatever you want with the file contents
          Object.assign(file, {
            dataURL: URL.createObjectURL(file)
          })
          file.cropped = 0;
          _this.setState({ showCropper: 1, cropFile: file });
          _this.setState({ errorMessage: null });
        }
      };
      fileReader.readAsArrayBuffer(file);
    }

  };

  handleError = (rejectionErrors) => {
    if (rejectionErrors && rejectionErrors.length > 0) {
      const [error] = rejectionErrors[0].errors;
      toast.error(`Error: code: ${error.code}, message: ${error.message}`);
    }
  }

  addFile = (file) => {
    this.setState({ totalFiles: this.state.totalFiles + 1 });
    this.state.dropzone.files.push(file);
    this.props.updateDropzone(this.state.dropzone);
  }

  preloadImages = () => {
    if (this.state.galleryImagesArray) {
      for (const galleryImage of this.state.galleryImagesArray) {
        const url = `${S3_BASE_URL}/${this.state.bucket}/${this.state.folder}/${encodeURIComponent(galleryImage[this.state.imageFileNameAttribute])}`;
        this.addFile({
          name: galleryImage[this.state.imageFileNameAttribute],
          cropped: 1,
          size: null,
          accepted: true,
          type: 'fake',
          url: url
        });
      }
    } else if (this.state.imageFilePath) {
      const url = `${S3_BASE_URL}/${this.state.bucket}/${encodeURIComponent(this.state.imageFilePath)}`;
      this.addFile({
        name: this.state.imageFilePath,
        cropped: 1,
        size: null,
        accepted: true,
        type: 'fake',
        url: url
      });
    }
  };

  cancelCropper = type => {
    if (typeof type !== 'undefined') {
      //close after save so not shift
    } else {
      this.setState({ cropFile: null });
      this.setState({ showCropper: false, locationEqIndex: null });
    }
  };

  customRemoveFile = index => {
    this.setState({ totalFiles: this.state.totalFiles - 1 });
    this.state.dropzone.files.splice(index, 1);
    this.forceUpdate();
  };

  _crop = () => {
    URL.revokeObjectURL(this.state.cropFile.dataURL);
    let file = this.state.cropFile;
    file.dataURL = this.cropperRef.current.cropper.getCroppedCanvas(
      {
        width: 1024,
        height: 680,
        minWidth: 512,
        minHeight: 340,
        maxWidth: 4096,
        maxHeight: 4096,
        fillColor: '#fff',
        imageSmoothingEnabled: false,
        imageSmoothingQuality: 'high',

      }).toDataURL(file.type, "0.9");

    file.cropped = 1;
    this.addFile(file);
    this.setState({ cropFile: null });
    this.setState({ showCropper: false });
  };

  render() {

    let _this = this;

    return (
      <div>

        <Dropzone
          multiple={false}
          maxSize={MAX_FILE_SIZE_BYTES}
          accept={ACCEPTED_FILE_TYPES}
          onDropRejected={this.handleError}
          disabled={this.state.totalFiles === this.state.maxFiles}
          drop
          onDrop={this.handleFileAdded}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ className: "dropzone-box" })}>
                <input {...getInputProps()} />
                {
                  this.state.totalFiles === this.state.maxFiles ?
                    <p>You cannot upload more than {this.state.maxFiles} file/s</p>
                    :
                    <p>Drag'n'drop a file, or click to select a file</p>
                }
              </div>
            </section>
          )}
        </Dropzone>

        <div className="dz-gallery">
          {
            this.state.dropzone.files && this.state.dropzone.files.length > 0 ?
              this.state.dropzone.files.map((item, index) =>
                <div key={index} className="dz-image" data-key={index}>
                  <img src={item.type === 'fake' ? item.url : item.dataURL} alt="gallery" />
                  <span><button type="button" onClick={() => this.customRemoveFile(index)}
                    title="Remove file">Remove file</button></span>
                </div>
              )
              : ""
          }
        </div>
        <ModalForm showForm={this.state.showCropper}
          size="large"
          title="Crop Uploaded Images"
          onClose={_this.cancelCropper}
          proceedEventHandler={this._crop}
          textOk={this.state.cropFile ? 'Next' : 'Save'}
          textAlign="pull-right"
          errorMessage={this.state.errorMessage}>
          {this.state.cropFile ?

            <Cropper
              ref={this.cropperRef}
              src={this.state.cropFile.dataURL}
              style={{ width: '100%', maxHeight: '32.14em', height: '100%', objectFit: 'contain' }}
              guides={true}
              autoCropArea={1}
              highlight={true}
              viewMode={1}
              checkOrientation={false}
            />
            : null}
        </ModalForm>
      </div>
    )
  }
}

export default DropGallery;
