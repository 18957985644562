import React from 'react'
import { Grid } from '@mui/material'
import { InfoCard, NoDataMessage } from '@securspace/securspace-ui-kit'
import {
  DocumentScanner,
  History,
  Flaky
} from '@mui/icons-material';
import { headerCells } from './data'
import { styles } from '../styles'
import type {InvoiceTransaction} from "../../../types/InvoiceTransaction";
import {BookingTransactionType} from "../../constants/securspace-constants";
import {useFlags} from "launchdarkly-react-client-sdk";

type Props = {
  invoices: Array<InvoiceTransaction>,
  loading: boolean,
  locationId: string,
  invoiceNumber: string,
  bookingNumber: string,
  invoiceType: string,
  sortBy: string,
  expandedItems: Object,
  handleToggle: (id: string) => void
};

const BuyerInvoicesTable = ({
  invoices,
  loading,
  locationId,
  invoiceNumber,
  bookingNumber,
  invoiceType,
  sortBy,
  expandedItems,
  handleToggle
}: Props) => {

  const {buyerSpaceUsageRedesign} = useFlags();

  const viewInvoice = (item) => {
    if (item) {
      window.open(`/buyer-invoice/${item?.transactionNumber}`);
    }
  }

  const viewHistory = (item: InvoiceTransaction) => {
    if (!buyerSpaceUsageRedesign) {
      window.open(`/api/overage-daily-report/${item?.transactionId}`);
      return;
    }
    let queryParams;
    if ([BookingTransactionType.OVERAGE_CHARGE, BookingTransactionType.DAILY_OVERAGE_CHARGE].includes(item.transactionType)) {
      queryParams = new URLSearchParams({overageInvoiceNumber: item.transactionNumber}).toString();
    } else {
      queryParams = new URLSearchParams(
        {initBookingNumber: item.bookingNumber, initStartDate: item.startDate, initEndDate: item.endDate})
        .toString();
    }
    window.open(`/reports/space-usage?${queryParams}`);
  }

  const viewApproveDisputeInvoice = (item) => {
    if (item) {
      //window.open(`/buyer-invoice/${item?.transactionNumber}`);
      window.open(`/buyer/service-fee/${item.transactionId}`);
    }
  }

  const actionItems = [
    {
      icon: <DocumentScanner fontSize='medium' color='primary' />,
      label: "View Invoice",
      onClick: viewInvoice
    },
    {
      icon: <History fontSize='medium' color='primary' />,
      label: "View History",
      onClick: viewHistory
    }
  ];

  const conditionalActionsItems = invoices?.map((invoice: InvoiceTransaction, index) => {
        if (invoice.transactionType === 'Service Charge') {
            return {
                id: index,
                icon: <Flaky fontSize='medium' color='primary' />,
                label: "Approve/Dispute Invoice",
                onClick: viewApproveDisputeInvoice
            };
        } else {
            return null;
        }
    }).filter(item => item !== null);

  return (
    <Grid item mb={4} container flexDirection={'column'} sx={{
      ...(!invoices.length && !loading) && styles.container,
      '& .css-13i4rnv-MuiGrid-root': {
          wordWrap:'normal',
      },
      '@media(max-width: 39.28em)': {
        '& .css-13i4rnv-MuiGrid-root': {
          top:'4%',
        }
      }

    }}>
      {(!invoices.length && !loading) ? (
        <Grid sx={styles.showMessage}>
          {
            invoiceNumber || locationId || bookingNumber || invoiceType || sortBy ? (
              <NoDataMessage
                image="/app-images/person-and-box.svg"
                message="No matching records found."
              />
            ) : (
              <NoDataMessage
                image="/app-images/no-data-match.png"
                message="No input selected, To view results kindly select the data above"
              />
            )
          }
        </Grid>
      ) : (
        invoices.map((invoice: InvoiceTransaction, index) => (
          <InfoCard
            headCells={headerCells}
            data={invoice}
            actionItems={actionItems}
            conditionalActionItems={conditionalActionsItems}
            inx={index}
            key={invoice.transactionId}
            handleToggle={handleToggle}
            collapse={expandedItems[invoice.transactionId]}
          />
        ))
      )}
    </Grid>
  )
}

export default BuyerInvoicesTable
