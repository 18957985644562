import React from "react";
import { Grid, Typography, Paper } from "@mui/material";
import { makeStyles } from '@mui/styles';
import StoreIcon from '@mui/icons-material/Store';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import WidgetsIcon from '@mui/icons-material/Widgets';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LanguageIcon from '@mui/icons-material/Language';
import classNames from "classnames";

const useStyles: (theme) => {
    signUpInfoMapTitle: CSSStyleSheet,
    signUpInfoMapItemTitle: CSSStyleSheet,
} = makeStyles(() => ({
    signUpInfoMapTitle: {
        fontFamily: 'Satoshi-Variable',
        fontSize: '1.71rem',
        fontWeight: 700,
        lineHeight: '100%',
    },
    signUpInfoMapItemTitle: {
        fontFamily: 'Satoshi-Variable',
        fontSize: '1.43rem',
        fontWeight: 700,
        lineHeight: '143%',
    },
}));

export const PartnerInfo = () => {
    const classes = useStyles();

    return (
        <Paper className="ss-sign-up-info-map-parent">
            <Typography component="h5" className={classNames("ss-sign-up-info-map-title", classes.signUpInfoMapTitle)}>As a Supplier you can</Typography>
            <Grid container className="ss-sign-up-info-map-container">

                <Grid container className="ss-sign-up-info-map-item-container">
                    <Grid item className="ss-sign-up-info-map-item-icon">
                        <StoreIcon fontSize='large'/>
                    </Grid>
                    <Grid item className="ss-sign-up-info-map-item-text-container">
                        <Typography variant="body1" className={classes.signUpInfoMapItemTitle}>List Your Space for Free</Typography>
                        <Typography variant="subtitle1">Signing up as a supplier is completely free. List, market, and share your space without spending a dime.</Typography>
                    </Grid>
                </Grid>

                <Grid container className="ss-sign-up-info-map-item-container">
                    <Grid item className="ss-sign-up-info-map-item-icon">
                        <AttachFileIcon fontSize='large'/>
                    </Grid>
                    <Grid item className="ss-sign-up-info-map-item-text-container">
                        <Typography variant="body1" className={classes.signUpInfoMapItemTitle}>Zero Paperwork</Typography>
                        <Typography variant="subtitle1">Invoicing and payment are handled completely by SecurSpace. Buyers pay at the time of booking, and suppliers are paid via direct deposit in just a few days.</Typography>
                    </Grid>
                </Grid>

                <Grid container className="ss-sign-up-info-map-item-container">
                    <Grid item className="ss-sign-up-info-map-item-icon">
                        <WidgetsIcon fontSize='large'/>
                    </Grid>
                    <Grid item className="ss-sign-up-info-map-item-text-container">
                        <Typography variant="body1" className={classes.signUpInfoMapItemTitle}>Stay in Control of Your Yard</Typography>
                        <Typography variant="subtitle1">When a buyer submits a booking request suppliers can accept or decline the booking any time, for any reason.</Typography>
                    </Grid>
                </Grid>

                <Grid container className="ss-sign-up-info-map-item-container">
                    <Grid item className="ss-sign-up-info-map-item-icon">
                        <MonetizationOnIcon fontSize='large'/>
                    </Grid>
                    <Grid item className="ss-sign-up-info-map-item-text-container">
                        <Typography variant="body1" className={classes.signUpInfoMapItemTitle}>Charge for All Your Services</Typography>
                        <Typography variant="subtitle1">Does your operation offer other services in addition to yard space? You can easily charge for M&R and other add-ons through the platform.</Typography>
                    </Grid>
                </Grid>

            </Grid>
        </Paper>
    )
};

export const CustomerInfo = () => {
    const classes = useStyles();

    return (
        <Paper className="ss-sign-up-info-map-parent">
            <Typography component="h5" className={classNames("ss-sign-up-info-map-title", classes.signUpInfoMapTitle)}>As a Buyer you can</Typography>
            <Grid container className="ss-sign-up-info-map-container">

                <Grid container className="ss-sign-up-info-map-item-container">
                    <Grid item className="ss-sign-up-info-map-item-icon">
                        <VerifiedUserIcon fontSize='large'/>
                    </Grid>
                    <Grid item className="ss-sign-up-info-map-item-text-container">
                        <Typography variant="body1" className={classes.signUpInfoMapItemTitle}>Find the Right Fit</Typography>
                        <Typography variant="subtitle1">Search for available parking and storage space with the security, visibility, and access that meets your needs.</Typography>
                    </Grid>
                </Grid>

                <Grid container className="ss-sign-up-info-map-item-container">
                    <Grid item className="ss-sign-up-info-map-item-icon">
                        <DateRangeIcon fontSize='large'/>
                    </Grid>
                    <Grid item className="ss-sign-up-info-map-item-text-container">
                        <Typography variant="body1" className={classes.signUpInfoMapItemTitle}>Flexible Arrangements</Typography>
                        <Typography variant="subtitle1">Reserve and pay only for the capacity that you need - no more long-term commitments.</Typography>
                    </Grid>
                </Grid>

                <Grid container className="ss-sign-up-info-map-item-container">
                    <Grid item className="ss-sign-up-info-map-item-icon">
                        <LanguageIcon fontSize='large'/>
                    </Grid>
                    <Grid item className="ss-sign-up-info-map-item-text-container">
                        <Typography variant="body1" className={classes.signUpInfoMapItemTitle}>Growing National Networks</Typography>
                        <Typography variant="subtitle1">Access a growing network of locations in every major transportation hub.</Typography>
                    </Grid>
                </Grid>

            </Grid>
        </Paper>
    )
};