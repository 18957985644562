import {Grid} from '@mui/material'
import React, {useState, useMemo} from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import PropTypes from "prop-types";
import { DateFormats } from '../../constants/securspace-constants';
import moment from 'moment';

const FromToDatePicker = ({
                                                                           fromDate,
                                                                           setFromDate,
                                                                           toDate,
                                                                           setToDate,
                                                                           disablePast = false,
                                                                           showEndDate = true,
                                                                           disabled = false,
                                                                           minToDate,
                                                                           startEndLabel = false,
                                                                           disableFuture = false
                                                                         }) => {
  const [error, setError] = useState(null);
    const minDateMessage = "Date cannot be before start date";

    const errorMessage = useMemo(() => {
        switch (error) {
          case 'maxDate':
          case 'minDate': {
            return minDateMessage;
          }
          case 'invalidDate': {
            return 'Your date is not valid';
          }
          default: {
            return '';
          }
        }
      }, [error]);
    
    

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid item container className='ss-container-gap ss-from-to-date-picker'>
                <Grid item xs={12} md>
                    <DatePicker
                        label={startEndLabel ? "Start" : "From"}
                        className="ss-date-from w-100"
                        disableToolbar
                        disabled={disabled}
                        disablePast={disablePast}
                        slotProps={{textField: {variant: "standard", helperText: errorMessage}}}
                        value={moment(fromDate, DateFormats.DAY)}
                        onChange={(date) => setFromDate(moment(date, DateFormats.DAY))}
                        format={DateFormats.DAY}
                        margin="normal"
                        onError={(newError) => setError(newError)}
                        disableFuture={disableFuture}
                    />
                </Grid>
                {
                  showEndDate && <Grid item xs={12} md>
                    <DatePicker
                        label={startEndLabel ? "End" : "To"}
                        className='ss-date-to w-100'
                        disableToolbar
                        disabled={disabled}
                        disablePast={disablePast}
                        slotProps={{textField: {variant: "standard", helperText: errorMessage}}}
                        onError={(newError) => setError(newError)}
                        value={moment(toDate, DateFormats.DAY)}
                        onChange={(date) => setToDate(moment(date, DateFormats.DAY))}
                        variant="inline"
                        format={DateFormats.DAY}
                        margin="normal"
                        minDate={moment(minToDate, DateFormats.DAY) || moment(fromDate, DateFormats.DAY)}
                        disableFuture={disableFuture}
                    />
                  </Grid>
                }
            </Grid>
        </LocalizationProvider>
    )
};

FromToDatePicker.propTypes = {
  fromDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  toDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  setFromDate: PropTypes.func.isRequired,
  setToDate: PropTypes.func.isRequired,
  disablePast: PropTypes.bool,
  showEndDate: PropTypes.bool,
  disabled: PropTypes.bool,
  minToDate: PropTypes.object,
  startEndLabel: PropTypes.bool,
  disableFuture: PropTypes.bool,
};

export default FromToDatePicker;
