import React from 'react';
import CreateIcon from "@mui/icons-material/Create";
import {IconButton} from "@mui/material";
import {Check} from "@mui/icons-material";
import {SyntheticEvent} from "react";
import {Theme} from "@mui/material";
import classNames from "classnames";
import { makeStyles } from '@mui/styles';

const useStyles: (theme: Theme) => {
  confirmButton: CSSStyleSheet,
  confirmIcon: CSSStyleSheet
} = makeStyles((theme: Theme) => ({
  confirmButton: {
    '&.MuiIconButton-root': {
      backgroundColor: "#3F51B514",
      padding: '0.29rem',
    }
  },
  confirmIcon: {
    '&.MuiSvgIcon-root': {
      fontSize: '1.43rem',
      fill: theme.palette.secondary.main,
    }
  }
}));


const EditToggleButton = (props: { open: boolean, onClick: () => void, className?: string }) => {
  const {open, onClick, className} = props;
  const classes = useStyles();

  const handleToggle = (event: SyntheticEvent) => {
    onClick(!open, event);
  };

  return <IconButton onClick={handleToggle} aria-label={open ? 'confirm' : 'edit'} color={'secondary'}
                     className={classNames(open ? classes.confirmButton : '', className)} size={'small'}>
    {open ? <Check fontSize={'small'} className={classes.confirmIcon}/> : <CreateIcon fontSize={'small'}/>}
  </IconButton>
};

export default EditToggleButton;