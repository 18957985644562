import moment from "moment/moment";
import {DateFormats} from "../../constants/securspace-constants";
import request from "../../../util/SuperagentUtils";
import type {PayoutsRequestParams} from "../../../types/payouts";

const getPayoutsUrlParams = (requestParams: PayoutsRequestParams, isPageable: boolean) => {

    const params = isPageable ? new URLSearchParams([["page", requestParams?.page || 0], ["size", requestParams?.pageSize || 10]]) : new URLSearchParams();

    if (requestParams?.startDate) {
        params.append("startDate", moment(requestParams?.startDate).format(DateFormats.DAY));
    }
    if (requestParams?.endDate) {
        params.append("endDate", moment(requestParams?.endDate).format(DateFormats.DAY));
    }
    if (requestParams?.locationId) {
        params.append("locationId", requestParams?.locationId)
    }
    if (requestParams?.locationName && !isPageable) {
        params.append("locationName", requestParams?.locationName)
    }
    if (requestParams?.status) {
        params.append("status", requestParams?.status)
    }
    if (requestParams?.payoutCompleted) {
        params.append("payoutCompleted", (requestParams?.payoutCompleted == 'true') ? 'true' : 'false')
    }
    if (requestParams?.sortBy) {
        params.append("sortBy", requestParams?.sortBy)
    }
    if (requestParams?.sortDir) {
        params.append("sortDir", requestParams?.sortDir)
    }

    return params;
}

export const requestPayoutsPaginated = (searchParams: PayoutsRequestParams,
                                                onSuccess?: () => void,
    onFail?: (err: Object) => void) : Promise | void => {

    const result = request.get(`/api/payouts/supplier/${searchParams.supplierAccountId}?${getPayoutsUrlParams(searchParams, true)}`);

    if(onSuccess && onFail) {
        result
            .then(onSuccess)
            .catch(onFail)
    } else {
        return result
    }
}

export const getPayoutsReportUrl = (searchParams: PayoutsRequestParams) => {
    return `/api/payouts/supplier/${searchParams.supplierAccountId}/report?${getPayoutsUrlParams(searchParams, false)}`;
}

export const getPayoutsGroupSummaryUrl = (searchParams: {}) => {
    let summaryParams = {
        startDate: searchParams.startDate,
        endDate: searchParams.endDate,
        locationId: searchParams.locationId,
        locationName: searchParams.locationName
    }

    return `/api/payouts/supplier/${searchParams.supplierAccountId}/group-summary-report?${getPayoutsUrlParams(summaryParams, false)}`;
}

export const requestCalculatePayoutsSummary = async (searchParams: PayoutsRequestParams) => {
    return await request.get(`/api/payouts/supplier/${searchParams.supplierAccountId}/summary?${getPayoutsUrlParams(searchParams, false)}`);
}
