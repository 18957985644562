import request from "../util/SuperagentUtils";

const BASE_URL = 'api/types';

const getAdminInvoiceGroupSummaryOptions: () => Promise = () => {
  return request.get(`${BASE_URL}/invoicesGroupSummaryOptions`);
};

const getAssetTypes: () => Promise = () => {
  return request.get(`${BASE_URL}/assets`);
}

const getTaxStatusTypes: () => Promise = () => {
  return request.get(`${BASE_URL}/taxStatus`);
}

const getAssetTypeGroupOptionsTypes: () => Promise = () => {
  return request.get(`${BASE_URL}/assetTypeGroupOptions`);
}

export {
  getAdminInvoiceGroupSummaryOptions,
  getAssetTypes,
  getTaxStatusTypes,
  getAssetTypeGroupOptionsTypes
}