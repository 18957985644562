import React from "react";
import { Grid, ButtonGroup, Button, Typography } from "@mui/material";
import classNames from "classnames";

const ChargeModelButton = (props) => {
    const {classes, useCalendarDays, setUseCalendarDays} = props;

    return (
      <Grid item component={'section'}
            className={classNames(classes.rowGap10, classes.marginTop20, classes.marginBottom20)}>
        <Typography variant={'subtitle1'} color={'textSecondary'} className={classes.labelText}>Charge
          Model</Typography>
        <ButtonGroup>
          <Button
            className={classNames(classes.button, `d-flex ss-tab-btn`)}
            onClick={() => setUseCalendarDays(false)}
            variant={!useCalendarDays ? "contained" : "outlined"}
            color={!useCalendarDays ? "primary" : "secondary"}
          >
            24 Hour
          </Button>
          <Button
            className={classNames(classes.button, `d-flex ss-tab-btn`)}
            onClick={() => setUseCalendarDays(true)}
            variant={useCalendarDays ? "contained" : "outlined"}
            color={useCalendarDays ? "primary" : "secondary"}
          >
            Calendar Days
          </Button>
        </ButtonGroup>
      </Grid>
    )
}

export default ChargeModelButton;