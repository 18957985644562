import React, {useMemo} from 'react'
import PropTypes from "prop-types";
import moment from "moment";
import FromToDatePicker from "./location/profile/FromToDatePicker";
import {Collapse, Grid} from "@mui/material";
import {Alert} from "@mui/material";

export const CollapsibleMonthlyAutoRenewalAlert = (props: { showEndDate: any }) => {
  const {showEndDate} = props;
  return <Collapse in={!showEndDate} className={"w-100"}>
    <Alert severity={"info"}>
      This booking will renew automatically every month until cancelled.
    </Alert>
  </Collapse>;
};

const BookingFromToDatePicker = ({
                                   fromDate,
                                   onFromDateChange,
                                   toDate,
                                   onToDateChange,
                                   disablePast = false,
                                   showEndDate = true,
                                   prePendedIcon,
                                   postPendedIcon,
                                   disabled = false,
                                   disableBookingChanges,
                                   hideAutoRenewalNotice = false
                                 }) => {

  const minToDate = useMemo(() => {
    return moment(fromDate, "MM/DD/YYYY").add(1, 'days');
  }, [fromDate]);

  const handleFromDateChange = (date) => {
    if (moment(date).isSameOrAfter(toDate, 'day')) {
      onToDateChange(moment(date).add(1, 'days'));
    }
    onFromDateChange(moment(date, "MM/DD/YYYY"));
  };

  const handleToDateChange = (date) => {
    if (moment(date).isSameOrBefore(fromDate, 'day')) {
      onToDateChange(moment(minToDate, "MM/DD/YYYY"));
    } else {
      onToDateChange(moment(date, "MM/DD/YYYY"));
    }
  };

  return (
    <Grid item container alignContent={'center'}>
      {prePendedIcon ? <Grid item className={'ss-byb-details-icon'}>{prePendedIcon}</Grid> : ''}
      <FromToDatePicker
        fromDate={fromDate}
        setToDate={handleToDateChange}
        setFromDate={handleFromDateChange}
        toDate={toDate}
        disabled={disabled}
        disablePast={disablePast}
        showEndDate={showEndDate}
        minToDate={minToDate}
      />
      {postPendedIcon && !disableBookingChanges ?
        <Grid item className={'ss-byb-details-icon'}>{postPendedIcon}</Grid> : ''}
      {!hideAutoRenewalNotice && <Grid item xs={12}>
        <CollapsibleMonthlyAutoRenewalAlert showEndDate={showEndDate}/>
      </Grid>
      }
    </Grid>
  );
};

BookingFromToDatePicker.propTypes = {
  fromDate: PropTypes.object.isRequired,
  toDate: PropTypes.object.isRequired,
  onFromDateChange: PropTypes.func.isRequired,
  onToDateChange: PropTypes.func.isRequired,
  disablePast: PropTypes.bool,
  showEndDate: PropTypes.bool,
  disabled: PropTypes.bool,
  prePendedIcon: PropTypes.node,
  postPendedIcon: PropTypes.node,
  hideAutoRenewalNotice: PropTypes.bool,
};

export default BookingFromToDatePicker;
