import React, {useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions, DialogContent, DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import {Theme} from "@mui/material";
import PropTypes from "prop-types";
import Busy from "../Busy";
import {requestDeactivateAccountPaymentMethod} from "./request/payment-method-requests";

const useStyles: (theme: Theme) => {
  marginTop10: CSSStyleSheet,
  backgroundGrey100: CSSStyleSheet,
} = makeStyles((theme: Theme) => ({
  marginTop10: {
    marginTop: '0.71rem',
  },
  backgroundGrey100: {
    backgroundColor: theme.palette.grey[100],
  },
}));

const RemovePaymentMethodDialog = ({
                                     open,
                                     onClose,
                                     onSuccess,
                                     handleLogout,
                                     account,
                                     paymentMethod,
                                   }) => {
  const [errorMsg, setErrorMsg] = useState('');
  const classes = useStyles();

  const resetDialog = () => {
    setErrorMsg('');
    // Add other reset logic here
  };

  const handleClose = () => {
    resetDialog();
    onClose();
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    const handleSuccess = (response) => {
      Busy.set(false);
      resetDialog();
      onSuccess(response);
    };
    const handleError = (error) => {
      Busy.set(false);
      if (error.response?.status === 401) {
        handleLogout();
      }
      try {
        if (error.response?.body?.message && error.response?.body?.errors) {
          const errorMessage = error.response.body;
          const orderNumbers = () => {
            let orderNumbers = '';
            for (const error in errorMessage?.errors) {
              orderNumbers += ' ' + error + ' ';
            }
            return orderNumbers;
          }
          const popupMessage = `${errorMessage.message} ${orderNumbers()}`;
          setErrorMsg(popupMessage ? popupMessage : 'An error occurred while removing the payment method.');
        } else {
          setErrorMsg('An error occurred while removing the payment method.');
        }
      } catch (e) {
        setErrorMsg('An error occurred while removing the payment method.');
      }
    };

    Busy.set(true);
    requestDeactivateAccountPaymentMethod(account.id, paymentMethod.id, handleSuccess, handleError);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby={'ss-remove-payment-method'}>
      <DialogTitle id={'ss-remove-payment-method'}>
        Remove Payment Method
      </DialogTitle>
      <DialogContent>
        <DialogContentText component={'strong'}>
          Are you sure you would like to remove this payout account? Please make sure to replace or update your bank
          account on file in order to process any payout from Securspace going forward.
        </DialogContentText>
        {
          errorMsg &&
          <Typography variant="body1" component="div" color="error" align="center" className={classes.marginTop10}>
            {errorMsg}
          </Typography>
        }
      </DialogContent>
      <DialogActions className={classes.backgroundGrey100}>
        <Button color={"primary"} onClick={handleClose}>Cancel</Button>
        <Button color={"primary"} onClick={handleSubmit}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

RemovePaymentMethodDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  paymentMethod: PropTypes.object,
};

export default RemovePaymentMethodDialog;