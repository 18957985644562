import React from 'react';
import type {GroupedLocationInventoryActivity} from "../../../types/GroupedLocationInventoryActivity";
import {Grid, Typography} from "@mui/material";
import {ClassNameMap} from "@mui/styles";
import {FC} from "react";
import { AssetTypeLabel} from "../../constants/securspace-constants";

type Props = {
  inventory: GroupedLocationInventoryActivity,
  className?: ClassNameMap
};

const InventoryBreakdown = ({inventory, className}: Props) => {

  const Row: FC<{label: string, value: string}> = ({label, value}) => {
    return value && <Grid item container justifyContent={'space-between'}>
      <Typography variant={'h6'} color={'textSecondary'}>{label}</Typography>
      <Typography variant={'h6'} color={'textPrimary'}>{value}</Typography>
    </Grid>
  };

  return (
    <Grid item container className={className} component={'section'} direction={'column'} gap={1}>
      <Typography variant={'h6'} component={'h5'} color={'textPrimary'}>Inventory Activity Details</Typography>
      <Row label={"Container Number"} value={inventory.containerNumber} />
      <Row label={"Trailer Number"} value={inventory.trailerNumber} />
      <Row label={"Chassis Number"} value={inventory.chassisNumber} />
      <Row label={"Chassis License"} value={inventory.chassisLicensePlateNumber} />
      <Row label={"Seal Number"} value={inventory.sealNumber} />
      <Row label={"Equipment Type"} value={AssetTypeLabel[inventory.assetType] || ""} />
      <Row label={"Asset Size"} value={inventory.assetSize} />
      <Row label={"Truck License Plate Number"} value={inventory.truckLicensePlateNumber} />
      <Row label={"Check In Date"} value={inventory.checkinDate} />
    </Grid>
  );
};

export default InventoryBreakdown;