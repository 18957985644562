import React from 'react';

const SubscriptionButton = (props) => {
  const {onSubmit} = props;

  return <div className="ss-button-container border-top text-center">
    <button type="button" className="ss-button-primary" onClick={onSubmit}>Save Changes</button>
  </div>

}

export default SubscriptionButton;
