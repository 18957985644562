// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import type {Account} from "../../types/Account";
import {Grid, Theme, Typography} from "@mui/material";
import moment from "moment";
import {DateFormats} from "../../components/constants/securspace-constants";
import {makeStyles} from "@mui/styles";
import {withSnackbar} from "../../components/hocs/withSnackbar";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../util/NetworkErrorUtil";
import {InfoCardLoader, GroupSummaries, GroupSummariesLoader} from "@securspace/securspace-ui-kit";
import { verbiage } from "../../components/payouts/payouts-report-verbiage";
import { groupSummaryFields } from "../../components/payouts/data";
import {requestPayoutsPaginated, requestCalculatePayoutsSummary} from "../../components/payouts/request/payouts-requests";
import {formatCurrencyValue} from "../../util/PaymentUtils";
import SecurSpaceContainer from "../../components/common/SecurSpaceContainer";
import PayoutsFilter from "../../components/payouts/PayoutsFilter";
import PayoutsTable from "../../components/payouts/PayoutsTable";
import PayoutsExportCSVs from "../../components/payouts/PayoutsExportCSVs";
import NoRecordsFound from '../../components/report/NoRecordsFound';
// eslint-disable-next-line
import type {PayoutsView, PayoutsRequestParams, PayoutsFilterParams} from "../../types/payouts";

const useStyles: (theme: Theme) => { mainContainer: CSSStyleSheet } = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: '2.5rem',
    paddingBottom: '2.86rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '8.04rem',
      paddingRight: '8.04rem',
    }
  },
}));

const initialFilterParams: PayoutsFilterParams = {
  locationId: '',
  status: '',
  payoutCompleted: '',
  startDate: moment().add(-1, 'months').format(DateFormats.DAY).toString(),
  endDate: moment().format(DateFormats.DAY).toString(),
  sortBy: 'createdOn',
  sortDir: 'asc',
};

const SupplierPayoutsReport = (props: {
  account: Account,
  handleLogout: () => void,
  snackbarShowMessage: () => void
}) => {
  const {account, handleLogout, snackbarShowMessage} = props;
  initialFilterParams.supplierAccountId = account.id;


  const [loading, setLoading] = useState(false)
  const [summaryLoading, setSummaryLoading] = useState(false)
  const [query: PayoutsRequestParams, setQuery] = useState({
    supplierAccountId: account.id,
    locationName: '',
    page: 0,
    pageSize: 10,
    ...initialFilterParams
  });
  const [payoutsItems: PayoutsView[], setPayoutsItems] = useState([]);
  const [totalItems: number, setTotalItems] = useState(0);

  const classes = useStyles();
  const needsNewSummary = useRef(true);


  useEffect(() => {
    setLoading(true);
    requestPayoutsPaginated(query)
    .then((response) => {
      if (response.body) {
        const {content, totalPages, count, page} = response.body;
        if (page + 1 > totalPages) {
          // If the page index is greater than the total pages
          // This will refresh the data and show the last page of results
          handlePageChange(null, totalPages > 0 ? totalPages - 1 : 0);
        }
        setTotalItems(count);
        setPayoutsItems(content.map((item) => {
          item.payoutAmount = formatCurrencyValue(item.payoutAmount);
          return item;
        }));
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    })
    .catch((error) => {
      snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error');
      if (error.response.status === 401) {
        handleLogout();
      }
    })
    .finally(() => setLoading(false));

    if (needsNewSummary.current) {
      needsNewSummary.current = false;
      setSummaryLoading(true);
      requestCalculatePayoutsSummary(query).then(({body}) => {
        if (body) {
          groupSummaryFields[0].value = formatCurrencyValue(body.totalPayouts, true);
          groupSummaryFields[1].value = body.payoutsCount;
          groupSummaryFields[2].value = body.transactionCount;
        }
      }).catch(err => snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(err), "error", 15000))
      .finally(() => setSummaryLoading(false));
    }
  }, [query, snackbarShowMessage, handleLogout]);

  const formatDate = (date) => {
    return date ? moment(date).format(DateFormats.DAY).toString() : '';
  }

  const handleFilterChange = (event) => {
    event = {
      ...event,
      startDate: event.startDate ? formatDate(event.startDate) : '',
      endDate: event.endDate ? formatDate(event.endDate) : '',
    };

    setQuery((currentQuery) => {
      const status = getSelectFilterValue(event, 'status', currentQuery.payoutCompleted);
      const payoutCompleted = getSelectFilterValue(event, 'payoutCompleted', currentQuery.payoutCompleted);

      needsNewSummary.current = currentQuery.locationId !== event.location.id ||
                                currentQuery.status !== status ||
                                currentQuery.payoutCompleted !== payoutCompleted ||
                                currentQuery.startDate !== event.startDate ||
                                currentQuery.endDate !== event.endDate;


    return {
      ...currentQuery,
      locationId: event.location.id,
      locationName: event.location.locationName,
      status,
      payoutCompleted,
      startDate: event.startDate,
      endDate: event.endDate,
      sortBy: event.sortBy,
      sortDir: event.sortDir,
      }
    })

  };

  const getSelectFilterValue = (event, selectFilterName, defaultValue) => {
    const selectFilter = event.selectFilters.find((selectFilters) => selectFilters.name === selectFilterName);
    return selectFilter ? selectFilter.value : defaultValue;
  }

  const handlePageChange = (event: React.ChangeEvent, page: number) => {
    if (page !== query.page) {
      setQuery({
        ...query,
        page,
      });
    }
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    if (pageSize !== query.pageSize) {
      setQuery({
        ...query,
        pageSize,
      });
    }
  };

  const handleReviewItem = (item: PayoutsView) => {
    item & (window.location.href = `/finances/invoices-in-payout/${item.payoutId}`, '_blank');
  };

  return (
    <SecurSpaceContainer className={classes.mainContainer}>
      <Grid sx={{ width: '100%', }} container spacing={1} flexDirection={"column"}>
        <Grid item marginBottom={"2em"}>
          <Typography variant={'h5'} component={'h1'}>{verbiage.title}</Typography>
        </Grid>
        <PayoutsFilter
          defaultFilterParams={initialFilterParams}
          onChange={(event) => handleFilterChange(event)}
        />

        {
          !loading && payoutsItems?.length > 0 && <Grid item container justifyContent={"space-between"} mb={6} gap={2}>
            <Grid item lg={6}>
              <Typography
                variant='body2'
                sx={{ textTransform: 'uppercase', color: '#000'}}
                component='p'
              >
                {query?.locationName} (SHOWING {payoutsItems?.length} RECORDS IN GROUP)
              </Typography>
            </Grid>
            <PayoutsExportCSVs
              item
              query={query}
            />
          </Grid>
        }

        <Grid item xs={12}>
          {
            summaryLoading ?
              <GroupSummariesLoader />
            : payoutsItems?.length ?
              <GroupSummaries groupSummaryFields={groupSummaryFields} />
            :
              ''
          }
        </Grid>
        {
          loading ?
            [...Array(10).keys()].map((_, index) => (
              <InfoCardLoader key={index} />
            ))
          : payoutsItems?.length ?
            <PayoutsTable
              items={payoutsItems}
              count={totalItems}
              rowsPerPage={query?.pageSize}
              page={query?.page}
              handleReviewItem={handleReviewItem}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
            />
          :
            <NoRecordsFound />
        }

      </Grid>
    </SecurSpaceContainer>
  );
};

export default withSnackbar(SupplierPayoutsReport);
