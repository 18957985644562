import Money, {} from 'js-money';

const CURRENCY_US_DOLLAR = 'usd';

let PaymentUtils = {

    CURRENCY_US_DOLLAR,

    convertSmallestSubUnitToMainUnit(amountInCurrencySmallestSubUnit, currency) {
        if (!amountInCurrencySmallestSubUnit) {
            return amountInCurrencySmallestSubUnit;
        }
        if (CURRENCY_US_DOLLAR === currency) {
            return amountInCurrencySmallestSubUnit / 100;
        } else {
            throw new Error("Currency is not supported:  " + currency);
        }
    }
};

function isInteger(x) {
    return x && (Number.isInteger(x) || (x.indexOf('.') < 0 && x % 1 === 0));
}

function formatCurrencyValue(value, showZero) {
    if (value) {
        value = parseCurrencyValue(value);
        if (isInteger(value)) {
            let valueMoney = new Money(parseInt(value), Money.USD, Math.round);
            let moneyString = valueMoney.toString();
            let moneyParts = moneyString.split(".");
            value = Money.USD.symbol + moneyParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + moneyParts[1];
        }
    }
    if (value === 0 || value === "$0" || value === "$0.0" || value === "$0.00") {
        value = showZero ? "$0" : "";
    }
    // https://envase.monday.com/boards/3334982114/views/81030767/pulses/3646888010
    // commented out due to having -$100 which is going to have a single quote added
    // like: '-$100 when sanitized for CSV injection
    // if (value && value.indexOf('-') > 0) {
    //     value = "-" + value.replace("-", "");
    // }
    return value;
}

function validateCurrencyValue(value) {
    if (!value) {
        return true;
    }

    value = removeFormatting(value);

    return Number.isInteger(value);
}

function removeFormatting(value) {
    if (!isInteger(value)) {
        value = removeFormatCharacters(value);
    }
    if (isInteger(value)) {
        //Removes any leading zeros and ensures value is an Integer
        value = parseInt(value);
    }
    return value;
}

function parseCurrencyValue(value) {
    if (value !== null && value !== undefined) {
        if (typeof value === 'string') {
            value = removeFormatCharacters(value);
            if (value.trim() === '') {
                return value; // Return empty spaces as they are
            }
        }
        if (!isNaN(value)) {
            value = parseInt(value);
        }
    }
    return value || 0; // Return 0 if value is falsy (e.g., null, undefined)
}

function removeFormatCharacters(value) {
    return value.replace(/[$,.]/g, '');
}

function getPricePerDayFromWeeklyRate(weeklyRate) {
    weeklyRate = parseCurrencyValue(weeklyRate);
    let weeklyRateMoney = new Money(weeklyRate, Money.USD, Math.round);
    return weeklyRateMoney.divide(7, Math.round);
}

function getPricePerDayFromMonthlyRate(monthlyRate) {
    monthlyRate = parseCurrencyValue(monthlyRate);
    let monthlyRateMoney = new Money(monthlyRate, Money.USD, Math.round);
    let averageNumberOfDaysInAMonth = 30.42;
    return monthlyRateMoney.divide(averageNumberOfDaysInAMonth, Math.round);
}

function formatMoneyAddDecimalPoint(amount) {
    // Convert the amount to a floating-point number
    return (amount / 100).toFixed(2);
}

function determinePaymentImage(paymentMethod) {
    if (paymentMethod?.type === 'ACH' || paymentMethod?.type === 'BANK') {
        return "https://static.secur.space/icons/Bank.png";
    } else {
        switch (paymentMethod?.cardBrand) {
            case 'Visa':
                return "https://static.secur.space/icons/Visa.png";
            case 'MasterCard':
                return "https://static.secur.space/icons/MasterCard.png";
            case 'American Express':
                return "https://static.secur.space/icons/AmericanExpress.png";
            case 'Discover':
                return "https://static.secur.space/icons/Discover.png";
            case 'Diners Club':
                return "https://static.secur.space/icons/DinersClub.png";
            case 'UnionPay':
                return "https://static.secur.space/icons/UnionPay.png";
            default:
                return "https://static.secur.space/icons/Visa.png";
        }
    }
};

const parseDwollaAPIError: string[] = (errorResponse, statusCode) => {
   try {
       const responseStatus = statusCode || errorResponse.status || 400;
       if (Number.isInteger(responseStatus) && 500 > responseStatus && responseStatus >= 400) {
           if (errorResponse.code === "ValidationError") {
               if (errorResponse._embedded && errorResponse._embedded.errors.length > 1) {
                   return errorResponse._embedded.errors.map((error) => error.message);
               }
               return [errorResponse._embedded.errors[0].message];
           }
           return [errorResponse.message];
       }
       return ["Internal Server Error"];
   } catch (e) {
       return ["Internal Server Error"];
   }
};

export {
    PaymentUtils as default,
    getPricePerDayFromWeeklyRate,
    getPricePerDayFromMonthlyRate,
    formatCurrencyValue,
    parseCurrencyValue,
    validateCurrencyValue,
    formatMoneyAddDecimalPoint,
    determinePaymentImage,
    parseDwollaAPIError,
    isInteger
};
