import React, { useContext } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from '../../context/app-context';
import { SocialLoginContext } from '../../context/social-login-context';
import { Navigate } from "react-router";
import { getLandingRedirectPathForUser } from '../../routing/route-utils';
import SignUp from './SignUp';
import { REDIRECT_REFERRER } from '../../components/constants/local-storage-constants';
import { AccountType, LOGIN_PATH } from '../../components/constants/securspace-constants';

const ContextualSignUp = (props) => {
    const appContext = useContext(AppContext)
    const {type = AccountType.BUYER} = props;
    const { user, loadingUserDetails, updateUser } = appContext

    const socialLoginContext = useContext(SocialLoginContext)
    const { socialLogin, setSocialLoginUser, clearSocialLoginUser } = socialLoginContext

    const location = useLocation()
    const history = useNavigate()

    const redirectPath = getLandingRedirectPathForUser(user)

    const updateUserAndRedirect = (user: Object) => {
        updateUser(user, history, localStorage.getItem(REDIRECT_REFERRER) ? localStorage.getItem(REDIRECT_REFERRER) : null);
    }

    let content

    if (loadingUserDetails) {
        content = <div /> //todo za -- loading component
    } else if(user && user.id) {
        content = <Navigate to={redirectPath} />
    } else {
        content = <SignUp
            type={type}
            handleNavigateToSignup={() => history('/signup')}
            handleNavigateToLogin={() => history(LOGIN_PATH)}
            handleAccountChange={updateUserAndRedirect}
            socialLoginTemporaryUser={socialLogin}
            setSocialLoginUser={setSocialLoginUser}
            clearSocialLoginUser={clearSocialLoginUser}
            location={location}
        />
    }

    return content;
};

export default ContextualSignUp;
