import React from 'react';
import {Grid, Theme} from "@mui/material";
import EditToggleButton from "./EditToggleButton";
import BookingDetails from "./BookingDetails";
import classNames from "classnames";
import { makeStyles } from '@mui/styles';

const useStyles: (theme: Theme) => {
  container: CSSStyleSheet
} = makeStyles(() => ({
  container: {
    paddingBottom: '0.71em',
    gap: '1.43rem',
  },
}));

const BookingDetailsFieldToggle = (props: {
  title: any,
  open: boolean,
  value: any,
  onChange: () => void,
  className?: string,
  toggleButtonClassName?: string,
  disableToggle?: boolean,
  children?: any,
}) => {
  const {title, open, onChange, value, className, toggleButtonClassName, disableToggle, children} = props;
  const classes = useStyles();

  return (
    <Grid item container justifyContent={'space-between'} alignItems={'flex-end'} wrap={'nowrap'}
          className={classNames(classes.container, className)}>
      {
        open ? <Grid item xs>{children}</Grid> : <Grid item xs>
          <BookingDetails title={title} value={value}/>
        </Grid>
      }
      {!disableToggle && <EditToggleButton open={open} onClick={onChange} className={toggleButtonClassName}/>}
    </Grid>
  );
};

export default BookingDetailsFieldToggle;