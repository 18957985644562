import React, { useRef, useCallback, useState } from 'react';
import RecentlyBookedLocationItem from './RecentlyBookedLocationItem';
import '../../../css/components/recentlyBookedLocations.css'
import { SwiperSlide } from "swiper/react";
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Container } from "@mui/material";
import RecentlyBookedCarouselSkeleton from '../../skeleton/RecentlyBookedCarouselSkeleton';
import SwiperCarousel from '../../location/profile/features/SwiperCarousel';

const RecentlyBookedLocations = (props) => {

    const { currentListings, loading } = props;
    const [disabled, setDisabled] = useState(false)
    const [disabledLeft, setDisabledRight] = useState(0)
    const sliderRef = useRef()

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);


    return (
        <>
            <Container maxWidth="lg" className='ss-swiper-container' >
                <ArrowBack
                    className={`ss-recently-booked-arrow-left  ${disabledLeft === 0 && `ss-carousel-arrow-disabled`}`}
                    onClick={handlePrev}
                    fontSize={"large"}
                />
                <Container maxWidth="lg" className='ss-swiper-container-padding' >
                   
                        <SwiperCarousel
                            isNextDisabled={false}
                            disabled={disabled}
                            setDisabled={setDisabled}
                            setDisabledRight={setDisabledRight} 
                            sliderRef={sliderRef}
                        >

                            {loading ? [...Array(8).keys()].map((_, inx) => {
                                return (<SwiperSlide className='ss-location-quick-view-item-container' key={inx}>
                                    <RecentlyBookedCarouselSkeleton />
                                </SwiperSlide>)
                            }) : currentListings.map((location) => {
                                return (
                                    <SwiperSlide className='ss-location-quick-view-item-container' key={location.id}>
                                        <RecentlyBookedLocationItem location={location} key={location.id} />
                                    </SwiperSlide>
                                )
                            })}
                        </SwiperCarousel>


                </Container>

                <ArrowForward
                    className={`ss-recently-booked-arrow-right  ${disabled ? 'ss-carousel-arrow-disabled' : ``} `}
                    onClick={handleNext}
                    fontSize={"large"}
                />
            </Container>
        </>
    )
};

export default RecentlyBookedLocations;
