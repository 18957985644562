import request from "../../../util/SuperagentUtils";

export const requestAddPaymentMethod = (data) => {
  return request
    .post("/api/payment-method")
    .set("Content-Type", "application/json")
    .send(data);
}

export const requestToken = (account) => {
  return request
    .get(`/api/payment-method/token?accountId=${account.id}`)
}
