import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { AccountTypeLabel, AccountTypes } from "../../components/constants/securspace-constants";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  appInput: {
    marginBottom: '1rem',
    marginTop: '1rem',
    width: '100%',
    '& input': {
      width: '100%',

    },
    '& .MuiInputBase-formControl': {
      background: 'transparent',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      fontSize: '.8em !important',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '2px solid #6639BF'
    },
    '& label': {
      fontSize: '.8em',
      fontWeight: 400,
      fontFamily: 'Inter',
      lineHeight: '0.8em',
      letterSpacing: '.05em',
      color: '#6639BF',
      marginLeft: '-0.8rem',
    },
    '& label+.css-pah74u-MuiInputBase-root-MuiInput-root-MuiSelect-root':{
      marginTop: '-0.2rem',
    }
  },
}));

const AccountTypeSelect = (props) => {
  const { accountType, handleAccountTypeChange } = props;
  const classes = useStyles();

  return <>
    <FormControl className={classes.appInput}>
      <InputLabel title={"Account Type"}>Account Type</InputLabel>
      <Select
        value={accountType ? accountType : AccountTypes.COMPANY_CHECKING}
        label="Account Type"
        onChange={handleAccountTypeChange}
        displayEmpty
        fullWidth
        variant='standard'
      >
        <MenuItem value={AccountTypes.INDIVIDUAL_CHECKING}>{AccountTypeLabel.INDIVIDUAL_CHECKING}</MenuItem>
        <MenuItem value={AccountTypes.COMPANY_CHECKING}>{AccountTypeLabel.COMPANY_CHECKING}</MenuItem>
        <MenuItem value={AccountTypes.INDIVIDUAL_SAVINGS}>{AccountTypeLabel.INDIVIDUAL_SAVINGS}</MenuItem>
        <MenuItem value={AccountTypes.COMPANY_SAVINGS}>{AccountTypeLabel.COMPANY_SAVINGS}</MenuItem>
      </Select>
    </FormControl>
  </>
}

export default AccountTypeSelect;
