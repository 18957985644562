import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, FormGroup, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  rowFont: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.14eem',
    lineHeight: '160%',
    letterSpacing: '0.01em'
  },
  rowContent: {
    justifyContent: 'space-between',
    margin: 0,
  },
}));

const TogglePanel = (props) => {
  const { title, toggleComponent, ...remainingProps } = props;

  const classes = useStyles();

  return (
    <Paper {...remainingProps}>
      <FormGroup>
        <FormControlLabel
          labelPlacement='start'
          label={
            <Typography variant='h6' color="textPrimary" className={classes.rowFont}>{title}</Typography>
          }
          control={toggleComponent}
          className={classes.rowContent}
        />
      </FormGroup>
    </Paper>
  );
};

TogglePanel.propTypes = {
  title: PropTypes.string,
  toggleComponent: PropTypes.node,
};

export default TogglePanel;