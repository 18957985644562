import React from 'react'
import Image from '../../skeleton/ImageComponent';
import { Swiper, SwiperSlide } from 'swiper/react'

const MainCarousel = ({
  itemData,
  ...props
}) => {
  return (
    <Swiper
      style={{
        '--swiper-navigation-color': '#000',
        '--swiper-pagination-color': '#000',
      }}
      {...props}
    >
      {itemData && itemData.map((img, index) => (
        <SwiperSlide key={index}>
          <Image
            errorImg='/app-images/not-found-image/place_holder_image.png'
            placeHolderimg="/app-images/loading/image-loader.gif"
            src={img}
            alt="gallery-item"
            height="100%"
            width="100%"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default MainCarousel