import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

const UserDialog = (props) => {
  const {showAlert, setShowAlert, dialogTitle, dialogText, submitToApi} = props;

  return <>
    <Dialog
      open={showAlert}
      onClose={() => setShowAlert(false)}
    >
      <DialogTitle>
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {dialogText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowAlert(false)}>Cancel</Button>
        <Button onClick={submitToApi} autoFocus>Proceed</Button>
      </DialogActions>
    </Dialog>
  </>
}

export default UserDialog;
