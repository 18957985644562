import React, {memo, useEffect, useState} from 'react';
import {TextField} from "@mui/material";
import useDebounce from "../hooks/useDebounce";
import PropTypes from "prop-types";

const DelayedTextField = (props) => {
  const {value = "", delay = 500, onChange, ...other} = props;
  const [localValue, setLocalValue] = useState(value);
  const debouncedValue = useDebounce(localValue, delay);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      onChange(debouncedValue);
    }
    return () => {
      ignore = true;
    }
  }, [debouncedValue, localValue, onChange]);

  return (
    <TextField
      {...other}
      value={localValue}
      onChange={(event) => setLocalValue(event.target.value)}
    />
  )
};

export default memo(DelayedTextField);

DelayedTextField.propTypes = {
  value: PropTypes.string,
  delay: PropTypes.number,
  onChange: PropTypes.func.isRequired
}