import React from 'react'
import { NoDataMessage, InfoCard } from "@securspace/securspace-ui-kit";
import { Box } from "@mui/material";
import {
    Print,
    Image
} from '@mui/icons-material';
import { styles } from './styles';
import { headerCells } from './data';



const BuyerInterChangesTable = ({
    interchanges,
    loading,
    setImages,
    handleOpen,
    locationId,
    supplierName,
    equipmentType,
    equipmentNumber,
    expandedItems,
    handleToggle,
    sortBy
}) => {

    const printInterchange = (item) => {
        if (item) {
            window.open(`/api/interchanges/${item.id}`);
        }
    };

    const getUrl = (item, id) => {
        const url = `https://s3-us-west-1.amazonaws.com/securspace-files/inventory/${id}/${item.galleryImageFileName}`
        return url
    };

    const viewImages = (item) => {
        const images = item?.inventoryGallery?.map((image) => getUrl(image, item.id))
        setImages(images)
        handleOpen()
    };

    const actionItems = [
        {
            icon: <Print fontSize='medium' color='primary' />,
            label: "Print",
            onClick: printInterchange
        }
    ];

    const imageActions = interchanges?.map((item, index) => {
        if (item.inventoryGallery?.length > 0) {
            return {
                id: index,
                icon: <Image fontSize='medium' color='primary' />,
                label: "View Image",
                onClick: viewImages
            };
        } else {
            return null;
        }
    }).filter(item => item !== null);


    return (
        <Box mt={4} sx={{
            ...(!interchanges.length && !loading) && styles.container,
            '& thead th': {
                fontWeight: 600
            },

        }}>
            {(!interchanges.length && !loading) ? (
                <Box sx={styles.showMessage}>
                    {sortBy || locationId || supplierName || equipmentType || equipmentNumber ? (
                        <NoDataMessage
                            image="/app-images/person-and-box.svg"
                            message="No matching records found."
                        />
                    ) : (
                        <NoDataMessage
                            image="/app-images/no-data-match.png"
                            message="No input selected, To view results kindly select the data above"
                        />
                    )}
                </Box>
            ) : (
                interchanges?.map((item, index) => {

                    let dynamicHeaderCells = [];
                    let insertIndex = 0;

                    if (item.activity === 'CHECK_IN') {
                        dynamicHeaderCells = dynamicHeaderCells.filter(cell => !['Dwell Time', 'Dwell Time Minutes'].includes(cell.label));

                    } else if (item.activity === 'CHECK_OUT') {
                        dynamicHeaderCells.push(
                            {
                                id: 'dwellTimeDisplay',
                                label: 'Dwell Time'
                            },
                            {
                                id: 'dwellTimeInMinutes',
                                label: 'Dwell Time Minutes'
                            }
                        );
                        insertIndex = 17;
                    }

                    item.headerCells = (item.headerCells || headerCells).filter(cell => !['Dwell Time', 'Dwell Time Minutes'].includes(cell.label));
                    item.headerCells.splice(insertIndex, 0, ...dynamicHeaderCells);

                    return (
                        <InfoCard
                            headCells={item.headerCells}
                            data={item}
                            actionItems={actionItems}
                            conditionalActionItems={imageActions}
                            inx={index}
                            key={item.id}
                            handleToggle={handleToggle}
                            collapse={expandedItems[item.id]}
                        />
                    );
                })
            )}
        </Box>
    )
}

export default BuyerInterChangesTable
