import React, {useEffect, useState} from 'react';
import { Button, FormControlLabel, Grid, TextField, Theme, Typography, Checkbox } from "@mui/material";
import { SelectInput } from '@securspace/securspace-ui-kit'
import { makeStyles } from "@mui/styles";
import { withSnackbar } from "../hocs/withSnackbar";
import { formatCurrencyValue, parseCurrencyValue, validateCurrencyValue } from "../../util/PaymentUtils";
import { verbiage } from "./subscription-verbiage";
import { PartnerSubscriptionTypes } from "../constants/securspace-constants";
import { requestCreateSubscription, requestDeletePartnerSubscription, requestGetSubscription, requestUpdatePartnerSubscription } from "./subscription-requests";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import Busy from "../Busy";

const useStyles: (theme: Theme) => {
  rowGap10: CSSStyleSheet,
} = makeStyles(() => ({
  rowGap10: {
    rowGap: '0.71rem',
  },
}));

const subscriptionOptions = [
  { value: PartnerSubscriptionTypes.NO_SUBSCRIPTION, label: PartnerSubscriptionTypes.NO_SUBSCRIPTION },
  { value: PartnerSubscriptionTypes.AGS_FEE, label: PartnerSubscriptionTypes.AGS_FEE }
];

const paymentSourceOptions = [
  { value: verbiage.payout, label: verbiage.payout }
];

const SubscriptionManager = (props) => {
  const {
    account,
    handleLogout,
    snackbarShowMessage
  } = props;

  const classes = useStyles();
  const defaultSubscription = {
    id: '',
    type: PartnerSubscriptionTypes.NO_SUBSCRIPTION,
    accountId: account?.id,
    amount: 0,
    reason: '',
    paymentSource: paymentSourceOptions[0].value,
    active: false
  };

  const [changesMade, setChangesMade] = useState(false);
  const [subscription, setSubscription] = useState(defaultSubscription);
  const [errorMessages, setErrorMessages] = useState({
    amount: '',
    reason: ''
  });

  useEffect(() => {
    Busy.set(true);
    requestGetSubscription(account.id).then((resp) => {
      if (resp.body) {
        setSubscription(resp.body);
      }
    }).catch(() => {
      // Don't need to alert that there isn't a current subscription
    }).finally(() => {
      Busy.set(false);
    });
  }, [account.id]);

  const handleSubscriptionChange = (updatedValues, errors) => {
    const newSubscription = {
      ...subscription,
      ...updatedValues
    };

    setSubscription(newSubscription);
    setErrorMessages(errors);

    setChangesMade(
      !errors.amount &&
      !errors.reason &&
      (!!newSubscription.id || newSubscription.type !== PartnerSubscriptionTypes.NO_SUBSCRIPTION)
    );
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (subscription.id || subscription.type !== PartnerSubscriptionTypes.NO_SUBSCRIPTION) {
      Busy.set(true);
      if (subscription.id) {
        if (subscription.type === PartnerSubscriptionTypes.NO_SUBSCRIPTION) {
          deleteSubscription();
        } else {
          updateSubscription();
        }
      } else {
        createSubscription();
      }
    } else {
      setChangesMade(false);
    }
  }

  const createSubscription = () => {
    requestCreateSubscription(subscription).then((resp) => {
      setSubscription(resp.body);
      setChangesMade(false);
      snackbarShowMessage(verbiage.saveSuccess, 'success', 8000);
    }).catch(error => {
      handleResponseError(error, getErrorMessageForStandardResponse(error), 10000);
    }).finally(() => {
      Busy.set(false);
    });
  }

  const updateSubscription = () => {
    requestUpdatePartnerSubscription(subscription.id, subscription).then(() => {
      setChangesMade(false);
      snackbarShowMessage(verbiage.updateSuccess, 'success', 8000);
    }).catch(error => {
      handleResponseError(error, getErrorMessageForStandardResponse(error), 10000);
    }).finally(() => {
      Busy.set(false);
    });
  }

  const deleteSubscription = () => {
    requestDeletePartnerSubscription(subscription.id).then(() => {
      setChangesMade(false);
      snackbarShowMessage(verbiage.deleteSuccess, 'success', 8000);
    }).catch(error => {
      handleResponseError(error, getErrorMessageForStandardResponse(error ), 10000);
    }).finally(() => {
      setSubscription(defaultSubscription)
      Busy.set(false);
    });
  }

  const handleResponseError = (error, message, number) => {
    if (error.status === 401) {
        handleLogout();
    } else {
      snackbarShowMessage(message, 'error', number);
    }
  }

  const validateAmount = (value) => {
    const cents = parseCurrencyValue(value);
    return {
      cents,
      error: validateCurrencyValue(value) ? (cents > 0) ?
        '' :
        verbiage.validation_amount_positive :
        verbiage.validation_amount_format
    }
  }

  const validateReason = (value) => {
    return value ? '' : verbiage.validation_reason_blank;
  }

  const handleChange = (name, value) => {
    let errors = errorMessages;
    switch (name) {
      case 'amount':
        const validationResult = validateAmount(value);
        errors.amount = validationResult.error;
        value = validationResult.cents;
        break;
      case 'reason':
        errors.reason = validateReason(value);
        break;
      default:
        break;
    }

    const item = {};
    item[name] = value;
    handleSubscriptionChange(item, errors);
  }

  return <form onSubmit={handleSubmit} noValidate className={'w-100'}>
    <Grid component={'article'} container direction={'column'} className={classes.rowGap10}>

      <Grid item container justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant={'h5'} component={'h1'} color={'textPrimary'}>{verbiage.title}</Typography>
        <Button type={'submit'} variant={'contained'} color={'primary'} disabled={!changesMade}>{verbiage.save}</Button>
      </Grid>

      <SelectInput
        label={verbiage.type}
        value={subscription.type}
        selectData={subscriptionOptions}
        hasAction={false}
        shrinkLabel={true}
        onChange={(e) => handleChange('type', e.target.value)}
      />
      <TextField
        variant='standard'
        value={formatCurrencyValue(subscription.amount)}
        label={verbiage.amount}
        error={!!errorMessages.amount}
        helperText={errorMessages.amount}
        placeholder={verbiage.placeholder_amount}
        onChange={(e) => handleChange('amount', e.target.value)}
        required
        fullWidth
      />
      <TextField
        variant='standard'
        value={subscription.reason}
        label={verbiage.reason}
        error={!!errorMessages.reason}
        helperText={errorMessages.reason}
        placeholder={verbiage.placeholder_reason}
        onChange={(e) => handleChange('reason', e.target.value)}
        required
        fullWidth
      />
      { paymentSourceOptions.length > 1 &&
        <SelectInput
          label={verbiage.source}
          value={subscription.paymentSource}
          selectData={paymentSourceOptions}
          hasAction={false}
          shrinkLabel={true}
          onChange={(e) => handleChange('paymentSource', e.target.value)}
        />
      }
      <FormControlLabel control={
        <Checkbox
          checked={subscription.active}
          onChange={(e) => handleChange('active', e.target.checked)}
        />}
        label={verbiage.active}
      />
    </Grid>
  </form>
}

export default withSnackbar(SubscriptionManager);
