import React, {useEffect, useState} from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import LocationListItemListingImage from '../../location/search/LocationImages/LocationListItemListingImage';
import ImageNotAvailablePlaceholder from '../../location/ImageNotAvailablePlaceholder';

const useStyles = makeStyles((theme) => ({
    locationQuickViewItemContainer: (props) => ({
        maxWidth: props.maxWidth ? props.maxWidth : '25%',
    }),
    secondaryMainColor: {
        color: theme.palette.secondary.main,
    },
    distance:{
        fontSize: '1.14rem',
        fontFamily: 'Inter, sans-serif',
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.87)',
      },
      main:{
        width: '100%'
      }
}));

const LocationItem = (props) => {
    const { listingImageFileName, pricePerDay, pricePerMonth, instantApproval, locationName, cityName, distanceTo } = props;

    const [price, setPrice] = useState(0);
    const [period, setPeriod] = useState('');

    const classes = useStyles(props);

  useEffect(() => {
    const pricePerDayInDollars = pricePerDay/100;
    const pricePerMonthInDollars = pricePerMonth/100;

    setPrice(pricePerDayInDollars === 0 ? pricePerMonthInDollars : pricePerDayInDollars);
    setPeriod(pricePerDayInDollars === 0 ? 'mon' : 'day');
  }, [pricePerDay, pricePerMonth]);

    return (
        <div className={classes.main}>
            {
                listingImageFileName ?
                    <LocationListItemListingImage height={'11.35em'}
                        width={'100%'}
                        objectfit={'cover'}
                        listingImageFileName={listingImageFileName} /> :
                    <ImageNotAvailablePlaceholder height={'11.35em'}
                        width={'100%'}
                        objectfit={'contain'}
                        />
            }

            <div className="ss-location-quick-view-item-info">
                <Grid container wrap='nowrap' justifyContent='space-between' alignItems='center'>
                <Grid item>
                {
                    instantApproval ?
                    <div className="ss-location-quick-view-item-approval-box instant-approval">
                            <Typography className="ss-location-quick-view-item-approval">Instant Approval</Typography>
                        </div>
                        :
                        <div className="ss-location-quick-view-item-approval-box request-space">
                            <Typography className="ss-location-quick-view-item-approval">Request Space</Typography>
                        </div>
                }
                </Grid>
                {distanceTo && <Typography className={classes.distance}>
                  {distanceTo} mi
                </Typography> }
                </Grid>
                <Typography className="ss-location-quick-view-item-price" color="textPrimary">${price}<strong className="ss-location-quick-view-item-price-units">/{period}</strong></Typography>
                <Typography variant="h5" noWrap={true} className={classNames("ss-location-quick-view-item-title", classes.secondaryMainColor)}>{locationName}</Typography>
                <Typography variant="body2" className="ss-location-quick-view-item-city">USA, {cityName}</Typography>
            </div>
            </div>
    )

}

export default LocationItem;
