const initializePendo = (user, pendoFunction) => {
    let pendoMetaData = {
        visitor: {id: 'VISITOR-UNIQUE-ID'}
    }
    if (user) {
        const fullName = `${user.firstName} ${user.lastName}`;
        pendoMetaData = {
            visitor: {
                id: user.loggedInUsername,
                email: user.email,
                full_name: fullName,
                role: user.userType,
            },
            account: {
                id: user.id,
                name: user.companyName,
                accountType: user.type,
                subscriptionType: user.subscriptionType,
                creationDate: user.createdOn,
            }
        }
    }
    pendoFunction(pendoMetaData);
}

export default initializePendo;
