import React, {useState} from "react";
import {Menu, MenuItem, Grid, Button} from "@mui/material";
import {fetchDownloadFile} from "../../../util/FileUtils";
import DownloadCsvIcon from "../../icons/DownloadCsvIcon";

type DownloadGroupSummaryCSVButtonProps = {
  queryParams: { key: string, value: any }[],
  onError: (error: any) => void,
  groupByOptions: { value: string, label: string }[],
  getUrl: (queryParams: { key: string, value: any }[]) => string
};

/**
 * Drop-down menu that allows the user to download a CSV file containing group summaries.
 *
 * @param queryParams List of key value pairs to be used as query parameters to generate the selected option's download URL
 * @param onError Callback function to handle errors that occur during the download process
 * @param groupByOptions List of options to be displayed in the menu. The value of each option will be used as the groupBy parameter in the download URL
 * @param getUrl Function that returns the download URL for the selected groupBy option.
 * @returns {JSX.Element}
 * @constructor
 */
const DownloadGroupSummaryCSVButton: (props: DownloadGroupSummaryCSVButtonProps) => JSX.Element = ({
                                         queryParams,
                                         onError,
                                         groupByOptions,
                                         getUrl
                                       }) => {

  const [menuAnchorEl, setMenuAnchorEl] = useState();

  const menuOpen = Boolean(menuAnchorEl);

  const handleClickGroupSummaryButton = (event) => {
    if (groupByOptions.length > 1) {
      setMenuAnchorEl(event.currentTarget);
    } else {
      handleExportByGroup(groupByOptions[0]);
    }
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleExportByGroup = async (groupByParameter) => {
    if (queryParams && groupByParameter) {
      let groupByQueryParameter = Object.assign({}, queryParams);
      groupByQueryParameter.groupBy = groupByParameter;
      let url = getUrl(groupByQueryParameter);

      downloadFile(url);
    }
  }

  const downloadFile = async (url) => {
    try {
      fetchDownloadFile(url);
    } catch (error) {
      onError && onError(error);
    }
  };

  return (
    <Grid item>
      <Grid item>
        <Button variant={"contained"} startIcon={<DownloadCsvIcon/>} onClick={handleClickGroupSummaryButton}>
          Export Group Summaries
        </Button>
      </Grid>
      <Menu open={menuOpen} anchorEl={menuAnchorEl} onClose={handleCloseMenu}>
        {
          groupByOptions.map((groupByOption) =>
            <MenuItem key={groupByOption.value} onClick={() => handleExportByGroup(groupByOption.value)}>
              {groupByOption.label}
            </MenuItem>
          )
        }
      </Menu>
    </Grid>
  )
}

export default DownloadGroupSummaryCSVButton;