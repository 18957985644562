import React from 'react';
import '../../css/components/report/report-data.css';
import * as PropTypes from 'prop-types';

const ReportDataWithIcon = (props) => {
  const { label, children, cssIconType } = props;

  return (
    <div className={`ss-report-data-${cssIconType}`}>
      <label className="ss-report-data-label">{label}</label>
      <div className={"ss-report-data-children"}>{children || '-'}</div>
    </div>
  )
}

ReportDataWithIcon.propTypes = {
  label: PropTypes.any.isRequired,
  cssIconType: PropTypes.any.isRequired,
}

export default ReportDataWithIcon;
