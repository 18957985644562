import React, {useMemo} from 'react';
import {Grid, Typography} from "@mui/material";
import {formatCurrencyValue} from "../../util/PaymentUtils";

type TotalPayoutProps = {
  payoutTransactions?: {payoutAmount: number}[],
  title?: string,
  gridItemProps?: Object
};

/**
 * Displays the total payout amount for the given payout transactions.
 *
 * @param payoutTransactions
 * @param title
 * @param gridItemProps
 * @returns {Element}
 * @constructor
 */
const TotalPayout: (props: TotalPayoutProps) => JSX.Element = ({payoutTransactions, title = '', gridItemProps}) => {

  const totalPayout = useMemo(() => {
    return formatCurrencyValue(payoutTransactions.reduce((acc, transaction) => acc + transaction.payoutAmount, 0));
  }, [payoutTransactions]);

  return (
    <Grid item {...gridItemProps}>
      <Typography variant={'body1'}> Total Payout Amount{title && ` ${title}`}: {totalPayout}</Typography>
    </Grid>
  )
};

export default TotalPayout;