import 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, FormControl, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import './style.css'
import { AppContext } from '../../../context/app-context';
import { useNavigate, useLocation } from 'react-router-dom';
import LocationItemPriceDisplay from '../LocationItemPriceDisplay';
import BookSpaceButton from './BookSpaceButton';
import moment from 'moment'
import { REDIRECT_REFERRER } from '../../constants/local-storage-constants';
import useUrlQueryParams from '../../../hooks/useUrlQueryParams';
import { DateFormats, UserType } from '../../constants/securspace-constants';
import classNames from 'classnames';
import AdminBrokeredBookingFormSection from './AdminBrokeredBookingFormSection';
import { parseCurrencyValue, validateCurrencyValue } from '../../../util/PaymentUtils';
import {Theme} from "@mui/material";
import adminEquipmentTypes from '../../ServiceTypes';
import EquipmentTypes from '../../EquipmentTypes';
import AdminBrokeredBookingConfirmation from "./AdminBrokeredBookingConfirmation";
import {calculateRate, DAILY, isZeroPartnerPayout, isZeroSecurSpacePayout, MONTHLY} from "../../../util/BookingUtil";
import {DEFAULT_DAYS_AHEAD, DEFAULT_NUM_SPACES} from "../../constants/book-space-constants";
import BookingFromToDatePicker from "../../BookingFromToDatePicker";
import {getEndDateMonthly} from "../../../controls/FrequencyOption";
import BookingNumberOfSpacesTextField from "../../BookingNumberOfSpacesTextField";
import ButtonSwitch from "../../ButtonSwitch";

const useStyles: (theme: Theme) => {
  mainContainer: CSSStyleSheet,
  bookSpaceContainer: CSSStyleSheet,
} = makeStyles(theme => ({
  mainContainer: {
    gap: '1.71rem',
  },
  bookSpaceContainer: {
    width: 'auto',
    height: 'auto',
    gap: '1.2em',
    [theme.breakpoints.up('md')]: {
      width: '27.35em',
    }
  },
}));

const LocationProfileBookSpaceForm = ({
                                        location,
                                        onCapacityRequestChange,
                                        capacityRequest = {
                                          durationType: MONTHLY,
                                          startDate: moment().format(DateFormats.DAY),
                                          endDate: moment().add(DEFAULT_DAYS_AHEAD, 'days').format(DateFormats.DAY),
                                          numberOfSpaces: DEFAULT_NUM_SPACES,
                                        },
                                        instantApproval
                                      }) => {
  const classes = useStyles();
  const [showMonth, setShowMonth] = useState(capacityRequest?.durationType === MONTHLY);
  const [fromDate, setFromDate] = useState(
    capacityRequest?.startDate ? moment(capacityRequest?.startDate, DateFormats.DAY) : moment(moment(), DateFormats.DAY));
  const [toDate, setToDate] = useState(
    capacityRequest?.endDate ? moment(capacityRequest?.endDate, DateFormats.DAY) : moment(moment(), DateFormats.DAY).add(DEFAULT_DAYS_AHEAD, 'days')
  );
  const [type, setType] = useState('');
  const [typeIsValid, setTypeIsValid] = useState(false);
  const [space, setSpace] = useState(capacityRequest.numberOfSpaces || 1);
  const [formIsValid, setFormIsValid] = useState(false);
  const [isBrokeredBooking, setIsBrokeredBooking] = useState(false);
  const [chargeOverages, setChargeOverages] = useState(null);
  const [customerChargedAmountPerPeriod, setCustomerChargedAmountPerPeriod] = useState('');
  const [partnerPayoutAmountPerPeriod, setPartnerPayoutAmountPerPeriod] = useState('');
  const [overageCustomerChargeRate, setOverageCustomerPayoutRate] = useState('');
  const [overagePartnerPayoutRate, setOveragePartnerPayoutRate] = useState('');
  const [ssPayoutConfirmed, setSsPayoutConfirmed] = useState(false);
  const [ssPayoutConfirmationRequired, setSsPayoutConfirmationRequired] = useState(false);
  const [equipmentTypeArray, setEquipmentTypeArray] = useState([]);
  const [pricePerDay, setPricePerDay] = useState(0);
  const [pricePerMonth, setPricePerMonth] = useState(0);
  const [disableDailyBooking, setDisableDailyBooking] = useState(false);
  const [manualPayment, setManualPayment] = useState(false);

  const { user } = useContext(AppContext)
  const history = useNavigate()
  const routerLocation = useLocation();
  const queryParams = useUrlQueryParams();

  const handleTab = (tab) => {
    if (tab) {
      const monthsToAdd = moment(getEndDateMonthly(moment(fromDate).format(DateFormats.DAY), 1));
      setToDate(moment(monthsToAdd, DateFormats.DAY));
    } else {
      setToDate(moment(fromDate, DateFormats.DAY).add(1, 'd'));
    }
    setShowMonth(tab);
  };

  const handleFromDateChange = (value) => {
    setFromDate(value);
  };

  const handleToDateChange = (value) => {
    setToDate(value);
  };

  const handleTypeChange = (event) => {
    const {value} = event.target;
    const typeMatchesLocationEquipmentArray = location?.equipmentTypes.some(equipmentType => value === equipmentType);
    const typeMatchesAdminOptionArray = adminEquipmentTypes.OPTIONS.some(type => value === type);
    setTypeIsValid(typeMatchesLocationEquipmentArray || typeMatchesAdminOptionArray)
    setType(value);
  };

  const handleBookingSpace = () => {
    if (user) {
      const formData = {
        showMonth: showMonth,
        fromDate: moment(fromDate).format(DateFormats.DAY),
        toDate: moment(toDate).format(DateFormats.DAY),
        type: type,
        assetTypes: location?.equipmentTypes,
        space: space,
        brokeredBooking: isBrokeredBooking,
      };
     
      if (isBrokeredBooking) {
        formData.chargeOverages = chargeOverages;
        formData.customerChargedAmountPerPeriod = customerChargedAmountPerPeriod;
        formData.partnerPayoutAmountPerPeriod = partnerPayoutAmountPerPeriod;
        formData.overageCustomerChargeRate = overageCustomerChargeRate;
        formData.overagePartnerPayoutRate = overagePartnerPayoutRate;
        formData.manualPayment = manualPayment;
      }
      history(`/location-profile/${location.locationId}/payment`, {
        state: {
          referrerData: formData,
          searchHistory: routerLocation?.state?.searchURL
        }
      });
    }
    else {
      const refQueryParams = new URLSearchParams();
      refQueryParams.append('showMonth', encodeURIComponent(showMonth));
      refQueryParams.append('fromDate', encodeURIComponent(moment(fromDate).format(DateFormats.DAY)));
      refQueryParams.append('toDate', encodeURIComponent(moment(toDate).format(DateFormats.DAY)));
      refQueryParams.append('type', encodeURIComponent(type));
      refQueryParams.append('space', encodeURIComponent(space));

      const state = {
        referrer: refQueryParams.toString() ? `${routerLocation.pathname}?${refQueryParams.toString()}` : `${routerLocation.pathname}`,
        redirectTo: refQueryParams.toString() ? `${routerLocation.pathname}?${refQueryParams.toString()}` : `${routerLocation.pathname}`,
      };
      if (formIsValid) {
        state.redirectTo = `/location-profile/${location.locationId}/payment`;
        state.redirectData = {
          showMonth: showMonth,
          fromDate: moment(fromDate).format(DateFormats.DAY),
          toDate: moment(toDate).format(DateFormats.DAY),
          assetTypes: location?.equipmentTypes,
          type: type,
          space: space,
          location,
          label: instantApproval ? 'Book Space' : 'Request Space',
          instantApproval: instantApproval
        };
        state.persistRedirectData = true;
      }

      history(
        '/login',
        {state: state}
      );
    }
  };

  const handleCheckBoxChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    switch (name) {
      case 'isBrokeredBooking':
        setIsBrokeredBooking(value);
        break;
      case 'ssPayoutConfirmed':
        setSsPayoutConfirmed(value);
        break;
      case 'manualPayment':
        setManualPayment(value);
        break;
      default:
        break;
    }
  };

  const handleTextChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    switch(name) {
      case 'customerChargedAmountPerPeriod':
        if (validateCurrencyValue(value)) {
          setCustomerChargedAmountPerPeriod(parseCurrencyValue(value));
          if (ssPayoutConfirmed) setSsPayoutConfirmed(false);
        }
        break;
      case 'partnerPayoutAmountPerPeriod':
        if (validateCurrencyValue(value)) {
          setPartnerPayoutAmountPerPeriod(parseCurrencyValue(value));
          if (ssPayoutConfirmed) setSsPayoutConfirmed(false);
        }
        break;
      case 'overageCustomerChargeRate':
        if (validateCurrencyValue(value)) {
          setOverageCustomerPayoutRate(parseCurrencyValue(value))
          if (ssPayoutConfirmed) setSsPayoutConfirmed(false);
        }
        break;
      case 'overagePartnerPayoutRate':
        if (validateCurrencyValue(value)) {
          setOveragePartnerPayoutRate(parseCurrencyValue(value));
          if (ssPayoutConfirmed) setSsPayoutConfirmed(false);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!location) return; // initializes with null value causing errors when filling form data equipmentTypes
    if (!location?.visible && user?.userType !== UserType.ADMIN) {
      history('/')
    }
    const fillFormData = (bookingDetails) => {
      let defaultDaysAhead = DEFAULT_DAYS_AHEAD;
      if (bookingDetails.showMonth) {
        setShowMonth(bookingDetails.showMonth === 'true');
        if (bookingDetails.showMonth === 'false') {
          defaultDaysAhead = 1;
          setToDate(moment().add(defaultDaysAhead, 'day').format(DateFormats.DAY));
        }
      }
      if (bookingDetails.fromDate && moment(bookingDetails.fromDate).isValid()) {
        const urlFromDate = moment(bookingDetails.fromDate);
        const today = moment().format(DateFormats.DAY);
        if (urlFromDate.isSameOrAfter(today)) {
          setFromDate(moment(urlFromDate, DateFormats.DAY));
          if (bookingDetails.toDate && moment(bookingDetails.toDate).isValid()) {
            const urlToDate = moment(bookingDetails.toDate);
            if (urlToDate.isAfter(urlFromDate)) {
              setToDate(moment(urlToDate, DateFormats.DAY));
            } else {
              setToDate(moment(urlFromDate, DateFormats.DAY).add(defaultDaysAhead, 'days'));
            }
          } else {
            setToDate(moment(urlFromDate, DateFormats.DAY).add(defaultDaysAhead, 'days'));
          }
        }
      }
      if (bookingDetails.type) {
        if (location?.equipmentTypes.some(value => value === bookingDetails.type)) {
          setType(bookingDetails.type);
          setTypeIsValid(true);
        }
      }
      if (bookingDetails.space && Number.parseInt(bookingDetails.space) >= 0) setSpace(Number.parseInt(bookingDetails.space));
    };
    if (queryParams && Object.keys(queryParams).length) {
      const bookingDetails = {
        showMonth: decodeURIComponent(queryParams.showMonth),
        fromDate: decodeURIComponent(queryParams.fromDate),
        toDate: decodeURIComponent(queryParams.toDate),
        type: decodeURIComponent(queryParams.type),
        space: decodeURIComponent(queryParams.space)
      };
      fillFormData(bookingDetails);
    } else if (localStorage.getItem(REDIRECT_REFERRER)?.startsWith(routerLocation.pathname)) {
      // Instead of redirecting, just reuse the form values from the URL search params
      // Parse our search params
      let refURL = new URL(localStorage.getItem(REDIRECT_REFERRER), window.location.origin);
      const lsQueryParams = new URLSearchParams(refURL.search);
      const bookingDetails = {
        showMonth: decodeURIComponent(lsQueryParams.get('showMonth')),
        fromDate: decodeURIComponent(lsQueryParams.get('fromDate')),
        toDate: decodeURIComponent(lsQueryParams.get('toDate')),
        type: decodeURIComponent(lsQueryParams.get('type')),
        space: decodeURIComponent(lsQueryParams.get('space')),
      };
      fillFormData(bookingDetails);
    }
    if (localStorage.getItem(REDIRECT_REFERRER)?.startsWith(routerLocation.pathname)) {
      localStorage.removeItem(REDIRECT_REFERRER);
    }
  }, [routerLocation, queryParams, location, user]);

  useEffect(() => {
    if (location) {
      const dailyRate = calculateRate(type, location, false);
      const monthlyRate = calculateRate(type, location, true);

      setPricePerDay(dailyRate);
      setPricePerMonth(monthlyRate);

      const userIsAdmin = user && user.userType === UserType.ADMIN;
      const locationDailyBookingsDisabled = dailyRate === 0;
      if (userIsAdmin)  {
        const disableDailyButton = locationDailyBookingsDisabled && !isBrokeredBooking;
        setDisableDailyBooking(disableDailyButton);
        setShowMonth(disableDailyButton ? true : showMonth);
      } else {
        setDisableDailyBooking(locationDailyBookingsDisabled);
        setShowMonth(locationDailyBookingsDisabled ? true : showMonth);
      }
    }
  }, [type, location, showMonth, isBrokeredBooking]);

  useEffect(() => {
    if (isBrokeredBooking) {
      const zeroSecurSpacePayout = isZeroSecurSpacePayout(customerChargedAmountPerPeriod, partnerPayoutAmountPerPeriod);
      const zeroPartnerPayout = isZeroPartnerPayout(customerChargedAmountPerPeriod, partnerPayoutAmountPerPeriod);
      setSsPayoutConfirmationRequired(zeroSecurSpacePayout || zeroPartnerPayout);
    } else {
      setSsPayoutConfirmationRequired(false);
    }
  }, [isBrokeredBooking, customerChargedAmountPerPeriod, partnerPayoutAmountPerPeriod]);

  useEffect(() => {
    const datesAreValid = moment(fromDate).isValid() && moment(toDate).isValid();
    const fromDateBeforeToday = moment(fromDate).isBefore(moment(), 'day');
    const fromDateSameOrAfterToDate = moment(fromDate).isSameOrAfter(toDate, 'day');
    const spaceIsValid = Number.isInteger(space) && space >= (location ? location.minNumberOfSpaces : 1);
    if (!datesAreValid || (fromDateBeforeToday && !isBrokeredBooking) || fromDateSameOrAfterToDate
      || !typeIsValid || (!spaceIsValid && !isBrokeredBooking)) {
      setFormIsValid(false);
    } else if (isBrokeredBooking) {
      const brokeredBookingIsValid = partnerPayoutAmountPerPeriod <= customerChargedAmountPerPeriod;
      let isValid = typeIsValid && brokeredBookingIsValid;
      if (ssPayoutConfirmationRequired) {
        isValid = isValid && ssPayoutConfirmed;
      }
      setFormIsValid(isValid);
    } else {
      setFormIsValid(true);
    }
  }, [
    location,
    typeIsValid,
    fromDate, toDate, space,
    isBrokeredBooking,
    partnerPayoutAmountPerPeriod,
    customerChargedAmountPerPeriod,
    ssPayoutConfirmationRequired,
    ssPayoutConfirmed
  ]);

  useEffect(() => {
    const options = [];
    if (location) {
      EquipmentTypes.jsonTypes.forEach((type) => {
        if (location.equipmentTypes.indexOf(type.assetType) > -1 && options.indexOf(type.assetType) === -1) {
            options.push(type.assetType);
        }
      });
    }
    if (isBrokeredBooking) {
      options.push(...adminEquipmentTypes.OPTIONS)
    }
    setEquipmentTypeArray(options);
  }, [isBrokeredBooking, location]);

  useEffect(() => {
    if (onCapacityRequestChange) {
      onCapacityRequestChange({
        durationType: showMonth ? MONTHLY : DAILY,
        startDate: moment(fromDate).format(DateFormats.DAY),
        endDate:moment(toDate).format(DateFormats.DAY),
        numberOfSpaces: space,
      });
    }
  }, [showMonth, fromDate, toDate, space, onCapacityRequestChange]);

  const displayAdminInfo = user && user.userType === UserType.ADMIN;
  const displayBrokeredBookingConfirmation = displayAdminInfo && isBrokeredBooking && ssPayoutConfirmationRequired;

  return <Grid container direction='column' className={classes.mainContainer}>
    {
      displayAdminInfo && <AdminBrokeredBookingFormSection
        user={user}
        location={location}
        isBrokeredBooking={isBrokeredBooking}
        chargeOverages={chargeOverages}
        locationChargeOverages={location?.chargeOverages}
        setChargeOverages={setChargeOverages}
        onTextChange={handleTextChange}
        onCheckBoxChange={handleCheckBoxChange}
        customerChargedAmountPerPeriod={customerChargedAmountPerPeriod}
        partnerPayoutAmountPerPeriod={partnerPayoutAmountPerPeriod}
        overageCustomerChargeRate={overageCustomerChargeRate}
        overagePartnerPayoutRate={overagePartnerPayoutRate}
        durationType={capacityRequest?.durationType}
        manualPayment={manualPayment}
      />
    }
    <Grid item container direction='column' className={classNames('ss-booking-space-container w-100', classes.bookSpaceContainer)}>
      <Grid item className='ss-booking-gap-bottom'>
        <LocationItemPriceDisplay
          pricePerMonth={pricePerMonth}
          pricePerDay={pricePerDay}
        />
      </Grid>
      <ButtonSwitch
        primarySelected={showMonth}
        onChange={handleTab}
        optionLabels={["Month-to-Month", "Daily Parking"]}
        disableSecondary={disableDailyBooking}
        disabledSecondaryLabel={"Monthly bookings only"}
      />

      <BookingFromToDatePicker
        showEndDate={!showMonth}
        fromDate={fromDate}
        onFromDateChange={handleFromDateChange}
        toDate={toDate}
        onToDateChange={handleToDateChange}
        disablePast={!(displayAdminInfo && isBrokeredBooking)}
      />
      <Grid item>
        <Typography className='ss-to-title ss-title-gap' variant='subtitle1'>
          Equipment Type
        </Typography>
        <FormControl className='ss-form-control' fullWidth>
          <Select
            value={type}
            onChange={handleTypeChange}
            displayEmpty
            variant='standard'
          >
            <MenuItem value={""} disabled>Choose</MenuItem>
            {
              equipmentTypeArray.map( item => (
                <MenuItem key={item} value={item}>{item}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <BookingNumberOfSpacesTextField
          fullWidth
          value={space}
          onChange={setSpace}
          minimumSpacesRequired={location ? location.minNumberOfSpaces : 1}
          overrideMinimum={isBrokeredBooking}
          label="Spaces"
          inputMode={'numeric'}
        />
      </Grid>
      {
        displayBrokeredBookingConfirmation && <AdminBrokeredBookingConfirmation
          partnerPayoutAmountPerPeriod={partnerPayoutAmountPerPeriod}
          customerChargedAmountPerPeriod={customerChargedAmountPerPeriod}
          checked={ssPayoutConfirmed}
          onChange={handleCheckBoxChange}
        />
      }
      <BookSpaceButton
        handleBookingSpace={handleBookingSpace}
        user={user}
        formIsValid={formIsValid}
        instantApproval={instantApproval}
        isBrokeredBooking={isBrokeredBooking}
      />
    </Grid>
  </Grid>
};

export default LocationProfileBookSpaceForm;
