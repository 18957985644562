import React, { useRef } from 'react';
import { useListingImgStyles } from '../../../../hooks/components/location/useListingImgStyles';
import {constructListingImageUrl} from "../../../../util/ConstructImageUrlUtil";

const LocationListItemListingImage = (props) => {
  const { listingImageFileName, ...other } = props;
  const classes = useListingImgStyles(props);
  const imageRef = useRef(null);

  return <div className={classes.container} >
    <img src={constructListingImageUrl(listingImageFileName)} ref={imageRef} alt={'listing'} className={classes.img} {...other} />
  </div>
}

export default LocationListItemListingImage;
