import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    relative:{
        position: 'relative',
        display: 'none',
        '@media (max-width: 54.85em)': {
            display: 'block',
       }
    },
    seeButton:{
        bottom: '2em',
        position: 'absolute',
        left: '1rem',
        borderRadius: '0.6rem',
        textTransform: 'uppercase',
        fontFamily: 'Inter',
        fontWeight: '500',
        fontSize: '0.9em',
        lineHeight: '180%',
        color: '#3F51B5',
        outline: 'none',
        transition: 'all 0.2s ease-in-out',
        zIndex: 99,
        display:'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        }
    },
    smallSide: {
        '& .swiper-button-prev, .swiper-button-next': {
            height: '3rem',
            width: '3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            borderRadius: '50%',
        },
        '& .swiper-button-next:after, .swiper-button-prev:after': {
            fontSize: '1.4em !important',
            zIndex: 999,
        },
        '& .swiper-slide img': {
            width: '100%',
            height: '16em',
            objectFit: 'cover',
            userSelect: 'none',

        }
    },
    mainSlider: {
        height: '100%',
        '& .swiper-slide': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '& .swiper-button-prev, .swiper-button-next': {
            height: '3rem',
            width: '3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            borderRadius: '50%',
        },
        '& .swiper-pagination-bullet': {
            backgroundColor: 'white',
        },
        '& .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction': {
            bottom: '4em',
            position: 'fixed',
            [theme.breakpoints.up('sm')]: {
                bottom: '2em',
            },
        },
        '& .swiper-button-next:after, .swiper-button-prev:after': {
            fontSize: '1.4em !important',
            zIndex: 999,
        },
        '& .swiper-slide img': {
            display: 'block',
            width: '100%',
            height: '38em',
            objectFit: 'contain',
            borderRadius: '1em',
        },
        '& .swiper-slide-thumb-active': {
            opacity: 1
        },
        [theme.breakpoints.up('xs')]: {
            '& .swiper-button-prev, .swiper-button-next': {
                display: 'flex'
            }
        }
    }
}));

