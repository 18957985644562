import React from 'react'
import { Box, Grid, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles: (theme) => {
  mainContainer: CSSStyleSheet,
  rowGap5: CSSStyleSheet,
  marginTop20: CSSStyleSheet,
  wrapContent: CSSStyleSheet,
  mainContent: CSSStyleSheet,
  marginBottom: CSSStyleSheet,
} = makeStyles(() => ({
  mainContainer: {
    paddingTop: '2.86rem',
    paddingRight: '7.79vw',
    paddingLeft: '7.79vw',
  },
  rowGap5: {
    rowGap: '0.36rem',
  },
  marginTop20: {
    marginTop: '1.43rem',
  },
  wrapContent: {
    marginTop: '0.5em',
    marginBottom: '2em',
  },
  mainContent: {
    marginTop: '2.5em',
  },
  marginBottom: {
    marginBottom: '2em',
    marginTop: '2em',
  }
}));

const PolicyDocumentSkeleton = ({ type }) => {

  const classes = useStyles();

  return (
    <Grid container direction='column' className={classes.mainContainer}>
      <Box component='header' className={classes.wrapContent}>
        <Skeleton variant='text' width={250} />
        <Grid container direction='column' className={classes.rowGap5}>
          <Skeleton variant='text' width={300} />
          <Skeleton variant='text' width={200} />
        </Grid>
        <Skeleton variant='text' width={250} className={classes.marginBottom} />
        {
          type === 'terms' && <Grid container alignItems='center' justifyContent='center' direction='column'>
            <Skeleton variant='text' width={'50%'} height={30} />
          </Grid>
        }
        {
          [...Array(20).keys()].map((item, index) => (
            <Grid container key={index} direction='column' className={classes.mainContent}>
              <Skeleton variant='text' width={100} />
              <Skeleton variant='text' height={50} />
            </Grid>
          ))
        }
      </Box>
    </Grid>
  )
}

export default PolicyDocumentSkeleton