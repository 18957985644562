import React, {useState, useCallback, useEffect} from 'react'

const ImageComponent = ({
    src,
    alt = '',
    errorImg = "/app-images/not-found-image/place_holder_image.png",
    placeHolderimg = "/app-images/loading/image-loader.gif",
    ...otherProps
}) => {
    const [imgSrc, setImgSrc] = useState(placeHolderimg);

    const onError = useCallback(() => {
        setImgSrc(errorImg);
    }, [errorImg]);

    useEffect(() => {
        setImgSrc(src ?? errorImg);
    }, [src, errorImg]);

    return <img {...otherProps} src={imgSrc} alt={alt} onError={onError} />;
};

export default ImageComponent;