import React, {useEffect, useState} from 'react';
import {Grid, useMediaQuery} from "@mui/material";
import DownloadCSVButton from "../../report/export/DownloadCSVButton";
import {ClassNameMap} from "@mui/styles";
import DownloadSpaceUsageCSVButton from '../DownloadSpaceUsageCSVButton';
import {getBuyerSpaceUsageReportUrl} from "../request/space-usage-requests";

const BuyerSpaceUsageExportCSVs = (props: {
    item?: boolean,
    query: {
        startDate: string,
        endDate: string,
        locationId: string,
        bookingNumber: string,
        overageInvoiceNumber: string,
        sortBy: string,
        locationName: string
    },
    onError?: (event) => void,
    className?: ClassNameMap
}) => {
    const { item, query, onError, className } = props;
    const isMd = useMediaQuery(theme => theme.breakpoints.up('md'));

    const [basicReportUrl, setBasicReportUrl] = useState("");

    useEffect(() => {
        setBasicReportUrl(getBuyerSpaceUsageReportUrl(query));
    }, [query]);

    return (
        <Grid item={item} container gap={4} justifyContent={isMd ? 'flex-end' : 'space-between'} xs={12} md>
            <Grid item>
                <DownloadCSVButton
                    reportUrl={basicReportUrl}
                    title={'Download Report'}
                    onError={onError}
                    className={className}/>
            </Grid>
            <DownloadSpaceUsageCSVButton
                query={query}
                isBuyer={true}
                onError={onError}
            />
        </Grid>
    )
};

export default BuyerSpaceUsageExportCSVs;