import * as DOMPurify from 'dompurify';

export const sanitizeData = (data) => {
  if (typeof data !== 'boolean' && !data) {
    return null;
  }
  // if data is an array, recursively sanitize it
  if (Array.isArray(data)) {
    return data.map(eachValue => {
      return sanitizeData(eachValue);
    });
  }
  // if data is an object, recursively clean the values of each key
  if (typeof data === 'object') {
    return Object.keys(data).reduce((acc, eachKey) => {
      acc[eachKey] = sanitizeData(data[eachKey]);
      return acc;
    }, {});
  }
  // if it's a boolean or number, no need to sanitize
  if (typeof data === 'boolean' || typeof data === 'number') {
    return data;
  }

  return DOMPurify.sanitize(data);
}

