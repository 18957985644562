import {
    LandingNav,
    SupplierNav,
    GateManagementNav,
    AccountNav,
    MarketplaceOnlyGmsUserNav,
} from "./navs";
import {SubscriptionType} from "../../components/constants/securspace-constants";

const GATE_MANAGEMENT_SUBNAV = {
    path: 'gate-management', label: 'Gate Management', submenuWidth: 220,
    badge: true,
    submenus: [
        GateManagementNav.CHECK_IN,
        GateManagementNav.SUPPLIER_INVENTORY,
        GateManagementNav.SUPPLIER_INTERCHANGES,
        GateManagementNav.SUPPLIER_ACTIVITY_SUMMARY,
    ]
}

const FINANCES_SUBNAV = {
    path: 'finances', label: 'Finances', submenuWidth: 170,
    submenus: [
        SupplierNav.SUPPLIER_INVOICES,
        SupplierNav.READY_FOR_PAYOUT,
        SupplierNav.SUPPLIER_PAYOUTS,
    ]
}

const makeBookingsSubmenu = (...additionalItems) => {

    const subMenus = Array.prototype.concat([
        SupplierNav.APPROVALS,
        SupplierNav.SUPPLIER_BOOKINGS,
    ], additionalItems)

    return {
        path: 'bookings',
        label: 'Bookings',
        submenuWidth: 220,
        badge: true,
        submenus: subMenus
    }
}

const makeFinancesSubMenu = (...additionalItems) => {
    const newFinancesSubnav = Object.assign({}, FINANCES_SUBNAV); // Copy default subnav vales
    newFinancesSubnav.submenus = newFinancesSubnav.submenus.concat(additionalItems);
    return newFinancesSubnav;
};

export const SUPPLIER_OWNER_GMS_LITE_NAV = [
    LandingNav.SEARCH,
    GATE_MANAGEMENT_SUBNAV,
    makeBookingsSubmenu(),
    FINANCES_SUBNAV,
    LandingNav.RESOURCES_AS_SUPPLIER,
    LandingNav.CONTACT
]


export const SUPPLIER_OWNER_GMS_PRO_NAV = [
    LandingNav.SEARCH,
    GATE_MANAGEMENT_SUBNAV,
    makeBookingsSubmenu(SupplierNav.SUPPLIER_BOOKED_SPACE_CALENDAR),
    makeFinancesSubMenu(SupplierNav.PARTNER_SUBSCRIPTIONS),
    LandingNav.RESOURCES_AS_SUPPLIER,
    LandingNav.CONTACT
]

export const SUPPLIER_OWNER_MARKETPLACE_ONLY_NAV = [
    LandingNav.SEARCH,
    SupplierNav.GMS_LANDING,
    makeBookingsSubmenu(),
    FINANCES_SUBNAV,
    LandingNav.RESOURCES_AS_SUPPLIER,
    LandingNav.CONTACT
]

export const makeSupplierOwnerGMSNav = (subscriptionType: String, supplierInventoryCorrections: boolean) => {
    if (subscriptionType === SubscriptionType.GMS_PRO) {
        return [
            LandingNav.SEARCH,
            supplierInventoryCorrections ?
              makeGateManagementSubmenu(GateManagementNav.INVENTORY_CORRECTION) : GATE_MANAGEMENT_SUBNAV,
            makeBookingsSubmenu(SupplierNav.SUPPLIER_BOOKED_SPACE_CALENDAR),
            makeFinancesSubMenu(SupplierNav.PARTNER_SUBSCRIPTIONS),
            LandingNav.RESOURCES_AS_SUPPLIER,
            LandingNav.CONTACT
        ];
    }
    return SUPPLIER_OWNER_GMS_LITE_NAV;
};

const makeGateManagementSubmenu = (...additionalItems) => {
    const newGateManagementSubmenu = Object.assign({}, GATE_MANAGEMENT_SUBNAV);
    newGateManagementSubmenu.submenus = newGateManagementSubmenu.submenus.concat(additionalItems);
    return newGateManagementSubmenu;
};

export const makeGateManagerNav = (user: { allowsGateManagersToViewBookings: boolean }, inventoryCorrection) => {
    const nav = [
        GateManagementNav.CHECK_IN,
        GateManagementNav.SUPPLIER_INVENTORY,
        GateManagementNav.SUPPLIER_INTERCHANGES,
        GateManagementNav.SUPPLIER_ACTIVITY_SUMMARY,
        SupplierNav.APPROVALS
    ]

    if(user.allowsGateManagersToViewBookings) {
        nav.push(SupplierNav.SUPPLIER_BOOKINGS)
    }
    
    if(inventoryCorrection) {
        nav.push(GateManagementNav.INVENTORY_CORRECTION)
    }

    Array.prototype.push.apply(nav, [AccountNav.LOCATIONS_PROFILE])

    nav.push(LandingNav.RESOURCES_AS_SUPPLIER);

    return nav
}

export const SUPPLIER_GATE_CLERK_NAV = [
    GateManagementNav.CHECK_IN,
    GateManagementNav.SUPPLIER_INVENTORY,
    GateManagementNav.SUPPLIER_INTERCHANGES,
    GateManagementNav.SUPPLIER_ACTIVITY_SUMMARY,
    LandingNav.RESOURCES_AS_SUPPLIER
]

export const MARKETPLACE_ONLY_GATE_USER_NAV = [
    MarketplaceOnlyGmsUserNav.GMS_UPGRADE,
    SupplierNav.GMS_LANDING,
    LandingNav.CONTACT,
    AccountNav.SIGN_OUT
]
