import React, {useState} from 'react';
import {Button} from "@mui/material";
import {withSnackbar} from "../hocs/withSnackbar";
import InitiatePayoutDialog from "../InitiatePayoutDialog";
import Busy from "../Busy";
import {requestInitiatePayouts, requestReadyForPayoutTransactionsForAccount} from "../../views/requests/account-report";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../util/NetworkErrorUtil";
import {logout} from "../../util/LogoutUtil";

type InitiatePayoutsProps = {
  accountId: string,
  locations: any[],
  snackbarShowMessage: (message: string, variant: string) => void,
}

const InitiatePayouts: (props: InitiatePayoutsProps) => JSX.Element = ({ accountId, locations, snackbarShowMessage }) => {
  const [showDialog, setShowDialog] = useState(false);

  const openDialog = () => {
    setShowDialog(true);
  }

  const initiatePayouts = async (locationId: string) => {
    Busy.set(true);
    try {
      const payoutTransactions = await requestReadyForPayoutTransactionsForAccount(accountId, { locationId: locationId === 'All' ? '' : locationId })
        .then((response) => response.body);
      const transactionIds = payoutTransactions.map((payoutTransaction) => payoutTransaction.transaction.bookingTransactionId);
      await requestInitiatePayouts({supplierAccountId: accountId, transactionIds: transactionIds })
        .then(() => {
          snackbarShowMessage('Payouts initiated successfully.', 'success');
        })
    } catch (error) {
      snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error, 'Failed to initiate payouts. Please try again or contact SecurSpace if this issue persists.'), 'error');
      if (error.response.status === 401) {
        logout();
      }
    } finally {
      Busy.set(false);
      setShowDialog(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={openDialog}
      >
        Initiate Available Payouts
      </Button>
      <InitiatePayoutDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        onConfirm={initiatePayouts}
        locations={locations}
      />
    </>
  );
};

export default withSnackbar(InitiatePayouts);