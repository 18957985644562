import React, {useState} from 'react';
import BookingCardList from "./BookingCardList";
import type {Booking} from "../../types/Booking";
import {Box} from "@mui/material";
import UpdatePaymentMethodDialog from "../UpdateBookingPayment/UpdatePaymentMethodDialog";
import type {Account} from "../../types/Account";
import {
  BookingStatusesEligibleForCancellation,
  BookingStatusesEligibleForPaymentMethodUpdate, DateFormats
} from "../constants/securspace-constants";
import {withSnackbar} from "../hocs/withSnackbar";
import TextBox from './TextBox';
import SimpleConfirmationDialog from "../SimpleConfirmationDialog";
import Busy from "../Busy";
import {cancelBookingSubscription} from "../../views/requests/booking-requests";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../util/NetworkErrorUtil";
import moment from "moment/moment";

const InactiveBookings = (props: {account: Account, onActionSuccess: () => void, snackbarShowMessage: () => void, setInactiveBookingCount: () => void, bookings: [Booking], headerCells: [{id: String, label: String}]}) => {
  const {account, onActionSuccess, snackbarShowMessage, bookings, headerCells} = props;
  const [showUpdatePaymentMethodDialog, setShowUpdatePaymentMethodDialog] = useState(false);
  const [bookingToEdit: Booking, setBookingToEdit] = useState(null);
  const [showEndMonthlyBookingConfirmation, setShowEndMonthlyBookingConfirmation] = useState(false);

  const handleEndBooking = (booking) => {
    if (booking.bookingSubscriptionStatus === "ACTIVE" &&
      BookingStatusesEligibleForCancellation.includes(booking.status)) {
      setBookingToEdit(booking);
      setShowEndMonthlyBookingConfirmation(true);
    } else {
      snackbarShowMessage("Inactive bookings are not eligible for cancellation.", "warning");
    }
  };

  const handleUpdateBooking = (booking: Booking) => {
    if (BookingStatusesEligibleForPaymentMethodUpdate.includes(booking.status)) {
      setBookingToEdit(booking);
      setShowUpdatePaymentMethodDialog(true);
    } else {
      // Remove this when we can programmatically disable the update payment method button
      snackbarShowMessage("This booking is not eligible for payment method update.", "warning");
    }
  };

  const handleCloseUpdatePaymentMethodDialog = () => {
    setShowUpdatePaymentMethodDialog(false);
    setBookingToEdit(null);
  };

  const handleUpdatePaymentMethod = () => {
    handleCloseUpdatePaymentMethodDialog();
    onActionSuccess();
  };

  const handleCloseEndMonthlyBookingConfirmation = () => {
    setShowEndMonthlyBookingConfirmation(false);
    setBookingToEdit(null);
  };

  const handleMonthlyBookingCancellation = () => {
    Busy.set(true);
    cancelBookingSubscription({id: bookingToEdit.id}).then((response) => {
      const canceledBooking = response.body;
      snackbarShowMessage(`Subscription for booking ${canceledBooking.orderNumber} will end on ${moment(canceledBooking.endDate).format(DateFormats.DAY)}`, "success");
      handleCloseEndMonthlyBookingConfirmation();
      onActionSuccess();
    }).catch((error) => {
      snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), "error");
    }).finally(() => {
      Busy.set(false);
    });
  };

  return (
    <Box>
      <TextBox title="A booking that is expired, canceled, or declined." />
      <BookingCardList bookings={bookings} onEnd={handleEndBooking} onUpdate={handleUpdateBooking} headerCells={headerCells}/>
      <UpdatePaymentMethodDialog
        open={showUpdatePaymentMethodDialog}
        onClose={handleCloseUpdatePaymentMethodDialog}
        booking={bookingToEdit}
        account={account}
        onSuccess={handleUpdatePaymentMethod}
      />
      <SimpleConfirmationDialog
        onClose={handleCloseEndMonthlyBookingConfirmation}
        onConfirm={handleMonthlyBookingCancellation}
        open={showEndMonthlyBookingConfirmation}
        title={"End Monthly Booking"}
        body={"Are you sure you want to end this monthly booking?"}
      />
    </Box>
  );
};

export default withSnackbar(InactiveBookings);
