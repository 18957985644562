import { makeStyles } from '@mui/styles'

export const useStylesBlog = makeStyles(_theme => ({
    container: {
        marginTop: '1.7em',
        "@media (max-width: 550px)": {
            flexDirection: 'column-reverse'
        }
    },
    root: {
        background: 'linear-gradient(272.09deg, #653BC0 0%, #29AAE2 100%)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        height: '14.28em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    error:{
        marginTop: '0.2em',
        fontFamily: 'Inter',
    },
    success:{
        marginTop: '0.2em',
        fontFamily: 'Inter',
        color: _theme.palette.success.main
    },
    bgTitle: {
        fontWeight: '700',
        fontSize: '5.33em',
        color: '#fff',
        textAlign: 'center',
        lineHight: '6.14em',
        "@media (max-width: 550px)": {
            fontSize: '2.33em',
        }
    },
    bgSubTitle: {
        fontWeight: '500',
        fontSize: '1.71em',
        color: '#fff',
        textAlign: 'center',
        lineHight: '2.28em',
        "@media (max-width: 550px)": {
            fontSize: '1.33em',
        }
    },
    gap: {
        gap: '2em',
        "@media (max-width: 550px)": {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }

    },
    sectionPadding: {
        paddingBottom: '3.7em'
    },
    icon: {
        width: '1.21em',
        height: '1.21em',
        objectfit: 'contain'
    },
    box: {
        background: '#6639BF',
        borderRadius: '0.8em',
        padding: '1.25em'
    },
    subTitle: {
        fontFamily: 'Inter',
        fontWeight: '500',
        fontSize: '1.28em',
        color: '#fff',
        textTransform: 'capitalize',
        marginBottom: '0.5em'
    },
    button: {
        backgroundColor: '#2196F3',
        marginTop: '1.2em',
        color: '#fff',
    },
    subcribe: {
        "@media (max-width: 550px)": {
            marginBottom: '2.5em',
        }
    },
    pagination: {
        marginTop: '1.7em'
    },
    skeleton: {
        boxShadow: '0px 4px 12px 4px rgba(63, 81, 181, 0.05)',
        background: '#FFFFFF',
        borderRadius: '0.8em',
        height: '23em',
        width: '20em',
    },
    wrapperPadding: {
        padding: '1.71em'
    }
}));