import React from 'react';
import { Button, Grid } from '@mui/material';
import { inventoryCorrectionStatus } from '../../constants/securspace-constants';

const ActionButtons = ({ item, setInventory, deleteCorrection }) => {

  const { checkinCorrection, checkoutCorrection } = item;

  const correction = checkinCorrection ? checkinCorrection : checkoutCorrection ? checkoutCorrection : null;

  const status = correction?.status ? correction.status : null;

  const retractCorrection = () => {
    deleteCorrection(correction.id)
  }

  const handleSubmit = () => setInventory(item);

  return <>
    {
      status === inventoryCorrectionStatus.PENDING ?
        <Grid container sx={{ fontSize:'1em' }}>
          <Button sx={{ fontSize:'1em' }} disabled={true} variant="outlined">CORRECTION SENT</Button>
          <Button sx={{ fontSize:'1em', mt:1, width:'100%' }} onClick={retractCorrection} variant="contained">RETRACT</Button>
        </Grid>
      : status === inventoryCorrectionStatus.CANCELLED || !status ?
        <Grid container sx={{ fontSize:'1em' }}>
          <Button sx={{ fontSize:'1em' }} onClick={handleSubmit} variant="outlined">SUBMIT CORRECTION</Button>
        </Grid>
      :
        ""
    }
  </>
};

export default ActionButtons;