import dataURLtoBlob from "blueimp-canvas-to-blob";

const FILE_API_BASE_URL = '/api/file';
const UPDATE_GALLERY_URL = "/api/gallery-check-out";
const GET_INVENTORY_UPLOAD_URL = `${FILE_API_BASE_URL}/inventory/upload-url`;
const GET_GALLERY_UPLOAD_URL = `${FILE_API_BASE_URL}/gallery/upload-url`;
const GET_LISTING_IMAGES_URL = `${FILE_API_BASE_URL}/listing-images/upload-url`
const GET_LEGAL_AGREEMENTS_URL = `${FILE_API_BASE_URL}/legal-agreements/upload-url`

const handleResponse = (response) => {
  if (!response.ok) {
    throw Error(`${response.status} - ${response.statusText}`);
  }
  return response;
}

const getUploadUrl = async (url, params) => {
  try {
    const response = await fetch(url + '?' + new URLSearchParams(params));
    return await handleResponse(response).text();
  } catch (error) {
    throw Error("Failed to retrieve upload URL");
  }
}

const uploadFile = async(file, uploadUrl, contentType) => {
  try {
    const response = await fetch(uploadUrl, {
      method: "PUT",
      headers: {
        "Content-Type": contentType
      },
      body: file
    });
    handleResponse(response);
  } catch (e) {
    throw Error("Failed to upload file");
  }
}

export const uploadInventoryImage = async (inventoryId, fileDataUrl, fileName, contentType) => {
  const uploadUrl = await getUploadUrl(GET_INVENTORY_UPLOAD_URL,
    {
      fileName,
      inventoryId,
      contentType
    });

  await uploadFile(dataURLtoBlob(fileDataUrl), uploadUrl, contentType);
  return fileName;
}

export const uploadGalleryImage = async (locationId, fileDataUrl, fileName, contentType) => {
  const uploadUrl = await getUploadUrl(GET_GALLERY_UPLOAD_URL,
    {
      fileName,
      locationId,
      contentType
    });

  await uploadFile(dataURLtoBlob(fileDataUrl), uploadUrl, contentType);
  return fileName;
}

export const uploadListingImage = async (fileDataUrl, fileName, contentType) => {
  const uploadUrl = await getUploadUrl(GET_LISTING_IMAGES_URL,
    {
      fileName,
      contentType
    });

  await uploadFile(dataURLtoBlob(fileDataUrl), uploadUrl, contentType);
  return fileName;
}

export const uploadCompanyProfileImage = async(file, fileName, contentType) => {
  const uploadUrl = await getUploadUrl(GET_LISTING_IMAGES_URL,
    {
      fileName,
      contentType
    });

  await uploadFile(file, uploadUrl, contentType);
  return fileName;
}

export const uploadLegalAgreement = async(file, fileName, contentType) => {
  const uploadUrl = await getUploadUrl(GET_LEGAL_AGREEMENTS_URL,
    {
      fileName,
      contentType
    });

  await uploadFile(file, uploadUrl, contentType);
  return fileName;
}

export const deleteFile = async (fileName) => {
  await fetch(FILE_API_BASE_URL + '?' + new URLSearchParams({fileName}), { method: "DELETE"})
}


export const updateGallery = async (activity, galleryFiles) => {
  const response = await fetch(UPDATE_GALLERY_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      activity: activity,
      galleryFiles: galleryFiles,
    }),
  });
  return handleResponse(response);
}
