import React, { useContext } from 'react'
import { Route } from "react-router";
import { AppContext } from "../../context/app-context";
import { withAccessControl } from "../ProtectedRoute";
import AccessControlBuilder from "../AccessControlBuilder";

import SupplierActiveBookings from "../../views/SupplierActiveBookings";
import SupplierInventoryReport from "../../views/SupplierInventoryReport";
import SupplierInterchangeReport from "../../views/SupplierInterchangeReport";
import SupplierActivitySummaryReport from "../../views/SupplierActivitySummaryReport";
import CheckIn from "../../views/CheckIn";

const allSupplierGmsAuthorization = new AccessControlBuilder().allowAllGmsUsers().build()

const ProtectedSupplierActiveBookings = withAccessControl(SupplierActiveBookings, allSupplierGmsAuthorization)
const ProtectedCheckIn = withAccessControl(CheckIn, allSupplierGmsAuthorization)
const ProtectedSupplierInventoryReport = withAccessControl(SupplierInventoryReport, allSupplierGmsAuthorization)
const ProtectedSupplierInterchangeReport = withAccessControl(SupplierInterchangeReport, allSupplierGmsAuthorization)
const ProtectedSupplierActivitySummaryReport = withAccessControl(SupplierActivitySummaryReport, allSupplierGmsAuthorization)

const GateManagementContent = () => {
    const appContext = useContext(AppContext)
    const { user, logout } = appContext

    return [
        <Route exact path="/check-in" key="/check-in" element={<ProtectedSupplierActiveBookings account={user} handleLogout={logout} />
        } >
        </Route>,
        <Route path="/check-in/:bookingId" key="/check-in/bookingId" element={<ProtectedCheckIn account={user} handleLogout={logout} />}>

        </Route>,
        <Route path="/supplier-inventory" key="/supplier-inventory" element={<ProtectedSupplierInventoryReport account={user} handleLogout={logout} />}>

        </Route>,
        <Route path="/supplier-interchanges" key="/supplier-interchanges" element={<ProtectedSupplierInterchangeReport account={user} handleLogout={logout} />}>

        </Route>,
        <Route path="/supplier-activity-summary" key="/supplier-activity-summary" element={<ProtectedSupplierActivitySummaryReport account={user} handleLogout={logout} />}>

        </Route>
    ]
}

export default GateManagementContent
