import React from "react";
import { Grid, Typography, Tooltip } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { formatMoneyAddDecimalPoint } from "../../../util/PaymentUtils";
import moment from "moment";
import Skeleton from "@mui/lab/Skeleton";
import {Theme} from "@mui/material";

const useStyles: (theme: Theme) => {
  title: CSSStyleSheet,
  totalMonthlyAmount: CSSStyleSheet,
  totalTodaysPayment: CSSStyleSheet,
  error: CSSStyleSheet,
  hidden: CSSStyleSheet,
} = makeStyles(() => ({
  title: {
    marginBottom: '0.6rem'
  },
  totalMonthlyAmount: {
    marginTop: '1rem',
    borderTop: '1px solid #E0E0E0',
    paddingTop: '1rem'
  },
  totalTodaysPayment: {
    marginTop: '1rem',
    borderTop: '1px solid #E0E0E0',
    paddingTop: '1rem',
    marginBottom: '1.5rem'
  },
  error: {
    fontWeight: '600',
    fontFamily: 'Inter',
    marginBottom: '1rem'
  },
  hidden: {
    display: 'none'
  }
}));

const BookingCostBreakdown = (props: {monthly: boolean, startDate: any, isBrokered: boolean, costData: any, calculatingCostData: boolean, displayCalculatedCostData: boolean}) => {
  const {monthly, startDate, isBrokered, costData, calculatingCostData, displayCalculatedCostData = true} = props;
  const classes = useStyles();

  return <Grid container className="ss-byb-cost-container">
    {monthly ? <>
      <Typography variant="h6" color={"textPrimary"} className={classes.title}>Today's Payment Summary</Typography>
        <Grid item className="ss-byb-cost-item">
          <Typography variant="h6" color={"textSecondary"}>First Cycle Dates Covered:</Typography>
          <Typography variant="h6" color={"textPrimary"} className={displayCalculatedCostData ? "" : classes.hidden}>
            {
              calculatingCostData ? <Skeleton variant={'text'} width={'12em'}/> :
              `${moment(startDate).format('MM/DD/YYYY')} - ${moment(costData?.firstRecurringPaymentDate).subtract(1, 'day').format('MM/DD/YYYY')}`
            }
          </Typography>
        </Grid>
        <Grid item className="ss-byb-cost-item">
          <Typography variant="h6" color={"textSecondary"}>First Cycle Booking Charge:</Typography>
          <Typography variant="h6" color={"textPrimary"} className={displayCalculatedCostData ? "" : classes.hidden}>
            {calculatingCostData ? <Skeleton variant={'text'} width={'8em'}/> : `$${formatMoneyAddDecimalPoint(costData?.initialCharge)}`}
          </Typography>
        </Grid>
        {!isBrokered ?
          <Grid>
            <Grid item className="ss-byb-cost-item">
              <Typography variant="h6" color={"textSecondary"}>Processing Fee:</Typography>
              <Typography variant="h6" color={"textPrimary"} className={displayCalculatedCostData ? "" : classes.hidden}>{calculatingCostData ? <Skeleton variant={'text'} width={'8em'}/> : `$${formatMoneyAddDecimalPoint(costData?.initialProcessingFee)}`}</Typography>
            </Grid>
            <Grid item className="ss-byb-cost-item">
              <Typography variant="h6" color={"textSecondary"}>Taxes:</Typography>
              <Typography variant="h6" color={"textPrimary"} className={displayCalculatedCostData ? "" : classes.hidden}>{calculatingCostData ? <Skeleton variant={'text'} width={'8em'}/> : `$${formatMoneyAddDecimalPoint(costData?.initialBookingTaxAmount)}`}</Typography>
            </Grid>
          </Grid>
        : ""
        }
         <Grid item className={`ss-byb-cost-item ${classes.totalTodaysPayment}`}>
          {isBrokered ?
            <Tooltip title = "Does not include taxes or payment processor fees" placement="top-start">
              <Typography variant={"h5"} color={"textPrimary"}>Today's Estimated Total</Typography>
            </Tooltip>
          :
            <Typography variant={"h5"} color={"textPrimary"}>Today's Payment Total</Typography>
          }
          <Typography variant="h5" color={"textPrimary"} className={displayCalculatedCostData ? "" : classes.hidden}>
          {calculatingCostData ? <Skeleton variant={'text'} width={'8em'}/> : `$${formatMoneyAddDecimalPoint(isBrokered ? costData?.initialCharge : costData?.initialTotalCharge)}`}</Typography>
         </Grid>

        <Typography variant="h6" color={"textPrimary"}>Monthly Payment Summary</Typography>
        <Typography className={classes.error} variant="body2" component={"strong"} color='error'>
          This booking will renew automatically every Month until cancelled
        </Typography>
        <Grid item className="ss-byb-cost-item">
          <Typography variant="h6" color={"textSecondary"}>Payment Day of the Month:</Typography>
          <Typography variant="h6" color={"textPrimary"}>1st</Typography>
        </Grid>
        <Grid item className="ss-byb-cost-item">
          <Typography variant="h6" color={"textSecondary"}>Next Payment Date:</Typography>
          <Typography variant="h6" color={"textPrimary"} className={displayCalculatedCostData ? "" : classes.hidden}>
            {calculatingCostData ? <Skeleton variant={'text'} width={'10em'}/> : costData?.firstRecurringPaymentDate}</Typography>
        </Grid>
        <Grid item className="ss-byb-cost-item">
          <Typography variant="h6" color={"textSecondary"}>Monthly Booking Charge:</Typography>
          <Typography variant="h6" color={"textPrimary"} className={displayCalculatedCostData ? "" : classes.hidden}>{calculatingCostData ? <Skeleton variant={'text'} width={'8em'}/> : `$${formatMoneyAddDecimalPoint(costData?.recurringCharge)}`}</Typography>
        </Grid>
        {!isBrokered ? 
            <Grid>
              <Grid item className="ss-byb-cost-item">
                <Typography variant="h6" color={"textSecondary"}>Processing Fee:</Typography>
                <Typography
                  variant="h6" color={"textPrimary"} className={displayCalculatedCostData ? "" : classes.hidden}>{calculatingCostData ? <Skeleton variant={'text'} width={'8em'}/> : `$${formatMoneyAddDecimalPoint(costData?.recurringProcessingFee)}`}</Typography>
              </Grid>
              <Grid item className="ss-byb-cost-item">
                <Typography variant="h6" color={"textSecondary"}>Taxes:</Typography>
                <Typography
                  variant="h6" color={"textPrimary"} className={displayCalculatedCostData ? "" : classes.hidden}>{calculatingCostData ? <Skeleton variant={'text'} width={'8em'}/> : `$${formatMoneyAddDecimalPoint(costData?.recurringBookingTaxAmount)}`}</Typography>
              </Grid>
            </Grid>
          : ""
        }
        <Grid item className={`ss-byb-cost-item ${classes.totalMonthlyAmount}`}>
          {isBrokered ?
            <Tooltip title = "Does not include taxes or payment processor fees" placement="top-start">
              <Typography variant={"h5"} color={"textPrimary"}>Total Estimated Monthly Payment</Typography>
            </Tooltip>
          :
            <Typography variant={"h5"} color={"textPrimary"}>Total Monthly Payment</Typography>
          }
          <Typography variant={"h5"} color={"textPrimary"} className={displayCalculatedCostData ? "" : classes.hidden}>
            {calculatingCostData ? <Skeleton variant={'text'} width={'8em'}/> : `$${formatMoneyAddDecimalPoint(isBrokered ? costData?.recurringCharge : costData?.recurringTotalCharge)}`}</Typography>
        </Grid>
      </> :
      <>
        <Grid item className="ss-byb-cost-item">
          <Typography variant="h6" color="textSecondary">Days</Typography>
          <Typography variant="h6" className={displayCalculatedCostData ? "" : classes.hidden}>{calculatingCostData ? <Skeleton variant={"text"} width={'8em'} /> : costData?.duration}</Typography>
        </Grid>
        <Grid item className="ss-byb-cost-item">
          <Typography variant="h6" color="textSecondary">Subtotal{monthly ? " (initial)" : ""}</Typography>
          <Typography variant="h6" className={displayCalculatedCostData ? "" : classes.hidden}>
            {calculatingCostData ? <Skeleton variant={"text"} width={'8em'}/> : `$${formatMoneyAddDecimalPoint(costData?.initialCharge)}`}
          </Typography>
        </Grid>
        {!isBrokered ? 
          <Grid>
            <Grid item className="ss-byb-cost-item">
              <Typography variant="h6" color="textSecondary">Processing Fee</Typography>
              <Typography variant="h6" className={displayCalculatedCostData ? "" : classes.hidden}>
                {calculatingCostData ? <Skeleton variant={"text"} width={'8em'} /> : `$${formatMoneyAddDecimalPoint(costData?.initialProcessingFee)}`}
              </Typography>
            </Grid>
            <Grid item className="ss-byb-cost-item">
              <Typography variant="h6" color="textSecondary">Taxes</Typography>
              <Typography variant="h6" className={displayCalculatedCostData ? "" : classes.hidden}>
                {calculatingCostData ? <Skeleton variant={"text"} width={'8em'} /> : `$${formatMoneyAddDecimalPoint(costData?.initialBookingTaxAmount)}`}
              </Typography>
            </Grid>
          </Grid>
        : ""
        }
        <Grid item className="ss-byb-cost-item">
          {
            isBrokered ?
              <Tooltip title="Does not include taxes or payment processor fees" placement="top-start">
                <Typography variant="h5">Total Estimated Payment</Typography>
              </Tooltip>
            :
            <Typography variant="h5">Total</Typography>
          }
            <Typography variant="h5" className={displayCalculatedCostData ? "" : classes.hidden}>
              {calculatingCostData ? <Skeleton variant={"text"} width={'8em'} /> : `$${formatMoneyAddDecimalPoint(isBrokered ? costData?.initialCharge :costData?.initialTotalCharge)}`}
            </Typography>
        </Grid>
      </>
    }
  </Grid>
};

export default BookingCostBreakdown;
