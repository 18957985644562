import React from 'react';
import {ReactNode} from "react";
import {Grid, Typography} from "@mui/material";

const NoMatchingInvoicesFound = (props: {item: boolean, imageURI: string, message: ReactNode}) => {
  const {item = false, imageURI = "/app-images/person-and-box.svg", message = "No matching invoices found"} = props;

  return (
    <Grid item={item} container spacing={4}>
      <Grid item xs={12} textAlign={'center'}>
        <img src={imageURI} alt="No matching invoices found" />
      </Grid>
      <Grid item xs={12} textAlign={'center'}>
        {
          message && React.isValidElement(message)
            ? message
            : <Typography variant='body2'>{message}</Typography>
        }
      </Grid>
    </Grid>
  );

};

export default NoMatchingInvoicesFound;