import React from 'react';
import { makeStyles } from '@mui/styles'
import { Box, Skeleton} from '@mui/material';


const useStyles = makeStyles(() => ({
    skeleton: {
        boxShadow: '0px 4px 12px 4px rgba(63, 81, 181, 0.05)',
        background: '#FFFFFF',
        borderRadius: '1.5em',
        height: '23em',
        width: '20.64em',
    },
    wrapperPadding: {
        padding: '1.71em'
    },
    margin: {
        marginTop: '1.1em'
    },
    radius: {
        borderTopLeftRadius: '1.5em',
        borderTopRightRadius: '1.5em',
    }
}));

const RecentlyBookedCarouselSkeleton = () => {

    const classes = useStyles();

    return (
        <Box className={classes.skeleton}>
            <Skeleton className={classes.radius} variant="rectangular" width={"100%"} height={158} />
            <Box className={classes.wrapperPadding}>
                <Skeleton width="30%" />
                <Skeleton width='60%' />
                <Box className={classes.margin}>
                    {[...Array(2).keys()].map((_, index) => <Skeleton variant="text" key={index} />)}
                </Box>
            </Box>
        </Box>
    )
}

export default RecentlyBookedCarouselSkeleton