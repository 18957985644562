import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import LocationProfileSimilarYardsNearby from '../../components/location/profile/LocationProfileSimilarYardsNearby';
import { getLocationById } from '../../components/location/requests/location-requests';
import Busy from '../../components/Busy';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import { useStyles } from './style';
import BookingDetails from '../../components/bookingPayment/details/BookingDetails';
import BookingPaymentInfo from './BookingPaymentInfo';
import RequestSpace from '../../components/bookingPayment/requestSpace/RequestSpace';
import { calculateRate, DAILY, getRentalAgreementURL, MONTHLY, ONE_TIME, RECURRING, scrollToTop } from '../../util/BookingUtil';
import { calculateBookingChargeAmount } from '../requests/booking-requests';
import { calculateNumberOfDays } from '../../util/DateUtils';
import BookingConfirmation from '../../components/bookingPayment/confirmation/BookingConfirmation';
import { requestPaymentMethods } from '../../components/paymentMethods/request/payment-method-requests';
import { AuthorityType, DateFormats } from '../../components/constants/securspace-constants';
import RedesignAddPaymentMethodSetup from '../RedesignAddPaymentMethodSetup';
import moment from "moment";
import {
  getErrorMessageForNonStandardAndStandardResponse
} from '../../util/NetworkErrorUtil';
import adminEquipmentTypes from '../../components/ServiceTypes';
import {withSnackbar} from "../../components/hocs/withSnackbar";
import useLocationHasRequestedCapacity from "../../hooks/components/location/useLocationHasRequestedCapacity";
import EquipmentTypes from "../../components/EquipmentTypes";
import {getEndDateMonthly} from "../../controls/FrequencyOption";

const BookingPayment = React.forwardRef(({ account, snackbarShowMessage }, ref) => {
  const routerLocation = useLocation();
  const classes = useStyles();
  const searchHistory = routerLocation?.state?.searchHistory;
  const {profileId} = useParams();
  const [bookingLocation, setBookingLocation] = useState();
  const [startDate, setStartDate] = useState(moment().format(DateFormats.DAY));
  const [endDate, setEndDate] = useState(moment().add(1, 'days').format(DateFormats.DAY));
  const [assetType, setAssetType] = useState('');
  const [rate, setRate] = useState(0);
  const [numberSpaces, setNumberSpaces] = useState(0);
  const [bookingStaticData, setBookingStaticData] = useState({
    monthly: false,
    instantApproval: false,
    assetTypeList: []
  });
  const [requestBookSpaceObject, setRequestBookSpaceObject] = useState();
  const [rentalAgreementURL, setRentalAgreementURL] = useState("");
  const [costData, setCostData] =
    useState({
      duration: 0,
      initialTotalCharge: 0,
      initialProcessingFee: 0,
      initialCharge: 0,
      recurringTotalCharge: 0,
      recurringProcessingFee: 0,
      recurringCharge: 0,
      firstRecurringPaymentDate: ''});
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [hasAcceptedRentalAgreement, setHasAcceptedRentalAgreement] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [bookingNumber, setBookingNumber] = useState();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [payWithAch, setACH] = useState(false);
  const [microDepositVerify, setMicroDeposit] = useState(false);
  const [collectMicroDepositVerifyPayment, setCollectMicroDepositVerifyPayment] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(1);
  const [disableBookingChanges, setDisableBookingChanges] = useState(false);
  const [bookingIsValid, setBookingIsValid] = useState(true);
  const [isBrokeredBooking, setIsBrokeredBooking] = useState(false);
  const [calculatingCostData, setCalculatingCostData] = useState(false);

  const assetTypeList = useMemo(() => {
    if (!bookingLocation) {
      return [''];
    }
    const options = [];
    if (bookingLocation) {
      EquipmentTypes.jsonTypes.forEach((type) => {
        if (bookingLocation.equipmentTypes.indexOf(type.assetType) > -1 && options.indexOf(type.assetType) === -1) {
          options.push(type.assetType);
        }
      });
    }
    if (isBrokeredBooking) {
      options.push(...adminEquipmentTypes.OPTIONS)
    }
    return options;
  }, [isBrokeredBooking, bookingLocation]);

  const locationHasRequestedCapacity = useLocationHasRequestedCapacity(
    profileId,
    numberSpaces,
    startDate,
    moment(endDate).format(DateFormats.DAY),
    bookingStaticData.monthly ? MONTHLY : DAILY,
  );

  const filterAndSetPaymentMethods = useCallback((paymentMethods) => {
    if (paymentMethods && paymentMethods.length === 0) {
      snackbarShowMessage('You must add a payment method before booking a space.', 'error', 15000);
      Busy.set(false);
      return;
    }

    if (paymentMethods && paymentMethods.length > 0) {
      const validPaymentMethods = paymentMethods.filter((paymentMethod) => {
        return paymentMethod?.dwollaStatus !== 'unverified' && paymentMethod?.stripeStatus !== 'unverified';
      });

      if (validPaymentMethods && validPaymentMethods.length === 0) {
        snackbarShowMessage('You must add a verified payment method before booking a space.', 'error', 15000);
        Busy.set(false);
        return;
      }

      setPaymentMethods(validPaymentMethods);
      setPaymentMethodId(validPaymentMethods[0].id);
    }
  }, [snackbarShowMessage]);

  const loadPaymentMethods = useCallback(() => {
    Busy.set(true);
    requestPaymentMethods(
      account.authorityId || account.id,
      account.authorityType ? account.authorityType : AuthorityType.ACCOUNT,
      (response) => {
        filterAndSetPaymentMethods(response);
        Busy.set(false);
      },
      (error) => {
        const appError = getErrorMessageForNonStandardAndStandardResponse(error);
        console.error(appError);
        Busy.set(false);
      }
    )
  }, [account, filterAndSetPaymentMethods]);

  useEffect(() => {
    if (paymentMethods.length > 0) {
      setPaymentMethodId(paymentMethods[selectedPaymentMethod - 1].id);
    }
  }, [setPaymentMethodId, paymentMethods, selectedPaymentMethod]);

  useEffect(() => {
    if (profileId) {
      getLocationById(profileId).then((response) => {
        loadPaymentMethods();
        setBookingLocation(response.body);
      }).catch((error) => {
        const appError = getErrorMessageForNonStandardAndStandardResponse(error);
        snackbarShowMessage(appError, 'error', 15000);
      });
    }
  }, [profileId, loadPaymentMethods, snackbarShowMessage]);

  useEffect(() => {
    setRentalAgreementURL(getRentalAgreementURL(bookingLocation?.supplierLegalAgreementFileName))
  }, [bookingLocation]);

  useEffect(() => {
    const data = routerLocation.state?.referrerData;
    if (data) {
      setStartDate(data.fromDate);
      setEndDate(data.toDate);
      setAssetType(data.type);
      setNumberSpaces(data.space);

      const newBookingData = {
        monthly: data.showMonth,
        brokeredBooking: data.brokeredBooking,
      };

      if (data.brokeredBooking) {
        newBookingData.chargeOverages = data.chargeOverages;
        newBookingData.initialChargeAlreadyPaidValue = data.initialChargeAlreadyPaidValue;
        newBookingData.recurringChargeAlreadyPaidValue = data.recurringChargeAlreadyPaidValue;
        newBookingData.customerChargedAmountPerPeriod = data.customerChargedAmountPerPeriod;
        newBookingData.partnerPayoutAmountPerPeriod = data.partnerPayoutAmountPerPeriod;
        newBookingData.overageCustomerChargeRate = data.overageCustomerChargeRate;
        newBookingData.overagePartnerPayoutRate = data.overagePartnerPayoutRate;
        newBookingData.manualPayment = data.manualPayment;
      }
      setBookingStaticData(newBookingData);
    }
  }, [routerLocation]);

  useEffect(() => {
    if (bookingLocation) {
      setRate(calculateRate(assetType, bookingLocation, bookingStaticData.monthly))
    }
  }, [bookingLocation, assetType, bookingStaticData])

  useEffect(() => {
    if (bookingLocation && account && bookingIsValid) {

      const {
        monthly,
        brokeredBooking,
        chargeOverages,
        initialChargeAlreadyPaidValue,
        recurringChargeAlreadyPaidValue,
        partnerPayoutAmountPerPeriod,
        customerChargedAmountPerPeriod,
        overagePartnerPayoutRate,
        overageCustomerChargeRate,
      } = bookingStaticData;

      const endOfMonth = moment(getEndDateMonthly(startDate, 1)).format(DateFormats.DAY);
      const calculateCostObject = {
        supplierAccountId: bookingLocation.id,
        buyerAccountId: account.id,
        locationId: bookingLocation.locationId,
        numberOfSpaces: numberSpaces,
        startDate: moment(startDate).format(DateFormats.DAY),
        endDate: monthly ? endOfMonth : moment(endDate).format(DateFormats.DAY),
        frequency: monthly ? RECURRING : ONE_TIME,
        recurrences: monthly ? 9999 : 0,
        durationType: monthly ? MONTHLY : DAILY,
        rate: rate,
        assetType: assetType,
        paymentMethodId: !brokeredBooking ? paymentMethodId : null,
        brokeredBooking: brokeredBooking,
      }

      if (brokeredBooking) {
        setIsBrokeredBooking(true);
        calculateCostObject.chargeOverages = chargeOverages;
        calculateCostObject.brokeredInitialChargeAlreadyPaid = initialChargeAlreadyPaidValue;
        calculateCostObject.brokeredRecurringChargeAlreadyPaid = recurringChargeAlreadyPaidValue;
        calculateCostObject.brokeredSupplierPaidPerOccurrence = partnerPayoutAmountPerPeriod;
        calculateCostObject.brokeredBuyerChargedPerOccurrence = customerChargedAmountPerPeriod;
        calculateCostObject.brokeredSupplierOverageRatePaid = overagePartnerPayoutRate;
        calculateCostObject.brokeredBuyerOverageRateCharged = overageCustomerChargeRate;
      }

      setCalculatingCostData(true);
      let ignore = false;
      calculateBookingChargeAmount(calculateCostObject)
        .then(res => {
          if (!ignore) {
            const {body} = res;

            if (body) {
              const {monthly} = bookingStaticData;
              const {
                duration,
                initialBookingPaymentAmount,
                initialBookingPaymentProcessingFee,
                initialBookingChargeAmount,
                recurringBookingPaymentAmount,
                recurringBookingPaymentProcessingFee,
                recurringBookingChargeAmount,
                firstRecurringPaymentDate,
                initialBookingTaxAmount,
                recurringBookingTaxAmount
              } = body;

              setCostData({
                duration: monthly ? calculateNumberOfDays(calculateCostObject.startDate, calculateCostObject.endDate) : duration,
                initialTotalCharge: initialBookingChargeAmount,
                initialProcessingFee: initialBookingPaymentProcessingFee,
                initialCharge: initialBookingPaymentAmount,
                recurringTotalCharge: recurringBookingChargeAmount,
                recurringProcessingFee: recurringBookingPaymentProcessingFee,
                recurringCharge: recurringBookingPaymentAmount,
                firstRecurringPaymentDate: firstRecurringPaymentDate,
                initialBookingTaxAmount: initialBookingTaxAmount,
                recurringBookingTaxAmount: recurringBookingTaxAmount
              })
            }
          }
        })
        .catch(err => {
          const appError = getErrorMessageForNonStandardAndStandardResponse(err);
          snackbarShowMessage(appError, 'error', 15000);
          // Users will have to change some part of the booking before they can submit the booking again
          setBookingIsValid(false);
        }).finally(() => {
          setCalculatingCostData(false);
        });
      return () => {
        ignore = true;
      }
    }
  }, [
    bookingLocation,
    account,
    numberSpaces,
    rate,
    assetType,
    startDate,
    endDate,
    paymentMethodId,
    snackbarShowMessage,
    bookingStaticData,
    bookingIsValid
  ]);

  useEffect(() => {
    const firstRecurringPaymentEndDate = moment(costData?.firstRecurringPaymentDate).subtract(1, 'day').format('MM/DD/YYYY');
    const requestBookSpaceObject = {
      supplierAccountId: bookingLocation?.id,
      buyerAccountId: account.id,
      locationId: bookingLocation?.locationId,
      numberOfSpaces: numberSpaces,
      startDate: startDate,
      endDate:  bookingStaticData.monthly? firstRecurringPaymentEndDate : moment(endDate).format(DateFormats.DAY),
      frequency: bookingStaticData.monthly ? RECURRING : ONE_TIME,
      recurrences: bookingStaticData.monthly ? 9999 : 0,
      durationType: bookingStaticData.monthly ? MONTHLY : DAILY,
      rate: rate,
      initialCharge: costData.initialTotalCharge,
      recurringCharge: costData.recurringTotalCharge,
      assetType: assetType,
      paymentMethodId: paymentMethodId,
      tosDocUrl: rentalAgreementURL,
      source: "MARKETPLACE",
      email: !bookingStaticData.brokeredBooking ? account.email: null,
      brokeredBooking: bookingStaticData.brokeredBooking,
      minNumberOfSpaces: bookingLocation?.minNumberOfSpaces ? bookingLocation?.minNumberOfSpaces : 1,
    };

    if (bookingStaticData.brokeredBooking) {
      requestBookSpaceObject.chargeOverages = bookingStaticData.chargeOverages;
      requestBookSpaceObject.brokeredInitialChargeAlreadyPaid = bookingStaticData.initialChargeAlreadyPaidValue;
      requestBookSpaceObject.brokeredRecurringChargeAlreadyPaid = bookingStaticData.recurringChargeAlreadyPaidValue;
      requestBookSpaceObject.brokeredSupplierPaidPerOccurrence = bookingStaticData.partnerPayoutAmountPerPeriod;
      requestBookSpaceObject.brokeredBuyerChargedPerOccurrence = bookingStaticData.customerChargedAmountPerPeriod;
      requestBookSpaceObject.brokeredSupplierOverageRatePaid = bookingStaticData.overagePartnerPayoutRate;
      requestBookSpaceObject.brokeredBuyerOverageRateCharged = bookingStaticData.overageCustomerChargeRate;
      requestBookSpaceObject.paymentMethodId = null;
      requestBookSpaceObject.manualPayment = bookingStaticData.manualPayment;
    }

    setRequestBookSpaceObject(requestBookSpaceObject)
  }, [bookingLocation, account, numberSpaces, bookingStaticData, rate, startDate, endDate, costData, assetType, paymentMethodId, rentalAgreementURL]);

  const showPaymentModal = () => {
    setPaymentModalOpen(!paymentModalOpen);
  }

  const closePaymentModal = () => {
    setPaymentModalOpen(!paymentModalOpen);
    loadPaymentMethods();
  }

  const setMicroDepositHandler = (bool) => {
    setCollectMicroDepositVerifyPayment(bool)
  }

  const setAchHandler = (bool) => {
    setACH(bool);
    setMicroDeposit(false);
    setCollectMicroDepositVerifyPayment(false)
  }

  const handleDisableBookingChanges = () => {
    setDisableBookingChanges((prevState) => !prevState);
  }

  

  const pageTitle = showConfirmation ? 'Confirmation' :
    locationHasRequestedCapacity ? 'Book Space' : 'Request Space';

  return (
    <>
      <Box component='section' className={classes.mainSection}>
        <Container className={classes.container}>
          <Box component='div' className={classes.section}>
            <header>
              <Typography color={'textPrimary'} component={'h1'} className={classes.heading}>
                {pageTitle}
              </Typography>
            </header>
            <Divider variant='fullWidth' className={classes.lpDivider}/>
            <Grid item container className={classes.mainContainer}>
              <Grid item container direction={'column'} className={classes.lpListingInfoSection} xs>
                {
                  showConfirmation ?
                    <BookingConfirmation bookingNumber={bookingNumber} classes={classes}
                                         instantApproval={locationHasRequestedCapacity} searchHistory={searchHistory}
                                         brokered={bookingStaticData.brokeredBooking}/>
                    :
                    <>
                      {
                        isBrokeredBooking ? null
                          :
                          <BookingPaymentInfo
                            selectedPaymentMethod={selectedPaymentMethod}
                            setSelectedPaymentMethod={setSelectedPaymentMethod}
                            showPaymentModal={showPaymentModal}
                            paymentMethods={paymentMethods}
                          />
                      }
                    </>
                }
              </Grid>
              <Grid item xs={12} md={5}>
                <BookingDetails
                  bookingLocation={bookingLocation}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  assetType={assetType}
                  setAssetType={setAssetType}
                  numberSpaces={numberSpaces}
                  setNumberSpaces={setNumberSpaces}
                  staticData={bookingStaticData}
                  costData={costData}
                  disableBookingChanges={disableBookingChanges}
                  instantApproval={locationHasRequestedCapacity}
                  isBrokeredBooking={isBrokeredBooking}
                  assetTypelist={assetTypeList}
                  calculatingCostData={calculatingCostData}
                  setBookingIsValid={setBookingIsValid}
                  displayCalculatedCostData={bookingIsValid}
                />
              </Grid>
              <Grid item xs={12}>
                <RequestSpace
                  duration={costData.duration}
                  rentalAgreementURL={rentalAgreementURL}
                  hasAcceptedRentalAgreement={hasAcceptedRentalAgreement}
                  setHasAcceptedRentalAgreement={setHasAcceptedRentalAgreement}
                  requestBookSpaceObject={requestBookSpaceObject}
                  bookingLocation={bookingLocation}
                  setBookingNumber={setBookingNumber}
                  setShowConfirmation={setShowConfirmation}
                  scrollToTop={scrollToTop}
                  disableBookingChanges={disableBookingChanges || !bookingIsValid}
                  handleDisableBookingChanges={handleDisableBookingChanges}
                  instantApproval={locationHasRequestedCapacity}
                  isBrokeredBooking={isBrokeredBooking}
                />
              </Grid>
            </Grid>
          </Box>
          <RedesignAddPaymentMethodSetup
            account={account}
            collectMicroDepositVerifyPayment={collectMicroDepositVerifyPayment}
            doneWithAddPaymentMethod={closePaymentModal}
            loadPaymentMethods={loadPaymentMethods}
            microDepositVerify={microDepositVerify}
            payWithAch={payWithAch}
            paymentModalOpen={paymentModalOpen}
            ref={ref}
            setACH={setAchHandler}
            setMicroDeposit={setMicroDepositHandler}
            setPaymentModalOpen={setPaymentModalOpen}
          />
        </Container>
      </Box>
      <Container className={classes.container}>
        <div>
          {bookingLocation && <LocationProfileSimilarYardsNearby location={bookingLocation}/>}
        </div>
      </Container>
    </>
  );
});

export default withSnackbar(BookingPayment);
