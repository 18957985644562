import { LandingNav, BuyerNav } from "./navs";

export const BUYER_OWNER_NAV = [
    LandingNav.SEARCH,
    BuyerNav.MY_BOOKINGS,
    {
        path: 'reports', label: 'Reports', submenuWidth: 236,
        submenus: [
            BuyerNav.BUYER_INVENTORY,
            BuyerNav.BUYER_INTERCHANGES,
            BuyerNav.BUYER_INVOICES,
            BuyerNav.BUYER_BOOKED_SPACE_CALENDAR,
        ]
    },
    LandingNav.RESOURCES_AS_BUYER,
    LandingNav.CONTACT
]

export const BUYER_OWNER_WITH_INVENTORY_CORRECTION_NAV = [
    LandingNav.SEARCH,
    BuyerNav.MY_BOOKINGS,
    {
        path: 'reports', label: 'Reports', submenuWidth: 236,
        submenus: [
            BuyerNav.BUYER_INVENTORY,
            BuyerNav.BUYER_INTERCHANGES,
            BuyerNav.BUYER_INVOICES,
            BuyerNav.BUYER_BOOKED_SPACE_CALENDAR,
            BuyerNav.BUYER_INVENTORY_CORRECTIONS,
        ]
    },
    LandingNav.RESOURCES_AS_BUYER,
    LandingNav.CONTACT
]

export const BUYER_DISPATCHER_NAV = [
    LandingNav.SEARCH,
    BuyerNav.BUYER_INTERCHANGES,
    BuyerNav.BUYER_INVENTORY,
    BuyerNav.BUYER_INVOICES,
    LandingNav.RESOURCES_AS_BUYER
]

export const makeBuyerOwnerNav = (buyerInventoryCorrection) => {
    if (buyerInventoryCorrection) {
        return BUYER_OWNER_WITH_INVENTORY_CORRECTION_NAV;
    }
    return BUYER_OWNER_NAV;
}
