import React from "react";

const RequiredFieldIndicationMessage = () => {
    return (
        <p className='ss-required-field-info'>* indicates a required field</p>
    )
}

export const labelWithRequired = (required, name, label) => {
    if (required) {
        return <label htmlFor={name}>{label} <i className='ss-required-field-asterisk'>*</i></label>
    } else return <label htmlFor={name}>{label}</label>
}

export default RequiredFieldIndicationMessage;