import React from 'react';
import {formatCurrencyValue} from "../../util/PaymentUtils";
import { Box, Grid, Typography } from '@mui/material';

const ServiceFeeBreakdown = (props) => {
  const {chargeAmount = 0, taxPercentage = 0, securspaceServiceFee = 0} = props;

  // make sure we are receiving integer values, if not set them to 0
  const actualChargeAmount = (Number.isInteger(chargeAmount) && chargeAmount) || 0;
  const actualSecurspaceFee = (Number.isFinite(securspaceServiceFee) && securspaceServiceFee/100) || 0;
  const actualTax = (Number.isFinite(taxPercentage) && taxPercentage/100) || 0;

  // calculate individual charges and total separately for display purposes
  const securspaceAmount = Math.floor(actualChargeAmount * actualSecurspaceFee);
  const taxAmount = Math.floor((actualChargeAmount + securspaceAmount) * actualTax);
  const totalAmount = actualChargeAmount + securspaceAmount + taxAmount;

  // format the individual charges to be pretty
  const formattedChargeAmount = formatCurrencyValue(actualChargeAmount, true);
  const formattedChargeTaxAmount = formatCurrencyValue(taxAmount, true);
  const formattedSecurspaceAmount = formatCurrencyValue(securspaceAmount, true);
  const formattedTotal = formatCurrencyValue(totalAmount, true);

  const commonStyles = {
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center',
      mb:0.8
  };


  return <Grid mb={4} container flexDirection='column'>
    <Grid item mb={2}>
     <label className='fw-bold'>Charge Breakdown</label>
    </Grid>
    <Box sx={commonStyles}>
      <Typography variant='body2' component='p'>Charge Amount:</Typography>
      <Typography variant='body2' component='p'>{formattedChargeAmount}</Typography>
    </Box>
    <Box sx={commonStyles}>
      <Typography variant='body2' component='p'>Securspace Fee:</Typography>
      <Typography variant='body2' component='p'>{formattedSecurspaceAmount}</Typography>
    </Box>
    <Box sx={commonStyles}>
      <Typography variant='body2' component='p'>Tax Amount:</Typography>
      <Typography variant='body2' component='p'>{formattedChargeTaxAmount}</Typography>
    </Box>
    <Box sx={commonStyles}>
      <Typography variant='body2' component='p'>Customer Charge:</Typography>
      <Typography variant='body2' component='p'>{formattedTotal}</Typography>
    </Box>
  </Grid>
}

export default ServiceFeeBreakdown;
