import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PinDrop } from '@mui/icons-material';
import '../../../css/components/backToSearch.css'
import InstantApprovalChip from '../search/InstantApprovalChip';
import RequestSpaceChip from '../search/RequestSpaceChip';
import { Theme } from "@mui/material";

const useStyles: (theme: Theme) => {
    goBackLocationName: CSSStyleSheet,
    wFitContent: CSSStyleSheet,
    cityState: CSSStyleSheet,
    spaceTop: CSSStyleSheet,
} = makeStyles((theme) => ({
    goBackLocationName: {
        ...theme.components.contentTitleText,
        fontWeight:'600',
        fontSize:'1.43em'
    },
    wFitContent: {
        width: 'fit-content',
    },
    cityState: {
        fontSize: '1em'
    },
    spaceTop:{
        marginTop: '0.3rem',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0.5em',
        }
    }
}));

const LocationProfileHeader = (props) => {
    const [locationAddress, setLocationAddress] = useState('');

    const { locationName, city, state, hasRequestedCapacity } = props;
    const classes = useStyles();

    useEffect(() => {
        const commaSeparateAddressPart = (address, newAddressPart) => {
            return address && address.length ? `, ${newAddressPart}` : newAddressPart;
        };
        let formattedAddress = '';
        formattedAddress += commaSeparateAddressPart(formattedAddress, city);
        formattedAddress += commaSeparateAddressPart(formattedAddress, state);
        setLocationAddress(formattedAddress);
    }, [city, state]);

    return (
        <header className='w-100'>
            <Grid container alignItems='center' className="ss-search-results-go-back-parent">
                <Grid container direction='column' className="ss-search-results-go-back-container w-100">
                    <Typography component='h1' className={classes.goBackLocationName}>
                        {locationName}
                    </Typography>
                    <Grid className={classes.spaceTop} container justifyContent='space-between' alignItems='center'>
                        <Grid item>
                            <Grid container alignItems='center'>
                                <PinDrop fontSize='small' className="ss-search-results-pindrop-go-back" />
                                <Typography variant="subtitle2" color="textPrimary" className={classes.cityState}>
                                    {locationAddress}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {
                                hasRequestedCapacity ?
                                    <div className={classes.wFitContent}><InstantApprovalChip /></div>
                                    : <div className={classes.wFitContent}><RequestSpaceChip /></div>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </header>
    );
};

export default LocationProfileHeader;
