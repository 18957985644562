import React from 'react';
import InvoiceCard from "./InvoiceCard";
import InfoCardLoadingSkeleton from "../../../skeleton/InfoCardLoadingSkeleton";
import {Grid} from "@mui/material";

type InvoiceListProps = {
  invoices: {transactionId: string}[],
  loading: boolean,
};

/**
 * Renders a list of invoice cards for the admin invoices report.
 *
 * @param invoices The list of invoices to render.
 * @param loading Determines whether to show a loading skeleton instead of the actual invoice cards.
 * @returns InvoiceCard[]
 * @constructor
 */
const InvoiceList: (props: InvoiceListProps) => JSX.Element = ({invoices, loading = false}) => {
  return (<Grid item container direction={'column'}>
      {
        loading ? <InfoCardLoadingSkeleton /> : invoices.map((invoice) => (<InvoiceCard key={invoice.transactionId} invoice={invoice} />))
      }
  </Grid>
  )
};

export default InvoiceList;