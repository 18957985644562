import React from 'react';
import { Box, Checkbox, TableCell, TableRow } from "@mui/material";
import CustomTooltip from './CustomTooltip';


const BuyerAdministrativeTableRow = (props) => {
  const {
    administrativeAccountOwnerRole,
    handleAdministrativeAccountOwnerRoleChange,
    administrativeDispatcherRole,
    handleAdministrativeDispatcherRoleChange,
    administrativeAdminRole,
    handleAdministrativeAdminRoleChange,
  } = props;

  const administrativeBuyerTooltips = 'Receive emails when invoices are created, adjusted, or refunded and when payments succeed or fail.';

  return <>
    <TableRow>
      <TableCell>
        <Box display='flex' alignItems='center'>
          {'Administrative'}
          <CustomTooltip title={administrativeBuyerTooltips} />
        </Box>
      </TableCell>
      <TableCell>
        {<Checkbox
          checked={administrativeAccountOwnerRole}
          onClick={handleAdministrativeAccountOwnerRoleChange}
        />}
      </TableCell>
      <TableCell>
        {<Checkbox
          checked={administrativeDispatcherRole}
          onClick={handleAdministrativeDispatcherRoleChange}
        />}
      </TableCell>
      <TableCell>
        {<Checkbox
          checked={administrativeAdminRole}
          onClick={handleAdministrativeAdminRoleChange}
        />}
      </TableCell>
    </TableRow>
  </>
}

export default BuyerAdministrativeTableRow;
