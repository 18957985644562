import React from 'react';
import BookingDetailsFieldToggle from "./BookingDetailsField/BookingDetailsFieldToggle";
import EquipmentTypeSelect from "./EquipmentTypeSelect";

const BookingDetailsEquipmentTypeSelect = (props: {
  editMode: boolean,
  onToggleEditMode: (value: boolean) => void,
  value: any,
  onChange: () => void,
  options: [],
  className?: Object,
  selectClasses: Object,
  disableBookingChanges: boolean,
  readOnly: boolean
}) => {

  const {
    editMode,
    onToggleEditMode,
    value,
    onChange,
    options,
    className,
    selectClasses,
    disableBookingChanges,
    readOnly
  } = props;

  const handleToggleEdit = (value, event) => {
    if (!readOnly) {
      onToggleEditMode(value, event);
    }
  };

  return (
    <BookingDetailsFieldToggle title={'Vehicle Type'} open={editMode && !readOnly} onChange={handleToggleEdit}
                               value={value}
                               className={className} disableToggle={readOnly}>
      <EquipmentTypeSelect
        selection={value}
        setSelection={onChange}
        assetTypeList={options}
        disableBookingChanges={disableBookingChanges}
        classes={selectClasses}
      />
    </BookingDetailsFieldToggle>
  );
};

export default BookingDetailsEquipmentTypeSelect;