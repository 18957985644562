const styles = {
    container: {
        maxWidth: '97.14em',
        margin: '0 auto'
    },
    sectionPadding: {
        padding: '2em 0em'
    },
    heading:{
        fontSize: '1.43em',
        fontWeight: '600',
        fontFamily: 'Inter',
        lineHeight: '2.28em',
        letterSpacing: '0.01em',
    },
    lpDivider: {
        height: '0.07rem',
        color: '#EEEEEE',
        marginTop: '1em',
    },
}

export default styles;