import React, {useContext} from 'react';
import {SpaceUsageCard} from "@securspace/securspace-ui-kit";
import type {BookedSpaceCalendarView} from "../../../types/BookedSpaceCalendarView";
import { Inventory} from "@mui/icons-material";
import {formatSpaceUsageItem} from "../formatSpaceUsage";
import moment from "moment/moment";
import {AppContext} from "../../../context/app-context";
import {getBuyerOverageDailyReportByDate} from "../../../requests/inventory-requests";
import {withSnackbar} from "../../hocs/withSnackbar";

const SpaceUsageItem = (props: { data: BookedSpaceCalendarView, snackbarShowMessage: () => void, onViewOverageInvoices: () => void}) => {
  const {data, snackbarShowMessage} = props;
  const {user} = useContext(AppContext);
  const baseUrl = user.baseUrl;

  const spaceUsageItem = formatSpaceUsageItem(data, true);
  const {overageInvoices} = spaceUsageItem;
  const links = {"overageInvoices":{ baseUrl: baseUrl + '/api/invoices-by-invoice-number'}};

  const actionItems = [
    {
      icon: <Inventory color={'secondary'}/>,
      label: 'Download Inventory Log',
      onClick: (item) => {
        getBuyerOverageDailyReportByDate(
          user.baseUrl,
          data.locationId,
          data.buyerAccountId,
          moment(item.date).format('YYYY-MM-DD')
        ).then((response) => {
          const {text} = response;
          if (text) {
            const fileName =
              `${user.companyName}_spaceUsage_${item.location}_${moment(item.date).format('YYYY-MM-DD')}.csv`;
            const blob = new Blob([text], {type: 'text/csv'});
            const doc = document.createElement("a");
            doc.download = fileName;
            doc.href = window.URL.createObjectURL(blob);
            const clickEvt = new MouseEvent('click', {
              view: window,
              bubbles: true,
              cancelable: true,
            })
            doc.dispatchEvent(clickEvt);
            doc.remove();
          }
        }).catch(() => {
          snackbarShowMessage(`Error downloading inventory for ${item.date}.`, 'error', 15000);
        });
      }
    }
  ];

  return (
    <SpaceUsageCard
      styles={{mb: '1rem', width: '100%'}}
      data={spaceUsageItem} hasAction actionItems={actionItems}
      links={overageInvoices ? links : null}
      variant='primary'
    />
  );
};

export default withSnackbar(SpaceUsageItem);
