import React, {useCallback, useEffect, useState} from 'react';
import {SpaceUsageCard, Pagination, NoDataMessage} from '@securspace/securspace-ui-kit';
import {requestInventoryReport} from "../../components/daily-activity/InventoryRecordRequests";
import {
  submitInventoryCorrection,
  deleteInventoryCorrection
} from '../../components/inventory-correction/requests/inventory-correction-requests';
import {
  getErrorMessageForStandardResponse,
  getErrorMessageForNonStandardAndStandardResponse
} from "../../util/NetworkErrorUtil";
import InventoryRecordsTable from "../../components/daily-activity/InventoryRecordsTable";
import { Typography, Button, Grid } from "@mui/material";
import { useStyles } from "../booking-payment/style";
import { ArrowBack } from '@mui/icons-material';
import type { BookedSpaceCalendarView } from "../../types/BookedSpaceCalendarView";
import { formatSpaceUsageItem } from "../../components/spaceUsage/formatSpaceUsage";
import type { Account } from "../../types/Account";
import SubmitCorrectionDialog from '../../components/daily-activity/SubmitCorrectionDialog';
import { withSnackbar } from '../../components/hocs/withSnackbar';
import SearchEquipmentType from "./SearchEquipmentType";
import { getComparator, stableSort } from '../../util/Sorting';
import type {GroupedLocationInventoryActivity} from "../../types/GroupedLocationInventoryActivity";

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

const InventoryRecords = (props: { account: Account, inventoryDate: string, spaceUtilizationRecord: BookedSpaceCalendarView, closeInventoryRecords: (event: MouseEvent) => void, snackbarShowMessage: () => void }) => {
  const { account, inventoryDate, spaceUtilizationRecord, closeInventoryRecords, snackbarShowMessage } = props;
  const spaceUsageItem = formatSpaceUsageItem(spaceUtilizationRecord);

  const [isLoading, setIsLoading] = useState(false);
  const [inventoryRecords: GroupedLocationInventoryActivity[], setInventoryRecords] = useState([]);
  const [filteredInventoryRecords: GroupedLocationInventoryActivity[], setFilteredInventoryRecords] = useState([]);
  const [equipmentNumber, setEquipmentNumber] = useState('');
  const [inventoryToSubmitCorrectionFor, setInventoryToSubmitCorrectionFor] = useState();
  const [sort, setSort] = useState('');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage: number, setRowsPerPage] = useState(50);
  const classes = useStyles();
  const emptyRows = filteredInventoryRecords?.length;


  const loadInventory: () => void = useCallback(() => {
    setIsLoading(true);
    requestInventoryReport(account.id, { date: inventoryDate, locationId: spaceUtilizationRecord?.locationId})
      .then((response) => {
        setInventoryRecords(response.body);
      })
      .catch((error) => {
        console.error(getErrorMessageForNonStandardAndStandardResponse(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [account, inventoryDate, spaceUtilizationRecord]);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      loadInventory();
    }
    return () => {
      ignore = true;
    };
  }, [loadInventory]);

  useEffect(() => {
    const filtered: GroupedLocationInventoryActivity[] = inventoryRecords.filter((record) => {
      if (!equipmentNumber) {
        return true;
      }
      let search = equipmentNumber.trim().toLowerCase();
      return record.containerNumber?.toLowerCase().includes(search) ||
        record.chassisNumber?.toLowerCase().includes(search) ||
        record.trailerNumber?.toLowerCase().includes(search) ||
        record.truckLicensePlateNumber?.toLowerCase().includes(search);
    });
    setFilteredInventoryRecords(filtered);
  }, [equipmentNumber, inventoryRecords]);

  const deleteCorrectionRequest = (correctionId) => {
    deleteInventoryCorrection(correctionId)
      .then(() => {
        loadInventory()
        snackbarShowMessage("Successfully retracted Inventory Correction.", "success");
      })
      .catch(err => snackbarShowMessage(getErrorMessageForStandardResponse(err), "error"))
  }

  const submitCorrection = (body) => {
    submitInventoryCorrection(body)
      .then(() => {
        loadInventory()
        snackbarShowMessage('Your correction has been submitted for review', "success");
      })
      .catch(err => snackbarShowMessage(getErrorMessageForStandardResponse(err), "error"));
  }
  const handlePageChange = (_, selectedPage) => {
    setPage(selectedPage);
    scrollToTop();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    scrollToTop();
  };

  const handleCloseSubmitDialog = () => {
    setInventoryToSubmitCorrectionFor(null);
  };

  const handleEquipmentNumberChange: (value: string) => void = useCallback((value) => {
    setEquipmentNumber(value);
  }, []);

  const handleSortChange = (sort, order) => {
    setSort(sort);
    setOrder(order);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredInventoryRecords, getComparator(order, sort)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [sort, page, rowsPerPage, filteredInventoryRecords, order],
  );


  return <Grid container>
    <Grid container gap={2} sx={{ mb: 4, mt: 2, width: '100%' }} justifyContent='space-between'>
      <Grid xs={12} md={6} sx={{
        display: 'flex',
        alignItems: 'center',
      }} item>
        <Button sx={{
          minWidth: '0em',
          padding: '0em',
          mr: 0.4
        }} variant='text' onClick={closeInventoryRecords} >
          <ArrowBack />
        </Button>
        <Typography color={'textPrimary'} component={'h1'} className={classes.heading}>Daily Activity</Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <SearchEquipmentType value={equipmentNumber} onChange={handleEquipmentNumberChange} />
      </Grid>
    </Grid>
    <SpaceUsageCard
      data={spaceUsageItem}
      styles={{ mb: 4, backgroundColor: (theme) => theme.palette.common.white, width: '100%' }}
      elementStyleActive={true}
      variant='secondary'
    />
   {
      emptyRows === 0 && !isLoading ? <Grid item style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
      }}>
        <NoDataMessage
          image="/app-images/person-and-box.svg"
          message="No matching records found."
        />
      </Grid> :
        <>
          <InventoryRecordsTable
            isLoading={isLoading}
            inventoryRecords={visibleRows}
            setInventory={setInventoryToSubmitCorrectionFor}
            deleteCorrection={deleteCorrectionRequest}
            handleSortChange={handleSortChange}
          />
          <Pagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            count={filteredInventoryRecords?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
    }
    <SubmitCorrectionDialog inventory={inventoryToSubmitCorrectionFor} onClose={handleCloseSubmitDialog} submitCorrection={submitCorrection}  snackbarShowMessage={snackbarShowMessage}/>
  </Grid>
}

export default withSnackbar(InventoryRecords);
