import request from '../../util/SuperagentUtils';

export const requestCreateSubscription = (subscription) => {
    return request.post(`/api/partner-subscription`)
        .send(subscription);
}

export const requestGetSubscription = (accountId) => {
    return request.get(`/api/partner-subscription/${accountId}`)
}

export const requestDeletePartnerSubscription = (partnerSubscriptionId) => {
    return request.delete(`/api/partner-subscription/${partnerSubscriptionId}`)
}

export const requestUpdatePartnerSubscription = (partnerSubscriptionId, partnerSubscription) => {
    return request.patch(`/api/partner-subscription/${partnerSubscriptionId}`)
      .send(partnerSubscription);
}

export const getTransactionsByPartnerSubscriptionId = (partnerSubscriptionId) => {
    return request.get(`/api/partner-subscription-transaction?partnerSubscriptionId=${partnerSubscriptionId}`);
}