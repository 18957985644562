import React from 'react'
import { Grid } from '@mui/material'
import { InfoCard, NoDataMessage } from '@securspace/securspace-ui-kit'
import { styles } from '../styles'
import { headerCells } from './data'

const SupplierInvoicesContent = ({
  loading,
  invoices,
  locationId,
  invoiceNumber,
  bookingNumber,
  invoiceType,
  sortBy,
  handleToggle,
  expandedItems
}) => {
  return (
    <Grid container flexDirection='column' mb={4} mt={4} sx={{
      ...(!invoices.length && !loading) && styles.container,
      '& .css-13i4rnv-MuiGrid-root': {
        wordWrap: 'normal',
      },
      '@media(max-width: 39.28em)': {
        '& .css-13i4rnv-MuiGrid-root': {
          top: '4%',
        }
      }

    }}>
      {(!invoices.length && !loading) ? (
        <Grid sx={styles.showMessage}>
          {
            invoiceNumber || locationId || bookingNumber || invoiceType || sortBy ? (
              <NoDataMessage
                image="/app-images/person-and-box.svg"
                message="No matching records found."
              />
            ) : (
              <NoDataMessage
                image="/app-images/no-data-match.png"
                message="No input selected, To view results kindly select the data above"
              />
            )
          }
        </Grid>
      ) : (
        invoices.map((invoice) => (
          <InfoCard
            headCells={headerCells}
            data={invoice}
            hasAction={false}
            key={invoice.id}
            handleToggle={handleToggle}
            collapse={expandedItems[invoice.id]}
          />
        ))
      )}
    </Grid>
  )
}

export default SupplierInvoicesContent
