import React, {useCallback, useState} from 'react';
import type {Account} from "../../types/Account";
import {Button, Grid, Theme, Typography} from "@mui/material";
import PartnerAddFundingSourceDialog from "../PartnerAddFundingSourceDialog";
import PartnerFundingSourcesList from "../PartnerFundingSourcesList";
import RemovePartnerFundingSourceDialog from "../RemovePartnerFundingSourceDialog";
import {withSnackbar} from "../hocs/withSnackbar";
import { makeStyles } from '@mui/styles';
import {requestLoggedInAuthoritySource} from "../../services/session/session-requests";

const useStyles: (theme: Theme) => {
  gap15: CSSStyleSheet,
  addPaymentButton: CSSStyleSheet,
} = makeStyles(() => ({
  gap15: {
    gap: '1.07rem'
  },
  addPaymentButton: {
    textTransform: 'uppercase'
  },
}));

const PartnerPaymentMethods = (props: {
  account: Account,
  handleLogout: () => void,
  handleAccountUpdated: () => void,
  snackbarShowMessage: () => void
}) => {

  const {account, handleLogout, handleAccountUpdated, snackbarShowMessage} = props;
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [showDeletePaymentDialog, setShowDeletePaymentDialog] = useState(false);

  const classes = useStyles();
  const openAddPaymentModal = () => {
    setShowAddPaymentModal(true);
  };

  const RefreshAuthoritySourceAndUser = () => {
    requestLoggedInAuthoritySource().then((resp) => {
      handleAccountUpdated(resp.body);
    }).catch(error => {
      if (error.status === 401) {
        handleLogout();
      } else {
        snackbarShowMessage('An error occurred while refreshing user data', 'error', 10000);
      }
    });
  }

  const handleAddFundingSourceSuccess = () => {
    RefreshAuthoritySourceAndUser();
    setShowAddPaymentModal(false);
  };

  const handleCloseRemovePaymentDialog = () => {
    setShowDeletePaymentDialog(false);
  };

  const handlePaymentRemove = () => {
    RefreshAuthoritySourceAndUser();
    setShowDeletePaymentDialog(false);
    snackbarShowMessage('Funding Source removed successfully', 'success', 8000);
  };

  const handlePaymentMenuAction = useCallback((actionName: string) => {
    switch (actionName) {
      case 'remove':
        setShowDeletePaymentDialog(true);
        break;
      default:
        break;
    }
  }, [setShowDeletePaymentDialog]);

  return (
    <Grid container direction={'column'} className={classes.gap15}>
      <Grid item container justifyContent={'space-between'} className={classes.gap15}>
        <Typography variant={'h5'} component={'h1'}>Payout Bank Account</Typography>
        <Button
          onClick={openAddPaymentModal}
          className={classes.addPaymentButton}
          color='secondary'
          variant='contained'
        >
          Change Bank Account
        </Button>
      </Grid>
      <PartnerFundingSourcesList
        account={account}
        onMenuAction={handlePaymentMenuAction}
      />
      <PartnerAddFundingSourceDialog
        account={account}
        handleLogout={handleLogout}
        open={showAddPaymentModal}
        onClose={() => setShowAddPaymentModal(false)}
        onSuccess={handleAddFundingSourceSuccess}
      />
      <RemovePartnerFundingSourceDialog
        open={showDeletePaymentDialog}
        onClose={handleCloseRemovePaymentDialog}
        handleLogout={handleLogout}
        account={account}
        onSuccess={handlePaymentRemove}
      />
    </Grid>
  );
};

export default withSnackbar(PartnerPaymentMethods);