import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    rowGap4: {
        rowGap: '0.29em',
    }
}));

const SimpleInfoPanel = (props) => {
    const { title, content, children, ...remainingProps } = props;
    const classes = useStyles();

    return (
        <Paper {...remainingProps} component='section'>
            <Grid container direction='column' className={classes.rowGap4}>
                {title && <Typography variant='h6' color='textPrimary'>{title}</Typography>}
                {content}
            </Grid>
            {children}
        </Paper>
    );
};

SimpleInfoPanel.propTypes = {
    title: PropTypes.string,
    content: PropTypes.node,
};

export default SimpleInfoPanel;