import React from 'react';
import Select from "../Select";
import {PartnerSubscriptionTypes} from "../constants/securspace-constants";

const SubscriptionSelectForm = (props) => {
  const {handleChange, subscriptionType} = props;

  return <>
    <fieldset className="ss-subscription ss-stand-alone">
      <label htmlFor="subscriptionType">SUBSCRIPTION TYPE</label>
      <Select id="subscriptionType"
              name="subscriptionType"
              optionsWidth="300px"
              handleChange={handleChange}
              selectedOption={subscriptionType}
              placeholder="Choose"
              options={[
                PartnerSubscriptionTypes.NO_SUBSCRIPTION,
                PartnerSubscriptionTypes.AGS_FEE,
              ]}
      />
    </fieldset>
  </>
}

export default SubscriptionSelectForm;
