export default {
    OPTIONS: [
        "Manned Security",
        "Fenced / Gated",
        "Yard Cameras",
        "Gate Cameras",
        "Lights",
        "Paved",
        "M & R Offered",
        "Side-pick / Top-Pick",
        "Daily Inventory Report",
        "24/7 Access",
        "Overnight Truck Parking",
        "Restrooms"
    ]
};