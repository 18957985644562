import React from "react";
import {TableRow, TableCell, Typography} from "@mui/material";
import {formatPhoneNumber} from "../../../util/ValidationUtils";
import {UserRoleToTypeName} from "../../constants/securspace-constants";
import UsersTableActionsMenu from "./UsersTableActionsMenu";
import PropTypes from "prop-types";

const UsersTableRow = ({user, handleEdit, classes, onDeleteUser, setUserToDelete, index}) => {
    return <TableRow>
    <TableCell>
        <Typography variant={'body2'} color={'textPrimary'}>{user.username}</Typography>
    </TableCell>
    <TableCell>
        <Typography
          variant={'body2'}
          color={'textPrimary'}
        >
            {`${user.firstName || ''} ${user.lastName || ''}`}
        </Typography>
    </TableCell>
    <TableCell>
        <Typography variant={'body2'} color={'textPrimary'}>{UserRoleToTypeName[user.role]}</Typography>
    </TableCell>
    <TableCell>
        <Typography variant={'body2'} color={'textPrimary'}>{formatPhoneNumber(user.phoneNumber)}</Typography>
    </TableCell>
    <UsersTableActionsMenu
        index={index}
        user={user}
        handleEdit={handleEdit} 
        classes={classes} 
        onDeleteUser={onDeleteUser}
        setUserToDelete={setUserToDelete}
    />
    </TableRow>
};

UsersTableRow.propTypes = {
    classes: PropTypes.object.isRequired,
    handleEdit: PropTypes.func.isRequired,
    onDeleteUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

export default UsersTableRow;
