import React, {JSX} from 'react';
import '../css/views/navMenu.css';
import '../css/components/subMenu.css';
import _ from 'underscore'
import Badge from "../views/Badge";
import type {Nav} from "../routing/nav/navs";
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';
import classNames from "classnames";
import type {NavMenuItem} from "../views/nav-menu/NavMenuSubmenuListItem";
import {ListItemText} from "@mui/material";

export type SubMenuProps = {
  id: string,
  activeSubMenuItemPath: string,
  anchorEl: null | HTMLElement,
  buyerSpaceUsageRedesign: boolean,
  handleLogout: (event) => void,
  menuItem: NavMenuItem,
  menuItemIndex: number,
  menuTotalItems: number,
  onSubMenuItemClick: (path: string) => void,
  pendingApprovalCount: number,
  pendingInventoryCount: number,
  show: boolean,
  onClose: (event) => void,
  MenuListProps: Object
};

const SubMenu: (props: SubMenuProps) => JSX.Element = (props) => {
  return (
    <Menu id={props.id} open={props.show} onClose={props.onClose} anchorEl={props.anchorEl} MenuListProps={props.MenuListProps}>
      {
        _.map(props.menuItem.submenus, (subMenuItem: Nav) =>
          <MenuItem
            key={subMenuItem.path}
            onClick={() => props.onSubMenuItemClick(subMenuItem.path)}
            className={classNames("ss-nav-drop-down-menu", props.activeSubMenuItemPath === subMenuItem.path ? "ss-drop-down-active" : "ss-dropw-down-inactive")}
          >
            {
              <ListItemText>
                  <span className={subMenuItem.highlighted ? "ss-nav-menu-item-highlighted" : ""}>
                    {props.buyerSpaceUsageRedesign && subMenuItem.label === 'Booked Space Calendar'
                      ? 'Space Usage' : subMenuItem.label}
                  </span>
              </ListItemText>
            }
            {
              subMenuItem.badge && subMenuItem.path === 'approvals' && !!props.pendingApprovalCount
              && props.pendingApprovalCount > 0 &&
              <Badge
                isPadding
                static
                pendingBookings={props.pendingApprovalCount}
              />
            }
            {
              subMenuItem.badge &&
              (subMenuItem.path === 'inventory-corrections' || subMenuItem.path === 'buyer-inventory-corrections') &&
              !!props.pendingInventoryCount && props.pendingInventoryCount > 0 &&
              <Badge
                pendingBookings={props.pendingInventoryCount}
                isPadding={true}
                static
              />
            }
          </MenuItem>
        )
      }
    </Menu>
  );
}

export default SubMenu;
