import React from 'react';
import {Dialog, DialogContent, DialogActions, DialogTitle, Grid, Button, FormLabel, Typography} from "@mui/material";
import { formatCurrencyValue } from '../../../util/PaymentUtils';
import {useFlags} from "launchdarkly-react-client-sdk";


const styles ={
  review_title: (theme) =>{
      return {
          [theme.breakpoints.down('sm')]: {
              fontSize: '1em'
          }
      }
  },
  tableAlignment:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center',
  }
}


const ReviewPaymentAmounts = ({calculateCostObject, bookingToComplete, completeBooking, open, onClose, classes}) => {

  const taxAmountFlag = useFlags()?.collectTax;

  const {
    initialBookingPaymentProcessingFee,
    initialBookingChargeAmount,
    recurringBookingPaymentProcessingFee,
    recurringBookingChargeAmount,
    recurringBookingPaymentAmount,
    initialBookingPaymentAmount,
    firstRecurringPaymentDate,
    initialBookingTaxAmount,
    recurringBookingTaxAmount,
    lastRecurringPaymentDate
  } = calculateCostObject;

  const {totalNumberOfPayments} = bookingToComplete;

  const recurringBooking = bookingToComplete.frequency === 'RECURRING';

  const durationTypeLabel = bookingToComplete.durationType === 'WEEKLY' ? "Weekly" : "Monthly";

        return (
            <Dialog sx={{
              '& .MuiDialog-paper': {
                width:'30em'
              }
            }}  open={open} onClose={onClose}>
                <DialogTitle>Review Payment Details</DialogTitle>
                    <DialogContent id="reviewPaymentAmounts">
                        <FormLabel className="ss-summary">
                            3. Review
                            Payment Details
                        </FormLabel>
                        <Grid container className={classes.topMargin}>
                            {
                                initialBookingChargeAmount === 'Calculating....' ?
                                    <Grid item>
                                        Calculating Payment Info...
                                    </Grid>
                                    :
                                    recurringBooking ?
                                        <Grid container>
                                            <Grid container flexDirection={"column"}>
                                                <Grid container sx={styles.tableAlignment} columnGap={2}>
                                                    <Typography sx={styles.review_title}>Initial Payment: </Typography>
                                                    <Typography>{initialBookingPaymentAmount === 0 ? "$0"
                                                        : formatCurrencyValue(initialBookingPaymentAmount)}</Typography>
                                                </Grid>
                                                <Grid container sx={styles.tableAlignment} columnGap={2}>
                                                    <Typography sx={styles.review_title}>Credit Card Processing Fee: </Typography>
                                                    <Typography>{initialBookingPaymentProcessingFee === 0 ? "$0"
                                                        : formatCurrencyValue(initialBookingPaymentProcessingFee)}</Typography>
                                                </Grid>
                                              {taxAmountFlag &&
                                                <Grid container sx={styles.tableAlignment} columnGap={2}>
                                                  <Typography sx={styles.review_title}>Taxes: </Typography>
                                                  <Typography>{initialBookingTaxAmount === 0 ? "$0"
                                                    : formatCurrencyValue(initialBookingTaxAmount)}</Typography>
                                                </Grid>
                                              }
                                                <Grid container sx={styles.tableAlignment}  columnGap={2}>
                                                    <Typography sx={styles.review_title} fontWeight={600}>{totalNumberOfPayments && totalNumberOfPayments > 0 ? 'Due Now:'
                                                        : 'Total Cost:'}
                                                        </Typography>
                                                        <Typography fontWeight={600}>{initialBookingChargeAmount === 0 ? "$0"
                                                            : formatCurrencyValue(initialBookingChargeAmount)}
                                                        </Typography>
                                                </Grid>
                                            </Grid>
                                            {
                                                totalNumberOfPayments > 0 ?
                                                <Grid container>
                                                    <Grid container spacing={2}>
                                                        <Grid item sx={styles.tableAlignment} >
                                                            <Typography sx={styles.review_title}>Number Of Payments:</Typography>
                                                            <Typography>{totalNumberOfPayments}</Typography>
                                                        </Grid>

                                                        <Grid item sx={styles.tableAlignment}>
                                                            <Typography sx={styles.review_title}>First Payment Date:</Typography>
                                                            <Typography>{firstRecurringPaymentDate}</Typography>
                                                        </Grid>

                                                        <Grid item sx={styles.tableAlignment}>
                                                            <Typography sx={styles.review_title}>Last Payment Date:</Typography>
                                                            <Typography>{lastRecurringPaymentDate}</Typography>
                                                        </Grid>
                                                    </Grid>

                                                        <Grid container flexDirection={"column"}>
                                                            <Grid container sx={styles.tableAlignment} columnGap={2}>
                                                                <Typography sx={styles.review_title}>{`${durationTypeLabel} Payment:`}</Typography>
                                                                <Typography>{recurringBookingPaymentAmount === 0 ? "$0"
                                                                    : formatCurrencyValue(recurringBookingPaymentAmount)}</Typography>
                                                            </Grid>
                                                            <Grid container sx={styles.tableAlignment} columnGap={2}>
                                                                <Typography sx={styles.review_title}>Credit Card Processing Fee: </Typography>
                                                                <Typography>{recurringBookingPaymentProcessingFee === 0 ? "$0"
                                                                    : formatCurrencyValue(recurringBookingPaymentProcessingFee)}</Typography>
                                                            </Grid>
                                                          {taxAmountFlag &&
                                                            <Grid container sx={styles.tableAlignment}  columnGap={2}>
                                                              <Typography sx={styles.review_title}>Taxes: </Typography>
                                                              <Typography>{recurringBookingTaxAmount === 0 ? "$0"
                                                                : formatCurrencyValue(recurringBookingTaxAmount)}</Typography>
                                                            </Grid>
                                                          }
                                                            <Grid container sx={styles.tableAlignment}  columnGap={2}>
                                                                <Typography sx={styles.review_title} fontWeight={600}>{`Total ${durationTypeLabel} Cost:`}</Typography>
                                                                <Typography fontWeight={600}>{recurringBookingChargeAmount === 0 ? "$0"
                                                                    : formatCurrencyValue(recurringBookingChargeAmount)}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    ''
                                            }
                                        </Grid>
                                        :
                                        <Grid container flexDirection={"column"}>
                                            <Grid container sx={styles.tableAlignment} columnGap={2}>
                                                <Typography sx={styles.review_title}>One-Time Payment:</Typography>
                                                <Typography>{initialBookingPaymentAmount === 0 ? "$0"
                                                    : formatCurrencyValue(initialBookingPaymentAmount)}</Typography>
                                            </Grid>
                                            <Grid container sx={styles.tableAlignment} columnGap={2}>
                                                <Typography sx={styles.review_title}>Credit Card Processing Fee:</Typography>
                                                <Typography>{initialBookingPaymentProcessingFee === 0 ? "$0"
                                                    : formatCurrencyValue(initialBookingPaymentProcessingFee)}</Typography>
                                            </Grid>
                                          {taxAmountFlag &&
                                            <Grid container sx={styles.tableAlignment} columnGap={2}>
                                              <Typography sx={styles.review_title}>Taxes:</Typography>
                                              <Typography>{initialBookingTaxAmount === 0 ? "$0"
                                                : formatCurrencyValue(initialBookingTaxAmount)}</Typography>
                                            </Grid>
                                          }
                                            <Grid container sx={styles.tableAlignment} columnGap={2}>
                                                <Typography  sx={styles.review_title} fontWeight={600}>Total Cost:</Typography>
                                                <Typography fontWeight={600}>{initialBookingChargeAmount === 0 ? "$0"
                                                    : formatCurrencyValue(initialBookingChargeAmount)}</Typography>
                                            </Grid>
                                        </Grid>
                            }
                        </Grid>
                    </DialogContent>
                <DialogActions className="modal-footer">
                    <Grid container justifyContent="center">
                        <Button variant="text" onClick={onClose} >
                            Cancel
                        </Button>
                        <Button variant="contained"
                                onClick={completeBooking}>Complete Booking
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>

  )
}

export default ReviewPaymentAmounts;
