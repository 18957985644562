export const searchRecordsByKeyword = (keyword, records) => {
  const filtered = [];
  if (keyword) {
    for (const record of records) {
      for (const element of Object.values(record)) {
        if (element?.toString().toLowerCase().includes(keyword.toLowerCase().trim())) {
          filtered.push(record);
          break;
        }
      }
    }
    return filtered;
  }
  return records;
}

export const getSortFields = (exampleObject) => {

  let fieldList = [];

  const properties = Object.keys(exampleObject);

  const formatLabel = (camelCaseProperty) => {
    const camelCase = camelCaseProperty.replace(/([a-z])([A-Z])/g, '$1 $2').split(" ")

    let flat = ""

    camelCase.forEach(word=>{    
        flat = flat + word.charAt(0).toUpperCase() + word.slice(1) + " "
    })  
    return flat;
  }

  properties.map(property => fieldList.push({value: property, label: formatLabel(property)}));

  return fieldList;
}

