import React from 'react';
import '../../css/components/report/report-flex-right-container.css';

const ReportFlexRightContainer = ({children}) => {
  return <div className="ss-report-flex-right">
    {children}
  </div>
}

export default ReportFlexRightContainer;
