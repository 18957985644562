import React from 'react';
import {Button, ButtonGroup, Grid, Theme, Tooltip} from "@mui/material";
import classNames from "classnames";
import {makeStyles} from "@mui/styles";
import {FC} from "react";

const useStyles: (theme: Theme) => {
  buttonGroup: CSSStyleSheet,
  button: CSSStyleSheet,
  buttonPadding: CSSStyleSheet,
} = makeStyles(theme => ({
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    background: "#fff",
    borderRadius: 8,
    marginBottom: 5,
  },
  button: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      minWidth: '10.71em',
    }
  },
  buttonPadding: {
    padding: 6,
    width: "100%",
    display: "flex",
    gap: 10,
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap'
    }
  },
}));

type ButtonSwitchProps = {
  // If the primary option selected
  primarySelected: boolean,
  onChange: (value: boolean) => void,
  // The labels for the primary and secondary options
  optionLabels?: [string, string],
  // If the secondary option should be disabled
  disableSecondary?: boolean,
  // The tool tip label to show when the secondary option is disabled
  disabledSecondaryLabel?: boolean,
};

const ButtonSwitch: FC<ButtonSwitchProps> = ({
                                               primarySelected = true,
                                               onChange,
                                               optionLabels = ["On", "Off"],
                                               disableSecondary = false,
                                               disabledSecondaryLabel = ""
                                             }: ButtonSwitchProps) => {
  const classes = useStyles();

  return (
    <ButtonGroup aria-label="outlined primary button group" className={classes.buttonGroup}>
      <Grid className={classes.buttonPadding}>
        <Button
          className={classNames(classes.button, `d-flex ss-tab-btn`)}
          onClick={() => onChange(true)}
          variant={primarySelected ? "contained" : "outlined"}
          color={primarySelected ? "primary" : "secondary"}
        >
          {optionLabels[0] || ""}
        </Button>
        {disableSecondary ? (
          <Tooltip title={disabledSecondaryLabel} placement="top" arrow>
            <span>
              <Button className={classNames(classes.button, `d-flex ss-tab-btn`)} disabled>
                {optionLabels[1] || ""}
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Button
            className={classNames(classes.button, `d-flex ss-tab-btn`)}
            onClick={() => onChange(false)}
            variant={!primarySelected ? "contained" : "outlined"}
            color={!primarySelected ? "primary" : "secondary"}
          >
            {optionLabels[1] || ""}
          </Button>
        )}
      </Grid>
    </ButtonGroup>
  )
};

export default ButtonSwitch;