import React from "react";
import { Grid } from "@mui/material";
import BuyerSpaceUsageMap from "./BuyerSpaceUsageMap";
import { Pagination } from "@securspace/securspace-ui-kit";

const BuyerSpaceUsageWithPagination = ({spaceUsageData, classes, account, size, page, totalNumberResults, handlePageChange, handleSizeChange, setInventoryRecordToShow }) => {

    return <Grid container sx={{
        width:'100%',
    }}>
        <Grid item sx={{
            width:'100%'
        }}>
            <BuyerSpaceUsageMap spaceUsageList={spaceUsageData} classes={classes} account={account} handleViewInventory={setInventoryRecordToShow} />
        </Grid>
        <Grid sx={{
            display:'flex',
            justifyContent:'flex-end',
            alignItems:'flex-end',
            width:'100%',
            mt:4
        }} item>
            <Pagination count={totalNumberResults} rowsPerPage={size} page={page} onPageChange={handlePageChange} onRowsPerPageChange={handleSizeChange} variant="tablePagination" />
        </Grid>
    </Grid>
}

export default BuyerSpaceUsageWithPagination
