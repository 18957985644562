import React, { useState } from 'react';
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { SocialLoginContext } from "../context/social-login-context";
import SecurSpaceContent from "./SecurSpaceContent";
import GlobalModalManager from "../components/modals/GlobalModalManager";
import ExternalContent from './content-routes/ExternalContent';
import { externalRoutePathnames } from "../components/constants/securspace-constants";

const SecurSpaceRouterContent = () => {
  const location = useLocation();
  const isExternalRoute = externalRoutePathnames.includes(location.pathname);

  return (
    <GlobalModalManager>
      {isExternalRoute ? <ExternalContent /> : <SecurSpaceContent />}
    </GlobalModalManager>
  );
};

const SecurSpaceRouter = () => {
  const [socialLogin, setSocialLogin] = useState({ user: null });

  const contextValue = {
    socialLogin,
    setSocialLoginUser: (user) => setSocialLogin({ user: user }),
    clearSocialLoginUser: () => setSocialLogin({ user: null })
  };

  return (
    <SocialLoginContext.Provider value={contextValue}>
      <Router>
        <SecurSpaceRouterContent />
      </Router>
    </SocialLoginContext.Provider>
  );
};

export default SecurSpaceRouter;
