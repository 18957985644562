import React, { useState } from 'react';
import { Grid, Typography, TextField, InputAdornment, Button } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import BlogCard from '../BlogCard';
import SkeletonBlog from '../SkeletonBlog';
import { useStylesBlog } from './style'
import SuperAgent from 'superagent'
import Busy from '../../Busy';

export function SubscribeEmail({
    email,
    setEmail
}) {
    const classes = useStylesBlog();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    function validateEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }


    const handleSubcribe = async () => {
        if (!email) return setErrorMessage(`Please enter your email address`)
        if (!validateEmail(email)) return setErrorMessage('Please enter a valid email address');
        Busy.set(true);
        setErrorMessage('')
        try {
            await SuperAgent.post(`api/blog/subscriptions`).send({
                email: email.toLowerCase()
            });
            Busy.set(false);
            setEmail("")
            setErrorMessage('')
            setSuccessMessage('Thank you for subscribing to our blog!')
        } catch (error) {
            Busy.set(false);
            const resBody = error?.response?.body
            const errMsg = resBody?.message || 'Something went wrong, please try again later!'
            setErrorMessage(errMsg)
        }
    }

    return (
        <Grid className={classes.box}>
            <Typography className={classes.subTitle}>Subscribe to our blog</Typography>
            <TextField
                type='email'
                InputLabelProps={{
                    shrink: true,
                }}
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value)
                }}
                placeholder='yourmail@example.com'
                className='ss-blog-sub-input'
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <img src="https://static.secur.space/icons/subemail.png" alt="Icon" className={classes.icon} />
                        </InputAdornment>
                    ),
                }}
                fullWidth
                label="Email"
                variant="standard"
            />
            {errorMessage && !successMessage && <Typography className={classes.error} variant='subtitle2' color='error'>{errorMessage}</Typography>}
            {!errorMessage && successMessage && <Typography className={classes.success} variant='subtitle2'>{successMessage}</Typography>}
            <Button onClick={handleSubcribe} fullWidth className={classes.button}>SUBSCRIBE</Button>
        </Grid>
    )
}



const BlogSection = ({
    loading,
    blogs,
    search,
    setSearch,
    email,
    setEmail, setOffset, totalPage
}) => {
    const classes = useStylesBlog();


    const paginationHandler = (_, value) => {
        setOffset(parseInt(value) === 0 ? 0 : value - 1)

    }

    return (
        <section className={classes.sectionPadding}>
            <Grid className={classes.root}>
                <Typography className={classes.bgTitle}>SecurSpace Blog</Typography>
                <Typography className={classes.bgSubTitle}>It's simple and secure</Typography>
            </Grid>
            <div className='ss-blog-container'>
                <Grid container wrap='wrap' className={classes.container}>
                    <Grid item sm={9} md={9}>
                        <Grid container className={classes.gap}>
                            {loading ? [...Array(12).keys()].map((_, index) => <SkeletonBlog key={index} />) : blogs?.map((item) => <BlogCard key={item?.id} {...item} />)}
                        </Grid>
                    </Grid>
                    <Grid item className={classes.subcribe} sm={3} md={3}>
                        <TextField
                            type='text'
                            value={search}
                            onChange={(e) => { setSearch(e.target.value); setOffset(0) }}
                            placeholder='Search'
                            className='ss-blog-input'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <img src="https://static.secur.space/icons/search.png" alt="Icon" className={classes.icon} />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            variant="standard"
                        />
                        <SubscribeEmail email={email} setEmail={setEmail} />
                    </Grid>
                </Grid>
                <Pagination className={classes.pagination} onChange={paginationHandler} count={totalPage} variant="outlined" color='primary' />
            </div>
        </section>
    )
}

export default BlogSection
