import React from 'react';
import LocationProfileCard from './LocationProfileCard';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import RecentlyBookedCarouselSkeleton from '../../../skeleton/RecentlyBookedCarouselSkeleton';
import SwiperCarousel from '../features/SwiperCarousel';
import { SwiperSlide } from "swiper/react";

const useStyles = makeStyles(() => ({
    container: {
        overflowX: 'auto',
        paddingBottom: '1rem', // To make bottom shadow appear better
    },
    main: {
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '.4em',
        width: '20em'
    }
}));

const LocationProfileCardList = (props) => {
    const { locations, loading } = props;

    const classes = useStyles();

    return (
        <Grid
            container
            className={classNames('ss-recent-booked-locations', classes.container)}
        >
            <SwiperCarousel isNextDisabled={true}>
                {loading ? [...Array(4).keys()].map((_, index) => <SwiperSlide key={index} className={classes.main}>
                    <RecentlyBookedCarouselSkeleton />
                </SwiperSlide>) : locations?.map((location) => (
                    <SwiperSlide key={location?.locationId} className={classes.main}>
                        <LocationProfileCard
                            key={location.locationId}
                            listingImageFileName={location.listingImageFileName}
                            pricePerDay={location.pricePerDay}
                            pricePerMonth={location.pricePerMonth}
                            instantApproval={location.managedSpaces > 0}
                            locationName={location.locationName}
                            cityName={location.city}
                            distanceTo={location.distance}
                            id={location.locationId}
                        />
                    </SwiperSlide>
                ))}
            </SwiperCarousel>
        </Grid>
    );
};

export default LocationProfileCardList;
