import React from 'react';
import PropTypes from 'prop-types';
import SimpleInfoPanel from '../SimpleInfoPanel';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AccountType } from '../constants/securspace-constants';
import classNames from 'classnames';
import { formatPhoneNumber } from '../../util/ValidationUtils';
import BookSpaceInfoPanelContent from '../location/profile/BookSpaceInfoPanelContent';
import {formatCurrencyValue} from "../../util/PaymentUtils";

const useStyles = makeStyles(theme => ({
  adminInfoPanel: {
    padding: '1.43rem',
    backgroundColor: '#FFF6D8',
    borderRadius: '0.57rem',
  },
  fontColorBlack: {
    color: theme.palette.common.black,
  },
  baseContentMargin: {
    marginTop: '0.86rem',
  }
}));

const LOGGED_IN_USER_TYPE = Object.freeze({
  [AccountType.BUYER]: "Customer",
  [AccountType.SUPPLIER]: "Partner",
});

const AdminInfoPanelContent = ({ location }) => {

  const panelData = [
    {
      label: 'Distance',
      value: `${location?.distance} mi` || '',
    },
    {
      label: 'Partner Name',
      value: location?.companyName || '',
    },
    {
      label: 'Partner Email',
      value: location?.companyEmail || '',
    },
    {
      label: 'Partner Phone',
      value: formatPhoneNumber(location?.companyPhoneNumber) || '',
    },
    {
      label: 'Location Phone',
      value: formatPhoneNumber(location?.locationPhoneNumber) || '',
    },
    {
      label: 'Location Manager Name',
      value: location?.locationManagerName || '',
    },
    {
      label: 'Location Manager Phone',
      value: formatPhoneNumber(location?.locationManagerPhone) || '',
    },
    {
      label: 'Location Manager email',
      value: location?.locationManagerEmail || '',
    },
    {
      label: 'Max Spaces Booked',
      value: location?.maxSpacesBooked || '',
    },
    {
      label: 'Auto-Approve Spaces',
      value: location?.managedSpaces || '',
    },
    {
      label: 'Total Spaces',
      value: location?.totalNumberOfSpaces || '',
    },
    {
      label: 'Min Booking Spaces',
      value: location?.minNumberOfSpaces || '',
    },
    {
      label: 'Min Booking Duration',
      value: location?.minDuration || '',
    },
    {
      label: 'Overage Rate',
      value: formatCurrencyValue(location?.overageRate , true),
    },
    {
      label: 'Current Inventory',
      value: location?.currentInventoryCount || '0',
    },
    {
      label: 'Default Commission',
      value: location?.commissionRate || '',
    },
  ];


  return <BookSpaceInfoPanelContent data={panelData} />;
};

const AdminInfoPanel = ({ user, location }) => {
  const classes = useStyles();

  const loggedInUserTitle = LOGGED_IN_USER_TYPE[user.type] ? `(${LOGGED_IN_USER_TYPE[user.type]}) ${user.companyName}` : user.companyName;

  return (
    <SimpleInfoPanel
      title='Admin Info'
      content={<AdminInfoPanelContent location={location} />}
      className={classes.adminInfoPanel}
    >
      <Typography
        variant='subtitle2'
        className={classNames(classes.fontColorBlack, classes.baseContentMargin)}
      >
        CURRENTLY LOGGED IN AS: {loggedInUserTitle}
      </Typography>
    </SimpleInfoPanel>
  );
};

AdminInfoPanel.propTypes = {
  user: PropTypes.object.isRequired,
  location: PropTypes.object
};

export default AdminInfoPanel;
