import React, { useMemo, useState } from 'react';
import {
  constructGalleryImageUrl,
  constructListingImageUrl
} from '../../../util/ConstructImageUrlUtil';
import { Grid, Skeleton } from '@mui/material';
import './style.css'
import SecurSpaceModal from '../../common/SecurSpaceModal';
import { AiOutlineClose } from 'react-icons/ai'
import Image from '../../skeleton/ImageComponent';
import { Navigation, Pagination, HashNavigation } from 'swiper/modules';
import { useStyles } from './carouselStyle'
import MainCarousel from './MainCarousel';


const LocationImageGallery = ({ locationId, listingImage, gallery }) => {
  const listingImageUrl = listingImage ? constructListingImageUrl(listingImage) : null;
  const galleryImagesUrls = gallery.map((item) => constructGalleryImageUrl(`${locationId}/${item}`));
  // create an items array that has listingImageUrl as the first item only when it's not null and galleryImagesUrls as the rest of the items
  const items = listingImageUrl ? [listingImageUrl, ...galleryImagesUrls] : [...galleryImagesUrls];
  const galleryLength = useMemo(() => items.length, [items])
  const multipleImage = useMemo(() => items.length > 1, [items])
  const carouselLength = useMemo(() => items.length > 0, [items])
  const [isCarouselOpen, setIsCarouselOpen] = useState(false)
  const classes = useStyles();


  const openModal = () => {
    setIsCarouselOpen(!isCarouselOpen)
  }

  return (
    <>
      {
        !isCarouselOpen &&
        <Grid item className={classes.relative}>
          {items.length > 1 &&
            <button onClick={openModal} className={classes.seeButton} >
              See All Photos
            </button>}
          {carouselLength ? <MainCarousel
            loop={true}
            slidesPerView={1}
            navigation={items.length > 1}
            hashNavigation={{
              watchState: true,
            }}
            modules={[HashNavigation, Navigation]}
            className={classes.smallSide}
            itemData={items}
          /> : <Image
            errorImg='/app-images/not-found-image/place_holder_image.png'
            placeHolderimg="/app-images/loading/image-loader.gif"
            className={`ss-gallery-img ${items.length === 0 && "ss-image-container"}`}
            src={items[0]}
            alt="gallery-item"
            height="100%"
            width="100%"
          />}

        </Grid>
      }
      <Grid className='ss-location-gallery-container ss-full-width ss-dflex ss-image-gap'>
        <Grid className={`${multipleImage ? "ss-first-multiple-image" : "ss-full-width"} ss-full-height ${multipleImage ? " ss-first-image-radius-single" : "ss-first-image-radius-all"} ss-relative ss-overflow-hidden`}>
          <Image
            errorImg='/app-images/not-found-image/place_holder_image.png'
            placeHolderimg="/app-images/loading/image-loader.gif"
            className={`ss-gallery-img ${items.length === 0 && "ss-image-container"}`}
            src={items[0]}
            alt="gallery-item"
            height="100%"
            width="100%"
          />

          {items.length !== 0 ?
            <button onClick={openModal} className='ss-absolute-bottom' >
              See All Photos
            </button> : <Skeleton width="60%" />}
        </Grid>

        {multipleImage && <Grid className="ss-dflex ss-flex-1 ss-flex-col ss-image-gap ss-full-height ss-overflow-hidden " >
          <Grid className={`${galleryLength === 2 ? "ss-full-height  " : "ss-half-height"} ss-full-width ss-dflex ss-image-gap`}>

            <Grid className={`${galleryLength > 1 && galleryLength < 4 ? "ss-full-width ss-second-full-radius" : "ss-half-width"} ss-full-height ss-overflow-hidden`}>
              <img className='ss-gallery-img' src={items[1]} alt="gallery-item" height="100%" width="100%" />
            </Grid>

            {galleryLength >= 4 &&
              <Grid className='ss-half-width ss-full-height ss-overflow-hidden ss-second-first-radius '>
                <img className='ss-gallery-img' src={items[3]} alt="gallery-item" height="100%" width="100%" />
              </Grid>
            }

          </Grid>
          {galleryLength > 2 &&
            <Grid className={`${galleryLength === 3 ? "ss-full-height" : "ss-half-height"} ss-full-width ss-dflex ss-image-gap ss-overflow-hidden`}  >

              <Grid className={`${galleryLength < 5 ? "ss-full-width ss-second-last-radius" : "ss-half-width"} ss-overflow-hidden ss-full-height`}>
                <img className='ss-gallery-img' src={items[2]} alt="gallery-item" height="100%" width="100%" />
              </Grid>
              {galleryLength >= 5 &&
                <Grid className='ss-half-width ss-full-height ss-overflow-hidden ss-relative ss-second-last-radius'>
                  <img className='ss-gallery-img' src={items[4]} alt="gallery-item" height="100%" width="100%" />
                  {galleryLength > 5 && <div onClick={openModal} className='ss-absolute-full' >+{galleryLength - 5} Images</div>}
                </Grid>
              }
            </Grid>
          }

        </Grid>}

      </Grid>
      <SecurSpaceModal overlayClassName="ss-modal-center" className="ss-gallery-modal" isOpen={isCarouselOpen}>
        <div className='ss-gallery-modal-container'>
          <button onClick={openModal} className='ss-modal-close-btn' >
            <AiOutlineClose />
          </button>
          {
            carouselLength && <MainCarousel
              loop={true}
              navigation={true}
              slidesPerView={1}
              hashNavigation={{
                watchState: true,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                400: {
                  slidesPerView: 1,
                  autoplay: false,
                },
              }}
              modules={[HashNavigation, Navigation, Pagination]}
              className={classes.mainSlider}
              itemData={items}
            />
          }
        </div>
      </SecurSpaceModal>
    </>
  )
}

export default LocationImageGallery;
