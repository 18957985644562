import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

// Scrolls to top every page navigation
const useScrollToTop = () => {
    const location = useLocation();

    useLayoutEffect(() => {
        // Don't interfer with routes with with hashes
        if (!location.hash) {
            window.scroll(0, 0);
        }
    }, [location]);
    
    return null;
};

export default useScrollToTop;