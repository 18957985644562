import React, {useState} from "react";
import {Grid, Typography} from "@mui/material";
import { makeStyles } from '@mui/styles';
import BookingPaymentBeforeYouBook from "./BookingPaymentBeforeYouBook";
import RequestBookSpaceButton from "./RequestBookSpaceButton";
import SimpleConfirmationDialog from "../../SimpleConfirmationDialog";
import {sendPurchaseEvent} from "../../../util/GoogleTagManagerUtil";
import {bookSpace} from "../../../views/requests/booking-requests";
import Busy from "../../Busy";
import {Theme} from "@mui/material";
import {withSnackbar} from "../../hocs/withSnackbar";
import {getErrorMessageForStandardResponse} from "../../../util/NetworkErrorUtil";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const useStyles: (theme: Theme) => {
  confirmationBodyPadding: CSSStyleSheet,
} = makeStyles(() => ({
  confirmationBodyPadding: {
    paddingBottom: '0.86rem',
  },
}));

const RequestSpace = (props) => {
  const [showConfirmBookingDialog, setShowConfirmBookingDialog] = useState(false);
  const closeBookingConfirmedDialog = () => setShowConfirmBookingDialog(false);
  const router = useNavigate();

  const classes = useStyles();

  const completeBooking = () => {
    handleDisableBookingChanges();
    Busy.set(true);
    bookSpace(requestBookSpaceObject)
      .then(res => {
        const {body} = res;
        if (body.orderNumber) {
          setBookingNumber(body.orderNumber)
        }
        router(`/booking-confirmation/${body?.id}`)
        setShowConfirmation(true)
        props.scrollToTop()
        sendPurchaseEvent(res)
      })
      .catch(err => {
        handleDisableBookingChanges();
        let error = getErrorMessageForStandardResponse(err);
        if (error && error.includes("Invalid amount")) {
          error = "There was an issue with your payment method. Please contact support";
        }
        props.snackbarShowMessage(error, "error", 15000);
      })
      .finally(() => {
        Busy.set(false);
        closeBookingConfirmedDialog();
      });
  };

  const {
    duration,
    rentalAgreementURL,
    hasAcceptedRentalAgreement,
    setHasAcceptedRentalAgreement,
    requestBookSpaceObject,
    bookingLocation,
    setBookingNumber,
    setShowConfirmation,
    instantApproval,
    handleDisableBookingChanges,
    disableBookingChanges,
    isBrokeredBooking
  } = props;


  return <Grid item>
    {!disableBookingChanges &&
      <>
        <BookingPaymentBeforeYouBook
          rentalAgreementURL={rentalAgreementURL}
          hasAcceptedRentalAgreement={hasAcceptedRentalAgreement}
          setHasAcceptedRentalAgreement={setHasAcceptedRentalAgreement}
          instantApproval={instantApproval}
          isBrokeredBooking={isBrokeredBooking}
        />

        <RequestBookSpaceButton
          requestBookSpaceObject={requestBookSpaceObject}
          duration={duration}
          minNumberOfSpaces={bookingLocation?.minNumberOfSpaces}
          minDuration={bookingLocation?.minDuration}
          hasAcceptedRentalAgreement={hasAcceptedRentalAgreement}
          setBookingNumber={setBookingNumber}
          setShowConfirmation={setShowConfirmation}
          disableBookingChanges={disableBookingChanges}
          handleDisableBookingChanges={handleDisableBookingChanges}
          isBrokeredBooking={isBrokeredBooking}
          setShowConfirmBookingDialog={setShowConfirmBookingDialog}
          instantApproval={instantApproval}
          bookSpace={completeBooking}
        />
      </>
    }
    <SimpleConfirmationDialog
      open={showConfirmBookingDialog}
      onClose={closeBookingConfirmedDialog}
      onConfirm={completeBooking}
      title={"Request Booking"}
      body={<Typography className={classes.confirmationBodyPadding} variant={'body2'} color={'textPrimary'}>Your
        reservations won’t be confirmed until the request is accepted. You will be notified by email and in your “My
        Bookings” page automatically whether your request is accepted within 30 minutes of submitting.</Typography>}
    />
  </Grid>
}

RequestSpace.propTypes = {
  duration: PropTypes.number,
  rentalAgreementURL: PropTypes.string,
  hasAcceptedRentalAgreement: PropTypes.bool,
  setHasAcceptedRentalAgreement: PropTypes.func,
  requestBookSpaceObject: PropTypes.object,
  bookingLocation: PropTypes.object,
  setBookingNumber: PropTypes.func,
  setShowConfirmation: PropTypes.func,
  instantApproval: PropTypes.bool,
  handleDisableBookingChanges: PropTypes.func,
  disableBookingChanges: PropTypes.bool,
  isBrokeredBooking: PropTypes.bool,
};

export default withSnackbar(RequestSpace);
