import React from 'react';
import ExportCSVButton from "./ExportCSVButton";
import _ from "underscore";

export const withExportButton = (Component, dataProp = 'data') => (props) => {
  const {[dataProp]: data, grouped, sortedAndGroupedData, reportName} = props;

  const exportData = () => {
    let data2Export = sortedAndGroupedData;
    if (grouped) {
      data2Export = _.flatten(sortedAndGroupedData.map((item) => item[dataProp]));
    }
    return (data2Export?.length && data2Export) || data;
  }

    return <>
      <ExportCSVButton
        data={exportData()}
        reportName={reportName}
      />
    <Component {...props}/>
  </>
}
