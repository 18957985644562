import React from 'react';
import LocationList from '../location/search/LocationList';
import LocationSearchListContentSkeleton from "../skeleton/LocationSearchListContentSkeleton";
import PropTypes from "prop-types";

const LocationSearchListContent = (props) => {
  const {locations, loading, instantApproval, listHover, setListHover, onlyLiveLocations} = props;
  return (
    loading ? <LocationSearchListContentSkeleton />
      : <LocationList
      locations={locations}
      loading={loading}
      instantApproval={instantApproval}
      onlyLiveLocations={onlyLiveLocations}
      listHover={listHover}
      setListHover={setListHover}
    />
  );
};

LocationSearchListContent.propTypes = {
  locations: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  instantApproval: PropTypes.bool.isRequired
};

export default LocationSearchListContent;
