import React from 'react';
import SimpleConfirmationDialog from "../SimpleConfirmationDialog";
import {deleteInventoryActivity} from "../../requests/inventory-requests";
import {withSnackbar} from "../hocs/withSnackbar";
import Busy from "../Busy";

type DeleteInterchangeConfirmationDialogProps = {
  handleLogout: () => void,
  inventoryActivity: Object,
  onClose: () => void,
  onSuccess: () => void,
  open: boolean,
  snackbarShowMessage: () => void
};

const DeleteInterchangeConfirmationDialog: (props: DeleteInterchangeConfirmationDialogProps) => JSX.Element = ({
  handleLogout = () => {},
  inventoryActivity = {},
  onClose = () => {},
  onSuccess = () => {},
  open = false,
  snackbarShowMessage = () => {}
}) => {

  const onConfirm = () => {
    Busy.set(true);
    deleteInventoryActivity(inventoryActivity.locationInventoryActivityGetVO.id).then(() => {
      snackbarShowMessage(`Successfully deleted interchange activity with booking ID ${inventoryActivity.orderNumber}`, 'success', 8000);
      onSuccess();
    }).catch((error) => {
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
      snackbarShowMessage(`Failed to delete interchange activity with booking ID ${inventoryActivity.orderNumber}`, 'error', 8000);
    }).finally(() => {
      Busy.set(false);
    });
  };

  return (
    <SimpleConfirmationDialog
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      title={"Delete Interchange"}
      body={"Are you sure you want to delete this interchange?"}
    />
  );
};

export default withSnackbar(DeleteInterchangeConfirmationDialog);