import React from "react";
import { Grid } from "@mui/material";
import BuyerSpaceUsageCard from "./BuyerSpaceUsageCard";

const BuyerSpaceUsageMap = ({spaceUsageList, onDownloadInventory, onViewInvoices, account, classes, handleViewInventory}) => {

    return (
        <Grid container gap={1}>
            {
                spaceUsageList ? 
                    spaceUsageList.map(item => <BuyerSpaceUsageCard 
                        classes={classes} 
                        spaceUsageItem={item} 
                        key={item.id} 
                        onDownloadInventory={onDownloadInventory} 
                        onViewInvoices={onViewInvoices} 
                        account={account} 
                        handleViewInventory={handleViewInventory}
                    />)
                : ""
            }
        </Grid>
    )
}

export default BuyerSpaceUsageMap;