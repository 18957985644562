import React from 'react';
import {FormGroup} from "react-bootstrap";
import {
  MAINTENANCE_AND_REPAIR_LABEL,
  ServiceFeeType,
  ServiceFeeTypeLabel
} from "../constants/securspace-constants";

const ServiceFeeTypeOrder = [
  ServiceFeeType.LIFT_FEE,
  ServiceFeeType.GATE_FEE,
  ServiceFeeType.VALET_MOVING_FEE,
  ServiceFeeType.TRANSLOADING,
  ServiceFeeType.CONTAINER_FREIGHT_STABILIZATION,
  ServiceFeeType.DRAYAGE,
  ServiceFeeType.EV_CHARGING,
];

const ServiceFeeSelectFormGroup = (props) => {
  const {onChange, value} = props;

  const handleOnChange = (e) => {
    onChange(e.target.value);
  }

  return <FormGroup className='mb-4'>
    <label className='fw-bold' htmlFor={'select-service-type'}>
      Service*
    </label>
    <select id={'select-service-type'} className={'form-control'} placeholder={'Select Service Fee Type'} value={value} onChange={handleOnChange}>
      {
        ServiceFeeTypeOrder.map(serviceFeeType => <option value={serviceFeeType} key={serviceFeeType}>{ServiceFeeTypeLabel[serviceFeeType]}</option>)
      }
      <option value={''} key={MAINTENANCE_AND_REPAIR_LABEL}>{MAINTENANCE_AND_REPAIR_LABEL}</option>
    </select>
  </FormGroup>
}

export default ServiceFeeSelectFormGroup;
