import React from 'react';
import PropTypes from 'prop-types';
import TogglePanel from '../../TogglePanel';
import { Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    brokeredBookingTogglePanel: {
        padding: '1.33rem 1.43rem', // top & bottom need to 1.33 to show hover animation properly
        backgroundColor: theme.palette.grey[100],
    },
}));

const BrokeredBookingTogglePanel = (props) => {
    const { isBrokeredBooking, onChange } = props;

    const classes = useStyles();

    return (
        <TogglePanel
            title='Brokered Booking'
            toggleComponent={<Switch name='isBrokeredBooking' checked={isBrokeredBooking} onChange={onChange} color='primary' inputProps={{ role: 'switch' }} />}
            className={classes.brokeredBookingTogglePanel}
        />
    );
};

BrokeredBookingTogglePanel.propTypes = {
    isBrokeredBooking: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default BrokeredBookingTogglePanel;