import React from 'react';
import {formatCurrencyValue} from "../../../util/PaymentUtils";
import {ListGroupItem} from "react-bootstrap";

const CostBreakdownListGroupItem = (props) => {
  const {title, description, amount} = props;
  
  const titleStyle = {
    fontSize: '1.15rem',  
  };
  
  const descriptionStyle = {
    fontSize: '0.875rem', 
    color: '#6c757d',
  };
  
  const amountStyle = {
    fontSize: '1rem', 
  };

  return (
    <ListGroupItem className={'d-flex justify-content-between align-items-center mt-2'}>
      <div>
        <h6 style={titleStyle}>{title}</h6>
        {description && <small style={descriptionStyle}>{description}</small>}
      </div>
      <div style={amountStyle}>
        {formatCurrencyValue(amount, true)}
      </div>
    </ListGroupItem>
  );
}

export default CostBreakdownListGroupItem;
