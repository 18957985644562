import React from 'react';
import { Box, Checkbox, TableCell, TableRow } from "@mui/material";
import CustomTooltip from './CustomTooltip';

const PartnerYardManagementTableRow = (props) => {
  const {
    partnerYardManagementAccountOwnerRole,
    handlePartnerYardManagementAccountOwnerRoleChange,
    partnerYardManagementGateClerkRole,
    handlePartnerYardManagementGateClerkRoleChange,
    partnerYardManagementGateManagerRole,
    handlePartnerYardManagementGateManagerRoleChange,
    partnerYardManagementAdminRole,
    handlePartnerYardManagementAdminRoleChange,
    gmsPro
  } = props;

  const partnerManagementToolTip = 'Receive emails when Customer bookings are created, updated, requested, or cancelled, or when an inventory correction is created.';

  return <>
    <TableRow>
      <TableCell>
        <Box display="flex" alignItems="center">
          {'Partner Yard Management'}
          <CustomTooltip title={partnerManagementToolTip} />
        </Box>
      </TableCell>
      <TableCell>
        {<Checkbox
          checked={partnerYardManagementAccountOwnerRole}
          onClick={handlePartnerYardManagementAccountOwnerRoleChange}
        />}
      </TableCell>
      {/* Need the two separate conditionals here for table formatting */}
      {
        gmsPro ?
              <TableCell>
                {<Checkbox
                  checked={partnerYardManagementGateClerkRole}
                  onClick={handlePartnerYardManagementGateClerkRoleChange}
                />}
              </TableCell>
        : ""
      }
      {
        gmsPro ?
              <TableCell>
                {<Checkbox
                  checked={partnerYardManagementGateManagerRole}
                  onClick={handlePartnerYardManagementGateManagerRoleChange}
                />}
              </TableCell>
        : ""
      }
      <TableCell>
        {<Checkbox
          checked={partnerYardManagementAdminRole}
          onClick={handlePartnerYardManagementAdminRoleChange}
        />}
      </TableCell>
    </TableRow>
  </>
}

export default PartnerYardManagementTableRow;
