import React, { useRef, useCallback } from 'react';
import {Grid} from "@mui/material";
import {SortFilter} from "@securspace/securspace-ui-kit";
import {withSnackbar} from "../hocs/withSnackbar";
import { sortByOptions, filterByOptions, filterBySelectOptions } from './data'
import type {PayoutsFilterParams} from "../../types/payouts";
import useAllLocationNames from "../../hooks/components/location/useAllLocationNames";

const PayoutsFilter = (props: {
  defaultFilterParams?: PayoutsFilterParams,
  onChange: (event) => void,
  snackbarShowMessage: () => void
}) => {
  const {defaultFilterParams = [], onChange = () => {}, snackbarShowMessage = () => {}} = props;

  const locations = useAllLocationNames(snackbarShowMessage);
  const initialRender = useRef(true);

  const handleCallback = useCallback((e) => {
    if (!initialRender.current) {
      onChange(e);
    } else {
      initialRender.current = false;
    }
  }, []);

  return (
    <Grid item container direction={'column'} component={'section'} mb={4}>
      <SortFilter
        filterCallback={handleCallback}
        sortByOption={sortByOptions}
        locations={locations}
        filterByOption={filterByOptions}
        filterBySelectOption={filterBySelectOptions}
        defaultLocation={defaultFilterParams.locationId}
        defaultStartDate={defaultFilterParams.startDate}
        defaultEndDate={defaultFilterParams.endDate}
        defaultSortBy={defaultFilterParams.sortBy}
        sortDir={defaultFilterParams.sortDir}
      />
    </Grid>
  );
};

export default withSnackbar(PayoutsFilter);
