import React from 'react';
import Select from "../Select";

const SubscriptionPaymentSource = (props) => {
  const {paymentSource, handleChange} = props;

  return <>
    <fieldset className="ss-subscription ss-stand-alone">
      <label htmlFor="paymentSource">PAYMENT SOURCE</label>
      <Select id="paymentSource"
              name="paymentSource"
              optionsWidth="300px"
              handleChange={handleChange}
              selectedOption={paymentSource}
              placeholder="Choose"
              options={[
                "",
                "PAYOUT",
              ]}
      />
    </fieldset>
  </>
}

export default SubscriptionPaymentSource;
