const geocodeCityState = (city, state) => {
    const address = `${city}, ${state}`;
    const geocoder = new window.google.maps.Geocoder();

    return new Promise((resolve, reject) => {
        geocoder.geocode({ address }, (results, status) => {
            if (status === 'OK' && results.length > 0) {
                const location = results[0].geometry.location;
                const latLng = {
                    latitude: location.lat(),
                    longitude: location.lng(),
                };
                resolve(latLng);
            } else {
                reject('Geocoding failed');
            }
        });
    });
};

const geocodeLatLng = (latitude, longitude) => {
    const latLng = new window.google.maps.LatLng(latitude, longitude);
    const geocoder = new window.google.maps.Geocoder();

    return new Promise((resolve, reject) => {
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK' && results.length > 0) {
                const addressComponents = results[0].address_components;
                const city = addressComponents.find(
                    (component) => component.types.includes('locality')
                )?.long_name;

                const state = addressComponents.find(
                    (component) =>
                        component.types.includes('administrative_area_level_1')
                )?.short_name;

                if (city && state) {
                    resolve({ city:slugify(city), state:state.toLowerCase() });
                } else {
                    reject('City and state not found');
                }
            } else {
                reject('Geocoding failed');
            }
        });
    });
};

const slugify = text =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')

export { geocodeCityState, geocodeLatLng, slugify };;

  