import {Typography, Link} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React from 'react';
import useRentalAgreementLink from '../../../hooks/useRentalAgreementLink';
import {Link as RouterLink} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  list: {
    listStyleType: 'disc',
    listStylePosition: 'outside',
    marginTop: '1rem',
    paddingLeft: '1.35rem',
  },
  secondaryMainColor: {
    color: theme.palette.secondary.main,
  },
}));

const LocationProfileBeforeYouBook = (props) => {
  const {rentalAgreementFileName} = props;

  const rentalAgreementURL = useRentalAgreementLink(rentalAgreementFileName);
  const classes = useStyles();

  return (
    <section>
      <Typography variant='h6' color='textPrimary'>Before You Book</Typography>
      <ul className={classes.list}>
        <li>
          <RouterLink to="/terms-of-use" target="_blank" className={classes.secondaryMainColor}>Terms of Use
            Policy</RouterLink>
        </li>
        <li>
          <Link className={classes.secondaryMainColor} href={rentalAgreementURL} target="_blank">Rental Agreement
            Policy</Link>
        </li>
      </ul>
    </section>
  );
};

export default LocationProfileBeforeYouBook;
