import React from 'react';
import '../../../css/components/groupAndSort/groupAndSort.css';
import ButtonSelector from "../../ButtonSelector";

const GroupAndSort = (props) => {

  const {
    groupByChangeHandler,
    groupBy,
    sortDirection,
    groupDirectionChangeHandler,
    groupByOptions,
    sortByChangeHandler,
    groupDirection,
    sortDirectionChangeHandler,
    sortBy,
    asc,
    desc
  } = props;

  return (
    <form className="report-form">
      <div className="ss-stand-alone data-list-filter-container search-container">
        <fieldset className="ss-fieldset-row-inner-left">
          <label>GROUP BY:</label>
          <select
            className={"ss-select-list ss-group-select"}
            id="groupBy"
            name="groupBy"
            onChange={groupByChangeHandler}
            value={groupBy}
            placeholder="Choose"
          >
            {groupByOptions && groupByOptions.map((option, key) =>
              <option
                key={key}
                value={option.name}
              >
                {option.label}
              </option>
            )}
          </select>
        </fieldset>
        <div className="ss-fieldset-row-inner-middle">
          <ButtonSelector
            options={[ asc, desc ]}
            selectedOption={groupDirection}
            handleOptionSelected={groupDirectionChangeHandler}
          />
        </div>
      </div>
      <div className="ss-stand-alone data-list-filter-container search-container">
        <fieldset className="ss-fieldset-row-inner-left">
          <label>SORT BY:</label>
          <select
            className={"ss-select-list ss-group-select"}
            id="groupBy"
            name="groupBy"
            onChange={sortByChangeHandler}
            value={sortBy}
            placeholder="Choose"
          >
            {groupByOptions && groupByOptions.map((option, key) =>
              <option
                key={key}
                value={option.name}
              >
                {option.label}
              </option>
            )}
          </select>
        </fieldset>
        <div className="ss-fieldset-row-inner-middle">
          <ButtonSelector
            options={[ asc, desc ]}
            selectedOption={sortDirection}
            handleOptionSelected={sortDirectionChangeHandler}
          />
        </div>
      </div>
    </form>
  );
}

export default GroupAndSort;