import React from "react";
import { Box } from "@mui/material";
import { Table } from "@securspace/securspace-ui-kit";

const headerCells = [
    {
      id: 'invoiceNumber',
      label: 'Invoice Number'
    },
    {
      id: 'type',
      label: 'Type'
    },
    {
      id: 'amount',
      label: 'Amount'
    },
    {
      id: 'serviceMonth',
      label: 'Service Month'
    },
    {
      id: 'paymentDate',
      label: 'Payment Date'
    },
    {
      id: 'status',
      label: 'Status'
    }
  ];

const PartnerSubscriptionInvoicesTable = ({transactions, handleSortChange}) => {

    return <Box mt={4}>
        <Table
            headCells={headerCells}
            rows={transactions}
            actionType='button'
            isSortable="true"
            handleSortRequest={handleSortChange}
        />
    </Box>
}

export default PartnerSubscriptionInvoicesTable;