import React from 'react';
import {FormGroup} from "react-bootstrap";

const NotesFormGroup = (props) => {
  const {value, onChange} = props;

  const handleOnChange = (e) => {
    onChange(e.target.value);
  }

  return <FormGroup className='mb-4'>
    <label className='fw-bold' htmlFor={'notes'}>Notes</label>
    <textarea className={'form-control'}
              id={'notes'}
              value={value}
              onChange={handleOnChange}
              maxLength={255}
    />
    <p className={'help-block'}>{value?.length || 0}/255</p>
  </FormGroup>
}

export default NotesFormGroup;
