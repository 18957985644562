import React, {useState, useEffect} from "react";
import { Dialog, 
    DialogContent, 
    DialogActions, 
    DialogTitle, 
    Button, 
    InputLabel,
    FormControl,
    Grid,
    TextField
} from "@mui/material";
import { inventoryCorrectionReasons } from "../constants/securspace-constants";
import { formatDateMMDDYYYY } from "../../util/DateUtils";
import DateCorrectionForm from "./forms/DateCorrectionForm";
import AssetNumberCorrectionForm from "./forms/AssetNumberCorrectionForm";
import moment from "moment-timezone";
import ReasonSelect from "./subComponents/ReasonSelect";
import type {GroupedLocationInventoryActivity} from "../../types/GroupedLocationInventoryActivity";
import InventoryBreakdown from "./subComponents/InventoryBreakdown";
import CheckoutDateCorrectionForm from "./forms/CheckoutDateCorrectionForm";

type Props = {
    onClose: () => void,
    inventory: GroupedLocationInventoryActivity,
    snackbarShowMessage: (message: string, severity: string) => void,
    submitCorrection: (body: any) => void
};

const SubmitCorrectionDialog = (props: Props) => {

    const {onClose, inventory, snackbarShowMessage, submitCorrection} = props;

    const defaultDriver = {firstName: "", lastName: "", license: ""};
    const defaultAsset = {containerNumber: inventory?.containerNumber, chassisNumber: inventory?.chassisNumber, trailerNumber: inventory?.trailerNumber, truckLicensePlateNumber: inventory?.truckLicensePlateNumber};
    const defaultDate = inventory?.checkoutDate;

    const [disableSubmit, setDisableSubmit] = useState(true);
    const [reason, setReason] = useState('');
    const [correctActivityDate, setCorrectActivityDate] = useState(defaultDate);
    const [correctAssetInfo, setCorrectAssetInfo] = useState("");
    const [currentTimeData, setCurrentTimeData] = useState({dateTime: "", timeZoneName: "", timeZoneAbbreviation: ""})
    const [driverInfo: {firstName: string, lastName: string, license: string}, setDriverInfo] = useState(defaultDriver);
    const [notes, setNotes] = useState('');

    const handleChangeReason = (e) => {
        setReason(e.target.value)
    };

    const handleClose = () => {
        setReason('');
        setCorrectActivityDate('');
        setCorrectAssetInfo(defaultAsset);
        setDriverInfo(defaultDriver);
        setNotes('');
        setDisableSubmit(true)
        onClose();
    };

    const handleChangeNotes = ({target}) => {
        setNotes(target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (reason && inventory) {
            const {ids} = inventory;
            let activityId;
            if (ids.length === 2 && reason === inventoryCorrectionReasons.ASSET_CHECKED_OUT_ON_WRONG_DATE) {
                activityId = ids[1];
            } else {
                activityId = ids[0];
            }
            const body = {
                locationInventoryActivityId: activityId,
                reason: reason,
                rejectionReason: '',
                chassisNumber: correctAssetInfo.chassisNumber,
                containerNumber: correctAssetInfo.containerNumber,
                trailerNumber: correctAssetInfo.trailerNumber,
                truckLicensePlateNumber: correctAssetInfo.truckLicensePlateNumber,
                driverFirstName: driverInfo.firstName,
                driverLastName: driverInfo.lastName,
                driverLicenseNumber: driverInfo.license,
                notes: notes
            };
            if ([inventoryCorrectionReasons.ASSET_CHECKED_OUT_ON_WRONG_DATE, inventoryCorrectionReasons.ASSET_LEFT_THE_YARD].includes(reason) && correctActivityDate) {
                if(moment(correctActivityDate).isAfter(currentTimeData.dateTime)){
                    snackbarShowMessage(`Please select an activity time before ${moment(currentTimeData.currentDateTime).format("hh:mm A MM/DD/YY")} ${currentTimeData.timeZoneAbbreviation}`, 'error', 4000)
                } else {
                    body.correctActivityDate = moment(correctActivityDate).toISOString(true)
                    submitCorrection(body);
                    handleClose();
                }
            } else{
                submitCorrection(body);
                handleClose();
            }

        } else {
            snackbarShowMessage("Please select a reason in order to submit a correction for review.", "warning");
        }
    }

    useEffect(() => {
        if(inventory) {
            const {timeZoneName} = inventory;
            const time = moment.tz(moment(), timeZoneName);
            const abbrev = moment().tz(timeZoneName).format('z');
            setCurrentTimeData({dateTime: time, timeZoneName: timeZoneName, timeZoneAbbreviation: abbrev});
        }
    }, [inventory])

    useEffect(() => {
        if(inventory && !correctActivityDate) {
            setCorrectActivityDate(inventory.checkoutDate)
        }
        if(inventory && !correctAssetInfo?.containerNumber && !correctAssetInfo?.chassisNumber && !correctAssetInfo?.trailerNumber && !correctAssetInfo?.truckLicensePlateNumber ) {
            const defaultAsset = {containerNumber: inventory.containerNumber, chassisNumber: inventory.chassisNumber, trailerNumber: inventory.trailerNumber, truckLicensePlateNumber: inventory.truckLicensePlateNumber};
            setCorrectAssetInfo(defaultAsset)
        }
    }, [inventory])

    useEffect(() => {
        const driverIsNotValid = !driverInfo.firstName || !driverInfo.lastName || !driverInfo.license;

        const assetNumbersMatch = () => {
            if(correctAssetInfo && inventory) {
                let match = true;
                Object.keys(correctAssetInfo).forEach(key => {
                    const nullSafeOriginalAssetNumber = inventory[key] === undefined || inventory[key] === null ? "" : inventory[key];
                    const nullSafeRevisedAssetNumber = correctAssetInfo[key] === undefined || correctAssetInfo[key] === null ? "" : correctAssetInfo[key];

                    if (nullSafeRevisedAssetNumber.toLowerCase() !== nullSafeOriginalAssetNumber.toLowerCase()) {
                        match = false
                    }
                });

                return match; 
            }
        }

        switch(reason) {
            case inventoryCorrectionReasons.ASSET_CHECKED_OUT_ON_WRONG_DATE:
                if(formatDateMMDDYYYY(correctActivityDate) === formatDateMMDDYYYY(inventory.checkoutDate) || driverIsNotValid || moment(correctActivityDate).isAfter(currentTimeData.dateTime)) {
                    setDisableSubmit(true)
                } else {
                    setDisableSubmit(false)
                }
                break;
            case inventoryCorrectionReasons.ASSET_NUMBER_ENTERED_INCORRECTLY:
                if(driverIsNotValid || assetNumbersMatch()) {
                    setDisableSubmit(true)
                } else {
                    setDisableSubmit(false)
                }
                    break;
            case inventoryCorrectionReasons.ASSET_DOES_NOT_BELONG_TO_BUYER:
                setDisableSubmit(false)
                break;
            case inventoryCorrectionReasons.ASSET_LEFT_THE_YARD:
                setDisableSubmit(driverIsNotValid || !moment(correctActivityDate).isValid() || !correctAssetInfo.truckLicensePlateNumber || moment(correctActivityDate).isAfter(currentTimeData.dateTime))
                break;
            default:
                setDisableSubmit(true)
                break
        }
    }, [reason, correctActivityDate, correctAssetInfo, driverInfo]);

    return <Dialog open={!!inventory} onClose={handleClose} fullWidth scroll={"body"}>
        <DialogTitle>Correction Reasons</DialogTitle>
        <DialogContent sx={{ paddingTop: "1em !important"}}>
            <form id={'ss-correction-form'} onSubmit={handleSubmit} noValidate>
                <Grid container gap={2}>
                    <Grid item xs={12}>
                        <ReasonSelect value={reason} onChange={handleChangeReason} groupedLocationInventoryActivity={inventory} required/>
                    </Grid>
                    {
                        reason === inventoryCorrectionReasons.ASSET_LEFT_THE_YARD &&
                          <>
                              <InventoryBreakdown
                                inventory={inventory}
                              />
                              <CheckoutDateCorrectionForm
                                inventory={inventory}
                                correctActivityDate={correctActivityDate}
                                setCorrectActivityDate={setCorrectActivityDate}
                                setDriverInfo={setDriverInfo}
                                correctAssetInfo={correctAssetInfo}
                                setCorrectAssetInfo={setCorrectAssetInfo}
                                setDisableSubmit={setDisableSubmit}
                                currentTimeData={currentTimeData}
                              />
                          </>
                    }
                    {
                          reason === inventoryCorrectionReasons.ASSET_CHECKED_OUT_ON_WRONG_DATE ?
                            <DateCorrectionForm
                              inventory={inventory}
                              correctActivityDate={correctActivityDate}
                              setCorrectActivityDate={setCorrectActivityDate}
                              driverInfo={driverInfo}
                              setDriverInfo={setDriverInfo}
                              setDisableSubmit={setDisableSubmit}
                              currentTimeData={currentTimeData}
                            />
                          : [inventoryCorrectionReasons.ASSET_NUMBER_ENTERED_INCORRECTLY].includes(reason) ?
                            <AssetNumberCorrectionForm
                              inventory={inventory}
                              setCorrectAssetInfo={setCorrectAssetInfo}
                              driverInfo={driverInfo}
                              setDriverInfo={setDriverInfo}
                            />
                        : ""
                    }
                    <Grid item xs={12}>
                        <InputLabel htmlFor="ss-select-correction-notes">Notes (optional)</InputLabel>
                        <FormControl fullWidth>
                            <TextField
                              id="ss-select-correction-notes"
                              value={notes}
                              name="notes"
                              type="text"
                              onChange={handleChangeNotes}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button form={'ss-correction-form'} onClick={handleSubmit} disabled={disableSubmit} type={'submit'}>Submit</Button>
        </DialogActions>
    </Dialog>
}

export default SubmitCorrectionDialog;