import React from 'react';
import {Grid, Theme} from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from '@mui/styles';

const useStyles: (theme: Theme) => {
  itemContainer: CSSStyleSheet,
} = makeStyles(theme => ({
  itemContainer: {
    columnGap: '0.57em',
    padding: '1.43em',
    alignItems: 'center',
    width: '100%',
    '&:not(:last-child)': {
      borderBottom: `0.07rem solid ${theme.palette.grey[200]}`,
    },
  },
}));

const PaymentMethodListCard = (props) => {
  const {children} = props;
  const classes = useStyles();

  return (
    <Grid item container alignContent={'center'} justifyContent={'space-between'} className={classes.itemContainer}>
      {children}
    </Grid>
  );
};

PaymentMethodListCard.propTypes = {
  children: PropTypes.any,
};

export default PaymentMethodListCard;