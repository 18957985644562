import React, {useContext, useEffect, useState} from 'react';
import {withSnackbar} from "../../../hocs/withSnackbar";
import {Grid} from "@mui/material";
import {GroupSummaries, GroupSummariesLoader} from "@securspace/securspace-ui-kit";
import {requestAdminInvoicesSummary} from "../../../../requests/invoice-requests";
import {AppContext} from "../../../../context/app-context";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../../../util/NetworkErrorUtil";
import {formatCurrencyValue} from "../../../../util/PaymentUtils";
import {omit} from "underscore";

const InvoiceGroupSummary = ({query, snackbarShowMessage}) => {
  const {logout} = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [groupSummaryFields, setGroupSummaryFields] = useState([]);

  useEffect(() => {
    setLoading(true);
    // Remove unused fields
    const filteredQuery = omit(query, ['sortBy', 'sortDir', 'page', 'pageSize']);
    requestAdminInvoicesSummary(filteredQuery)
      .then(response => {
        setGroupSummaryFields([
          {title: 'Buyer Charges', value: formatCurrencyValue(response.body.buyerCharges, true)},
          {title: 'Payment Processor Fees', value: formatCurrencyValue(response.body.paymentProcessorFees, true)},
          {title: 'Supplier Payouts', value: formatCurrencyValue(response.body.supplierPayouts, true)},
          {title: 'SecurSpace Fees', value: formatCurrencyValue(response.body.securSpaceFees, true)}
        ]);
      })
      .catch(error => {
        if (error.response?.status === 401) logout();
        snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [query, snackbarShowMessage]);

  return (
    <Grid item xs={12}>
      {
        loading ? <GroupSummariesLoader /> :
          <GroupSummaries groupSummaryFields={groupSummaryFields} />
      }
    </Grid>
  );
};

export default withSnackbar(InvoiceGroupSummary);