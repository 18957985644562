import React from "react";
import {convertDuration, MONTHLY} from "../../../util/BookingUtil";
import {Button} from "@mui/material";
import {withSnackbar} from "../../hocs/withSnackbar";
import moment from "moment/moment";
import PropTypes from "prop-types";

const RequestBookSpaceButton = (props) => {

  const {
    requestBookSpaceObject,
    hasAcceptedRentalAgreement,
    disableBookingChanges,
    isBrokeredBooking,
    snackbarShowMessage,
    instantApproval,
    setShowConfirmBookingDialog,
    bookSpace,
    minDuration,
    duration,
  } = props;

  const handleBookSpace = () => {
    if (hasAcceptedRentalAgreement || isBrokeredBooking) {
      if (requestBookSpaceObject) {
        const {numberOfSpaces, minNumberOfSpaces, assetType, durationType, startDate, endDate, paymentMethodId} = requestBookSpaceObject;
        const datesAreValid = moment(startDate).isValid() && moment(endDate).isValid();
        const startDateBeforeToday = moment(startDate).isBefore(moment(), 'day');
        const startDateSameOrAfterEndDate = moment(startDate).isSameOrAfter(endDate, 'day');
        if (!Number.isInteger(numberOfSpaces)) {
          snackbarShowMessage('Please enter Number Of Spaces.', 'error', 15000);
        } else if (!assetType) {
          snackbarShowMessage('Please select an Equipment Type.', 'error', 15000);
        } else if (numberOfSpaces < minNumberOfSpaces && !isBrokeredBooking) {
          snackbarShowMessage(`This supplier requires a minimum of ${minNumberOfSpaces} spaces.`, 'error', 15000);
        } else if (durationType !== MONTHLY && duration < convertDuration(minDuration) && !isBrokeredBooking) {
          snackbarShowMessage(`This supplier requires a minimum of ${convertDuration(minDuration)} days.`, 'error', 15000);
        } else if (!datesAreValid) {
          snackbarShowMessage('Please select a valid Start Date and End Date.', 'error', 15000);
        } else if (startDateBeforeToday && !isBrokeredBooking) {
          snackbarShowMessage('Please select a Start Date on or after today.', 'error', 15000);
        } else if (startDateSameOrAfterEndDate) {
          snackbarShowMessage('Please select a Start Date before the End Date.', 'error', 15000);
        } else if (!isBrokeredBooking && !paymentMethodId) {
          snackbarShowMessage('Please add a payment method to complete the booking.', 'error', 15000);
        } else if (!instantApproval && setShowConfirmBookingDialog) {
          setShowConfirmBookingDialog(true);
        } else {
          bookSpace();
        }
      }
    } else {
      snackbarShowMessage("Please accept the rental agreement & terms of use", "error", 15000, { vertical: "bottom", horizontal: "center" });
    }
  }

  return <Button
    onClick={handleBookSpace}
    variant={"contained"}
    color={"secondary"}
    disabled={disableBookingChanges}
    sx={{ mt:3 }}
  >
    Book Space
  </Button>
}

RequestBookSpaceButton.propTypes = {
  requestBookSpaceObject: PropTypes.object,
  hasAcceptedRentalAgreement: PropTypes.bool.isRequired,
  disableBookingChanges: PropTypes.bool.isRequired,
  isBrokeredBooking: PropTypes.bool.isRequired,
  snackbarShowMessage: PropTypes.func.isRequired,
  setShowConfirmBookingDialog: PropTypes.func,
  bookSpace: PropTypes.func.isRequired,
};

export default withSnackbar(RequestBookSpaceButton);
