import React, {useCallback, useState} from "react";
import {
  Snackbar,
  Slide,
  Alert
} from "@mui/material";

export const withSnackbar = WrappedComponent => {
  return props => {
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [message, setMessage] = useState("An Error Occurred");
    const [duration, setDuration] = useState(8000);
    const [severity, setSeverity] = useState('success');
    const [anchorOrigin, setAnchorOrigin] = useState({ vertical: "top", horizontal: "center" });

    const showMessage = useCallback(function showMessage(message: Object, severity: String, duration: Number, position?: {}) {
      const errorMessage = message && typeof message === 'object' ? message.message : message;
      const finalMessage = errorMessage ?? "Something went wrong!";
      setMessage(finalMessage);
      setSeverity(severity);
      setDuration(duration);
      setShowSnackbar(true);
      if (position) {
        setAnchorOrigin(position)
      }
    }, []);

    return <>
      <WrappedComponent {...props} snackbarShowMessage={showMessage}/>
      <Snackbar
        anchorOrigin={anchorOrigin}
        autoHideDuration={duration}
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        TransitionComponent={Slide}
        className="snackbar"
        sx={{
          '& .MuiAlert-message, .MuiAlert-icon, .MuiAlert-action':{
            color:'#fff'
          }
        }}
      >
        <Alert variant="filled" onClose={() => setShowSnackbar(false)} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </>;
  };
};
