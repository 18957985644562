import React from "react";
import {Box} from "@mui/material";
import {GridCard} from "@securspace/securspace-ui-kit";
import {BookingTransactionType, BookingTransactionTypeLabel} from "../../components/constants/securspace-constants";

const headerCells = [
  {
    id: 'transactionNumber',
    label: 'Invoice Number'
  },
  {
    id: 'supplierAmount',
    label: 'Amount'
  },
  {
    id: 'createdOn',
    label: 'Charged Date'
  },
  {
    id: 'paymentCreatedOn',
    label: 'Payment Date'
  },
  {
    id: 'serviceDates',
    label: 'Service Dates'
  },
  {
    id: 'transactionType',
    label: 'Type'
  },
  {
    id: 'bookingNumber',
    label: 'Booking Number'
  },
  {
    id: 'buyerCompanyName',
    label: 'Customer'
  }
];

const agsHeaderCells = [
  {
    id: 'transactionNumber',
    label: 'Invoice Number'
  },
  {
    id: 'supplierAmount',
    label: 'Amount'
  },
  {
    id: 'serviceMonth',
    label: 'Service Month'
  },
  {
    id: 'agsServiceDates',
    label: 'Payment Date'
  },
  {
    id: 'transactionType',
    label: 'Type'
  },
];

const InvoicesInPayoutCard = ({invoices, handleSortChange}) => {

  return (
    <Box mt={4}>
      {invoices.length > 0 && invoices.map(invoice => (
        <GridCard
          cardStyles={{marginBottom: '1.5rem'}}
          listId={invoice.transactionNumber}
          data={invoice}
          handleSortChange={handleSortChange}
          menuCloseListener={null}
          menuOpenListener={null}
          menuOptions={null}
          headData={invoice.transactionType === BookingTransactionTypeLabel[BookingTransactionType.AGS_FEE] ? agsHeaderCells : headerCells}
        />
      ))
      }
    </Box>
  );
}

export default InvoicesInPayoutCard;
