import { makeStyles } from "@mui/styles";


export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    position: 'absolute',
    width: '37.5em',
    left: '42%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFF',
    borderRadius: '0.5em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  stickyTop: {
    position: 'sticky',
    top: 0,
    padding: '1.5em 1.5em .2em 1.5em',
  },
  padding: {
    padding: '1em'
  },
  marginTop: {
    marginTop: '1em'
  },
  marginBottom: {
    marginBottom: '1em'
  },
  verifyButton: {
    backgroundColor: 'rgba(245, 245, 245, 1)',
    padding: '0.5em',
    width: '100%',
  },
  accountIcon:{
    width: '1.5em',
    height: '1.5em',
    objectFit: 'contain',
  },
  fullContent: {
    flex: '1',
    overflowY: 'scroll',
  },
  paddingContainer: {
    padding: '0em 1.71em 0em 1.71em',
  },
  error:{
    color: theme.palette.error.main,
    fontSize: '1em',
    fontWeight: 400,
    fontFamily: 'Inter',
    lineHeight: '1.25em',
    marginBottom: '1em',
  },
  bankContainer: {
    borderRadius: '0.5em',
    padding: '1.5em 2.5em 2.5em 2.5em',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  icon: {
    color: '#FFFFFF',
    fontSize: '1em'
  },
  personIcon:{
    color:'rgba(0, 0, 0, 0.54)'
  },
  bankIcon: {
    color: theme.palette.primary.main,
  },
  appInput: {
    marginBottom: '1rem',
    width: '100%',
    '& input': {
      width: '100%',
      fontSize: '.8em'
    },
    '& .MuiInputBase-formControl': {
      background: 'transparent',
      marginTop: '.5em'
    },
    '& .MuiInput-underline:before':{
      borderBottom: '2px solid #6639BF'
    },
    '& label': {
      fontSize: '.8em',
      fontWeight: 400,
      fontFamily: 'Inter',
      lineHeight: '0.8em',
      letterSpacing: '.05em',
      color: '#6639BF',
    }
  },
  title: {
    fontSize: '1.5em',
    textAlign: 'center',
    fontWeight: 500,
    fontFamily: 'Inter',
    lineHeight: '1.25em',
    marginTop: '1.5em',
    marginBottom: '1.5em',
  },
  verifyTitlePrimary: {
    fontSize: '1.1em',
    fontWeight: 500,
    fontFamily: 'Inter',
    lineHeight: '1.25em',
    marginTop: '1.5em',
  },
  verifyTitleSecondary: {
    fontSize: '1.1em',
    fontWeight: 700,
    fontFamily: 'Inter',
    lineHeight: '1.25em',
    marginTop: '1.5em',
    marginBlockEnd: '1.5em',
  },
  routeLabel: {
    fontSize: '1.1em',
    fontWeight: 500,
    fontFamily: 'Inter',
    lineHeight: '1.25em',
    marginTop: '1.5em',
    marginBlockEnd: '1.5em',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  bankText: {
    fontSize: '1.1em',
    fontWeight: 400,
    fontFamily: 'Inter',
    lineHeight: '1.25em',
    marginTop: '.2em',
    marginLeft: '.5em',
    flex: '1'
  },
  bankBullet: {
    width: '0.5em',
    height: '0.5em',
    borderRadius: '50%',
    backgroundColor: '#000000',
  },
  buttonContainer: {
    marginTop: '1.5em',
    gap: '1em',
  },
  button: {
    marginTop: '1.5em',
  }
}));