import React from 'react';
import {Box, Theme, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles: (theme: Theme) => {
  panel: CSSStyleSheet,
} = makeStyles((theme) => ({
  panel: {
    backgroundColor: theme.palette.grey[50],
    padding: '1.35em',
    borderRadius: '0.57rem',
  },
}));

const UpdatePaymentMethodSuccessBody = () => {
  const classes = useStyles();
  return (
    <Box className={classes.panel}>
      <Typography variant={"subtitle2"} mb={1}>
        The payment method for this booking was updated successfully.
      </Typography>
      <Typography variant={"subtitle2"}>
        Any future changes to this booking payment method will include any associated payment process fees
      </Typography>
    </Box>
  );
};

export default UpdatePaymentMethodSuccessBody;