import React from 'react';
import Select from "../../Select";
import LocationManagerOption from "../../../controls/LocationManagerOption";

const LocationManagerSelect = (props) => {
  const {onChange, currentManager, managerList} = props;

  // Add null option to list of managers & convert to set to remove duplicates
  let nullOption = new LocationManagerOption(null)

  const managerSet = new Set(managerList);

  managerSet.add(nullOption);

  // change back to array so that the Select component can map the values
  const uniqueManagerArray = Array.from(managerSet)

  return <fieldset className="ss-stand-alone">
    <label htmlFor="locationManager">Select a Location Manager</label>
    {
      managerList?.length &&
        <Select id="selectedLocationManager"
                name="locationManager"
                handleChange={onChange}
                className="ss-account-select"
                selectedOption={currentManager ? new LocationManagerOption(currentManager) : ''}
                placeholder="Choose"
                options={uniqueManagerArray}
                canSearch={false}
        />
    }
  </fieldset>
}

export default LocationManagerSelect;
