import React from 'react';
import {ButtonBase, Grid, Typography} from "@mui/material";
import { makeStyles } from '@mui/styles';
import {bookingCardStyles} from "../../views/booking-payment/style";
import PropTypes from "prop-types";
import {Theme} from "@mui/material";
import PaymentMethodsActionsMenu from "./PaymentMethodsActionsMenu";
import {BankAccountStatus} from "../constants/securspace-constants";
import PaymentMethodListCard from "./PaymentMethodListCard";

const useStyles: (theme: Theme) => {
  itemContainer: CSSStyleSheet,
  cardInfoContainer: CSSStyleSheet,
} = makeStyles(theme => ({
  itemContainer: {
    columnGap: '0.57em',
    padding: '1.43em',
    alignItems: 'center',
    width: '100%',
    '&:not(:last-child)': {
      borderBottom: `0.07rem solid ${theme.palette.grey[200]}`,
    },
  },
  cardInfoContainer: {
    gap: '1em',
    alignItems: 'center',
  },
}));

const PaymentMethodItem = ({ paymentMethod, img, cardTitle, verifyPaymentMethod, onMenuAction, disableRemove }) => {

  const classes = useStyles();
  const bookingCardClasses = bookingCardStyles();

  const paymentNeedsVerification = (paymentMethod) => {
    return (paymentMethod.stripeCustomerId && paymentMethod.stripeStatus === BankAccountStatus.UNVERIFIED) ||
      (paymentMethod.dwollaFundingSourceId && paymentMethod.dwollaStatus === BankAccountStatus.UNVERIFIED);
  };

  const handleVerifyClick = () => {
    verifyPaymentMethod(paymentMethod);
  };

  const handleMenuAction = (actionName: string) => {
    onMenuAction(actionName, paymentMethod);
  };

  return (
    <PaymentMethodListCard>
      <Grid item container alignContent={'center'} xs={12} sm className={classes.cardInfoContainer}>
        <img src={img} alt="Payment Card" className={bookingCardClasses.cardImg} />
        <Typography variant={'h6'} color={'textPrimary'}>{cardTitle}</Typography>
      </Grid>
      <Grid item xs>
        Last Four: {paymentMethod.lastFour}
      </Grid>
      <Grid item xs>
        {`Nick Name: ${paymentMethod.nickName || 'n/a'}`}
      </Grid>
      {
        paymentNeedsVerification(paymentMethod) ?
          <Grid item xs>
            <ButtonBase onClick={handleVerifyClick}>
              <Typography variant={'h6'} color={'secondary'}>Click here to verify</Typography>
            </ButtonBase>
          </Grid> :
          <Grid item xs>
            {`Exp. ${paymentMethod.expiresOn || 'n/a'}`}
          </Grid>
      }
      <Grid container item xs justifyContent={'flex-end'}>
        <PaymentMethodsActionsMenu
          onSelect={handleMenuAction}
          disableRemove={disableRemove}
        />
      </Grid>
    </PaymentMethodListCard>
  );
};

PaymentMethodItem.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  onMenuAction: PropTypes.func.isRequired,
  verifyPaymentMethod: PropTypes.func.isRequired,
  disableRemove: PropTypes.bool,
};

export default PaymentMethodItem;