import React from 'react';
import LocationManagedLocationChip from './LocationManagedLocationChip';

const InstantApprovalChip = () => {
  return <LocationManagedLocationChip label={'Instant Approval'}
                                      severity={'success'}
  />
}

export default InstantApprovalChip;
