import React, {useEffect, useState} from 'react';
import CostBreakdownListGroupItem from "./CostBreakdownListGroupItem";

const InvoiceListGroupItem = (props) => {
  const {invoiceItem} = props;
  const [title, setTitle] = useState(invoiceItem?.description);
  const [description, setDescription] = useState('');

  useEffect(() => {
    const SERVICE_CHARGE = 'Service Charge';

    const stripServiceChargeDescription = (invoiceItemDescription) => {
      return invoiceItemDescription.split(SERVICE_CHARGE + ' - ');
    }

    if (invoiceItem?.description && invoiceItem.description.startsWith(SERVICE_CHARGE)) {
      setTitle(SERVICE_CHARGE);
      setDescription(stripServiceChargeDescription(invoiceItem.description));
    }
  }, [invoiceItem]);

  return <CostBreakdownListGroupItem title={title}
                                     description={description}
                                     amount={invoiceItem?.amount}
  />
}

export default InvoiceListGroupItem;
