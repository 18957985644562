import React, {useRef, useState} from 'react';
import {Grid, Paper} from '@mui/material';
import { makeStyles } from '@mui/styles';
import useClickOutsideRef from '../../hooks/useClickOutsideRef';
import PlacesAutocompleteInputMui from '../placesAutocomplete/PlacesAutocompleteInputMui';
import PropTypes from 'prop-types';
import './style.css'

const useStyles: {
  paper: CSSStyleSheet,
  form: CSSStyleSheet,
} = makeStyles(({
  paper: {
    height: '4.6428571428571428em',
  },
  form: {
    margin: '0 1.75em 0 1.75em',
  },
}));

const LandingSearchInput = (props) => {
  const {
    name,
    selectedPacItem,
    handleClickPac,
    handleSelectPac,
    onSearchSubmit,
  } = props;
  const classes = useStyles();
  const containerRef = useRef(null);
  const [value, setValue] = useState('');
  const [showPac, setShowPac] = useState(false);

  const handleOnFocus = () => {
    setShowPac(true);
  }

  useClickOutsideRef(containerRef, () => {
    setShowPac(false);
  });

  const handleOnSubmit = (event) => {
    event.preventDefault();
    onSearchSubmit(event);
  }

  const handleValueChange = (event) => {
    setValue(event?.target?.value);
  }

  const handleCancelIconClick = () => {
    setValue('');
  }

  return <Grid container
               direction={'row'}
               justifyContent={'center'}
               onFocus={handleOnFocus}
               className='search_input'
  >
    <Grid className='text_input' item xs={6} lg={4}
          ref={containerRef}>
      <Paper className={classes.paper}>
        <Grid container
              direction={'column'}
              justifyContent={'space-evenly'}
              alignItems={'stretch'}
              className={'h-100'}
        >
          <Grid item>
            <form className={classes.form} onSubmit={handleOnSubmit}>
              <PlacesAutocompleteInputMui name={name}
                                          value={value}
                                          onChange={handleValueChange}
                                          selectedPacItem={selectedPacItem}
                                          handleClickPac={handleClickPac}
                                          handleSelectPac={handleSelectPac}
                                          onCancelIconClick={handleCancelIconClick}
                                          containerRef={containerRef}
                                          showPac={showPac}
                                          autoFocus
              />
            </form>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  </Grid>
}

LandingSearchInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  onCancelIconClick: PropTypes.func,
  selectedPacItem: PropTypes.object,
  handleClickPac: PropTypes.func,
  handleSelectPac: PropTypes.func,
  onSearchSubmit: PropTypes.func,
}

export default LandingSearchInput;
