import React, { useRef, useState } from "react";
import { TableCell, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { DeleteForever, MoreHoriz, Edit } from "@mui/icons-material";
import {UserTypeDBFormat} from "../../constants/securspace-constants";


const UsersTableActionsMenu = ({user, handleEdit, classes, onDeleteUser, setUserToDelete, index}) => {

  const [menuOpen, setMenuOpen] = useState(false);

    const iconRef = useRef(null);

    const onEdit = () => {
      handleEdit(user)
    }

    const onDelete = () => {
      setUserToDelete(user)
      onDeleteUser(user)
    }

    const onIconClick = () => {
      setMenuOpen(prev => !prev)
    }

    const handleCloseUserActions = () => {
      setMenuOpen(false);
    };

    return <TableCell align={'right'}>
      <IconButton
        onClick={onIconClick}
        aria-haspopup={true}
        aria-controls={'ss-user-actions'}
        ref={iconRef}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id={'ss-user-actions'}
        anchorEl={iconRef.current}
        open={menuOpen}
        onClick={handleCloseUserActions}
        onClose={handleCloseUserActions}
        keepMounted
      >

        <MenuItem
          id={`${index} ${menuOpen}`}
          onClick={onEdit}
          className={classes.columnGap8}
        >
          <ListItemIcon className={classes.minWidthAuto}>
            <Edit className={classes.fontColorSecondaryDark} fontSize={'small'}/>
          </ListItemIcon>
          <ListItemText
            primary={'Edit'}
            primaryTypographyProps={{
              variant: 'subtitle2',
              color: 'secondary'
            }}
          />
        </MenuItem>
        {user && user?.role !== UserTypeDBFormat.ROLE_USERTYPE_PRIMARY_OWNER &&
          <MenuItem onClick={onDelete} className={classes.columnGap8}>
            <ListItemIcon className={classes.minWidthAuto}>
              <DeleteForever className={classes.fontColorSecondaryDark} fontSize={'small'}/>
            </ListItemIcon>
            <ListItemText
              primary={'Delete'}
              primaryTypographyProps={{
                variant: 'subtitle2',
                color: 'secondary'
              }}
            />
          </MenuItem>
        }
      </Menu>
    </TableCell>

}

export default UsersTableActionsMenu;
