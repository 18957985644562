import React from 'react';
import '../../css/components/report/report-form.css';

const ReportForm = (props) => {
  const {children} = props;
  return <form className="ss-report-form-base">
    {children}
  </form>
}

export default ReportForm;
