import React from 'react'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ToastContainerWrapper = () => {
    return (
        <ToastContainer
            className="ss-toast-container"
            toastClassName="ss-toast"
            position="top-center"
            autoClose={10000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            draggable
            pauseOnHover
            theme="colored"
            limit={4}
        />
    )
}

export default ToastContainerWrapper
