import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {
  checkin,
  requestBooking,
  requestCheckInFieldsForAccountId
} from "../components/checkin/request/check-in-requests";
import {correlationIdRequest} from "../components/checkin/request/correlationId-request";
import {toast} from "react-toastify";
import Busy from "../components/Busy";
import Select from "../components/Select";
import ReferenceOption from "../controls/ReferenceOption";
import LoadedContainerCheckInForm from "../components/LoadedContainerCheckInForm";
import EmptyContainerCheckInForm from "../components/EmptyContainerCheckInForm";
import ChassisOnlyCheckInForm from "../components/ChassisOnlyCheckInForm";
import TrailerLoadedCheckInForm from "../components/TrailerLoadedCheckInForm";
import TrailerEmptyCheckInForm from "../components/TrailerEmptyCheckInForm";
import TruckOnlyCheckInForm from "../components/TruckOnlyCheckInForm";
import ConfirmDialogBlock from "../components/ConfirmDialogBlock";
import {uploadInventoryImage, updateGallery} from "../components/checkin/request/file-upload-requests";

import "../css/theme/forms.css";
import "../css/components/checkInParentControl.css";
import "../css/theme/buttons.css";
import {getErrorMessageForStandardResponse} from "../util/NetworkErrorUtil";


const GALLERY_BUCKET = "inventory";

const ACTIVE_ASSET_TYPES = [
  {key: 'CONTAINER_LOADED', value: 'Container / Chassis (Loaded)'},
  {key: 'CONTAINER_EMPTY', value: 'Container / Chassis (Empty)'},
  {key: 'CHASSIS_ONLY', value: 'Chassis Only'},
  {key: 'TRUCK_TRAILER_LOADED', value: 'Truck + Trailer (Loaded)'},
  {key: 'TRUCK_TRAILER_EMPTY', value: 'Truck + Trailer (Empty)'},
  {key: 'TRUCK_ONLY', value: 'Truck Only'},
  {key: 'TRUCK_ONLY_25', value: "Truck Only (25')"},
  {key: 'TRAILER_LOADED', value: 'Trailer (Loaded)'},
  {key: 'TRAILER_EMPTY', value: 'Trailer (Empty)'},
  {key: 'REEFER_LOADED_PLUGIN', value: 'Refrigerated (Loaded) (Plug In)'},
];

const CheckIn =  (props) => {
  const history = useNavigate();
  const { bookingId } = useParams();

  const accountId = props.account.id;

  const [ booking, setBooking ] = useState(null);
  const [ assetType, setAssetType ] = useState("");
  const [ assetTypeOptions, setAssetTypeOptions ] = useState([]);
  const [ correlationId, setCorrelationId ] = useState(null);
  const [ fieldMap, setFieldMap ] = useState(null);
  const [ equipmentDataErrorMessage, setEquipmentDataErrorMessage ]= useState(null);

  const [ showCancelConfirmation, setShowCancelConfirmation ] = useState(false);

  const [ checkInForm, setCheckInForm ] = useState(null);

  const [ failedToLoadBooking, setFailedToLoadBooking ] = useState(false);

  let dropzone = null;

  const navigateToCheckInPage = () => {
    history("/check-in");
  }

  useEffect(() => {
    const abortController = new AbortController();
    Busy.set(true);
    async function fetchData() {
      const { body } = await requestBooking(bookingId);
      return body;
    }
    fetchData()
      .then((data) => {
        setBooking(data);
      })
      .catch(() => {
        toast.error("There was problem finding the Booking");
        setFailedToLoadBooking(true);
      }).then(() => Busy.set(false));
    return () => {
      abortController.abort()
    }
  }, [bookingId]);

  useEffect(function fetchPrerequisites() {
    const abortController = new AbortController();
    const checkInFieldsForAccountIdPromise = requestCheckInFieldsForAccountId(accountId);
    const correlationIdPromise = correlationIdRequest();
    Promise.allSettled([checkInFieldsForAccountIdPromise, correlationIdPromise]).then(([checkInFieldsResponse, correlationIdResponse]) => {
      try {
        setFieldMap(checkInFieldsResponse.value.body);
        setCorrelationId(correlationIdResponse.value.body);
      } catch(error) {
        toast.error("There was an error loading Checkin Form");
      }
    });
    return () => {
      abortController.abort()
    }
  }, [accountId]);


  useEffect(function populatesAssetTypeOptions() {
    const abortController = new AbortController();
    setAssetTypeOptions(ACTIVE_ASSET_TYPES.map((item) => {
      return new ReferenceOption(item.key, item.value);
    }));
    return () => {
      abortController.abort()
    }
  }, [bookingId]);

  useEffect(function assetTypeChanged() {
    const abortController = new AbortController();
    switch (assetType.value) {
      case  "CONTAINER_LOADED":
      case "TRUCK_CONTAINER_LOADED":
        setCheckInForm(<LoadedContainerForm/>);
        break;
      case "CONTAINER_EMPTY":
      case "TRUCK_CONTAINER_EMPTY":
        setCheckInForm(<EmptyContainerForm/>);
        break;
      case "CHASSIS_ONLY":
        setCheckInForm(<ChassisOnlyForm/>);
        break;
      case "TRUCK_TRAILER_LOADED":
      case "TRAILER_LOADED":
      case "REEFER_LOADED_NO_PLUGIN":
      case "TRUCK_REEFER_LOADED_PLUGIN":
      case "TRUCK_REEFER_LOADED_NO_PLUGIN":
      case "REEFER_LOADED_PLUGIN":
        setCheckInForm(<TrailerLoadedForm/>);
        break;
      case "TRUCK_TRAILER_EMPTY":
      case "TRAILER_EMPTY":
        setCheckInForm(<TrailerEmptyForm/>);
        break;
      case "TRUCK_ONLY":
        setCheckInForm(<TruckOnlyForm/>);
        break;
      case "TRUCK_ONLY_25":
        setCheckInForm(<TruckOnlyForm/>);
        break;
      default:
        setCheckInForm(null);
    }
    return () => {
      abortController.abort()
    }
  }, [assetType]);

  const handleEquipmentError = (errorMessage) => {
    setEquipmentDataErrorMessage(errorMessage);
  };

  const handleEquipmentErrorCleared = () => {
    setEquipmentDataErrorMessage(null);
  };

  const showCancelConfirmationDialog = () => {
    setShowCancelConfirmation(true);
  }

  const toastSuccessMessage= () => {
    toast.success("Successfully checked in!");
  }

  const handleSuccessfulSave = () => {
    Busy.set(false);
    toastSuccessMessage();
    navigateToCheckInPage();
  }

  const saveCheckInForm = (formValues) => {
    if (typeof formValues.dropzone !== "undefined") {
      dropzone = formValues.dropzone;
    } else {
      dropzone = null;
    }
    Busy.set(true);

    const data = {
      bookingId: booking.id,
      locationId: booking.location.id,
      buyerId: booking.buyerAccount.id,
      containerNumber: formValues.containerNumber,
      trailerNumber: formValues.trailerNumber,
      chassisNumber: formValues.chassisNumber,
      chassisLicensePlateNumber: formValues.chassisLicensePlateNumber,
      sealNumber: formValues.sealNumber,
      driverFirstName: formValues.driverFirstName,
      driverLastName: formValues.driverLastName,
      driverLicenseNumber: formValues.driverLicenseNumber,
      truckLicensePlateNumber: formValues.truckLicensePlateNumber,
      notes: formValues.notes,
      assetSize: formValues.assetSize,
      assetType: formValues.assetType.value,
      equipmentDataErrorMessage: equipmentDataErrorMessage,
      correlationId: correlationId,
      checkInUser: props.account.loggedInUsername
    };

    checkin(data)
      .then(async (resp) => {
        const data = resp.body;
        if (data.id) {
          if (dropzone && dropzone.files.length > 0) {
            await saveGallery(data);
          } else {
            handleSuccessfulSave();
          }
        }
      }).catch(err => {
        toast.error(getErrorMessageForStandardResponse(err))
    }).finally(() => {
      Busy.set(false);
    });

  };

  const saveGallery = async(checkout) => {
    const galleryFileNames = [];
    if (dropzone && dropzone.files.length > 0) {
      for (const file of dropzone.files) {
        if (file && file.type !== "fake") {
          const fileName = await uploadFileData(GALLERY_BUCKET, file, checkout.id);
          // update gallery when image uploaded successfully
          if (fileName) {
            galleryFileNames.push(fileName);
          }
        } else {
          galleryFileNames.push(file.name);
        }
      }
      await updateGalleryTable(checkout, galleryFileNames);
    }
  }

  const uploadFileData = async(folder, file, inventoryId) => {

    const fileName = `${new Date().getTime()}_${file.name}`;
    try {
      return await uploadInventoryImage(inventoryId, file.dataURL, fileName, file.type);
    } catch (error) {
      toast.warn(`Image upload failed: ${error}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const updateGalleryTable = (checkout, galleryFileNames) => {
    // save files names in gallery table and delete???
    updateGallery(checkout, galleryFileNames)
      .then(() => {
        handleSuccessfulSave();
      })
      .catch((error) => {
        toastTotalImageErrors(error);
        Busy.set(false);
        navigateToCheckInPage();
      });
  }

  const toastTotalImageErrors = (error) => {
    toast.warn(`Failed to update gallery ${error}`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  /**
   * LoadedContainerCheckInForm component
   * @returns {JSX.Element}
   * @constructor
   */
  const LoadedContainerForm = () => {
    return (
      <LoadedContainerCheckInForm
        gallery={GALLERY_BUCKET}
        booking={booking}
        handlePanelCloseEvent={showCancelConfirmationDialog}
        handleFormSave={saveCheckInForm}
        assetType={assetType}
        handleEquipmentError={handleEquipmentError}
        handleEquipmentErrorCleared={handleEquipmentErrorCleared}
        customFields={fieldMap}
        correlationId={correlationId}
      />
    )
  }

  /**
   * EmptyContainerForm component
   * @returns {JSX.Element}
   * @constructor
   */
  const EmptyContainerForm = () => {
    return (
      <EmptyContainerCheckInForm
        gallery={GALLERY_BUCKET}
        booking={booking}
        handlePanelCloseEvent={showCancelConfirmationDialog}
        handleFormSave={saveCheckInForm}
        assetType={assetType}
        handleEquipmentError={handleEquipmentError}
        handleEquipmentErrorCleared={handleEquipmentErrorCleared}
        customFields={fieldMap}
        correlationId={correlationId}
      />
    )
  }

  /**
   * ChassisOnlyCheckInForm component
   * @returns {JSX.Element}
   * @constructor
   */
  const ChassisOnlyForm = () => {
    return (
      <ChassisOnlyCheckInForm
        gallery={GALLERY_BUCKET}
        booking={booking}
        handlePanelCloseEvent={showCancelConfirmationDialog}
        handleFormSave={saveCheckInForm}
        assetType={assetType}
        customFields={fieldMap}
        correlationId={correlationId}
      />
    );
  }

  /**
   * TrailerLoadedCheckInForm component
   * @returns {JSX.Element}
   * @constructor
   */
  const TrailerLoadedForm = () => {
    return (
      <TrailerLoadedCheckInForm
        gallery={GALLERY_BUCKET}
        booking={booking}
        handlePanelCloseEvent={showCancelConfirmationDialog}
        handleFormSave={saveCheckInForm}
        assetType={assetType}
        customFields={fieldMap}
        correlationId={correlationId}
      />
    );
  }

  /**
   * TrailerEmptyCheckInForm component
   * @returns {JSX.Element}
   * @constructor
   */
  const TrailerEmptyForm = () => {
    return (
      <TrailerEmptyCheckInForm
        gallery={GALLERY_BUCKET}
        booking={booking}
        handlePanelCloseEvent={showCancelConfirmationDialog}
        handleFormSave={saveCheckInForm}
        assetType={assetType}
        customFields={fieldMap}
        correlationId={correlationId}
      />
    );
  }

  /**
   * TruckOnlyCheckInForm component
   * @returns {JSX.Element}
   * @constructor
   */
  const TruckOnlyForm = () => {
    return (
      <TruckOnlyCheckInForm
        gallery={GALLERY_BUCKET}
        booking={booking}
        handlePanelCloseEvent={showCancelConfirmationDialog}
        handleFormSave={saveCheckInForm}
        assetType={assetType}
        customFields={fieldMap}
        correlationId={correlationId}
      />
    );
  }

  const handleAssetTypeChange = (event) => {
    setAssetType(event.target.value);
  }

  return (
        <>
          <div className="popup-header">
            <img
              alt=""
              src="https://static.secur.space/app-images/login.png"
            />
            <div>
              <h1 className="w100">Check In Asset</h1>
              <h4 className="blue-txt">
                {booking?.buyerAccount?.companyName}
              </h4>
            </div>
            <ConfirmDialogBlock
              showAlert={showCancelConfirmation}
              title="Confirmation"
              onClose={() => {
                setShowCancelConfirmation(false);
              }}
              proceedEventHandler={navigateToCheckInPage}>
              Are you sure you want to cancel?
            </ConfirmDialogBlock>
            <button
              type="button"
              className="close move-right app_btn"
              aria-label="Close"
              onClick={showCancelConfirmationDialog}
            >
              <img alt="" src="../app-images/close.png" />
            </button>
          </div>
          {
            !failedToLoadBooking ? (
              <>
                <div className="ss-check-in-parent-container">
                  <form className="ss-form ss-block">
                    <div className="checkin-selector">
                      <label>ASSET TYPE</label>
                      {
                        assetTypeOptions ? (
                          <Select
                            id="assetType"
                            name="assetType"
                            className="ss-book-space-form-asset-type"
                            optionsWidth="300px"
                            handleChange={handleAssetTypeChange}
                            selectedOption={assetType}
                            placeholder="Select the equipment type being checked in"
                            options={assetTypeOptions}
                          />
                        ) : ""
                      }
                      {checkInForm}
                    </div>
                  </form>
                </div>
              </>
            ) : ""
          }
        </>
  )
}

export default CheckIn;
