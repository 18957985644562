import React from 'react';
import ReportDataSummary from "../report/ReportDataSummary";
import DownloadCSVButton from "../report/export/DownloadCSVButton";
import {getReadyForPayoutGroupSummaryReportUrl, getReadyForPayoutReportUrl} from "../../views/requests/account-report";
import {Grid, useMediaQuery} from "@mui/material";

type PayoutReportDataSummaryProps = {
  accountId: string,
  query: {
    locationId?: string,
    sortBy?: string,
    sortDir?: string,
  },
  count: number,
  title: string,
};


/**
 * Displays a summary of the Payout Report. Includes buttons to download the report and report as a group summary in CSV format.
 *
 * @param accountId
 * @param query
 * @param count
 * @param title
 * @returns {Element}
 * @constructor
 */
const PayoutReportDataSummary: (props: PayoutReportDataSummaryProps) => JSX.Element = ({accountId, query,  count, title}) => {
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  return (
    <ReportDataSummary count={count} title={title}>
      <Grid item container lg xs={12} gap={2} justifyContent={isLgUp ? 'end' : 'start'} wrap={isLgUp ? 'nowrap' : 'wrap'}>
        <Grid item>
          <DownloadCSVButton
            title={'Export Group Summary'}
            reportUrl={getReadyForPayoutGroupSummaryReportUrl(accountId, query)}
          />
        </Grid>
        <Grid item>
          <DownloadCSVButton
            title={'Download Report'}
            reportUrl={getReadyForPayoutReportUrl(accountId, query)}
          />
        </Grid>
      </Grid>
    </ReportDataSummary>
  )
};

export default PayoutReportDataSummary;