import React from 'react';
import Iframe from "react-iframe";
import '../css/views/externalLanding.css';

const ExternalLanding = ({url, height}) => {

  return (
    <>
      <Iframe
        url={url}
        position="relative"
        width="100%"
        height={height}
        className="ss-external-landing"
      />
    </>
  );
}

export default ExternalLanding;
