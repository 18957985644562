import React, {memo} from 'react';
import {InputAdornment} from "@mui/material";
import {Search} from "@mui/icons-material";
import DelayedTextField from "../../components/DelayedTextField";

type Props = {
  value: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchEquipmentType: (props: Props) => JSX.Element =
  ({value, onChange}: Props) => {
    return (
      <DelayedTextField
        delay={300}
        variant="standard"
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
        name="equipmentType"
        value={value}
        onChange={onChange}
        placeholder="Filter results by equipment number"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search fontSize='medium'/>
            </InputAdornment>
          ),
        }}
        fullWidth
        label="Equipment Number"
        sx={{
          '& input::placeholder': {
            fontSize: '1em'
          }
        }}
      />
    )
};

export default memo(SearchEquipmentType);