import React from 'react';
import { Swiper } from "swiper/react";

const SwiperCarousel = ({
    isNextDisabled,
    disabled,
    setDisabled,
    setDisabledRight,
    sliderRef,
    children
}) => {


    return (
        <>
            <Swiper
                spaceBetween={50}
                slidesPerView="auto"
                ref={sliderRef}
                onReachEnd={() => !isNextDisabled ? setDisabled(true) : console.log('')}
                onActiveIndexChange={(swiper) => {
                    if (!isNextDisabled) {
                        setDisabledRight(swiper?.activeIndex)
                        if (disabled) setDisabled(false)
                    }
                }}
            >

                {children}
            </Swiper>
        </>
    )
}

export default SwiperCarousel