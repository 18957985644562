import React, { useState } from 'react'
import { Button, InputAdornment, TextField } from '@mui/material';
import Busy from '../../components/Busy';
import Error from '../../components/Error';
import Success from '../../components/Success';
import SuperAgent from 'superagent'



const ForgotForm = () => {

    const [email, setEmail] = useState("");
    const [state, setState] = useState({
        resetSuccessful: false,
        errorMessage: ''
    })
    async function handleForgotPassword(e) {
        e.preventDefault();
        Busy.set(true);

        try {
            await SuperAgent.post(`api/reset-password`).send({
                username: email.toLowerCase()
            }).set('accept', 'json');
            Busy.set(false);
            setEmail("")
            setState({
                resetSuccessful: true,
                errorMessage: ''
            });
        } catch (error) {
            Busy.set(false);
            let errorMessage = error.responseJSON ? error.responseJSON.message : "Password reset failed";
            setState({
                resetSuccessful: false,
                errorMessage: errorMessage
            });
        }
    }

    return (
        <form onSubmit={handleForgotPassword} className='ss-forgot-password-form'>
            <TextField
                type='email'
                InputLabelProps={{
                    shrink: true,
                }}
                value={email}
                autoFocus={true}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Please enter your mail'
                className='ss-forgot-password-input'
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <img src="https://static.secur.space/icons/Email.png" alt="Icon" className='ss-forgot-password-icon-email' />
                        </InputAdornment>
                    ),
                }}
                fullWidth
                variant="standard"
                label="Email"
            />
            {
                state.errorMessage ?
                    <Error>{state.errorMessage}</Error>
                    :
                    ''
            }
            {
                state.resetSuccessful ?
                    <Success>If you have an account, you'll receive an email with a link to reset your password.</Success>
                    :
                    ''
            }
            <div className="clear"></div>
            <Button disabled={!email} fullWidth type='submit' variant="contained" color="primary">
                SUBMIT
            </Button>
        </form>
    )
}

export default ForgotForm
