import React, {useState, useEffect} from "react";
import { Grid, Typography } from "@mui/material";
import { formatCurrencyValue, getPricePerDayFromMonthlyRate } from "../../../../util/PaymentUtils";
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { makeStyles } from '@mui/styles';



const useStyles = makeStyles({
    price: {
        display:'flex',
        justifyContent:'flex-end',
    },
    subprice:{
        display:'flex',
        justifyContent:'flex-end',
        '@media (max-width: 39.28em)': {
            fontSize: '0.8rem',
        }
    }
  });

const EquipmentTypeItem = (props) => {
    const {asset, defaultPricePerDay, defaultPricePerMonth} = props;
    const classes = useStyles();


    const [pricePerDayInDollars, setPricePerDayInDollars] = useState();
    const [pricePerMonthInDollars, setPricePerMonthInDollars] = useState(defaultPricePerMonth);
    const [pricePerDayPerMonth, setPricePerDayPerMonth] = useState();

    const {equipmentType, pricePerDay, pricePerMonth} = asset;

    useEffect(() => {
        const dailyPriceInCents = pricePerDay !== null && pricePerDay > 0 ? pricePerDay : defaultPricePerDay;
        const monthlyPriceInCents = pricePerMonth !== null && pricePerMonth > 0 ? pricePerMonth : defaultPricePerMonth;
        setPricePerDayInDollars(formatCurrencyValue(dailyPriceInCents));
        setPricePerMonthInDollars(formatCurrencyValue(monthlyPriceInCents));

        const pricePerDayBasedOnMonthInCents = getPricePerDayFromMonthlyRate(monthlyPriceInCents).amount;
        const pricePerDayBasedOnMonthInDollars = formatCurrencyValue(pricePerDayBasedOnMonthInCents);

        setPricePerDayPerMonth(pricePerDayBasedOnMonthInDollars)

    }, [pricePerDay, pricePerMonth, defaultPricePerDay, defaultPricePerMonth])

    return(
        <Grid container className="ss-location-profile-pricing-grid-item">
            <Grid item className="ss-location-profile-pricing-type-icon-container">
                <TrackChangesIcon className="ss-location-profile-pricing-icon"/>
                <Typography variant='body1' className="ss-ocation-profile-pricing-title">
                    {equipmentType}
                </Typography>
            </Grid>
            <Grid item className="ss-location-profile-pricing-subcontainer-items">
                <div className="ss-location-profile-pricing-subcontainer-item">
                    <Typography variant='body1' className={classes.price}>
                        {pricePerDayInDollars}
                    </Typography>
                </div>
                <div className="ss-location-profile-pricing-subcontainer-item">
                    <Typography variant='body1' className={classes.price}>
                        {pricePerMonthInDollars}
                    </Typography>
                    <Typography variant='body1' className={classes.subprice}>
                        ({pricePerDayPerMonth}/day)
                    </Typography>
                </div>
            </Grid>
        </Grid>
    )
}

export default EquipmentTypeItem;
