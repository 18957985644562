import React, {useState} from "react";
import {Modal} from "../../../react-router-modal";
import Busy from "../../Busy";
import { requestPatchInvoiceStatus } from "../request/invoice-requests";
import { invoiceCancelStatuses } from "../../constants/securspace-constants";
import ActionButton from "../../ActionButton";
import '../../../css/components/alert.css'
import '../../../css/components/cancelInvoiceNotesModal.css'
import '../../../react-router-modal/react-router-modal.css';

const CancelInvoiceNotesInput = (props) => {

    const {showNotes, setShowNotes, transactionId, successCancelInvoice, failCancelInvoice} = props;

    const [cancelStatusNotes, setCancelStatusNotes] = useState('');
    const [attemtedSubmitWithoutNotes, setAttemtedSubmitWithoutNotes] = useState(false)

    const handleNotesChange = event => {
        let value = event.target.value;

        setCancelStatusNotes(value)
    };

    const closeEventHandler = () => {
        setShowNotes(false)
    }

    const Required = () => <strong className="ss-cancel-invoice-required-field">Required: </strong>;

    const submit = () => {
        if (cancelStatusNotes === "") {
            setAttemtedSubmitWithoutNotes(true)
        } else {
            const statusAndNotes = {status: invoiceCancelStatuses.CANCEL_OTHER, statusNotes: cancelStatusNotes}
            Busy.set(true)
            requestPatchInvoiceStatus(transactionId, statusAndNotes, successCancelInvoice, failCancelInvoice)
        }
    }

    return (
        <div>
            {showNotes ?
                    <Modal
                        onBackdropClick={closeEventHandler}>

                        <div className='modal-content ss-cancel-invoice-modal-div ss-cancel-invoice-notes-modal-container'>
                            <div className='ss-cancel-invoice-notes-title-container d-flex'>
                                <h2 className="ss-cancel-invoice-modal-title">
                                    Cancel Invoice - Other
                                </h2>
                            </div>
                            <div className="ss-cancel-invoice-notes-input-container">
                                <h4 className="ss-cancel-invoice-notes-description">{ attemtedSubmitWithoutNotes ?
                                    <Required/>
                                    : null } Please provide an explanation for the cancellation</h4>
                                <textarea
                                    type="text"
                                    id="cancelStatusNotesInput"
                                    name="cancelStatusNotesInput"
                                    value={cancelStatusNotes}
                                    onChange={handleNotesChange}
                                    placeholder="Notes"
                                    className="ss-cancel-invoice-modal-notes-input"
                                />
                            </div>
                            <div className="ss-cancel-invoice-modal-button-container">
                                <div className='ss-button-primary-modal-form reverse'>
                                    <ActionButton label='SUBMIT' onClick={submit} />
                                </div>
                                <div className='ss-button-primary-modal-form reverse'>
                                    <ActionButton label='CANCEL' onClick={closeEventHandler} />
                                </div>
                            </div>
                        </div>
                    </ Modal>
                : null
            }
        </div>
    )
}

export default CancelInvoiceNotesInput
