import {useEffect} from 'react';

const useClickOutsideRef = (ref, onClickOutside) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event?.target)) {
        onClickOutside();
      }
    }
    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    }
  }, [ref, onClickOutside]);
}

export default useClickOutsideRef;
