import React from "react";
import { Grid, Typography } from "@mui/material";
import { FeatureIconMap, useStyles } from "../../search/FeatureIconMap";

const FeatureOptionItem = ( {feature} ) => {

    const classes = useStyles();

    return (
        <Grid item className='ss-location-features-option-item'>
            {
                FeatureIconMap[feature](classes, 'small', '#7C8597')
            }
            <Typography className='ss-location-features-item-name'>
                {feature}
            </Typography>
        </Grid>
    )
}

export default FeatureOptionItem;
