import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import Image from '../components/skeleton/ImageComponent'

const useStyles = makeStyles((theme) => ({
    numListings: { ...theme.components.helperText },
}));

const PopularLocationListingItem = (props) => {
    const classes = useStyles();

    return (
        <Grid className="location-panel" item  container>
            <Grid item md={3}>
                <Link to={props.linkTo}>
                    <Image
                        src={props.cityObj.imageUrl}
                        className="city-img"
                        alt={props.cityObj.city}
                        errorImg='../app-images/city_placeholder.jpg'
                        placeHolderimg="https://miro.medium.com/v2/resize:fit:512/0*pyRw1qikTI1eqGm9.gif"
                    />
                </Link>
            </Grid>
            <Grid className="location-details-container" item xs>
                <Link to={props.linkTo}>
                    <Typography className="location-name" variant="subtitle2" color="secondary">
                        {props.cityObj.city}
                    </Typography>
                </Link>
                <Typography className={classes.numListings} color="textSecondary">{props.cityObj.rank} listings</Typography>
            </Grid>
        </Grid>
    );
};

export default PopularLocationListingItem;
