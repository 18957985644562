
import ContainerValidator from "container-validator";
const containerValidator = new ContainerValidator();

function validateContainerNumber(containerNumber) {

    if (!containerNumber) {
        return false;
    }

    return containerValidator.isValid(containerNumber.toUpperCase());
}

function getContainerNumberErrorMessage(containerNumber) {

    if (!containerNumber) {
        return false;
    }

    containerValidator.validate(containerNumber.toUpperCase());
    return containerValidator.getErrorMessages();
}

export { getContainerNumberErrorMessage, validateContainerNumber };
