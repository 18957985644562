import request from "../../util/SuperagentUtils";

export const getNotificationsSettingsRequest = (accountId) => {
  return request
    .get(`/api/get-notification-settings?accountId=${accountId}`);
}

export const saveNotificationSettingsRequest = (data) => {
  return request
    .post(`/api/save-notification-settings`)
    .send(data);
}
