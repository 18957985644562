import { Button, Grid, InputAdornment, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { usePasswordValidation } from '../../hooks/usePasswordValidation';
import ValidationMessage from './ValidationMessage';
import Error from '../../components/Error'
import { toast } from 'react-toastify'
import SuperAgent from 'superagent'
import Busy from '../../components/Busy';
import URLUtils from '../../util/URLUtils';

const ChangePasswordForm = ({ isNewUser, history }) => {

  const [password, setPassword] = useState("");
  const [error, setError] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("");
  const token = URLUtils.getQueryVariable('token');
  const [
    validLength,
    hasNumber,
    upperCase,
    match,
    specialChar,
  ] = usePasswordValidation({
    password,
    confirmPassword,
  });


  async function handleChangePassword(e) {
    e.preventDefault();
    if (!match) return setError("Passwords don't match.")
    if (!validLength || !hasNumber || !upperCase || !match || !specialChar) return setError("Password must have at least: 8 characters, one lowercase, one uppercase, one number, and one symbol")
    if(password !== confirmPassword) setError("")
    Busy.set(true)
    try {
      await SuperAgent.post(`api/change-password`).send({
        token,
        password
      }).set('accept', 'json');
      Busy.set(false);
      let successMessage = isNewUser ? "Successfully set password!" : "Successfully changed password!";
      toast.success(successMessage);
      history('/login');
    } catch (error) {
      Busy.set(false);
      toast.error('Password change failed');
    }
  }

  useEffect(() => {
    if (!token) history('/login')
  }, [token, history])

  return (
    <form onSubmit={handleChangePassword} className='ss-change-password-form'>
      <TextField
        type='password'
        InputLabelProps={{
          shrink: true,
        }}
        autoFocus={true}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder='Password'
        className='ss-change-password'
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img src='https://static.secur.space/icons/Password.png' alt="Icon" className='ss-change-password-icon-password' />
            </InputAdornment>
          ),
        }}
        fullWidth
        label="Password"
        helperText={!validLength && `Create a password with at least 8 characters`}
        variant="standard"
      />
      <TextField
        type='password'
        InputLabelProps={{
          shrink: true,
        }}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        placeholder='Retype your password'
        className='ss-change-password ss-change-password-confirm'
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img src="https://static.secur.space/icons/Password.png" alt="Icon" className='ss-change-password-icon-password' />
            </InputAdornment>
          ),
        }}
        fullWidth
        label="Password"
        variant="standard"
      />
      <Grid className='ss-change-password-validation'>
        {validLength ? <ValidationMessage source="https://static.secur.space/icons/check.png" title="8 Characters" disabled={false} /> : <ValidationMessage source="https://static.secur.space/icons/unread.png" title="8 Characters" disabled={true} />}
        {upperCase ? <ValidationMessage source="https://static.secur.space/icons/check.png" title="Capital Letter" disabled={false} /> : <ValidationMessage source="https://static.secur.space/icons/unread.png" title="Capital Letter" disabled={true} />}
        {!specialChar ? <ValidationMessage source="https://static.secur.space/icons/unread.png" title="Symbol *&#" disabled={true} /> : <ValidationMessage source="https://static.secur.space/icons/check.png" title="Symbol *&#" disabled={false} />}
        {!hasNumber ? <ValidationMessage source="https://static.secur.space/icons/unread.png" title="Numeric" disabled={true} /> : <ValidationMessage source="https://static.secur.space/icons/check.png" title="Numeric" disabled={false} />}
        {error && <Error>{error}</Error>}
      </Grid>
      <Button disabled={!password || !confirmPassword} fullWidth type='submit' variant="contained" color="primary">
        CHANGE PASSWORD
      </Button>
    </form>
  )
}

export default ChangePasswordForm
