import React from 'react';
import '../../css/components/report/report-data.css';
import '../../css/views/accountReport.css'
import * as PropTypes from 'prop-types';

const ReportDataWithStatus = (props) => {
  const { label, children, title } = props;

  return (
    <div className="ss-report-data-base">
      <label className="ss-report-data-label">{label}</label>
      <div title={title} className="status report-value">{children || '-'}</div>
    </div>
  )
}

ReportDataWithStatus.propTypes = {
  label: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired,
}

export default ReportDataWithStatus;
