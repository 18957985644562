import React, {useCallback, useEffect, useState} from 'react';
import {Button, Grid, Typography, Theme} from "@mui/material";
import {CreditCard} from "@mui/icons-material";
import Busy from "../Busy";
import {requestPaymentMethods} from "./request/payment-method-requests";
import {AuthorityType} from "../constants/securspace-constants";
import PaymentMethodsList from "./PaymentMethodsList";
import PropTypes from "prop-types";
import VerifyBankAccountDialog from "./VerifyBankAccountDialog";
import AddPaymentMethodFlowManager from "./AddPaymentMethodFlowManager";
import RenamePaymentMethodDialog from "./RenamePaymentMethodDialog";
import RemovePaymentMethodDialog from "./RemovePaymentMethodDialog";
import {withSnackbar} from "../hocs/withSnackbar";
import { makeStyles } from '@mui/styles';

const useStyles: (theme: Theme) => {
  gap15: CSSStyleSheet,
  addPaymentButton: CSSStyleSheet,
} = makeStyles(() => ({
  gap15: {
    gap: '1.07rem'
  },
  addPaymentButton: {
    textTransform: 'uppercase'
  },
}));

const BuyerPaymentMethods = ({ account, handleLogout, snackbarShowMessage }) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentToVerify, setPaymentToVerify] = useState(null);
  const [paymentToEdit, setPaymentToEdit] = useState(null);
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [showRenamePaymentDialog, setShowRenamePaymentDialog] = useState(false);
  const [showDeletePaymentDialog, setShowDeletePaymentDialog] = useState(false);

  const classes = useStyles();

  const loadPaymentMethods = useCallback(() => {
    Busy.set(true);
    requestPaymentMethods(
      account.authorityId || account.id,
      account.authorityType ? account.authorityType : AuthorityType.ACCOUNT,
      (response) => {
        setPaymentMethods(response);
        Busy.set(false);
      },
      (error) => {
        Busy.set(false);
        snackbarShowMessage(error, 'error', 15000);
      }
    );
  }, [account, snackbarShowMessage]);

  useEffect(() => {
    loadPaymentMethods();
  }, [loadPaymentMethods]);

  const openAddPaymentModal = () => {
    setShowAddPaymentModal(true);
  };

  const handlePaymentVerificationSuccess = () => {
    setPaymentToVerify(null);
    loadPaymentMethods();
  };

  const closePaymentVerificationDialog = () => {
    setPaymentToVerify(null);
  };

  const handlePaymentVerification = useCallback(function handlePaymentVerification(paymentMethod) {
    setPaymentToVerify({...paymentMethod});
  }, [setPaymentToVerify]);

  const closeRenamePaymentDialog = () => {
    setShowRenamePaymentDialog(false);
    setPaymentToEdit(null);
  };

  const handlePaymentRename = () => {
    setPaymentToEdit(null);
    setShowRenamePaymentDialog(false);
    snackbarShowMessage('Payment method nick name updated successfully', 'success', 8000);
    loadPaymentMethods();
  };

  const handleCloseRemovePaymentDialog = () => {
    setShowDeletePaymentDialog(false);
    setPaymentToEdit(null);
  };

  const handlePaymentRemove = () => {
    setPaymentToEdit(null);
    setShowDeletePaymentDialog(false);
    snackbarShowMessage('Payment method removed successfully', 'success', 8000);
    loadPaymentMethods();
  };

  const handlePaymentMenuAction = useCallback((actionName: string, paymentMethod) => {
    setPaymentToEdit({...paymentMethod });
    switch (actionName) {
      case 'rename':
        setShowRenamePaymentDialog(true);
        break;
      case 'remove':
        setShowDeletePaymentDialog(true);
        break;
      default:
        break;
    }
  }, [setPaymentToEdit, setShowRenamePaymentDialog, setShowDeletePaymentDialog]);

  return (
    <Grid container direction={'column'} className={classes.gap15}>
      <Grid item container justifyContent={'space-between'} className={classes.gap15}>
        <Typography variant={'h5'} component={'h1'}>Payment Method</Typography>
        <Button
          onClick={openAddPaymentModal}
          className={classes.addPaymentButton}
          color='secondary'
          startIcon={<CreditCard/>}
          variant='contained'
        >
          Add Payment
        </Button>
      </Grid>
      <PaymentMethodsList
        paymentMethods={paymentMethods}
        onMenuAction={handlePaymentMenuAction}
        verifyPaymentMethod={handlePaymentVerification}
      />
      <AddPaymentMethodFlowManager
        account={account}
        setOpen={setShowAddPaymentModal}
        open={showAddPaymentModal}
        loadPaymentMethods={loadPaymentMethods}
      />
      <VerifyBankAccountDialog
        open={paymentToVerify?.id !== undefined}
        onClose={closePaymentVerificationDialog}
        paymentMethodId={paymentToVerify?.id ? paymentToVerify.id : ''}
        handleLogout={handleLogout}
        onSuccess={handlePaymentVerificationSuccess}
      />
      <RenamePaymentMethodDialog
        open={showRenamePaymentDialog}
        onClose={closeRenamePaymentDialog}
        handleLogout={handleLogout}
        account={account}
        paymentMethod={paymentToEdit}
        onSuccess={handlePaymentRename}
      />
      <RemovePaymentMethodDialog
        open={showDeletePaymentDialog}
        onClose={handleCloseRemovePaymentDialog}
        handleLogout={handleLogout}
        account={account}
        paymentMethod={paymentToEdit}
        onSuccess={handlePaymentRemove}
      />
    </Grid>
  );
};

BuyerPaymentMethods.propTypes = {
  account: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default withSnackbar(BuyerPaymentMethods);
