import React from 'react'
import { Box, Typography, Button, Modal, TextField } from "@mui/material";
import {ClassNameMap} from "@mui/styles";
import { InventoryCorrectionReasonLabels, inventoryCorrectionReasons } from '../constants/securspace-constants';

const InventoryModal = (props: {
    correctionReason: string,
    onDeleteInterchangeActivity: () => void,
    type: string,
    error: boolean,
    handleClose: () => void,
    classes: ClassNameMap,
    rejectReason: string,
    setRejectReason: (value: string) => void,
    orderNumber: string,
    setOrderNumber: (orderNumber: string) => void,
    handleSubmit: () => void,
    errorMessages: string,
    modalTypeOptions: []
}) => {
    const {
        correctionReason,
        onDeleteInterchangeActivity,
        type,
        error,
        handleClose,
        classes,
        rejectReason,
        setRejectReason,
        orderNumber,
        setOrderNumber,
        handleSubmit,
        errorMessages,
        modalTypeOptions
    } = props

    const openModal = type === modalTypeOptions.ACCEPT || type === modalTypeOptions.REJECT;

    


    const showOwnershipCorrectionTitle = () => {
        const ownershipLabel = InventoryCorrectionReasonLabels.filter(item => item.value === inventoryCorrectionReasons.ASSET_DOES_NOT_BELONG_TO_BUYER)[0].label;
        return type === modalTypeOptions.ACCEPT && correctionReason === ownershipLabel;
    }

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            sx={{
                m:2
            }}
        >
            <Box className={classes.modal}>
                <Box sx={{ p: 4 }}>
                    <Typography variant="h6">
                        {showOwnershipCorrectionTitle() ? 'Move Inventory to Different Booking' : type === "review" ? "Review Inventory Correction" : 'Reject Reason'}
                    </Typography>
                    {
                     type === modalTypeOptions.REJECT ?
                            <TextField
                                type='text'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                                value={rejectReason}
                                onChange={(e) => setRejectReason(e.target.value)}
                                placeholder='Type your reason here'
                                fullWidth
                                label="Enter the reason for rejection"
                                className={classes.textField}
                                helperText={error && errorMessages}
                            />
                            : <TextField
                                type='text'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                                value={orderNumber}
                                onChange={(e) => setOrderNumber(e.target.value)}
                                placeholder='Enter the order number'
                                fullWidth
                                label="Booking Number"
                                className={classes.textField}
                                helperText={error && errorMessages === 'The booking entered is invalid.' && errorMessages}
                            />
                    }
                </Box>
                <Box className={classes.btnGroup}>
                    {
                        showOwnershipCorrectionTitle() &&
                      <Button onClick={onDeleteInterchangeActivity} sx={{marginRight: 'auto'}}>Unable to determine booking number</Button>
                    }
                        <Button
                            variant="text"
                            onClick={handleClose}
                            color="secondary">
                            Cancel
                        </Button>
                        <Button
                            variant="text"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            {type === modalTypeOptions.ACCEPT ? 'SAVE CHANGES' : 'Submit'}
                        </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default InventoryModal