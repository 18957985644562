import React, { useCallback, useEffect, useState } from 'react';
import SuperAgent from 'superagent'
import '../../css/views/externalLanding.css'
import BlogSection from '../../components/blog/page/BlogSection';
import useDebounce from '../../hooks/useDebounce';


const Blog = () => {
    const [search, setSearch] = useState('')
    const searchDebounceVal = useDebounce(search, 600)
    const [email, setEmail] = useState('');
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const LIMIT = 9;
    const [offset, setOffset] = useState(0)
    const [totalPage, setTotalPage] = useState(1)
    const hasSearchVal = searchDebounceVal !== ""

    const fetchData = useCallback(async () => {
        setLoading(true)
        try {
            let requestUrl = `api/blog/posts?limit=${LIMIT}&offset=${offset ?? ""}&search=${searchDebounceVal ?? ""}`
            const { body } = await SuperAgent.get(requestUrl);
            setBlogs(body?.results)
            setTotalPage(body?.total)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }

    }, [offset, searchDebounceVal])

    useEffect(() => {
        fetchData()
    }, [offset, hasSearchVal, fetchData])

    return (
        <BlogSection totalPage={Math.ceil(totalPage / LIMIT)} setOffset={setOffset} search={search} setSearch={setSearch} email={email} setEmail={setEmail} blogs={blogs} loading={loading} />
    )
}

export default Blog