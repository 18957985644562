import React from 'react';
import { useListingImgStyles } from '../../hooks/components/location/useListingImgStyles';

const ImageNotAvailablePlaceholder = (props) => {
  const classes = useListingImgStyles(props);

  return <div className={classes.container} >
    <img src={'/app-images/not-found-image/place_holder_image.png'}
      alt={'placeholder'}
      className={classes.img}
    />
  </div >
}

export default ImageNotAvailablePlaceholder;
