import moment from "moment";

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy, false);
}

function descendingComparator(a, b, orderBy, placeEmptyAtEnd = true) {
    const emptyValues = [null, "-", ""];
    if (emptyValues.includes(a[orderBy]) && emptyValues.includes(b[orderBy])) {
        return 0;
    } else if (emptyValues.includes(a[orderBy])) {
        return placeEmptyAtEnd ? 1 : -1;
    } else if (emptyValues.includes(b[orderBy])) {
        return placeEmptyAtEnd ? -1 : 1;
    }

    const bDate = moment(b[orderBy]);
    const aDate = moment(a[orderBy]);
    if (aDate.isValid() && bDate.isValid()) {
        if (moment(b[orderBy]).isBefore(moment(a[orderBy]))) return -1;
        if (moment(b[orderBy]).isAfter(moment(a[orderBy]))) return 1;
        return 0;
    }
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


export { descendingComparator, getComparator, stableSort };