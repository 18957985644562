import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        paddingTop: '2.5rem',
        paddingBottom: '2.86rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '8.04rem',
            paddingRight: '8.04rem',
        }
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth:'40em',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '0.5em',
    },
    reviewModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth:'50em',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '0.5em',
    },
    correctionHeaderCol: {
        width: "30%",
        marginTop: "2em"
    },
    correctionDataColSingle: {
        width: "35%",
        marginTop: "2em"
    },
    correctionDataColDouble: {
        width: "70%",
        marginTop: "2em"
    },
    correctionDisplayContainer: {
        width: "100%",
    },
    hidden: {
        display: "none",
    },
    textField:{
        marginTop: '1.5em',
        '& label': {
            color: theme.palette.primary.main,
            fontWeight: '500',
        },
        '& input::placeholder': {
            fontSize: '1em',
            color: 'rgba(0, 0, 0, 0.87)',
        },
        '& p':{
            color: theme.palette.error.main,
            fontSize: '0.75em',
        }
    },
    btnGroup:{
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '0.3em',
        backgroundColor: theme.palette.grey[100],
        padding: '0.5em 1em',
    },
    correctionReason: {
        fontSize: "1.15em",
        fontWeight: "600"
    },
    correctedField: {
        backgroundColor: "#3F51B5",
        color: "white",
        paddingLeft: "5px",
        marginLeft: "-5px",
        borderRadius: "4px",
    },
    originalField: {
        backgroundColor: theme.palette.error.main,
        color: "white",
        paddingLeft: "5px",
        margin: "0 10px 0-5px",
        borderRadius: "4px",
    }
}));

export default useStyles;