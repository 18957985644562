import React, {useState} from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import SignUpForm from "../components/SignUpForm";
import classNames from "classnames";

const useStyles: (theme: Theme) => {
    signUpContainer: CSSStyleSheet,
    signUpFormContainer: CSSStyleSheet,
    loginLinkContainer: CSSStyleSheet,
} = makeStyles((theme) => ({
    signUpContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '0.57rem',
        overflow: 'hidden',
        width: '100%',
    },
    signUpFormContainer: {
        padding: '2.5em',
        [theme.breakpoints.up('md')]: {
            padding: '3rem 4.6rem 0 4.6rem',
        }
    },
    loginLinkContainer: {
        marginTop: '1.71rem',
        height: '4.29rem',
        backgroundColor: '#FAFAFA',
        borderRadius: '0 0 0.57rem 0.57rem',
    },
}));

const HubspotBuyerSignup = ({type}) => {

    const [formSubmitted, setFormSubmitted] = useState(false);

    const classes = useStyles()

    const nullFunc = () => {};
    const onSubmitForm = () => {
        setFormSubmitted(true)
    };

    return (
        <Grid container>
            {
                !formSubmitted ? 
                    <Grid item>
                        <SignUpForm 
                            type={type}  
                            handleAccountChange={nullFunc}
                            socialLoginTemporaryUser={nullFunc}
                            externalForm={true}
                            externalSubmitForm={onSubmitForm}
                        />
                    </Grid>
                :
                    <Grid item container className={classNames('w-100', classes.loginLinkContainer)} alignContent='center'>
                        <Paper className={classes.signUpContainer} elevation={2}>
                            <Typography variant="subtitle2" align='center' color="textPrimary" className='w-100'>
                                Thank you for registering with SecurSpace!
                                Please check your email for a welcome message, or click <a href="https://www.secur.space">here</a> to visit out home page.
                            </Typography>
                        </Paper>
                    </Grid>
            }
        </Grid>
    )
    
}

export default HubspotBuyerSignup;