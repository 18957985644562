import React, { useState, useEffect } from 'react'
import { BookingLoadingSkeleton, formatFrequency } from '@securspace/securspace-ui-kit'
import { InputAdornment, TextField, Typography, Container, Grid } from '@mui/material'
import moment from 'moment'
import { withSnackbar } from '../../components/hocs/withSnackbar'
import useStyles from '../../components/supplier-bookings-approvals/styles'
import BookingList from '../../components/supplier-bookings-approvals/SupplierBookingList'
import { getErrorMessageForStandardResponse } from '../../util/NetworkErrorUtil'
import { getBookingApprovals } from '../../components/supplier-bookings-approvals/request/booking-approvals-requests'
import { BookingStatus } from '../../components/constants/securspace-constants'
import { formatCurrencyValue } from '../../util/PaymentUtils'
import { Search } from '@mui/icons-material'

const shouldShowEndDate = (data, bookingStatusLabel) => {
    return (
        data.frequency === 'RECURRING' &&
        data.endDateAdjusted !== true &&
        (data.active === true || data.status === bookingStatusLabel.PENDING || data.status === bookingStatusLabel.INCOMPLETE)
    );
};

export function transformBooking(booking) {
    const commonProperties = {
        initialCharge: formatCurrencyValue(booking.initialSupplierPayoutAmount, true),
        recurringCharge: formatCurrencyValue(booking.recurringSupplierPayoutAmount, true),
        overageRate: booking.brokeredSupplierOverageRatePaid ? formatCurrencyValue(booking.brokeredSupplierOverageRatePaid) : formatCurrencyValue(booking.location.pricePerDay),
        rateValue: `${formatCurrencyValue(booking.rate, true)} ${booking.durationType === 'WEEKLY' ? ' /week' : booking.durationType === 'MONTHLY' ? ' /month' : ' /day'}`,
        frequencyValue: formatFrequency(booking.frequency, booking.durationType),
        brokered: booking?.brokered === true ? 'True' : 'False',
        customerName: booking?.buyerAccount?.companyName,
        locationName: booking?.location?.locationName,
        bookedDates: `${moment(booking.startDate).format("MM/DD/YYYY") || "N/A"} → ${shouldShowEndDate(booking, BookingStatus) ? 'Until cancelled' : moment(booking.endDate).format("MM/DD/YYYY")}`,
        createdOn: moment(booking.createdOn).format("L h:mm a").toUpperCase(),
    };

    return {
        ...booking,
        ...commonProperties
    };
}

const SupplierBookingApprovals = ({
    account,
    snackbarShowMessage
}) => {

    const classes = useStyles();
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [bookingLists, setBookingLists] = useState([]);
    const [originalBookingLists, setOriginalBookingLists] = useState([]);

    useEffect(() => {
        if (search.trim()) {
            let filteredData = originalBookingLists.filter((item) =>
                item.orderNumber?.toLowerCase().includes(search.toLowerCase()) ||
                item.buyerAccount?.companyName?.toLowerCase().includes(search.toLowerCase()) ||
                item.assetType?.toLowerCase().includes(search.toLowerCase()) ||
                item.locationName?.toLowerCase().includes(search.toLowerCase()) ||
                item.startDate?.toLowerCase().includes(search.toLowerCase()) ||
                item.endDate?.toLowerCase().includes(search.toLowerCase()) ||
                item.locationCity?.toLowerCase().includes(search.toLowerCase())
            );
            setBookingLists(filteredData);
        } else {
            setBookingLists(originalBookingLists);
        }
    }, [search]);


    useEffect(() => {
        const { id } = account;
        if (id) {
            setLoading(true)
            const requestParams = {
                supplierAccountId: id,
            }
            getBookingApprovals(requestParams)
                .then(({ body }) => {
                    const data = body?.map((booking) => {
                        return transformBooking(booking)
                    })
                    setBookingLists(data)
                    setOriginalBookingLists(data)
                    setLoading(false)
                })
                .catch(err => {
                    snackbarShowMessage(getErrorMessageForStandardResponse(err), "error", 15000)
                    setLoading(false)
                });
        }

    }, [account]);

    return (
        <Container className={classes.mainContainer}>
            <Grid container spacing={4} mb={4} alignItems='center'>
                <Grid item md={9} xs={12}>
                    <Typography mb={-2} variant={'h5'} component={'h1'}>Booking Approvals</Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                    <TextField
                        variant="standard"
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Enter your query here..."
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Search fontSize='medium' />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        label="Search"
                        sx={{
                            '& input::placeholder': {
                                fontSize: '1em'
                            }
                        }}
                    />
                </Grid>
            </Grid>
            {
                loading ? [...Array(10).keys()].map((index) => <BookingLoadingSkeleton key={index} />) : <BookingList
                    bookingLists={bookingLists}
                    search={search}
                    snackbarShowMessage={snackbarShowMessage}
                    setBookingLists={setBookingLists}
                />
            }
        </Container>
    )
}

export default withSnackbar(SupplierBookingApprovals)
