import Option from "./Option";
import { formatPhoneNumber } from "../util/ValidationUtils";

class LocationManagerOption extends Option {

    constructor(locationManager) {
        super(locationManager);
        // to avoid destructuring a null object below
        if (locationManager === null) {
            this.displayValue = "None"
            return;
        }

        const {username, phoneNumber, firstName, lastName} = locationManager;

        if (phoneNumber) {
            this.displayPhoneNumber = formatPhoneNumber(phoneNumber);
        }
        if (firstName && lastName && phoneNumber) {
            this.displayValue = `${firstName} ${lastName} - ${this.displayPhoneNumber}`;
        } else if (username) {
            this.displayValue = username
        }
    }

    getValue() {
        return this.value;
    }

    getDisplayValue() {
        return this.displayValue;
    }
}

export default LocationManagerOption;
