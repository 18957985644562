import moment from "moment/moment";
import {BookingSubscriptionStatus, EquipmentLengthsConstant} from "../components/constants/securspace-constants";
import {formatCurrencyValue} from "./PaymentUtils";
import EquipmentTypes from "../components/EquipmentTypes";

const dateFormat = "MM/DD/YYYY hh:mm A";

export const RECURRING = "RECURRING";
export const ONE_TIME = "ONE_TIME";
export const DAILY = "DAILY";
export const MONTHLY = "MONTHLY";
export const WEEKLY = "WEEKLY";

export const formatDate = (date) => {
  return date ? moment(new Date(date)).format(dateFormat) : "N/A";
}

export const formatFrequency = (booking) => {
  return booking.frequency === 'RECURRING' && booking.durationType === 'WEEKLY' ?
    'Recurring - Weekly' : booking.frequency === 'RECURRING' && booking.durationType === 'MONTHLY' ?
      'Recurring - Monthly' : 'One-Time'
}

export const formatFrequencyByDuration = (frequency, duration) => {
  return frequency === 'RECURRING' && duration === 'WEEKLY' ?
    'Recurring - Weekly' : frequency === 'RECURRING' && duration === 'MONTHLY' ?
      'Recurring - Monthly' : 'One-Time'
}

export const formatEquipmentLength = (equipmentLength) => {
  switch (equipmentLength) {
    case Object.keys(EquipmentLengthsConstant)[ 0 ]:
      return EquipmentLengthsConstant.TWENTY_FIVE_FOOT
    case Object.keys(EquipmentLengthsConstant)[ 1 ]:
      return EquipmentLengthsConstant.FORTY_FIVE_FOOT
    case Object.keys(EquipmentLengthsConstant)[ 2 ]:
      return EquipmentLengthsConstant.FIFTY_THREE_FOOT
    case Object.keys(EquipmentLengthsConstant)[ 3 ]:
      return EquipmentLengthsConstant.EIGHTY_THREE_FOOT
    default :
      return Object.keys(EquipmentLengthsConstant)[ 0 ]
  }
}

export const formatSubscriptionStatus = (bookingSubscriptionStatus) => {
  switch (bookingSubscriptionStatus) {
    case Object.keys(BookingSubscriptionStatus)[ 0 ]:
      return BookingSubscriptionStatus.ACTIVE
    case Object.keys(BookingSubscriptionStatus)[ 1 ]:
      return BookingSubscriptionStatus.CANCELLED
    case Object.keys(BookingSubscriptionStatus)[ 2 ]:
      return BookingSubscriptionStatus.DECLINED
    case Object.keys(BookingSubscriptionStatus)[ 3 ]:
      return BookingSubscriptionStatus.NONE
    default:
      return BookingSubscriptionStatus.NONE;
  }
}

export const formatRecurringPayoutAmount = (booking) => {
  return booking.frequency === 'RECURRING' ?
    formatCurrencyValue(booking.recurringSupplierPayoutAmount, true)
    :
    '$0'
}

export const formatBookingRate = (booking) => {
  switch (booking.durationType) {
    case 'WEEKLY' :
      return `${formatCurrencyValue(booking.rate, true)}/week`;
    case 'MONTHLY':
      return `${formatCurrencyValue(booking.rate, true)}/month`;
    case 'DAILY':
      return `${formatCurrencyValue(booking.rate, true)}/day`;
    default :
      return `${formatCurrencyValue(booking.rate, true)}/day`;
  }
}

export const formatActive = (value) => {
  return value ? 'Active' : 'Not Active'
}

export const formatBool = (bool) => {
  return bool ? "YES" : "NO";
}

export const getRentalAgreementURL = (fileName) => {
  return fileName ?
      `https://s3-us-west-1.amazonaws.com/securspace-files/legal-agreements/${fileName}`
      :
      'https://static.secur.space/app-files/RESERVATION+AGREEMENT.pdf';
}

export const convertDuration = (duration) => {
  if (duration === MONTHLY) {
      return 30;
  } else if (duration === WEEKLY) {
      return 7;
  } else {
      return 0;
  }
}

export const calculateRate = (selectedAssetType, location, monthly) => {
  const selectedEq = EquipmentTypes.getRatesByEqType(selectedAssetType, location?.equipmentTypesAll);
  const dayRate = (selectedEq?.pricePerDay ?? 0) > 0 ? selectedEq.pricePerDay : (location?.pricePerDay ?? 0);
  const monthRate = (selectedEq?.pricePerMonth ?? 0) > 0 ? selectedEq.pricePerMonth : (location?.pricePerMonth ?? 0);

  return monthly ? monthRate : dayRate;
};

export const isZeroPartnerPayout = (customerChargedAmountPerPeriod, supplierPayoutAmountPerPeriod) => {
  const noPartnerPayout = !supplierPayoutAmountPerPeriod || supplierPayoutAmountPerPeriod === 0 || supplierPayoutAmountPerPeriod === '';
  return customerChargedAmountPerPeriod > 0 && noPartnerPayout;
}

export const isZeroSecurSpacePayout = (customerChargedAmountPerPeriod, supplierPayoutAmountPerPeriod) => {
  return customerChargedAmountPerPeriod === supplierPayoutAmountPerPeriod && customerChargedAmountPerPeriod !== '' && supplierPayoutAmountPerPeriod !== '';
}

export const getAgreementUrl = (booking) => {
  return booking && booking.supplierLegalAgreementFileName ?
      'https://s3-us-west-1.amazonaws.com/securspace-files/legal-agreements/' + booking.supplierLegalAgreementFileName
      :
      'https://static.secur.space/app-files/RESERVATION+AGREEMENT.pdf';
}

export const startDateLessThan24Hours = (booking) => {
  const ONE_HOUR = 60 * 60 * 1000;
  const TWENTY_FOUR_HOURS = 24 * ONE_HOUR;
  let timeBeforeBookingStart = new Date(booking.startDate) - (new Date());

  return timeBeforeBookingStart < TWENTY_FOUR_HOURS;
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};