import React from 'react';
import AboutNavHeader from '../components/about/AboutNavHeader';
import UserBasedNav from './UserBasedNav';

const LocationUserBasedNav = ({ locationPathname, pendingApprovalCount, pendingInventoryCount }) => {

    switch (locationPathname) {
        case "/":
            return null;
        case '/about':
            return <AboutNavHeader pendingApprovalCount={pendingApprovalCount} pendingInventoryCount={pendingInventoryCount} />;
        default:
            return <UserBasedNav pendingApprovalCount={pendingApprovalCount} pendingInventoryCount={pendingInventoryCount} />;
    }
};

export default LocationUserBasedNav;