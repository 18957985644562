import React, {useEffect, useState} from 'react';
import Busy from '../components/Busy';
import Error from '../components/Error';
import LocationManagerOption from '../controls/LocationManagerOption';
import {toast} from 'react-toastify';
import {getLocationManagers, updateLocationManager} from '../components/location/requests/location-requests';
import {
    getErrorMessageForNonStandardAndStandardResponse,
    getErrorMessageForStandardResponse
} from '../util/NetworkErrorUtil';
import AddLocationManagerInfo from '../components/AddLocationManagerInfo'
import LocationManagerSelect from "../components/location/manager/LocationManagerSelect";
import {validateName, validatePhoneNumber} from '../util/ValidationUtils';

const LocationManagerContent = (props) => {
    const {accountId, currentManager, setCurrentManager} = props;
    const [managerList, setManagerList] = useState([]);
    const [showError, setShowError] = useState({show: false, message: ''});

    const [updatedManagerInfo, setUpdatedManagerInfo] = useState({username: '' ,firstName: '', lastName: '', phoneNumber: '', isOptedIntoText: false});

    const [refreshManagerList, setRefreshManagerList] = useState(true);
    const [showUpdateButton, setShowUpdateButton] = useState(false);

    useEffect(() => {
        const getManagers = async () => {
            return await getLocationManagers(accountId);
        };

        if (refreshManagerList) {
            getManagers()
                .then((res) => {
                    const { text } = res;
                    const managerArray = JSON.parse(text)
                    setManagerList(managerArray.map(manager => new LocationManagerOption(manager)))
                })
                .catch((err) => {
                    setShowError({show: true, message: getErrorMessageForStandardResponse(err)})
                })

            setRefreshManagerList(false)
        }


    }, [accountId, refreshManagerList])

    useEffect(() => {
        if (currentManager) {
            if (!currentManager.firstName || !currentManager.lastName || !currentManager.phoneNumber) {
                setShowError({show: true, message: 'Selection is missing some information! Please update below:'})
            } else {
                setShowError({show: false, message: ''})
            }
            setUpdatedManagerInfo(  {
                  username: currentManager.username,
                  firstName: currentManager.firstName || '',
                  lastName: currentManager.lastName || '',
                  phoneNumber: currentManager.phoneNumber || '',
                  isOptedIntoText: !!currentManager.isOptedIntoText,
              }
            )
        }
    }, [currentManager])

    useEffect(() => {
        if (currentManager) {
            if (updatedManagerInfo.firstName !== currentManager?.firstName ||
                updatedManagerInfo.lastName !== currentManager?.lastName ||
                updatedManagerInfo.phoneNumber !== currentManager?.phoneNumber ||
                updatedManagerInfo.isOptedIntoText !== currentManager?.isOptedIntoText
            ) {
                setShowUpdateButton(true);
            } else {
                setShowUpdateButton(false);
            }
        } else {
            setShowUpdateButton(false);
        }
    }, [updatedManagerInfo, currentManager])


    const handleChangeManagerSelection = (event) => {
        const selected = event.target.value;
        setCurrentManager(selected.value);
    }

    const onSuccessUpdate = () => {
        Busy.set(false)
        toast.success("Successfully Updated Location Manager information");
        setRefreshManagerList(true)
    }

    const onFailUpdate = (err) => {
        Busy.set(false)
        toast.error(getErrorMessageForNonStandardAndStandardResponse(err));
    }

    const submitUpdateManager = () => {
        if (currentManager.value !== null) {
            if (!validatePhoneNumber(updatedManagerInfo?.phoneNumber, true)) {
                toast.error("A valid phone number is required for location managers");
                return;
            } else if (!validateName(updatedManagerInfo?.firstName) ) {
                toast.error("Location manager's first name must be non-empty and can contain only spaces, hyphens, letters or numbers");
                return;
            } else if (!validateName(updatedManagerInfo?.lastName)) {
                toast.error("Location manager's last name must be non-empty and can contain only spaces, hyphens, letters, or numbers");
                return;
            }
        }
        Busy.set(true)
        const sanitizedManagerInfo = {
            username: updatedManagerInfo.username,
            firstName: updatedManagerInfo.firstName.trim(),
            lastName: updatedManagerInfo.lastName.trim(),
            phoneNumber: updatedManagerInfo.phoneNumber,
            isOptedIntoText: updatedManagerInfo.isOptedIntoText
        }
        updateLocationManager(accountId, sanitizedManagerInfo, onSuccessUpdate, onFailUpdate)
        const newManagerDisplay = new LocationManagerOption(sanitizedManagerInfo);
        setCurrentManager(newManagerDisplay.value);
    }

    return (
        <>
            <LocationManagerSelect onChange={handleChangeManagerSelection}
                                   currentManager={currentManager}
                                   managerList={managerList}
            />
            {
                showError?.show && <Error> { showError.message } </Error>
            }
            {
                currentManager &&
                <AddLocationManagerInfo updatedManagerInfo={updatedManagerInfo}
                                                         setUpdatedManagerInfo={setUpdatedManagerInfo}
                                                         showAllUpdateFields={true}
                                                         showUpdateButton={showUpdateButton}
                                                         submit={submitUpdateManager}
                />
            }

        </>
    )
}

export default LocationManagerContent;
