import React, { useState } from 'react';
import { Button, ButtonGroup, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { CustomerText } from "./CustomerText";
import './style.css'
import { PartnerText } from "./PartnerText";

const useStyles = makeStyles({
    section: {
        justifyContent: 'center',
        marginTop: 70,
        backgroundColor: "#FFFFFF",
        paddingTop: 30,
        paddingBottom : 30,
        borderBottom : "0.4rem solid #757DE8"
    },
    header: {
        justifyContent: 'center',
        textAlign: 'center',
        height: '1%',
        fontSize: 32
    },
    h3: {
        textAlign: 'center'
    },
    overLine: {
        textAlign: 'center',
        fontSize: 12,
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '3%',
        marginBottom: 48
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '1rem',
        backgroundColor: '#3F51B5',
    }
});

const HowItWorks = () => {
    const classes = useStyles();
    const [showText, setShowText] = useState(true);

    const showCustomerText = () => {
        setShowText(true);
    }

    const showPartnerText = () => {
        setShowText(false);
    }

    return <section className={classes.section} id='how-it-works'>
        <header className={classes.header}>
            <Typography variant="h3" color="textPrimary" className={classes.h3}>How SecurSpace Works?</Typography>
            <Typography variant="overline" color="textSecondary" className={classes.overLine}>HOW IT WORKS</Typography>
        </header>
        <ButtonGroup aria-label="outlined primary button group" className={classes.buttonGroup}>
            <Button className='tab_btn' onClick={showCustomerText} variant={showText ? "contained" : "outlined"} color={showText ? "primary" : "secondary"}>For Buyers</Button>
            <Button className='tab_btn' onClick={showPartnerText} variant={!showText ? "contained" : "outlined"} color={!showText ? "primary" : "secondary"}>For Suppliers</Button>
        </ButtonGroup>
        {showText ? CustomerText() : PartnerText()}
        
    </section>
}

export default HowItWorks;
