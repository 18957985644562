import React from "react";
import LocationItem from "./LocationItem";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
    locationItemLink: {
        '&:link': {
            textDecoration: 'none',
        },
        '&:visited': {
            textDecoration: 'none',
        },
        '&:focus': {
            textDecoration: 'none',
        },
        '&:hover': {
            textDecoration: 'none',
        },
        '&:active': {
            textDecoration: 'none',
        },
    }
}));

type LocationItemLinkProps = {
    cityName: string,
    className: string,
    distanceTo: string,
    href: string,
    instantApproval: boolean,
    listingImageFileName: string,
    locationName: string,
    pricePerDay: number,
    pricePerMonth: number,
    target: string,
};

const LocationItemLink: (props: LocationItemLinkProps) => JSX.Element = (props) => {
    const {listingImageFileName, pricePerDay, pricePerMonth, instantApproval, locationName, cityName, distanceTo, href = "/", target = "_self", className} = props;
    const classes = useStyles();

    return (
        <Link to={href} target={target} className={classNames(classes.locationItemLink, className)}>
            <LocationItem
                listingImageFileName={listingImageFileName}
                pricePerDay={pricePerDay}
                pricePerMonth={pricePerMonth}
                instantApproval={instantApproval}
                locationName={locationName}
                cityName={cityName}
                distanceTo={distanceTo}
                width="100%"
            />
        </Link>
    );
};

export default LocationItemLink;
