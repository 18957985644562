import React from "react";
import { Box, Grid, Button } from "@mui/material";
import { Table, Pagination } from '@securspace/securspace-ui-kit';
import { headerCells } from './data'
import { verbiage } from "./payouts-report-verbiage";

const PayoutsTable = ({
  items,
  count,
  rowsPerPage,
  page,
  handleReviewItem,
  handlePageChange,
  handleRowsPerPageChange
}) => {

  const actionButtons = (item) => {
    return <Grid container sx={{ fontSize:'1em' }}>
      <Button sx={{ fontSize:'1em' }} onClick={() => handleReviewItem(item?.item)} variant="outlined">{verbiage.buttonText}</Button>
    </Grid>
  }

  return <Box>
    <Table
      headCells={headerCells}
      rows={items}
      hasRowAction={true}
      actionType='button'
      actionButtons={actionButtons}
    />
    <Grid sx={{ display:'flex', justifyContent:'flex-end', alignItems:'flex-end', width:'100%', mt:4 }} item>
        <Pagination
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          variant="tablePagination" />
    </Grid>
  </Box>
}

export default PayoutsTable
