import React from 'react';
import { AccountType } from '../../components/constants/securspace-constants';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SignUpForm from '../../components/SignUpForm';
import InfoColumn from './InfoColumn';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    mainContainer: {
        position: 'relative',
        padding: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    wrapperContainer: {
        gap: '2.5rem',
        maxWidth: '93em',
        transform: 'translateY(-20em)',

    }
}));

const SignUp = (props) => {
    const type = props.type || AccountType.BUYER;
    const classes = useStyles();

    return (
        <section>
            <div className="ss-signup-container" />
            <Grid container className={classes.mainContainer} justifyContent='center' alignContent='center'>
                <Grid className={classes.wrapperContainer} container justifyContent='center' alignContent='center'>
                    <Grid item xs>
                        <SignUpForm
                            type={type}
                            handleAccountChange={props.handleAccountChange}
                            socialLoginTemporaryUser={props.socialLoginTemporaryUser}
                        />
                    </Grid>
                    <Grid item xs>
                        <InfoColumn accountType={type} />
                    </Grid>
                </Grid>
            </Grid>
        </section>
    );
};

SignUp.propTypes = {
    type: PropTypes.oneOf([AccountType.BUYER, AccountType.SUPPLIER]),
};

export default SignUp;
