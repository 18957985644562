import request from '../../../util/SuperagentUtils';

export const requestPaymentMethods = (id: String, userType: String, onSuccess: Function, onFail: Function) => {
    requestPaymentMethodsForAccountId(id, onSuccess, onFail)
}

export const requestPaymentMethodsForAccountId = (accountId: String,
                                                  onSuccess: (paymentMethods: Array<Object>) => void,
                                                  onFail: (err: Object) => void) => {
    if (accountId) {
        request
            .get("/api/payment-method")
            .query({accountId})
            .then((resp) => onSuccess(resp.body))
            .catch((err) => onFail(err))
    }
}

export const requestDeactivatePaymentMethod = (authorityId: String, paymentMethodId: String, userType: String,
                                               onSuccess: (resp: Object) => void, onFail: (err: Object) => void) => {
    requestDeactivateAccountPaymentMethod(authorityId, paymentMethodId, onSuccess, onFail)

}

export const requestDeactivateAccountPaymentMethod = (accountId: String, paymentMethodId: String, onSuccess: (resp: Object) => void,
                                                      onFail: (err: Object) => void) => {
    if (accountId && paymentMethodId) {
        request
            .post(`/api/payment-method/deactivate`)
            .send({
                id: paymentMethodId,
                accountId
            })
            .then((resp) => onSuccess(resp))
            .catch((err) => onFail(err))
    }
}

export const requestRenamePaymentMethod = (data: { authorityId: String, paymentMethodId: String, nickName: String },
                                           userType: String, onSuccess: (resp: Object) => void, onFail: (err: Object) => void) => {
    requestRenameAccountPaymentMethod({ ...data, accountId: data.authorityId }, onSuccess, onFail)
}

export const requestRenameAccountPaymentMethod = (data: { accountId: String, paymentMethodId: String, nickName: String },
                                                  onSuccess: (resp: Object) => void,
                                                  onFail: (err: Object) => void) => {
    const {accountId, paymentMethodId, nickName} = data || {}

    if (accountId && paymentMethodId) {
        request
            .post(`/api/payment-method/rename`)
            .send({
                id: paymentMethodId,
                accountId,
                nickName
            })
            .then((resp) => onSuccess(resp))
            .catch((err) => onFail(err))
    }
}

export const requestToken = (authorityId: String, userType: String, onSuccess: (token: String) => void, onFail: (err: Object) => void) => {
    requestTokenForAccount(authorityId, onSuccess, onFail)
}

export const requestTokenForAccount = (accountId: String, onSuccess: (token: Object) => void, onFail: (err: Object) => void) => {
    request
        .get(`/api/payment-method/token`)
        .query({
            accountId
        })
        .then((resp) => onSuccess(resp.body.token))
        .catch((err) => onFail(err))
}

export const requestPaymentProcessorEnvironmentDetails = (onSuccess?: (env: Object) => void, onFail?: (err: Object) => void) : (Promise<Object> | void) => {
    const result = request
        .get(`/api/payment-method/environment`)

    if(onSuccess) {
        result
            .then((resp) => onSuccess(resp.body))
            .catch((err) => onFail(err))

    } else {
        return result
            .then((resp) => resp.body)
    }
}

export const requestAddStripePaymentMethod = (
    data: { authorityId: String, userType: String, stripeToken: String },
    onSuccess: Function,
    onFail: Function) => {
        requestAddStripePaymentMethodToAccount({ ...data, accountId: data.authorityId }, onSuccess, onFail)
}

export const requestAddStripePaymentMethodToAccount = (
    data: { accountId: String, stripeToken: String }, onSuccess, onFail) => {
    requestAddAccountPaymentMethod(data, onSuccess, onFail)
}

export const requestAddPlaidPaymentMethod = (
    data: { authorityId: String, userType: String, plaidPublicToken: String, plaidAccountId: String },
    onSuccess: Function,
    onFail: Function) => {

    const { authorityId } = data

    requestAddPlaidPaymentMethodToAccount({ ...data, accountId: authorityId }, onSuccess, onFail)
}

export const requestAddPlaidPaymentMethodToAccount = (
    data: { accountId: String, plaidPublicToken: String, plaidAccountId: String },
    onSuccess: Function,
    onFail: Function) => {

    requestAddAccountPaymentMethod(data, onSuccess, onFail)
}

export const requestAddDwollaPaymentMethod = (
    data: {authorityId: String, userType: String, dwollaFundingSourceId: String},
    onSuccess: Function,
    onFail: Function) => {

    const { authorityId, dwollaFundingSourceId } = data

    requestAddDwollaPaymentMethodToAccount(authorityId, dwollaFundingSourceId, onSuccess, onFail)

}

export const requestAddDwollaPaymentMethodToAccount = (accountId: String, dwollaFundingSourceId: String,
                                                       onSuccess: Function, onFail: Function) => {
    requestAddAccountPaymentMethod({accountId, dwollaFundingSourceId}, onSuccess, onFail)
}

export const requestMakePaymentMethodDefault = (
    paymentMethodId: String,
    onSuccess?: () => void,
    onFail?: (err: Object) => void) : (Promise<void> | void) => {
    const result = request
        .post(`/api/payment-method/${paymentMethodId}/default`)

    if(onSuccess) {
        result
            .then(() => onSuccess())
            .catch((err) => onFail(err))
    } else {
        return result
    }
}

export const requestVerifyBankAccount = (
    data: { paymentMethodId: String, microDeposit1: Number, microDeposit2: Number },
    onSuccess?: (updatedPaymentMethod: Object) => void,
    onFail?: (err: Object) => void
    ) : (Promise<Object> | void) => {

    const result = request
        .post(`/api/payment-method/verify`)
        .send(data)

    if(onSuccess) {
        result
            .then((resp) => onSuccess(resp.body))
            .catch((err) => onFail(err))
    } else {
        return result
    }
}

const requestAddAccountPaymentMethod = (data, onSuccess, onFail) => {
    request
        .post('/api/payment-method')
        .send(data)
        .then((resp) => onSuccess(resp))
        .catch((err) => onFail(err))
}

export const requestPlaidLinkToken: (onSuccess?: Function, onFail?: Function) => void | Promise = (onSuccess, onFail) => {
    const result = request
      .get('/api/payment-method/plaid-link-token')
    if (onSuccess && onFail) {
      result
        .then((resp) => onSuccess(resp.body))
        .catch((err) => onFail(err));
    } else {
      return result;
    }
};
