import { createTheme } from '@mui/material/styles';
import InterTtf from './fonts/inter/Inter-VariableFont_slnt,wght.ttf';
import SatoshiVariableTtf from './fonts/satoshi/Satoshi-Variable.ttf';
import SatoshiVariableWoff from './fonts/satoshi/Satoshi-Variable.woff';
import SatoshiVariableWoff2 from './fonts/satoshi/Satoshi-Variable.woff2';


const Inter = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 300,
    src: `
        local('Inter'),
        local('inter'),
        url(${InterTtf}) format('truetype')
    `,
};

const SatoshiVariable = {
    fontFamily: 'Satoshi-Variable',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `
        local('Satoshi-Variable),
        url(${SatoshiVariableWoff2}) format('woff2'),
        url(${SatoshiVariableWoff}) format('woff'),
        url(${SatoshiVariableTtf}) format('truetype')
    `,
};

const globalThemes = createTheme({
    palette: {
        primary: {
            light: '#757DE8',
            main: '#3F51B5',
            dark: '#002984',
        },
        secondary: {
            light: '#757DE8',
            main: '#3F51B5',
            dark: '#002984',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.6)',
            disabled: 'rgba(0, 0, 0, 0.38)',
        },
        warning: {
            main: '#ED6C02',
        },
        success: {
            light: "rgba(76, 175, 80, 0.08)",
            main: '#4CAF50',
        },
        neutral: {
            light: "rgba(63, 81, 181, 0.08)",
        },
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
        fontSize: 14,
        h1: {
            fontFamily: 'Satoshi-Variable',
            fontWeight: 700,
            fontSize: '4.57rem',
            lineHeight: '6.14rem',
        },
        h3: {
            fontFamily: 'Satoshi-Variable',
            fontWeight: 700,
            fontSize: '2.29rem',
            lineHeight: '3.07rem',
        },
        h5: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '1.71rem',
            lineHeight: '133.4%',
        },
        h6: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '1.14rem',
            lineHeight: '160%',
            letterSpacing: '0.01rem',
        },
        subtitle1: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '1.14rem',
            lineHeight: '175%',
            letterSpacing: '0.01rem',
        },
        subtitle2: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '1rem',
            lineHeight: '157%',
            letterSpacing: '0.007rem',
        },
        body1: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '1.14rem',
            lineHeight: '150%',
            letterSpacing: '0.01rem',
        },
        body2: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: '143%',
            letterSpacing: '0.01rem',
        },
        caption: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '0.86rem',
            lineHeight: '166%',
            letterSpacing: '0.03rem',
        },
        overline: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '0.86rem',
            lineHeight: '266%',
            letterSpacing: '0.07rem',
            textTransform: 'uppercase',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [Inter, SatoshiVariable],
            }
        }
    },
    components: {
        helperText: {
            fontFamily: 'Inter',
            fontSize: '0.86rem',
            fontWeight: 400,
            lineHeight: '167%',
            letterSpacing: '0.03rem',
        },
        returnToText: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '0.71rem',
            lineHeight: '100%',
            textTransform: 'uppercase',
        },
        contentTitleText: {
            fontFamily: 'Inter',
            fontSize: '1.43rem',
            fontWeight: 600,
            lineHeight: '160%',
            letterSpacing: '0.01rem',
        }
    },
})


export default globalThemes;
