import {makeStyles} from '@mui/styles';

export const useListingImgStyles: () => {img: CSSStyleSheet} = makeStyles(() => ({
  img: {
    height: "100%",
    width: "100%",
    objectfit: props => props?.objectfit || 'cover',
  },
  container : {
    height: props => props?.height || '11.42em',
    width: props => props?.width || '20em',
    borderRadius: props => props?.borderRadius || '0.57143em',
    overflow: "hidden"
  }
}));
