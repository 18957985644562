import {useEffect, useState} from 'react';

const useRefOffsetDimensions = (containerRef) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0, left: 0, top: 0 })

  useEffect(() => {
    const getDimensions = () => {
      return {
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
        left: containerRef.current.offsetLeft,
        top: containerRef.current.offsetTop,
      };
    }

    const handleResize = () => {
      setDimensions(getDimensions());
    }

    if (containerRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [containerRef])

  return dimensions;
};

export default useRefOffsetDimensions;
