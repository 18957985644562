import React, {memo} from 'react';
import { makeStyles } from '@mui/styles'
import {Box, Paper, Skeleton} from '@mui/material';
import PropTypes from "prop-types";


const useStyles = makeStyles(() => ({
    radius: {
        borderRadius: '1.5em',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        gap: '1em',
        padding: '1em',
        borderBottom: '1px solid #E9E9FB'
    },
    fullWidth: {
        flex: 1
    },
    bottomContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
}));

const LocationSearchListContentSkeleton = ({items = 4}) => {
    const classes = useStyles();
    return (
      [...Array(items).keys()].map((_, index) => {
          return <Paper className={"ss-search-result-container"} square key={index}>
              <Box className={classes.flex}>
                  <Skeleton className={classes.radius} variant="rectangular" width={259} height={159} />
                  <Box className={classes.fullWidth}>
                      <Skeleton width={'30%'} height={30} />
                      <Skeleton variant='text' />
                      {[...Array(3).keys()].map((_, index) => <Skeleton width={'50%'} key={index} />)}
                      <Box className={classes.bottomContent}>
                          {[...Array(2).keys()].map((_, index) => <Skeleton width={'30%'} key={index} />)}
                      </Box>
                  </Box>
              </Box>
          </Paper>
      })
    );
};

LocationSearchListContentSkeleton.propTypes = {
    items: PropTypes.number,
};

export default memo(LocationSearchListContentSkeleton);