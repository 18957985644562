import request from '../../util/SuperagentUtils';

export const requestCreateServiceCharge = (bookingId: String, serviceFeeCharge: {serviceFeeType: String, taxRate: Number, chargeAmount: Number, notes: String}) => {
  return request.post(`/api/booking/${bookingId}/service-charge`)
    .send(serviceFeeCharge);
}

export const requestCalculateServiceChargeTax = (bookingId: String, serviceFeeCharge: {serviceFeeType: String, taxRate: Number, chargeAmount: Number, notes: String}) => {
  return request.post(`/api/booking/${bookingId}/service-charge/calculate-tax`)
    .send(serviceFeeCharge);
}

export const requestBookingTransactionById = (bookingTransactionId: String) => {
  return request.get(`/api/booking-transaction/${bookingTransactionId}`);
}
