import request from '../../../util/SuperagentUtils';

const ASSET_TYPES_URL = "/api/types/assets";
const BOOKING_URL = "/api/booking"

export const requestCheckInFieldsForAccountId = (accountId) => {

    if(accountId) {
        return request.get(`/api/account/${accountId}/check-in-fields`);
    }
}

export const requestAssetTypes = () => {
    return request.get(ASSET_TYPES_URL);
}

export const requestBooking = (bookingId) => {
    return request.get(`${BOOKING_URL}/${bookingId}`);
}

export const checkin = (data) => {
    return request.post(`/api/check-in-images`).send(data);
}
