import React from 'react';
import {Grid, Hidden, IconButton, InputAdornment, Typography} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

const useStyles: {gridAlign: CSSStyleSheet} = makeStyles({
  gridAlign: {
    alignSelf: 'center'
  }
});

const PlacesAutocompleteInputAdornmentEnd = (props) => {
  const {searchValue, onCancelIconClick} = props;
  const classes = useStyles();

  return <InputAdornment position={'end'}>
    {
      searchValue && <Grid container direction={'row'} wrap={'nowrap'} justifyContent={'center'} spacing={1}>
        <Hidden smDown>
          <Grid item className={classes.gridAlign}>
            <Typography variant={'overline'}>Hit Enter to Search</Typography>
          </Grid>
          <Grid item className={classes.gridAlign}>
            <KeyboardReturnIcon fontSize={'small'} color={'primary'}/>
          </Grid>
        </Hidden>
        <Grid item className={classes.gridAlign}>
          <IconButton onClick={onCancelIconClick} color={'primary'}>
            <CancelIcon fontSize='small' />
          </IconButton>
        </Grid>
      </Grid>
    }
  </InputAdornment>
}

export default PlacesAutocompleteInputAdornmentEnd;
