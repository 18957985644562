import {useEffect, useState} from "react";
import {MONTHLY} from "../../../util/BookingUtil";
import {getLocationHasRequestedCapacityById} from "../../../components/location/requests/location-requests";
import {getErrorMessageForStandardResponse} from "../../../util/NetworkErrorUtil";
import {DateFormats} from "../../../components/constants/securspace-constants";
import moment from "moment";

const useLocationHasRequestedCapacity = (
  locationId: string,
  startDate: string,
  endDate: string,
  numberOfSpaces: number,
  frequency: string,
) => {
  const [locationHasRequestedCapacity, setLocationHasRequestedCapacity] = useState(false);

  useEffect(() => {
    if (frequency === MONTHLY || !moment(startDate, DateFormats.DAY).isValid() || !moment(endDate, DateFormats.DAY).isValid()) {
      setLocationHasRequestedCapacity(false);
      return;
    }
    let ignore = false;
    getLocationHasRequestedCapacityById(locationId, startDate, endDate, numberOfSpaces)
      .then((response) => {
        if (!ignore) {
          setLocationHasRequestedCapacity(response.body.hasRequestedCapacity);
        }
      })
      .catch((error) => {
        console.error(getErrorMessageForStandardResponse(error));
      });
    return () => {
      ignore = true;
    };
  }, [locationId, startDate, endDate, numberOfSpaces, frequency]);

  return locationHasRequestedCapacity;
};

export default useLocationHasRequestedCapacity;