import React from 'react';
import {Table} from "@securspace/securspace-ui-kit";
import ActionButtons from './subComponents/ActionButtons';
import CorrectionStatusBar from './subComponents/CorrectionStatusBar';
import { makeStyles } from '@mui/styles';
import {Theme} from "@mui/material";
import {useFlags} from "launchdarkly-react-client-sdk";

const useStyles: (theme: Theme) => {
    buttonContainer: CSSStyleSheet
} = makeStyles(theme => ({
    buttonContainer: {
        minWidth: "25rem",
    },
    statusBarApproved: {
      backgroundColor: theme.palette.success.light,
      margin: '1em 0 1em 0',
      boxShadow: 'none',
    },
    statusBarPending: {
      backgroundColor: theme.palette.neutral.light,
      margin: '1em 0 1em 0',
      boxShadow: 'none',
    },
}));

const inventoryRecordsHeadCells = [
  {'id' : 'containerNumber', 'label' : 'CONTAINER NUMBER'},
  {'id' : 'chassisNumber', 'label' : 'CHASSIS NUMBER'},
  {'id' : 'trailerNumber', 'label' : 'TRAILER NUMBER'},
  {'id' : 'truckLicensePlateNumber', 'label' : 'TRUCK LICENSE PLATE NUMBER'},
  {'id' : 'checkinDate', 'label' : 'CHECKIN ACTIVITY'},
  {'id' : 'checkoutDate', 'label' : 'CHECKOUT ACTIVITY'},
];

const InventoryRecordsTable = ({inventoryRecords, isLoading, setInventory, deleteCorrection, handleSortChange}) => {
  const { buyerInventoryCorrection } = useFlags();
  const classes = useStyles();

  const actionButtons = ({item, id}) => <ActionButtons item={item} id={id} setInventory={setInventory} deleteCorrection={deleteCorrection} classes={classes} />;

  const correctionStatusBar = ({item, id}) =>  <CorrectionStatusBar classes={classes} item={item} id={id} />;

  return (
    <Table
      actionType={'button'}
      headCells={inventoryRecordsHeadCells}
      rows={inventoryRecords}
      isLoading={isLoading}
      isShadow={true}
      actionButtons={buyerInventoryCorrection ? actionButtons : undefined}
      hasRowAction={true}
      RowSubComponent={correctionStatusBar}
      isSortable={true}
      handleSortRequest={handleSortChange}
    />
  );
}

export default InventoryRecordsTable;
