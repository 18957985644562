import React from "react";
import LocationItemLink from "../../../landing/recentlyBooked/LocationItemLink";
import PropTypes from 'prop-types';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    locationItemLink: {
        maxWidth: '75%',
        [theme.breakpoints.up('md')]: {
            maxWidth: '25%',
        }
    }
}));

const LocationProfileCard = (props) => {
    const { listingImageFileName, pricePerDay, pricePerMonth, instantApproval, locationName, cityName, distanceTo, id } = props;

    const classes = useStyles();

    return (
        <LocationItemLink
            listingImageFileName={listingImageFileName}
            pricePerDay={pricePerDay}
            pricePerMonth={pricePerMonth}
            instantApproval={instantApproval}
            locationName={locationName}
            cityName={cityName}
            distanceTo={distanceTo}
            href={`/location-profile/${id}`}
            target="_blank"
            className={classes.locationItemLink}
        />
    )
};


LocationProfileCard.propTypes = {
    listingImageFileName: PropTypes.string,
    pricePerDay: PropTypes.number,
    instantApproval: PropTypes.bool,
    locationName: PropTypes.string,
    cityName: PropTypes.string,
    id: PropTypes.string,
};

export default LocationProfileCard;
