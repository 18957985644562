import React, { useEffect, useState } from 'react';
import MapStyle from '../../MapStyle';
import { GoogleMap } from '@react-google-maps/api';
import { makeStyles } from '@mui/styles';
import {
  Skeleton
} from '@mui/material';

const google = window.google;

const useStyles: () => {
  mapContainer: CSSStyleSheet,
} = makeStyles({
  mapContainer: {
    width: '100%',
    height: '25.71428em',
    borderRadius: '0.571428em',
  },
});

const LocationProfileMapPreview = (props) => {
  const { lat, lng, loading } = props;
  const [coordinates, setCoordinates] = useState(new google.maps.LatLng(lat, lng));
  const classes = useStyles();

  useEffect(() => {
    setCoordinates(new google.maps.LatLng(lat, lng));
  }, [lat, lng]);

  return loading ? <Skeleton variant="rectangular" width={"100%"} height={359} /> : <GoogleMap center={coordinates}
    zoom={12}
    mapContainerClassName={classes.mapContainer}
    options={{
      styles: MapStyle,
      mapTypeControl: false,
      disableDefaultUI: true,
      draggable: false,
      disableDoubleClickZoom: false,
      zoomControl: false,
    }}
  />
}

export default LocationProfileMapPreview;
