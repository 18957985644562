import React from 'react';
import BookingNumberOfSpacesTextField, {CollapsibleMinSpaceOverrideAlert} from "../../BookingNumberOfSpacesTextField";
import BookingDetailsFieldToggle from "./BookingDetailsField/BookingDetailsFieldToggle";
import type {Supplier} from "../../../types/Supplier";
import {Grid, Theme} from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles: (theme: Theme) => {
  alert: CSSStyleSheet,
} = makeStyles(() => ({
  alert: {
    margin: '0.57em 0em 0.71em 0em',
  }
}));

const BookingDetailsNumberOfSpaces = (props: {
  editMode: boolean,
  onToggleEditMode: (editMode: boolean) => void,
  bookingLocation: Supplier,
  value: number,
  onChange: () => void,
  overrideMinimum: boolean,
  readOnly: boolean
}) => {
  const {
    editMode,
    onToggleEditMode,
    bookingLocation,
    value,
    onChange,
    overrideMinimum,
    readOnly,
  } = props;

  const classes = useStyles();

  const minimumSpacesRequired = bookingLocation?.minNumberOfSpaces ? bookingLocation.minNumberOfSpaces : 1;
  const intValue = Number.parseInt(value, 10);
  const isValid = overrideMinimum ? intValue >= 0 : intValue >= minimumSpacesRequired;
  const displayAdminWarning = overrideMinimum && intValue < minimumSpacesRequired;

  const handleToggleEdit = (toggleValue, event) => {
    if (!toggleValue) {
      if (isValid) {
        onToggleEditMode(toggleValue, event);
      }
    } else {
      onToggleEditMode(toggleValue, event);
    }
  };

  const formatSpacesText = (numberOfSpaces: number) => {
    if (numberOfSpaces === 1) {
      return '1 Space';
    } else {
      return `${numberOfSpaces} Spaces`;
    }
  };

  return (
    <>
      <BookingDetailsFieldToggle title={'Spaces'} open={editMode && !readOnly} value={formatSpacesText(value)}
                                 onChange={handleToggleEdit} disableToggle={readOnly}>
        <BookingNumberOfSpacesTextField
          fullWidth
          className='ss-byb-details-input'
          label="Space"
          variant="standard"
          onChange={onChange}
          hideOverrideNotice
          minimumSpacesRequired={minimumSpacesRequired}
          overrideMinimum={overrideMinimum}
          value={value}
          inputMode={'numeric'}
        />
      </BookingDetailsFieldToggle>
      <Grid item xs={12} className={displayAdminWarning ? classes.alert : ''}>
        <CollapsibleMinSpaceOverrideAlert in={displayAdminWarning} minimumSpacesRequired={minimumSpacesRequired}/>
      </Grid>
    </>
  );
};

export default BookingDetailsNumberOfSpaces;