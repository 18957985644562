import React from 'react';
import {Container, Typography} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import type {SeverityColor} from '../../constants/securspace-constants';
import {severityColors} from '../../constants/securspace-constants';

const useStyles: () => {
  typography: CSSStyleSheet,
  container: CSSStyleSheet,
} = makeStyles(theme => ({
  typography: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '.7143rem',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1rem',
  },
  container: {
    background: props => theme.palette[props.severity].main,
    borderRadius: '0.2857em',
    padding: '0.2857em 0.5714em',
    width: 'fit-content'
  }
}));

const LocationManagedLocationChip = (props: {label: string, severity: SeverityColor}) => {
  const {label, severity = 'success'} = props;
  const styles = useStyles({severity: severity});

  return <Container className={styles.container}>
    <Typography className={styles.typography}>{label}</Typography>
  </Container>
}

LocationManagedLocationChip.propTypes = {
  label: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(severityColors),
}

export default LocationManagedLocationChip;
