import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router";
import ServiceFeeChargeForm from "../../components/serviceFees/ServiceFeeChargeForm";
import ServiceFeeChargeFormLegacy from "../../components/serviceFees/ServiceFeeChargeFormLegacy";
import { ServiceFeeType } from "../../components/constants/securspace-constants";
import {
  requestCalculateServiceChargeTax,
  requestCreateServiceCharge
} from "../../components/serviceFees/service-fee-requests";
import Busy from "../../components/Busy";
import { toast } from "react-toastify";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import SecurSpacePageTitle from "../../components/common/SecurSpacePageTitle";
import '../../css/components/serviceFee.css'
import { Grid } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
``
const ChargeServiceFee = (props) => {
  const { account } = props;
  const navigate = useNavigate();
  const { state: { booking }} = useLocation();
  const { bookingId } = useParams();
  const [serviceFee, setServiceFee] = useState({
    serviceFeeType: ServiceFeeType.LIFT_FEE,
    chargeAmount: 0,
    notes: '',
  });
  const [taxRate, setTaxRate] = useState(0);

  const serviceFeeTaxEnabled = useFlags()?.collectTax;
  const [serviceFeeLegacy, setServiceFeeLegacy] = useState({
      serviceFeeType: ServiceFeeType.LIFT_FEE,
      chargeAmount: 0,
      taxRate: 0,
      notes: '',
    });


  const handleServiceFeeChange = (updatedServiceFee) => {
    setServiceFee({ ...updatedServiceFee });
  }

  const handleServiceFeeChangeLegacy = (updatedServiceFee) => {
    setServiceFeeLegacy({ ...updatedServiceFee });
  }

  const handleCharge = () => {
    if (bookingId) {
      Busy.set(true);
      requestCreateServiceCharge(bookingId, serviceFeeTaxEnabled ? serviceFee : serviceFeeLegacy ).then(() => {
        navigate(-1);
      }).catch(err => {
        toast.error(getErrorMessageForStandardResponse(err));
      }).finally(() => {
        Busy.set(false);
      });
    }
  }

  const handleCancel = () => {
    navigate(-1);
  }


  useEffect(() => {
    if (serviceFeeTaxEnabled && bookingId) {
      const data = {
        serviceFeeType: serviceFee?.serviceFeeType,
        chargeAmount: serviceFee?.chargeAmount,
        ...(serviceFee?.notes && { notes: serviceFee?.notes }),
      }
      requestCalculateServiceChargeTax(bookingId, data).then(({ body }) => {
        setTaxRate(body?.taxRate);
      })
    }
  }, [bookingId, serviceFee.chargeAmount, serviceFee.serviceFeeType])

  return (
    <Grid container flexDirection='column' justifyContent='center' alignItems='center' p={4}>
      <Grid item sx={{
        width: '100%',
        maxWidth: '43em',
        margin: 'auto'
      }}>
        <SecurSpacePageTitle>New Service Fee</SecurSpacePageTitle>
        {
          serviceFeeTaxEnabled ?
            <ServiceFeeChargeForm
              serviceFee={serviceFee}
              securspaceServiceFeePercentage={account?.securspaceServiceFeePercentage}
              onSubmit={handleCharge}
              onCancel={handleCancel}
              onChange={handleServiceFeeChange}
              taxRate={taxRate}
              booking={booking} />
          :
            <ServiceFeeChargeFormLegacy
              serviceFee={serviceFeeLegacy}
              securspaceServiceFeePercentage={account?.securspaceServiceFeePercentage}
              onSubmit={handleCharge}
              onCancel={handleCancel}
              onChange={handleServiceFeeChangeLegacy}
              booking={booking} />
        }
      </Grid>
    </Grid>
  )
}

export default ChargeServiceFee;
