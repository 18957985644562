import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        paddingTop: '2.5rem',
        paddingBottom: '2.86rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '8.04rem',
            paddingRight: '8.04rem',
        }
    },
    header: {
        display: "flex",
        justifyContent: "space-between"
    },
    searchContainer: {
        width: '80%',
    },
    csvContainer: {
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: 'center',
        width: '20%'
    },
}));

export default useStyles;