import React from 'react';

const SubscriptionReason = (props) => {
  const {reason, handleChange} = props;

  return <>
    <fieldset>
      <label htmlFor="reason">REASON</label>
      <input type="text"
             id="reason"
             name="reason"
             value={reason}
             onChange={handleChange}
             placeholder="Enter the reason"
      />
    </fieldset>
  </>
}

export default SubscriptionReason;
