import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getOverageInvoice } from '../../components/staticContent/static-content-requests';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import ExternalInvoiceSkeleton from './ExternalInvoiceSkeleton';
import {withSnackbar} from "../../components/hocs/withSnackbar";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../util/NetworkErrorUtil";

const useStyles: (theme) => {
    mainContainer: CSSStyleSheet,
    rowGap5: CSSStyleSheet,
    marginTop20: CSSStyleSheet,
    titleFont: CSSStyleSheet,
    lastModifiedFont: CSSStyleSheet,
} = makeStyles(() => ({
    mainContainer: {
        paddingTop: '2.86rem',
        paddingRight: '7.79vw',
        paddingLeft: '7.79vw',
    },
    rowGap5: {
        rowGap: '0.36rem',
    },
    marginTop20: {
        marginTop: '1.43rem',
    },
    wrapContent: {
        marginTop: '0.5em',
        marginBottom: '2em',
    },
    titleFont: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '1.43rem',
        lineHeight: '160%',
        letterSpacing: '0.01rem',
    },
    lastModifiedFont: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '1.14rem',
        lineHeight: '125%',
        letterSpacing: '0.01rem',
    },
    contentStyles: {
        '& .no-display': {
            display: 'none'
        },
        '& .ss-tos-platform-title': {
            fontWeight: '500',
            fontSize: '1.1em'
        },
        '& .ss-tos-arbitration-note': {
            textAlign: 'center'
        },
        '& .ss-tos-title': {
            fontWeight: '600',
            marginBottom: '1.5em'
        },
        '& .container': {
            width: '100% !important',
        },
        '& .static-page-section p': {
            textIndent: '0em',
            marginTop: '2em',
        },
        '& .static-page-section h4': {
            marginBottom: '-1em'
        },
        '& div h6': {
            marginBottom: '2.5em',
            fontWeight: 'bold',
            fontSize: '1.1em'
        },
        '& div h4': {
            fontWeight: '500',
            fontSize: '1.1em',
            marginBottom: '0.7em'
        },
        '& div h5': {
            fontWeight: 'bold',
            fontSize: '1.1em',
            marginBottom: '1em'
        },
        '& div p': {
            fontWeight: '400',
            fontSize: '1.1em',
            lineHeight: '1.5em',
            letterSpacing: '0.01em',
            '& strong': {
                fontWeight: '500',
                display: 'inline-block',
                marginBottom: '0.3em'
            }
        },
        '& div': {
            marginBottom: '2.5em'
        },
        '& p, h2, h3': {
            marginBottom: '2em'
        }
    }
}));


const ExternalInvoice = ({ type, snackbarShowMessage }) => {
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    const { invoiceNumber } = useParams();

    useEffect(() => {
        setLoading(true);
        getOverageInvoice(invoiceNumber).then((response) => {
            setLoading(false);
            setContent(response.text);
        }).catch((error) => {
            setLoading(false);
            const errorMessage = getErrorMessageForNonStandardAndStandardResponse(error);
            snackbarShowMessage(`${errorMessage}`, 'error', 15000);
        });
    }, [invoiceNumber, snackbarShowMessage]);

    let headerTitle;
    switch (invoiceNumber) {
        case "unauthorized":
            headerTitle = "You are not authorized to view this page. Please log in as an authorized user.";
            break;
        default:
            break;
    }

    return (
        loading ? <ExternalInvoiceSkeleton type={type} /> : <Grid container direction='column' className={classes.mainContainer}>
            <Box component='header' className={classes.wrapContent}>
                {!loading && <Grid container direction='column' className={classes.rowGap5}>
                    <Typography className={classNames(classes.titleFont, classes.marginTop20)}>
                        {headerTitle}
                    </Typography>
                </Grid>
                }
            </Box>
            <div dangerouslySetInnerHTML={{ __html: content?.toString() }} className={classes.contentStyles} />
        </Grid>
    );
};

ExternalInvoice.propTypes = {
    type: PropTypes.oneOf(["unauthorized"]),
};

export default withSnackbar(ExternalInvoice);
