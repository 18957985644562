import React from 'react';
import type {Booking} from "../../types/Booking";
import BookingCardItem from './BookingCardItem.js';
import NoBookingsFound from './NoBookingsFound.js';

const BookingCardList = (props: {bookings: Booking[], onEnd: (booking: Booking) => void, onUpdate: (booking: Booking) => void, headerCells: [{id: String, label: String}]}) => {
  const {bookings, onEnd, onUpdate, onShowPaymentModal, onShowCancelModal, headerCells, rootStyle} = props;

  return (
    bookings.length?
      bookings.map((booking: Booking) => <BookingCardItem key={booking.id} booking={booking} onEnd={onEnd} onUpdate={onUpdate} onShowPaymentModal={onShowPaymentModal} onShowCancelModal={onShowCancelModal} headerCells={headerCells} rootStyle={rootStyle} />)
      : <NoBookingsFound />
  );
}

export default BookingCardList;