import React from 'react';
import { Box, Checkbox, TableCell, TableRow } from "@mui/material";
import CustomTooltip from './CustomTooltip';

const FleetManagementTableRow = (props) => {
  const {
    fleetManagementAccountOwnerRole,
    handleFleetManagementAccountOwnerRoleChange,
    fleetManagementDispatcherRole,
    handleFleetManagementDispatcherRoleChange,
    fleetManagementAdminRole,
    handleFleetManagementAdminRoleChange
  } = props;

  const fleetManagementToolTip = 'Receive emails when bookings are created, updated, requested, approved, declined, or cancelled.';

  return <TableRow>
    <TableCell>
      <Box display='flex' alignItems='center'>
        {'Fleet Management'}
        <CustomTooltip title={fleetManagementToolTip} />
      </Box>
    </TableCell>
    <TableCell>
      {<Checkbox
        checked={fleetManagementAccountOwnerRole}
        onClick={handleFleetManagementAccountOwnerRoleChange}
      />}
    </TableCell>
    <TableCell>
      {<Checkbox
        checked={fleetManagementDispatcherRole}
        onClick={handleFleetManagementDispatcherRoleChange}
      />}
    </TableCell>
    <TableCell>
      {<Checkbox
        checked={fleetManagementAdminRole}
        onClick={handleFleetManagementAdminRoleChange}
      />}
    </TableCell>
  </TableRow>
}

export default FleetManagementTableRow;
