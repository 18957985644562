import request from '../../../util/SuperagentUtils'
import {getErrorMessageForNonStandardAndStandardResponse} from "../../../util/NetworkErrorUtil";

export const requestAdminInvoices = (startDate, endDate) => {
  return request
    .get(`/api/admins/transactions`)
    .query({startDate: startDate, endDate: endDate});
}

export const requestCompleteRefund = (item, deductFromNextPayout) => {
  return request
    .post(`/api/booking/complete-refund`)
    .send({
      transactionId: item.transactionId,
      deductFromNextPayout: deductFromNextPayout
    });
}

export const requestDenyRefund = (item) => {
  return request
    .post(`/api/booking/deny-refund`)
    .send({id: item.transactionId});
}

export const requestRetryPayment = (item) => {
  return request
    .post(`/api/booking/retry-charging-transaction`)
    .send({bookingTransactionId: item.transactionId});
}

export const requestToggleIncludeRefundInPayout = (item, newIncludeValue) => {
  return request
    .post(`/api/booking-transaction/${item.transactionId}/include-refund-in-payout`)
    .send({
      bookingTransactionId: item.transactionId,
      include: newIncludeValue
    });
}

export const requestPutPayoutOnHold = (item) => {
  return request
    .post(`/api/booking/put-payout-on-hold`)
    .send({bookingTransactionId: item.transactionId});
}

export const requestTakePayoutOffHold = (item) => {
  return request
    .post(`/api/booking/take-payout-off-hold`)
    .send({bookingTransactionId: item.transactionId});
}

export const requestPatchInvoiceStatus = (transactionId, changes, onSuccess?: (response:Object)=> void,
                                          onFail?: (err: Object)=> void): (Promise | void) => {

  const {status, statusNotes, disputeNotes} = changes;

  const result = request
    .patch(`/api/booking-transaction/${transactionId}`)
    .send({
      status: status, 
      statusNotes: statusNotes ? statusNotes : '',
      disputeNotes: disputeNotes ? disputeNotes: ''
    })

  if (onSuccess && onFail) {
    result
    .then((resp) => onSuccess(resp.body))
    .catch((err) => onFail(getErrorMessageForNonStandardAndStandardResponse(err)))
  } else {
    return result
  }
}
