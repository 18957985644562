import React, {useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormLabel, Grid,
} from "@mui/material";
import {Link} from "react-router-dom";
import RentalAgreementCheckbox from "../../RentalAgreementCheckbox";
import {Theme} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles: (theme: Theme) => {link: CSSStyleSheet} = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    fontSize: "inherit",
    color: theme.palette.secondary.main,
  }
}));

const TermsAndConditionsModal = (props) => {
  const {open, onClose, completeBooking} = props;

  const [agreementAccepted, setAgreementAccepted] = useState();
  const classes = useStyles();

  const handleToggleAgreement = () => {
    setAgreementAccepted(prev => !prev)
  }

  return <Dialog open={open} onClose={onClose}>
    <DialogTitle>Review Details</DialogTitle>
    <DialogContent id="reviewPaymentAmounts">
      <FormLabel id="tosAgreementLabel">
        Accept <Link to={'/terms-of-use'} target={"_blank"} className={classes.link}>Terms of Service</Link> Agreement:
      </FormLabel>
      <RentalAgreementCheckbox
        checked={agreementAccepted}
        onChange={handleToggleAgreement}
        required
      />
    </DialogContent>
    <DialogActions>
      <Grid container justifyContent={"center"}>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={completeBooking} disabled={!agreementAccepted}>
          Next
        </Button>
      </Grid>
    </DialogActions>
  </Dialog>
}

export default TermsAndConditionsModal;
