import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { GroupSummaries, SpaceUsageLoader, GroupSummariesLoader } from '@securspace/securspace-ui-kit'
import BuyerSpaceUsageFilters from "../../components/spaceUsage/buyer/BuyerSpaceUsageFilters";
import { withSnackbar } from "../../components/hocs/withSnackbar";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import NoMatchingInvoicesFound from '../../components/NoMatchingInvoicesFound';
import BuyerSpaceUsageWithPagination from "../../components/spaceUsage/BuyerSpaceUsageWithPagination";
import InventoryRecords from "../inventory-records/InventoryRecords";
import useUrlQueryParams from "../../hooks/useUrlQueryParams";
import SecurSpaceContainer from "../../components/common/SecurSpaceContainer";
import BuyerSpaceUsageExportCSVs from "../../components/spaceUsage/buyer/BuyerSpaceUsageExportCSVs";
import {
    requestBuyerSpaceUsagePaginated,
    requestCalculateBuyerSpaceUsageSummary
} from "../../components/spaceUsage/request/space-usage-requests";
import {Theme} from "@mui/material";
import moment from "moment";
import { DateFormats } from "../../components/constants/securspace-constants";

const useStyles: (theme: Theme) => {
    borderBottomGrey200: CSSStyleSheet,
    marginRight: CSSStyleSheet,
    mainContainer: CSSStyleSheet,
    overflow: CSSStyleSheet,
} = makeStyles(theme => ({
    borderBottomGrey200: {
        borderBottom: `${theme.palette.grey[200]} solid 0.07rem`
    },
    marginRight: {
        marginRight: "0.5em"
    },
    mainContainer: {
        paddingTop: '2.5rem',
        paddingBottom: '2.86rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '8.04rem',
            paddingRight: '8.04rem',
        }
    },
    overflow: {
        p: 2,
        mb: 4
    },
}));

const defaultSummariesData = {
    "totalOverageSpaces": 0,
    "totalChargedOverages": 0,
    "totalUnchargedOverages": 0
};

const BuyerSpaceUsageReport = ({ account, snackbarShowMessage }) => {
    const { overageInvoiceNumber, initBookingNumber, initStartDate, initEndDate } = useUrlQueryParams();

    const {sessionStorage} = window;

    const sessionStart = sessionStorage?.getItem("startDate")?.toString();
    const sessionEnd = sessionStorage?.getItem("endDate")?.toString();
    const savedStartDate = initStartDate ? initStartDate : sessionStart ? moment(sessionStart).format(DateFormats.DAY).toString() : moment().add(-1, 'months').format(DateFormats.DAY).toString();
    const savedEndDate = initEndDate ? initEndDate : sessionEnd ? moment(sessionEnd).format(DateFormats.DAY).toString() : moment().format(DateFormats.DAY).toString();
    const savedBookingNumber = initBookingNumber ? initBookingNumber : sessionStorage.getItem("bookingNumber");
    const savedInvoiceNumber = overageInvoiceNumber ? overageInvoiceNumber : sessionStorage.getItem("invoiceNumber");
    const savedSortBy = sessionStorage.getItem("sortBy");
    const savedSortDir = sessionStorage.getItem("sortDir");
    const savedPage = sessionStorage.getItem("page");
    const savedSize = sessionStorage.getItem("size");

    const [spaceUsageData: BookedSpaceCalendarView[], setSpaceUsageData] = useState([]);
    const [summaries, setSummaries] = useState(defaultSummariesData);
    const [page, setPage] = useState(savedPage || 0);
    const [size, setSize] = useState(savedSize || 50);
    const [totalNumberResults, setTotalNumberResults] = useState(0);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [endDate, setEndDate] = useState(savedEndDate);
    const [startDate, setStartDate] = useState(savedStartDate);
    const [bookingNumber, setBookingNumber] = useState(savedBookingNumber || "");
    const [invoiceNumber, setInvoiceNumber] = useState(savedInvoiceNumber || "");
    const [sortBy, setSortBy] = useState(savedSortBy || "locationName");
    const [inventoryRecordToShow, setInventoryRecordToShow] = useState();
    const [loading, setLoading] = useState(false);
    const [sortDir, setSortDir] = useState(savedSortDir || "asc");
    const [query, setQuery] = useState();
    const classes = useStyles();

    useEffect(() => {
        const { id } = account;
        const checkLength = (number) => number?.length >= 3 ? number : "";

        if (id) {
            setLoading(true);
            setQuery({
                buyerAccountId: id,
                page: page,
                size: size,
                startDate: startDate,
                endDate: endDate,
                sortBy: sortBy,
                locationId: selectedLocation?.id,
                locationName: selectedLocation?.locationName,
                bookingNumber: checkLength(bookingNumber),
                invoiceNumber: checkLength(invoiceNumber),
                sortDir
            });
        }

    }, [account, page, size, startDate, endDate, selectedLocation, bookingNumber, invoiceNumber, sortBy, sortDir]);

    useEffect( () => {

        const {id} = account;
        const checkLength = (number) => number?.length >= 3 ? number : "";

        if (id) {
            getSummary({
                buyerAccountId: id,
                startDate: startDate,
                endDate: endDate,
                locationId: selectedLocation?.id,
                bookingNumber: checkLength(bookingNumber),
                invoiceNumber: checkLength(invoiceNumber)
            }).then(({body}) => {
                setSummaries(body || defaultSummariesData);
            }).catch(err => snackbarShowMessage(getErrorMessageForStandardResponse(err), "error", 15000));
        }

    }, [account, startDate, endDate, selectedLocation, bookingNumber, invoiceNumber]);

    useEffect(() => {
        if (query) {
            getData(query)
                .then(({body}) => {
                    const {content} = body;
                    setTotalNumberResults(body.count);
                    setSpaceUsageData(content);
                    setLoading(false);
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                })
                .catch(err => snackbarShowMessage(getErrorMessageForStandardResponse(err), "error", 15000))
                .finally(() => setLoading(false));
        }
    }, [query]);

    const getSummary = async (params) => {
        return await requestCalculateBuyerSpaceUsageSummary(params);
    }

    const getData = async (params) => {
        return await requestBuyerSpaceUsagePaginated(params);
    };

    const handlePageChange = (_, selectedPage) => {
        setPage(selectedPage)
    }

    const handleSizeChange = (event) => {
        setSize(event.target.value)
    }

    const closeInventoryRecords = () => {
        setInventoryRecordToShow(null)
    }

    const handleShowInventory = (inventory) => {
        sessionStorage.setItem("location", JSON.stringify(selectedLocation))
        sessionStorage.setItem("page", query.page)
        sessionStorage.setItem("size", query.size)
        sessionStorage.setItem("startDate", query.startDate)
        sessionStorage.setItem("endDate", query.endDate)
        sessionStorage.setItem("sortBy", query.sortBy)
        sessionStorage.setItem("bookingNumber", query.bookingNumber)
        sessionStorage.setItem("invoiceNumber", query.invoiceNumber)
        sessionStorage.setItem("sortDir", query.sortDir)

        setInventoryRecordToShow(inventory)
    }

    const handleReportError = () => {
        snackbarShowMessage('Failed to download report', 'error');
    }

    const filterState = {filter: "inventory"}

    return (
        <SecurSpaceContainer className={classes.mainContainer}>
            <Grid sx={{
                width: '100%',
            }} container spacing={1} flexDirection={"column"}>
                <Grid item marginBottom={"2em"}>
                    <Typography variant={'h5'} component={'h1'}>Space Usage</Typography>
                </Grid>
                {
                    inventoryRecordToShow ?
                        <InventoryRecords
                            account={account}
                            inventoryDate={inventoryRecordToShow.calendarDate}
                            spaceUtilizationRecord={inventoryRecordToShow}
                            closeInventoryRecords={closeInventoryRecords}
                            filterState={filterState}
                        />
                        :
                        <Grid item sx={{
                            width: '100%',
                        }}>
                            <Grid item container p={2} mb={4}>
                                <BuyerSpaceUsageFilters
                                    buyerAccountId={account.id}
                                    setSelectedLocation={setSelectedLocation}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    setBookingNumber={setBookingNumber}
                                    setInvoiceNumber={setInvoiceNumber}
                                    setSortBy={setSortBy}
                                    setSortDir={setSortDir}
                                    savedStartDate={startDate}
                                    savedEndDate={endDate}
                                    savedLocation={selectedLocation}
                                    savedBookingNumber={bookingNumber}
                                    savedSortBy={sortBy}
                                    savedInvoiceNumber={invoiceNumber}
                                />
                            </Grid>
                            {
                                !loading && spaceUsageData.length > 0 && <Grid item container justifyContent={"space-between"} mb={6} gap={2}>
                                    <Grid item lg={6}>
                                        <Typography
                                          variant='body2'
                                          sx={{ textTransform: 'uppercase', color: '#000'}}
                                          component='p'
                                        >
                                            {selectedLocation?.locationName || 'All'} (SHOWING {spaceUsageData.length} RECORDS IN GROUP)
                                        </Typography>
                                    </Grid>
                                    <BuyerSpaceUsageExportCSVs
                                        item
                                        query={query}
                                        onError={handleReportError}
                                    />
                                </Grid>
                            }
                            {
                                (loading || spaceUsageData.length > 0) && <Grid item mb={4} xs={12}>
                                    {
                                        loading ? <GroupSummariesLoader /> : <GroupSummaries
                                            groupSummaryFields={[
                                                {
                                                    title: 'Total Overage Spaces',
                                                    value: summaries.totalOverageSpaces,
                                                },
                                                {
                                                    title: 'Total Charged Overages',
                                                    value: summaries.totalChargedOverages,
                                                },
                                                {
                                                    title: 'Total Un-Charged Overages',
                                                    value: summaries.totalUnchargedOverages,
                                                },
                                            ]}
                                        />
                                    }
                                </Grid>
                            }
                            {
                                loading ? Array.from({ length: 12 }).map((_, index) => (
                                    <SpaceUsageLoader key={index} />
                                )) : spaceUsageData.length > 0 ?
                                    <BuyerSpaceUsageWithPagination
                                        spaceUsageData={spaceUsageData}
                                        classes={classes}
                                        account={account}
                                        size={size}
                                        page={page}
                                        totalNumberResults={totalNumberResults}
                                        handlePageChange={handlePageChange}
                                        handleSizeChange={handleSizeChange}
                                        setInventoryRecordToShow={handleShowInventory}
                                    />
                                    : <NoMatchingInvoicesFound item message="No matching records found" />
                            }
                        </Grid>
                }
            </Grid>
        </SecurSpaceContainer>
    )
}

export default withSnackbar(BuyerSpaceUsageReport);
