import { verbiage } from "./payouts-report-verbiage";
import { SupplierInvoiceStatusGroup } from "../constants/booking-transaction-constants";

const headerCells = [
  { id: 'payoutAmount', label: verbiage.fieldAmount },
  { id: 'locationName', label: verbiage.fieldLocation },
  { id: 'createdOn', label: verbiage.fieldPayoutInitiated },
  { id: 'completedOn', label: verbiage.fieldPayoutCompleted },
  { id: 'status', label: verbiage.fieldStatus }
];

const sortByOptions = [
  { value: "createdOn", label: verbiage.fieldPayoutInitiated  },
  { value: "completedOn", label: verbiage.fieldPayoutCompleted },
  { value: "status", label: verbiage.fieldStatus },
  { value: "payoutAmount", label: verbiage.fieldAmount },
  { value: "locationName", label: verbiage.fieldLocation },
];
const filterByOptions = []
const payoutCompletedOptions = [
  { value: 'true', label: verbiage.optionYes },
  { value: 'false', label: verbiage.optionNo },
];

const filterBySelectOptions = [
  {
    label: verbiage.fieldPayoutComplete,
    value: "",
    name: "payoutCompleted",
    option: [
      { value: '', label: verbiage.optionAny },
      ...payoutCompletedOptions,
    ],
  },
  {
    label: verbiage.fieldStatus,
    value: "",
    name: "status",
    option: [
      { value: '', label: verbiage.optionAny },
      ...Object.values(SupplierInvoiceStatusGroup),
    ],
  },
];

const groupSummaryFields = [
  {
    title: verbiage.summaryTotalPayouts,
    value: 0,
  },
  {
    title: verbiage.summaryPayoutsCount,
    value: 0,
  },
  {
    title: verbiage.summaryTransactionCount,
    value: 0,
  },
];

export {
    headerCells,
    sortByOptions,
    filterByOptions,
    filterBySelectOptions,
    groupSummaryFields
}