import request from '../../util/SuperagentUtils';
import { logout } from "../../util/LogoutUtil";

const BOOKING_URL = "/api/booking";

const handleResponse = (response) => {
  if (!response.ok) {
    throw Error(`${response.status} - ${response.statusText}`);
  }
  if (response.status === 401) {
    logout();
  }
  return response.text();
}

export const sendBookingConfirmationEmail = async (bookingId) => {
  const response = await fetch(`${BOOKING_URL}/${bookingId}/confirmation-email`, {
    method: "POST"
  });
  return handleResponse(response);
}

export const requestDenyRefund = async (transaction) => {
  const response = await fetch(`${BOOKING_URL}/deny-refund`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Accept": "application/json",
    },
    body:  JSON.stringify(transaction),
  });
  return handleResponse(response);
}

export const bookSpace = (bookingRequest) => {
  return request.post('/api/booking').send(bookingRequest);
}

export const cancelBooking: (payload: {id: string}) => Promise = (payload) => {
  return request.post(`${BOOKING_URL}/cancel`).send(payload);
};

export const cancelBookingSubscription: (payload: {id: string}) => Promise = (payload) => {
  return request.post(`${BOOKING_URL}/cancel-subscription`).send(payload);
};

export const calculateBookingChargeAmount = (bookingData: string) : Promise => {
  return request.post('/api/booking/calculate-booking-charge-amount').send(bookingData);
}

export const completeBooking = async (bookingData) => {
  return request.post('/api/booking/complete-booking').send(bookingData);
}
export const getBookingInfo = async (bookingId) => {
  return request.get(`/api/booking/${bookingId}`)
}

export const adjustEndDate = (adjustDateRequest) => {
  return request.post(`/api/booking/adjust-dates`).send(adjustDateRequest);
}

export const updatePaymentMethodForBooking = (updatePaymentData) => {
  return request.post(`api/booking/update-payment-method`).send(updatePaymentData)
}
