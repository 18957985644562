import React from 'react';

const MICRO_DEPOSIT = "Micro Deposit";
const INSTANT = "Instant";

const PayWithACH = (props) => {
  const {selectInstantVerifyClassName, toggleMicroDepositVerify, selectMicroDepositVerifyClassName} = props;
  const verifyClassName = selectInstantVerifyClassName();
  const microDepositClassName = selectMicroDepositVerifyClassName();

  return <>
    <div>
      <br/>
      <h4 className="ss-summary">Select Bank Account Verify Method</h4>
      <div>
        <button type="button"
                id="selectInstantVerify"
                className={verifyClassName}
                onClick={() => toggleMicroDepositVerify(INSTANT)}>
          Instant
        </button>
        <button type="button"
                id="selectMicroDepositVerify"
                className={microDepositClassName}
                onClick={() => toggleMicroDepositVerify(MICRO_DEPOSIT)}>
          Micro Deposit
        </button>
        <p className="ss-details">
          <b>Instant</b>
          <br/>
          &nbsp;&bull;&nbsp;&nbsp;Requires you to enter your Online Banking credentials
          <br/>
          &nbsp;&bull;&nbsp;&nbsp;Not supported with some local banks
          <br/>
          <b>Micro Deposit</b>
          <br/>
          &nbsp;&bull;&nbsp;&nbsp;Requires you to enter your bank account info
          <br/>
          &nbsp;&bull;&nbsp;&nbsp;Takes a few days to complete
          <br/>
          &nbsp;&bull;&nbsp;&nbsp;Supported with all banks
        </p>
      </div>
    </div>
  </>
}

export default PayWithACH;
