import React, { useState } from 'react';
import { Box, Button, TextField, Typography, InputAdornment, Grid } from "@mui/material";
import AccountTypeSelect from "./AccountTypeSelect";
import {
  requestAddPaymentMethod,
  requestToken
} from "../../components/paymentMethods/addPaymentMethod/paymentMethodRequests";
import { getErrorMessageForNonStandardAndStandardResponse } from "../../util/NetworkErrorUtil";
import Busy from "../../components/Busy";
import DwollaService from "../../components/paymentMethods/processors/DwollaService";
import { AccountTypes } from "../../components/constants/securspace-constants";
import BankAccountConfirmation from "../../components/paymentMethods/addPaymentMethod/BankAccountConfirmation";
import { useStyles } from './styles/bankInfoStyles';
import {
  Person,
} from '@mui/icons-material';
import {withSnackbar} from "../../components/hocs/withSnackbar";

const RedesignMicroDepositForm = React.forwardRef((props) => {
  const { account, setShowMicroDepositVerify, handleModalClose, snackbarShowMessage } = props;
  const classes = useStyles();
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountNumberVerify, setAccountNumberVerify] = useState('');
  const [accountType, setAccountType] = useState('');
  const [accountAddedSuccessfully, setAccountAddedSuccessfully] = useState(false);
  const [accountHolderName, setAccountHolderName] = useState('');
  const dwolla = new DwollaService({ dwollaEnv: account.dwollaEnv });

  const handleRoutingNumberChange = (event) => {
    setRoutingNumber(event.target.value);
  }
  const handleAccountNameChange = (event) => {
    setAccountHolderName(event.target.value);
  }

  const handleAccountNumberChange = (event) => {
    setAccountNumber(event.target.value);
  }

  const handleAccountNumberVerifyChange = (event) => {
    setAccountNumberVerify(event.target.value);
  }

  const handleAccountTypeChange = (event) => {
    setAccountType(event.target.value);
  }

  const onSubmit = () => {
    if ((accountNumber.length >= 4) && (accountNumber !== accountNumberVerify)) {
      alert('Account numbers do not match');
      return;
    }
    const bankAccountLastFour = accountNumber.substring(accountNumber.length - 4, accountNumber.length);
    const checkingOrSavings = accountType === AccountTypes.COMPANY_CHECKING || accountType === AccountTypes.INDIVIDUAL_CHECKING ? "checking" : "savings";
    Busy.set(true);
    requestToken(account)
      .then((resp) => {
        const data = {
          authorityId: account.authorityId,
          userType: account.userType,
          routingNumber: routingNumber,
          accountNumber: accountNumber,
          accountType: checkingOrSavings,
          bankAccountLastFour: bankAccountLastFour,
          token: resp.body.token
        }
        dwolla.createDwollaFundingSource(data, (dwollaFundingSourceId) => {
          const paymentAdd = JSON.stringify({
            accountId: account.id,
            paymentProcessor: 'DWOLLA',
            bankAccountNickName: bankAccountLastFour,
            dwollaFundingSourceId: dwollaFundingSourceId
          })
          requestAddPaymentMethod(paymentAdd)
            .then(() => {
              setAccountAddedSuccessfully(true);
            })
        }, (error) => {
          if (error.toLowerCase().startsWith("bank already exists")) {
            // Avoid showing user id in error message
            snackbarShowMessage("A bank account with the provided routing and account number already exists for your account.", 'error', 15000);
            return;
          }
          snackbarShowMessage(error, 'error', 15000);
        })
      }).catch((error) => {
        snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error', 15000);
      }).finally(() => {
        Busy.set(false);
      });
  }

  return <Box className={classes.mainContainer}>
    {!accountAddedSuccessfully ?
      <Box>
        <Box className={classes.paddingContainer}>
          <Typography variant='h6' className={classes.verifyTitlePrimary}>Add Payment Method</Typography>
          <Typography variant='h6' className={classes.routeLabel}>
            Enter Bank Account Details
          </Typography>
          <TextField
            type='text'
            onChange={handleAccountNameChange}
            value={accountHolderName}
            placeholder='Enter the full name of the person who holds this account'
            className={classes.appInput}
            fullWidth
            label="Account Holder Full Name"
            InputLabelProps={{
              shrink: true,
            }}
            variant='standard'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Person className={classes.personIcon} size='sm' />
                </InputAdornment>
              ),
            }}
          />
          <AccountTypeSelect accountType={accountType} handleAccountTypeChange={handleAccountTypeChange} />
          <TextField
            type='text'
            onChange={handleRoutingNumberChange}
            placeholder='1100000000'
            className={classes.appInput}
            fullWidth
            label="Routing Number"
            InputLabelProps={{
              shrink: true,
            }}
            variant='standard'
          />
          <TextField
            type='text'
            onChange={handleAccountNumberChange}
            placeholder='1122334455666'
            className={classes.appInput}
            fullWidth
            label="Account Number"
            InputLabelProps={{
              shrink: true,
            }}
            variant='standard'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img className={classes.accountIcon} src="/app-images/AccountBalance.png" alt="Account Icon" />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            type='text'
            onChange={handleAccountNumberVerifyChange}
            placeholder='1122334455666'
            className={classes.appInput}
            fullWidth
            label="Re-enter Account Number"
            InputLabelProps={{
              shrink: true,
            }}
            variant='standard'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img className={classes.accountIcon} src="/app-images/AccountBalance.png" alt="Account Icon" />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Grid className={classes.verifyButton} container alignItems='flex-end' justifyContent='flex-end'>
          <Grid item>
            <Button variant='text' color='secondary' onClick={() => setShowMicroDepositVerify(false)}>
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button variant='text' color='secondary' onClick={onSubmit}>
              Next
            </Button>
          </Grid>
        </Grid>
      </Box> :
      <BankAccountConfirmation
        handleModalClose={handleModalClose}
      />
    }
  </Box>
});

export default withSnackbar(RedesignMicroDepositForm);
