import React, { useEffect, useState } from 'react';
import AccountReport from './AccountReport';
import { useParams } from 'react-router-dom';
import { getTransactionsByPartnerSubscriptionId } from '../components/subscriptions/subscription-requests';
import { toast } from 'react-toastify';
import moment from 'moment';
import { PartnerSubscriptionTransactionStatusTypeLabel, DateFormats, PartnerSubscriptionTypeLabel } from '../components/constants/securspace-constants';
import Busy from '../components/Busy';
import Money from 'js-money';

const PartnerSubscriptionInvoicesReportLegacy = (props) => {
    const [transactions, setTransactions] = useState([]);
    const { subscriptionId } = useParams();

    useEffect(() => {
        if (!subscriptionId) {
            toast.error('No Partner Subscription Id Provided in URL');
        } else {
            Busy.set(true);
            getTransactionsByPartnerSubscriptionId(subscriptionId).then((response) => {
                if (response.body) {
                    setTransactions(response.body);
                }
            }).catch(() => {
            }).finally(() => Busy.set(false));
        }
    }, [subscriptionId]);

    const transactionFields = [
        {
            label: "Invoice Number",
            name: "transactionNumber",
        },
        {
            label: "Type",
            name: "type",
            formatter: (value) => {
                return PartnerSubscriptionTypeLabel[value] ? PartnerSubscriptionTypeLabel[value] : value.replaceAll('_', ' ');
            }
        },
        {
            label: "Amount",
            name: "amount",
            formatter: (value, transaction) => {
                // If actual transaction amount is 0 then DataListResultsEntryRow.js will pass '' as the value
                let sanitizedValue;
                if (value) {
                    sanitizedValue = value;
                } else {
                   sanitizedValue = transaction.amount ? transaction.amount : 0;
                }
                const money = new Money(sanitizedValue, Money.USD, Math.round); // val originally stored in cents
                return `${Money.USD.symbol}${money.toString()}`;
            }
        },
        {
            label: "Service Month",
            name: "creationDate",
            formatter: (value) => {
                return moment(new Date(value)).format(DateFormats.MONTH_YEAR);
            }
        },
        {
            label: "Payment Date",
            name: "serviceDate",
            formatter: (value) => {
                return value ? `${moment(new Date(value)).format(DateFormats.DAY)}` : '';
            },
        },
        {
            label: "Status",
            name: "status",
            formatter: (value) => {
                return PartnerSubscriptionTransactionStatusTypeLabel[value] ? PartnerSubscriptionTransactionStatusTypeLabel[value] : value.replaceAll('_', ' ');
            }
        },
    ];

    return (
        <article className="h-100">
            <AccountReport
                title="Partner Subscription Invoices"
                parentMenu="Finances"
                data={transactions}
                account={props.account}
                reportFields={transactionFields}
                defaultSortBy="creationDate"
                defaultSortByDirection="DESC"
            />
        </article>
    );
};

export default PartnerSubscriptionInvoicesReportLegacy;