import React from 'react';
import {Grid} from "@mui/material";
import type {BookedSpaceCalendarView} from "../types/BookedSpaceCalendarView";
import SpaceUsageItem from "./spaceUsage/supplier/SpaceUsageItem";

const SpaceUsageItemsList = (props: {spaceUsageItems: BookedSpaceCalendarView[], onViewOverageInvoices: () => void}) => {
  const {spaceUsageItems, onViewOverageInvoices} = props;
  return (
    <Grid item container rowGap={'0.36rem'}>
      {
        spaceUsageItems.map((item) => <SpaceUsageItem data={item} key={item.id} onViewOverageInvoices={onViewOverageInvoices}/>)
      }
    </Grid>
  );
};

export default SpaceUsageItemsList;