import React from 'react';
import {Grid, Typography} from '@mui/material';
import { makeStyles } from '@mui/styles';
import LockedOutlinedIcon from '@mui/icons-material/LockOutlined';
import CameraOutlinedIcon from '@mui/icons-material/CameraOutlined';
import WbIncandescentOutlinedIcon from '@mui/icons-material/WbIncandescentOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import NightsStayOutlinedIcon from '@mui/icons-material/NightsStayOutlined';
import WcOutlinedIcon from '@mui/icons-material/WcOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';

const useStyles: () => {
  typography: CSSStyleSheet,
  alignSelf: CSSStyleSheet,
  gridPadding: CSSStyleSheet,
} = makeStyles(() => ({
  typography: {
    color: '#2E363E',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.857143rem',
    lineHeight: '1.07143rem',
  },
  alignSelf: {
    alignSelf: 'center',
  },
}));

// better way to do this?
const FeatureIconMap = {
  'Manned Security': (classes, fontSize, htmlColor) => <LockedOutlinedIcon className={classes.alignSelf} fontSize={fontSize} htmlColor={htmlColor}/>,
  'Fenced / Gated': (classes, fontSize, htmlColor) => <BlockOutlinedIcon className={classes.alignSelf} fontSize={fontSize} htmlColor={htmlColor}/>,
  'Yard Cameras': (classes, fontSize, htmlColor) => <CameraOutlinedIcon className={classes.alignSelf} fontSize={fontSize} htmlColor={htmlColor}/>,
  'Gate Cameras': (classes, fontSize, htmlColor) => <CameraOutlinedIcon className={classes.alignSelf} fontSize={fontSize} htmlColor={htmlColor}/>,
  'Lights': (classes, fontSize, htmlColor) => <WbIncandescentOutlinedIcon className={classes.alignSelf} fontSize={fontSize} htmlColor={htmlColor}/>,
  'Paved': (classes, fontSize, htmlColor) => <LocalShippingOutlinedIcon className={classes.alignSelf} fontSize={fontSize} htmlColor={htmlColor}/>,
  'M & R Offered': (classes, fontSize, htmlColor) => <BuildOutlinedIcon className={classes.alignSelf} fontSize={fontSize} htmlColor={htmlColor}/>,
  'Side-pick / Top-Pick': (classes, fontSize, htmlColor) => <PublishOutlinedIcon className={classes.alignSelf} fontSize={fontSize} htmlColor={htmlColor}/>,
  'Daily Inventory Report': (classes, fontSize, htmlColor) => <AssignmentTurnedInOutlinedIcon className={classes.alignSelf} fontSize={fontSize} htmlColor={htmlColor}/>,
  '24/7 Access': (classes, fontSize, htmlColor) => <HistoryOutlinedIcon className={classes.alignSelf} fontSize={fontSize} htmlColor={htmlColor}/>,
  'Overnight Truck Parking': (classes, fontSize, htmlColor) => <NightsStayOutlinedIcon className={classes.alignSelf} fontSize={fontSize} htmlColor={htmlColor}/>,
  'Restrooms': (classes, fontSize, htmlColor) => <WcOutlinedIcon className={classes.alignSelf} fontSize={fontSize} htmlColor={htmlColor}/>,
}

const LocationFeatureListItem = (props) => {
  const {feature} = props;
  const classes = useStyles();

  return <Grid item>
    <Grid container spacing={1}>
      <Grid item>
        {/*
          for some reason making a Grid item makes a containing div that doesn't have display: flex; on it.
          this causes any flex properties to not work in child components. In order to get the child component to
          flex properly you need to create another container within it. fyi, i also tried creating <Grid item container>
          but that creates the structure <div class='Mui-container'><div class='Mui-item>{children}</div></div> which, as
          you can see, runs into the same problem. having extra containers is kinda annoying, but not the worst.
        */}
        <Grid container>
          {
            FeatureIconMap[feature](classes, 'small', '#7C8597')
          }
        </Grid>
      </Grid>
      <Grid item className={classes.alignSelf}>
        <Typography className={classes.typography}>
          {feature}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
}

export default LocationFeatureListItem;
