import React from 'react';
import BookingFromToDatePicker, {CollapsibleMonthlyAutoRenewalAlert} from "../../BookingFromToDatePicker";
import BookingDetailsFieldToggle from "./BookingDetailsField/BookingDetailsFieldToggle";
import moment from "moment";
import {Grid, Theme} from "@mui/material";
import {ReactNode} from "react";
import { makeStyles } from '@mui/styles';

const useStyles: (theme: Theme) => { alert: CSSStyleSheet, marginBottom8: CSSStyleSheet } = makeStyles(() => ({
  alert: {
    margin: '0em 0em 0.71em 0em',
  },
  marginBottom8: {
    marginBottom: '.57em'
  }
}))

const BookingDetailsDatePicker = (props: {
  editMode: boolean,
  onToggleEditMode: (value: boolean) => void,
  showEndDate: boolean,
  fromDate: any,
  onFromDateChange: (date: any) => void,
  toDate: any,
  onToDateChange: (date: any) => void,
  disablePast?: boolean,
  readOnly?: boolean,
  prePendedIcon?: ReactNode,
  postPendedIcon?: ReactNode,
}) => {
  const {
    editMode,
    onToggleEditMode,
    showEndDate,
    fromDate,
    onFromDateChange,
    toDate,
    onToDateChange,
    disablePast,
    readOnly,
    prePendedIcon,
    postPendedIcon,
  } = props;
  const classes = useStyles();

  const handleToggleEdit = (value, event) => {
    if (!readOnly) {
      onToggleEditMode(value, event);
    }
  };

  const formatDatesText = (startDate, endDate) => {
    const momentStartDate = moment(startDate);
    if (!showEndDate) {
      if (moment().isSame(momentStartDate, 'year')) {
        return momentStartDate.format("MMMM DD");
      }
      return momentStartDate.format("MMMM DD, YYYY");
    }
    const momentEndDate = moment(endDate);
    if (momentStartDate.isSame(momentEndDate, 'year')) {
      if (momentStartDate.isSame(momentEndDate, 'month')) {
        return `${momentStartDate.format("MMMM DD")} - ${momentEndDate.format("DD")}`
      }
      return `${momentStartDate.format("MMMM DD")} - ${momentEndDate.format("MMMM DD")}`;
    }
    return `${momentStartDate.format("MMMM DD, YYYY")} - ${momentEndDate.format("MMMM DD, YYYY")}`;
  };

  return (
    <>
      <BookingDetailsFieldToggle title={'Dates'} open={editMode && !readOnly} value={formatDatesText(fromDate, toDate)}
                                 onChange={handleToggleEdit} disableToggle={readOnly} toggleButtonClassName={editMode ? classes.marginBottom8 : ''}>
        <BookingFromToDatePicker
          showEndDate={showEndDate}
          fromDate={fromDate}
          onFromDateChange={onFromDateChange}
          toDate={toDate}
          onToDateChange={onToDateChange}
          disablePast={disablePast}
          prePendedIcon={prePendedIcon}
          postPendedIcon={postPendedIcon}
          hideAutoRenewalNotice
        />
      </BookingDetailsFieldToggle>
      <Grid item xs={12} className={!showEndDate ? classes.alert : ''}>
        <CollapsibleMonthlyAutoRenewalAlert showEndDate={showEndDate} />
      </Grid>
    </>
  );
};

export default BookingDetailsDatePicker;