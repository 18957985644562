import React from "react";
import { ButtonGroup, Button, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    blackTitle: {
        color: '#000000',
        fontWeight: 500,
    },
    blackDescription: {
        color: '#000000',
        fontWeight: 400,
        marginBottom: '1em',
    },
})


const RegisterSubMenu = (props) => {
    const { show, onClick } = props;
    const history = useNavigate();
    const classes = useStyles();

    return <ButtonGroup
        variant="text"
        className={show ? "ss-register-submenu-visible" : "ss-submenu-hidden"}
    >
        <Button onClick={() => {
            onClick && onClick();
            history(`/buyer-signup`)
        }} className="ss-register-customer-button-container">
            <Grid container className="ss-register-submenu-item ss-register-customer-button">
                <Typography variant="body1" className={classes.blackTitle}>As A Buyer</Typography>
                <Typography variant="body2" className={classes.blackDescription}>Reserve available parking and storage space.</Typography>
                <Typography variant="body2" className='ss-register-submenu-caps'>REGISTER</Typography>
            </Grid>
        </Button>
        <Button onClick={() => {
            onClick && onClick();
            history(`/supplier-signup`)
        }}>
            <Grid container className="ss-register-submenu-item ss-register-partner-button">
                <Typography variant="body1" className={classes.blackTitle}>As A Supplier</Typography>
                <Typography variant="body2" className={classes.blackDescription}>List your location for truck and container parking.</Typography>
                <Typography variant="body2" className='ss-register-submenu-caps'>LEARN MORE</Typography>
            </Grid>
        </Button>
    </ButtonGroup>
        ;
}

export default RegisterSubMenu;