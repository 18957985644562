const serviceFees = {
  LIFT_FEE: "Lift Fee",
  GATE_FEE: "Gate Fee",
  VALET_MOVING_FEE: "Valet/Moving Fee",
  TRANSLOADING: "Transloading",
  CONTAINER_FREIGHT_STABILIZATION: "Container Freight Stabilization",
  DRAYAGE: "Drayage",
  EV_CHARGING: "EV Charging",
  MUDFLAP_REPAIR: "Mudflap Repair",
  TRUCK_OR_TRAILER_WASH: "Truck or Trailer Wash",
  HEADLIGHT_TAILLIGHT_REPAIR_REPLACEMENT: "Headlight/taillight Repair/Replacement",
  TIRE_REPAIR_REPLACEMENT: "Tire Repair/Replacement",
  OIL_CHANGE: "Oil Change",
  GENERAL_CHASSIS_REPAIR: "General Chassis Repair",
  GENERAL_CONTAINER_REPAIR: "General Container Repair",
};

const getServiceFeeDisplayValue = (value) => {
  return value && serviceFees[value] ? serviceFees[value] : "";
};

export { getServiceFeeDisplayValue };