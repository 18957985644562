import React, {useEffect, useMemo, useState} from 'react';
import {getInvoicesInPayout} from "./payout-requests";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../util/NetworkErrorUtil";
import {toast} from "react-toastify";
import {useParams} from "react-router";
import {Box, Grid, Typography} from "@mui/material";
import {CSVButton, GroupSortFilter, Pagination} from "@securspace/securspace-ui-kit";
import DownloadCsvIcon from "../../components/icons/DownloadCsvIcon";
import NoMatchingInvoicesFound from "../../components/NoMatchingInvoicesFound";
import {SORT_DIRECTION_ASC} from "../../util/ReportUtils";
import {scrollToTop} from "../../util/BookingUtil";
import {getComparator, stableSort} from "../../util/Sorting";
import moment from "moment";
import useStyles from '../../components/partner-subscriptions/styles';
import InvoicesInPayoutCard from "./InvoicesInPayoutCard";
import Busy from "../../components/Busy";
import {BookingTransactionTypeLabel, DateFormats} from "../../components/constants/securspace-constants";

const InvoicesInPayoutReport = () => {
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [sort, setSort] = useState('');
  const [order, setOrder] = useState(SORT_DIRECTION_ASC);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const {payoutCorrelationId} = useParams();
  const classes = useStyles();

  const sortByOptions = [
    {value: "none", label: "None"},
    {value: "transactionNumber", label: "Invoice Number"},
    {value: "supplierAmount", label: "Amount"},
    {value: "createdOn", label: "Charged Date"},
    {value: "serviceDates", label: "Service Dates"},
    {value: "paymentCreatedOn", label: "Payment Date"},
    {value: "transactionType", label: "Type"},
    {value: "bookingNumber", label: "Booking Number"},
    {value: "buyerCompanyName", label: "Buyer"},
  ];

  const handleSearchQueryChange = (event) => {
    setSearchFilter(event.searchFilter);
    handleSortChange(event.sortBy, event.sortDir);
  };

  const handleSortChange = (sort, order) => {
    setSort(sort);
    setOrder(order);
  };

  const handlePageChange = (_, selectedPage) => {
    setPage(selectedPage);
    scrollToTop();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    scrollToTop();
  };

  const visibleRows = useMemo(() =>
      stableSort(filteredInvoices, getComparator(order, sort)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [sort, page, rowsPerPage, filteredInvoices, order],
  );

  useEffect(() => {
    if (!payoutCorrelationId) {
      toast.error('No Payout Correlation Id Provided in URL');
    } else {
      Busy.set(true);
      getInvoicesInPayout(payoutCorrelationId)
        .then(({body}) => {
          if (body) {
            setInvoices(formatItems(body));
          }
        }).catch(error => {
        toast.error(getErrorMessageForNonStandardAndStandardResponse(error));
      }).finally(() => Busy.set(false));
    }
  }, [payoutCorrelationId]);


  useEffect(() => {
    let filtered = [];

    if (searchFilter) {
      let filterTokens = searchFilter.split(" ").map(value => value.toLocaleLowerCase());
      invoices.forEach((invoice) => {
        for (let token of filterTokens) {
          if (invoice?.transactionNumber?.toString().includes(token) ||
            invoice?.bookingNumber?.toString().includes(token) ||
            invoice?.buyerCompanyName?.toLocaleLowerCase().includes(token)
          ) {
            filtered.push(invoice)
            break;
          }
        }
      });

    } else {
      filtered = invoices;
    }

    setFilteredInvoices(filtered);
  }, [searchFilter, invoices]);

  const formatItems = (invoices) => {
    return invoices.map((item) => {
      const {
        transactionNumber,
        supplierAmount,
        createdOn,
        serviceDates,
        paymentCreatedOn,
        transactionType,
        bookingNumber,
        buyerCompanyName
      } = item;

      return ({
        transactionNumber: transactionNumber,
        supplierAmount: supplierAmount,
        createdOn: createdOn,
        serviceDates: serviceDates,
        agsServiceDates:  moment(serviceDates).format(DateFormats.DAY),
        paymentCreatedOn: paymentCreatedOn,
        transactionType: BookingTransactionTypeLabel[transactionType],
        bookingNumber: bookingNumber,
        buyerCompanyName: buyerCompanyName,
        serviceMonth: moment(createdOn).format(DateFormats.MONTH_YEAR)
      });
    })
  };

  const reportName = `Invoices In Payout_${moment().format("MM-DD-YYYY_hh:mm A")}`;

  return <Grid container flexDirection="column" className={classes.mainContainer}>
    <Typography variant={'h5'} component={'h1'}>Invoices In Payout</Typography>
    <Grid container item justifyContent="space-between">
      <Grid item mt={4} className={classes.searchContainer}>
        <GroupSortFilter
          filterCallback={handleSearchQueryChange}
          sortByOption={sortByOptions}
          sortDir={order}
          debounceTime={1000}
          searchPlaceholder={'Search by invoice number, booking number, or buyer'}
        />
      </Grid>
      <Grid item mt={4} className={classes.csvContainer}>
        <CSVButton
          reportName={reportName}
          reportData={filteredInvoices}
          title="Download Report"
          startIcon={<DownloadCsvIcon/>}
        />
      </Grid>
    </Grid>
    <Box mt={4}>
      {
        visibleRows?.length ?
          <InvoicesInPayoutCard invoices={visibleRows} handleSortChange={handleSortChange}/>
          :
          <NoMatchingInvoicesFound
            item
            imageURI="/app-images/person-and-box.svg"
            message="No invoices found in this payout"
          />
      }
    </Box>
    <Pagination
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      count={invoices?.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </Grid>;
}

export default InvoicesInPayoutReport;
