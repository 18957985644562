import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    title: {
      fontFamily: 'Inter',
      fontSize: '0.9em',
      fontWeight: '400',
      padding: '0 0 0.7em 0',
      marginBottom: '0.5em',
      marginTop: '0.5em',
    },
    cardContent: {
      backgroundColor: '#F5F5F5',
      padding: '0.8em',
      borderRadius: '0.5em',
      marginBottom: '.3em',
    },
    cardContainer:{
        padding: '0em 1.5em .2em 1.5em',
    },
    cardTitle: {
      fontFamily: 'Inter',
      fontSize: '1em',
      fontWeight: '500',
      color: '#000000',
    },
    cardSubtitle: {
      fontFamily: 'Inter',
      fontSize: '0.9em',
      fontWeight: '400',
      color: 'rgba(0, 0, 0, 0.6)',
      lineHeight: '1.28em',
      marginTop: '0.2em',
    }
  
  }));
  