import React from "react";
import {withGroupAndSortExportAndGroupings} from "../../report/hocs/withGroupAndSortExportAndGroupings";
import ReportRow from "../../report/ReportRow";
import InvoiceItem from "./InvoiceItem";
import ReportTotal from "../../report/ReportTotal";
import {formatCurrencyValue} from "../../../util/PaymentUtils";
import AdminInvoicesMenu from "./AdminInvoicesMenu";

const InvoicesContent = (props) => {

  const {
    account,
    invoices,
    addRefundCreditCompleted,
    viewInvoice,
    viewInventoryLog,
    handlePutPayoutOnHold,
    handleTakePayoutOffHold,
    handleRetryPayment,
    addRefundTransactionCompleted,
    handleCompleteRefund,
    handleDenyRefund,
    handleIncludeRefundInPayout,
    closeAllDialogs,
    showAddRefundTransactionView,
    addRefundTransaction,
    showAddRefundCreditView,
    addRefundCreditTransaction
  } = props;
  const formatTotals = (value) => formatCurrencyValue(value, true);

  return <>
    <ReportTotal
      data={invoices}
      totalFieldLabel={'Customer Charges'}
      totalFieldName={'buyerAmount'}
      totalFieldFormat={formatTotals}
    />
    <ReportTotal
      data={invoices}
      totalFieldLabel={'Payment Processor Fees'}
      totalFieldName={'paymentProcessorFees'}
      totalFieldFormat={formatTotals}
    />
    <ReportTotal
      data={invoices}
      totalFieldLabel={'Partner Payouts'}
      totalFieldName={'supplierAmount'}
      totalFieldFormat={formatTotals}
    />
    <ReportTotal
      data={invoices}
      totalFieldLabel={'SecurSpace Fees'}
      totalFieldName={'securspaceFees'}
      totalFieldFormat={formatTotals}
    />
    <br/>
    {invoices && invoices.map((invoice, key) => {
      return <ReportRow key={key}>
        <AdminInvoicesMenu
          account={account}
          viewInvoice={viewInvoice}
          viewInventoryLog={viewInventoryLog}
          addRefundCreditCompleted={addRefundCreditCompleted}
          handlePutPayoutOnHold={handlePutPayoutOnHold}
          handleTakePayoutOffHold={handleTakePayoutOffHold}
          handleRetryPayment={handleRetryPayment}
          addRefundTransactionCompleted={addRefundTransactionCompleted}
          handleCompleteRefund={handleCompleteRefund}
          handleIncludeRefundInPayout={handleIncludeRefundInPayout}
          handleDenyRefund={handleDenyRefund}
          addRefundCreditTransaction={addRefundCreditTransaction}
          addRefundTransaction={addRefundTransaction}
          showAddRefundCreditView={showAddRefundCreditView}
          showAddRefundTransactionView={showAddRefundTransactionView}
          closeAllDialogs={closeAllDialogs}
          invoice={invoice}
        />
        <InvoiceItem
          invoice={invoice}
        />
      </ReportRow>
    })}
  </>
}

export default InvoicesContent
export const InvoicesItemsWithGroupAndSort = withGroupAndSortExportAndGroupings(InvoicesContent, 'invoices');
