import React, {useState} from 'react';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import { makeStyles } from '@mui/styles';
import {DeleteForever, Edit, MoreHoriz} from "@mui/icons-material";
import {Theme} from "@mui/material";
import PropTypes from "prop-types";

const useStyles: (theme: Theme) => {
  fontColorSecondaryDark: CSSStyleSheet,
  minWidthAuto: CSSStyleSheet,
  columnGap8: CSSStyleSheet,
} = makeStyles((theme) => ({
  fontColorSecondaryDark: {
    color: theme.palette.secondary.dark
  },
  minWidthAuto: {
    minWidth: 'auto'
  },
  columnGap8: {
    columnGap: '0.57rem',
  },
}));

const PaymentMethodsActionsMenu = ({ onSelect, disableRemove = false }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const iconRef = React.useRef(null);

  const classes = useStyles();

  const onIconClick = () => {
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleSelect = (value) => {
    setMenuOpen(false);
    onSelect(value);
  };

  return (
    <>
      <IconButton
        onClick={onIconClick}
        aria-haspopup={true}
        aria-controls={'ss-payment-actions'}
        ref={iconRef}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id={'ss-payment-actions'}
        anchorEl={iconRef.current}
        open={menuOpen}
        onClose={handleCloseMenu}
        keepMounted
      >
        {
          !disableRemove && <MenuItem onClick={() => handleSelect('remove')} className={classes.columnGap8}>
            <ListItemIcon className={classes.minWidthAuto}>
              <DeleteForever className={classes.fontColorSecondaryDark} fontSize={'small'}/>
            </ListItemIcon>
            <ListItemText
              primary={'Remove'}
              primaryTypographyProps={{
                variant: 'subtitle2',
                color: 'secondary'
              }}
            />
          </MenuItem>
        }
        <MenuItem onClick={() => handleSelect('rename')} className={classes.columnGap8}>
          <ListItemIcon className={classes.minWidthAuto}>
            <Edit className={classes.fontColorSecondaryDark} fontSize={'small'} />
          </ListItemIcon>
          <ListItemText
            primary={'Rename'}
            primaryTypographyProps={{
              variant: 'subtitle2',
              color: 'secondary'
            }}
          />
        </MenuItem>
      </Menu>
    </>
  );
};

PaymentMethodsActionsMenu.propTypes = {
  onSelect: PropTypes.func.isRequired,
  disableRemove: PropTypes.bool,
};

export default PaymentMethodsActionsMenu;