import React from 'react'
import { Box, Typography, Button, Modal, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@securspace/securspace-ui-kit'

const SupplierBookingAdjustModal = ({
    openModal,
    handleClose,
    adjustBookingDates,
    handleDatePicker,
    endDate
}) => {

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            sx={{
                m: 2
            }}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '40em',
                width: '100%',
                backgroundColor: (theme) => theme.palette.background.paper,
                borderRadius: '0.5em',
            }}>
                <Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: 4,
                        py: 2,
                        borderBottom: (theme) => `1px solid ${theme.palette.grey[100]}`,
                    }}>
                        <Typography variant="h6" sx={{
                            fontWeight: 600
                        }}>
                            Adjust End Date
                        </Typography>
                        <IconButton
                            variant="text"
                            onClick={handleClose}
                            color="secondary">
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Box sx={{
                        p: 4
                    }}>
                        <DatePicker label='UNTIL' value={endDate} onChange={handleDatePicker} />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        px: 4,
                        py: 1,
                        bgcolor: (theme) => `${theme.palette.grey[100]}`,
                        gap: '1em'
                    }}>
                        <Button
                            variant="text"
                            sx={{
                                color: (theme) => theme.palette.error.main,
                                border: (theme) => `1px solid ${theme.palette.error.main}`,
                            }}
                            onClick={handleClose}
                            color="primary">
                            Cancel
                        </Button>
                        <Button
                            variant="text"
                            onClick={adjustBookingDates}
                            sx={{
                                color: (theme) => theme.palette.success.main,
                                border: (theme) => `1px solid ${theme.palette.success.main}`,
                            }}
                            color="primary">
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default SupplierBookingAdjustModal
