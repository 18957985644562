import request from '../../../util/SuperagentUtils'
import Request from 'superagent';

export const createUpdateAccount = (account) => {
    return Request.post('/api/account', account);
};

export const confirmTermsOfService = (accountId, account) => {
    return Request.post(`/api/account/confirm-tos/${accountId}`).send(account);
}

export const getAccountUsers = (accountId: string) => {
    return Request.get(`/api/account-users/${accountId}`);
};

export const createNewUser = (user) => {
  return Request.post('/api/account-users', user);
};

export const updateExistingUser = (user) => {
    return request.put('/api/account-users').send(user);
}

export const deleteUser = (user) => {
    return Request.delete('/api/account-users/', user);
};

export const requestCognitoTokenForUser = (
    accountId: String,
    username: String): Promise<Object> => {

    if (accountId && username) {
        return request
            .get(`/api/account/${accountId}/cognito/token`)
            .query({ email: username })
    } else {
        console.error("Attempting request for cognito token with invalid account id or username")
    }
}

export const createAccountFundingSource = (account: {
    id: string,
    newFundingSourceBankAccountType: string,
    newFundingSourceBankRoutingNumber: string,
    newFundingSourceBankAccountNumber: string,
    newFundingSourceAccountNickname?: string}
) => {
    return request.post('/api/account/funding-sources').send(account);
};

export const removeAccountFundingSource = (accountId: String, onSuccess: Function, onError: Function) => {
    return request.del(`/api/account/${accountId}/funding-sources`)
        .then((resp) => onSuccess(resp.body))
        .catch((err) => onError(err));
}

export const requestUpdateUserAccessibleLocations = (accountId: String, username: String, locationIds: Array<String>) : Promise => {
    return request
      .put(`/api/account-users/${accountId}`)
      .send({username, locationIds})
}
