import React, { useEffect, useState } from 'react'
import { withSnackbar } from "../../components/hocs/withSnackbar";
import {
    getErrorMessageForNonStandardAndStandardResponse
} from '../../util/NetworkErrorUtil';
import { Box, Container, Divider, Typography, Grid } from '@mui/material';
import BookingDetails from '../../components/bookingPayment/details/BookingDetails';
import BookingConfirmation from '../../components/bookingPayment/confirmation/BookingConfirmation';
import styles from './styles';
import { useParams } from 'react-router-dom';
import Busy from '../../components/Busy';
import useLocationHasRequestedCapacity from '../../hooks/components/location/useLocationHasRequestedCapacity';
import moment from 'moment';
import { DateFormats } from '../../components/constants/securspace-constants';
import { DAILY, MONTHLY, ONE_TIME, RECURRING } from '../../util/BookingUtil';
import { calculateBookingChargeAmount, getBookingInfo } from '../requests/booking-requests';
import { getEndDateMonthly } from '../../controls/FrequencyOption';
import { calculateNumberOfDays } from '../../util/DateUtils';

const BookingConfirmationPage = ({ account, snackbarShowMessage }) => {

    const { bookingId } = useParams();
    const [booking, setBooking] = useState(null);
    const [monthly, setMonthly] = useState(false);
    const locationHasRequestedCapacity = useLocationHasRequestedCapacity(
        booking?.location?.id,
        booking?.numberOfSpaces,
        booking?.startDate,
        moment(booking?.endDate).format(DateFormats.DAY),
        monthly ? MONTHLY : DAILY,
    );

    const [costData, setCostData] = useState({
        duration: 0,
        initialTotalCharge: 0,
        initialProcessingFee: 0,
        initialCharge: 0,
        recurringTotalCharge: 0,
        recurringProcessingFee: 0,
        recurringCharge: 0,
        firstRecurringPaymentDate: '',
        initialBookingTaxAmount: 0,
        recurringBookingTaxAmount: 0
    });

    useEffect(() => {
        if (bookingId) {
            Busy.set(true)
            getBookingInfo(bookingId)
                .then(res => {
                    setBooking(res.body)
                    setMonthly(res.body?.durationType === MONTHLY ? true : false)
                    Busy.set(false)
                }).catch((err) => {
                    Busy.set(false)
                    snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(err), 'error', 6000)
                })
        }
    }, [bookingId]);

    useEffect(() => {
        if (booking?.id) {
            const endOfMonth = moment(getEndDateMonthly(booking?.startDate, 1)).format(DateFormats.DAY);
            const calculateCostObject = {
                supplierAccountId: booking?.supplierAccount?.id,
                buyerAccountId: account.id,
                locationId: booking?.location?.id,
                numberOfSpaces: booking?.numberOfSpaces,
                startDate: moment(booking?.startDate).format(DateFormats.DAY),
                endDate: monthly ? endOfMonth : moment(booking?.endDate).format(DateFormats.DAY),
                frequency: monthly ? RECURRING : ONE_TIME,
                recurrences: monthly ? 9999 : 0,
                durationType: monthly ? MONTHLY : DAILY,
                rate: booking?.rate,
                assetType: booking?.assetType,
                paymentMethodId: !booking?.brokered ? booking?.paymentMethodId : null,
                brokeredBooking: booking?.brokered,
                chargeOverages: booking?.chargeOverages,
                brokeredInitialChargeAlreadyPaid: booking?.brokeredInitialChargeAlreadyPaidValue,
                brokeredRecurringChargeAlreadyPaid: booking?.brokeredRecurringChargeAlreadyPaidValue,
                brokeredSupplierPaidPerOccurrence: booking?.brokeredSupplierPaidPerOccurrence,
                brokeredBuyerChargedPerOccurrence: booking?.brokeredBuyerChargedPerOccurrence,
                brokeredSupplierOverageRatePaid: booking?.brokeredSupplierOverageRatePaid,
                brokeredBuyerOverageRateCharged: booking?.brokeredBuyerOverageChargedPaid,
            }
            Busy.set(true)
            calculateBookingChargeAmount(calculateCostObject)
                .then(res => {
                    const {
                        duration,
                        initialBookingPaymentAmount,
                        initialBookingPaymentProcessingFee,
                        initialBookingChargeAmount,
                        recurringBookingPaymentAmount,
                        recurringBookingPaymentProcessingFee,
                        recurringBookingChargeAmount,
                        firstRecurringPaymentDate,
                        initialBookingTaxAmount,
                        recurringBookingTaxAmount
                    } = res.body;

                    setCostData({
                        duration: monthly ? calculateNumberOfDays(calculateCostObject.startDate, calculateCostObject.endDate) : duration,
                        initialTotalCharge: initialBookingChargeAmount,
                        initialProcessingFee: initialBookingPaymentProcessingFee,
                        initialCharge: initialBookingPaymentAmount,
                        recurringTotalCharge: recurringBookingChargeAmount,
                        recurringProcessingFee: recurringBookingPaymentProcessingFee,
                        recurringCharge: recurringBookingPaymentAmount,
                        firstRecurringPaymentDate: firstRecurringPaymentDate,
                        initialBookingTaxAmount: initialBookingTaxAmount,
                        recurringBookingTaxAmount: recurringBookingTaxAmount
                    })
                    Busy.set(false)
                }).catch((err) => {
                    Busy.set(false)
                    snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(err), 'error', 6000)
                })
        }
    }, [booking]);

    return (
        <Box component='section'>
            <Container sx={styles.container}>
                <Box sx={styles.sectionPadding}>
                    <Typography color="textPrimary" variant='body2' sx={styles.heading}>
                        Booking Confirmation
                    </Typography>
                    <Divider variant='fullWidth' sx={styles.lpDivider} />
                    <Grid container spacing={4} mt={2}>
                        <Grid item xs={12} sm={7}>
                            <BookingConfirmation bookingNumber={booking?.orderNumber}
                                instantApproval={locationHasRequestedCapacity} searchHistory={'searchHistory'}
                                brokered={booking?.brokered} />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <BookingDetails
                                bookingLocation={booking?.location}
                                startDate={booking?.startDate}
                                endDate={booking?.endDate}
                                assetType={booking?.assetType}
                                numberSpaces={booking?.numberOfSpaces}
                                staticData={{ monthly }}
                                costData={costData}
                                disableBookingChanges={'disableBookingChanges'}
                                instantApproval={locationHasRequestedCapacity}
                                isBrokeredBooking={booking?.brokered}
                                assetTypelist={'assetTypeList'}
                                calculatingCostData={false}
                                setBookingIsValid={() => { }}
                                displayCalculatedCostData={true}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

export default withSnackbar(BookingConfirmationPage)
