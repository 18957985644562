import React from 'react';
import {SortFilter} from "@securspace/securspace-ui-kit";
import {Grid} from "@mui/material";

type PayoutFilterProps = {
  locations: { id: string, locationName: string }[],
  onFilterChange: (input: {
    filters: any[],
    selectFilters?: any[],
    groupBy?: string,
    sortBy: string,
    startDate?: string,
    endDate?: string,
    location: Object | undefined,
    sortDir?: string,
  }) => void
};

const PayoutFilter: (props: PayoutFilterProps) => JSX.Element = ({ locations, onFilterChange}) => {

  return (
    <Grid mb={2} item container direction={'column'} component={'section'}>
      <SortFilter
        filterCallback={onFilterChange}
        locations={locations}
        sortByOption={[
          {label: 'Invoice Number', value: 'invoiceNumber'},
          {label: 'Payout Amount', value: 'payoutAmount'},
          {label: 'Charge Type', value: 'transactionType'},
          {label: 'Service Dates', value: 'serviceStartDate'},
          {label: 'Payment Date', value: 'paymentDate'},
          {label: 'Customer', value: 'buyerCompanyName'},
          {label: 'Location', value: 'locationName'},
          {label: 'Booking Number', value: 'orderNumber'},
        ]}
       locationOption={[]}
      />
    </Grid>
  );
};

export default PayoutFilter;