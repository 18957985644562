import React, { useEffect, useState } from 'react';
import Money from 'js-money';
import InstantApprovalInfoBox from '../location/map/InstantApprovalInfoBox';
import RequestSpaceInfoBox from '../location/map/RequestSpaceInfoBox';
import { OverlayView } from '@react-google-maps/api';
import { DAYS_PER_MONTH } from '../constants/securspace-constants';

const google = window.google;

const LocationMarker = (props: { location: any, onLocationSelect: () => {} }) => {
  const { location, onLocationSelect, listHover } = props;
  const [formattedPricePerDay, setFormattedPricePerDay] = useState('');

  useEffect(() => {
    const { pricePerDay, pricePerMonth } = location;
    const currencyType = Money.USD;
    // convert to money objects if exists
    const pricePerDayMoney = pricePerDay && new Money(pricePerDay, currencyType);
    const pricePerMonthToPricePerDayMoney = pricePerMonth && (new Money(pricePerMonth, currencyType)).divide(DAYS_PER_MONTH);
    // grab price per day if it exists first, then converted monthly price if not
    const actualPricePerDay = pricePerDayMoney || pricePerMonthToPricePerDayMoney;
    if (actualPricePerDay) {
      // no actual function to do ceil with money object unfortunately, so split decimals and add one if greater than 0
      let [pricePerDayMoniesNoDecimal, pricePerDayDecimals] = actualPricePerDay.toString().split('.');
      if (pricePerDayDecimals > 0) {
        pricePerDayMoniesNoDecimal = parseInt(pricePerDayMoniesNoDecimal) + 1;
      }
      setFormattedPricePerDay(`${currencyType.symbol}${pricePerDayMoniesNoDecimal}`);
    }
  }, [location]);

  const handleLocationSelect = () => {
    onLocationSelect(location);
  }

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  })

  return (
    <OverlayView position={new google.maps.LatLng(location.addressLatitude, location.addressLongitude)}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset}>
      {
        location?.hasRequestedCapacity ?
          <InstantApprovalInfoBox listHover={location?.locationId === listHover?.locationId} formattedPrice={formattedPricePerDay} onClick={handleLocationSelect} /> :
          <RequestSpaceInfoBox listHover={location?.locationId === listHover?.locationId}  formattedPrice={formattedPricePerDay} onClick={handleLocationSelect} />
      }
    </OverlayView>
  );
}

export default LocationMarker;
