import React from 'react';
import LocationPriceInfoBoxBase from './LocationPriceInfoBoxBase';

const RequestSpaceInfoBox = (props) => {
  const {formattedPrice, onClick, listHover} = props;
  return <LocationPriceInfoBoxBase formattedPrice={formattedPrice}
                                   onClick={onClick}
                                   severity={'warning'}
                                   backgroundColor={'dark'}
                                   listHover={listHover}
  />
}

export default RequestSpaceInfoBox;
