import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import {requestRenamePaymentMethod} from "./request/payment-method-requests";
import {Theme} from "@mui/material";
import PropTypes from "prop-types";
import Busy from "../Busy";

const useStyles: (theme: Theme) => {
  rowGap10: CSSStyleSheet,
  backgroundGrey100: CSSStyleSheet,
} = makeStyles((theme: Theme) => ({
  rowGap10: {
    rowGap: '0.71rem',
  },
  backgroundGrey100: {
    backgroundColor: theme.palette.grey[100],
  },
}));

const RenamePaymentMethodDialog = ({
    open,
    onClose,
    onSuccess,
    handleLogout,
    account,
    paymentMethod,
  }) => {
  const [nickName, setNickName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const classes = useStyles();

  useEffect(() => {
    if (open && paymentMethod?.nickName) {
      setNickName(paymentMethod.nickName);
    }
  }, [open, paymentMethod]);

  const handleChange = (e) => {
    if (e.target.value.length > 30) return;
    setNickName(e.target.value);
  };

  const resetDialog = () => {
    setNickName('');
    setErrorMsg('');
  };

  const handleClose = () => {
    resetDialog();
    onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      authorityId: account.id, // Request method maps authorityId to accountId
      paymentMethodId: paymentMethod.id,
      nickName: nickName,
    };
    const handleSuccess = (response) => {
      Busy.set(false);
      resetDialog();
      onSuccess(response);
    };
    const handleError = (error) => {
      Busy.set(false);
      if (error.status === 401) {
        handleLogout();
        return;
      }
      setErrorMsg('Failed to update nickname. If this error persists, please contact support.');
    };
    Busy.set(true);
    requestRenamePaymentMethod(data, account.userType, handleSuccess, handleError);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby={'ss-rename-payment-method'}>
      <DialogTitle id={'ss-rename-payment-method'}>
        Rename Payment Method
      </DialogTitle>
      <DialogContent>
        <form id={'ss-rename-payment-method-form'} onSubmit={handleSubmit} noValidate className={'w-100'}>
          <Grid container direction={'column'} className={classes.rowGap10}>
            <TextField
              onChange={handleChange}
              name={'nickname'}
              label={'Nickname'}
              value={nickName}
              placeholder={'Enter a new nickname'}
              helperText={'Nicknames can be up to 30 characters long'}
              error={!!errorMsg}
            />
            {
              errorMsg &&
              <Typography variant="body1" component="div" color="error" align="center">{errorMsg}</Typography>
            }
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.backgroundGrey100}>
        <Button color={"primary"} onClick={handleClose}>Cancel</Button>
        <Button color={"primary"} type={"submit"} form={"ss-rename-payment-method-form"} >Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

RenamePaymentMethodDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  paymentMethod: PropTypes.object,
};

export default RenamePaymentMethodDialog;