import React, {useEffect, useState} from 'react'
import './style.css';
import { Grid } from '@mui/material';
import LoginForm from './LoginForm';
import Busy from "../../components/Busy";

const Login = () => {
  const [loaded, isLoaded] = useState(false);

  useEffect(() => {
    Busy.set(true);
    const timeout = setTimeout(() => {
      isLoaded(true);
      Busy.set(false);
    }, 100);
    return () => {
      clearTimeout(timeout);
      Busy.set(false);
    }
  }, []);

    return (
        <section>
            <div className="ss-login-container" />
            <Grid className='ss-login-section-container' container direction='column' justifyContent='center' alignItems='center'>
                <Grid item className='ss-login-content-container'>
                  {loaded && <LoginForm />}
                </Grid>
            </Grid>
        </section>
    );
};

export default Login;