import { formatDateMMDDyyyy } from "../../util/DateUtils";
import Money from "js-money";
import { PartnerSubscriptionTransactionStatusTypeLabel, PartnerSubscriptionTypeLabel } from "../constants/securspace-constants";
import { DateFormats } from "../constants/securspace-constants";
import moment from "moment";

export const formatSubscriptionItem = (subscriptionItem) => {

    const {id, type, creationDate, createdBy, reason, amount, paymentSource, active} = subscriptionItem;

    const formatAmount = () => {
        const money = new Money(amount, Money.USD, Math.round); // val originally stored in cents
        return `${Money.USD.symbol}${money.toString()}`;
    }

    return {
        id: id,
        feeType: type === "AGS_FEE" ? "AGS Fee" : type,
        amount: formatAmount(),
        createdOn: formatDateMMDDyyyy(creationDate),
        createdBy: createdBy,
        paymentSource: paymentSource,
        active: active !== null ? JSON.stringify(active) : "true",
        reason: reason,
    };
    
}

export const formatInvoiceItem = (invoiceItem) => {
    const {transactionNumber, amount, creationDate, serviceDate, status, type} = invoiceItem;

    const formatAmount = () => {
        // If actual transaction amount is 0 then DataListResultsEntryRow.js will pass '' as the value
        let sanitizedValue;
        if (amount) {
            sanitizedValue = amount;
        } else {
           sanitizedValue = invoiceItem.amount ? invoiceItem.amount : 0;
        }
        const money = new Money(sanitizedValue, Money.USD, Math.round); // val originally stored in cents
        return `${Money.USD.symbol}${money.toString()}`;
    };

    const formatStatus = () => {
        return PartnerSubscriptionTransactionStatusTypeLabel[status] ? PartnerSubscriptionTransactionStatusTypeLabel[status] : status.replaceAll('_', ' ');
    }

    const formatDate = () => {
        return serviceDate ? moment(new Date(serviceDate)).format(DateFormats.DAY) : '';
    }

    const formatCreationDate = () => {
        return creationDate ? moment(new Date(serviceDate)).format(DateFormats.MONTH_YEAR) : '';
    }

    const formatType = () => {
        return PartnerSubscriptionTypeLabel[type] ? PartnerSubscriptionTypeLabel[type] : type.replaceAll('_', ' ');
    }

    return {
        type: formatType(),
        amount: formatAmount(),
        serviceMonth: formatCreationDate(),
        invoiceNumber: transactionNumber,
        paymentDate: formatDate(),
        status: formatStatus()
    };
}

export const formatSubscriptionItemsForCSV = (subscriptionItems) => {

    return subscriptionItems.map((item) => {
        const {feeType, amount, createdOn, createdBy, paymentSource, active, reason} = item;
        
        return ({
            feeType: feeType,
            amount: amount,
            createdOn: createdOn,
            createdBy: createdBy,
            paymentSource: paymentSource,
            active: active,
            reason: reason
        });
    })
};