import React from 'react';

const Filter = (props) => {

  const { searchBox, searchChangeHandler } = props;

  return (
    <form className="report-form">
      <div className="data-list-filter-container search-container">
        <fieldset className='trigger-click hs-field'>
          <label>FILTER</label>
          <div className='ss-filter-box'>
          <input
            type="text"
            id="searchBox"
            name="searchBox"
            value={searchBox}
            onChange={searchChangeHandler}
            placeholder="Type to filter results"
          />
          <i className="fa fa-search"/>
          </div>
        </fieldset>
      </div>
    </form>
  );
}

export default Filter;