import React from 'react'
import SecurSpaceModal from "../common/SecurSpaceModal";
import SolidButton from "../form/SolidButton";
import _ from 'underscore'
import PropTypes from "prop-types";

import './style/booked-space-calendar-invoice-modal.css'

const BookedSpaceCalendarInvoiceModal = (props) => {
  const { isOpen, invoiceItems, closeModal } = props

  return (
    <SecurSpaceModal isOpen={isOpen}>
      <div className="booked-space-calendar-invoice-modal-content-container">
        <div className="booked-space-calendar-invoice-modal-header-container">
          <div className="booked-space-calendar-invoice-modal-header">Overage Invoices</div>
        </div>

        <div className="booked-space-calendar-invoice-modal-overage-items">
          {
            _.map(invoiceItems, (item, idx) => {
              return (
                <div key={idx} className="booked-space-calendar-invoice-modal-item-container">
                  <a className="booked-space-calendar-invoice-modal-item-link"
                     target="_blank"
                     rel="noopener noreferrer"
                     href={`${window.location.origin}/api/invoices-by-invoice-number/${item}`}>
                    {item}
                  </a>
                </div>
              )
            })
          }
        </div>

        <div className="booked-space-calendar-invoice-modal-footer">
          <div className="booked-space-calendar-invoice-modal-exit-btn-container">
            <SolidButton label="Close" onClick={closeModal} />
          </div>
        </div>
      </div>
    </SecurSpaceModal>
  )
}

BookedSpaceCalendarInvoiceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  invoiceItems: PropTypes.array.isRequired
}

export default BookedSpaceCalendarInvoiceModal
