import React from 'react'
import { bookingCardStyles } from './style'
import { ButtonBase, Box, Grid, Typography } from '@mui/material';

const SinglePaymentMethod = ({ img, id, cardInfo, isSelect, setIsSelect }) => {

    const classes = bookingCardStyles();

    return (
        <ButtonBase className={classes.bookCardContainer} onClick={() => setIsSelect(id)}>
            <Grid container alignItems='center' className={classes.gap}>
                <img src={img} alt="Payment Card" className={classes.cardImg} />
                <Typography className={classes.cardTitle}>{cardInfo}</Typography>
            </Grid>
            <Box className={classes.circle}>
                {isSelect && <Box className={classes.circleInner} />}
            </Box>
        </ButtonBase>
    )
}

export default SinglePaymentMethod
