import React from 'react';
import '../../css/components/report/report-row.css';

const ReportRow = (props) => {
  const { children } = props;

  return (
    <div className="ss-report-row">
      <div>
        {children}
      </div>
    </div>
  )
}

export default ReportRow;
