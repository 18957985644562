import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import './style.css'

const useStyles: { paper: CSSStyleSheet } = makeStyles({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '.42857em .7857em',
    margin: '0 2em 0 2em',

    height: '3.14em',
    background: '#FBFBFF',
    borderRadius: '.5714em',
    '&:hover': {
      background: '#3F51B5',
      color: 'white',
    },

    /* Inside auto layout */
    flex: 'none',
    order: 1,
    flexGrow: 0,
  },
  selected: {
    background: '#3F51B5',
    color: 'white',
  },
})

const PlacesAutoCompleteMuiItem = (props) => {
  const { pacItem, onClick, selected } = props;
  const classes = useStyles();

  return <Grid item>
    <Paper className={classNames(classes.paper, selected ? classes.selected : '')}
      elevation={0}
      onClick={() => onClick(pacItem)}
    >
      <Grid container
        direction={'row'}
        alignItems={'center'}
        alignContent={'center'}
        justifyContent={'space-between'}
        wrap={'nowrap'}
      >
        <div className='ss-place-input'>
          <div className='ss-place-input-wrapper'>
            <Grid item>
              <Typography variant={'subtitle2'}>{pacItem?.structured_formatting?.main_text}</Typography>
            </Grid>
            <Grid item>
              <Typography variant={'caption'}>{pacItem?.structured_formatting?.secondary_text}</Typography>
            </Grid>
          </div>
        </div>
        {
          selected && <Grid
            item
            container
            xs
            wrap={'nowrap'}
            justifyContent={'flex-end'}
            alignContent={'center'}
            alignItems={'center'}
            sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' }}}
          >
            <Grid item>
              <Typography variant={'overline'}>CLICK OR HIT ENTER TO SEARCH</Typography>
            </Grid>
            <Grid item className={'ss-align-self-center'} ml={'0.43em'}>
              <KeyboardReturnIcon fontSize={'small'} />
            </Grid>
          </Grid>
        }
      </Grid>
    </Paper>
  </Grid>
}

export default PlacesAutoCompleteMuiItem;
