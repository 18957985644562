import { Grid, Typography } from '@mui/material'
import React from 'react'

const ValidationMessage = ({
    source,
    disabled =  false,
    title
}) => {
    return (
        <>
            <Grid container alignItems='center' className='ss-change-flex-container'>
                <img src={source} alt="Icons" className='ss-change-password-validation-icon' />
                <Typography className={disabled ? `ss-change-password-validation-title disabled`: `ss-change-password-validation-title`}>{title}</Typography>
            </Grid>
        </>
    )
}

export default ValidationMessage