import React from "react";
import {Grid, Card, Typography} from "@mui/material";
import { makeStyles } from '@mui/styles';
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    blueCard: {
      backgroundColor: 'rgba(216, 248, 255, 1)',
      marginTop: '1.5em'
    },
    bottomLink: {
        color: theme.palette.secondary.main,
        marginBottom: '2em',
        fontSize: '0.75rem',
        fontWeight: '500',
        ...theme.components.returnToText,
        '@media(max-width: 39.28em)': {
            marginBottom: '0.5em',
        }
    },
  }));

const BookingConfirmation = (props) => {
    const {bookingNumber, instantApproval, brokered} = props;

    const localClasses = useStyles();

    const instantApprovalMessage = "This order has been approved and your payment is processing. Please check the My Bookings page for details about your booking.";
    const reserveSpaceMessage = "This order has been submitted for approval. Please check the My Bookings page for the status of this order. You will not be charged until the order is approved"

    const shouldShowBookingNumber = (brokered || instantApproval) && bookingNumber;

    return <Grid item>
        <Grid className="ss-byb-content">
            <Typography variant={'h5'} color={'textPrimary'}>Thanks for your order</Typography>
            <Card className={`ss-byb-card ${localClasses.blueCard}`}>
                <Typography variant="h6">{ shouldShowBookingNumber ? `Booking #${bookingNumber}`: 'Confirmation' }</Typography>
                <Typography variant="body2">{ instantApproval ? instantApprovalMessage : reserveSpaceMessage }</Typography>
            </Card>
        </Grid>
        <Link to="/my-bookings" className={localClasses.bottomLink}>VIEW MY BOOKINGS</Link>
    </Grid>
}

export default BookingConfirmation;
