import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Grid } from "@mui/material";

const CancelBookingModal = ({open, onClose, cancelBooking, booking}) => {

    return (<Dialog open={open} onClose={onClose}>
        <DialogTitle>Cancel Booking {booking.orderNumber}</DialogTitle>
        <DialogContent>
            <Grid container>
                <Grid item>
                    <Typography>Are you sure you want to cancel this booking request?</Typography>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button color={"primary"} onClick={onClose}>Back</Button>
            <Button color={"primary"} onClick={cancelBooking}>Confirm</Button>
        </DialogActions>
    </Dialog>)
}

export default CancelBookingModal;