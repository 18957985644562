import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import HubSpotForm from './HubSpotForm';

const useStyles: () => {
    noContentContainer: CSSStyleSheet,
} = makeStyles(() => ({
    noContentContainer: {
        padding: '1rem 2.17rem',
    }
}));

const LocationSearchNoContent = () => {
    const classes = useStyles();

    return (
        <section className={classes.noContentContainer}>
            <Typography variant='h4'>Secure Storage & Parking</Typography>
            <Typography variant='body2'>Fill out the form below to request spaces in your location.</Typography>
            <HubSpotForm
                region="na1"
                portalId="45201498"
                formId="f12c73c9-9a91-4d28-baf2-e36557a6ed7e"
            />
        </section>
    );
};

export default LocationSearchNoContent;
