import {Container, Tab, Tabs} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {TabContext, TabPanel} from "@mui/lab";
import CompanyProfile from "../../components/companyProfile/CompanyProfile";
import Notifications from "../../components/notifications/Notifications";
import UserManagement from "../../components/user/manageUsers/UserManagement";
import PaymentMethods from "../../components/paymentMethods/PaymentMethods";
import SubscriptionManager from "../../components/subscriptions/SubscriptionManager";
import {Theme} from "@mui/material";
import { AccountType, UserType } from "../../components/constants/securspace-constants";

const useStyles: (theme: Theme) => {
  mainContainer: CSSStyleSheet,
  borderBottomGrey200: CSSStyleSheet
} = makeStyles(theme => ({
  mainContainer: {
    paddingTop: '2.5rem',
    paddingBottom: '2.86rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '8.04rem',
      paddingRight: '8.04rem',
    }
  },
  borderBottomGrey200: {
    borderBottom: `${theme.palette.grey[200]} solid 0.07rem`,
    '& .MuiButtonBase-root.MuiTab-root ':{
      fontSize:'1em'
    }
  }
}));

const AccountOverview = ({ initialTab = 'company', account, handleLogout, handleAccountUpdated }) => {
  const [currentTab, setCurrentTab] = useState(initialTab);
  const classes = useStyles();

  const handleTabChange = (_event, value) => {
    setCurrentTab(value);
  }
  return (
    <Container className={classes.mainContainer}>
      <TabContext value={currentTab + ''}>
        <Tabs
          variant={'fullWidth'}
          value={currentTab}
          onChange={handleTabChange}
          aria-label={'Account Tabs'}
          className={classes.borderBottomGrey200}
          textColor={'secondary'}
        >
          <Tab value={'company'} label='Company'/>
          <Tab value={'payment'} label={account.type === AccountType.SUPPLIER ? 'Payout Bank Account' : 'Payment Methods'}/>
          { account.userType === UserType.ADMIN && account.type === AccountType.SUPPLIER &&
            <Tab value={'subscriptions'} label='Subscriptions'/>
          }
          <Tab value={'users'} label='Users'/>
          <Tab value={'notifications'} label='Notifications'/>
        </Tabs>
        <TabPanel value={'company'}>
          <CompanyProfile account={account} handleLogout={handleLogout} handleAccountUpdated={handleAccountUpdated}/>
        </TabPanel>
        <TabPanel value={'payment'}>
          <PaymentMethods account={account} handleLogout={handleLogout} handleAccountUpdated={handleAccountUpdated}/>
        </TabPanel>
        <TabPanel value={'subscriptions'}>
          <SubscriptionManager account={account} handleLogout={handleLogout} handleAccountUpdated={handleAccountUpdated}/>
        </TabPanel>
        <TabPanel value={'users'}>
          <UserManagement account={account} handleLogout={handleLogout} />
        </TabPanel>
        <TabPanel value={'notifications'}>
          <Notifications account={account} handleLogout={handleLogout} />
        </TabPanel>
      </TabContext>
    </Container>
  );
};

AccountOverview.propTypes = {
  initialTab: PropTypes.string,
  account: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
  handleAccountUpdated: PropTypes.func.isRequired,
};

export default AccountOverview;
