import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  rowFont: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '0.93em',
    lineHeight: '169%',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
  },
  fontWeight400: {
    fontWeight: 400,
  },
  fontWeight500: {
    fontWeight: 500,
  },
}));

const BookSpaceInfoPanelContent = ({ data }) => {

  const classes = useStyles();
  return (
    <>
      {
        data.map(({ label, value }, index) => {
          return (
            <Grid key={index} item container justifyContent='space-between'>
              <Typography className={classNames(classes.rowFont, classes.fontWeight400)}>{label}:</Typography>
              <Typography className={classNames(classes.rowFont, classes.fontWeight500)}>{value}</Typography>
            </Grid>
          )
        })
      }
    </>
  );
};

BookSpaceInfoPanelContent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.any,
    value: PropTypes.any,
  })),
};

export default BookSpaceInfoPanelContent;