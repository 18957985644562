import React, { useContext, useState } from 'react'
import { NoDataMessage, BookingCard } from "@securspace/securspace-ui-kit";
import { Grid } from "@mui/material";
import { toast } from 'react-toastify'
import useStyles from './styles';
import { bookingStatus } from './request/booking-approvals-requests';
import Busy from '../Busy';
import { getErrorMessageForStandardResponse } from '../../util/NetworkErrorUtil';
import { transformBooking } from '../../views/supplier-bookings-approvals/SupplierBookingApprovals';
import SupplierBookingAdjustModal from './SupplierBookingAdjustModal';
import { adjustEndDate } from '../../views/requests/booking-requests';
import moment from 'moment';
import { DateFormats } from '../constants/securspace-constants';
import { AppContext } from '../../context/app-context';
import { readPendingBooking } from '../../routing/SecurSpaceContent';

export const headerCells = [
    { id: 'bookedDates', label: 'BOOKED DATES' },
    { id: 'brokered', label: 'Brokered' },
    { id: 'orderNumber', label: 'BOOKING NUMBER' },
    { id: 'status', label: 'STATUS' },
    { id: 'recurringCharge', label: 'MONTHLY CHARGE' },
    { id: 'overageRate', label: 'Overage Rate' },
    { id: 'locationName', label: 'LOCATION' },
    { id: 'frequencyValue', label: 'FREQUENCY' },
    { id: 'assetType', label: 'EQUIPMENT TYPE' },
    { id: 'numberOfSpaces', label: 'SPACES BOOKED' },
    { id: 'initialCharge', label: 'INITIAL CHARGE' },
    { id: 'rateValue', label: 'Rate' },
];

const SupplierBookingList = ({
    bookingLists,
    search,
    snackbarShowMessage,
    setBookingLists
}) => {

    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [endDate, setEndDate] = useState('');
    const { updatePendingApprovalCount, user } = useContext(AppContext)

    function updateBookingStatus(status, booking, message) {
        Busy.set(true);
        const params = {
            status,
            body: {
                id: booking.id
            }
        }
        bookingStatus(params)
            .then((res) => {
                const updatedBooking = res.body;
                const updateData = bookingLists.map(booking => {
                    if (booking.id === updatedBooking.id) {
                        return transformBooking(updatedBooking);
                    } else {
                        return transformBooking(booking);
                    }
                });
                setBookingLists(updateData);
                Busy.set(false)
                readPendingBooking(user, updatePendingApprovalCount)
                toast.success(message)
            })
            .catch((err) => {
                Busy.set(false)
                snackbarShowMessage(getErrorMessageForStandardResponse(err), "error", 15000)
            })
    }

    function approveBooking(booking) {
        updateBookingStatus('approve', booking, `Successfully Approved Booking ${booking.orderNumber}`)
    }

    function declineBooking(booking) {
        updateBookingStatus('decline', booking, `Successfully Declined Booking ${booking.orderNumber}`)
    }

    function handleClose() {
        setOpenModal(!openModal)
    }

    function handleDatePicker(date) {
        setEndDate(moment(date).format(DateFormats.DAY))
    }

    function adjustBookingDates() {
        Busy.set(true);
        const data = {
            bookingId: selectedBooking.id,
            endDate
        };
        adjustEndDate(data).then((res) => {
            Busy.set(false);
            const updatedBooking = res.body;
            const updateData = bookingLists.map(booking => {
                if (booking.id === updatedBooking.id) {
                    return transformBooking(updatedBooking);
                } else {
                    return transformBooking(booking);
                }
            })
            setBookingLists(updateData);
            toast.success('Successfully adjusted dates on booking!');
            handleClose();
        }).catch((error) => {
            Busy.set(false);
            toast.error(getErrorMessageForStandardResponse(error));
            handleClose();
        }
        )
    }



    return (
        <Grid item mt={4} container flexDirection={'column'} className={!bookingLists.length && classes.container}>
            {!bookingLists.length ? (
                <Grid item className={classes.showMessage}>
                    {
                        search ? (
                            <NoDataMessage
                                image="/app-images/person-and-box.svg"
                                message="No matching records found."
                            />
                        ) : (
                            <NoDataMessage
                                image="/app-images/no-data-match.png"
                                message="No input selected, To view results kindly select the data above"
                            />
                        )
                    }
                </Grid>
            ) : (
                bookingLists.map((booking) => {

                    const buttons = [];
                    if (['Approved', 'Active', 'Complete'].includes(booking.status) && booking.frequency !== 'RECURRING') {
                        buttons.push(
                            {
                                label: 'Adjust End Date',
                                onClick: (bookingData) => {
                                    setSelectedBooking(bookingData);
                                    handleDatePicker(bookingData.endDate);
                                    setOpenModal(!openModal)
                                },
                                variant: "text",
                                styles: {
                                    color: (theme) => theme.palette.success.main,
                                    border: (theme) => `1px solid ${theme.palette.success.main}`,
                                }
                            }
                        );
                    }
                    else if (booking.status === 'Pending') {
                        buttons.push(
                            {
                                label: 'Approve',
                                onClick: approveBooking,
                                variant: "text",
                                styles: {
                                    color: (theme) => theme.palette.success.main,
                                    border: (theme) => `1px solid ${theme.palette.success.main}`,
                                }
                            },
                            {
                                label: 'Decline',
                                onClick: declineBooking,
                                variant: "text",
                                styles: {
                                    color: (theme) => theme.palette.error.main,
                                    border: (theme) => `1px solid ${theme.palette.error.main}`,
                                }
                            },
                        )
                    } else if (booking.status === 'Incomplete') {
                        buttons.push(
                            {
                                label: 'Decline',
                                onClick: declineBooking,
                                variant: "text",
                                styles: {
                                    color: (theme) => theme.palette.error.main,
                                    border: (theme) => `1px solid ${theme.palette.error.main}`,
                                }
                            }
                        )
                    }

                    return (
                        <BookingCard
                            headCells={headerCells}
                            data={booking}
                            bookingType='supplier'
                            hasAction={false}
                            isStatusShow={false}
                            rootStyle={classes.bookingCard}
                            buttons={buttons}
                            key={booking.id}
                            bookingTitle={booking.customerName}
                        />
                    )
                })
            )}
            <SupplierBookingAdjustModal
                openModal={openModal}
                adjustBookingDates={adjustBookingDates}
                handleClose={handleClose}
                endDate={endDate}
                handleDatePicker={handleDatePicker}
            />
        </Grid>
    )
}

export default SupplierBookingList
