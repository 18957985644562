import React from "react";
import { Grid, Typography } from "@mui/material";
import BookingCostBreakdown from "./BookingCostBreakdown";
import BookingInfo from "./BookingInfo";
import {Theme} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import {DateFormats} from "../../constants/securspace-constants";

const useStyles: (theme: Theme) => {
  title: CSSStyleSheet
} = makeStyles(() => ({
  title: {
    marginBottom: '0.71em',
  }
}));

const BookingDetails = (props) => {

  const {
    bookingLocation,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    assetType,
    setAssetType,
    numberSpaces,
    setNumberSpaces,
    staticData,
    costData,
    disableBookingChanges,
    instantApproval,
    isBrokeredBooking,
    assetTypelist,
    calculatingCostData,
    setBookingIsValid,
    displayCalculatedCostData,
  } = props;
  const classes = useStyles();

  const handleStartDateChange = (value: string | moment) => {
    setStartDate(moment(value).isValid() ? moment(value).format(DateFormats.DAY) : value);
  };

  const handleEndDateChange = (value: string | moment) => {
    setEndDate(moment(value).isValid() ? moment(value).format(DateFormats.DAY) : value);
  };

  return <Grid item>
    <Typography variant={'h5'} color={'textPrimary'} className={classes.title}>Details</Typography>
    <BookingInfo
      instantApproval={instantApproval}
      bookingLocation={bookingLocation}
      startDate={startDate}
      endDate={endDate}
      setStartDate={handleStartDateChange}
      setEndDate={handleEndDateChange}
      assetType={assetType}
      setAssetType={setAssetType}
      staticData={staticData}
      numberSpaces={numberSpaces}
      setNumberSpaces={setNumberSpaces}
      disableDatePickerPast={!isBrokeredBooking}
      isBrokeredBooking={isBrokeredBooking}
      disableBookingChanges={disableBookingChanges}
      assetTypelist={assetTypelist}
      setBookingIsValid={setBookingIsValid}
    />
    <BookingCostBreakdown
      isBrokered={isBrokeredBooking}
      monthly={staticData.monthly}
      startDate={startDate}
      costData={costData}
      calculatingCostData={calculatingCostData}
      displayCalculatedCostData={displayCalculatedCostData}
    />
  </Grid>
}

export default BookingDetails;
