import { LandingNav, LandingNavHome, AccountNav, SupplierNav } from "./navs";
import {UserType, AccountType} from "../../components/constants/securspace-constants";

export const LEFT_NAV = [
    LandingNav.LIST_YOUR_LOCATION,
    SupplierNav.GMS_LANDING,
    LandingNav.HOW_IT_WORKS,
]
export const LEFT_NAV_HOME = [
    LandingNavHome.LIST_YOUR_LOCATION,
    SupplierNav.GMS_LANDING,
]
export const LEFT_NAV_LOGIN = [
    LandingNav.LIST_YOUR_LOCATION,
    SupplierNav.GMS_LANDING,
]

export const RIGHT_NAV = [
    {
        path: 'register', label: 'Register', submenuWidth: 236,
        submenus: [
            LandingNav.REGISTER_AS_CUSTOMER,
            LandingNav.REGISTER_AS_PARTNER,
        ]
    },
    LandingNav.LOG_IN,
]

export const LOGGED_CUSTOMER_ACCOUNT = [
    {
        path: 'account', label: 'Account', submenuWidth: 152,
        submenus: [
            AccountNav.COMPANY_PROFILE,
            AccountNav.USER_MANAGEMENT,
            AccountNav.NOTIFICATIONS_SETTINGS,
            AccountNav.SIGN_OUT
        ]
    }
]

export const LOGGED_PARTNER_ACCOUNT = [
    {
        path: 'account', label: 'Account', submenuWidth: 152,
        submenus: [
            AccountNav.COMPANY_PROFILE,
            AccountNav.LOCATIONS_PROFILE,
            AccountNav.USER_MANAGEMENT,
            AccountNav.NOTIFICATIONS_SETTINGS,
            AccountNav.SIGN_OUT
        ]
    }
]

export const LOGGED_IN_LOWER_USER = [
    {
        path: 'account', label: 'Account', submenuWidth: 152,
        submenus: [
            AccountNav.SIGN_OUT
        ]
    }
]

export const determineRightNav = (user) => {
    const adminOrOwner = [UserType.ADMIN, UserType.OWNER, UserType.PRIMARY_OWNER];
    const lowerAuthUser = [UserType.DISPATCHER, UserType.GATE_CLERK, UserType.GATE_MANAGER];

    if (user && adminOrOwner.includes(user.userType)) {
        if (user?.type === AccountType.SUPPLIER) {
            return LOGGED_PARTNER_ACCOUNT
        } else if (user?.type === AccountType.BUYER) {
            return LOGGED_CUSTOMER_ACCOUNT
        } 
    } else if (user && lowerAuthUser.includes(user.userType)) {
        return LOGGED_IN_LOWER_USER
    }
    else {
        return RIGHT_NAV;
    }
}
