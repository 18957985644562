import moment from "moment";
import request from "../../../../util/SuperagentUtils";
import { DateFormats } from "../../../constants/securspace-constants";


const getSupplierInvoices = (
    searchParams,
    onSuccess,
    onFail
) => {

    const { accountId, page, size, startDate, endDate, locationId, bookingNumber, invoiceNumber, transactionType, status, sortBy, sortDir } = searchParams;

    let pageParam = ["page", page || 0];
    let sizeParam = ["size", size || 10];

    let params = new URLSearchParams([pageParam, sizeParam]);

    if (startDate) {
        params.append("startDate", moment(startDate).format(DateFormats.DAY));
    }
    if (endDate) {
        params.append("endDate", moment(endDate).format(DateFormats.DAY));
    }
    if (locationId) {
        params.append("locationId", locationId)
    }
    if (bookingNumber) {
        params.append("bookingNumber", bookingNumber)
    }
    if (invoiceNumber) {
        params.append("transactionNumber", invoiceNumber)
    }
    if (transactionType) {
        params.append("transactionType", transactionType)
    }
    if (status) {
        params.append("status", status)
    }
    if (sortBy) {
        params.append("sortBy", sortBy)
    }
    if (sortDir) {
        params.append("sortDir", sortDir)
    }


    const result = request.get(`/api/invoices/suppliers/${accountId}?${params}`);

    if (onSuccess && onFail) {
        result
            .then(onSuccess)
            .catch(onFail)
    } else {
        return result
    }
}

export {
    getSupplierInvoices
}