import React from 'react'
import { Box, Typography, Button, Modal, Grid } from "@mui/material";
import {ClassNameMap} from "@mui/styles";
import {
    inventoryCorrectionReasons,
    InventoryCorrectionReasonLabels,
} from '../constants/securspace-constants';
import CorrectionComparison from './CorrectionComparison';
import {getDates} from "../../views/inventory-correction/util";

const ReviewModal = (props: {
    inventoryCorrection: {},
    handleClose: () => void,
    classes: ClassNameMap,
    showNewBookingNumberForm: () => {},
    handleAccept: () => void,
    handleReject: () => void,
    openModal: boolean,
}) => {
    const {
        inventoryCorrection,
        handleClose,
        classes,
        showNewBookingNumberForm,
        handleAccept,
        handleReject,
        openModal,
        isSupplier
    } = props

    const reason = inventoryCorrection?.reason;

    const buyerCorrectionMessage = reason ? InventoryCorrectionReasonLabels.find(item => item.value === reason).label : '';
    const reasonRejected = inventoryCorrection?.status === 'REJECTED' ? inventoryCorrection?.reason : '';
    const buttonText = isSupplier ? 'Cancel' : 'Close';

    const handleSelectAccept = () => {
        if(reason === inventoryCorrectionReasons.ASSET_DOES_NOT_BELONG_TO_BUYER) {
            showNewBookingNumberForm()
        } else {
            handleAccept()
        }
    }

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            sx={{
                m:2
            }}
        >
            <Box className={classes.reviewModal}>
                <Box sx={{ p: 4 }}>
                    <Typography variant="h6">Review Inventory Correction</Typography>
                    <Grid container flexDirection="column" rowSpacing={2} marginTop={"1em"}>
                        <Grid item>
                            <Typography className={classes.correctionReason}>Status: {inventoryCorrection?.status}</Typography>
                        </Grid>
                        {!isSupplier && reasonRejected !== '' &&
                          <Grid item>
                              <Typography className={classes.correctionReason}>Reason: {reasonRejected}</Typography>
                          </Grid>
                        }
                        <Grid item>
                            <Typography>Buyer's Response:  </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.correctionReason}>{buyerCorrectionMessage}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>Notes:</Typography>
                            <Typography className={classes.correctionReason}>{inventoryCorrection?.notes}</Typography>
                        </Grid>
                        <CorrectionComparison
                            inventoryCorrection={inventoryCorrection}
                            getDates={() => getDates(inventoryCorrection)}
                            classes={classes}
                        />
                    </Grid>
                </Box>
                <Box className={classes.btnGroup}>
                    <Button
                        variant="text"
                        onClick={handleClose}
                        color="secondary">
                        {buttonText}
                    </Button>
                    {isSupplier &&
                        <Grid item container justifyContent="flex-end" columnGap={1.5}>
                            <Button onClick={handleSelectAccept} variant='outlined'>Accept</Button>
                            <Button onClick={handleReject} variant='outlined'>Reject</Button>
                        </Grid>
                    }
                </Box>
            </Box>
        </Modal>
    )
}

export default ReviewModal;
