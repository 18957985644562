import React from 'react';
import {NoDataMessage, Table} from "@securspace/securspace-ui-kit";
import {Box, Button, Grid} from "@mui/material";

const supplierHeaderCells = [
  {
    id: 'orderNumber',
    label: 'Booking #'
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'buyerName',
    label: 'Buyer Name'
  },
  {
    id: 'reason',
    label: 'Buyer Response'
  },
  {
    id: 'dateOfSubmission',
    label: 'Date Of Submission'
  }
];

const buyerHeaderCells = [
  {
    id: 'orderNumber',
    label: 'Booking #'
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'supplierName',
    label: 'Supplier Name'
  },
  {
    id: 'locationName',
    label: 'Location Name'
  },
  {
    id: 'rejectionReason',
    label: 'Rejection Reason'
  },
  {
    id: 'dateOfSubmission',
    label: 'Date Of Submission'
  }
];

const InventoryCorrectionsTable = (props: {
  inventoryCorrections: any[],
  handleReviewItem: (item: any) => void,
  isSupplier: boolean
}) => {
  const {inventoryCorrections, handleReviewItem, isSupplier} = props;
  const buttonText = isSupplier ? 'Review' : 'View';

  const actionButtons = (item) => {
    return <Grid container sx={{ fontSize:'1em' }}>
      <Button sx={{ fontSize:'1em' }} onClick={() => handleReviewItem(item)} variant="outlined">{buttonText}</Button>
    </Grid>
  }

  return (
    <Box mt={4} sx={{
      ...(inventoryCorrections.length === 0) && {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
      }
    }}>
      {inventoryCorrections.length === 0 ?
        <NoDataMessage
          image="https://s3-us-west-1.amazonaws.com/securspace-files/listing-images/a80edbe2-b50c-4332-a711-a75ceed6bef9_Artwork.png"
          message={'You have no inventory corrections yet.'}
          textStyle={''}
        /> : <Table
          headCells={isSupplier ? supplierHeaderCells : buyerHeaderCells}
          rows={inventoryCorrections}
          hasRowAction={true}
          actionType='button'
          actionButtons={actionButtons}
        />}
    </Box>
  )
};

export default InventoryCorrectionsTable;
