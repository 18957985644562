import React from 'react';
import {InfoCardLoader} from "@securspace/securspace-ui-kit";

type InfoCardLoadingSkeletonProps = {
  cards?: number
};

/**
 * Renders a list of loading InfoCardLoaders.
 *
 * @param cards The number of cards to render.
 * @returns InfoCardLoader[]
 * @constructor
 */
const InfoCardLoadingSkeleton: (props: InfoCardLoadingSkeletonProps) => JSX.Element = ({cards = 10}) => {
  return [...Array(cards).keys()].map((_, index) => (
    <InfoCardLoader key={index} />)
  );
};

export default InfoCardLoadingSkeleton;