export const InventoryCorrectionReasons = Object.freeze({
  ASSET_DOES_NOT_BELONG_TO_BUYER: "ASSET_DOES_NOT_BELONG_TO_BUYER",
  ASSET_CHECKED_OUT_ON_WRONG_DATE: "ASSET_CHECKED_OUT_ON_WRONG_DATE",
  ASSET_NUMBER_ENTERED_INCORRECTLY: "ASSET_NUMBER_ENTERED_INCORRECTLY",
  ASSET_LEFT_THE_YARD: "ASSET_LEFT_THE_YARD",
});

export const InventoryCorrectionStatus: Readonly<"PENDING" | "APPROVED" | "REJECTED" | "CANCELLED"> = Object.freeze({
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  CANCELLED: "CANCELLED",
});

export const InventoryCorrectionReasonLabel = Object.freeze({
  [InventoryCorrectionReasons.ASSET_DOES_NOT_BELONG_TO_BUYER]: "Asset does not belong to me",
  [InventoryCorrectionReasons.ASSET_CHECKED_OUT_ON_WRONG_DATE]: "Asset was checked out on the wrong date",
  [InventoryCorrectionReasons.ASSET_NUMBER_ENTERED_INCORRECTLY]: "Asset number was entered incorrectly",
  [InventoryCorrectionReasons.ASSET_LEFT_THE_YARD]: "Asset left the yard",
});
