import React, { useContext } from 'react'
import { Route } from "react-router"
import { withAccessControl } from "../ProtectedRoute";
import { UserType, AccountType, SubscriptionType } from "../../components/constants/securspace-constants";
import AccessControlBuilder from "../AccessControlBuilder";

import Approvals from "../../views/Approvals";
import SupplierBookingsReport from "../../views/SupplierBookingsReport";
import SupplierInvoicesReport from "../../views/SupplierInvoicesReport";
import RedesignSupplierInvoicesReport from "../../views/invoices/SupplierInvoicesReport";
import SupplierReadyForPayoutReport from "../../views/SupplierReadyForPayoutReport";
import SupplierPayoutsReportLegacy from "../../views/SupplierPayoutsReportLegacy";
import SupplierPayoutsReport from "../../views/payouts/SupplierPayoutsReport";
import SupplierBookedSpaceCalendarReport from "../../views/SupplierBookedSpaceCalendarReport";
import GmsUpgrade from "../../components/gms/GmsUpgrade";
import { AppContext } from "../../context/app-context";
import ChargeServiceFee from "../../views/servicefees/ChargeServiceFee";
import PartnerSubscriptions from '../../views/PartnerSubscriptions';
import PartnerSubscriptionInvoicesReport from '../../views/PartnerSubscriptionInvoicesReport';
import PartnerSubscriptionsLegacy from '../../views/PartnerSubscriptionLegacy';
import PartnerSubscriptionInvoicesReportLegacy from '../../views/PartnerSubscriptionInvoicesReportLegacy';
import {Navigate} from "react-router-dom";
import {useFlags} from "launchdarkly-react-client-sdk";
import SupplierSpaceUsage from "../../views/SupplierSpaceUsage";
import SupplierInventoryCorrections from '../../views/inventory-correction/SupplierInventoryCorrections';
import SupplierBooking from '../../views/supplier-booking/SupplierBooking';
import SupplierBookingApprovals from '../../views/supplier-bookings-approvals/SupplierBookingApprovals';
import SupplierReadyForPayout from "../../views/SupplierReadyForPayout/SupplierReadyForPayout";
import InvoicesInPayoutReport from "../../views/supplier-payout/InvoicesInPayoutReport";

const allSupplierOwnerAuthorization = new AccessControlBuilder().allowAllSupplierOwner().build()
const gmsProGateManagersAuthorization = new AccessControlBuilder().allowGmsProGateManagers().build()
const gateManagerAuthorization = new AccessControlBuilder().allowGateManager().build()
const gmsProOwnerAuthorization = new AccessControlBuilder().allowGmsProOwner().build()

const canGateManagersViewSupplierBookings = (user: { allowsGateManagersToViewBookings: boolean }) => {
    return user && user.allowsGateManagersToViewBookings
}

const modifiedGateManagerAuthorization = {
    ...gateManagerAuthorization,
    additionalValidation: canGateManagersViewSupplierBookings
}

const gmsUpgradeAuthorization = new AccessControlBuilder()
    .allowAccountType(AccountType.SUPPLIER)
    .allowUserType(UserType.GATE_CLERK)
    .allowUserType(UserType.GATE_MANAGER)
    .allowSubscriptionType(SubscriptionType.MARKETPLACE_ONLY)
    .build()

const ProtectedApprovals = withAccessControl(Approvals, allSupplierOwnerAuthorization, gateManagerAuthorization)
const ProtectedSupplierBookingsReport = withAccessControl(SupplierBookingsReport, allSupplierOwnerAuthorization, modifiedGateManagerAuthorization)
const ProtectedRedesignSupplierBooking = withAccessControl(SupplierBooking, allSupplierOwnerAuthorization, modifiedGateManagerAuthorization)
const ProtectedSupplierInvoicesReport = withAccessControl(SupplierInvoicesReport, allSupplierOwnerAuthorization)
const ProtectedRedesignSupplierInvoicesReport = withAccessControl(RedesignSupplierInvoicesReport, allSupplierOwnerAuthorization)
const ProtectedSupplierReadyForPayoutReport = withAccessControl(SupplierReadyForPayoutReport, allSupplierOwnerAuthorization)
const ProtectedSupplierPayoutsReportLegacy = withAccessControl(SupplierPayoutsReportLegacy, allSupplierOwnerAuthorization)
const ProtectedSupplierPayoutsReport = withAccessControl(SupplierPayoutsReport, allSupplierOwnerAuthorization)
const ProtectedPartnerSubscriptionsReportLegacy = withAccessControl(PartnerSubscriptionsLegacy, gmsProOwnerAuthorization);
const ProtectedPartnerSubscriptionsReport = withAccessControl(PartnerSubscriptions, gmsProOwnerAuthorization);
const ProtectedPartnerSubscriptionsInvoicesLegacyReport = withAccessControl(PartnerSubscriptionInvoicesReportLegacy, gmsProOwnerAuthorization);
const ProtectedPartnerSubscriptionsInvoicesReport = withAccessControl(PartnerSubscriptionInvoicesReport, gmsProOwnerAuthorization);
const ProtectedSupplierBookedSpaceCalendarReport = withAccessControl(SupplierBookedSpaceCalendarReport, gmsProOwnerAuthorization)
const ProtectedSupplierInventoryCorrections = withAccessControl(SupplierInventoryCorrections, gmsProGateManagersAuthorization);
const ProtectedGmsUpgrade = withAccessControl(GmsUpgrade, gmsUpgradeAuthorization)
const ProtectedChargeServiceFee = withAccessControl(ChargeServiceFee, allSupplierOwnerAuthorization)
const ProtectedRedesignSupplierSpaceUsage = withAccessControl(SupplierSpaceUsage, gmsProOwnerAuthorization);
const ProtectedRedesignSupplierBookingApprovals = withAccessControl(SupplierBookingApprovals, allSupplierOwnerAuthorization, gateManagerAuthorization);
const ProtectedRedesignSupplierReadyForPayout = withAccessControl(SupplierReadyForPayout, allSupplierOwnerAuthorization);
const ProtectedInvoicesInPayout = withAccessControl(InvoicesInPayoutReport, allSupplierOwnerAuthorization);

const SupplierContent = (readSupplierPendingBooking) => {

    const appContext = useContext(AppContext);
    const { user, logout } = appContext;
    const {
        bookingApprovalRedesign,
        inventoryCorrection,
        partnerSubscriptionRedesign,
        supplierBookingsNavRedesign,
        supplierInvoicesRedesign,
        supplierReadyForPayoutRedesign,
        supplierSpaceUsageRedesign
    } = useFlags();

    return [
        <Route path={"/approvals"} key={"/approvals"} element={
            bookingApprovalRedesign ?  <Navigate to={'/bookings/approvals'} /> :
            <ProtectedApprovals account={user} handleLogout={logout} readPendingBooking={readSupplierPendingBooking} />
        }/>,
        <Route path={"/supplier-bookings"} key={"/supplier-bookings"} element={
            supplierBookingsNavRedesign ? <Navigate to={'/bookings/supplier-bookings'} /> :
            <ProtectedSupplierBookingsReport account={user} handleLogout={logout} />
        }/>,
        <Route exact path={"/supplier-payouts"} key={"/supplier-payouts"} element={
            supplierReadyForPayoutRedesign ? <Navigate to={"/payouts/supplier-payouts"}/> :
            <ProtectedSupplierPayoutsReportLegacy account={user} handleLogout={logout} />
        }/>,
        <Route exact path={"/subscriptions"} key={"/subscriptions"} element={
            partnerSubscriptionRedesign ? <Navigate to={"/supplier-subscriptions"}/> :
            <ProtectedPartnerSubscriptionsReportLegacy account={user} handleLogout={logout} />
        }/>,
        <Route path={"/ready-for-payout"} key={"/ready-for-payout"} element={
            supplierReadyForPayoutRedesign ? <ProtectedRedesignSupplierReadyForPayout account={user} handleLogout={logout} /> :
            <ProtectedSupplierReadyForPayoutReport account={user} handleLogout={logout} />
        }/>,
        <Route path={"/supplier-booked-space-calendar"} key={"/supplier-booked-space-calendar"} element={
            supplierSpaceUsageRedesign ? <Navigate to={'/bookings/space-usage'} /> :
            <ProtectedSupplierBookedSpaceCalendarReport account={user} handleLogout={logout} />
        }/>,
        <Route path={"/inventory-corrections"} key={"/inventory-corrections"} element={
            inventoryCorrection ? <ProtectedSupplierInventoryCorrections account={user} handleLogout={logout} /> :
            <Navigate to={'/'}/>
        }/>,
        <Route path={"/supplier-invoices"} key={"/supplier-invoices"} element={
          supplierInvoicesRedesign ? <Navigate to={'/finances/supplier-invoices'} /> :
          <ProtectedSupplierInvoicesReport account={user} handleLogout={logout} />
        }/>,

        <Route path={"/booking/:bookingId/charge-service-fee"} key={"booking/bookingId/charge-service-fee"} element={<ProtectedChargeServiceFee account={user} handleLogout={logout} />}/>,
        <Route path={"/bookings/approvals"} key={"/bookings/approvals"} element={<ProtectedRedesignSupplierBookingApprovals account={user} handleLogout={logout} />}/>,
        <Route path={"/bookings/space-usage"} key={"/bookings/space-usage"} element={<ProtectedRedesignSupplierSpaceUsage account={user} handleLogout={logout} />}/>,
        <Route path={"/bookings/supplier-bookings"} key={"/bookings/supplier-bookings"} element={<ProtectedRedesignSupplierBooking account={user} handleLogout={logout} />}/>,
        <Route path={"/finances/invoices-in-payout/:payoutCorrelationId"} key={"/finances/invoices-in-payout/:payoutCorrelationId"} element={<ProtectedInvoicesInPayout account={user} handleLogout={logout} />}/>,
        <Route path={"/finances/supplier-invoices"} key={"/finances/supplier-invoices"} element={<ProtectedRedesignSupplierInvoicesReport account={user} handleLogout={logout} />}/>,
        <Route path={"/gms-upgrade"} key={"/gms-upgrade"} element={<ProtectedGmsUpgrade />}/>,
        <Route path={"/payouts/supplier-payouts"} key={"/payouts/supplier-payouts"} element={<ProtectedSupplierPayoutsReport account={user} handleLogout={logout} />}/>,
        <Route path={"/subscriptions/:subscriptionId"} key={"/subscriptions/subscriptionId"} element={<ProtectedPartnerSubscriptionsInvoicesLegacyReport account={user} handleLogout={logout} />}/>,
        <Route path={"/supplier-subscriptions"} key={"/supplier-subscriptions"} element={<ProtectedPartnerSubscriptionsReport account={user} handleLogout={logout} />}/>,
        <Route path={"/supplier-subscriptions/:subscriptionId"} key={"/supplier-subscriptions/subscriptionId"} element={<ProtectedPartnerSubscriptionsInvoicesReport account={user} handleLogout={logout} />}/>,
    ];
};

export default SupplierContent;
