import React, {useEffect, useMemo, useState} from 'react';
import {SORT_DIRECTION_ASC, SORT_DIRECTION_DESC} from "../../../util/ReportUtils";
import _ from "underscore";
import ReportEmpty from "../ReportEmpty";
import GroupAndSort from "./GroupAndSort";

export const withGroupAndSort = (Component, dataProp = 'data') => (props) => {
  const {[dataProp]: data = [], reportFields, ...rest} = props;
  const [groupBy, setGroupBy] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION_ASC);
  const [groupDirection, setGroupDirection] = useState(SORT_DIRECTION_ASC);
  const [total, setTotal] = useState(0);

  const sortedAndGroupedData = useMemo(() => {
    let tempData = data;
    if (tempData) {
      if (groupBy) {
        tempData = _.groupBy(tempData, groupBy);
        tempData = _.toArray(tempData);
        if (sortBy) {
          tempData = tempData.map(group => _.sortBy(group, sortBy));
          if (sortDirection === SORT_DIRECTION_DESC)
            tempData = tempData.map(group => group.reverse());
        }
        if (groupDirection === SORT_DIRECTION_DESC) {
          tempData = [...tempData.reverse()];
        }
        tempData = tempData.map(group => ({[dataProp]: group}));
      } else if (sortBy) {
        tempData = _.sortBy(tempData, sortBy);
        if (sortDirection === SORT_DIRECTION_DESC) {
          tempData = [...tempData.reverse()];
        }
        tempData = {[dataProp]: tempData};
      } else {
        tempData = {[dataProp]: tempData};
      }
    }
    return tempData;
  }, [data, sortBy, groupBy, sortDirection, groupDirection]);

  useEffect(() => {
    setTotal(data?.length);
  }, [data.length]);

  const groupByChangeHandler = (event) => {
    const value = event.target.value;
    setGroupBy(value);
  };

  const sortByChangeHandler = (event) => {
    const value = event.target.value;
    setSortBy(value);
  };

  const sortDirectionChangeHandler = () => {
    setSortDirection(prevSortDirection => prevSortDirection === SORT_DIRECTION_ASC ? SORT_DIRECTION_DESC : SORT_DIRECTION_ASC);
  }

  const groupDirectionChangeHandler = () => {
    setGroupDirection(prevGroupDirection => prevGroupDirection === SORT_DIRECTION_ASC ? SORT_DIRECTION_DESC : SORT_DIRECTION_ASC);
  }

  return <>
    <GroupAndSort groupByOptions={reportFields}
                  groupByChangeHandler={groupByChangeHandler}
                  groupBy={groupBy}
                  groupDirection={groupDirection}
                  groupDirectionChangeHandler={groupDirectionChangeHandler}
                  sortBy={sortBy}
                  sortByChangeHandler={sortByChangeHandler}
                  sortDirection={sortDirection}
                  sortDirectionChangeHandler={sortDirectionChangeHandler}
                  asc={SORT_DIRECTION_ASC}
                  desc={SORT_DIRECTION_DESC}
    />
    {
      !total && <ReportEmpty/>
    }
    {
      !!total && <Component {...rest}
                            {...sortedAndGroupedData}
                            grouped={groupBy}
                            groupBy={groupBy}
                            sortedAndGroupedData={sortedAndGroupedData}
                            reportFields={reportFields}/>
    }
  </>
}
