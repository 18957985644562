import request from '../util/SuperagentUtils';

export const getBlogPost: (id: string) => Promise = (id) => {
  return request.get(`/api/blog/posts/${id}`);
};

export const getRelatedBlogs: (
  contentGroupId: string,
  limit: number,
  offset: number
) => Promise = (contentGroupId, limit, offset) => {
  return request.get(`/api/blog/posts?contentGroupId=${contentGroupId}&limit=${limit}&offset=${offset}`);
};