import React, {useEffect, useState, useCallback, useContext} from 'react'
import {withSnackbar} from "../../components/hocs/withSnackbar";
import {getErrorMessageForNonStandardAndStandardResponse} from '../../util/NetworkErrorUtil';
import {
  getAllSupplierInventoryCorrections,
  inventoryCorrectionCount,
} from '../../components/inventory-correction/requests/inventory-correction-requests';
import Busy from '../../components/Busy';
import moment from 'moment';
import {DateFormats} from '../../components/constants/securspace-constants';
import {
  InventoryCorrectionReasonLabel,
  InventoryCorrectionReasons,
  InventoryCorrectionStatus
} from "../../components/constants/inventory-correction-constants";
import {AppContext} from '../../context/app-context';
import InventoryCorrections from "./InventoryCorrections";

const sortByOptions = [
  {value: "none", label: "None"},
  {value: "orderNumber", label: "Booking Number"},
  {value: "buyerName", label: "Buyer Name"},
  {value: "dateOfSubmission", label: "Date of submission"}
];

const defaultSearchFilter = {searchFilter: '', sortBy: 'none', sortDir: 'desc'};

const SupplierInventoryCorrections = ({account, handleLogout, snackbarShowMessage}) => {
  const {updatePendingInventoryCount} = useContext(AppContext)

  const [inventoryCorrections, setInventoryCorrections] = useState([]);
  const [rawCorrections, setRawCorrections] = useState([]);

  const fetchInventoryCorrections = useCallback(() => {
    Busy.set(true);
    getAllSupplierInventoryCorrections(account.id, {status: InventoryCorrectionStatus.PENDING})
      .then((response) => {
        const formattedCorrection = response.body?.map((item) => {

          return {
            ...item,
            dateOfSubmission: moment(item?.creationDate).format(DateFormats.DAY),
            buyerName: item?.locationInventoryActivityGetVO?.buyerName,
            orderNumber: item?.locationInventoryActivityGetVO?.orderNumber,
            reason: InventoryCorrectionReasons[item?.reason] ? InventoryCorrectionReasonLabel[item.reason] : 'N/A',
          }
        });
        setRawCorrections(response.body);
        setInventoryCorrections(formattedCorrection);
        inventoryCorrectionCount(account, updatePendingInventoryCount);
      })
      .catch((error) => {
        snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error');
      }).finally(() => {
      Busy.set(false);
    });
  }, [account, snackbarShowMessage]);

  useEffect(() => {
    if (account.id) {
      fetchInventoryCorrections();
    }
  }, [account, fetchInventoryCorrections]);

  return (
    <InventoryCorrections
      fetchInventoryCorrections={fetchInventoryCorrections}
      inventoryCorrections={inventoryCorrections}
      rawCorrections={rawCorrections}
      sortByOptions={sortByOptions}
      account={account}
      handleLogout={handleLogout}
      defaultSearchFilter = {defaultSearchFilter}
      snackbarShowMessage={snackbarShowMessage}
    />
  )
}

export default withSnackbar(SupplierInventoryCorrections)
