// @ts-nocheck

import React, {useCallback, useEffect, useState, useRef} from 'react';
import type {Account} from "../types/Account";
import {Grid, Theme, Typography} from "@mui/material";
import moment from "moment";
import {getSupplierSpaceUsageByPage} from "../requests/space-utilization-requests";
import {DateFormats} from "../components/constants/securspace-constants";
import {makeStyles} from "@mui/styles";
import {withSnackbar} from "../components/hocs/withSnackbar";
import {getErrorMessageForNonStandardAndStandardResponse} from "../util/NetworkErrorUtil";
import SpaceUsageItemsPaginated from "../components/spaceUsage/SpaceUsageItemsPaginated";
import {NoDataMessage, SpaceUsageLoader} from "@securspace/securspace-ui-kit";
import NoMatchingInvoicesFound from "../components/NoMatchingInvoicesFound";
import type {BookedSpaceCalendarView} from "../types/BookedSpaceCalendarView";
import BookedSpaceCalendarInvoiceModal from "../components/bookedSpaces/BookedSpaceCalendarInvoiceModal";
import SpaceUsageFilterWithQueryParams from "../components/spaceUsage/supplier/SpaceUsageFilterWithQueryParams";
import useUrlQueryParams from "../hooks/useUrlQueryParams";
import SpaceUsageExportCSVs from "../components/spaceUsage/supplier/SpaceUsageExportCSVs";

const useStyles: (theme: Theme) => { mainContainer: CSSStyleSheet } = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: '2.5rem',
    paddingBottom: '2.86rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '8.04rem',
      paddingRight: '8.04rem',
    }
  },
}));

const SupplierSpaceUsage = (props: {
  account: Account,
  handleLogout: () => void,
  snackbarShowMessage: () => void
}) => {
  const {account, handleLogout, snackbarShowMessage} = props;

  const {overageInvoiceNumber} = useUrlQueryParams();
  const lastMonthDate = moment().add(-1, 'months').format(DateFormats.DAY).toString();
  const todayDate = moment().format(DateFormats.DAY).toString();
  const [loading, setLoading] = useState(false)

  const [query: {
    locationId: string,
    locationName: string,
    bookingNumber: string,
    overageInvoiceNumber: string,
    startDate: string,
    endDate: string,
    sortBy: string
  }, setQuery] = useState({
    locationId: '',
    locationName: 'All',
    bookingNumber: '',
    overageInvoiceNumber: overageInvoiceNumber || '',
    startDate: lastMonthDate,
    endDate: todayDate,
    sortBy: 'calendarDate',
    sortDir: 'asc'
  });
  const [spaceUsageItems: BookedSpaceCalendarView[], setSpaceUsageItems] = useState([]);
  const [page: number, setPage] = useState(0);
  const [pageSize: number, setPageSize] = useState(10);
  const [totalElements: number, setTotalElements] = useState(0);
  const [formChanged: boolean, setFormChanged] = useState(false);
  const [showOverageInvoicesModal: boolean, setShowOverageInvoicesModal] = useState(false);
  const [overageInvoicesToView, setOverageInvoicesToView] = useState([]);

  const classes = useStyles();
  const initialRender = useRef(true);

  useEffect(() => {
    const {locationId, bookingNumber, overageInvoiceNumber, startDate, endDate, sortBy, sortDir} = query;
    let ignore = false;
    setLoading(true);
    getSupplierSpaceUsageByPage(
      account.id,
      locationId,
      bookingNumber,
      overageInvoiceNumber,
      startDate ? moment(startDate).format(DateFormats.DAY) : null,
      endDate ? moment(endDate).format(DateFormats.DAY) : null,
      sortBy,
      sortDir,
      page,
      pageSize
    ).then((response) => {
      if (!ignore && response.body) {
        const {content, totalPages, count, page} = response.body;
        if (page + 1 > totalPages) {
          // If the page index is greater than the total pages
          // This will refresh the data and show the last page of results
          setPage(totalPages > 0 ? totalPages - 1 : 0);
        }
        setSpaceUsageItems(content);
        setTotalElements(count);
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    })
      .catch((error) => {
        snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error');
        if (error.response.status === 401) {
          handleLogout();
        }
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      ignore = true;
    };
  }, [account, query, page, pageSize, snackbarShowMessage, handleLogout]);

  const handleChange = useCallback((event) => {
    if (!initialRender.current) {
      const bookingNumber = event.filters.find((filter) => filter.name === 'bookingNumber')?.value;
      const overageInvoiceNumber = event.filters.find((filter) => filter.name === 'overageInvoiceNumber')?.value;
      setQuery({
        locationId: event.location.id,
        locationName: event.location.locationName,
        bookingNumber: bookingNumber,
        overageInvoiceNumber: overageInvoiceNumber,
        startDate: event.startDate,
        endDate: event.endDate,
        sortBy: event.sortBy,
        sortDir: event.sortDir
      });
      setFormChanged(true);
    }
    else {
      initialRender.current = false;
  }
  }, []);

  const handlePageChange = (event: React.ChangeEvent, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
  };

  const handleOpenOverageInvoicesModal = (spaceUsageItem: BookedSpaceCalendarView, overageInvoices: any[]) => {
    setOverageInvoicesToView(overageInvoices);
    setShowOverageInvoicesModal(true);
  };

  const closeOverageInvoicesModal = () => {
    setOverageInvoicesToView([]);
    setShowOverageInvoicesModal(false);
  };

  return (
    <Grid container rowSpacing={'1rem'} className={classes.mainContainer}>
      <Grid item mb={2}>
        <Typography variant={'h5'} component={'h1'}>Space Usage</Typography>
      </Grid>
      <BookedSpaceCalendarInvoiceModal
        isOpen={showOverageInvoicesModal}
        closeModal={closeOverageInvoicesModal}
        invoiceItems={overageInvoicesToView}
      />
      <SpaceUsageFilterWithQueryParams
        onChange={handleChange}
        defaultStartDate={lastMonthDate}
        defaultEndDate={todayDate}
      />
      <SpaceUsageExportCSVs
        item
        accountId={account.id}
        query={query}
      />
      {
       loading ? Array.from({ length: 12 }).map((_, index) => (
        <Grid item sx={{ width:'100%' }} key={index}>
          <SpaceUsageLoader />
        </Grid>
    )) : spaceUsageItems.length ? <SpaceUsageItemsPaginated
          spaceUsageItems={spaceUsageItems}
          count={totalElements}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          onPageChange={handlePageChange}
          onViewOverageInvoices={handleOpenOverageInvoicesModal}
        /> : formChanged ? <NoMatchingInvoicesFound item message="No matching records found" /> :
          <Grid item container><NoDataMessage textStyle={""}/></Grid>
      }
    </Grid>
  );
};

export default withSnackbar(SupplierSpaceUsage);
