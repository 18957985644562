import React from 'react';
import { ButtonBase, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowBack } from '@mui/icons-material';
import classNames from 'classnames';
import {Theme} from "@mui/material";

const useStyles: (theme: Theme) => {
    secondaryMainColor: CSSStyleSheet,
    goBackArrow: CSSStyleSheet,
    backToSearchResults: CSSStyleSheet,
} = makeStyles((theme) => ({
    secondaryMainColor: {
        color: theme.palette.secondary.main,
    },
    goBackArrow: {
        marginRight: '0.5em',
    },
    backToSearchResults: {
        ...theme.components.returnToText,
    },
}));

const BackButton = ({ children, ...props }) => {
    const classes = useStyles();

    return (
        <ButtonBase {...props}>
            <ArrowBack className={classNames(classes.goBackArrow, classes.secondaryMainColor)} fontSize='small' />
            <Typography className={classNames(classes.backToSearchResults, classes.secondaryMainColor)}>
                {children}
            </Typography>
        </ButtonBase>
    );
};

export default BackButton;