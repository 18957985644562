import React, {Component} from 'react';
import '../css/components/busy.css'
import {
    CircularProgress,
    Box
} from '@mui/material';

const $ = window.$;

class Busy extends Component {

    static set(value) {
        if (value) {
            $(".busy-screen").removeClass("not-visible");
        } else {
            $(".busy-screen").addClass("not-visible");
        }
    }

    render()
    {
        return (
            <Box className="busy-screen">
                <Box className="busy-spinner-container">
                    <CircularProgress color='primary' />
                </Box>
            </Box>
        );
    }
}

export default Busy;
