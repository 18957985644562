import React, {useEffect, useState} from 'react';
import SecurSpaceContainer from "../../components/common/SecurSpaceContainer";
import {Grid, Typography} from "@mui/material";
import moment from "moment";
import {
  BookingStatus,
  BookingTransactionStatusTypeLabel, ChargeTypes,
  DateFormats
} from "../../components/constants/securspace-constants";
import InvoiceList from "../../components/admin/invoices/redesign/InvoiceList";
import InvoiceSortFilter from "../../components/admin/invoices/redesign/InvoiceSortFilter/InvoiceSortFilter";
import {Pagination} from "@securspace/securspace-ui-kit";
import {requestAdminInvoices} from "../../requests/invoice-requests";
import useStyles from "./styles";
import InvoiceGroupSummary from "../../components/admin/invoices/redesign/InvoiceGroupSummary";
import ExportCSVs from "../../components/admin/invoices/redesign/ExportCSVs";
import {formatCurrencyValue} from "../../util/PaymentUtils";
import {SubscriptionType} from "../../components/constants/booking-transaction-constants";
import {withSnackbar} from "../../components/hocs/withSnackbar";

type AdminInvoicesReportProps = {
  handleLogout: () => void;
  snackbarShowMessage: any;
};

const AdminInvoicesReport: (props: AdminInvoicesReportProps) => JSX.Element = ({handleLogout, snackbarShowMessage}) => {
  const [query: {
    startDate: string,
    endDate: string,
    bookingNumber: string,
    transactionNumber: string,
    assetTypeGroup: string,
    buyerCompanyName: string,
    supplierCompanyName: string,
    transactionType: string,
    bookingStatus: string,
    supplierTransactionStatus: string,
    taxStatus: string,
    paymentProcessor: string,
    sortBy: string,
    sortDir: string,
    page: number,
    size: number,
  }, setQuery] = useState({
    startDate: moment().subtract(1, 'month').format(DateFormats.DAY),
    endDate: moment().format(DateFormats.DAY),
    bookingNumber: '',
    transactionNumber: '',
    assetTypeGroup: '',
    buyerCompanyName: '',
    supplierCompanyName: '',
    transactionType: '',
    bookingStatus: '',
    supplierTransactionStatus: '',
    taxStatus: '',
    paymentProcessor: '',
    sortBy: 'transactionNumber',
    sortDir: 'asc',
    page: 0,
    size: 10,
  });
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    requestAdminInvoices(query)
      .then((response) => {
        setInvoices(response.body.content.map((invoice) => {
          return {
            ...invoice,
            bookingStatus: BookingStatus[invoice.bookingStatus] || invoice.bookingStatus,
            buyerAmount: formatCurrencyValue(invoice.buyerAmount, true),
            locationAddress: `${invoice.locationAddressLine1}, ${invoice.locationCity}, ${invoice.locationState} ${invoice.locationZip}`,
            locationIsLive: invoice.locationIsLive ? 'True' : 'False',
            paymentProcessorFees: formatCurrencyValue(invoice.paymentProcessorFees, true),
            securspaceAmount: formatCurrencyValue(invoice.securspaceAmount, true),
            securspaceFees: formatCurrencyValue(invoice.securspaceFees, true),
            status: BookingTransactionStatusTypeLabel[invoice.status] || invoice.status,
            supplierAmount: formatCurrencyValue(invoice.supplierAmount, true),
            supplierPayoutOnHold: invoice.supplierPayoutOnHold ? 'True' : 'False',
            supplierSubscriptionType: SubscriptionType[invoice.supplierSubscriptionType]?.label || invoice.supplierSubscriptionType,
            transactionTaxAmount: formatCurrencyValue(invoice.transactionTaxAmount, true),
            transactionType: ChargeTypes[invoice.transactionType] || invoice.transactionType,
          };
        }));
      })
      .catch((err) => {
        if (err.response.status === 401) handleLogout();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [query, handleLogout, snackbarShowMessage]);

  return (
    <SecurSpaceContainer>
      <Grid container className={classes.mainContainer} rowGap={4}>
        <Typography variant={'h5'} component={'h1'}>Invoices</Typography>
        <InvoiceSortFilter
          setQuery={setQuery}
        />
        <ExportCSVs query={query} />
        <InvoiceGroupSummary query={query} />
        <InvoiceList
          invoices={invoices}
          loading={loading}
        />
        <Grid item container justifyContent={'end'}>
          <Pagination
            count={invoices.length}
            rowsPerPage={query.size}
            page={query.page}
            onPageChange={(_event, page) => setQuery((prevState) => ({...prevState, page}))}
            onRowsPerPageChange={(event) => (setQuery((prevState) => ({...prevState, size: event.target.value})))}
            variant={'tablePagination'}
          />
        </Grid>
      </Grid>
    </SecurSpaceContainer>
  );
};

export default withSnackbar(AdminInvoicesReport);