import React from "react";
import {SpaceUsageCard, SpaceUsageLoader} from "@securspace/securspace-ui-kit";
import {BookingTransactionTypeLabel, DateFormats} from "../constants/securspace-constants";
import moment from "moment";
import {formatCurrencyValue} from "../../util/PaymentUtils";
import {Grid} from "@mui/material";

const LoadingSkeleton = () => {
  return (
    <Grid container direction={'column'} gap={2}>
      {Array.from({length: 10}).map((_, index) => <SpaceUsageLoader key={index} />)}
    </Grid>
  );
};

type PayoutTransactionListProps = {
  loading: boolean,
  payoutTransactions: Object[]
};

const PayoutTransactionList: (props: PayoutTransactionListProps) => JSX.Element = ({loading, payoutTransactions}) => {

  const getStartDate = (startDate) => {
    const momentStartDate = moment(startDate);
    if (momentStartDate.isValid()) {
      return `startDate=${momentStartDate.format(DateFormats.DAY)}&`;
    }
    return '';
  }

  return (
    loading ? <LoadingSkeleton /> : payoutTransactions?.map((transaction) =>
      <SpaceUsageCard
        data={{
          invoiceNumber: transaction.invoiceNumber,
          payoutAmount: formatCurrencyValue(transaction.payoutAmount),
          chargeType: BookingTransactionTypeLabel[transaction.transactionType],
          serviceDates: transaction.serviceDates,
          paymentDate: transaction.paymentDate,
          buyer: transaction.buyerCompanyName,
          location: transaction.locationName,
          bookingNumber: transaction.orderNumber,
        }}
        variant={'primary'}
        key={transaction.invoiceNumber}
        links={{
          invoiceNumber: {baseUrl: `/finances/supplier-invoices?${getStartDate(transaction.serviceStartDate)}`, queryParam: "invoiceNumber"},
          bookingNumber: {baseUrl: '/bookings/supplier-bookings', queryParam: "bookingNumber"},
      }}
        styles={{ mb: 2, width: '100%'}}
      />
    )
  );
};

export default PayoutTransactionList;