import React from 'react';
import { Box, Button, Grid, List, ListItem, Typography } from "@mui/material";
import { AccountBalance, CreditCard } from "@mui/icons-material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useStyles } from "./styles/paymentMethodStyle";

const Documents = [
  {
    name: 'Instant',
    options: [
      "Requires you to enter your Online Banking credentials", 
      "Not supported with your local banks Micro Deposit",
      "Can be used immediately",
      "No weekly transaction limit (based on available balance)",
    ]
  },
  {
    name: 'Micro Deposit',
    options: [
      'Requires you to enter your bank account info',
      'Cannot be used until micro deposits are received and verified',
      'Takes a few days to complete',
      'Supported with all Banks',
      'Weekly transaction limit of $25,000'
    ]
  }
]

const PaymentMethodType = (props) => {
  const { payWithAch, setACH, collectMicroDepositVerifyPayment, setMicroDeposit } = props;
  const classes = useStyles();

  return <>
    <Box className={classes.flex}>
      <Button
        onClick={() => setACH(false)}
        className={classes.cardStyles}
        variant={!payWithAch ? "contained" : "outlined"}
        color={!payWithAch ? "primary" : "secondary"}
      >
        <CreditCard />
        <Typography variant='subtitle2' className={!payWithAch ? classes.cardTitleActive : classes.cardTitleDefault}>Pay with Card</Typography>
      </Button>
      <Button
        onClick={() => setACH(true)}
        className={classes.cardStyles}
        variant={payWithAch ? "contained" : "outlined"}
        color={payWithAch ? "primary" : "secondary"}
      >
        <AccountBalance />
        <Typography variant='subtitle2' className={payWithAch ? classes.cardTitleActive : classes.cardTitleDefault}>Pay with ACH</Typography>
      </Button>
    </Box>
    {payWithAch &&
      <Box>
        <Typography className={classes.title}>Select Bank Account Verify Method</Typography>
        <Grid spacing={2} container alignItems='center'>
          <Grid item xs={6}>
            <Button
              onClick={() => setMicroDeposit(false)}
              className={classes.paymentACH}
              variant={!collectMicroDepositVerifyPayment ? "contained" : "outlined"}
              color={!collectMicroDepositVerifyPayment ? "primary" : "secondary"}
              fullWidth={true}
            >
              Instant
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => setMicroDeposit(true)}
              className={classes.paymentACH}
              variant={collectMicroDepositVerifyPayment ? "contained" : "outlined"}
              color={collectMicroDepositVerifyPayment ? "primary" : "secondary"}
              fullWidth={true}
            >
              Micro Deposit
            </Button>
          </Grid>
        </Grid>
        <Box className={classes.cardContent}>
          {Documents.map((item, index) => (
            <Box key={index} className={classes.cardContentFooter}>
              <Typography className={classes.listTitle}>{item.name}</Typography>
              {item.options.map((option, index) => (
                <List key={index} className={classes.listItem}>
                  <FiberManualRecordIcon className={classes.icon} />
                  <ListItem className={classes.listDescription}>
                    {option}
                  </ListItem>
                </List>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    }
  </>
}

export default PaymentMethodType;
