import React from 'react';
import { DocumentScanner } from '@mui/icons-material';
import {SpaceUsageCard} from "@securspace/securspace-ui-kit";
import {Box} from "@mui/material";

const dataFields = ["feeType", "createdOn","createdBy", "reason", "amount", "paymentSource", "active"]

const PartnerSubscriptionsMap = (props: {
  subscriptions: any[],
}) => {
  const {subscriptions} = props;

  const actionItems = [
    {
        icon: <DocumentScanner fontSize="medium" color="primary" />,
        label: 'View Invoice',
        onClick: (item) => {
          if (item) {
            window.open(`/supplier-subscriptions/${item.id}`)
          }
        },
    },]

  return (
    <Box mt={4} sx={{
      ...(subscriptions.length === 0) && {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
      }
    }}>
      {
        subscriptions ?
            subscriptions.map(item => <SpaceUsageCard data={item} styles={{ mb:2, width:'100%'}} fields={dataFields} emailFieldName="createdBy" columnStyling={{md: 8}} hasAction={true} actionItems={actionItems}/>)
            : ""
        }
    </Box>
  )
};

export default PartnerSubscriptionsMap;
